import { getFacEmitidasFactoring, getFacEmitidasIndividualPDF, eventTrackingToExcel, trackingSidebar } from '../../../services/factoringApi';
import { asyncActionType } from '../../../helpers/ActionsHelper';
import FileDownload from 'js-file-download';
import Moment from "moment/moment";

export const fetchAction = asyncActionType('@@FACTURAS_EMITIDAS_FACTORING', 'FETCH')

export const fetchFacEmitidasFactoring = (request) => ({
    type: fetchAction.PENDING,
    pageSize: request.pageSize
});

 export const facEmitidasFactoringFetched = (response,tipoDocumento) => ({
    tipoDocumento : tipoDocumento,
    type: fetchAction.SUCCESS,
    rows: response.data,
    pagination: {
      currentPage: response.paginacion.numeroPagina,
      top: response.paginacion.top,
      totalItems: response.paginacion.totalRegistro,
      totalPages: response.paginacion.totalPagina,
      from: response.paginacion.registroDesde,
      to: response.paginacion.registroHasta
    },
});

export const docpdf = (response) => ({
    data: response
})

export const FilterFacEmitidasFactoring = (params) => (dispatch) => {
    dispatch(fetchFacEmitidasFactoring(params));
    return getFacEmitidasFactoring(params)
        .then((response) => dispatch(facEmitidasFactoringFetched(response,params.tipoDocumento)))
        .catch(error => error);
};

export const GetPDF = (params) => (dispatch) => {
    params.isExportPdf = true;
    return getFacEmitidasFactoring(params)
        .then(response => FileDownload(response, `Facturas Emitidas ${Moment().format("YYYY-MM-DD-hh-mm")}` + ".pdf"))
        .catch(error => error);

}

export const GetExcel = (params) => (dispatch) => {
    return getFacEmitidasFactoring(params)
        .then(response => response)
        .catch(error => error);

}
export const EventTrackingExcel = (params) => (dispatch) => {    
    return eventTrackingToExcel(params)
        .then(response => response)
        .catch(error => error);
}
export const GetIndividualPDF = (params) => () => {
    //params.isExportPdf = true;
    return getFacEmitidasIndividualPDF(params)
        .then(response => response)
        .catch(error => error);
}
export const TrackingSidebar = (params) => () => {    
    return trackingSidebar(params)
        .then(response => response)
        .catch(error => error);
}
export default { TrackingSidebar, EventTrackingExcel, fetchAction, FilterFacEmitidasFactoring, GetPDF, GetIndividualPDF, GetExcel };

import React from 'react';
import '../../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import Header from '../../../sharedv2/Header/Header';
import Loader from '../../../shared/Loader/Loader';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableSubscription from './TableSubscription';
import ConfiguracionSincronizacionDte from '../../../sharedv2/ConfiguracionSincronizacion/ConfiguracionSincronizacionDte';
import ConfiguracionSincronizacionServicios from '../../../sharedv2/ConfiguracionSincronizacion/ConfiguracionSincronizacionServicios';
import EliminarModal from '../../../sharedv2/EliminarModal/EliminarModal';
import { connect } from 'react-redux';
import actions from '../../../main/reducer';
import factorxApi from '../../../../services/factorxApi';
import fxActions from '../actions';
import { AppState } from '../../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';
import { esEjecutivoAsistente } from '../../../../helpers/UserHelper';

interface IProps extends WithStyles<typeof HistoricoStyle> {
  empresa: Dto.Portal.Empresa;
  esEjecutivo: boolean;
  notificacion: (exito: boolean, mensaje: string) => void;
  cargarDatosEmpresaUsuario: () => void;
}

interface IState {
  estaCargando: boolean,
  activeTab: number,
  abrirSincronizacionDte: boolean,
  abrirSincronizacionServicios: boolean,
  eliminarAbierto: boolean,
  sincronizacion: Core.Dto.Sincronizacion | undefined
}

class Subscripciones extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      estaCargando: true,
      activeTab: 0,
      abrirSincronizacionDte: false,
      abrirSincronizacionServicios: false,
      eliminarAbierto: false,
      sincronizacion: undefined
    };
  }

  componentDidMount() {
    this.setState({
      estaCargando: false
    });
  }

  toggleEliminar = (sinc: Core.Dto.Sincronizacion | undefined) => {
    this.setState((state) => ({
      sincronizacion: sinc,
      eliminarAbierto: !state.eliminarAbierto
    }));
  };

  toogleSincronizacion = (sinc: Core.Dto.Sincronizacion) => {
    if (sinc.tipo === 1) {
      this.setState((state) => ({
        sincronizacion: sinc,
        abrirSincronizacionDte: !state.abrirSincronizacionDte
      }));
    } else {
      this.setState((state) => ({
        sincronizacion: sinc,
        abrirSincronizacionServicios: !state.abrirSincronizacionServicios
      }));
    }
  };

  onAgregarSubscripcion = () => {
    this.props.cargarDatosEmpresaUsuario();
    this.setState(() => ({ abrirSincronizacionDte: false, abrirSincronizacionServicios: false }));
  };

  onEliminarSubscripcion = () => {
    if (this.state.sincronizacion) {
      const tipo = this.state.sincronizacion.tipo;
      const api = tipo === 1 ?
        factorxApi.eliminarRegistroSincronizacionDte() :
        tipo === 2 ?
          factorxApi.eliminarRegistroSincronizacionPfi() :
          tipo === 3 ?
            factorxApi.eliminarRegistroSincronizacionAec() :
            tipo === 4 ?
              factorxApi.eliminarRegistroSincronizacionTgr() :
              tipo === 5 ?
                factorxApi.eliminarRegistroSincronizacionCte() : undefined;

      if (api) {
        this.setState({ estaCargando: true });
        api.then(respuesta => {
          this.setState({ estaCargando: false, eliminarAbierto: false });
          if (respuesta.exito) {
            this.props.cargarDatosEmpresaUsuario();
            this.props.notificacion(false, 'La suscripción fue eliminada exitosamente.');
          } else {
            this.props.notificacion(false, respuesta.mensaje);
          }
        });
      }
    }
  };

  render() {
    const { classes, empresa } = this.props;
    const {
      activeTab,
      abrirSincronizacionDte,
      abrirSincronizacionServicios,
      eliminarAbierto,
      sincronizacion
    } = this.state;

    return (
      <div className='container'>
        {this.state.estaCargando ? (<Loader />) : (
          <>
            <Header
              title='Administración de Suscripciones con Fuentes de Datos'
              breadcrum='Usted se encuentra en: Administración / Subscripciones'
            />
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`tabs ${classes.tabsBackground}`}>
                  <Tabs value={activeTab} classes={{ indicator: 'indicator' }}>
                    <Tab label='SUBSCRIPCIONES' classes={{ root: 'tabs-factoral' }} />
                  </Tabs>
                  <TableSubscription
                    sincronizacion={empresa.sincronizacion || []}
                    deleteSincronizacion={this.toggleEliminar}
                    openSincronizacion={this.toogleSincronizacion}
                  />
                </div>
              </Grid>
            </Grid>
            <ConfiguracionSincronizacionDte
              open={abrirSincronizacionDte}
              onClose={this.onAgregarSubscripcion}
            />
            <ConfiguracionSincronizacionServicios
              sincronizacion={sincronizacion}
              open={abrirSincronizacionServicios}
              onClose={this.onAgregarSubscripcion}
            />
            <EliminarModal
              open={eliminarAbierto}
              titulo='Eliminar suscripción'
              mensaje='¿Está seguro que desea eliminar la suscripción seleccionada?'
              onClose={() => this.toggleEliminar(undefined)}
              onDelete={this.onEliminarSubscripcion}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  empresa: state.factorx.empresa,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje)),
  cargarDatosEmpresaUsuario: () => dispatch(fxActions.cargarDatosEmpresaUsuario())
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(Subscripciones));

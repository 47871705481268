import { changeStatusEmpresa, crearEmpresa, editEmpresa, getDetalleEmpresas } from '../../../services/factoringApi';
import { Dispatch } from 'redux';

// export const fetchAction = asyncActionType('@@ADMIN_ENTERPRISE', 'FETCH');

// export const fetchDatosEmpresas = (request) => ({
//     type: fetchAction.PENDING,
//     pageSize: request.pageSize
// });
//
// export const DatosEmpresasFetched = (response) => ({
//     type: fetchAction.SUCCESS,
//     empresa:{
//         rut: response.data,
//         nombre: response.data,
//         razonSocial: response.data,
//         direccionComercial: response.data,
//         telefonoComercial: response.data
//     },
//     usuario:{
//         rut: response.data,
//         nombre: response.data,
//         email: response.data,
//         celular: response.data
//     },
// });

export const getDatosEmpresas = () => (dispatch : Dispatch) : Promise<Dto.EnterpriseProfile[]> => {
  return getDetalleEmpresas()
    .then(response => response)
    .catch(error => error);
};

export interface crearEmpresaParams {
  rut : string;
  socialReason : string;
  address : string;
  phone : string;
  token : string;
}

export const crearEmpresaAction = (params : crearEmpresaParams) => (dispatch : Dispatch) : Promise<boolean> => {
  return crearEmpresa(params)
    .then(response => response)
    .catch(error => error);
};

export const editEmpresaAction = (params : Dto.EditEnterprise) => (dispatch : Dispatch) : Promise<boolean> => {
  return editEmpresa(params)
    .then(response => response)
    .catch(error => error);
};

export const changeStatusEmpresaAction = (params : Dto.ChangeStatusEnterprise) => (dispatch : Dispatch) : Promise<boolean> => {
  return changeStatusEmpresa(params)
    .then(response => response)
    .catch(error => error);
};


// export default { getDatosEmpresas, crearEmpresaAction, editEmpresaAction, changeStatusEmpresaAction };

// TODO Implementar function onGrabar, si es que requiere parametros
import { IconButton } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import TableContainer from '../../../sharedv2/Table/TableContainer';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const dataMockReDocumentos = [{
  idDocumento: '1',
  nroOperacion: 12331233,
  detalle: 'Solicitud ingresada',
  fecha: '20202/11/21',
  estado: 'Correcto'
}, {
  idDocumento: '1',
  nroOperacion: 12331233,
  detalle: 'Solicitud ingresada',
  fecha: '20202/11/21',
  estado: 'Correcto'
}];

interface IHistorialTabla extends WithStyles<typeof HistoricoStyle> {
  historial: Dto.RegistroCivil.HistorialInscripcion[];
}

const HistorialTabla: FC<IHistorialTabla> = (props) => {
  const { classes, historial } = props;
  const history = useHistory();
  const [result, setResult] = useState(false);
  const [documentos, setContratos] = useState<any[]>([]);

  useEffect(() => {}, []);

  const tableInfo = [
    /*{
      column: 'Nro',
      relation: 'idDocumento',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: any) => documento.idDocumento,
    },*/
    {
      column: 'Nro Operación',
      relation: 'nroOperacion',
      renderData: (historial: Dto.RegistroCivil.HistorialInscripcion) => historial.idInscripcion,
    },
    {
      column: 'Detalle',
      relation: 'detalle',
      renderData: (historial: Dto.RegistroCivil.HistorialInscripcion) => historial.observaciones,
    },
    {
      column: 'Fecha',
      relation: 'fecha',
      renderData: (historial: Dto.RegistroCivil.HistorialInscripcion) => historial.fecha,
    },
    /*{
      column: 'Estado',
      relation: 'estado',
      renderData: (documento: any) => documento.estado,
    }*/
  ];

  const obtenerOperaciones = (pagina: number, tamano: number) => {
    console.log('obtenerOperaciones');
  };

  return <TableContainer classes={classes} data={historial} tableInfo={tableInfo} obtenerDatos={obtenerOperaciones} pagination={undefined} />;
};

export default withStyles(HistoricoStyle)(HistorialTabla);

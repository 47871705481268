import * as React from 'react';
import { Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { CloudUpload } from '@material-ui/icons';
import Dropzone from 'react-dropzone';
import Typography from '@material-ui/core/Typography';
import SDropzone from './style';
import clsx from 'clsx';

const TitleText = ({ title, css, subtitle, type }) => {
  return (
    <React.Fragment>
      {title ? (
        <Typography variant='h2' className={css.title}>
          {title}
        </Typography>
      ) : (
        ''
      )}
      {subtitle ? (
        <Typography variant='body2' className={css.subtitle}>
          {subtitle}
        </Typography>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

class DropzoneContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, name } = this.props;
    return (
      <Dropzone onDrop={this.props.onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} className={clsx(classes.dropContainer, { [classes.inputStyle]: this.props.inputStyle === 'true' })}>
            <input {...getInputProps()} name={name} />
            <Grid
              container
              direction='column'
              alignItems='center'
              wrap='nowrap'
              className={classes.container}
            >
              {this.props.inputStyle !== 'true' && (
                <React.Fragment>
                  <Grid item>
                    <CloudUpload className={classes.icon} />
                  </Grid>
                  <Grid item style={{ width: '100%' }}>
                    <div style={{ marginTop: 'auto', width: '100%' }}>
                      {isDragActive ? (
                        <Typography variant='h2' className={classes.text}>
                          Drop the files here...
                        </Typography>
                      ) : (
                        <TitleText
                          title={this.props.title}
                          css={{ title: classes.text, subtitle: classes.subtitle }}
                          subtitle={this.props.subtitle}
                          type={this.props.type}
                        />
                      )}
                    </div>
                  </Grid>
                </React.Fragment>
              )}
              {this.props.inputStyle === 'true' && (
                <div className={classes.uploadButtonContainer}>
                  <Button variant='outlined' color='primary' className={classes.greenButton}>
                    Archivo
                  </Button>
                </div>
              )}
            </Grid>
          </div>
        )}
      </Dropzone>
    );
  }
}

export default withStyles(SDropzone)(DropzoneContainer);

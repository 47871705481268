import { getDetalleExcedentesHistorico, trackingSidebar, getExcedenteCursadoIndividualPDF, eventTrackingToExcel } from '../../../../services/factoringApi';
import { asyncActionType } from '../../../../helpers/ActionsHelper';
import FileDownload from 'js-file-download';
import Moment from "moment/moment";

export const fetchAction = asyncActionType('@@EXCEDENTES_HISTORICOS', 'FETCH')

export const fetchExcedentesHistorico = (request) => ({
    type: fetchAction.PENDING,
    pageSize: request.pageSize
});

export const excedentesHistoricoFetched = (response, tipoDocumento) => ({
    tipoDocumento : tipoDocumento,
    type: fetchAction.SUCCESS,
    rows: response.data,
    pagination: {
        currentPage: response.paginacion.numeroPagina,
        top: response.paginacion.top,
        totalItems: response.paginacion.totalRegistro,
        totalPages: response.paginacion.totalPagina,
        from: response.paginacion.registroDesde,
        to: response.paginacion.registroHasta
    },
});

export const FilterExcedentesHistorico = (params) => (dispatch) => {
    dispatch(fetchExcedentesHistorico(params));    
    return getDetalleExcedentesHistorico(params)
        .then((response) => dispatch(excedentesHistoricoFetched(response, params.tipoDocumento)))
        .catch(error => error);
};

export const GetPDF = (params) => (dispatch) => {
    params.isExportPdf = true;
    return getDetalleExcedentesHistorico(params)
        .then(response => FileDownload(response, `Excedentes cursados ${Moment().format("YYYY-MM-DD-hh-mm")}` + ".pdf"))
        .catch(error => error);

}

export const GetExcel = (params) => (dispatch) => {
    return getDetalleExcedentesHistorico(params)
        .then(response => response)
        .catch(error => error);

}
export const EventTrackingExcel = (params) => (dispatch) => {    
    return eventTrackingToExcel(params)
        .then(response => response)
        .catch(error => error);
}

export const GetIndividualPDF = (params) => () => {
    return getExcedenteCursadoIndividualPDF(params)
        .then(response => response)
        .catch(error => error);
}
export const TrackingSidebar = (params) => () => {    
    return trackingSidebar(params)
        .then(response => response)
        .catch(error => error);
}
export default { TrackingSidebar, EventTrackingExcel, fetchAction, FilterExcedentesHistorico, GetPDF };

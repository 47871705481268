
import { fetchAction } from './actions';
import { AnyAction } from 'redux';
import SubscriberState from "./state";

export const reducer = (state = SubscriberState, action : AnyAction) => {
    switch (action.type) {
        case fetchAction.PENDING: {
            return Object.assign({}, state, action);
        }

        case fetchAction.SUCCESS: {
            return Object.assign({}, state, action);
        }

        // case fetchAction.ERROR: {
        //   return Object.assign({}, state, action);
        // }
        //
        // case fetchAction.CLEAR: {
        //   return Object.assign({}, state, action);
        // }

        default:
            return state;
    }
};

// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getDatosEmpresas } from '../empresa/actions';
import { changeStatusUsuarioAction, crearUsuarioAction, downloadTrackingUsersAction, editUsuarioAction, getListaUsuariosAction } from './actions';
import deburr from 'lodash/deburr';
import ReactTable from 'react-table';
import xlsx from 'xlsx';
import Moment from 'moment/moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOff from '@material-ui/icons/HighlightOff';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import PaginationBase from '../../shared/Pagination/PaginationBase';
import Loader from '../../shared/Loader/Loader';
import Style from '../../../styles/jss/components/extendedFormsStyle';
import { formatRut } from '../../../helpers/RutHelper';
import ModalComponent from '../../shared/ModalMsg/ModalMsg';
import ActualizarUsuarios from './actualizarUsuarios';
import { AppState } from '../../../config/rootReducer';
import { FormControl, FormHelperText, WithStyles } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const iniUser = (): Dto.User => ({
  enable: false,
  mail: '',
  userId: '' as any,
  rut: '' as any,
  phoneNumber: '',
  completeRut: '',
  lastName: '',
  firstName: '',
  empresas: [],
  token: '',
  dv: '',
  completeName: '',
  perfiles: []
});

interface IRecord<T> {
  _original: T;
}

interface IRowRecord<T> {
  original: T;
}

interface DispatchProps {
  dispatch: any;
  crearUsuario: (usuario: Dto.CreateUser) => Promise<boolean>;
  editUsuario: (usuario: Dto.User) => Promise<boolean>;
  changeStatusUsuario: (payload: any) => Promise<boolean>;
  getListaUsuarios: () => Promise<Dto.User[]>;
  getDatosEmpresas: () => Promise<Dto.EnterpriseProfile[]>;
}

interface IProps extends DispatchProps, WithStyles<typeof Style> {
}

interface IState {
  loadingTable: boolean;
  stateLoadNewUser: boolean;
  errorGenerated: boolean;
  enterpriseList: Dto.EnterpriseProfile[];
  profilesList: IItem[];
  productsList: IItem[];
  habilitado: IItem[];
  usersList: Dto.User[];
  usersListRender: Dto.User[];
  token: string;
  openModal1: boolean;
  openModal2: boolean;
  openModal3: boolean;
  editState: boolean;
  usuario: Dto.User | undefined,
  openModalChangeStatus: boolean;
  filtroListado: string;
  filtroEmpresas: string;
}

interface IItem {
  id: number;
  text: string;
}

class AdminUser extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loadingTable: false,
      stateLoadNewUser: false,
      enterpriseList: [],
      errorGenerated: false,
      usersList: [],
      usersListRender: [],
      openModal1: false,
      openModal2: false,
      openModal3: false,
      usuario: undefined,
      token: '',
      editState: false,
      openModalChangeStatus: false,
      profilesList: [{ id: 1, text: 'Empresa (cliente)' }, { id: 2, text: 'Ejecutivo' }, { id: 3, text: 'Asistente' }, { id: 4, text: 'Marketing' }],
      productsList: [{ id: 1, text: 'Factoring' }, { id: 2, text: 'Leasing' }, { id: 3, text: 'Leasing Tect.' }, { id: 4, text: 'Registro civil' }],
      habilitado: [{ id: 0, text: 'No' }, { id: 1, text: 'Si' }],
      filtroListado: '',
      filtroEmpresas: ''
    };
    // this.fetchData = this.fetchData.bind(this);
  }

  componentWillMount = () => {
    this.loadEnterprise();
  };

  async loadEnterprise() {
    const data = await this.props.getDatosEmpresas();
    if (data) this.setState({ enterpriseList: data });
  }

  fetchData = async () => {
    this.setState({ loadingTable: true });
    const data = await this.props.getListaUsuarios();
    if (data) {
      this.setState({ ...{ loadingTable: false, usersList: data, usersListRender: this.filtrarData(data, this.state.filtroListado) } });
    } else {
      this.setState({ ...{ loadingTable: false } });
    }
  };

  async editUser() {
    this.setState({
      ...{ stateLoadNewUser: true }
    });
    if (this.state.usuario) {
      this.state.usuario.token = this.state.token;
      const usuario = this.state.usuario;
      usuario.empresas = usuario.empresas.filter(d => Number(d.deleted) === 0);
      const data = await this.props.editUsuario(usuario);
      this.clearStates(data);
    }
  }

  async changeStatusUser() {
    this.setState({
      ...{ stateLoadNewUser: true }
    });
    if (this.state.usuario) {
      const data = await this.props.changeStatusUsuario({
        id: this.state.usuario.userId,
        status: !this.state.usuario.enable,
        token: this.state.token
      });
      this.clearStates(data);
    }
  }

  getSuggestions = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    let value = e.currentTarget.value;
    const dataReturn = this.filtrarData(this.state.usersList, value);
    this.setState({ usersListRender: dataReturn, filtroListado: value });
  };
  filtrarData = (data: Dto.User[], filtro: string): Dto.User[] => {
    const inputValue = deburr(filtro.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let dataReturn = inputLength === 0
      ? data
      : data.filter(suggestion => {
        let _name = suggestion.completeName.toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0;
        let _rutEnterprise = suggestion.completeRut.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        let _nameEnterprise = suggestion.empresas.reduce((ac, it) => ac + ' ' + it.socialReason.toLowerCase(), '')
                                        .indexOf(inputValue.toLowerCase()) >= 0;
        return _name || _rutEnterprise || _nameEnterprise;
      });
    return dataReturn;
  };

  handleCloseModal = () => {
    this.setState({ openModal1: false, openModal2: false, openModal3: false, openModalChangeStatus: false },
      () => {
        if (document.activeElement != document.body) {
          // @ts-ignore
          document.activeElement.blur();
        }
      });
  };

  openAddUserModal = () => {
    this.setState({
      openModal1: true,
      editState: false,
      usuario: iniUser()
    });
  };

  downloadTrackingUsers = () => {
    this.setState({ loadingTable: true });
    return this.props.dispatch(
                 (dispatch: any) => Promise.all([dispatch(downloadTrackingUsersAction())])
               )
               .then((data: any) => {
                 if (data && data[0].status === 200) {
                   this.setState({
                     loadingTable: false
                   });
                   this.exportExcel(data[0].data);
                 } else {
                   this.setState({ errorGenerated: true, loadingTable: false });
                 }

                 this.setState({ ...{ loadingTable: false } });
               })
               .catch((e: any) => {
               });
  };
  exportExcel = (data: Comun.Dto.Tracking[]) => {
    let workbook = xlsx.utils.book_new();
    let formatted = [];

    formatted = data.map(d => {
      let formattedOrder = {
        'RUT EMPRESA': d.rutEmpresa,
        'EVENTO': d.evento,
        'FECHA EVENTO': Moment(d.fecha)
          .format('DD/MM/YYYY'),
        'HORA EVENTO': d.hora.split('.')[0],
        'NÚMERO OPERACIÓN': d.numeroOperacion,
        'MONTO OPERACIÓN': d.montoOperacion,
        'CANTIDAD DOCS': d.cantidadDocs,
        'TIPO DOCUMENTO': d.tipoDocumento
      };
      return formattedOrder;
    });

    let orderSheet = xlsx.utils.json_to_sheet(formatted);

    orderSheet['!cols'] = [
      { wpx: 250 }, //column 1
      { wpx: 110 }, //column 2
      { wpx: 100 }, //column 3
      { wpx: 130 },
      { wpx: 88 }
    ];
    xlsx.utils.book_append_sheet(workbook, orderSheet, 'Seguimiento Usuarios');
    xlsx.writeFile(workbook, `Seguimiento Usuarios - ${Moment()
      .format('YYYY-MM-DD-hh-mm')}.xlsx`);

  };
  openEditUserModal = (row: Dto.User) => {
    this.setState({
      openModal1: true,
      editState: true,
      usuario: row
    });
  };

  changeStatusUserModal = (row: Dto.User) => {
    this.setState({
      openModalChangeStatus: true,
      usuario: row,
      editState: true
    });
  };

  crearUsuario() {
    this.setState({
      stateLoadNewUser: true
    });

    if (this.state.usuario) {
      const usuario: Dto.CreateUser = {
        entId: undefined,// this.state.enterpriseSelected || 0,
        dv: this.state.usuario.completeRut.split('-')[1],
        firstName: this.state.usuario.firstName,
        lastName: this.state.usuario.lastName,
        token: this.state.token,
        mail: this.state.usuario.mail,
        phoneNumber: this.state.usuario.phoneNumber,
        rut: Number(this.state.usuario.completeRut.split('-')[0]),
        tipoPerfilEmpresa: undefined,// 1,
        producto: undefined//1
      };
      return this.props.crearUsuario(usuario).then(data => {
        this.clearStates(data);
      });
    }
  }

  clearStates = (s: boolean) => {
    this.setState({
      stateLoadNewUser: false,
      openModal1: false,
      openModal2: s,
      openModal3: !s,
      usuario: undefined,
      token: '',
      filtroEmpresas: '',
      openModalChangeStatus: false
    }, () => {
      // @ts-ignore
      this.refs.facturaTable.fireFetchData();
    });
  };

  agregarEmpresa = () => {
    const usr = this.state.usuario;
    if (usr) {
      // @ts-ignore
      usr.empresas.push({
        deleted: 0,
        rut: 0,
        dv: '',
        completeEntRut: '',
        socialReason: '',
        address: '',
        phone: '',
        executiveType: 1,
        executiveTypeDescription: '',
        enable: 1,
        product: 1,
        productDescription: '',
        entId: 0,
        userEntId: 0
      });
      this.setState({ ...this.state, usuario: { ...usr } });
    }
  };

  onChangeUsuario = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    const usr = this.state.usuario;
    if (usr) {
      this.setState({ ...this.state, usuario: { ...usr, [e.target.name]: e.target.value } });
    }
  };

  onChangeEmpresaUsuario = (idx: number) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    const usr = this.state.usuario;
    if (usr) {
      if (e.target.name === 'entId') {
        const emp = this.state.enterpriseList.find(e2 => e2.enterpriseId.toString() === e.target.value);
        const razonSocial = emp === undefined ? '' : emp.socialReason;
        // @ts-ignore
        usr.empresas[idx] = { ...usr.empresas[idx], [e.target.name]: e.target.value, socialReason: razonSocial };
      } else {
        // @ts-ignore
        usr.empresas[idx] = { ...usr.empresas[idx], [e.target.name]: e.target.value };
      }
      this.setState({ ...this.state, usuario: { ...usr } });
    }
  };

  onChangeRoles = (perfil: number) => (e: any) => {
    const usr = this.state.usuario;
    if (usr) {
      // @ts-ignore
      usr.perfiles = usr.perfiles.filter(d => d === perfil).length > 0 ? usr.perfiles.filter(d => d !== perfil) : usr.perfiles.concat(perfil);
      this.setState({ ...this.state, usuario: { ...usr } });
    }
  };

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRut = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ ...this.state, usuario: { ...this.state.usuario, [e.target.name]: formatRut(e) } });
  };

  checkInputFilter = () => {
    return !(this.state.usuario !== undefined &&
      this.state.usuario.firstName.length >= 2 &&
      this.state.usuario.lastName.length >= 2 &&
      this.state.usuario.completeRut.length > 4 &&
      this.state.usuario.mail.length > 4 &&
      this.state.token.length);
  };


  addEnterpriseModal = () => {
    const tieneExterno = this.state.usuario && this.state.usuario.perfiles.filter(d => d === 1).length > 0 || false;
    const tieneInterno = this.state.usuario && this.state.usuario.perfiles.filter(d => d === 2).length > 0 || false;
    const tieneMarketing = this.state.usuario && this.state.usuario.perfiles.filter(d => d === 3).length > 0 || false;
    const tieneAdmin = this.state.usuario && this.state.usuario.perfiles.filter(d => d === 4).length > 0 || false;
    const tieneRegistro = this.state.usuario && this.state.usuario.perfiles.filter(d => d === 5).length > 0 || false;
    const permisos: boolean[] = [false];
    permisos.push(tieneExterno);
    permisos.push(tieneInterno);
    permisos.push(tieneMarketing);
    permisos.push(tieneAdmin);
    permisos.push(tieneRegistro);

    return <div>
      {this.state.usuario && <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModal1 || false}
      >
        <div className={this.props.classes.modalPaper} style={{ height: 'auto' }}>
          {this.state.stateLoadNewUser ? <Loader /> : ''}
          <Grid container>
            <Grid item xs={12}>
              <div className={this.props.classes.modalContent}>
                <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'
                            style={{ lineHeight: 'normal' }}>
                  {this.state.editState ? 'Editar usuario' : 'Crear nuevo usuario'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='firstName'
                name='firstName'
                value={this.state.usuario.firstName}
                label='Nombre Usuario'
                placeholder='Nombre Usuario'
                onChange={this.onChangeUsuario}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='lastName'
                name='lastName'
                value={this.state.usuario.lastName}
                label='Apellido Usuario'
                placeholder='Apellido Usuario'
                onChange={this.onChangeUsuario}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='completeRut'
                name='completeRut'
                value={this.state.usuario.completeRut}
                label='Rut Usuario'
                placeholder='Rut Usuario'
                onChange={this.onChangeRut}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: this.state.editState,
                  disabled: this.state.editState
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='mail'
                name='mail'
                value={this.state.usuario.mail}
                label='Email'
                placeholder='Email'
                onChange={this.onChangeUsuario}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='phoneNumber'
                name='phoneNumber'
                value={this.state.usuario.phoneNumber}
                label='Teléfono'
                placeholder='Teléfono'
                onChange={this.onChangeUsuario}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <FormControl>
                <FormHelperText>Roles públicos</FormHelperText>
                <Grid container style={{ marginLeft: 14, marginTop: 10 }}>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='externo'
                        checked={permisos[1]}
                        onChange={this.onChangeRoles(1)}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Cliente'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='interno'
                        checked={permisos[2]}
                        onChange={this.onChangeRoles(2)}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Ejecutivo o asistente'
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='marketing'
                        checked={permisos[3]}
                        onChange={this.onChangeRoles(3)}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Marketing'
                    /></Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <FormControl>
                <FormHelperText>Roles admin</FormHelperText>
                <Grid container style={{ marginLeft: 14, marginTop: 10 }}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='admin'
                        checked={permisos[4]}
                        onChange={this.onChangeRoles(4)}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Admin usuarios y empresas'
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='registroCivil'
                        checked={permisos[5]}
                        onChange={this.onChangeRoles(5)}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Admin registro civil'
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
              <FormControl style={{ width: '100%' }}>
                <FormHelperText>En empresas (filtrar)</FormHelperText>
                <TextField
                  className={this.props.classes.filterFormtextField}
                  placeholder='filtro'
                  name='filtroEmpresas'
                  onChange={this.onChange}
                  InputLabelProps={{ shrink: true }}
                />
                <Grid container style={{ overflow: 'auto', maxHeight: 300 }}>
                  <Grid item xs={6}>Razón social</Grid>
                  <Grid item xs={2}>Perfil</Grid>
                  <Grid item xs={2}>Producto</Grid>
                  <Grid item xs={1}>Habilitado</Grid>
                  <Grid item xs={1}>Elim</Grid>

                  {this.state.usuario.empresas.map((emp, idx) =>
                    (emp.deleted || (emp.socialReason.toLowerCase().indexOf(this.state.filtroEmpresas.toLowerCase()) === -1 && emp.entId.toString() !== '0')) ? <></> : //no se debe filtrar para que el idx se mantenga post deletes
                      <>
                        <Grid item xs={6}>
                          <Select
                            value={emp.entId.toString()}
                            onChange={this.onChangeEmpresaUsuario(idx)}
                            inputProps={{
                              name: 'entId',
                              id: 'entId'
                            }}
                            style={{ width: '100%' }}
                          >{(emp.entId.toString() === '0' ?
                            this.state.enterpriseList.filter(e => e.socialReason.toLowerCase().indexOf(this.state.filtroEmpresas.toLowerCase()) >= 0) :
                            this.state.enterpriseList).map((e: Dto.EnterpriseProfile, i: number) =>
                            <MenuItem key={i} value={e.enterpriseId.toString()}>{e.socialReason} ({e.completeRut})</MenuItem>)}
                          </Select>
                        </Grid>
                        <Grid item xs={2}>
                          <Select
                            value={emp.executiveType.toString()}
                            onChange={this.onChangeEmpresaUsuario(idx)}
                            inputProps={{
                              name: 'executiveType',
                              id: 'executiveType'
                            }}
                            style={{ width: '100%' }}
                          >
                            {this.state.profilesList.map((e: IItem, i: number) => <MenuItem key={i} value={e.id.toString()}>{e.text}</MenuItem>)}
                          </Select>
                        </Grid>
                        <Grid item xs={2}>
                          <Select
                            value={emp.product.toString()}
                            onChange={this.onChangeEmpresaUsuario(idx)}
                            inputProps={{
                              name: 'product',
                              id: 'product'
                            }}
                            style={{ width: '100%' }}
                          >
                            {this.state.productsList.map((e: IItem, i: number) => <MenuItem key={i} value={e.id.toString()}>{e.text}</MenuItem>)}
                          </Select>
                        </Grid>
                        <Grid item xs={1}>
                          <Select
                            value={emp.enable.toString()}
                            onChange={this.onChangeEmpresaUsuario(idx)}
                            inputProps={{
                              name: 'enable',
                              id: 'enable'
                            }}
                            style={{ width: '100%' }}
                          >
                            {this.state.habilitado.map((e: IItem, i: number) => <MenuItem key={i} value={e.id.toString()}>{e.text}</MenuItem>)}
                          </Select></Grid>
                        <Grid item xs={1}>
                          <IconButton aria-label='Delete' onClick={() => this.onChangeEmpresaUsuario(idx)({ target: { name: 'deleted', value: '1' } } as any)}>
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        </Grid>
                      </>
                  )}
                </Grid>
                <Grid item xs={5} />
                <Grid item xs={7}><Button variant='contained' size='small' color='primary' onClick={this.agregarEmpresa}
                                          style={{ position: 'relative', top: 5 }}> Agregar </Button></Grid>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='token'
                name='token'
                value={this.state.token}
                label='Token'
                placeholder='Token'
                onChange={this.onChange}
                autoComplete='none'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

          </Grid>

          <div className={this.props.classes.modalFooter} style={{ display: 'flex', marginTop: '2rem' }}>
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => this.handleCloseModal()}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.state.editState ? this.editUser() : this.crearUsuario()}
              disabled={this.checkInputFilter()}
            >
              {this.state.editState ? 'Editar usuario' : 'Crear usuario'}
            </Button>

          </div>
        </div>
      </Modal>}
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModal2 || false}
        onClose={() => {
          this.setState({ editState: false });
        }}
      >
        <div className={this.props.classes.modalPaper}>
          <div className={this.props.classes.modalHeader}>
            <SVGIcons icon='done' />
          </div>
          <div className={this.props.classes.modalContent}>
            <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'>
              {this.state.editState ? 'Usuario editado con éxito' : '¡Usuario editado con éxito!'}
            </Typography>
          </div>
          <div className={this.props.classes.modalFooter}>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.handleCloseModal()}>
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModal3 || false}
      >
        <div className={this.props.classes.modalPaper}>
          <div className={this.props.classes.modalHeader}>
            <SVGIcons icon='error_outline' />
          </div>
          <div className={this.props.classes.modalContent}>
            <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'
                        style={{ lineHeight: 'normal' }}>
              Ocurrió un problema.
            </Typography>
            <Typography className={this.props.classes.modalContentSubTitle} variant='subtitle1'
                        id='simple-modal-description'>
              Estimado Cliente, intentar mas tarde.<br />
              Si el problema persisite, porfavor comunicarse con equipo Factotal.
            </Typography>
          </div>
          <div className={this.props.classes.modalFooter}>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.handleCloseModal()}>
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>
      {this.state.usuario && <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModalChangeStatus}
      >
        <div className={this.props.classes.modalPaper} style={{ height: 350 }}>
          {this.state.stateLoadNewUser ? <Loader /> : ''}
          <Grid container>
            <Grid item xs={12}>
              <div className={this.props.classes.modalContent}>
                <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'
                            style={{ lineHeight: 'normal' }}>
                  {this.state.usuario.enable ? 'Deshabilitar Usuario' : 'Habilitar Usuario'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='token'
                name='token'
                value={this.state.token}
                label='Token'
                placeholder='Token'
                onChange={this.onChange}
                autoComplete='none'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <div className={this.props.classes.modalFooter} style={{ display: 'flex' }}>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.handleCloseModal()}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.changeStatusUser()}
              disabled={this.state.token.length < 5}
            >
              {this.state.usuario.enable ? 'Deshabilitar' : 'Habilitar'}
            </Button>
          </div>
        </div>
      </Modal>}
    </div>;
  };

  render() {
    const { classes } = this.props;
    const { errorGenerated } = this.state;
    const tableProps = (params: any) => ({
      columns: params.columns,
      defaultPageSize: 10,
      showPaginationBottom: true,
      PaginationComponent: PaginationBase,
      previousText: 'Anterior',
      nextText: 'Siguiente',
      noDataText: 'Utilice la opción buscar usuarios',
      pageText: 'Pagina',
      ofText: 'de',
      rowsText: 'filas',
      resizable: false,
      // defaultFilterMethod: this.defaultFilterMethod,
      filterable: true,
      showFilters: false,
      loadingText: <Loader loadTable />,
      manual: false,
      data: params.data,
      ref: params.nameTable,
      // onFetchData: (state: any) => this.fetchData(),
      loading: params.loading
    });
    let columns = [
      {
        Header: 'ID User',
        accessor: 'userId',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'Nombre usuario',
        accessor: 'completeName',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'Rut Usuario',
        accessor: 'completeRut',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'Email Usuario',
        accessor: 'mail',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'Teléfono Usuario',
        accessor: 'phoneNumber',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'Empresa (perfil, producto)',
        accessor: 'entSocialReason',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.User>) =>
          row.original.empresas == null || row.original.empresas.length === 0 ?
            <></> :
            <div style={{ width: '100%', textAlign: 'center' }}>
              {row.original.empresas.filter(e => e.executiveTypeDescription === 'Ejecutivo' || e.executiveTypeDescription === 'Empresa').map((e, idx) =>
                <Typography key={idx} component='h4' color={e.enable ? 'primary' : 'error'}>
                  {e.completeEntRut}{' '}{e.socialReason}{' ('}{e.executiveTypeDescription}{', '}{e.productDescription}{')'}<br /></Typography>)}
            </div>
      },
      {
        Header: 'Estado',
        accessor: 'status',
        headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.User>) =>
          <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography component='h3' color={row.original.enable ? 'primary' : 'error'}>
              {row.original.enable ? 'Habilitado' : 'Deshabilitado'}
            </Typography>
          </div>
      },
      {
        Header: 'Funciones',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row: IRowRecord<Dto.User>) =>
          row.original.enable ?
            (<div>
              <Tooltip title='Deshabilitar' aria-label='Deshabilitar'>
                <Button size='small' className={classes.disableUser}
                        onClick={() => this.changeStatusUserModal(row.original)}>
                  <HighlightOff />
                </Button>
              </Tooltip>
              <Tooltip title='Editar' aria-label='Editar'>
                <Button size='small' className={classes.deleteUser}
                        onClick={() => this.openEditUserModal(row.original)}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </div>)
            :
            (<div>
              <Tooltip title='Habilitar' aria-label='Habilitar'>
                <Button size='small' className={classes.enableUser}
                        onClick={() => this.changeStatusUserModal(row.original)}>
                  <CheckCircle />
                </Button>
              </Tooltip>
              <Tooltip title='Editar' aria-label='Editar' onClick={() => this.openEditUserModal(row.original)}>
                <Button size='small' className={classes.deleteUser}
                        onClick={() => this.openEditUserModal(row.original)}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </div>)
      }
    ];
    return (
      <div>
        {errorGenerated ?
          <ModalComponent
            openModal={errorGenerated}
            title='Ha ocurrido un error'
            text='Estimado Cliente, intentar mas tarde. Si el problema persisite, por favor comunicarse con equipo Factotal.'
            closeModalMsg={() => {
              this.setState({ errorGenerated: false });
            }} /> : ''}
        <Breadcrumb category='Usted se encuentra en: Administración / Usuarios' textAlign='right' />
        <Grid container>

          {this.addEnterpriseModal()}
          <Grid item xs={8}>
            <Button
              variant='outlined'
              color='primary'
              style={{ marginRight: 5 }}
              onClick={() => this.openAddUserModal()}
            >
              Agregar usuario
            </Button>
            <ActualizarUsuarios />
            <Button
              variant='outlined'
              color='primary'
              onClick={() => this.downloadTrackingUsers()}
            >
              Descargar Seguimiento Usuarios
            </Button>
          </Grid>
          <Grid item xs={4}>
            <TextField
              style={{
                width: '100%'
              }}
              placeholder='Buscar usuario'
              id='simple-start-adornment'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' style={{ margin: 0 }}>
                    <IconButton>
                      <SearchIcon style={{ width: 20, height: 20 }} onClick={this.fetchData} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={e => this.getSuggestions(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              style={{ marginTop: 14 }}
              {...tableProps({
                nameTable: 'facturaTable',
                columns: columns,
                loading: this.state.loadingTable,
                data: this.state.usersListRender,
                noTable: 1,
                nomDataTable: 'dataUser'
              })}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.adminUser.isLoading
});
const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  dispatch: dispatch,
  crearUsuario: (usuario: Dto.CreateUser) => dispatch(crearUsuarioAction(usuario)),
  editUsuario: (usuario: Dto.User) => dispatch(editUsuarioAction(usuario)),
  changeStatusUsuario: (payload: any) => dispatch(changeStatusUsuarioAction(payload)),
  getListaUsuarios: () => dispatch(getListaUsuariosAction()),
  getDatosEmpresas: () => dispatch(getDatosEmpresas())

});

export default withStyles(Style)(connect(mapStateToProps, mapDispatchToProps)(AdminUser));

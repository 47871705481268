import fxActions from './actions';
import { AnyAction } from 'redux';

export interface IFactorXState {
  empresa: Dto.Portal.Empresa;
  usuario: Dto.Usuario;
  global: {
    bancos: Dto.Banco[],
    tipoCuentas: Dto.Portal.TipoCuenta[],
    comunas: Dto.Comuna[],
    proveedoresFacturacion: Dto.Portal.ProveedoresFacturacion[]
  },
}

const factorx: IFactorXState = ({
  empresa: {
    certificados: [],
    formasPago: [],
    sincronizacion: [],
    sincronizacionDteCorrecta: false,
    defecto: false,
    direccion: '',
    giro: '',
    id: 0,
    idComuna: 0,
    idEmpresaPais: {
      idPais: 0,
      valor: ''
    },
    locale: 'es',
    movil: '',
    nombreComuna: '',
    razonSocial: '',
    sincronizacionAecCorrecta: false,
    sincronizacionCteCorrecta: false,
    sincronizacionPfiCorrecta: false,
    sincronizacionTgrCorrecta: false,
    telefono: '',
    contratos: [],
    tieneCalendarioPago: false
  },
  usuario: {
    correoElectronico: '',
    esDeSistema: false,
    habilitado: false,
    id: 0,
    identificador: '',
    nombre: '',
    nuevaContrasena: '',
    puedeModificarContrasenaUsuario: false,
    telefono: '',
    movil: '',
    fechaUltimoCambioContrasena: '',
    empresaBloqueada: false
  },
  global: {
    bancos: [],
    tipoCuentas: [],
    comunas: [],
    proveedoresFacturacion: []
  }
});

export const reducer = (state = factorx, action: AnyAction) => {
  switch (action.type) {
    case fxActions.obtenerEmpresaUsuarioAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.obtenerEmpresaUsuarioAction.SUCCESS: {
      return Object.assign({}, state, { empresa: action.empresa, usuario: action.usuario });
    }

    case fxActions.subirCertificadoAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.subirCertificadoAction.SUCCESS: {
      return Object.assign({}, state, {});
    }

    case fxActions.obtenerCertificadoAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.obtenerCertificadoAction.SUCCESS: {
      const empresa = Object.assign({}, state.empresa, { certificados: action.certificados });
      return Object.assign({}, state, { empresa: empresa });
    }

    case fxActions.eliminarCertificadoAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.eliminarCertificadoAction.SUCCESS: {
      if (action.id === -1) return Object.assign({}, state, action);
      const certificados = state.empresa.certificados.filter(i => i.id != action.id);

      const empresa = Object.assign({}, state.empresa, { certificados: certificados });
      return Object.assign({}, state, { empresa: empresa });
    }

    case fxActions.actualizarCertificadoAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.actualizarCertificadoAction.SUCCESS: {
      const certificados = state.empresa.certificados;
      const idxCertificado = certificados.findIndex(i => i.id == action.payload.id);
      if (action.payload.id === -1 || idxCertificado === -1) return Object.assign({}, state, {});

      const certificado = certificados[idxCertificado];
      certificado.paraCeder = action.payload.paraCeder;
      certificado.paraContrato = action.payload.paraContrato;
      certificados[idxCertificado] = certificado;

      const empresa = Object.assign({}, state.empresa, { certificados: certificados });
      return Object.assign({}, state, { empresa: empresa });
    }

    case fxActions.cargarDatosGlobalAction.SUCCESS: {
      return Object.assign({}, state, { global: action.payload });
    }

    case fxActions.eliminarCuentaCorrienteAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.eliminarCuentaCorrienteAction.SUCCESS: {
      if (action.id === -1) return Object.assign({}, state, action);
      const cuentasCorrientes = state.empresa.formasPago.filter(i => i.id != action.id);

      const empresa = Object.assign({}, state.empresa, { cuentasCorrientes: cuentasCorrientes });
      return Object.assign({}, state, { empresa: empresa });
    }

    case fxActions.crearCuentaCorrienteAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.crearCuentaCorrienteAction.SUCCESS: {
      const empresa = Object.assign({}, state.empresa, { cuentasCorrientes: action.payload.cuentasCorrientes });
      return Object.assign({}, state, { empresa: empresa });
    }

    case fxActions.actualizarCuentaCorrienteAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case fxActions.actualizarCuentaCorrienteAction.SUCCESS: {
      const empresa = Object.assign({}, state.empresa, { cuentasCorrientes: action.payload.cuentasCorrientes });
      return Object.assign({}, state, { empresa: empresa });
    }


    default:
      return state;
  }
};

export default reducer;

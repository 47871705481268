// External
import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  EventTrackingExcel,
  FilterExcedentesHistorico,
  GetExcel,
  GetIndividualPDF,
  GetPDF,
  TrackingSidebar
} from './actions';
import xlsx from 'xlsx';
import ReactTable from 'react-table';
import Moment from 'moment/moment';

// componentes material ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// componentes custom
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../../shared/Grid/GridItem';
import GridContainer from '../../../shared/Grid/GridContainer';
import NavPills from '../../../shared/NavPills/NavPills';
import SVGIcons from '../../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../../shared/RangeCalendar/RangeCalendar';
import Loader from '../../../shared/Loader/Loader';
import {formatNumber} from '../../../../helpers/FormatNumber';
import {onChangeOnlyNumericHelper} from '../../../../helpers/ValidateNumeric';
import PaginationBase from '../../../shared/Pagination/PaginationBase';
import PDFViewer from '../../../general/pdfViewer/PDFViewer';
import PDFJSBackend from '../../../../back/pdfjs';
// Importación de estilo para tabla
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';

const divSinPdf = (
  <div style={{
    height: 40,
    color: '#A9ACAD',
    textAlign: 'center',
    border: '5px dashed #E5EAD9',
    marginTop: 110,
    padding: 40,
    fontSize: '.86rem'
  }}>
    <span style={{width: '100%', float: 'left'}}>Seleccione ver detalle</span>
    <span style={{width: '100%', float: 'left'}}>para visualizar aquí</span>
    <span style={{width: '100%', float: 'left'}}>su Factura.</span>
  </div>
);

class ExcedentesHistorico extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      isPageOpSelected: false,
      pageAnteriorVisitada: 0,
      pageOpSelected: 0,
      pageActual: 0,
      idSeleccion: null,
      numLiquidacion: '',
      expanded: true,
      urlImg: null,
      openCalendar: false,
      anchorElCalendar: null,
      calendarValue: {
        desde: '',
        hasta: ''
      },
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      },
      statusAction: false,
      loading: true,
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      data: props.rows,
      dataFactura: props.rows,
      dataPagare: props.rows,
      dataLetra: props.rows,
      dataCheque: props.rows,
      tipoDocumento: '',
      activeTab: 1,
      namePdf: ''
    };
  }

  onChangeTab = (active) => this.setState((state) => ({activeTab: active}));

  getFilters(page, pageSize, tipoDocumento, sorted) {
    return {
      numeroLiquidacion: this.state.numLiquidacion,
      fechaVencimientoDesde: (this.state.calendarValue.desde) ? Moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      fechaVencimientoHasta: (this.state.calendarValue.hasta) ? Moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      page: page,
      pageSize: pageSize,
      tipoDocumento: tipoDocumento,
      ascending: sorted && sorted[0] && sorted[0].desc ? false : sorted && sorted[0] ? true : null,
      orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : ''
    };
  }

  getSortIdColumn(column) {
    let _id = null;
    switch (column) {
      case 'numLiquidacion':
        _id = 1;
        break;
      case 'fechaLiquidacion':
        _id = 2;
        break;
      case 'Retencion':
        _id = 3;
        break;
      case 'MontoDocumento':
        _id = 4;
        break;
      case 'MontoAmortizado':
        _id = 5;
        break;
      case 'MontoPagoAtrasado':
        _id = 6;
        break;
      case 'OtrosGastos':
        _id = 7;
        break;
      case 'MontoPrenomina':
        _id = 8;
        break;
      case 'montoTotalGirar':
        _id = 9;
        break;
      case 'NombreBeneficiario':
        _id = 10;
        break;
      case 'RutBeneficiario':
        _id = 11;
        break;
      case 'FormaPago':
        _id = 12;
        break;
      case 'Banco':
        _id = 13;
        break;
      case 'TipoCuenta':
        _id = 14;
        break;
      case 'NumCuentaCorriente':
        _id = 15;
        break;
    }
    return _id;
  }

  _loading = (b, t) => {
    let loadingTable = 'loading' + (t ? t : '');
    let _obj = {};
    _obj[loadingTable] = b;
    return _obj;
  };

  fetchData(state, tipoDocumento, dataProp, dataPagination) {
    let _obj = {};
    _obj[dataProp] = [];
    _obj[dataPagination] = {
      currentPage: 0,
      top: 0,
      totalItems: 0,
      totalPages: 0,
      from: 0,
      to: 0
    };
    this.setState({
      ...this._loading(true, tipoDocumento),
      ...{statusAction: true},
      ..._obj
    });
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(FilterExcedentesHistorico(this.getFilters(state.page, state.pageSize, tipoDocumento, state.sorted)))])
    )
      .then((data) => {
        let _obj = {};
        if (data[0] && data[0].tipoDocumento) {
          _obj[dataProp] = data[0].rows ? data[0].rows : {};
          _obj[dataPagination] = data[0].pagination ? data[0].pagination : {};
        }
        this.setState({...{statusAction: false}, ...this._loading(false, tipoDocumento), ..._obj});
      });
  }

  buttonGetPDF = () => {
    let paginationPage, paginationPageSize;
    switch (this.state.activeTab) {
      case 1:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 2:
        paginationPage = this.state.paginationCheque.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 3:
        paginationPage = this.state.paginationLetra.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 4:
        paginationPage = this.state.paginationPagare.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      default:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
    }
    if (this.state.statusAction === true) return;
    this.setState({...this._loading(true, this.state.activeTab), ...{statusAction: true}});
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetPDF(this.getFilters(paginationPage, paginationPageSize, this.state.activeTab)))])
    ).then(() => {
      this.setState({
        ...{statusAction: false},
        ...this._loading(false, this.state.activeTab)
      });
    });
  };

  buttonGetExcel = (b) => {
    if (this.state.statusAction === true) return;
    this.setState({...{statusAction: true}, ...this._loading(true, this.state.activeTab)});
    let paginationTotalItems;
    switch (this.state.activeTab) {
      case 1:
        paginationTotalItems = this.state.paginationFactura.totalItems;
        break;
      case 2:
        paginationTotalItems = this.state.paginationCheque.totalItems;
        break;
      case 3:
        paginationTotalItems = this.state.paginationLetra.totalItems;
        break;
      case 4:
        paginationTotalItems = this.state.paginationPagare.totalItems;
        break;
      default:
        paginationTotalItems = this.state.paginationFactura.totalItems;
    }
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetExcel(this.getFilters(0, paginationTotalItems, this.state.activeTab)))])
    ).then((data) => {
      this.setState({
        ...{statusAction: false},
        ...this._loading(false, this.state.activeTab)
      }, () => {
        this.exportExcel(data[0]);
      });
      return this.props.dispatch(
        dispatch => Promise.all([dispatch(EventTrackingExcel('excedentesHistoricosExcel'))])
      ).then(data => data);
    });
  };

  exportExcel = (data) => {
    let workbook = xlsx.utils.book_new();
    let formattedData = [];
    if (data.data.length > 0) {
      formattedData = data.data.map(d => {
        let formattedOrder = {
          'N° LIQUIDACIÓN': d.numLiquidacion,
          'FECHA LIQUIDACIÓN': d.fechaLiquidacion ? Moment(d.fechaLiquidacion).format('DD/MM/YYYY') : '',
          'MONTO LIQUIDACIÓN': d.montoTotalGirar
        };
        return formattedOrder;
      });
    }
    let orderSheet = xlsx.utils.json_to_sheet(formattedData);

    orderSheet['!cols'] = [
      {wpx: 100}, //N° Operación
      {wpx: 130}, //Fecha operación
      {wpx: 140}, //Monto operación
      {wpx: 130}, //Monto documento
      {wpx: 110} //Monto girado
    ];

    xlsx.utils.book_append_sheet(workbook, orderSheet, 'FACTURAS');
    xlsx.writeFile(workbook, `Excedentes cursados - ${Moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  handleFiltrar = () => {
    this.refs.facturasTable.state.page = 0;
    this.refs.facturasTable.fireFetchData();

    if (!this.checkInputFilter()) {
      Promise.all([new Promise(TrackingSidebar('excedentesHistoricosBuscar'))])
        .then(data => data);
    }
  };

  handleExpandClick = () => {
    this.setState((state) => ({expanded: !state.expanded}));
  };

  defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  onChangeOnlyNumeric = (e) => {
    this.setState({[e.target.name]: onChangeOnlyNumericHelper(e)});
  };

  handleClickTable = (event, row, id) => {
    const documentPdfId = document.getElementById('renderPDFId');
    let renderPDF = (divSinPdf);
    ReactDOM.render(renderPDF, documentPdfId);
    const {activeTab, pageActual} = this.state;
    this.setState(this._loading(true, activeTab));
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetIndividualPDF({
        numLiquidacion: row
      }))])
    ).then((data) => {
      this.setState(this._loading(false, activeTab));
      if (data && data[0] && data[0].data && data[0].data.documento) {
        renderPDF = (
          <PDFViewer
            ref={this.myViewer}
            backend={PDFJSBackend}
            namePdf={`${row}-Excedentes`}
            base64={data[0].data.documento} />);
        ReactDOM.render(renderPDF, documentPdfId);
      }
    });
  };

  callbackcambioPagina(pageIndex) {
    this.setState({pageActual: pageIndex});
    for (var i of document.getElementsByClassName('active')) {
      if (i.nodeName === 'svg') i.classList.remove('active');
    }
    if (pageIndex > this.state.pageAnteriorVisitada)
      this.setState({pageAnteriorVisitada: pageIndex}, () => {
        if (this.state.isPageOpSelected == true && pageIndex == this.state.pageOpSelected && document.getElementById(this.state.idSeleccion))
          document.getElementById(this.state.idSeleccion).classList.add('active');
      });
    else
      this.setState({pageAnteriorVisitada: pageIndex}, () => {
        if (this.state.isPageOpSelected == true && pageIndex == this.state.pageOpSelected && document.getElementById(this.state.idSeleccion))
          document.getElementById(this.state.idSeleccion).classList.add('active');
      });
  }

  handleStatesCalendar = params => {
    this.setState(params);
  };

  cleanFilters() {
    this.setState({
      numLiquidacion: '',
      calendarValue: {
        desde: '',
        hasta: ''
      }
    }, () => {
      this.handleFiltrar();
    });
  }

  checkInputFilter = () => {
    return this.state.calendarValue.desde ||
    this.state.calendarValue.hasta ||
    this.state.numLiquidacion.length > 0 ? false : true;
  };

  render() {
    const {classes} = this.props;
    const {
      anchorEl, openCalendar, anchorElCalendar, calendarValue, dataFactura, dataCheque,
      dataPagare, dataLetra
    } = this.state;
    const open = Boolean(anchorEl);
    const columnsTablePopUp = [
      {
        Header: 'Fecha',
        headerClassName: classes.HeaderTable,
        accessor: 'date',
        width: 100,
        id: 'date',
        className: classes.TDTable
      },
      {
        Header: 'Comentario',
        headerClassName: classes.HeaderTable,
        accessor: 'comment',
        width: 300,
        id: 'comment',
        className: classes.TDTable,
        Cell: ((row) => {
          let text = row && row.original && row.original.comment ? row.original.comment : '';
          return <div style={{whiteSpace: 'normal'}}>{text}</div>;
        })
      }
    ];

    const columns = [
      {
        Header: 'N° LIQUIDACIÓN',
        accessor: 'numLiquidacion',
        headerClassName: classes.HeaderTable,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableNoOperacion,
        id: 'numLiquidacion'
        // filterable: false,
      },
      {
        Header: 'FECHA LIQUIDACIÓN',
        accessor: 'fechaLiquidacion',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        //width: 80,
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row) => <div>
          {row.original && row.original.fechaLiquidacion ? Moment(row.original.fechaLiquidacion).format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        Header: 'MONTO LIQUIDACIÓN',
        accessor: 'montoTotalGirar',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value)
      },

      /*{
        Header: "BANCO DESTINO DE FONDOS",
        accessor: "banco",
        headerClassName: classes.HeaderTable,
        //width: "100%",
        className: classes.TDTable,
      },*/
      {
        Header: 'LIQUIDACIÓN',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign,
        Cell: ((row) => {
          //row.pageIndex
          return <div>
            <div
              className={classes.TDTableCenterIcon}
              //aria-haspopup="true"
              onClick={(event) => this.handleClickTable(event, row.original.numLiquidacion)}
            >
              <SearchIcon className={classes.TDTableCenterIcon} id={`fila${row.original.numLiquidacion}`} />
            </div>
          </div>;
        })
      }
    ];

    const tableProps = (params) => {
      return ({
        columns: params.columns,
        defaultPageSize: 10,
        minRows: params.totalItemsPerPage && params.totalItemsPerPage <= params.totalItems ? params.totalItemsPerPage : 10,
        showPaginationBottom: params.totalItemsPerPage === params.totalItems ? false : true,
        PaginationComponent: PaginationBase,
        previousText: 'Anterior',
        nextText: 'Siguiente',
        noDataText: 'Sin información',
        pageText: 'Pagina',
        ofText: 'de',
        rowsText: 'filas',
        resizable: false,
        defaultFilterMethod: this.defaultFilterMethod,
        filterable: true,
        showFilters: false,
        loadingText: <Loader loadTable />,
        manual: true,
        data: params.data,
        ref: params.nameTable,
        onFetchData: (state) => this.fetchData(state, params.noTable, params.nomDataTable, params.paginationTable),
        pages: params.pages,
        totalItems: params.totalItems,
        loading: params.loading
      });
    };
    return (
      <div>
        <div>
          <Breadcrumb category="Usted se encuentra en: Factoring / Excedentes / Cursados" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Excedentes Cursados
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h5" className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color="primary" variant="outlined" onClick={() => this.cleanFilters()}
                            style={{marginLeft: 14}}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{shrink: true}}
                          id="numLiq"
                          name="numLiquidacion"
                          value={this.state.numLiquidacion}
                          label="N&deg; Liquidación"
                          placeholder="000000"
                          onChange={this.onChangeOnlyNumeric}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id="Fch"
                            name="Fch"
                            label="Fecha"
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      {/*<GridItem xs={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="idBanco"
                          label="Banco"                          
                          placeholder="Seleccione"                          
                        />
              </GridItem>*/}
                      <GridItem className={classes.filterFormButtonContainer}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleFiltrar}
                            disabled={this.checkInputFilter()}
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={7} style={{position: 'relative'}}>
            {(dataFactura.length > 0 || dataCheque.length > 0 || dataLetra.length > 0 || dataPagare.length > 0) &&
            <GridItem className={classes.gridPDFExcelButtons}>
              <Tooltip title="Descargar PDF" placement="top">
                <IconButton onClick={() => this.buttonGetPDF()}>
                  <SVGIcons id="ExHIconDescargarPdf" icon="pdf" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descargar Planilla Excel" placement="top">
                <IconButton onClick={() => this.buttonGetExcel()}>
                  <SVGIcons id="ExHIconDescargarExcel" icon="excel" />
                </IconButton>
              </Tooltip>
            </GridItem>}
            <NavPills
              color="info"
              tabs={[
                {
                  tabButton: 'LIQUIDACIONES',
                  hidden: true,
                  tabContent: (
                    <ReactTable
                      {...tableProps({
                        nameTable: 'facturasTable',
                        columns: columns,
                        loading: this.state.loading1,
                        data: this.state.dataFactura,
                        noTable: 1,
                        nomDataTable: 'dataFactura',
                        paginationTable: 'paginationFactura',
                        pages: this.props.paginationFactura.totalPages,
                        totalItems: this.props.paginationFactura.totalItems,
                        totalItemsPerPage: this.props.paginationFactura.top
                      })}
                      onPageChange={(pageIndex) => this.callbackcambioPagina(pageIndex)}
                    />
                  )
                }
              ]}
            />

          </GridItem>
          <GridItem xs={5} style={{padding: 8}}>
            <Card style={{height: '100%', display: 'flex', alignItems: 'center', minHeight: 400}}>
              <CardContent id="renderPDFId" className={classes.facEmitPDFContainer}>
                {divSinPdf}
              </CardContent>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading,
  rows: state.excedentesHistorico.rows,
  pagination: state.excedentesHistorico.pagination,
  pageSize: state.excedentesHistorico.pageSize,
  tipoDocumento: state.excedentesHistorico.tipoDocumento,
  paginationLetra: state.excedentesHistorico.pagination,
  paginationFactura: state.excedentesHistorico.pagination,
  paginationCheque: state.excedentesHistorico.pagination,
  paginationPagare: state.excedentesHistorico.pagination
});

ExcedentesHistorico.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired
};

export default withStyles(HistoricoStyle)(connect(mapStateToProps)(ExcedentesHistorico));

// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ReactTable from 'react-table';
import deburr from 'lodash/deburr';

// componentes material ui
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import HighlightOff from '@material-ui/icons/HighlightOff';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';

// componentes custom
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import PaginationBase from '../../shared/Pagination/PaginationBase';
import Loader from '../../shared/Loader/Loader';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import { formatRut } from '../../../helpers/RutHelper';

// estilos
import Style from '../../../styles/jss/components/extendedFormsStyle';
import { FormControl, FormHelperText, WithStyles } from '@material-ui/core';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { changeStatusEmpresaAction, crearEmpresaAction, editEmpresaAction, getDatosEmpresas } from './actions';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface IRecord<T> {
  _original: T;
}

interface IRowRecord<T> {
  original: T;
}

interface DispatchProps {
  getDatosEmpresas: () => Promise<Dto.EnterpriseProfile[]>;
  editEmpresa: (params: Dto.EditEnterprise) => Promise<boolean>;
  changeStatusEmpresa: (params: Dto.ChangeStatusEnterprise) => Promise<boolean>;
  crearEmpresa: (params: any) => Promise<boolean>;
}

interface IProps extends DispatchProps, WithStyles<typeof Style> {
}

interface IState {
  companyList: Dto.EnterpriseProfile[];
  companyListRender: Dto.EnterpriseProfile[];
  loading: boolean;
  loadingTable: boolean;
  openModal1: boolean;
  openModal2: boolean;
  openModal3: boolean;
  stateLoadNewEnterprise: boolean;
  rutEmpresa: string;
  razonSocial: string;
  telefonoEmpresa: string;
  direccionEmpresa: string;
  enterpriseId: string | null;
  token: string;
  enableEnterprise: boolean;
  enableFactoring: boolean;
  enableLeasing: boolean;
  enableVendor: boolean;
  enableRegistroCivil: boolean;
  editState: boolean;
  openModalChangeStatus: boolean;
  filtroListado: string;
}

class AdminEnterprise extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      companyList: [],
      companyListRender: [],
      loading: false,
      openModal1: false,
      openModal2: false,
      openModal3: false,
      stateLoadNewEnterprise: false,
      rutEmpresa: '',
      razonSocial: '',
      telefonoEmpresa: '',
      direccionEmpresa: '',
      token: '',
      enterpriseId: null,
      editState: false,
      enableEnterprise: false,
      enableFactoring: false,
      enableLeasing: false,
      enableVendor: false,
      enableRegistroCivil: false,
      openModalChangeStatus: false,
      loadingTable: false,
      filtroListado: ''
    };
    // this.fetchData = this.fetchData.bind(this);
  }

  fetchData = async () => {
    this.setState({ loadingTable: true });
    const data = await this.props.getDatosEmpresas();
    this.setState({ ...{ loadingTable: false, companyList: data, companyListRender: this.filtrarData(data, this.state.filtroListado) } });
  };

  editEnterprise() {
    this.setState({
      ...{ stateLoadNewEnterprise: true }
    });
    return this.props.editEmpresa({
      enterpriseId: Number(this.state.enterpriseId),
      socialReason: this.state.razonSocial,
      token: this.state.token,
      factoringEnabled: this.state.enableFactoring,
      leasingEnabled: this.state.enableLeasing,
      vendorEnabled: this.state.enableVendor,
      registroCivilEnabled: this.state.enableRegistroCivil
    }).then((exito) => {
      this.clearStates(!exito);
    });
  }

  clearStates(error: boolean) {
    this.setState({
      stateLoadNewEnterprise: false,
      openModal1: false,
      openModal2: !error,
      openModal3: error,
      rutEmpresa: '',
      razonSocial: '',
      telefonoEmpresa: '',
      direccionEmpresa: '',
      enableFactoring: false,
      enableLeasing: false,
      enableVendor: false,
      enableRegistroCivil: false,
      enterpriseId: null,
      token: '',
      enableEnterprise: false,
      openModalChangeStatus: false
    }, () => {
      const facturaTable = this.refs.facturaTable as any;
      facturaTable.fireFetchData();
    });
  }

  changeStatusEnterprise() {
    this.setState({
      ...{ stateLoadNewEnterprise: true }
    });
    return this.props.changeStatusEmpresa({
      enterpriseId: Number(this.state.enterpriseId),
      status: !this.state.enableEnterprise,
      token: this.state.token
    }).then((exito) => {
      this.clearStates(!exito);
    });
  }

  getSuggestions = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    let value = e.currentTarget.value;
    const dataReturn = this.filtrarData(this.state.companyList, value);
    this.setState({ companyListRender: dataReturn, filtroListado: value });
  };

  filtrarData = (data: Dto.EnterpriseProfile[], filtro: string): Dto.EnterpriseProfile[] => {
    const inputValue = deburr(filtro.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let dataReturn = inputLength === 0
      ? data
      : data.filter(suggestion => {
        let _socialReason = suggestion.socialReason.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        let _rutEnterprise = suggestion.rut.toString().toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        return _socialReason || _rutEnterprise;
      });
    return dataReturn;
  };

  handleCloseModal = () => {
    this.setState({ openModal1: false, openModal2: false, openModal3: false, openModalChangeStatus: false },
      () => {
        const activeElement = window.document.activeElement as any;
        if (activeElement && activeElement != document.body) activeElement.blur();
      });
  };

  openAddEnterpriseModal = () => {
    this.setState({
      openModal1: true,
      editState: false,
      rutEmpresa: '',
      razonSocial: '',
      telefonoEmpresa: '',
      direccionEmpresa: '',
      enableFactoring: false,
      enableLeasing: false,
      enableVendor: false,
      enableRegistroCivil: false,
      token: '',
      enterpriseId: ''
    });
  };

  openEditEnterpriseModal = (row: Dto.EnterpriseProfile) => {
    this.setState({
      openModal1: true,
      editState: true,
      enterpriseId: row.enterpriseId ? row.enterpriseId.toString() : null,
      rutEmpresa: row.rut.toString() + '-' + row.dv,
      razonSocial: row.socialReason,
      telefonoEmpresa: row.phone,
      direccionEmpresa: row.address,
      enableFactoring: row.factoring,
      enableLeasing: row.leasing,
      enableVendor: row.vendor,
      enableRegistroCivil: row.registroCivil
    });
  };

  changeStatusEnterpriseModal = (row: Dto.EnterpriseProfile) => {
    this.setState({
      openModalChangeStatus: true,
      enableEnterprise: row.enable,
      editState: true,
      enterpriseId: row.enterpriseId ? row.enterpriseId.toString() : null
    });
  };

  crearEmpresa() {
    this.setState({
      ...{ stateLoadNewEnterprise: true }
    });

    return this.props.crearEmpresa({
      rut: this.state.rutEmpresa,
      socialReason: this.state.razonSocial,
      address: this.state.direccionEmpresa,
      phone: this.state.telefonoEmpresa,
      token: this.state.token
    }).then((exito) => {
      this.clearStates(!exito);
    });
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.target.type === 'checkbox') {
      // @ts-ignore
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      // @ts-ignore
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeRut = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: formatRut(e) });
  };

  checkInputFilter = () =>
    !(this.state.rutEmpresa.length > 4 ||
      this.state.razonSocial.length > 4 ||
      this.state.telefonoEmpresa.length > 4 ||
      this.state.direccionEmpresa.length > 4);

  addEnterpriseModal = () => {
    return <div><Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={this.state.openModal1}
    >
      <div className={this.props.classes.modalPaper} style={{ height: 350 + (this.state.editState ? 50 : 0) }}>
        {this.state.stateLoadNewEnterprise ? <Loader /> : ''}
        <Grid container>
          <Grid item xs={12}>
            <div className={this.props.classes.modalContent}>
              <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'
                          style={{ lineHeight: 'normal' }}>
                {this.state.editState ? 'Editar empresa' : 'Crear nueva empresa'}
              </Typography>

            </div>
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15, marginBottom: 15 }}>
            <TextField
              className={this.props.classes.filterFormtextField}
              id='rutEmpresa'
              name='rutEmpresa'
              value={this.state.rutEmpresa}
              label='Rut Empresa'
              placeholder='Rut Empresa'
              onChange={this.onChangeRut}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: this.state.editState,
                disabled: this.state.editState
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
            <TextField
              className={this.props.classes.filterFormtextField}
              id='razonSocial'
              name='razonSocial'
              value={this.state.razonSocial}
              label='Razón Social'
              placeholder='Razón Social'
              onChange={this.onChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
            <TextField
              className={this.props.classes.filterFormtextField}
              id='telefonoEmpresa'
              name='telefonoEmpresa'
              value={this.state.telefonoEmpresa}
              label='Teléfono'
              placeholder='Teléfono'
              onChange={this.onChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: this.state.editState,
                disabled: this.state.editState
              }}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingLeft: 15, paddingRight: 15 }}>
            <TextField
              className={this.props.classes.filterFormtextField}
              id='direccionEmpresa'
              name='direccionEmpresa'
              value={this.state.direccionEmpresa}
              label='Dirección Empresa'
              placeholder='Dirección Empresa'
              onChange={this.onChange}
              InputLabelProps={{ shrink: true }}
              autoComplete='none'
              InputProps={{
                readOnly: this.state.editState,
                disabled: this.state.editState
              }}
            />
          </Grid>
          {this.state.editState ?
            <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15 }}>
              <FormControl style={{ width: '100%' }}>
                <FormHelperText>Productos habilitados</FormHelperText>
                <Grid container style={{ marginLeft: 14, marginTop: 10 }}>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='enableFactoring'
                        checked={this.state.enableFactoring}
                        onChange={this.onChange}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Factoring'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='enableLeasing'
                        checked={this.state.enableLeasing}
                        onChange={this.onChange}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Leasing'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='enableVendor'
                        checked={this.state.enableVendor}
                        onChange={this.onChange}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Vendor (L. tecno)'
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <FormControlLabel
                      control={<Checkbox
                        color='primary'
                        name='enableRegistroCivil'
                        checked={this.state.enableRegistroCivil}
                        onChange={this.onChange}
                        inputProps={{ 'aria-label': 'Selecionar' }}
                      />}
                      label='Registro civil'
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Grid> : null}
          <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
            <TextField
              className={this.props.classes.filterFormtextField}
              id='token'
              name='token'
              value={this.state.token}
              label='Token'
              placeholder='Token'
              onChange={this.onChange}
              autoComplete='none'
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

        </Grid>

        <div className={this.props.classes.modalFooter} style={{ display: 'flex' }}>
          <Button
            variant='contained'
            size='large'
            onClick={this.handleCloseModal}
          >
            Cancelar
          </Button>
          <Button
            variant='contained'
            size='large'
            onClick={() => this.state.editState ? this.editEnterprise() : this.crearEmpresa()}
            disabled={this.checkInputFilter()}
          >
            {this.state.editState ? 'Editar empresa' : 'Crear Empresa'}
          </Button>

        </div>
      </div>
    </Modal>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModal2 || false}
        onClose={() => {
          this.setState({ editState: false });
        }}
      >
        <div className={this.props.classes.modalPaper}>
          <div className={this.props.classes.modalHeader}>
            <SVGIcons icon='done' />
          </div>
          <div className={this.props.classes.modalContent}>
            <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'>
              {this.state.editState ? 'Empresa editada con éxito' : '¡Empresa creada con éxito!'}
            </Typography>
          </div>
          <div className={this.props.classes.modalFooter}>
            <Button
              variant='contained'
              size='large'
              onClick={this.handleCloseModal}>
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModal3 || false}
      >
        <div className={this.props.classes.modalPaper}>
          <div className={this.props.classes.modalHeader}>
            <SVGIcons icon='error_outline' />
          </div>
          <div className={this.props.classes.modalContent}>
            <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'
                        style={{ lineHeight: 'normal' }}>
              Ocurrio un problema.
            </Typography>
            <Typography className={this.props.classes.modalContentSubTitle} variant='subtitle1'
                        id='simple-modal-description'>
              Estimado Cliente, intentar mas tarde.<br />
              Si el problema persisite, porfavor comunicarse con equipo Factotal.
            </Typography>
          </div>
          <div className={this.props.classes.modalFooter}>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.handleCloseModal()}>
              Cerrar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={this.state.openModalChangeStatus}
      >
        <div className={this.props.classes.modalPaper} style={{ height: 350 }}>
          {this.state.stateLoadNewEnterprise ? <Loader /> : ''}
          <Grid container>
            <Grid item xs={12}>
              <div className={this.props.classes.modalContent}>
                <Typography className={this.props.classes.modalContentTitle} variant='h6' id='modal-title'
                            style={{ lineHeight: 'normal' }}>
                  {this.state.enableEnterprise ? 'Deshabilitar Empresa' : 'Habilitar Empresa'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 15, paddingRight: 15, marginTop: 15 }}>
              <TextField
                className={this.props.classes.filterFormtextField}
                id='token'
                name='token'
                value={this.state.token}
                label='Token'
                placeholder='Token'
                onChange={this.onChange}
                autoComplete='none'
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <div className={this.props.classes.modalFooter} style={{ display: 'flex' }}>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.handleCloseModal()}
            >
              Cancelar
            </Button>
            <Button
              variant='contained'
              size='large'
              onClick={() => this.changeStatusEnterprise()}
              disabled={this.state.token.length < 5}
            >
              {this.state.enableEnterprise ? 'Deshabilitar' : 'Habilitar'}
            </Button>
          </div>
        </div>
      </Modal>
    </div>;
  };

  render() {
    const { classes } = this.props;
    const tableProps = (params: any) => ({
      columns: params.columns,
      defaultPageSize: 10,
      showPaginationBottom: true,
      PaginationComponent: PaginationBase,
      previousText: 'Anterior',
      nextText: 'Siguiente',
      noDataText: 'Utilice la opción buscar empresas',
      pageText: 'Pagina',
      ofText: 'de',
      rowsText: 'filas',
      resizable: false,
      defaultFilterMethod: undefined,//this.defaultFilterMethod,
      filterable: true,
      showFilters: false,
      loadingText: <Loader loadTable />,
      manual: false,
      data: params.data,
      ref: params.nameTable,
      // onFetchData: (state: any) => this.fetchData(),
      loading: params.loading
    });
    let columns = [
      {
        Header: 'ID',
        accessor: 'enterpriseId',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'RUT',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return <div>{row.original.rut && row.original.dv ? row.original.rut.toString() + '-' + row.original.dv : ''}</div>;
        }
      },
      {
        Header: 'Razón Social',
        accessor: 'socialReason',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable
      },
      {
        Header: 'Factoring',
        accessor: 'factoring',
        headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return <div style={{ width: '100%', textAlign: 'center' }}>
            <Checkbox disabled checked={row.original.factoring} value='checkedD' style={{ margin: 'auto' }} />
          </div>;
        }
      },
      {
        Header: 'Leasing',
        accessor: 'leasing',
        headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return <div style={{ width: '100%', textAlign: 'center' }}>
            <Checkbox disabled checked={row.original.leasing} value='checkedD' style={{ margin: 'auto' }} />
          </div>;
        }
      },
      {
        Header: 'Vendor',
        accessor: 'vendor',
        headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return <div style={{ width: '100%', textAlign: 'center' }}>
            <Checkbox disabled checked={row.original.vendor} value='checkedD' style={{ margin: 'auto' }} />
          </div>;
        }
      },
      {
        Header: 'Registro civil',
        accessor: 'registroCivil',
        headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return <div style={{ width: '100%', textAlign: 'center' }}>
            <Checkbox disabled checked={row.original.registroCivil} value='checkedD' style={{ margin: 'auto' }} />
          </div>;
        }
      },
      {
        Header: 'Estado',
        accessor: 'status',
        headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: this.props.classes.TDTable,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return <div style={{ width: '100%', textAlign: 'center' }}>
            <Typography component='h3' color={row.original.enable ? 'primary' : 'error'}>
              {row.original.enable ? 'Habilitado' : 'Deshabilitado'}
            </Typography>
          </div>;
        }
      },
      {
        Header: 'Funciones',
        headerClassName: this.props.classes.HeaderTable,
        className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row: IRowRecord<Dto.EnterpriseProfile>) => {
          return row.original.enable ?
            (<div>
              <Tooltip title='Deshabilitar' aria-label='Deshabilitar'>
                <Button size='small' className={classes.disableUser}
                        onClick={() => this.changeStatusEnterpriseModal(row.original)}>
                  <HighlightOff />
                </Button>
              </Tooltip>
              <Tooltip title='Editar' aria-label='Editar'>
                <Button size='small' className={classes.deleteUser}
                        onClick={() => this.openEditEnterpriseModal(row.original)}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </div>)
            :
            (<div>
              <Tooltip title='Habilitar' aria-label='Habilitar'>
                <Button size='small' className={classes.enableUser}
                        onClick={() => this.changeStatusEnterpriseModal(row.original)}>
                  <CheckCircle />
                </Button>
              </Tooltip>
              <Tooltip title='Editar' aria-label='Editar' onClick={() => this.openEditEnterpriseModal(row.original)}>
                <Button size='small' className={classes.deleteUser}
                        onClick={() => this.openEditEnterpriseModal(row.original)}>
                  <EditIcon />
                </Button>
              </Tooltip>
            </div>);
        }
      }
    ];
    return (
      <div>
        <Breadcrumb category='Usted se encuentra en: Administración / Admin' textAlign='right' />
        <Grid container>
          {this.addEnterpriseModal()}
          <Grid item xs={8}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => this.openAddEnterpriseModal()}
            >
              Agregar empresa
            </Button>
          </Grid>
          <Grid item xs={4}>
            <TextField
              style={{
                width: '100%'
              }}
              placeholder='Buscar empresa'
              id='simple-start-adornment'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' style={{ margin: 0 }}>
                    <IconButton>
                      <SearchIcon style={{ width: 20, height: 20 }} onClick={this.fetchData} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={e => this.getSuggestions(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <ReactTable
              style={{ marginTop: 14 }}
              {...tableProps({
                nameTable: 'facturaTable',
                columns: columns,
                loading: this.state.loadingTable,
                data: this.state.companyListRender,
                noTable: 1,
                nomDataTable: 'dataUser'
              })}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: false
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  getDatosEmpresas: () => dispatch(getDatosEmpresas()),
  editEmpresa: (params: Dto.EditEnterprise) => dispatch(editEmpresaAction(params)),
  changeStatusEmpresa: (params: Dto.ChangeStatusEnterprise) => dispatch(changeStatusEmpresaAction(params)),
  crearEmpresa: (params: any) => dispatch(crearEmpresaAction(params))
});

export default withStyles(Style)(connect(mapStateToProps, mapDispatchToProps)(AdminEnterprise));

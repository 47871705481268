import React, { FC } from 'react';
import cx from 'classnames';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Collapse, List, ListItem } from '@material-ui/core';
import { useHistory } from 'react-router';
import routes from '../../../config/routes';
import IcoCarteraBlack from '../../../images/icons/ico-cartera-black.svg';
import IcoOperacionesBlack from '../../../images/icons/ico-operaciones-black.svg';
import IcoSolicitudCurseBlack from '../../../images/icons/ico-solicitud-curse-black.svg';
import IcoEnprocesoBlack from '../../../images/icons/ico-enproceso-black.svg';
import IcoHistoricoBlack from '../../../images/icons/ico-historico-black.svg';
import IcoExcedentesBlack from '../../../images/icons/ico-excedentes-black.svg';
import IcoExcedentesCursados from '../../../images/icons/ico-excedentes-cursados.svg';
import IcoExcedentesDisponibles from '../../../images/icons/ico-excedentes-disponibles.svg';
import IcoCobranzaBlack from '../../../images/icons/ico-cobranza-black.svg';
import IcoGreenCollapseArrow from '../../../images/icons/ico-green-collapse-arrow.svg';
import IcoFacturasEmitidasBlack from '../../../images/icons/ico-facturas-emitidas-black.svg';
import { menuSidebarStyle } from './menuSidebarStyle';
import FatherMenuItem from './FatherMenuItem';

interface IMenuFactoring extends WithStyles<typeof menuSidebarStyle> {
  miniActive: boolean;
  toogleCollapse: (menu: string, fatherCollapse: string) => any;
  isCollapse: string;
  trackingSidebar: (param: string) => any;
  activeRoute: (router: string) => any;
  checkCollapse: (collapse: string[]) => any;
}

const MenuFactoring: FC<IMenuFactoring> = (props) => {
  const history = useHistory();
  const { classes, miniActive, isCollapse, trackingSidebar, toogleCollapse, activeRoute, checkCollapse } = props;
  const fatherCollapse = 'openFactoring';

  const goTo = (to: any, track: string) => {
    trackingSidebar(track);
    history.push(to);
  };

  const handlerToggleCollapse = (collapse: string) => {
    toogleCollapse(collapse, fatherCollapse);
  };

  return (
    <ListItem classes={{ root: classes.listItemContainer }}>
      <FatherMenuItem
        title={'FACTORING'}
        miniActive={miniActive}
        openWhen={['openFactoring', 'openFactoringOpe', 'openFactoringExc']}
        click={() => handlerToggleCollapse('openFactoring')}
        collapse={isCollapse}
        fatherCollapse={fatherCollapse}
      />
      <Collapse
        classes={{
          container: classes.collapseContainer,
          entered: classes.collapseEntered
        }}
        in={checkCollapse([
          'openFactoring',
          'openFactoringOpe',
          'openFactoringExc'
        ])}
        unmountOnExit
      >
        <List>
          {/* Cartera menu */}
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.factoring.cartera, 'cartera')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoCarteraBlack} />
              <span>Cartera</span>
            </div>
          </ListItem>
          {/* Operaciones Menu */}
          <List classes={{ root: classes.listItemContainer }}>
            <ListItem
              className={cx(classes.menuNavLink, {
                open: checkCollapse(['openFactoringOpe'])
              })}
              onClick={() => handlerToggleCollapse('openFactoringOpe')}
            >
              <div className={classes.linkTextContainer}>
                <img src={IcoOperacionesBlack} />
                <span>Operaciones</span>
              </div>
              <img
                src={IcoGreenCollapseArrow}
                className={cx(classes.arrowtoogleCollapse, {
                  active: checkCollapse(['openFactoringOpe'])
                })}
              />
            </ListItem>
            <Collapse
              classes={{ container: classes.collapseContainer }}
              in={isCollapse === 'openFactoringOpe'}
              unmountOnExit
            >
              <List className={classes.listInside}>
                <ListItem
                  className={classes.menuNavLinkInside}
                  onClick={() => goTo(routes.factoring.operaciones.solicitud, 'solicitudOperaciones')}
                >
                  <div className={classes.linkTextContainer}>
                    <img src={IcoSolicitudCurseBlack} />
                    <span>Ingresa tu Operación</span>
                  </div>
                </ListItem>
                <ListItem
                  className={classes.menuNavLinkInside}
                  onClick={() => goTo(routes.factoring.operaciones.proceso, 'procesoOperaciones')}
                >
                  <div className={classes.linkTextContainer}>
                    <img src={IcoEnprocesoBlack} />
                    <span>En Proceso</span>
                  </div>
                </ListItem>
                <ListItem
                  className={classes.menuNavLinkInside}
                  onClick={() => goTo(routes.factoring.operaciones.recesion, 'recesionOperaciones')}
                >
                  <div className={classes.linkTextContainer}>
                    <img src={IcoEnprocesoBlack} />
                    <span>Recesión</span>
                  </div>
                </ListItem>
                <ListItem
                  className={classes.menuNavLinkInside}
                  onClick={() => goTo(routes.factoring.operaciones.historico, 'historicoOperaciones')}
                >
                  <div className={classes.linkTextContainer}>
                    <img src={IcoHistoricoBlack} />
                    <span>Histórico</span>
                  </div>
                </ListItem>
              </List>
            </Collapse>
          </List>
          {/* Menu Excedentes */}
          <List classes={{ root: classes.listItemContainer }}>
            <ListItem
              className={cx(classes.menuNavLink, {
                open: checkCollapse(['openFactoringExc'])
              })}
              onClick={() => handlerToggleCollapse('openFactoringExc')}
            >
              <div className={classes.linkTextContainer}>
                <img src={IcoExcedentesBlack} />
                <span>Excedentes</span>
              </div>
              <img
                src={IcoGreenCollapseArrow}
                className={cx(classes.arrowtoogleCollapse, {
                  active: checkCollapse(['openFactoringExc'])
                })}
              />
            </ListItem>
            <Collapse classes={{ container: classes.collapseContainer }}
                      in={isCollapse === 'openFactoringExc'}
                      unmountOnExit>
              <List className={classes.listInside}>
                <ListItem
                  className={classes.menuNavLinkInside}
                  onClick={() => goTo(routes.factoring.excedentes.disponibles, 'excedentesDisponibles')}
                >
                  <div className={classes.linkTextContainer}>
                    <img src={IcoExcedentesDisponibles} />
                    <span>Pendientes</span>
                  </div>
                </ListItem>
                <ListItem
                  className={classes.menuNavLinkInside}
                  onClick={() => goTo(routes.factoring.excedentes.historico, 'excedentesHistoricos')}
                >
                  <div className={classes.linkTextContainer}>
                    <img src={IcoExcedentesCursados} />
                    <span>Cursados</span>
                  </div>
                </ListItem>
              </List>
            </Collapse>
          </List>
          {/* Cobranza Menu */}
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo('/factoring/cobranza', 'cobranza')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoCobranzaBlack} />
              <span>Cobranza</span>
            </div>
          </ListItem>
          {/* Facturas Eminitidas */}
          <ListItem
            className={classes.menuNavLink}
            onClick={() =>
              goTo('/factoring/facturasemitidas', 'facturasEmitidas')
            }
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoFacturasEmitidasBlack} />
              <span>Facturas Emitidas</span>
            </div>
          </ListItem>
        </List>
      </Collapse>
    </ListItem>
  );
};

export default withStyles(menuSidebarStyle)(MenuFactoring);

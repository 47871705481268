import { eventTrackingToExcel, getCarteraFactoring, trackingSidebar } from '../../../services/factoringApi';
import { asyncActionType } from '../../../helpers/ActionsHelper';
import FileDownload from 'js-file-download';
import Moment from 'moment/moment';
import { Dispatch } from 'redux';
import { ActionT } from '../../../config/rootReducer';

export interface IFetchCarteraSuccess {
  tipoDocumento: number;
  carteraRow: Dto.CarteraRow[];
  carteraTotal: Dto.CarteraTotal;
  paginacion: Dto.Paginacion;
}

export interface IFetchPending {
  tipoDocumento: number;
  loading: boolean;
}


export const fetchAction = asyncActionType('@@CARTERA_FACTORING', 'FETCH');

export const setCargandoObtenerCarteraFactoring = (tipoDocumento: number, loading: boolean): ActionT<IFetchPending> => ({
  type: fetchAction.PENDING,
  payload: {
    tipoDocumento: tipoDocumento,
    loading: loading
  }
});

export const terminoObtenerCarteraFactoring = (response: Response.Cartera, tipoDocumento: number): ActionT<IFetchCarteraSuccess> => ({
  type: fetchAction.SUCCESS,
  payload: {
    tipoDocumento: tipoDocumento,
    carteraRow: response.data,
    carteraTotal: response.total,
    paginacion: {
      ...response.paginacion,
      numeroPagina: response.paginacion.numeroPagina - 1
    }
  }
});

export const docpdf = (response: any) => ({
  data: response
});

export const obtenerCarteraFactoring = (params: Filters.CarteraFactoring) => (dispatch: Dispatch): Promise<void> => {
  dispatch(setCargandoObtenerCarteraFactoring(params.tipoDocumento, true));
  return getCarteraFactoring(params)
    .then((response) => {
      const rCartera = response as Response.Cartera;
      return dispatch(terminoObtenerCarteraFactoring(rCartera, params.tipoDocumento));
    })
    .catch(error => error);
};

export const obtenerPdfCarteraFactoring = (params: Filters.CarteraFactoring) => (dispatch: Dispatch) => {
  dispatch(setCargandoObtenerCarteraFactoring(params.tipoDocumento, true));
  params.isExportPdf = true;
  return getCarteraFactoring(params)
    .then(response => {
      dispatch(setCargandoObtenerCarteraFactoring(params.tipoDocumento, false));
      FileDownload(response as Blob, `Cartera ${Moment().format('YYYY-MM-DD-hh-mm')}` + '.pdf');
    })
    .catch(error => error);

};

export const obtenerExcelCarteraFactoring = (params: any) => (dispatch: Dispatch): Promise<Response.Cartera> => {
  dispatch(setCargandoObtenerCarteraFactoring(params.tipoDocumento, true));
  return getCarteraFactoring(params)
    .then(response => {
      dispatch(setCargandoObtenerCarteraFactoring(params.tipoDocumento, false));
      return response;
    })
    .catch(error => error);

};
export const setEventTrackingExcel = (params: string) => (dispatch: Dispatch) => {
  return eventTrackingToExcel(params)
    .then(response => response)
    .catch(error => error);
};
export const setTrackingSidebar = (params: string) => () => {
  return trackingSidebar(params)
    .then(response => response)
    .catch(error => error);
};
export default { fetchAction };

import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../../../sharedv2/custom_theme.scss';
import tableStyle from '../../../../styles/jss/components/tableStyle';
import Button from '@material-ui/core/Button';
import { formatStringDate } from '../../../../helpers/FormatDate';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface IProps extends WithStyles<typeof tableStyle> {
  sincronizacion: Core.Dto.Sincronizacion[];
  openSincronizacion: (sinronizacion: Core.Dto.Sincronizacion) => void;
  deleteSincronizacion: (sinronizacion: Core.Dto.Sincronizacion) => void;
}

const TableSubscription: FC<IProps> = (props) => {

  const { sincronizacion, classes, openSincronizacion, deleteSincronizacion } = props;
  const tipos = [1, 3, 4, 5];

  return (
    <Table className={classes.table}>
      <TableHead className={classes.thead}>
        <TableRow className={classes.trow}>
          <TableCell className={classes.cell}>Servicio</TableCell>
          <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Estado</TableCell>
          <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Origen</TableCell>
          <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Cuenta</TableCell>
          <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Vigencia</TableCell>
          <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Acción</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sincronizacion.filter(d => tipos.filter(r => r === d.tipo).length > 0)
                       .map((row) => (
                         <TableRow key={row.tipo} className={classes.rowBody}>
                           <TableCell className={classes.cellBody}>
                             <div className={classes.contentInfoServicio}><span>{row.tipoDesc}</span></div>
                           </TableCell>
                           <TableCell align='right' className={[classes.cellBody, classes.centerText].join(' ')}>{row.estadoDesc}</TableCell>
                           <TableCell align='right' className={[classes.cellBody, classes.centerText].join(' ')}>{row.servicio}</TableCell>
                           <TableCell align='right' className={[classes.cellBody, classes.centerText].join(' ')}>{row.identificador} {row.textoIdentificador}</TableCell>
                           <TableCell align='right' className={[classes.cellBody, classes.centerText].join(' ')}>{formatStringDate(row.vigencia)}</TableCell>
                           <TableCell align='right' className={[classes.cellBody, classes.centerText].join(' ')}>
                             {(row.estado === 1 || row.estado === 2) && row.tipo !== 3 && (
                               <>
                                 <Button className={classes.buttonAgregar} onClick={() => openSincronizacion(row)}>
                                   Modificar
                                 </Button>
                                 <Button className={classes.buttonEliminar} onClick={() => deleteSincronizacion(row)}>
                                   Eliminar
                                 </Button>
                               </>
                             )}
                             {row.estado === 0 && (
                               <Button className={classes.buttonAgregar} onClick={() => openSincronizacion(row)}>
                                 Agregar
                               </Button>
                             )}
                           </TableCell>
                         </TableRow>
                       ))}
      </TableBody>
    </Table>
  );
};

export default withStyles(tableStyle)(TableSubscription);

import { fetchAction } from './actions';
import { AnyAction } from 'redux';
import datosUsuarios from './state';

export interface IAdminEmpresa {
  rut : string,
  nombre : string,
  razonSocial : string,
  direccionComercial : string,
  telefonoComercial : string
}

export interface IAdminUsuario {
  rut : string,
  nombre : string,
  email : string,
  celular : string
}

export interface IAdminDatosUsuarios {
  empresa : IAdminEmpresa,
  usuario : IAdminUsuario,
  isLoading : boolean
}

export const reducer = (state = datosUsuarios, action : AnyAction) => {
  switch (action.type) {
    case fetchAction.PENDING: {
      return Object.assign({}, state, action);
    }

    case fetchAction.SUCCESS: {
      return Object.assign({}, state, action);
    }

    default:
      return state;
  }
};

export default reducer;

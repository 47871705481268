import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme, WithStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DropzoneContainer from '../../../sharedv2/Dropzone/Dropzone';
import UploadedDocumentListItem from '../../../sharedv2/UploadedDocumentListItem/UploadedDocumentListItem';

const style = (theme: Theme) => ({
  dialogContent: {
    paddingTop: '5px',
    overflow: 'hidden',
    height: '75%',
    overflowY: 'auto' as 'auto'
  },
  buttonUpload: {
    background: '#82BC00',
    boxShadow: '0px 2px 15px rgba(1, 213, 159, 0.401005)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    marginTop: '31px',
    padding: '8px 32px',
    '&:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)',
      boxShadow: '0px 2px 15px rgba(130, 180, 0, .3)'
    }
  }
});

interface IUploadDialogProps extends WithStyles<typeof style> {
  esEjecutivo: boolean,
  loading: boolean,
  enviarArchivos: (archivos: File[]) => void
}

interface IState {
  archivos: Comun.Dto.ArchivoContenido[],
  sinArchivos: boolean,
  tab: string,
  fileError: boolean
}

class UploadDialog extends React.Component<IUploadDialogProps, IState> {
  constructor(props: IUploadDialogProps) {
    super(props);

    this.state = {
      tab: 'uploading',
      fileError: false,
      sinArchivos: true,
      archivos: []
    };

  }

  onChangeTab = (event: React.ChangeEvent<any>, newValue: string) => this.setState({ tab: newValue });

  onDropFile = (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (acceptedFiles.length) {
      acceptedFiles.forEach(file => {
        const archivo: Comun.Dto.ArchivoContenido = {
          id: file.lastModified.toString(),
          nombre: file.name,
          puedeEliminar: true,
          file: file,
          error: '',
          fecha: undefined,
          progreso: 0
        };
        this.updateListUploadingDocuments(archivo);
      });
      this.setState({ tab: 'uploading' });
    }
    if (rejectedFiles.length) {
      this.setState({
        fileError: true
      });
    }
  };

  onDeleteDocument = (id: string, nombre: string) => {
    const idx = this.state.archivos.findIndex(archivo => archivo.id === id);
    if (idx !== -1) {
      this.setState((state) => {
        state.archivos.splice(idx, 1);
        return ({
          archivos: state.archivos,
          sinArchivos: state.archivos.length === 0
        });
      });
    }
  };

  updateListUploadingDocuments = (archivo: Comun.Dto.ArchivoContenido) => {
    this.setState((state) => ({
      archivos: state.archivos.concat(archivo),
      sinArchivos: false
    }));
  };


  render() {
    const { loading, enviarArchivos, classes, esEjecutivo } = this.props;
    return (
      <React.Fragment>
        <DialogContent className={classes.dialogContent}>
          <DropzoneContainer
            title='Arrastre los archivos'
            subtitle='que desea enviar'
            accept=''
            type=''
            onDrop={this.onDropFile}
            name='files'
            classes={{}}
          />
          {/*{this.state.fileError ? (*/}
          {/*  <FormErrorText>Formato inválido, adjunte un archivo*/}
          {/*    con extensión {this.props.dropzoneProps.type}</FormErrorText>*/}
          {/*) : ''}*/}
          <Tabs
            value={this.state.tab}
            indicatorColor='primary'
            textColor='primary'
            onChange={this.onChangeTab}
          >
            <Tab value='uploading' label={'Archivos a enviar  (' + this.state.archivos.length + ')'} />
          </Tabs>
          <div>
            {(this.state.archivos || []).map(
              (archivo: Comun.Dto.Archivo) => <UploadedDocumentListItem key={archivo.id} archivo={archivo}
                                                                        onDelete={this.onDeleteDocument} />
            )}
          </div>

        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button color='primary'
                  className={classes.buttonUpload}
                  onClick={() => enviarArchivos(this.state.archivos.map((a: Comun.Dto.ArchivoContenido) => a.file))}
                  disabled={loading || esEjecutivo || this.state.sinArchivos}>
            {loading ?
              <CircularProgress size={20} />
              : 'Enviar'}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default withStyles(style)(UploadDialog);

import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, {FC, useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import bigHeaderStyle from './styles';
import FlagIcon from '../../../../images/icons/icons-bienes/flagicon.svg';
import { routes } from '../../../../config/routes';
import clsx from 'clsx';

interface IBigHeaderContent extends WithStyles<typeof bigHeaderStyle> {
  titulo: string;
  estado: string;
  cargando: boolean;
  onGrabar?: () => any;
  onEnviar?: () => any;
  onTransferir?: () => any;
  onCargarArchivo?: () => any;
  onVolver: () => any;
  showCargarArchivo: boolean;
  showEnviar: boolean;
  showGuardar: boolean;
  onAbrirHistorial?: () => any;
  showHistorial: boolean;
  notValid: boolean;
  onObtenerCAV?: () => any;
  showObtenerCAV: boolean;
}

const BigHeaderContent: FC<IBigHeaderContent> = (props) => {
  const { classes, titulo, estado, onGrabar, onEnviar, onTransferir, onCargarArchivo, onVolver, cargando, showCargarArchivo,
    showEnviar, showGuardar, onAbrirHistorial, showHistorial, onObtenerCAV, showObtenerCAV, notValid } = props;


  return (
    <div className={classes.headerBigContent}>
      <div className={classes.flexgrow}>
        <img src={FlagIcon} alt='' />
        <div className={classes.normalTitle}>{titulo}</div>
        <div className={classes.estadoContrato}>{estado}</div>
      </div>
      {onTransferir &&
        <Button variant='contained' color='primary' disabled={cargando} className={clsx(classes.greenButton, 'marginRight')} onClick={onTransferir}>
          Transferir
        </Button>
      }
      {onGrabar && showGuardar &&
        <Button variant='contained' color='primary' disabled={cargando || notValid} className={classes.greenButton} onClick={onGrabar}>
          Guardar
        </Button>}
      {onEnviar && showEnviar &&
        <Button variant='contained' color='primary' disabled={cargando} className={classes.greenButton} onClick={onEnviar}>
          Enviar
        </Button>
      }
      {onCargarArchivo && showCargarArchivo &&
          <Button variant='contained' color='primary' disabled={cargando} className={classes.greenButton} onClick={onCargarArchivo}>
              Subir documento
          </Button>
      }{onAbrirHistorial && showHistorial  &&
        <Button variant='contained' color='primary' disabled={cargando} className={classes.greenButton} onClick={onAbrirHistorial}>
            Abrir historial
        </Button>
    }
      {onObtenerCAV && showObtenerCAV  &&
          <Button variant='contained' color='primary' disabled={cargando} className={classes.greenButton} onClick={onObtenerCAV}>
              Certificado AV
          </Button>
      }
      <Button variant='outlined' color='primary' disabled={cargando} className={classes.greyButton} onClick={onVolver}>
        Volver
      </Button>
    </div>
  );
};

export default withStyles(bigHeaderStyle)(BigHeaderContent);

import React, { FC } from 'react';
import { Button, Card, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import scssTool from '../../../styles/scssTool';
// import ImgKeys from '../../images/img-keys.png';

const bannerStyle = (theme: Theme) => ({
  searchContainer: {
    ...scssTool.flex('flex-start', 'column', 'center'),
    paddingLeft: '32px',
  },
  searchTitle: {
    color: '#414141',
    ...scssTool.font(22, 800, 25),
    marginBottom: '2px',
  },
  searchSubTitle: {
    color: '#414141',
    ...scssTool.font(14, 400, 25),
    marginBottom: '8px',
  },
  btnSolicitar: {
    background: '#94C11F',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    width: '196px',
    height: '43px',
    borderRadius: '0px',
    '&:hover': {
      background: '#94C11F',
    },
  },
  btnSolicitarLabel: {
    ...scssTool.font(14, 'bold', 24),
    color: '#ffffff',
    fontFamily: scssTool.family.lato,
  },
});

interface IBannerSolicitar extends WithStyles<typeof bannerStyle> {
  img: any;
  titulo: string;
  subtitulo: string;
  botonTexto: string;
  action: () => any;
}

const BannerSolicitar: FC<IBannerSolicitar> = (props) => {
  const { classes, img, titulo, subtitulo, botonTexto, action } = props;
  return (
    <Card style={{ display: 'flex', height: '154px' }}>
      <img src={img} alt='' style={{ maxWidth: '50%' }} />
      <div className={classes.searchContainer}>
        <div className={classes.searchTitle}>{titulo}</div>
        <div className={classes.searchSubTitle}>{subtitulo}</div>
        <Button
          classes={{
            root: classes.btnSolicitar,
            label: classes.btnSolicitarLabel,
          }}
          onClick={action}
        >
          {botonTexto}
        </Button>
      </div>
    </Card>
  );
};

export default withStyles(bannerStyle)(BannerSolicitar);

import * as React from "react";
import { withStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';

const styles = _theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    minHeight: '60px',
    padding: '11px 63px 9px 26px',
    boxSizing: 'border-box',
    borderRadius: '2px',
    marginBottom: '16px'
  },
  success: {
    background: '#82bc00',
    '& span':{
      color: '#FFFFFF'
    },
    '& svg': {
      color: '#FFFFFF',
      marginRight: '8px'
    }
  },
  warning: {
    background: '#FFE897',
    '& span':{
      color: '#986604'
    },
    '& svg': {
      color: '#986604',
      marginRight: '8px'
    }
  },
  danger: {
    background: '#FFD7D7',
    '& span':{
      color: '#9D1D1D'
    },
    '& svg': {
      color: '#9D1D1D',
      marginRight: '8px'
    }
  },
  black: {
    color: '#555759'
  }
});

const Banner = ({ classes, text, type }) => {
  
  return (
    <div className={[classes.container, classes[type]].join(' ')}>
      <InfoIcon />
      <span>{text}</span>
    </div>
  )
}

export default withStyles(styles)(Banner)

import { fetchBienesAction, fetchContratosAction, IFetchBienesSuccess, IFetchContratosSuccess, IFetchPending } from './actions';
import { ActionT } from '../../config/rootReducer';
import { convertPaginacion } from '../../helpers/PaginationHelper';

const iniPaginacion : State.Paginacion = { count: 0, from: 0, maxPages: 0, page: 0, pageSize: 0, to: 0 };

export interface IVendorState {
  filterParameters : any,
  bienes : Dto.Vendor.BienesRow[],
  bienesPaginacion : State.Paginacion,
  contratos : Dto.Vendor.ContratosVigentesRow[],
  contratosPaginacion : State.Paginacion,
  isValidForm : boolean,
}

const vendorState : IVendorState = ({
  filterParameters: {},
  bienes: [],
  bienesPaginacion: iniPaginacion,
  contratos: [],
  contratosPaginacion: iniPaginacion,
  isValidForm: true
});

export const reducer = (state = vendorState, action : ActionT<any>) => {
  switch (action.type) {
    case fetchBienesAction.PENDING: {
      const payload = action.payload as IFetchPending;
      return {
        ...state,
        bienesPaginacion: {
          ...state.bienesPaginacion,
          pageSize: payload.tamanoPagina
        }
      };
    }

    case fetchBienesAction.SUCCESS: {
      const payload = action.payload as IFetchBienesSuccess;

      return {
        ...state,
        bienes: payload.bienes,
        bienesPaginacion: convertPaginacion(payload.paginacion)
      };
    }

    case fetchContratosAction.PENDING: {
      const payload = action.payload as IFetchPending;
      return {
        ...state,
        contratosPaginacion: {
          ...state.contratosPaginacion,
          pageSize: payload.tamanoPagina
        }
      };
    }

    case fetchContratosAction.SUCCESS: {
      const payload = action.payload as IFetchContratosSuccess;

      return {
        ...state,
        contratos: payload.contratos,
        contratosPaginacion: convertPaginacion(payload.paginacion)
      };
    }

    default:
      return state;
  }
};

export default reducer;

import ModalComponent from '../../shared/ModalMsg/ModalMsg';
import React, { FC, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { updateUserEnterpriseAction } from './actions';
import * as signalR from '@microsoft/signalr';
import { IHttpConnectionOptions } from '@microsoft/signalr';
import config from '../../../config/api';
import ModalSeleccionRutsSincronizacion from './modalSeleccionRutsSincronizacion';

interface IProps {
  dispatch : ThunkDispatch<any, any, AnyAction>,
}

const ActualizarUsuarios : FC<IProps> = (props : IProps) => {
  const {} = props;

  const [openInputModal, setOpenInputModal] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [textModal, setTextModal] = useState('');
  const jobId = '12345';

  const options : IHttpConnectionOptions = {
    withCredentials: false
  };

  const hubConnection : signalR.HubConnection = new signalR.HubConnectionBuilder()
    .withUrl(config.apiUri.replace('api/', '') + 'jobprogress', options)
    .configureLogging(signalR.LogLevel.Information)
    .build();

  useEffect(() => {
    return function cleanup() {
      if (hubConnection !== undefined) {
        hubConnection.stop()
                     .then(r => {
                       console.log('stopped');
                     });
      }
    };
  }, []);

  const closeResultModal = () => setOpenResultModal(false);
  const closeInputModal = (continua : boolean, rutEmpresa : string) => {
    setOpenInputModal(false);
    if (continua) {
      setTextModal('Iniciando procesamiento...');
      setOpenResultModal(true);
      updateUserEnterprise(rutEmpresa);
    }
  };

  const openModal = () => setOpenInputModal(true);

  const updateUserEnterprise = (rutEmpresa : string) => {
    props.dispatch(updateUserEnterpriseAction(jobId, rutEmpresa))
         .then((data) => {
           if (data.status === 200) {
             hubConnection.start()
                          .then(a => {
                            if (hubConnection.connectionId) {
                              hubConnection.invoke('AssociateJob', jobId);
                              hubConnection.on('sincronizacionEjecutivos', message => {
                                setTextModal(message);
                              });
                            }
                          });
           }
         })
         .catch((e) => {
         });
  };

  return (
    <>
      <Button
        variant="outlined"
        style={{ marginRight: 5 }}
        color="primary"
        onClick={openModal}
      >
        Actualizar Usuarios
      </Button>
      {openInputModal &&
      <ModalSeleccionRutsSincronizacion
        openModal={openInputModal}
        closeModal={closeInputModal} />
      }
      {openResultModal &&
      <ModalComponent
        openModal={openResultModal}
        title="Actualización de datos en curso"
        text={textModal}
        closeModalMsg={closeResultModal} />
      }
    </>
  );


};

const mapDispatchToProps = (dispatch : ThunkDispatch<any, any, AnyAction>) => ({
  dispatch: dispatch
});

export default connect(null, mapDispatchToProps)(ActualizarUsuarios);

import React, { FC, useEffect, useState } from "react";
import dialogStyle from "../../../../styles/jss/components/dialogStyle";
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import clsx from "clsx";
import {Button, Dialog, DialogActions, DialogContent, TextField} from "@material-ui/core";
import CloudIcon from "../../../../images/icons/cloud_upload_24px.svg";
import registroCivilApi from "../../../../services/registroCivilApi";

interface ICertificadoAnotacionesVigentesModal extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  open: boolean;
  inscripcion?: Dto.RegistroCivil.Inscripcion;
  empresa?: Dto.RegistroCivil.Empresa;
  contrato?: Dto.RegistroCivil.Contrato;
  transferencia?: Dto.RegistroCivil.Transferencia;
}


const CertificadoAnotacionesVigentesModal: FC<ICertificadoAnotacionesVigentesModal> = (props)=>{
  const {classes, onClose, open, inscripcion, empresa, contrato, transferencia }=props;
  const [consultado, setConsultado]= useState(false);
  const [ppu, setPpu]=useState('');
  const [certificado, setCertificado]= useState<Dto.RegistroCivil.CertificadoAnotacionVigentePatente | undefined>(undefined);
  const [respuesta, setRespuesta]=useState<any | undefined>(undefined);

  useEffect(()=>{
    let cleanedPpu='';
    if(inscripcion && inscripcion.ppu){
      cleanedPpu=cleanPpu(inscripcion.ppu)
      setPpu(cleanedPpu);
    }
    else if(transferencia && transferencia.vehiculo.ppu){
      cleanedPpu=cleanPpu(transferencia.vehiculo.ppu)
      setPpu(cleanedPpu);
    }
    else{
      setPpu(cleanedPpu);
    }
  },[inscripcion, transferencia]);

  const handleInputPpu=(_ppu:string)=>{
    if(_ppu){
      setPpu(_ppu);
    }
  }
  const closeModal=()=>{
    setPpu('');
    setRespuesta(undefined);
    setCertificado(undefined);
    onClose();
  }
  const cleanPpu=(ppu:string)=>{
    if(ppu.includes('-')){
      let arr = ppu.split('-');
      let clean= arr[0].replaceAll('.','');
      return clean;
    }
    else if(ppu.includes('.')){
      let clean= ppu.replaceAll('.','');
      return clean;
    }
    else{
      return ppu;
    }

  }
  const handleBottom = async ()=>{
    if(ppu){
      const req: Request.RegistroCivil.ObtenerCertificadoAnotacionesVigentes={
        ppu: ppu
      }
      registroCivilApi.obtenerCertificadoAnotacionesVigentes(req)
        .then(resp=>{
          debugger;
          setRespuesta(resp);
          if(resp && resp.certificado){
            setCertificado(resp.certificado);
          }
        })
    }
  }
  return(
    <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: clsx(classes.dialogPaperGreyVariant, 'responsive') }}>
      <div className={[classes.containerGreyVariant, classes.heightAuto].join(' ')}>
        <div className={classes.floatingHeaderIcon}>
          <img src={CloudIcon} alt='' />
        </div>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Placa patente</div>
            <TextField
              value={ppu}
              onChange={(e) => handleInputPpu(e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          {respuesta && !respuesta.success ?
            <p>
              {respuesta.glosa}
            </p>: null
          }
          {respuesta && respuesta.success ?
          <>
            <h3>Datos del Vehiculo</h3>
            <p>Placa Patente: {certificado?.datosVehiculo.ppu}</p>
            <p>Tipo: {certificado?.datosVehiculo.tipo}</p>
            <p>Año fabricacion: {certificado?.datosVehiculo.aFabrica}</p>
            <p>Marca: {certificado?.datosVehiculo.marca}</p>
            <p>Modelo: {certificado?.datosVehiculo.modelo}</p>
            <p>Numero de motor: {certificado?.datosVehiculo.numeroMotor}</p>
            <p>Numero de serie: {certificado?.datosVehiculo.numeroSerie}</p>
            <p>Numero de chasis: {certificado?.datosVehiculo.chasis}</p>
            <p>Color: {certificado?.datosVehiculo.color}</p>
            <p>VIN: {certificado?.datosVehiculo.vin}</p>
            <h3>Datos del Propietario</h3>
            <p>Nombre completo: {certificado?.propietarios.propietarioActual.nombrePersonaNatural.nombre}&nbsp;{certificado?.propietarios.propietarioActual.nombrePersonaNatural.apellidoPaterno}&nbsp;{certificado?.propietarios.propietarioActual.nombrePersonaNatural.apellidoMaterno}</p>
            <p>RUT: {certificado?.propietarios.propietarioActual.run.numero}-{certificado?.propietarios.propietarioActual.run.dv}</p>
          </>:null
          }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {!consultado &&
              <Button variant='outlined' color='primary' className={classes.greenButton} onClick={handleBottom}>
                  Obtener Certificado
              </Button>
          }
          <Button variant='outlined' color='primary' className={classes.greyButton} onClick={closeModal}>
            Cerrar
          </Button>
        </DialogActions>
      </div>

    </Dialog>
  )
}
export default withStyles(dialogStyle)(CertificadoAnotacionesVigentesModal);
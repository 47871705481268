import React from 'react';

// componentes material ui
import { Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import NegociosForm from '../shared/NegociosForm/form';
import IcoDashedGreenArrow from '../../images/icons/ico-dashed-green-arrow.svg';
import IcoCreditia from '../../images/icons/ico-crediticia.svg';
import IcoFirmaContrato from '../../images/icons/ico-firmar-contrato.svg';
import IcoDesembolsoOperacion from '../../images/icons/ico-desombolso-operacion.svg';
import IcoProveedorCliente from '../../images/icons/ico-proveedor-cliente.svg';
import IcoEmisionFactura from '../../images/icons/ico-emision-factura.svg';
import IcoEntregaBienes from '../../images/icons/ico-entrega-vienes.svg';
import FactotalTheme from '../../styles/FactotalTheme';
import BorderSeparator from '../sharedv2/BorderSeparator/BorderSeparator';
import IcoDevolucion from '../../images/icons/ico-devolucion.svg';
import MesaComercialContainer from '../factoring/components/MesaComercialContainer';
import scssTool from '../../styles/scssTool';

const vendorStyle = (theme : Theme) => ({
  containerFac: {
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
    padding: '0px 10px'
  },
  leftContainer: {
    ...scssTool.flex('flex-start', 'row'),
    height: '100%'
  },
  facTitle: {
    ...scssTool.font(16, 'bold', 24),
    color: '#000000'
  },
  facTitleC: {
    ...scssTool.font(16, 'bold', 24),
    color: '#000000',
    marginTop: '27px'
  },
  facParraf: {
    ...scssTool.font(14, 400, 18),
    color: '#414141',
    margin: '16px 0px'
  },
  formContactoColumn: {
    ...scssTool.flex('flex-start', 'column', 'flex-start')
  },
  btnSolicitar: {
    background: '#94C11F',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    width: '196px',
    height: '59px',
    '&:hover': {
      background: '#94C11F'
    }
  },
  btnSolicitarLabel: {
    ...scssTool.font(16, 'bold', 24),
    color: 'white'
  },
  inputTextfield: {
    width: '100%',
    marginBottom: '12px'
  },
  listColumnContainer: {
    ...scssTool.flex('center', 'column', 'center'),
    width: '80px'
  },
  listIconContainer: {
    ...scssTool.flex('flex-start', 'row', 'space-between'),
    height: '180px',
    width: '100%'
  },
  greenCircle: {
    background: FactotalTheme.palette.primary.main,
    width: '18px',
    height: '18px',
    borderRadius: '18px',
    ...scssTool.font(12, 'bold', 17),
    ...scssTool.flex('center', 'row', 'center'),
    color: '#fff',
    marginBottom: '10px'
  },
  listTitle: {
    ...scssTool.font(12, 400, 17),
    color: '#414141',
    marginTop: '16px',
    width: '100%',
    textAlign: 'center' as 'center'
  },
  icoListInside: {
    height: '50px',
    ...scssTool.flex('center', 'column', 'center')
  },
  contentIconDashed: {
    ...scssTool.flex('center', 'column', 'center'),
    height: '67%'
  }
});

interface StateProps {
  isLoading : boolean
}

interface IProps extends WithStyles<typeof vendorStyle> {
}

class VendorProducto extends React.Component<IProps> {
  constructor(props : IProps) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={16}>
        <Grid item xs={12} md={7}>
          <div className={this.props.classes.containerFac}>
            <div className={this.props.classes.facTitle}>Solicita Leasing tecnológico</div>
            <div className={this.props.classes.facParraf}>
              El leasing tecnológico permite entregar a nuestros clientes propuestas inteligentes para
              el uso de tecnología de punta con precios atractivos al mantener acuerdos comerciales
              con los más importantes proveedores de tecnología y sus distribuidores.
            </div>
            <div className={classes.listIconContainer}>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>1</div>
                <div className={classes.icoListInside}>
                  <img src={IcoProveedorCliente} alt='' />
                </div>
                <div className={classes.listTitle}>Proveedor/Cliente indica bienes a financiar</div>
              </div>
              <div className={classes.contentIconDashed}>
                <img src={IcoDashedGreenArrow} alt='' />
              </div>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>2</div>
                <div className={classes.icoListInside}>
                  <img src={IcoCreditia} alt='' />
                </div>
                <div className={classes.listTitle}>Evaluación comercial y crediticia</div>
              </div>
              <div className={classes.contentIconDashed}>
                <img src={IcoDashedGreenArrow} alt='' />
              </div>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>3</div>
                <div className={classes.icoListInside}>
                  <img src={IcoFirmaContrato} alt='' />
                </div>
                <div className={classes.listTitle}>Firma de contrato</div>
              </div>
              <div className={classes.contentIconDashed}>
                <img src={IcoDashedGreenArrow} alt='' />
              </div>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>4</div>
                <div className={classes.icoListInside}>
                  <img src={IcoEmisionFactura} alt='' />
                </div>
                <div className={classes.listTitle}>FT envía orden de compra al proveedor</div>
              </div>
              <div className={classes.contentIconDashed}>
                <img src={IcoDashedGreenArrow} alt='' />
              </div>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>5</div>
                <div className={classes.icoListInside}>
                  <img src={IcoEntregaBienes} alt='' />
                </div>
                <div className={classes.listTitle}>Proveedor entrega bienes al cliente</div>
              </div>
              <div className={classes.contentIconDashed}>
                <img src={IcoDashedGreenArrow} alt='' />
              </div>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>6</div>
                <div className={classes.icoListInside}>
                  <img src={IcoDevolucion} alt='' />
                </div>
                <div className={classes.listTitle}>FT Vendor paga al Proveedor</div>
              </div>
              <div className={classes.contentIconDashed}>
                <img src={IcoDashedGreenArrow} alt='' />
              </div>
              <div className={classes.listColumnContainer}>
                <div className={classes.greenCircle}>7</div>
                <div className={classes.icoListInside}>
                  <img src={IcoDesembolsoOperacion} alt='' />
                </div>
                <div className={classes.listTitle}>Cliente paga cuotas de leasing tecnológico</div>
              </div>
            </div>

            <NegociosForm textoBoton={'Solicitar Leasing tecnológico'} tipoNegocioForm={'Leasing tecnológico'} />
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <div className={classes.leftContainer}>
            <BorderSeparator />
            <MesaComercialContainer />
          </div>
        </Grid>
      </Grid>

    );
  }
}


export default withStyles(vendorStyle)(VendorProducto);

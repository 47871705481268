import React from 'react';
import Button from '@material-ui/core/Button';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DropzoneContainer from '../../../../sharedv2/Dropzone/Dropzone';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import DeleteIcon from '@material-ui/icons/Delete';
import fxOpActions from '../actions';
import Banner from '../components/Banner/Banner';
import { subirXmlDocumentos } from '../../solicitud/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../../config/rootReducer';
import { AnyAction } from 'redux';

interface ISubirXmlDocumentoProps {
  documento : Dto.BackOffice.Documento,
  className : string,
  verificarPreFactibilidadDocumento : (idDocumento : string) => void,
  eliminarAnexoDocumentoSimulacion : (payload : Payloads.EliminarAnexoDocumentoSimulacionPayload) => void,
  subirXmlDocumentoSimulacion : (file : File, id : number) => Promise<Mensaje.SubirDocumentoRespuesta>,
  subirAnexoDocumentoSimulacion : (payload : Payloads.ArchivoSubidoPayload) => Promise<Mensaje.AdjuntarArchivoDocumentoRespuesta>,
}

interface IState {
  open : boolean,
  mostrarError : boolean,
  mensajeError : string
}

class SubirXmlDocumento extends React.Component<ISubirXmlDocumentoProps, IState> {
  constructor(props : ISubirXmlDocumentoProps) {
    super(props);
    this.state = {
      open: false,
      mostrarError: false,
      mensajeError: ''
    };

  }

  open = () => this.setState({ open: true, mostrarError: false });
  close = () => this.setState({ open: false, mostrarError: false });

  onDropFile = (acceptedFiles : File[]) => {
    this.setState({ mostrarError: false });
    if (acceptedFiles.length) {
      const promesas = acceptedFiles.map(file => {
        if (file.name.endsWith('.xml')) {
          return this.props.subirXmlDocumentoSimulacion(file, Number(this.props.documento.id));
        } else {
          return this.props.subirAnexoDocumentoSimulacion({
            invoiceId: this.props.documento.id,
            file,
            attachmentId: ''
          });
        }
      });
      Promise.all(promesas)
             .then((resp) => {
                 this.setState({ mostrarError: !resp[0].exito, mensajeError: resp[0].mensaje });
                 if (resp[0].exito) {
                   this.props.verificarPreFactibilidadDocumento(this.props.documento.id);
                 }
               }
             );
    }
  };

  render() {
    const { className, documento } = this.props;
    const title = `${documento.tipoDteDesc} #${documento.numeroDocumento}`;

    return (
      <React.Fragment>
        <IconButton
          onClick={this.open}
        >
          <Typography className={className}>Cargar XML</Typography>
        </IconButton>
        <Dialog
          open={this.state.open}
          fullWidth
          maxWidth="sm"
          scroll="body"
        >
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            <DropzoneContainer
              title="Adjuntar más documentos"
              onDrop={this.onDropFile}
              name="files"
            />
            {this.state.mostrarError && <Banner
              text={this.state.mensajeError}
              type="danger" />}
            <Table>
              <TableBody>
                <TableRow><TableCell>Archivo</TableCell><TableCell>Acciones</TableCell></TableRow>
                {documento.archivos && documento.archivos.map((a, idx) =>
                  <TableRow key={idx}>
                    <TableCell>{a.nombre}</TableCell>
                    <TableCell>
                      {a.puedeEliminar ?
                        <IconButton onClick={() =>
                          this.props.eliminarAnexoDocumentoSimulacion({
                            attachmentId: a.id,
                            invoiceId: documento.id,
                            nombre: a.nombre
                          })}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                        : undefined}
                    </TableCell>
                  </TableRow>)
                }
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={this.close}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, null, AnyAction>) => ({
  eliminarAnexoDocumentoSimulacion: (payload : Payloads.EliminarAnexoDocumentoSimulacionPayload) => dispatch(fxOpActions.eliminarAnexoDocumentoSimulacion(payload)),
  subirAnexoDocumentoSimulacion: (payload : Payloads.ArchivoSubidoPayload) => dispatch(fxOpActions.subirAnexoDocumento(payload)),
  subirXmlDocumentoSimulacion: (file : File, idDocumento : number) => dispatch(subirXmlDocumentos(file, idDocumento)),
  verificarPreFactibilidadDocumento: (idDocumento : string) => dispatch(fxOpActions.verificarPreFactibilidadDocumento(idDocumento))
});

export default connect(
  null,
  mapDispatchToProps
)(SubirXmlDocumento);

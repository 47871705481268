import FactotalTheme from '../../../../styles/FactotalTheme';
import { Theme } from '@material-ui/core';

const SFooterButtons = (theme : Theme) => ({
  root: {
    backgroundColor: '#E4E2E2',
    boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.12)',
    height: '86px',
    width: '100%',
    position: 'fixed' as 'fixed',
    marginTop: '20px',
    padding: '23px 7% 21px 243px',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: '0px',
    left: '0px',
    zIndex: 5,
    '& button': {
      fontSize: '12px',
      lineHeight: '17px',
      textAlign: 'center' as 'center',
      textTransform: 'uppercase' as 'uppercase',
      fontWeight: 'normal' as 'normal',
      boxSizing: 'border-box' as 'border-box',
      borderRadius: '4px',
      height: '42px'
    },
    '& button:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)',
      opacity: '0.6'
    }
  },
  botonVolver: {
    width: '80px',
    background: '#FFFFFF',
    border: '1px solid #82BC00',
    color: '#82BC00'
  },
  cederYAceptar: {
    width: '145px',
    background: '#FFFFFF',
    border: '1px solid #82BC00',
    color: '#82BC00'
  },
  cederYAceptarFull: {
    width: '145px',
    background: '#82BC00',
    color: '#FFFFFF'
  }
});

const SAcceptYieldModal = (theme : Theme) => ({
  root: {
    '& h1': {
      color: '#000000',
      fontSize: '30px',
      textAlign: 'center' as 'center',
      fontWeight: 'normal' as 'normal'
    },
    '& p': {
      color: '#555759',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: '#555759'
      }
    }
  },
  rootDeleteModal: {
    '& h1': {
      color: '#000000',
      fontSize: '30px',
      textAlign: 'center' as 'center',
      fontWeight: 'normal' as 'normal'
    }
  },
  rootXml: {
    width: '700px !important',
    height: '90vh !important',
    maxWidth: 'none'
  },
  rootYTModal: {
    width: '85vw !important',
    height: '90vh !important',
    border: 'none !important',
    padding: '32px !important',
    display: 'flex',
    flexDirection: 'column' as 'column',
    maxWidth: 'none'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mRight: {
    marginRight: '8px' as any
  },
  ytVideoDesc: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    width: '30%',
    height: '100%'
  },
  ytVideoTitle: {
    fontWeight: 'bold' as 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    color: '#000000',
    marginBottom: '8px'
  },
  ytVideoDescSub: {
    fontWeight: 'normal' as 'normal',
    fontSize: '14px',
    lineHeight: '16px',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  paperDialogtitle: {
    borderBottom: 'none',
    marginBottom: 0
  },
  midleDialogContent: {
    display: 'flex',
    height: '100%'
  },
  ytVideoDialog: {
    width: '65%',
    height: '100%',
    marginRight: '16px' as any
  },
  ytVideoPlayer: {
    height: '100%'
  },
  dialogPaper: {
    width: '750px',
    minHeight: '300px',
    border: '3px solid #82BC00',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '16px',
    overflow: 'inherit',
    padding: '50px 40px 40px 40px',
    lineHeight: '21px',
    position: 'absolute' as 'absolute',
    maxWidth: 'none'
  },
  dialogConfirm: {
    height: '60%'
  },
  dialogPaperWarning: {
    border: '3px solid #B58D00'
  },
  dialogPaperDelete: {
    border: '3px solid #CD2F2F'
  },
  checkIcon: {
    position: 'absolute' as 'absolute',
    width: '100px',
    height: '80px',
    left: '50%',
    top: '-40px',
    background: 'rgba(130, 188, 0, 0.8)',
    borderRadius: '13px',
    marginLeft: '-50px',
    textAlign: 'center' as 'center',
    '& svg': {
      color: '#fff',
      fontSize: '80px'
    }
  },
  checkIconWarning: {
    background: 'rgba(255, 220, 98, 0.8)'
  },
  chekIconDelete: {
    background: 'rgba(205, 47, 47, 0.9)'
  },
  messageDelete: {
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center' as 'center',
    margin: '32px 0px',
    color: '#8C8C8C',
    '& span': {
      color: '#000000'
    },
    '& .red': {
      color: '#CD2F2F'
    }
  },
  containerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& p': {
      marginRight: '20px' as any
    },
    '& button': {
      background: '#82BC00',
      border: '1px solid #82BC00',
      borderRadius: '4px',
      width: '100px',
      height: '40px',
      textAlign: 'center' as 'center',
      fontSize: '12px',
      lineHeight: '17px',
      color: '#fff',
      marginRight: '20px' as any,
      '&:hover': {
        color: '#82BC00',
        background: '#FFF',
        fontSize: '13px'
      }
    },
    '& button:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)'
    }
  },
  secondaryButton: {
    width: '80px !important',
    background: '#FFFFFF !important',
    border: '1px solid #82BC00 !important',
    color: '#82BC00 !important'
  },
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
    height: '75%',
    overflowY: 'auto' as 'auto'
  },
  textField: {
    width: '318px',
    margin: '14px 0px 0px 0px'
  },
  textFieldMargin: {
    margin: '8px 0px 0px 0px'
  },
  greenButton: {
    background: '#82BC00',
    borderRadius: '5px',
    color: '#fff',
    width: 131,
    height: 45,
    '&:hover': {
      color: '#82BC00'
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)'
    }
  },
  redButton: {
    background: '#fff',
    border: '1px solid #CD2F2F',
    borderRadius: '5px',
    color: '#CD2F2F',
    width: 131,
    height: 45,
    '&:hover': {
      background: '#CD2F2F',
      color: '#fff'
    }
  },
  greyButton: {
    background: '#EFF0F0',
    borderRadius: '5px',
    width: 131,
    height: 45
  },
  titleModal: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#484848'
  },
  labelSelectModal: {
    marginTop: '32px',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#3C3C3C'
  },
  errorLabel: {
    color: '#CD2F2F !important',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '8px 0px',
    margin: '0px'
  },
  buttonUpload: {
    background: '#82BC00',
    boxShadow: '0px 2px 15px rgba(1, 213, 159, 0.401005)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    marginTop: '31px',
    padding: '8px 32px',
    '&:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)',
      boxShadow: '0px 2px 15px rgba(130, 180, 0, .3)'
    }
  },
  uploadDocument: {
    boxSizing: 'border-box' as 'border-box',
    padding: '16px 0px',
    minHeight: '20px'
  },
  passwordForm: {
    boxSizing: 'border-box' as 'border-box',
    padding: '32px 0px'
  },
  titlePass: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#3C3C3C'
  },
  TableFTC: {
    marginTop: '2px',
    borderSpacing: '0 2px',
    borderCollapse: 'separate' as 'separate'
  },
  HeaderTableFCT: {
    backgroundColor: FactotalTheme.palette.grey[200],
    textAlign: 'center' as 'center'
  },
  TableRowFCT: {
    paddingRight: '0px' as '0px',
    height: '30px'
  },
  TableThFCT: {
    paddingRight: '0px' as '0px',
    color: '#FFFFFF',
    fontSize: '10px',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'normal' as 'normal',
    letterSpacing: '1px'
  },
  TableRowBodyFCT: {
    height: '31px',
    color: FactotalTheme.palette.grey[500],
    fontSize: '12px',
    borderBottom: 'none',
    '& td:last-child': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  TableCellFCT: {
    borderTop: '1px solid rgba(224, 224, 224, 1)'
  },
  dialogActions: {
    justifyContent: 'flex-start',
    padding: '16px'
  },
  labelForm: {
    marginTop: '32px',
    marginBottom: '8px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#3C3C3C'
  },
  textfieldPass: {
    width: '209px'
  },
  outlinePrimeryButton: {
    border: '1px solid #82BC00',
    background: '#fff !important',
    color: '#82BC00 !important'
  },
  gridDialog: {
    marginBottom: '32px'
  },
  uploadContent: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  fullwidth: {
    width: '100%'
  },
  middleWidth: {
    width: '80%'
  },
  formControlDialog: {
    width: '100%',
    display: 'contents'
  },
  inputFile: {
    display: 'none'
  },
  dialogChekbox: {
    display: 'flex',
    alignItems: 'center'
  },
  termsLink: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#8C8C8C',
    '& a': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  dialogActionsTerm: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center'
  },
  labelRadioButton: {
    fontSize: '12px',
    lineHeight: '14px',
    marginLeft: '-8px',
    marginBottom: '8px',
    '& [class*=body2]': {
      color: '#8C8C8C !important'
    }
  },
  inputLabel: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#3C3C3C',
    marginBottom: '16px',
    display: 'block'
  },
  fixTop: {
    marginTop: '16px'
  },
  passInput: {
    width: '210px'
  },
  dialogScrollContent: {
    padding: '8px 16px 32px 0px',
    marginTop: '16px',
    height: '55vh',
    '& ul': {
      color: '#555759'
    },
    '& h2': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#3C3C3C'
    },
    '& table': {
      color: '#555759'
    }
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    color: '#555759'
  },
  tableTerms: {
    borderCollapse: 'collapse' as 'collapse'
  },
  dialogPapersOr: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  termsConditionTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3C3C3C'
  },
  uploadXml: {
    width: '700px !important',
    height: '85vh !important',
    maxWidth: 'none'
  },
  cancelButtom: {
    fontWeight: 'normal' as 'normal',
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize'
  },
  tableHeader: {
    textAlign: 'center' as 'center'
  },
  clearSpace: {
    height: '32px'
  },
  cardReq: {
    background: '#E5E6E6',
    borderRadius: '5px',
    padding: '8px',
    display: 'flex',
    alignItems: 'flex-start',
    margin: '16px 0px'
  },
  cardReqIcon: {
    marginRight: '16px' as any
  },
  cardReqContent: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  cardReqTitle: {
    fontWeight: 'normal' as 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#102333'
  },
  cardReqSubtitle: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#828282',
    marginBottom: '4px'
  },
  cardReqFunc: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: 'rgba(16, 35, 51, 0.65)'
  },
  dialogTitleTerms: {
    margin: '0px',
    padding: '0px 0px 23px 0px',
    borderBottom: 'none',
    '& h6': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#3C3C3C'
    }
  },
  dialogTermsTabRoot: {
    background: '#F5F6FA',
    border: '1px solid #686B6D',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '10px',
    width: '100%',
    height: '45px'
  },
  dialogTermIndicator: {
    height: '100%',
    background: '#6F777B',
    zIndex: 1
  },
  termDialogTab: {
    width: '50%',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center' as 'center',
    color: '#555759',
    maxWidth: 'none'
  },
  dialogTermsTabsSelected: {
    background: '#6F777B',
    color: '#FFFFFF',
    zIndex: 2
  },
  dialogTermLabelContainer: {
    paddingTop: '3px'
  }
});

const modalStyle = (theme : Theme) => ({
  root: {},
  dialogPaper: {
    width: '750px',
    minHeight: '300px',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '20px',
    boxShadow: '0px -4px 9px rgba(0, 0, 0, 0.12)',
    overflow: 'inherit',
    padding: '0px',
    lineHeight: '21px',
    position: 'absolute' as 'absolute',
    maxWidth: 'none'
  },
  titleForm: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '18px',
    color: '#333333',
    margin: '24px 0px 0px'
  },
  labelForm: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
    marginBottom: '4px'
  },
  textField: {
    fontFamily: 'Larsseit',
    width: '100%',
    border: '1px solid #CACED8',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '4px',
    padding: '13px 18px',
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '16px',
    height: '48px',
    '& $underline:before': {
      borderBottom: 'none'
    },
    '&.bottom': {
      marginBottom: '46px'
    },
    '&.area': {
      height: '150px'
    }
  },
  buttonEnviar: {
    background: theme.palette.primary.main,
    borderRadius: '5px',
    height: 45,
    width: 194,
  },
  buttonEnviarDisabled: {
    background: theme.palette.primary.main,
    borderRadius: '5px',
    height: 45,
    width: 194,
    opacity: .6
  },
  labelButton: {
    fontSize: '14px',
    lineHeight: '14px',
    color: '#FFFFFF',
    fontWeight: 500
  },
  dialogActions: {
    justifyContent: 'flex-end',
    padding: '0px 31px 26px 31px'
  }
});

export { SFooterButtons, SAcceptYieldModal, modalStyle };

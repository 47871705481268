import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from '@material-ui/core';
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

import breadcrumbStyle from "../../../styles/jss/components/breadcrumbStyle";

function Breadcrumb({ ...props }) {
  const { textAlign, category, title, classes } = props;
  const heading =
    classes.heading +
    " " +
    cx({
      [classes[textAlign + "TextAlign"]]: textAlign !== undefined
    });
  const arr = category.split(">");
  if (title !== undefined || category !== undefined) {
    return (
      <div className={heading}>
        {title !== undefined ? (
          <h4 className={classes.title}>{title}</h4>
        ) : null}
        {category !== undefined ? (
          <Typography variant="h5" className={classes.category}>
            {arr.map((path, i) => (
              <span key={i} className={classes.marginRight}>
                <span>{path}</span>
                {arr.length - 1 !== i && (
                  <KeyboardArrowRight className={classes.icons} />
                )}
              </span>))}
          </Typography>
        ) : null}
      </div>

    );
  }
  return null;
}

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
  category: PropTypes.string,
  textAlign: PropTypes.oneOf(["right", "left", "center"])
};

export default withStyles(breadcrumbStyle)(Breadcrumb);

import { Button, ButtonBase, Theme } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useState } from 'react';
import CardIcon from '../../../images/icons/icons-bienes/caricons.svg';
import OneIcon from '../../../images/icons/icons-bienes/oceicons.svg';
import FlagIcon from '../../../images/icons/icons-bienes/flagicon.svg';
import CheckIcon from '../../../images/icons/icons-bienes/check_24px_outlined.svg';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import routes from '../../../config/routes';
import scssTool from '../../../styles/scssTool';
import { TipoVehiculo } from './nuevoBien';

const style = (theme: Theme) => ({
  cardBienContainer: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '4px',
    padding: '16px',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    height: '120px',
    marginBottom: '16px',
    transition: 'all 0.3s ease-in-out',
    trasnformOrigin: 'center' as 'center'
  },
  columnNumber: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    height: '100%',
    width: '48px',
    alignItems: 'center'
  },
  numberContainer: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    flexGrow: 1
  },
  numberIndicator: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#555759'
  },
  smallFont: {
    fontSize: '9px',
    lineHeight: '11px',
    color: '#555759'
  },
  middleFont: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#555759',
    flexGrow: 1
  },
  centerContent: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    width: '100%',
    height: '100%',
    padding: '0px 8px'
  },
  sepatatorVertical: {
    width: '1px',
    height: '70px',
    backgroundColor: '#E7E4E4'
  },
  centerContentUp: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-evenly' as 'space-evenly',
    height: '33px',
    borderBottom: '1px solid #E7E4E4',
    paddingBottom: '5px'
  },
  centerContentDown: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    height: '100%',
    width: '100%'
  },
  buttonContainer: {
    marginLeft: '8px'
  },
  contentUp: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
    minWidth: '44px',
    justifyContent: 'flex-start' as 'flex-start',
    height: '100%'
  },
  justifyContent: {
    justifyContent: 'center' as 'center'
  },
  contentStepMarquer: {
    flexGrow: 1,
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center'
  },
  stepMarquer: {
    backgroundColor: '#82BC00',
    width: '18px',
    height: '18px',
    borderRadius: '18px',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center'
  },
  activeStep: {
    border: '1px solid #82BC00',
    backgroundColor: '#fff',
    fontSize: '9px',
    lineHeight: '11px',
    color: '#82BC00'
  },
  waitingStep: {
    border: '1px solid #555759',
    backgroundColor: '#fff',
    fontSize: '9px',
    lineHeight: '11px',
    color: '#555759'
  },
  nameMarquer: {
    fontSize: '11px',
    lineHeight: '13px',
    color: '#555759'
  },
  separadorMarquer: {
    height: '2px',
    background: '#C4C4C4',
    borderRadius: '2px',
    width: '100%',
    marginTop: '-11px'
  },
  transferenciaText: {
    ...scssTool.font(10, 400, 12),
    color: '#82BC00',
    marginTop: '14px',
    fontFamily: 'Arial'
  }
});

interface ICardDetalle extends WithStyles<typeof style> {
  paso: {
    estado: string;
    titulo: string;
    paso?: number;
  };
}

const CardDetalle: FC<ICardDetalle> = (props) => {
  const { classes, paso } = props;
  return (
    <div className={[classes.contentUp, classes.justifyContent].join(' ')}>
      <div className={classes.contentStepMarquer}>
        <div
          className={clsx(classes.stepMarquer, {
            [classes.activeStep]: paso.estado === 'activo',
            [classes.waitingStep]: paso.estado === 'esperando'
          })}>
          {paso.paso ? paso.paso : <img src={CheckIcon} alt='' />}
        </div>
      </div>
      <div className={classes.nameMarquer}>{paso.titulo}</div>
    </div>
  );
};

interface IPaso {
  estado: 'listo' | 'activo' | 'esperando';
  titulo: string;
  paso?: number;
}

interface ICardbien extends WithStyles<typeof style> {
  key?: any;
  marca: string;
  modelo: string;
  pasos: IPaso[];
  bandera: boolean;
  irRegistro: () => void;
}

const Bien: FC<ICardbien> = (props) => {
  const { classes, pasos, bandera, irRegistro, marca, modelo } = props;

  return (
    <div className={classes.cardBienContainer}>
      <div className={classes.columnNumber}>
        <div className={classes.numberContainer}>
          <span className={classes.numberIndicator}>1</span>
          {bandera && (<img src={FlagIcon} alt='' />)}
        </div>
        <span className={classes.smallFont}>Nro</span>
      </div>
      <div className={classes.sepatatorVertical} />
      <div className={classes.centerContent}>
        <div className={classes.centerContentUp}>
          <div className={classes.contentUp}>
            <span className={classes.middleFont}>
              <img src={CardIcon} alt='' />
            </span>
            <span className={classes.smallFont}>Bien</span>
          </div>
          <div className={classes.contentUp}>
            <span className={classes.middleFont}>
              <img src={OneIcon} alt='' />
            </span>
            <span className={classes.smallFont}>Tipo</span>
          </div>
          <div className={classes.contentUp}>
            <span className={classes.middleFont}>{marca}</span>
            <span className={classes.smallFont}>Bien</span>
          </div>
          <div className={classes.contentUp}>
            <span className={classes.middleFont}>{modelo}</span>
            <span className={classes.smallFont}>Modelo</span>
          </div>
        </div>
        {/* Down Options */}
        <div className={classes.centerContentDown}>
          {pasos.map((p, i) => (
            <React.Fragment>
              <CardDetalle paso={p} classes={classes} />
              {i !== pasos.length - 1 && <div className={classes.separadorMarquer}></div>}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={classes.sepatatorVertical} />
      <div className={classes.buttonContainer}>
        <Button variant='contained' color='primary' onClick={irRegistro}>Ver</Button>
        {/*{tipo === '2' && (<div className={classes.transferenciaText}>Transferencia</div>)}*/}
      </div>
    </div>
  );
};

export default withStyles(style)(Bien);

import dashboardFactoringState from './state';
import { fetchAction } from './actions';
import { ActionT } from '../../config/rootReducer';

export const reducer = (state = dashboardFactoringState, action :  ActionT<any>) => {
  switch (action.type) {
    case fetchAction.PENDING: {
      return Object.assign({}, state, action);
    }

    case fetchAction.SUCCESS: {
      return Object.assign({}, state, action);
    }

    default:
      return state;
  }
};

export default reducer;

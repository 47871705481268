//External
import React, { KeyboardEvent } from 'react';
// @ts-ignore
import SweetAlert from 'react-bootstrap-sweetalert';

import queryString from 'query-string';
import { connect } from 'react-redux';
import deburr from 'lodash/deburr';
import { ActionLogin, recoverCode, recoveryChangePassword, validateRecoverCode } from './actions';
import ReCAPTCHA from 'react-google-recaptcha';
import { formatRut, validaRut } from '../../../helpers/RutHelper';
import fxActions from '../fxEmpresa/actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// componentes material ui
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccountBox from '@material-ui/icons/AccountBox';
import PersonPin from '@material-ui/icons/PersonPin';
import Lock from '@material-ui/icons/Lock';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

// componentes custom
import LoginStyle from '../../../styles/LoginStyle';
import bgImage from '../../../images/servicios.jpg';
import logo from '../../../images/logo-factotal.svg';
import ModalComponent from '../../shared/ModalMsg/ModalMsg';
import factorxApi from '../../../services/factorxApi';
import { AppState } from '../../../config/rootReducer';
import { WithStyles } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { puedeEntrarSitioAdmin, puedeEntrarSitioAdminDirecto } from '../../../helpers/UserHelper';
//https://portalcliente.factotal.cl/?hv=WidZmLs

interface IProps extends WithStyles<typeof LoginStyle>, RouteComponentProps {
  login: (username: string, password: string) => Promise<Comun.Dto.LoginUserInfo | undefined>,
  handleLogin: (userInfo: Response.Login) => void
}

interface IState {
  captchaToken: boolean;
  rut: string;
  rutErrorMessage: string;
  isValidRut: boolean;
  password: string;
  passwordErrorMessage: string;
  isValidPassword: boolean;
  isSelectCompany: boolean;
  isEnlaceRecuperar: boolean;
  codigoRecuperar: string;
  alert: JSX.Element | undefined;
  rutRecuperar: string;
  rutRecuperarErrorMessage: string;
  isValidRutRecuperar: boolean;
  correoRecuperacion: string;
  openModal: boolean;
  isRecuperar: boolean;
  isValidcodigoCrearClave: boolean;
  codigoCrearClaveErrorMessage: string;
  rutCrear: string;
  codigoCrearClave: string;
  checkedG: boolean;
  buttonCheckboxTerms: boolean;
  isValidClaveNueva: boolean;
  claveNuevaErrorMessage: string;
  isValidClaveNuevaDos: boolean;
  claveNuevaDosErrorMessage: string;
  isNuevaContrasena: boolean;
  nuevaClave: string;
  isValidClave: boolean;
  nuevaClaveDos: string;
  isValidClaveDos: boolean;
  companyList: Dto.EnterpriseProfile[];
  companyListRender: Dto.EnterpriseProfile[];
  loginData: Comun.Dto.LoginUserInfo | undefined;
}

class Login extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);

    this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
    // this.verifyCallback = this.verifyCallback.bind(this);
    this.state = {
      alert: undefined,
      rut: '',
      rutCrear: '',
      rutRecuperar: '',
      password: '',
      nuevaClave: '',
      nuevaClaveDos: '',
      codigoCrearClave: '',
      codigoRecuperar: '',
      isRecuperar: false,
      isValidRutRecuperar: true,
      isNuevaContrasena: false,
      isEnlaceRecuperar: false,
      isValidcodigoCrearClave: true,
      codigoCrearClaveErrorMessage: '',
      isValidPassword: true,
      isValidRut: true,
      isValidClaveNueva: true,
      isValidClaveNuevaDos: true,
      passwordErrorMessage: '',
      claveNuevaErrorMessage: '',
      claveNuevaDosErrorMessage: '',
      captchaToken: true,
      buttonCheckboxTerms: true,
      openModal: false,
      isSelectCompany: false,
      companyList: [],
      companyListRender: [],
      loginData: undefined,
      checkedG: false,
      correoRecuperacion: '',
      isValidClave: false,
      isValidClaveDos: false,
      rutErrorMessage: '',
      rutRecuperarErrorMessage: ''
    };
    this.hideAlert = this.hideAlert.bind(this);
    this.showRecuperar = this.showRecuperar.bind(this);

  }

  captchaDemo: ReCAPTCHA | null = null;
  Boton = withRouter(({ history }) => (
      <button
          type='button'
          onClick={() => { history.push('/Subscriber') }}
      >
        Click Me!
      </button>
  ));
  terminarLogin = (userInfo: Response.Login) => {
    this.props.handleLogin(userInfo);
  };

  componentDidMount = () => {
    const valueCodigo = queryString.parse(window.location.href.split('?')[1]);
    if (valueCodigo && valueCodigo.hv)
      this.setState({
        isEnlaceRecuperar: true,
        codigoRecuperar: valueCodigo.hv.toString()
      });
    else
      this.setState({
        isEnlaceRecuperar: false
      });
    if (this.captchaDemo) {
      this.captchaDemo.reset();
      this.setState({ captchaToken: true });
    }
  };

  restablecerFormulario = () => {
    this.setState({
      rut: '',
      rutRecuperar: '',
      rutCrear: '',
      password: '',
      nuevaClave: '',
      nuevaClaveDos: '',
      isValidPassword: true,
      isValidRut: true,
      isValidClaveNueva: true,
      isValidRutRecuperar: true,
      isValidcodigoCrearClave: true,
      isValidClaveNuevaDos: true,
      passwordErrorMessage: '',
      claveNuevaErrorMessage: '',
      claveNuevaDosErrorMessage: '',
      codigoCrearClave: '',
      buttonCheckboxTerms: true,
      correoRecuperacion: ''
    });
  };

  onLoadRecaptcha = (value: any) => {
    console.log('Captcha value:', value);
    if (this.captchaDemo && value != null) {
      this.setState({ captchaToken: false });
    }
  };

  handleInputEnter = (e: KeyboardEvent<any>) => {
    if (e.key === 'Enter') this.handleClick(e as any);
  };

  handleClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    let _isValidRut = validaRut(this.state.rut);
    if (this.state.rut.trim() === '') {
      this.setState({
        rutErrorMessage: 'El RUT es requerido',
        isValidRut: false
      });
      _isValidRut = false;
    } else if (!_isValidRut) {
      this.setState({
        rutErrorMessage: 'El Rut no es válido',
        isValidRut: false
      });
      _isValidRut = false;
    } else {
      this.setState({
        rutErrorMessage: '',
        isValidRut: true
      });
      _isValidRut = true;
    }

    let _isValidPassword = false;

    if (this.state.password.trim() === '') {
      this.setState({
        passwordErrorMessage: 'La contraseña es requerida',
        isValidPassword: false
      });
    } else {
      this.setState({
        passwordErrorMessage: '',
        isValidPassword: true
      });
      _isValidPassword = true;
    }

    if (_isValidRut && _isValidPassword) {
      const userInfo = await this.props.login(this.state.rut, this.state.password);
      if (userInfo === undefined) return;

      if (userInfo.status === 200) {
        if (puedeEntrarSitioAdminDirecto(userInfo)) {
          this.terminarLogin(userInfo);
        } else if (userInfo.empresas.length > 1 || (userInfo.empresas.length === 1 && puedeEntrarSitioAdmin(userInfo))) {
          this.setState({
            isSelectCompany: true,
            companyList: userInfo.empresas,
            companyListRender: userInfo.empresas,
            loginData: userInfo
          });
        } else {
          this.terminarLogin(userInfo);
        }
      } else if (userInfo.status === 401) {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title={
                <Typography variant='h6' align='center' component='p' gutterBottom>
                  ¡Error!
                </Typography>
              }
              showConfirm={false}>
              <Typography variant='body1' gutterBottom component='p'>
                El rut o clave ingresada son incorrectas.
              </Typography>
              <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                      style={{ justifyContent: 'center' }}>Cerrar</Button>
            </SweetAlert>
          )
        });
      } else if (userInfo.status !== 200) {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title={
                <Typography variant='h6' align='center' component='p' gutterBottom>
                  ¡Error!
                </Typography>
              }
              showConfirm={false}>
              <Typography variant='body1' gutterBottom component='p'>
                Existen problemas al intentar ingresar, por favor intente más tarde.
              </Typography>
              <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                      style={{ justifyContent: 'center' }}>Cerrar</Button>
            </SweetAlert>
          )
        });
      }
    }
  };

  handleEnviaRecuperar = () => {
    let _isValidRutRecuperar = validaRut(this.state.rutRecuperar);

    if (this.state.rutRecuperar.trim() === '') {
      this.setState({
        rutRecuperarErrorMessage: 'El RUT es requerido',
        isValidRutRecuperar: false
      });
      _isValidRutRecuperar = false;
    } else if (!_isValidRutRecuperar) {
      this.setState({
        rutRecuperarErrorMessage: 'El Rut no es válido',
        isValidRutRecuperar: false
      });
      _isValidRutRecuperar = false;
    } else {
      this.setState({
        rutRecuperarErrorMessage: '',
        isValidRutRecuperar: true
      });
      _isValidRutRecuperar = true;
    }

    recoverCode({ rut: this.state.rutRecuperar })
      .then(r => {
        if (r && r.status === 200 && r.data) {
          if (_isValidRutRecuperar && !this.state.captchaToken) {
            this.setState({
              correoRecuperacion: r.data,
              captchaToken: true,
              openModal: true
            });
          }
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: 'block', marginTop: '-100px' }}
                title={
                  <Typography variant='h6' align='center' component='p' gutterBottom>
                    ¡Error al intentar recuperar clave!
                  </Typography>
                }
                showConfirm={false}>
                <Typography variant='body1' gutterBottom component='p'>
                  El rut ingresado no se encuentra registrado.
                </Typography>
                <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                        style={{ justifyContent: 'center' }}>Cerrar</Button>
              </SweetAlert>
            ),
            isRecuperar: false,
            captchaToken: true
          });
        }
      });
  };

  handleEnviaValidaCodigo = () => {
    validateRecoverCode({ token: this.state.codigoRecuperar })
      .then(r => {
        if (r && r.status === 200) {
          this.setState({ isNuevaContrasena: true, isEnlaceRecuperar: false });
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: 'block', marginTop: '-100px' }}
                title={
                  <Typography variant='h6' align='center' component='p' gutterBottom>
                    ¡Código incorrecto!
                  </Typography>
                }
                showConfirm={false}>
                <Typography variant='body1' gutterBottom component='p'>
                  El código ingresado no se encuentra registrado.
                </Typography>
                <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                        style={{ justifyContent: 'center' }}>Cerrar</Button>
              </SweetAlert>
            ),
            isNuevaContrasena: false
          });
        }
      });
  };

  handleCambiaClave = () => {
    let _isValidClave = this.validatePassword(this.state.nuevaClave);
    let _isValidClaveDos = this.validatePassword(this.state.nuevaClave);

    if (this.state.nuevaClave.trim() === '') {
      this.setState({
        claveNuevaErrorMessage: 'La contraseña es requerida',
        isValidClave: false
      });

      _isValidClave = false;
    } else if (!_isValidClave) {
      this.setState({
        claveNuevaErrorMessage: 'La contraseña debe ser de un largo mínimo de 6 caracteres, además de contener al menos una mayúscula o un caracter, y un número',
        isValidClave: false
      });
    } else {
      this.setState({
        claveNuevaErrorMessage: '',
        isValidClave: true
      });
      _isValidClave = true;
    }

    if (this.state.nuevaClaveDos.trim() === '') {
      this.setState({
        claveNuevaDosErrorMessage: 'La contraseña es requerida',
        isValidClaveDos: false
      });

      _isValidClaveDos = false;
    } else if (!_isValidClaveDos) {
      this.setState({
        claveNuevaDosErrorMessage: 'La contraseña debe ser de un largo mínimo de 6 caracteres, además de contener al menos una mayúscula o un caracter, y un número',
        isValidClaveDos: false
      });
    } else {
      this.setState({
        claveNuevaDosErrorMessage: '',
        isValidClaveDos: true
      });
      _isValidClaveDos = true;
    }

    if (_isValidClave && _isValidClaveDos) {
      if (this.state.nuevaClaveDos.trim() !== this.state.nuevaClave.trim()) {
        this.setState({
          claveNuevaDosErrorMessage: 'Las contraseñas deben ser iguales',
          isValidClaveDos: false
        });
      } else {
        recoveryChangePassword({
          token: this.state.codigoRecuperar,
          newPassword: this.state.nuevaClave,
          confirmNewPassword: this.state.nuevaClaveDos
        })
          .then((r) => {
            if (r && r.status === 200) {
              this.setState({
                alert: (
                  <SweetAlert
                    success
                    style={{ display: 'block', marginTop: '-100px' }}
                    title={
                      <Typography variant='h6' align='center' component='p' gutterBottom>
                        ¡Felicitaciones!
                      </Typography>
                    }
                    showConfirm={false}>
                    <Typography variant='body1' gutterBottom component='p'>
                      Su contraseña ha sido modificada con éxito. Puede ingresar sin problemas.
                    </Typography>
                    <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                            style={{ justifyContent: 'center' }}>Cerrar</Button>
                  </SweetAlert>
                ),
                isNuevaContrasena: false
              }, () => {
                window.history.replaceState({}, document.title, '/');
              });
            } else {
              this.setState({
                alert: (
                  <SweetAlert
                    error
                    style={{ display: 'block', marginTop: '-100px' }}
                    title={
                      <Typography variant='h6' align='center' component='p' gutterBottom>
                        Error al intentar cambiar la clave
                      </Typography>
                    }
                    showConfirm={false}>
                    <Typography variant='body1' gutterBottom component='p'>
                      Vuelva a intentarlo mas tarde, o contacte a soporte.
                    </Typography>
                    <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                            style={{ justifyContent: 'center' }}>Cerrar</Button>
                  </SweetAlert>
                ),
                isNuevaContrasena: false
              });
            }
          });
      }
    }
  };

  showRecuperar() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title={
            <Typography variant='h6' align='center' component='p' gutterBottom>
              ¡Código de recuperación!
            </Typography>
          }
          showConfirm={false}>
          <Typography variant='body1' gutterBottom component='p'>
            Un mensaje con el código ha sido enviado a su correo electrónico edfr........tg@fthnjltda.cl registrado en
            el sitio.
          </Typography>
          <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                  style={{ justifyContent: 'center' }}>Cerrar</Button>
        </SweetAlert>
      ),
      isRecuperar: false
    });
  }

  hideAlert = () => this.setState({ alert: undefined });

  handleAcitivaRecuperar = () => {
    this.setState({ isRecuperar: true });
    this.restablecerFormulario();
  };
  handleRegistroDeUsuarios = () => {
    this.props.history.push('/Subscriber');
    this.restablecerFormulario();
  };

  handleDesactivaRecuperar = () => this.setState({ isRecuperar: false, captchaToken: true }, () => {
    window.location.href = '/';
  });

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRut = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: formatRut(e) });
  };

  validatePassword = (password: string) => {
    let validated = true;

    if (password.length < 6) {
      validated = false;
    } else if (!/[^a-zA-Z]/.test(password)) {
      validated = false;
    } else if (!/[^0-9]/.test(password)) {
      validated = false;
    }

    return validated;
  };

  selectCompanyContent = () => {
    let cardGenerator = () => this.state.companyListRender.map((e, i) =>
      <Grid item xs={6} key={i}>
        <Card className={this.props.classes.cardSelectCompany} onClick={(event) => this.onClickCompanyCard(e)}>
          <CardContent classes={{
            root: this.props.classes.cardContentRoot
          }}>
            <Typography component='h3' color='primary'>
              {e.socialReason}
            </Typography>
            <Typography component='h3'>
              {e.rut + '-' + e.dv}
            </Typography>
            <Typography component='h3'>
              Perfil: {e.executiveTypeDescription} ({e.leasing ? ' Leasing' : ''} {e.factoring ? ' Factoring' : ''} {e.vendor ? ' Leasing tec.' : ''} {e.registroCivil ? ' Registro civil' : ''})
            </Typography>
          </CardContent>
        </Card>
      </Grid>);
    const adminCard = () => this.state.loginData !== undefined && puedeEntrarSitioAdmin(this.state.loginData) &&
      <Grid item xs={6}>
        <Card className={this.props.classes.cardSelectCompany} onClick={() => this.onClickCompanyCard(undefined)}>
          <CardContent classes={{ root: this.props.classes.cardContentRoot }}>
            <Typography component='h3' color='primary'>Sitio admin</Typography>
          </CardContent>
        </Card>
      </Grid> || <></>;
    const _content = <Grid item xs={9} style={{ padding: '2rem' }}>
      <Grid container style={{ marginBottom: 15 }}>
        <Grid item xs={6}>
          <Typography component='h2' color='primary' style={{ marginBottom: 15 }}>
            Seleccione una empresa
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <TextField
            style={{
              width: '100%'
            }}
            placeholder='Buscar empresa'
            id='simple-start-adornment'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end' style={{ margin: 0 }}>
                  <IconButton>
                    <SearchIcon style={{ width: 20, height: 20 }} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={e => this.getSuggestions(e)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={24} direction='row' justify='flex-start' alignItems='flex-start'
            className={this.props.classes.containerCardsCompany}>
        {adminCard()}
        {cardGenerator()}
      </Grid>
    </Grid>;
    setTimeout(() => {
      window.location.href = '/';
    }, 180000);
    return _content;
  };

  getSuggestions = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    let value = e.currentTarget.value;
    const inputValue = deburr(value.trim())
      .toLowerCase();
    const inputLength = inputValue.length;
    let dataReturn = inputLength === 0
      ? this.state.companyList
      : this.state.companyList.filter(suggestion => {
        let _socialReason = suggestion.socialReason.toLowerCase()
                                      .indexOf(inputValue.toLowerCase()) >= 0;
        let _rutEnterprise = (suggestion.rut.toString() + '-' + suggestion.rut.toString()).toLowerCase()
                                                                                          .indexOf(inputValue.toLowerCase()) >= 0;
        return _socialReason || _rutEnterprise;
      });
    this.setState({ companyListRender: dataReturn });
  };

  onClickCompanyCard = (e: Dto.EnterpriseProfile | undefined) => {
    let _loginData = this.state.loginData;
    if (_loginData) {
      _loginData.empresas = e === undefined ? [] : [e];
      _loginData.isValid = true;
      sessionStorage.setItem('user', JSON.stringify(_loginData));
      if (_loginData.empresas.length > 0) {
        factorxApi.registrarEmpresa(_loginData)
                  .then((respuesta) => {
                    if (respuesta.exito) {
                      sessionStorage.setItem('fx-token', respuesta.token);
                      sessionStorage.setItem('fx-id', JSON.stringify(respuesta.idEmpresaPais));
                      fxActions.agendarRenovacion();
                      if (_loginData) {
                        this.terminarLogin(_loginData);
                      }
                    }
                  });
      } else {
        this.terminarLogin(_loginData);
      }
    }
  };

  render() {
    const { classes } = this.props;
    const { openModal, correoRecuperacion } = this.state;
    return (
      <React.Fragment>
        <CssBaseline />
        {this.state.alert}
        <div
          className={classes.fullPage}
          style={{ backgroundImage: 'url(' + bgImage + ')' }}
        >
          <Grid
            container
            justify='center'
            style={{
              marginBottom: 'auto',
              marginTop: 'auto',
              minWidth: this.state.isSelectCompany || this.state.isEnlaceRecuperar || this.state.isNuevaContrasena || this.state.isRecuperar ? '990px' : '700px'
            }}>
            <Grid item xs={12}>
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <Grid container>
                    <Grid
                      item
                      xs={this.state.isSelectCompany || this.state.isEnlaceRecuperar || this.state.isNuevaContrasena || this.state.isRecuperar ? 3 : 4}
                      className={classes.gridLoginImage}
                      style={{}}
                    >
                      <img className={classes.media} src={logo} alt='...' />
                    </Grid>
                    {!this.state.isSelectCompany ?
                      !this.state.isEnlaceRecuperar ?
                        !this.state.isNuevaContrasena ?
                          !this.state.isRecuperar ? (
                            <Grid item xs={8} className={classes.gridLoginContent} key='LoginForm'>
                              <Paper className={classes.paper}>
                                <PersonPin className={classes.icon} />
                                <Typography variant='h3' style={{
                                  color: '#2E2A25',
                                  fontSize: '2rem',
                                  lineHeight: '3.5rem'
                                }}>Bienvenido</Typography>
                                <Typography variant='caption' paragraph style={{
                                  color: '#555759',
                                  fontSize: '1rem',
                                  marginBottom: '0',
                                  lineHeight: '1.2rem'
                                }}>
                                  Ingrese sus datos para poder acceder.
                                </Typography>
                              </Paper>
                              <div>
                                <Grid container spacing={8} alignItems='flex-end'>
                                  <Grid item xs={12}>
                                    <FormControl required fullWidth>
                                      <InputLabel shrink htmlFor='rut'>Rut usuario</InputLabel>
                                      <Input
                                        classes={{
                                          // adornedStart: classes.loginFormAdornedStart,
                                          focused: classes.loginFormInputFocused
                                        }}
                                        inputProps={{
                                          maxLength: 10,
                                          style: { paddingLeft: 10, paddingRight: 10 }
                                        }}
                                        error={!this.state.isValidRut}
                                        onChange={this.onChangeRut}
                                        id='rut'
                                        name='rut'
                                        // label="Ingrese su RUT"
                                        placeholder='Ingrese su RUT'
                                        autoFocus
                                        // maxLength={10}
                                        startAdornment={<InputAdornment position='start'
                                                                        style={{
                                                                          paddingTop: 18,
                                                                          paddingBottom: 18
                                                                        }}><AccountBox
                                          className={classes.iconLine} /></InputAdornment>}
                                        onKeyPress={this.handleInputEnter}
                                      />
                                      <FormHelperText id='component-error-text'
                                                      error>{this.state.rutErrorMessage}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </div>
                              <div>
                                <Grid container spacing={8} alignItems='flex-end'>
                                  <Grid item xs={12}>
                                    <FormControl required fullWidth>
                                      <InputLabel shrink htmlFor='password'>Contraseña</InputLabel>
                                      <Input
                                        classes={{
                                          // adornedStart: classes.loginFormAdornedStart,
                                          focused: classes.loginFormInputFocused
                                        }}
                                        inputProps={{
                                          style: { paddingLeft: 10, paddingRight: 10 }
                                        }}
                                        error={!this.state.isValidPassword}
                                        onChange={this.onChange}
                                        name='password'
                                        type='password'
                                        id='password'
                                        // label="Ingrese su contraseña"
                                        placeholder='Ingrese su contraseña'
                                        startAdornment={<InputAdornment position='start'
                                                                        style={{ paddingTop: 18, paddingBottom: 18 }}><Lock
                                          className={classes.iconLine} /></InputAdornment>}
                                        autoComplete='current-password'
                                        onKeyPress={this.handleInputEnter} />
                                      <FormHelperText id='component-error-text'
                                                      error>{this.state.passwordErrorMessage}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button
                                        size='small'
                                        variant='text'
                                        disableRipple={true}
                                        classes={{
                                          root: classes.buttonSubscriberRoot
                                        }}
                                        onClick={this.handleRegistroDeUsuarios}>
                                      Crea tu cuenta
                                    </Button>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Button
                                        size='small'
                                        variant='text'
                                        disableRipple={true}
                                        classes={{
                                          root: classes.buttonForgotPwdRoot
                                        }}
                                        onClick={this.handleAcitivaRecuperar}>
                                      ¿Olvidó su contraseña?
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>
                              <Button variant='contained' className={classes.button}
                                      onClick={this.handleClick}>Ingresar</Button>
                            </Grid>
                          ) : (
                            <Grid item
                                  xs={this.state.isEnlaceRecuperar || this.state.isNuevaContrasena || this.state.isRecuperar ? 9 : 8}
                                  className={classes.gridLoginContent} key='SolicitudRecuperacion'>
                              <Paper className={classes.paper} style={{ padding: '35px 8px' }}>
                                <Typography variant='h3' style={{
                                  color: '#2E2A25',
                                  fontSize: '2rem',
                                  lineHeight: '3.5rem',
                                  textAlign: 'left' as 'left',
                                  marginRight: 'auto'
                                }}>Solicitud para recuperación de clave</Typography>
                                <Typography variant='caption' paragraph style={{
                                  color: '#555759',
                                  fontSize: '1rem',
                                  marginBottom: '0',
                                  lineHeight: '1.2rem',
                                  textAlign: 'left' as 'left',
                                  marginRight: 'auto',
                                  marginTop: 15
                                }}>
                                  Si olvidó su clave podrá recuperarla de manera rápida y segura.
                                  <br />Por favor ingrese su RUT de usuario y siga las instrucciones.
                                </Typography>
                              </Paper>
                              <div className={classes.margin}>
                                <Grid container spacing={8} alignItems='flex-end'>
                                  <Grid item xs={5}>
                                    <FormControl margin='normal' required fullWidth style={{ margin: 0 }}>
                                      <InputLabel shrink htmlFor='rutRecuperar'>Rut usuario</InputLabel>
                                      <Input
                                        inputProps={{
                                          maxLength: 10
                                        }}
                                        error={!this.state.isValidRutRecuperar}
                                        onChange={this.onChangeRut}
                                        id='rutRecuperar'
                                        name='rutRecuperar'
                                        // maxLength={10}
                                        placeholder='Ingrese su RUT usuario'
                                      />
                                      <FormHelperText id='component-error-text'
                                                      error>{this.state.rutRecuperarErrorMessage}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={7} style={{ marginBottom: 17 }}>
                                    <ReCAPTCHA
                                      ref={(el) => {
                                        this.captchaDemo = el;
                                      }}
                                      size='normal'
                                      data-theme='dark'
                                      // render="explicit"
                                      sitekey='6LdNS3oUAAAAAAFw5159Nf5JSIKma6vp1Wdmc5Mo'
                                      onChange={this.onLoadRecaptcha}
                                      hl={'es'}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div className={classes.margin}>
                                <Grid container spacing={8} alignItems='flex-end'>
                                  <Grid item xs={12} style={{}}>
                                    <ModalComponent
                                      openModal={openModal}
                                      title='¡Código de recuperación!'
                                      text={`Un mensaje con el código ha sido enviado a su correo electrónico ${correoRecuperacion} registrado en el sitio.`}
                                      closeModalMsg={() => {
                                        this.setState({ openModal: false, isRecuperar: false });
                                      }} />
                                    <Button
                                      variant='contained'
                                      className={classes.button}
                                      style={{
                                        marginRight: 0,
                                        marginLeft: 0,
                                        float: 'right'
                                      }}
                                      disabled={this.state.captchaToken}
                                      onClick={this.handleEnviaRecuperar}>
                                      Continuar
                                    </Button>
                                    <Button
                                      className={classes.buttonOutLined}
                                      variant='outlined'
                                      color='primary'
                                      style={{
                                        marginRight: '1rem',
                                        marginLeft: 0,
                                        float: 'right'
                                      }}
                                      onClick={this.handleDesactivaRecuperar}
                                    >
                                      Cancelar
                                    </Button>
                                  </Grid>
                                  <Grid item xs={12} style={{ marginBottom: 15 }}>
                                    <Paper
                                      elevation={1}
                                      style={{
                                        boxShadow: 'none',
                                        padding: '25px 50px 20px',
                                        backgroundColor: '#E5E6E6',
                                        marginTop: '.5rem'
                                      }}
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        component='p'
                                        style={{ color: '#608C00' }}
                                        gutterBottom>
                                        <strong>IMPORTANTE</strong>
                                      </Typography>
                                      <Typography variant='body2' paragraph style={{ padding: '5px 5px 5px 5px', margin: 0 }}
                                                  gutterBottom>
                                        <small>
                                          Sus claves de acceso y de seguridad son personales e intransferibles y debe hacer un
                                          buen uso de ellas.
                                          <br /><br /> FACTOTAL no almacena ni registra las claves secretas del cliente.
                                          <br /><br /> Ningún colaborador de FACTOTAL requiere ni tiene necesidad de conocer
                                          claves secretas o cualquier información.
                                        </small>
                                      </Typography>
                                    </Paper>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          ) : (
                            <Grid item xs={9} className={classes.gridLoginContent} key='NuevaContrasena'>
                              <Paper className={classes.paper}>
                                <PersonPin className={classes.icon} />
                                <Typography variant='h3' style={{ color: '#2E2A25', fontSize: '2rem', lineHeight: '3.5rem' }}>Ingrese
                                  su nueva clave</Typography>
                                <Typography variant='caption' paragraph style={{
                                  color: '#555759',
                                  fontSize: '1rem',
                                  marginBottom: '0',
                                  lineHeight: '1.2rem'
                                }}>
                                  Su nueva clave debe tener como mínimo 6 caracteres distribuidos en letras y números.
                                </Typography>
                              </Paper>
                              <div className={classes.margin}>
                                <Grid container spacing={8} alignItems='flex-end'>
                                  <Grid item xs={6}>
                                    <FormControl margin='normal' required fullWidth>
                                      <InputLabel htmlFor='nuevaClave'>Nueva clave</InputLabel>
                                      <Input
                                        inputProps={{
                                          maxLength: 24
                                        }}
                                        error={!this.state.isValidClaveNueva}
                                        onChange={this.onChange}
                                        id='nuevaClave'
                                        name='nuevaClave'
                                        type='password'
                                        // maxLength={24}
                                      />
                                      <FormHelperText id='component-error-text'
                                                      error>{this.state.claveNuevaErrorMessage}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <FormControl margin='normal' required fullWidth>
                                      <InputLabel htmlFor='nuevaClaveDos'>Vuelva a escribir su clave</InputLabel>
                                      <Input
                                        inputProps={{
                                          maxLength: 24
                                        }}
                                        error={!this.state.isValidClaveNuevaDos}
                                        onChange={this.onChange}
                                        id='nuevaClaveDos'
                                        name='nuevaClaveDos'
                                        autoFocus
                                        type='password'
                                        // maxLength={24}
                                      />
                                      <FormHelperText id='component-error-text'
                                                      error>{this.state.claveNuevaDosErrorMessage}</FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} style={{}}>
                                    <Button
                                      variant='contained'
                                      className={classes.button}
                                      style={{
                                        marginRight: 0,
                                        marginLeft: 0,
                                        float: 'right'
                                      }}
                                      onClick={this.handleCambiaClave}>
                                      CAMBIAR CLAVE
                                    </Button>
                                    <Button
                                      className={classes.buttonOutLined}
                                      variant='outlined'
                                      color='primary'
                                      style={{
                                        marginRight: '1rem',
                                        marginLeft: 0,
                                        float: 'right'
                                      }}
                                      onClick={this.handleDesactivaRecuperar}
                                    >
                                      Cancelar
                                    </Button>
                                  </Grid>
                                </Grid>
                              </div>

                            </Grid>
                          ) : (
                          <Grid item xs={9} className={classes.gridLoginContent} key='ValidaContrasenaTemporal'>
                            <Paper className={classes.paper}>
                              <PersonPin className={classes.icon} />
                              <Typography variant='h3' style={{ color: '#2E2A25', fontSize: '2rem', lineHeight: '3.5rem' }}>Ingrese
                                el código de seguridad</Typography>
                              <Typography variant='caption' paragraph style={{
                                color: '#555759',
                                fontSize: '1rem',
                                marginBottom: '0',
                                lineHeight: '1.2rem'
                              }}>
                                Ingrese el código de seguridad enviado a su correo electrónico.
                              </Typography>
                            </Paper>
                            <div className={classes.margin}>
                              <Grid container spacing={8} alignItems='flex-end'>
                                <Grid item xs={6}>
                                  <FormControl margin='normal' required fullWidth>
                                    <InputLabel shrink htmlFor='tokenClave'>Código</InputLabel>
                                    <Input
                                      onChange={this.onChange}
                                      id='tokenClave'
                                      name='tokenClave'
                                      autoFocus
                                      value={this.state.codigoRecuperar}
                                      placeholder='Ingrese el código'
                                    />
                                    <FormHelperText id='component-error-text'
                                                    error>{this.state.rutRecuperarErrorMessage}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <Button
                                    variant='contained'
                                    className={classes.button}
                                    style={{
                                      marginRight: 0,
                                      marginLeft: 0,
                                      float: 'right'
                                    }}
                                    onClick={this.handleEnviaValidaCodigo}>
                                    Continuar
                                  </Button>
                                  <Button
                                    className={classes.buttonOutLined}
                                    variant='outlined'
                                    color='primary'
                                    style={{
                                      marginRight: '1rem',
                                      marginLeft: 0,
                                      float: 'right'
                                    }}
                                    onClick={this.handleDesactivaRecuperar}
                                  >
                                    Cancelar
                                  </Button>
                                </Grid>
                              </Grid>
                            </div>
                            <div className={classes.margin}>
                              <Grid container spacing={8} alignItems='flex-end'>
                                <Grid item xs={12}>
                                  <Paper elevation={1}>
                                    <Typography variant='subtitle2' component='p' style={{ padding: '5px 5px 5px 5px' }}
                                                gutterBottom>
                                      <strong>Importante.</strong>
                                    </Typography>
                                    <Typography variant='body2' paragraph style={{ padding: '5px 5px 5px 5px' }} gutterBottom>
                                      <small>
                                        - Sus claves de acceso y de seguridad son personales e intransferibles y debe hacer
                                        un buen uso de ellas.
                                        <br />- FACTOTAL no almacena ni registra las claves secretas del cliente.
                                        <br />- Ningún colaborador de FACTOTAL requiere ni tiene necesidad de conocer claves
                                        secretas o cualquier información.
                                      </small>
                                    </Typography>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        ) : (
                        this.selectCompanyContent()
                      )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <footer>
            <Typography variant='subtitle1' align='center' component='p' className={classes.textoFooterLogin}>
              {'. Todos los derechos reservados a '}<a
              href='https://www.factotal.com'>{'@ '}{new Date().getFullYear()}{' Factotal S.A.'}</a>
            </Typography>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  login: (username: string, password: string) => dispatch(ActionLogin({ username: username, password: password }))
});

export default withStyles(LoginStyle)(connect(mapStateToProps, mapDispatchToProps)(withRouter(Login)));


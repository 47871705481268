import React from 'react';

export const validarRangoNroDoc = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

  // @ts-ignore
  let nroDocDesde = document.getElementById('numDocumentosFrom').value;
  // @ts-ignore
  let nroDocHasta = document.getElementById('numDocumentosTo').value;

  if (parseInt(nroDocDesde) > parseInt(nroDocHasta)) e.target.value = '';
};

export const validateNumerico = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const targetNum = Number(e.target.value);
  if (isNaN(targetNum)) e.target.value = '';
  if (targetNum <= 0) e.target.value = '';
};


export const onChangeRangNumericHelper = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  const pre = e.target.value.split('-');
  let valor = pre[0].length > 0 ? (pre[0].replace(/[^0-9]+/gi, '')
                                         .replace('(', '')
                                         .replace(')', '')
                                         .replace('|', '')) : ('');
  if (pre.length > 1)
    valor = valor + '-' + pre[1].replace(/[^0-9]+/gi, '')
                                .replace('(', '')
                                .replace(')', '')
                                .replace('|', '');
  return valor;
};

export const onChangeOnlyNumericHelper = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  return e.target.value.replace(/[^0-9]+/gi, '')
          .replace('(', '')
          .replace(')', '')
          .replace('|', '');
};

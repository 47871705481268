import FactotalTheme from '../../FactotalTheme';
import ytOff from '../../../images/icons/yt-off.svg';
import ytOn from '../../../images/icons/yt-on.svg';
import { Theme } from '@material-ui/core';

const tableStyle = (theme : Theme) => ({
  table: {
    marginTop: '2px',
    borderSpacing: '0 2px',
    borderCollapse: 'separate' as 'separate'
  },
  thead: {
    backgroundColor: FactotalTheme.palette.grey[200],
    textAlign: 'center' as 'center'
  },
  trow: {
    height: '31px'
  },
  cell: {
    padding: '0px 16px 0px 16px',
    color: '#FFFFFF',
    fontSize: '10px',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'normal' as 'normal',
    letterSpacing: '1px',
    height: '31px'
  },
  rowBody: {
    height: '31px',
    color: FactotalTheme.palette.grey[500],
    fontSize: '12px',
    borderBottom: 'none',
    '& td:last-child': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  cellBody: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    padding: '0px 16px 0px 16px'
  },
  centerText: {
    textAlign: 'center' as 'center'
  },
  ytPreviewBox: {
    width: '197px',
    height: '111px',
    backgroundSize: 'cover',
    position: 'relative' as 'relative',
    margin: '16px'
  },
  ytPreviewHover: {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    '& div': {
      backgroundImage: `url(${ytOff})`,
      transition: 'all 0.3s ease-in-out',
      width: '45px',
      height: '32px'
    },
    '&:hover': {
      '& div': {
        backgroundImage: `url(${ytOn})`,
        transition: 'all 0.3s ease-in-out'
      }
    }
  },
  descColumn: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  descColumnTitle: {
    fontWeight: 'normal' as 'normal',
    fontSize: '17px',
    lineHeight: '20px',
    color: '#95989A',
    marginTop: '8px'
  },
  descColumnLitle: {
    fontWeight: 'normal' as 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: '#95989A'
  },
  buttonEliminar: {
    fontWeight: 'normal' as 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center' as 'center',
    textDecorationLine: 'underline',
    color: '#CD2F2F'
  },
  buttonAgregar: {
    fontWeight: 'normal' as 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center' as 'center',
    textDecorationLine: 'underline',
    color: '#82BC00'
  },
  tooltip: {
    fontWeight: 'normal' as 'normal',
    fontSize: '11px',
    lineHeight: '15px',
    textAlign: 'center' as 'center',
    color: '#FFFFFF'
  },
  contentInfoServicio: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: '#555759',
      width: '13.33px',
      height: '13.33px',
      padding: '8px',
      cursor: 'pointer'
    }
  }
});

export default tableStyle;

import { Button, Theme } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC } from 'react';
import CardBien from './bien';
import routes from '../../../config/routes';
import { useHistory } from 'react-router';

const style = (theme: Theme) => ({
  bienesContratoContainer: {
    backgroundColor: '#F4F4F4',
    width: '100%',
    height: '100%',
    padding: '16px',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    borderRadius: '8px'
  },
  header: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between'
  },
  titleHeader: {
    fontSize: '14px',
    lineHeight: '19px',
    color: '#555759',
    '& span': {
      fontWeight: 600
    }
  },
  buttonGreen: {
    fontSize: '11px',
    lineHeight: '17px',
    color: '#82BC00',
    textTransform: 'capitalize' as 'capitalize',
    fontWeight: 300
  },
  contentBienes: {
    height: '280px',
    overflowY: 'auto' as 'auto',
    paddingTop: '16px'
  }
});

interface IProps extends WithStyles<typeof style> {
  contrato: Dto.RegistroCivil.Contrato;
  key?: any;
  contratoid?: any;
  onAgregarBien: () => any;
}

const Bienes: FC<IProps> = (props) => {

  const { classes, contrato, onAgregarBien } = props;
  const history = useHistory();

  // const onVerRegistro = () => {
  //   if (tipo === 'vehiculo') {
  //     history.push(routes.registroVehiculos.primeraInscripcion('123123', tipo));
  //   } else if (tipo === '2') {
  //     history.push(routes.registroVehiculos.transferencia('123123'));
  //   }
  // };

  const totalBienes= contrato.inscripciones.length + contrato.transferencias.length;

  return (
    <div className={classes.bienesContratoContainer}>
      <div className={classes.header}>
        <div className={classes.titleHeader}>El contrato <span>{contrato.numero}</span> posee <span>{totalBienes}</span> bienes.</div>
        <Button
          variant='outlined'
          className={classes.buttonGreen}
          color='primary'
          onClick={onAgregarBien}
        >
          Agregar Bien
        </Button>
      </div>
      <div className={classes.contentBienes}>
        {contrato.inscripciones.map((ins, idx) =>
          <CardBien
            irRegistro={() => {
              history.push(routes.registroVehiculos.primeraInscripcion(ins.idInscripcion.toString(), ins.tipoVehiculo));
            }}
            marca={ins.vehiculo.marca}
            modelo={ins.vehiculo.modelo}
            bandera={true}
            key={idx}
            pasos={[
              {
                estado: 'listo',
                titulo: 'Creación'
              },
              {
                estado: 'listo',
                titulo: 'Factura'
              },
              {
                estado: 'listo',
                titulo: 'Inscripción'
              },
              {
                estado: 'activo',
                titulo: 'Pago',
                paso: 4
              },
              {
                estado: 'esperando',
                titulo: 'CAV',
                paso: 5
              }
            ]} />)}
        {contrato.transferencias.map((trans, idx) =>
          <CardBien
            irRegistro={() => {
              history.push(routes.registroVehiculos.transferencia(trans.idTransferencia.toString(), 'vehiculo'));
            }}
            marca={trans.vehiculo.marca}
            modelo={trans.vehiculo.modelo}
            bandera={true}
            key={idx}
            pasos={[
              {
                estado: 'listo',
                titulo: 'Creación'
              },
              {
                estado: 'listo',
                titulo: 'Impuesto'
              },
              {
                estado: 'listo',
                titulo: 'Pago'
              },
              {
                estado: 'listo',
                titulo: 'CAV'
              }
            ]} />)}
      </div>
    </div>
  );
};

export default withStyles(style)(Bienes);

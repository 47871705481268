import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import HistoricoStyle from "../../../../styles/jss/components/extendedFormsStyle";
import React, {FC, useEffect} from "react";
import TableContainer from "../../../sharedv2/Table/TableContainer";

interface IHistorialTransferenciaTabla extends WithStyles<typeof HistoricoStyle> {
  historial: Dto.RegistroCivil.HistorialTransferencia[];
}

const HistorialTransferenciaTabla: FC<IHistorialTransferenciaTabla> = (props) =>{
  const { classes, historial } = props;

  //useEffect(() => {}, []);

  const tableInfo = [
    /*{
      column: 'Nro',
      relation: 'idDocumento',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: any) => documento.idDocumento,
    },*/
    {
      column: 'Nro Operación',
      relation: 'nroOperacion',
      renderData: (historial: Dto.RegistroCivil.HistorialTransferencia) => historial.idTransferencia,
    },
    {
      column: 'Detalle',
      relation: 'detalle',
      renderData: (historial: Dto.RegistroCivil.HistorialTransferencia) => historial.observaciones,
    },
    {
      column: 'Fecha',
      relation: 'fecha',
      renderData: (historial: Dto.RegistroCivil.HistorialTransferencia) => historial.fecha,
    },
    /*{
      column: 'Estado',
      relation: 'estado',
      renderData: (documento: any) => documento.estado,
    }*/
  ];

  const obtenerOperaciones = (pagina: number, tamano: number) => {
    console.log('obtenerOperaciones');
  };

  return <TableContainer classes={classes} data={historial} tableInfo={tableInfo} obtenerDatos={obtenerOperaciones} pagination={undefined} />;
};

export default withStyles(HistoricoStyle)(HistorialTransferenciaTabla);
import React, { FC } from 'react';
import { Button, Card, Theme, WithStyles, withStyles } from '@material-ui/core';
import scssTool from '../../../styles/scssTool';
import IcoFactotal from '../../../images/ico-factotal.svg';
import IcoGreenCarpeta from '../../../images/ico-green-carpeta.svg';
import IcoGreenDeuda from '../../../images/ico-green-deuda.svg';
import IcoGreenFacturacion from '../../../images/ico-green-facturacion.svg';
import IcoRegistroCesiones from '../../../images/icons/ico-registro-cesiones.svg';
import FactotalTheme from '../../../styles/FactotalTheme';
import routes from '../../../config/routes';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const styleComparteDatos = (theme: Theme) => ({
  containerComparteDatos: {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    width: '100%'
  },
  infoC: {
    ...scssTool.flex('center', 'row', 'space-between')
  },
  infoT: {
    ...scssTool.font(16, 600, 22),
    padding: '23px 25px',
    fontFamily: scssTool.family.lato,
    width: '50%'
  },
  buttonFtc: {
    width: '160px',
    height: '40px',
    background: FactotalTheme.palette.primary.main,
    borderRadius: '0px',
    '&:hover': {
      background: FactotalTheme.palette.primary.main
    }
  },
  buttonFtcLabel: {
    ...scssTool.font(13, 600, 19),
    color: '#FFFFFF',
    fontFamily: scssTool.family.lato
  },
  icoBtnFtc: {
    marginLeft: '5px'
  },
  containerBtnFtc: {
    ...scssTool.flex('center', 'row', 'center'),
    padding: '0px 22px',
    width: 'auto'
  },
  greenContainer: {
    background: '#F5FFE0',
    borderTop: '1px solid #82BC00',
    padding: '12px 32px',
    ...scssTool.flex('center', 'row', 'space-between'),
    borderRadius: '0px 0px 10px 10px'
  },
  itemgreen: {
    ...scssTool.flex('center', 'row', 'center'),
    [theme.breakpoints.down('md')]: {
      ...scssTool.flex('center', 'column', 'center')
    }
  },
  spanItem: {
    ...scssTool.font(12, 800, 14),
    color: '#686B6D',
    fontFamily: scssTool.family.lato,
    marginLeft: '16px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
      marginTop: '8px'
    }
  }
});

interface IComparteDatos extends WithStyles<typeof styleComparteDatos>, RouteComponentProps {
}

const ComparteDatos: FC<IComparteDatos> = (props) => {
  const { classes } = props;

  const handleClick = () => {
    props.history.push(routes.user.subscripciones);
  };

  return (
    <Card className={classes.containerComparteDatos}>
      <div className={classes.infoC}>
        <div className={classes.infoT}>
          Comparte tus datos para acelerar tus <strong>curses</strong>
        </div>
        <div className={classes.containerBtnFtc}>
          <Button
            onClick={handleClick}
            classes={{ root: classes.buttonFtc, label: classes.buttonFtcLabel }}
          >
            Sincroniza con{' '}
            <img src={IcoFactotal} className={classes.icoBtnFtc} alt='' />
          </Button>
        </div>
      </div>
      <div className={classes.greenContainer}>
        <div className={classes.itemgreen}>
          <img src={IcoGreenFacturacion} alt='' />
          <span className={classes.spanItem}>
            Facturación
            <br />
            electrónica
          </span>
        </div>
        <div className={classes.itemgreen}>
          <img src={IcoGreenCarpeta} alt='' />
          <span className={classes.spanItem}>
            Carpeta
            <br />
            tributaria
          </span>
        </div>
        <div className={classes.itemgreen}>
          <img src={IcoGreenDeuda} alt='' />
          <span className={classes.spanItem}>
            Deuda
            <br />
            tesorería
          </span>
        </div>
        <div className={classes.itemgreen}>
          <img src={IcoRegistroCesiones} alt='' />
          <span className={classes.spanItem}>
            Registro de
            <br />
            cesiones
          </span>
        </div>
      </div>
    </Card>
  );
};

export default withRouter(withStyles(styleComparteDatos)(ComparteDatos));

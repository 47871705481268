import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SAcceptYieldModal } from '../../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../../../sharedv2/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import fxActions from '../actions';
import { connect } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { WithStyles } from '@material-ui/core';
import { AppState } from '../../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

interface IProps extends WithStyles<typeof SAcceptYieldModal> {
  bancos: Dto.Banco[];
  tipoCuentas: Dto.Portal.TipoCuenta[];
  crearCuentaCorriente: (payload: Payloads.CrearFormaPagoPayload) => Promise<Mensaje.AgregarFormaPagoRespuesta>;
  onClose: () => void;
  onSave: () => void;
  open: boolean;
}

interface IState extends Payloads.CrearFormaPagoPayload {
}

class AgregarCuentaModal extends React.Component<IProps, IState> {

  initState: Payloads.CrearFormaPagoPayload = {
    banco: 0,
    tipo: 0,
    numero: '',
    habilitada: true,
    predeterminada: false,
    esDeTercero: 0
  };

  constructor(props: IProps) {
    super(props);
    this.state = this.initState;
  }


  handleInputChange = (propName: string) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = event.target;

    if (type === 'checkbox') {
      // @ts-ignore
      this.setState({ [propName]: event.target.checked });
    } else {
      // @ts-ignore
      this.setState({ [propName]: value });
    }

  };

  puedeGuardar = () => {
    return this.state.banco && this.state.numero && this.state.tipo;
  };

  guardarCuenta = () => {
    this.props.crearCuentaCorriente(this.state)
        .then(respuesta => {
          if (respuesta.exito) {
            this.setState(this.initState);
            this.props.onSave();
          }
        });
  };

  render() {
    const { classes, onClose, open, tipoCuentas, bancos } = this.props;
    const { banco, tipo, numero, predeterminada } = this.state;
    const paperClass = [classes.dialogPaper, classes.rootXml].join(' ');

    return (
      <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: paperClass }}>
        <DialogTitle onClose={onClose}>Cuenta Bancaria</DialogTitle>
        <DialogContent>
          <div className={classes.titleModal}>Ingrese la información de su cuenta bancaria a utilizar para los giros de
            sus operaciones.
          </div>
          <div className={classes.labelSelectModal}>Banco</div>
          <FormControl required variant='outlined' className={[classes.textField, classes.textFieldMargin].join(' ')}>
            <Select
              name='banco'
              displayEmpty
              value={banco}
              onChange={this.handleInputChange('banco')}
              input={
                <OutlinedInput name='banco' labelWidth={0} id='outlined-age-native-simple' />
              }
            >{bancos.map(banco => {
                return <MenuItem key={banco.id} value={banco.id}>{banco.razonSocial}</MenuItem>;
              }
            )}
            </Select>
            {banco === 0 &&
              <FormHelperText className={classes.errorLabel}>Debe seleccionar un banco</FormHelperText>
            }
          </FormControl>
          <div className={classes.labelSelectModal}>Tipo de Cuenta</div>
          <FormControl variant='outlined' className={[classes.textField, classes.textFieldMargin].join(' ')}>
            <InputLabel htmlFor='outlined-tipo-native-simple' />
            <Select
              name='tipo'
              value={tipo}
              onChange={this.handleInputChange('tipo')}
              input={
                <OutlinedInput name='tipo' labelWidth={0} id='outlined-tipo-native-simple' />
              }
            >{tipoCuentas.map(tc => {
                return <MenuItem key={tc.id} value={tc.id}>{tc.tipo}</MenuItem>;
              }
            )}
            </Select>
            {tipo === 0 &&
              <FormHelperText className={classes.errorLabel}>Debe seleccionar un tipo de cuenta</FormHelperText>
            }
          </FormControl>
          <div className={classes.labelSelectModal}>Número</div>
          <TextField
            id='numero'
            className={[classes.textField, classes.textFieldMargin].join(' ')}
            onChange={this.handleInputChange('numero')}
            value={numero}
            margin='normal'
            variant='outlined'
          />
          {numero === '' &&
            <FormHelperText className={classes.errorLabel}>Debe ingresar un número</FormHelperText>
          }
          <div className={classes.labelSelectModal}>Predeterminada</div>
          <Switch
            onChange={this.handleInputChange('predeterminada')}
            checked={predeterminada}
          />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button variant='outlined' onClick={() => this.guardarCuenta()} disabled={!this.puedeGuardar()}
                  color='primary'
                  className={classes.greenButton}>
            Crear
          </Button>
          <Button variant='outlined' className={classes.greyButton} onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  bancos: state.factorx.global.bancos,
  tipoCuentas: state.factorx.global.tipoCuentas
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  crearCuentaCorriente: (payload: Payloads.CrearFormaPagoPayload) => dispatch(fxActions.crearCuentaCorriente(payload))
});

export default withStyles(SAcceptYieldModal)(connect(mapStateToProps, mapDispatchToProps)(AgregarCuentaModal));

export function authHeader() {
  // return authorization header with jwt token
  let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
  if (user && user.accessToken) {
    return { 'Authorization': 'Bearer ' + user.accessToken };
  } else {
    return {};
  }
}


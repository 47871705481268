import React, { FC } from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import clsx from 'clsx';

const styles = (theme: Theme) => ({
  root: {
    padding: '31px 31px 26px 31px',
    borderBottom: '1px solid #E4E4E4',
    [theme.breakpoints.down('xs')]: {
      margin: '0 20px 28px',
      padding: '20px 35px 20px 0',
    },
  },
  closeButton: {
    top: '34px',
    color: 'rgba(0, 0, 0, 0.54)',
    right: '24px',
    position: 'absolute' as 'absolute',
    [theme.breakpoints.down('xs')]: {
      right: 5,
      top: 7,
    },
  },
  icon: {
    fontSize: 30,
  },
  dialogTitle: {
    fontSize: '24px',
    lineHeight: '24px',
    color: '#333333',
    marginBotton: '3px',
    fontWeight: 500
  },
  dialogSubtitle: {
    fontSize: '14px',
    lineHeight: '18px',
    color: '#292929',
  },
});

interface DialogTitleProps extends WithStyles<typeof styles> {
  onClose: () => void;
  titleClass?: string;
  closeButtonClass?: string;
  subtitle?: string;
}

const DialogTitle: FC<DialogTitleProps> = (props) => {
  const { classes, children, onClose, titleClass, closeButtonClass, subtitle } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={clsx([
        classes.root,
        {
          [titleClass!]: titleClass,
        },
      ])}
    >
      <Typography variant='h6' className={classes.dialogTitle}>
        {children}
      </Typography>
      <Typography className={classes.dialogSubtitle}>{subtitle}</Typography>
      {onClose ? (
        <IconButton
          aria-label='Close'
          className={clsx([
            classes.closeButton,
            {
              [closeButtonClass!]: closeButtonClass,
            },
          ])}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

export default withStyles(styles)(DialogTitle);

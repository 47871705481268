import {
  IFetchPending,
  fetchEmpresasAction,
  fetchEmpresaContratosAction,
  nuevoContratoEmpresaAction,
  seleccionarContratoAction,
  persistirInscripcionAction,
  cargarInscripcionAction,
  enviarInscripcionAction,
  enviarTransferenciaAction,
  cargarTransferenciaAction,
  persistirTransferenciaAction,
  limpiarInscripcionAction,
  limpiarTransferenciaAction
} from './actions';
import { ActionT } from '../../config/rootReducer';
import { convertPaginacion } from '../../helpers/PaginationHelper';

export const iniPaginacion = (): State.Paginacion => ({ count: 0, from: 0, maxPages: 0, page: 0, pageSize: 0, to: 0 });

export interface IRegistroCivilState {
  empresas: {
    items: Dto.RegistroCivil.Empresa[];
    paginacion: State.Paginacion;
  };
  empresa: Dto.RegistroCivil.Empresa | undefined;
  contrato: Dto.RegistroCivil.Contrato | undefined;
  inscripcion: Dto.RegistroCivil.Inscripcion | undefined;
  transferencia: Dto.RegistroCivil.Transferencia | undefined;
  paginacionContratos: State.Paginacion;
}

const registroCivilState: IRegistroCivilState = ({
  empresas: {
    items: [],
    paginacion: iniPaginacion()
  },
  empresa: undefined,
  contrato: undefined,
  inscripcion: undefined,
  transferencia: undefined,
  paginacionContratos: iniPaginacion()
});

export const reducer = (state = registroCivilState, action: ActionT<any>) => {
  switch (action.type) {
    case fetchEmpresasAction.PENDING: {
      const payload = action.payload as IFetchPending;
      return {
        ...state,
        empresa: undefined,
        contrato: undefined,
        empresas: {
          ...state.empresas,
          paginacion: {
            ...state.empresas.paginacion,
            pageSize: payload.tamanoPagina
          }
        }
      };
    }

    case fetchEmpresasAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.Empresas;
      return {
        ...state,
        empresa: undefined,
        contrato: undefined,
        empresas: {
          items: payload.data,
          paginacion: convertPaginacion(payload.paginacion)
        }
      };
    }

    case fetchEmpresaContratosAction.PENDING: {
      const payload = action.payload as any;
      return { ...state };
    }

    case fetchEmpresaContratosAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.EmpresaContratos;
      return {
        ...state,
        empresa: payload.empresa,
        contrato: undefined,
        paginacionContratos: convertPaginacion(payload.paginacionContratos)
      };
    }

    case nuevoContratoEmpresaAction.PENDING: {
      const payload = action.payload as any;
      return { ...state };
    }

    case nuevoContratoEmpresaAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.NuevoContrato;
      return {
        ...state,
        empresa: payload.empresa,
        paginacionContratos: convertPaginacion(payload.paginacionContratos)
      };
    }

    case seleccionarContratoAction.SUCCESS: {
      const payload = action.payload as number;
      return {
        ...state,
        contrato: state.empresa?.contratos.find(c => c.idContrato === payload)
      };
    }
    case limpiarInscripcionAction.SUCCESS: {
      debugger;
      const payload = action.payload as any;
      return { ...state,
        inscripcion: undefined
      };
    }
    case limpiarTransferenciaAction.SUCCESS: {
      debugger;
      const payload = action.payload as any;
      return { ...state,
        transferencia: undefined
      };
    }
    case persistirInscripcionAction.PENDING: {
      const payload = action.payload as any;
      return { ...state };
    }

    case persistirInscripcionAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.Inscripcion;
      return {
        ...state,
        empresa: payload.empresa,
        inscripcion: payload.inscrip,
        paginacionContratos: convertPaginacion(payload.paginacionContratos)
      };
    }

    case cargarInscripcionAction.SUCCESS: {
      const payload = action.payload as Dto.RegistroCivil.Inscripcion;
      return {
        ...state,
        inscripcion: payload
      };
    }

    case enviarInscripcionAction.PENDING: {
      const payload = action.payload as any;
      return { ...state };
    }

    case enviarInscripcionAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.EnviarInscripcion;
      return {
        ...state,
        inscripcion: payload.inscrip
      };
    }

    case persistirTransferenciaAction.PENDING: {
      const payload = action.payload as any;
      return { ...state };
    }

    case persistirTransferenciaAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.Transferencia;
      return {
        ...state,
        empresa: payload.empresa,
        transferencia: payload.transfer,
        paginacionContratos: convertPaginacion(payload.paginacionContratos)
      };
    }

    case cargarTransferenciaAction.SUCCESS: {
      const payload = action.payload as Dto.RegistroCivil.Transferencia;
      return {
        ...state,
        transferencia: payload
      };
    }

    case enviarTransferenciaAction.PENDING: {
      const payload = action.payload as any;
      return { ...state };
    }

    case enviarTransferenciaAction.SUCCESS: {
      const payload = action.payload as Response.RegistroCivil.EnviarTransferencia;
      return {
        ...state,
        transferencia: payload.transf
      };
    }

    default:
      return state;
  }
};

export default reducer;

export const buildQueryString = (obj : any, excluded : string[]) : string => {
  let str = [];
  for (let key in obj) {
    if (excluded.includes(key)) continue;

    if (obj.hasOwnProperty(key)) {
      let val = obj[key];

      if (!val || val.toString()
                     .trim() === '') continue;

      if (Array.isArray(val)) {
        for (let i = 0, len = val.length; i < len; ++i) {
          let item = val[i];
          str.push(`${encodeURIComponent(key)}=${encodeURIComponent(item)}`);
        }
      } else {
        str.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      }
    }
  }
  return str.join('&');
};


import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SAcceptYieldModal } from './style';
import Dialog from '@material-ui/core/Dialog';
import UploadDialog from './UploadDialog';
import DialogTitle from '../../../sharedv2/DialogTitle/DialogTitle';
import factorxApi from '../../../../services/factorxApi';
import actions from '../../../main/reducer';
import { connect } from 'react-redux';
import { WithStyles } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../config/rootReducer';
import { AnyAction } from 'redux';

interface ISubirArchivosEjecutivoProps extends WithStyles<typeof SAcceptYieldModal> {
  onClose : (retorno : boolean) => void,
  esEjecutivo : boolean,
  numeroOperacion : number,
  open : boolean,
  notificacion : (exito : boolean, mensaje : string) => void
}

interface IState {
  loading : boolean
}

class SubirArchivosEjecutivo extends React.Component<ISubirArchivosEjecutivoProps, IState> {

  constructor(props : ISubirArchivosEjecutivoProps) {
    super(props);
    this.state = {
      loading: false
    };

  }

  handleClose = () => {
    this.setState({
      loading: false
    });
    this.props.onClose(true);
  };

  loadingToogle = () => {
    this.setState((state) => ({
      loading: !state.loading
    }));
  };

  enviarArchivos = (archivos : File[]) => {
    if (this.props.esEjecutivo) return;
    this.loadingToogle();
    factorxApi.enviarArchivosEjecutivo({
                archivos: archivos,
                numeroOperacion: this.props.numeroOperacion
              })
              .then((respuesta : Mensaje.EnviarArchivosEjecutivoRespuesta) => {
                this.handleClose();
                if (respuesta.exito) {
                  this.props.notificacion(respuesta.exito, 'El correo se ha enviado exitosamente');
                } else {
                  this.props.notificacion(respuesta.exito, respuesta.mensaje);
                }
              });
  };

  render() {
    const { classes, onClose, open, esEjecutivo } = this.props;
    const { loading } = this.state;
    const paperClass = [classes.dialogPaper, classes.rootXml].join(' ');
    return (
      <Dialog className={classes.root} open={open} PaperProps={{ className: paperClass }}>
        <DialogTitle onClose={this.handleClose}>Envío de archivos a ejecutivo, Operación
          N° {this.props.numeroOperacion}</DialogTitle>
        <UploadDialog
          esEjecutivo={esEjecutivo}
          enviarArchivos={this.enviarArchivos}
          loading={loading}
        />
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, null, AnyAction>) => ({
  notificacion: (exito : boolean, mensaje : string) => dispatch(actions.openSnackBar(exito, mensaje))
});


export default withStyles(SAcceptYieldModal)(connect(null, mapDispatchToProps)(SubirArchivosEjecutivo));

import React from 'react';
import cx from 'classnames';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import { MuiThemeProvider } from '@material-ui/core/styles';
import actions from './reducer';
import fxActions from '../general/fxEmpresa/actions';
import FactotalTheme from '../../styles/FactotalTheme';
import Header from '../shared/Header/Header';
import Footer from '../shared/Footer/Footer';
import Sidebar from './sidebarPublico';
import SidebarInterno from './sidebarInterno';
import dashboardRoutes, { IRuta } from './dashboard';
import appStyle from '../../styles/jss/components/dasBoardPageStyle';
import Login from '../general/login/view';
import IdleTimer from 'react-idle-timer';
import 'moment/locale/es';
import { userApi } from '../../services/userApi';
import logo from '../../images/icons/logoFactotal.svg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';
import OpenVideoDetails from '../general/videoTutoriales/OpenVideoDetails';
import factorxApi from '../../services/factorxApi';
import { AppState } from '../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';
import config from '../../config/api';
import IcoWhiteChat from '../../images/icons/ico-white-chat.svg';
import { esEjecutivoRegistroCivil, puedeEntrarSitioAdmin, puedeEntrarSitioAdminDirecto } from '../../helpers/UserHelper';
import Subscriber from "../general/Subscriber/view";

interface StateProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  isMenuOpen: boolean;
  isSnackbarOpen: boolean;
  snackColor: string;
  snackMessage: string;
  snackTimeout: number;
  userInfo: Comun.Dto.LoginUserInfo | undefined;
}

interface DispatchProps {
  dispatch: any;
}

interface OwnProps {
}

interface IProps
  extends OwnProps,
    StateProps,
    DispatchProps,
    RouteComponentProps,
    WithStyles<typeof appStyle> {
}

interface IState {
  mobileOpen: boolean;
  miniActive: boolean;
  timeout: number;
  videoSelected: Dto.Portal.Tutorial | undefined;
  abrirModalVideo: boolean;
  dashRoutes: IRuta | undefined;
  esAdmin: boolean | undefined;
  empresa: Dto.EnterpriseProfile | undefined;
}

class Main extends React.Component<IProps, IState> {

  idleTimer: any = null;
  onActive: any;
  onIdle: any;

  constructor(props: IProps) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      timeout: config.idleTimeout,
      videoSelected: undefined,
      abrirModalVideo: false,
      dashRoutes: undefined,
      esAdmin: undefined,
      empresa: undefined
    };
    this.resizeFunction = this.resizeFunction.bind(this);
    this.cierreModalVideo = this.cierreModalVideo.bind(this);

    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
  }

  handleLogin = (userInfo: Response.Login) => {
    this.props.dispatch(actions.logIn(userInfo));
  };
  handleSubscribe = (userInfo: Response.Subscribe) => {
    this.props.dispatch();
  };

  handleCloseSnack1 = (event: React.ChangeEvent<any>, reason: string) => {
    this.props.dispatch(actions.closeSnackBar());
  };
  handleCloseSnack2 = (event: React.ChangeEvent<any>) => {
    this.props.dispatch(actions.closeSnackBar());
  };

  async componentDidMount() {
    window.addEventListener('resize', this.resizeFunction);
    if (this.props.isLoggedIn && this.props.userInfo) {
      const esAdmin = puedeEntrarSitioAdminDirecto(this.props.userInfo) || esEjecutivoRegistroCivil(this.props.userInfo);
      const empresa = esAdmin ? undefined : this.props.userInfo.empresas[0];
      this.setState(
        (state) => ({ ...state, esAdmin: esAdmin }),
        () => this.terminarIngreso(this.props.userInfo, empresa)
      );
      // await this.terminarIngreso(esAdmin || false, empresa);
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (prevProps.userInfo !== this.props.userInfo && this.props.userInfo) {
      const esAdmin = puedeEntrarSitioAdminDirecto(this.props.userInfo) || esEjecutivoRegistroCivil(this.props.userInfo);
      const empresa = esAdmin ? undefined : this.props.userInfo.empresas[0];
      this.setState(
        (state) => ({ ...state, esAdmin: esAdmin }),
        () => this.terminarIngreso(this.props.userInfo, empresa)
      );
    }
  }

  terminarIngreso = async (userInfo: Comun.Dto.LoginUserInfo | undefined, empresa: Dto.EnterpriseProfile | undefined) => {
    if (!userInfo) return;
    const esAdmin = puedeEntrarSitioAdmin(userInfo) || esEjecutivoRegistroCivil(userInfo);
    const dashRoutes = dashboardRoutes(esAdmin, userInfo);
    if (esAdmin) {
      this.setState({ dashRoutes: dashRoutes });
    } else {
      if (sessionStorage.getItem('fx-token')) {
        this.props.dispatch(fxActions.cargarDatosEmpresaUsuario());
        this.props.dispatch(fxActions.cargarDatosGlobal());
        fxActions.agendarRenovacion();
        if (empresa && empresa.factoring) {
          const resp = await factorxApi.obtenerSiguienteTutorialParaUsuario();
          if (resp.exito && resp.tutorial) {
            this.setState({
              videoSelected: resp.tutorial,
              empresa: empresa,
              abrirModalVideo: true,
              dashRoutes: dashRoutes
            });
          } else {
            this.setState({
              videoSelected: undefined,
              empresa: empresa,
              abrirModalVideo: false,
              dashRoutes: dashRoutes
            });
          }
        } else {
          this.setState({
            videoSelected: undefined,
            empresa: empresa,
            abrirModalVideo: false,
            dashRoutes: dashRoutes
          });
        }
      }
    }
  };

  cierreModalVideo() {
    this.setState({
      abrirModalVideo: false,
      videoSelected: undefined
    });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1 && this.props.isLoggedIn) {
      //ps.destroy();
    }
    window.removeEventListener('resize', this.resizeFunction);
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  _onActive() {
    // if (this.props.isLoggedIn) this.setState({ isIdle: false });
  }

  async _onIdle() {
    if (this.props.isLoggedIn) {
      fxActions.limpiarRenovacion();
      await userApi.logout();
    }
  }

  openChatZendesk = (e: any) => {
    const script = document.createElement('script');
    script.src = 'https://v2.zopim.com/?4C2O1sVy5adIROMqNm5iGKqnJL0E9l61';
    document.body.appendChild(script);
    const chat = document.getElementsByClassName('zopim');
    if (chat.length) (chat[0] as any).style.display = '';
  };

  render() {

    const { classes, ...rest } = this.props;
    const { history, isLoggedIn, isSnackbarOpen, snackColor, snackMessage, snackTimeout } =
      this.props;
    const { dashRoutes } = this.state;

    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1
    })}`;

    const user = this.props.userInfo;

    const router = (
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={FactotalTheme}>
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            onActive={this.onActive}
            onIdle={this.onIdle}
            timeout={this.state.timeout}
          />
          {user && dashRoutes && (
            <div className={classes.wrapper}>
              {this.state.esAdmin ? (
                <SidebarInterno
                  routes={dashRoutes.rutas || []}
                  logoText='factotal'
                  logo={logo}
                  user={user}
                  bgColor='green'
                  miniActive={this.state.miniActive}
                  image={undefined}
                  {...rest}
                />
              ) : this.state.empresa ? (
                <Sidebar
                  empresa={this.state.empresa}
                  ruta={history.location.pathname}
                  user={user}
                  miniActive={this.state.miniActive}
                />
              ) : null}
              <Header
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                color='success'
                rtlActive={false}
                {...rest}
              />
              <div className={mainPanel} ref='mainPanel'>
                <div className={classes.content}>
                  <div className={classes.container}>
                    <Switch>
                      {[1].map((prop, key) => {
                        if (dashRoutes.rutas) {
                          return dashRoutes.rutas.map((prop1, key1) => (
                            <Route path={prop1.path} exact component={prop1.component} key={key1} />
                          ));
                        }
                        return (
                          <Route
                            path={dashRoutes.path}
                            exact
                            component={dashRoutes.component}
                            key={key}
                          />
                        );
                      })}
                      {dashRoutes.redirect && dashRoutes.pathTo && (
                        <Redirect from={dashRoutes.path} to={dashRoutes.pathTo} />
                      )}
                    </Switch>
                  </div>
                </div>
                <Footer fluid />
              </div>
              <Snackbar
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                open={isSnackbarOpen}
                autoHideDuration={snackTimeout || 6000}
                onClose={this.handleCloseSnack1}
              >
                <div className={classes.Snackbar}>
                  <div className='line-header' />
                  <div className='content'>
                    <div className='title'>
                      <span>Notificación</span>
                      <IconButton
                        key='close'
                        aria-label='Close'
                        color='inherit'
                        onClick={this.handleCloseSnack2}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                    <div className='desc'>
                      <div className={`circle ${snackColor}`} />
                      <div className='t'>{snackMessage}</div>
                    </div>
                  </div>
                </div>
              </Snackbar>

              <OpenVideoDetails
                open={this.state.abrirModalVideo}
                onClose={this.cierreModalVideo}
                videoSelected={this.state.videoSelected}
              />
              <div className={classes.chatContainer}>
                <IconButton classes={{ root: classes.buttonChatfloating }} onClick={this.openChatZendesk}>
                  <img src={IcoWhiteChat} />
                </IconButton>
              </div>
            </div>
          )}
        </MuiThemeProvider>
      </ConnectedRouter>
    );

    const login = <Login handleLogin={this.handleLogin} />;
    const location =()=> {
        const loc = window.location.pathname;
        return loc.includes("/Subscriber");

    }
    const subscribe = <Subscriber subscribe={()=>{}} handleSubscribe={this.handleSubscribe} />
    const body = isLoggedIn ? router : location()?subscribe:login;
    return { ...body };
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  isLoading: state.main.isLoading,
  isLoggedIn: state.main.isLoggedIn,
  isMenuOpen: state.main.isMenuOpen,
  isSnackbarOpen: state.main.isSnackbarOpen,
  snackColor: state.main.snackColor,
  snackMessage: state.main.snackMessage,
  snackTimeout: state.main.snackTimeout,
  userInfo: state.main.userInfo
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({
  dispatch: (action: AnyAction) => dispatch(action)
});

export default withStyles(appStyle)(connect(mapStateToProps, mapDispatchToProps)(withRouter(Main)));

import { fetchAction, IFetchCarteraSuccess, IFetchPending } from './actions';
import { convertPaginacion, iniPaginacion } from '../../../helpers/PaginationHelper';
import { ActionT } from '../../../config/rootReducer';

export const iniTotalCarteraCobranza = (): Dto.CarteraTotal => ({} as Dto.CarteraTotal);
export const iniCarteraCobranza = (): Dto.CarteraRow[][] => {
  let retorno: Dto.CarteraRow[][] = [];
  retorno[1] = [];
  retorno[2] = [];
  retorno[3] = [];
  retorno[4] = [];
  return retorno;
};

export interface ICarteraState {
  tipoDocumento: number;
  rows: Dto.CarteraRow[][];
  paginacion: State.Paginacion[];
  total: Dto.CarteraTotal[];
  loading: boolean[];
  filterParameters: any;
  isValidForm: true;
  validationMessages: {
    numeroDocumentoDesde: '',
    numeroDocumentoHasta: ''
  };
}

const carterFactoringState: ICarteraState = ({
  filterParameters: {},
  rows: iniCarteraCobranza(),
  paginacion: [iniPaginacion(), iniPaginacion(), iniPaginacion(), iniPaginacion(), iniPaginacion()],
  total: [iniTotalCarteraCobranza(), iniTotalCarteraCobranza(), iniTotalCarteraCobranza(), iniTotalCarteraCobranza(), iniTotalCarteraCobranza()],
  loading: [false, false, false, false, false],
  tipoDocumento: 0,
  isValidForm: true,
  validationMessages: {
    numeroDocumentoDesde: '',
    numeroDocumentoHasta: ''
  }
});


export const reducer = (state = carterFactoringState, action: ActionT<any>) => {
  switch (action.type) {
    case fetchAction.PENDING: {
      const payload = action.payload as IFetchPending;

      let { rows, paginacion, total, loading } = state;
      loading[payload.tipoDocumento] = payload.loading;
      return {
        ...state,
        loading: [...loading]
      };
    }

    case fetchAction.SUCCESS: {

      const payload = action.payload as IFetchCarteraSuccess;

      let { rows, paginacion, total, loading } = state;
      rows[payload.tipoDocumento] = payload.carteraRow;
      paginacion[payload.tipoDocumento] = convertPaginacion(payload.paginacion);
      total[payload.tipoDocumento] = payload.carteraTotal;
      loading[payload.tipoDocumento] = false;

      return {
        ...state,
        rows: [...rows],
        total: [...total],
        paginacion: [...paginacion],
        loading: [...loading]
      };

    }

    default:
      return state;
  }
};

export default reducer;

import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof HistoricoStyle> {
  expanded : boolean,
  cleanFilters : () => void,
  toggleExpand : () => void
}

const FiltersHeader : FC<IProps> = (props : IProps) => {
  const { classes, toggleExpand, cleanFilters } = props;

  return (
    <CardHeader
      action={(
        <div>
          <Tooltip title={props.expanded ? 'Colapsar' : 'Ampliar'} placement="top">
            <IconButton
              className={classnames(classes.expand, {
                // [classes.expandOpen]: this.state.expanded,
                [classes.filterButtonCollapse]: true
              })}
              onClick={toggleExpand}
              aria-expanded={props.expanded}
              aria-label="Show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      title={(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h5" className={classes.filterTitle}>
            Filtros de Búsqueda
          </Typography>
          <Button color="primary" variant="outlined" onClick={() => cleanFilters()} style={{ marginLeft: 14 }}>
            Limpiar
          </Button>
        </div>
      )}
      classes={{
        title: classes.filterTitle,
        action: classes.filterExpandButton,
        root: classes.filterCardHeader
      }}
    />
  );
};

export default withStyles(HistoricoStyle)(FiltersHeader);

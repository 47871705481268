import axios from 'axios';
import config from '../config/api';
import fxActions from '../components/general/fxEmpresa/actions';

const apiUserUrl = config.apiUri;
const apiFactotalCore = config.apiFactotalCore

function getUserData(rut: string) {
  axios.get(apiUserUrl + '/Persona/GetFullDatosPersonaByRut?rut=' + rut).catch(error => error);
}

function updateUserInfoApi(form: any) {
  axios.post(apiUserUrl + '/Persona/SaveDatosPersona', { ...form }).catch(error => error);
}

function login(params: Dto.LoginRequest) {
  return axios
    .post(`${apiUserUrl}auth/login`, params,
      {
        headers: { 'Content-Type': 'application/json' }
      })
    .catch(error => error)
    .then(r => r);
}

function recoverCode(params: Dto.PasswordRecoveryRequest) {
  return axios
    .post(`${apiUserUrl}auth/recovery/requestpassword`, {
        Rut: params.rut
      },
      {
        headers: { 'Content-Type': 'application/json' }
      })
    .catch(error => {
      logout();
    })
    .then(r => r);
}

function validateRecoverCode(params: Dto.ValidateRecoveryTokenRequest) {
  return axios
    .post(`${apiUserUrl}auth/recovery/validatetoken`, {
        Token: params.token
      },
      {
        headers: { 'Content-Type': 'application/json' }
      })
    .catch(error => logout())
    .then(r => r);
}

function recoveryChangePassword(params: Dto.PasswordRecoveryChangeRequest) {
  return axios
    .post(`${apiUserUrl}auth/recovery/changepassword`, params,
      {
        headers: { 'Content-Type': 'application/json' }
      })
    .catch(error => logout())
    .then(r => r);
}

export const logout = () => {

  // remove user from local storage to log user out
  let userData: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
  const payload: Dto.RevokeRequest = {
    token: userData.accessToken,
    userRut: userData.userRut
  };
  return axios
    .post(`${apiUserUrl}auth/login/revoke`, payload,
      {
        headers: { 'Content-Type': 'application/json' }
      })
    .catch(error => {
      return error;
    })
    .then(response => {
      fxActions.limpiarRenovacion();
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('fx-token');
        sessionStorage.removeItem('fx-id');
      window.location.href = '/';
      //window.location.reload(true);
    });

};
function getComunas (){
    return axios.get(`${apiFactotalCore}GlbTComunas`, {
        headers: { 'Content-Type': 'application/json' ,'Access-Control-Allow-Origin':"*"}
    }).catch(error=>{
        return error;
    }).then(r=>r)
}
function subscribeClient(client:any){

    return axios.post(`${apiFactotalCore}Register/registrar`,client, {
        headers: { 'Content-Type': 'application/json' ,'Access-Control-Allow-Origin':"*"}
    }).then(r=>{
        return r
        }).catch(error=>
        {
            if(error.status===400){
                return error?.response?.data?.errors;
            }else{
                return error?.response?.data;
            }

        }
    )

}


export const userApi = {
  login,
  logout,
  getComunas,
  subscribeClient,
  getUserData,
  updateUserInfoApi,
  recoverCode,
  validateRecoverCode,
  recoveryChangePassword
};

import React, { FC, useEffect, useState } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Header from '../../components/sharedv2/Header/Header';
import HistoricoStyle from '../../styles/jss/components/extendedFormsStyle';
import { Button, Card, CardHeader, Grid, IconButton, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '../sharedv2/Table/TableContainer';
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import routes from '../../config/routes';
import { obtenerEmpresaContratos, obtenerEmpresas } from './actions';
import { AppState } from '../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import registroCivilApi from '../../services/registroCivilApi';
import { iniPaginacion } from './reducer';
import { convertPaginacion } from '../../helpers/PaginationHelper';

export interface IContratosData {
  idContrato: string;
  rutCliente: string;
  numeroContrato: string;
  fechaSolicitud: string;
  razonSocial: string;
  encargado: string;
  qtyContratos: number;
  estado?: string;
  monto?: string | number;
  bienes?: [];
  firmado?: string;
}

interface StateProps {
}

interface DispatchProps {
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof HistoricoStyle> {
}

const Informes: FC<IProps> = (props) => {
  const { classes } = props;
  const [expanded, setExpanded] = useState(true);
  const [empresa, setEmpresa] = useState('');
  const [encargado, setEncargado] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [inscripciones, setInscripciones] = useState<Dto.RegistroCivil.Inscripcion[]>([]);
  const [transferencias, setTransferencias] = useState<Dto.RegistroCivil.Transferencia[]>([]);
  const [paginacion, setPaginacion] = useState<State.Paginacion>(iniPaginacion());

  useEffect(() => {
    searchSolicitudes();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const cleanFilters = () => {
    setEmpresa('');
    setEncargado('');
    searchSolicitudes();
  };

  const searchSolicitudes = () => {
    setInscripciones([]);
    setTransferencias([]);
    obtenerDatos(1, 10);
  };

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setActiveTab(value);
  };
  const obtenerDatos = async (pagina: number, tamano: number) => {
    const resp = await registroCivilApi.getInformeInscripcionesTransferencias({ filtroEmpresa: empresa, numeroPagina: pagina, tamanoPagina: tamano });
    setInscripciones(resp.inscripciones);
    setTransferencias(resp.transferencias);
    setPaginacion(convertPaginacion(resp.paginacion, -1));
  };


  const tableInfoInscripcion = [
    {
      column: 'ID',
      relation: 'idInscripcion',
      renderData: (inscripcion: Dto.RegistroCivil.Inscripcion) => inscripcion.idInscripcion
    },
    {
      column: 'PPU',
      relation: 'ppu',
      renderData: (inscripcion: Dto.RegistroCivil.Inscripcion) => inscripcion.ppu
    },
    {
      column: 'Razón Social',
      relation: 'razonSocial',
      renderData: (inscripcion: Dto.RegistroCivil.Inscripcion) => inscripcion.ppu
    },
    {
      column: 'Encargado',
      relation: 'encargado',
      renderData: (inscripcion: Dto.RegistroCivil.Inscripcion) => inscripcion.ppu
    },

    {
      column: 'Acciones',
      relation: '',
      renderData: (inscripcion: Dto.RegistroCivil.Inscripcion) => (
        <div style={{ display: 'flex' as 'flex', justifyContent: 'flex-end' as 'flex-end' }}>
          <Link
            to={`${routes.registroVehiculos.contratosEmpresa(inscripcion.ppu?.toString())}`}>
            <SearchOutlinedIcon />
          </Link>
        </div>
      )
    }
  ];

  const tableInfoTransferencia = [
    {
      column: 'ID',
      relation: 'idInscripcion',
      renderData: (inscripcion: Dto.RegistroCivil.Transferencia) => inscripcion.idTransferencia
    },
    {
      column: 'PPU',
      relation: 'ppu',
      renderData: (inscripcion: Dto.RegistroCivil.Transferencia) => inscripcion.numeroSolicitud
    },
    {
      column: 'Razón Social',
      relation: 'razonSocial',
      renderData: (inscripcion: Dto.RegistroCivil.Transferencia) => inscripcion.numeroSolicitud
    },
    {
      column: 'Encargado',
      relation: 'encargado',
      renderData: (inscripcion: Dto.RegistroCivil.Transferencia) => inscripcion.numeroSolicitud
    },

    {
      column: 'Acciones',
      relation: '',
      renderData: (inscripcion: Dto.RegistroCivil.Transferencia) => (
        <div style={{ display: 'flex' as 'flex', justifyContent: 'flex-end' as 'flex-end' }}>
          <Link
            to={`${routes.registroVehiculos.contratosEmpresa(inscripcion.numeroSolicitud?.toString())}`}>
            <SearchOutlinedIcon />
          </Link>
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <Header title='Registros y transferencias' />
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <div>
                  <Tooltip title={expanded ? 'Colapsar' : 'Ampliar'} placement='top'>
                    <IconButton
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label='Show more'
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              }
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='h5' className={classes.filterTitle}>
                    Filtros de Búsqueda
                  </Typography>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={cleanFilters}
                    style={{ marginLeft: 14 }}
                  >
                    Limpiar
                  </Button>
                </div>
              }
            />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <CardContent className={classes.filterCardContent}>
                <form className={classes.filterForm}>
                  <div className={classes.collapseForm}>
                    <FormControl fullWidth className={classes.maxWidthInput}>
                      <TextField
                        className={[classes.filterFormtextField, classes.maxWidthInput].join(' ')}
                        id='empresa'
                        name='empresa'
                        value={empresa}
                        label='Empresa'
                        placeholder='Ej. 100'
                        onChange={(e) => setEmpresa(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth className={classes.maxWidthInput}>
                      <TextField
                        className={[classes.filterFormtextField, classes.maxWidthInput].join(' ')}
                        id='encargado'
                        name='encargado'
                        value={encargado}
                        label='Encargado'
                        placeholder='Ej. Todos'
                        onChange={(e) => setEncargado(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.collapseFormButtons}>
                    <Button variant='outlined' color='primary' onClick={searchSolicitudes}>
                      Buscar
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={12} md={12}>
          <div className='tabs'>
            <Tabs value={activeTab} onChange={handleChange} classes={{ indicator: 'indicator' }}>
              <Tab label='Inscripciones' classes={{ root: 'tabs-factotal' }} />
              <Tab label='Transferencias' classes={{ root: 'tabs-factotal' }} />
            </Tabs>
            {activeTab === 0 &&
              <TableContainer
                classes={classes}
                data={inscripciones}
                pagination={paginacion}
                tableInfo={tableInfoInscripcion}
                obtenerDatos={obtenerDatos}
              />
            }
            {activeTab === 1 &&
              <TableContainer
                classes={classes}
                data={transferencias}
                pagination={paginacion}
                tableInfo={tableInfoTransferencia}
                obtenerDatos={obtenerDatos}
              />
            }
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(HistoricoStyle)(Informes);


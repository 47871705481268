import { Theme } from '@material-ui/core';
import scssTool from '../../styles/scssTool';

export const styleVendor = (theme : Theme) => ({
  otroBtnRequerimientos: {
    background: theme.palette.primary.main,
    width: '254px',
    height: '40px',
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  otroBtnRequerimientosLabel: {
    ...scssTool.font(16, "bold", 24),
    color: "#ffffff"
  },
  leftContainer: {
    ...scssTool.flex("flex-start", "row"),
    height: "230px"
  },
  contratosVigentesContainer: {
    ...scssTool.flex("center", "row", "space-between"),
    background: "#F1FFEC",
    borderRadius: "9px",
    padding: "20px 24px"
  },
  contratosVigentesNumero: {
    ...scssTool.font(44, "bold", 25),
    color: '#C3DB30',
    textDecoration: 'none'
  },
  contratosVigentesTitle: {
    ...scssTool.font(18, "bold", 20),
    color: '#414141',
    textDecoration: 'none',
    width: '104px',
    marginLeft: '15px',
    flexGrow: 1
  },
  otrosReqCon: {
    ...scssTool.flex("center", "row", "center"),
  },
  btnOtroReq: {
    background: "transparent",
    marginTop: "28px",
  },
  otrosReqLabel: {
    ...scssTool.font(16, 400, 24),
    color: "#82BC00",
    textDecoration: 'underline' as 'underline'
  },
  tabsRoot: {
    marginTop: '46px',
    boxShadow: '0px -14px 21px -16px rgb(0 0 0 / 12%)'
  },
  tabsIndicator: {
    backgroundColor: 'transparent'
  },
  tabRoot: {
    width: '50%',
    maxWidth: 'inherit' as 'inherit',
    backgroundColor: '#F5F5F5',
    borderRadius: '7px 7px 0px 0px',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    color: '#ADADAD'
  },
  tabsSelected: {
    backgroundColor: '#FFFFFF',
    borderRadius: '7px 7px 0px 0px',
    boxShadow: '0px 1px 12px rgba(0, 0, 0, 0.12)',
    color: 'rgba(148, 193, 31, 0.87)',
    fontWeight: 600
  },
  tabContent: {
    padding: '32px 16px'
  },
  tabContentTitle: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '25px',
    color: '#414141',
    marginBottom: '16px'
  },
  tabContentBigTitle: {
    fontWeight: 500,
    fontSize: '22px',
    lineHeight: '25px',
    color: '#414141',
    letterSpacign: '0.315px',
    marginBottom: '25px'
  },
  certificadosContent: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'column' as 'column',
    width: '100%'
  },
  certificadosContainer: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column' as 'column'
    }
  },
  certificadoBox: {
    border: '1px solid #94C11F',
    boxSizing: 'border-box' as 'border-box',
    width: '100%',
    padding: '7px 16px',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '40px',
    color: '#94C11F',
    marginBottom: '16px',
    cursor: 'pointer' as 'pointer',
    transition: 'all 0.3s ease-in-out',
    margin: '4px 16px 4px 0px',
    textAlign: 'center' as 'center',
    '&:last-child': {
      marginRight: '0px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0px'
    },
    '&:hover': {
      transition: 'all 0.3s ease-in-out',
      background: '#94C11F',
      color: '#fff'
    }
  },
  pasosSeguirContainer: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  itemPasosASeguir: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    marginBottom: '16px'
  },
  pasosNumero: {
    marginRight: '13px',
    width: '31px',
    height: '31px',
    minWidth: '31px',
    minHeight: '31px',
    borderRadius: '31px',
    backgroundColor: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#FFFFFF',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center'
  },
  underline: {
    color: theme.palette.primary.main,
    textDecoration: 'underline' as 'underline',
    cursor: 'pointer' as 'pointer'
  },
  textPasos: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#414141'
  }
});

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {bannerStyle} from './style';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';

const Banner = (props) => {
  const {classes, text, type} = props;

  return (
    <Grid container spacing={16}>
      <Grid item xs={12} sm={12} md={12}>
        <div className={classes.banner}>
          <InfoIcon className={classes.icon} />
          <span>{text}</span>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(bannerStyle)(Banner);

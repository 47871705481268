import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DialogActions from '@material-ui/core/DialogActions';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof SAcceptYieldModal> {
  cuenta: Comun.Dto.FormaPago;
  onDelete: (cuenta: number) => void;
  onClose: () => void;
  open: boolean;
}

interface IState {
}

class EliminarCuentaModal extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  render() {
    const { classes, onClose, open, cuenta, onDelete } = this.props;

    return (
      <Dialog onClose={onClose} className={classes.rootDeleteModal} open={open}
              PaperProps={{ className: [classes.dialogPaper, classes.dialogPaperDelete].join(' ') }}>
        <div className={[classes.checkIcon, classes.chekIconDelete].join(' ')}><DeleteOutlinedIcon />
        </div>
        <h1>Desea eliminar esta cuenta bancaria</h1>
        <p className={classes.messageDelete}>¿Está seguro que desea <span
          className='red'>eliminar</span> la <span>{cuenta.tipoCuenta}</span> del <span>{cuenta.banco.nombre}</span> Nro <span>{cuenta.numero}</span>
        </p>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button variant='outlined' onClick={() => onDelete(cuenta.id)} className={classes.redButton}>
            Eliminar
          </Button>
          <Button variant='outlined' className={classes.greyButton} onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(EliminarCuentaModal);

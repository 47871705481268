import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import '../../../../sharedv2/custom_theme.scss';
import 'react-datetime/css/react-datetime.css';
import { RouteComponentProps } from 'react-router-dom';
import Header from '../../../../sharedv2/Header/Header';
import HistoricoStyle from '../../../../../styles/jss/components/extendedFormsStyle';
import Loader from '../../../../shared/Loader/Loader';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import DetailsBox from './DetailsBox';
import DocumentTable from './DocumentTable';
import Banner from '../components/Banner/Banner';
import FooterButtons from './FooterButtons';
import AcceptYieldModal from './AcceptYieldModal';
import fxOpActions from '../actions';
import Button from '@material-ui/core/Button';
import factorxApi from '../../../../../services/factorxApi';
import { descargaArchivo } from '../../../../../helpers/FileHelper';
import ReintentarCesionModal from './ReintentarCesionModal';
import actions from '../../../../main/reducer';
import SeleccionCertificadoCesionPartial from './SeleccionCertificadoCesionPartial';
import { Checkbox } from '@material-ui/core';
import TermsAndConditions from '../../../../sharedv2/TermsAndConditions/TermsAndConditions';
import ConfirmacionSincronizacionAutomatica from './ConfirmacionSincronizacionAutomatica';
import { AppState } from '../../../../../config/rootReducer';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { esEjecutivoAsistente } from '../../../../../helpers/UserHelper';

interface IOperacionEnProcesoDetalleProps extends RouteComponentProps<{ id: string, paraCeder?: string }>, WithStyles<typeof HistoricoStyle> {
  operacion: Dto.BackOffice.Operacion | undefined;
  empresa: Dto.Portal.Empresa;
  autorizarOperacionDocumentos: (payload: Payloads.AutorizarOperacionPayload) => Promise<boolean>;
  reintentoCesionDocumentosOperacion: (payload: Payloads.ReintentoCesionDocumentosOperacionPayload) => Promise<boolean>;
  obtenerOperacion: (numeroOperacion: number, paraCeder: boolean) => Promise<Mensaje.ObtenerOperacionBackOfficeRespuesta>;
  notificacion: (exito: boolean, mensaje: string) => void;
  esEjecutivo: boolean;
}

interface IState {
  loading: boolean;
  userData: Comun.Dto.LoginUserInfo;
  numeroOperacion: number;
  paraCeder: boolean;
  idCertificadoCesion?: number;
  idsCertificadosContrato?: any[];
  idCuentaCorriente: number;
  passwordCertificadoCesion: string;
  agregarCuentaCorrienteModal: boolean;
  expanded: boolean;
  activeTab: number;
  abrirModalCesion: boolean;
  termsModal: boolean;
  detalleOperacionModalState: boolean;
  cesionErrorModal: boolean;
  suscripcionAutomatica: boolean;
  mostrarCheckSuscripcionAutomatica: boolean;
  abrirModalConfirmacionSincronizacionAutomatica: boolean;
}

class OperacionEnProcesoDetalle extends React.Component<IOperacionEnProcesoDetalleProps, IState> {
  constructor(props: IOperacionEnProcesoDetalleProps) {
    super(props);
    this.state = {
      loading: true,
      userData: JSON.parse(sessionStorage.getItem('user') || ''),
      numeroOperacion: Number(this.props.match.params.id),
      paraCeder: Number('0' + this.props.match.params.paraCeder) === 1,
      idCertificadoCesion: undefined,
      idCuentaCorriente: 0,//undefined,
      passwordCertificadoCesion: '',
      agregarCuentaCorrienteModal: false,
      expanded: true,
      activeTab: 0,
      abrirModalCesion: false,
      cesionErrorModal: false,
      termsModal: false,
      suscripcionAutomatica: true,
      mostrarCheckSuscripcionAutomatica: true,
      abrirModalConfirmacionSincronizacionAutomatica: false,
      detalleOperacionModalState: false,
      idsCertificadosContrato: undefined
    };

    this.obtenerOperacion = this.obtenerOperacion.bind(this);
    this.descargarAdjuntoDocumento = this.descargarAdjuntoDocumento.bind(this);
    this.aceptarOperacion = this.aceptarOperacion.bind(this);
    this.reintentoCesionDocumentosOperacion = this.reintentoCesionDocumentosOperacion.bind(this);
  }

  componentDidMount() {
    this.obtenerOperacion(this.state.numeroOperacion, this.state.paraCeder);
  }

  componentDidUpdate(prevProps: IOperacionEnProcesoDetalleProps, prevState: IState, snapshot: any) {
    if (this.props.empresa !== prevProps.empresa && this.props.empresa.sincronizacionDteCorrecta) {
      this.setState((state) => ({
        mostrarCheckSuscripcionAutomatica: false,
        suscripcionAutomatica: true
      }));
    }
  }

  obtenerOperacion(numeroOperacion: number, paraCeder: boolean) {
    this.setState({ loading: true });
    this.props.obtenerOperacion(numeroOperacion, paraCeder)
        .then((data: Mensaje.ObtenerOperacionBackOfficeRespuesta) => {
          if (!data.exito) {
            this.props.notificacion(false, data.mensaje);
          }
          this.setState({ loading: false });
        });
  }

  getTabName = (estadoOperacion: number) => {
    let name = '';
    name = 'DOCUMENTOS OPERACIÓN';
    return name;
  };

  mostrarModalConfirmacionSincronizacionAutomatica = () => {
    if (this.props.esEjecutivo) return;
    if (this.puedeEjecutarOperacion()) {
      if (this.state.suscripcionAutomatica) {
        this.mostrarModalAceptarOperacion();
      } else {
        this.setState((state) => ({
          abrirModalConfirmacionSincronizacionAutomatica: true
        }));
      }
    }
  };

  cierreConfirmacionSincronizacionAutomatica = (autorizo: boolean) => {
    this.setState((state) => ({
      abrirModalConfirmacionSincronizacionAutomatica: false,
      suscripcionAutomatica: autorizo,
      abrirModalCesion: true
    }));
  };

  mostrarModalAceptarOperacion = () => {
    if (this.props.esEjecutivo) return;
    if (this.puedeEjecutarOperacion()) {
      this.setState((state) => ({
        abrirModalCesion: true
      }));
    }
  };

  esconderModalAceptarOperacion = () => {
    this.setState((state) => ({
      abrirModalCesion: false
    }));
  };

  aceptarOperacion = async () => {
    if (this.state.idCertificadoCesion) {
      const modelo: Factoring.Mensaje.Requerimiento.Operacion.ActualizarIdentidadCesion = {
        contrasenaIngresoSucursal: '',
        idCertificado: this.state.idCertificadoCesion,
        contrasenaCertificado: this.state.passwordCertificadoCesion,
        idExterno: this.state.numeroOperacion.toString()
      };
      const resp = await factorxApi.actualizarIdentidadCesionOperacion(modelo);
      if (!resp.exito) {
        this.props.notificacion(false, resp.mensaje);
        return;
      }

      const payload: Payloads.AutorizarOperacionPayload = {
        numeroOperacion: this.state.numeroOperacion.toString(),
        habilitaSincronizacionDte: this.state.suscripcionAutomatica
      };
      const exitosa = await this.props.autorizarOperacionDocumentos(payload);
      if (exitosa) {
        this.props.history.push('/factoring/operaciones/proceso/');
      }
    }
  };

  reintentoCesionDocumentosOperacion = async (idCertificadoCesion: string, passwordCertificadoCesion: string, idsDocumentosAutorizados: string[]): Promise<boolean> => {
    const modelo: Factoring.Mensaje.Requerimiento.Operacion.ActualizarIdentidadCesion = {
      contrasenaIngresoSucursal: '',
      idCertificado: Number(idCertificadoCesion),
      contrasenaCertificado: passwordCertificadoCesion,
      idExterno: this.state.numeroOperacion.toString()
    };
    const resp = await factorxApi.actualizarIdentidadCesionOperacion(modelo);
    if (!resp.exito) {
      this.props.notificacion(false, resp.mensaje);
      return Promise.reject();
    }
    const payload: Payloads.ReintentoCesionDocumentosOperacionPayload = {
      numeroOperacion: this.state.numeroOperacion.toString(),
      idsDocumentosAutorizados: idsDocumentosAutorizados
    };
    return this.props.reintentoCesionDocumentosOperacion(payload)
               .then((exitosa) => {
                 if (exitosa) {
                   this.props.history.push('/factoring/operaciones/proceso/');
                 }
                 return exitosa;
               });
  };

  /* actualiza un state */
  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeTab = (event: React.ChangeEvent<any>, value: number) => {
    this.setState({ activeTab: value });
  };

  handleExpandClick = () => this.setState((state) => ({ expanded: !state.expanded }));

  docsPuedeOperar = () => {
    return this.props.operacion && this.props.operacion.documentos.filter(d => d.aprobadoEvaluacion && d.existeXml && d.aproboFactibilidad) || [];
  };

  puedeEjecutarOperacion = () => {
    const puedenCeder = this.docsPuedeOperar().length;
    let operacion = this.props.operacion;
    const todos = operacion ? operacion.documentos.length : 0;
    const puedeAutorizar = operacion ? operacion.puedeAutorizar : false;
    return this.state.idCertificadoCesion && (puedenCeder === todos) && puedeAutorizar && this.state.passwordCertificadoCesion !== '';//&& this.state.idCuentaCorriente
  };

  descargarAdjuntoDocumento = (idDocumento: string, nombre: string) => {
    let operacion = this.props.operacion;
    if (operacion) {
      factorxApi.descargaArchivoOperacion(operacion.idOperacion, idDocumento, nombre)
                .then((respuesta: Payloads.DescargaArchivoPayload) => {
                  descargaArchivo(respuesta);
                });
    }
  };

  hayEventosPrefactibilidad = (operacion: Dto.BackOffice.Operacion) => {
    return operacion.documentos.filter(r => r.existeXml && r.idEstado !== 1 && !r.aproboFactibilidad).length > 0;
  };

  handleSelectCertificadoCesion = (event: React.ChangeEvent<{}>, checked: boolean) => {
    const element = event.currentTarget as any;
    this.setState({
      idCertificadoCesion: Number(element.value)
    });
  };

  openModalAgregarCuentaCorriente = () => {
    this.setState((state) => ({
      agregarCuentaCorrienteModal: true
    }));
  };

  /** error cesion modal handler */
  /* accept and yield toogle open modal */
  openModalReintentarCesion = () => {
    this.setState((state: IState) => ({
      cesionErrorModal: true
    }));
  };

  handleErrorCesionModalClose = () => {
    this.setState((state: IState) => ({
      cesionErrorModal: false
    }));
  };


  handlerTermsModal = () => {
    this.setState({
      termsModal: true
    });
  };

  handleCloseTerms = () => {
    this.setState({
      termsModal: false
    });
  };

  aceptarYCerrar = () => {
    this.changeTermsConditions(true);
    this.handleCloseTerms();
  };

  changeTermsConditions = (checked: boolean) => {
    this.setState({
      suscripcionAutomatica: checked
    });
  };

  render() {
    const { classes, operacion, empresa, esEjecutivo }: IOperacionEnProcesoDetalleProps = this.props;
    const { activeTab } = this.state;

    const intencionCeder = operacion && operacion.puedeCeder && this.state.paraCeder || false;
    const inicioCesion = operacion && operacion.idEstadoCesion > 0 || false;
    return (
      <div className='container'>
        {this.state.loading ? <Loader /> :
          operacion == null ?
            <FooterButtons desplegarCeder={false} opcionFull={false} onClick={() => {
            }} disabled={true}
            /> :
            <div>
              <Header title={'En proceso detalle #' + operacion.numeroOperacion}
                      breadcrum='Usted se encuentra en: Factoring / Operaciones / En Curse / Detalle' />
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  {operacion.idEstado === 0 &&
                    <Banner text='Esta operación fue rechazada por el Comité.' type='danger' />
                  }
                  {intencionCeder && this.hayEventosPrefactibilidad(operacion) &&
                    <Banner
                      text='El análisis de pre-factibilidad de cesión ha detectado documentos con eventos en el SII, su ejecutivo deberá actualizar la operación.'
                      type='danger' />
                  }
                  {(operacion.idEstado === 2) &&
                    <Banner
                      text='Esta operación aún se encuentra en proceso de evaluación, le notificaremos cuando se libere para que pueda ceder los documentos y finalizar el proceso.'
                      type='warning' />
                  }
                  <Card>
                    <CardHeader
                      action={(
                        <div>
                          <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement='top'>
                            <IconButton onClick={this.handleExpandClick} aria-expanded={this.state.expanded}
                                        aria-label='Show more'>
                              <ExpandMoreIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    />
                    <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
                      <CardContent className={classes.filterCardContent}>
                        <DetailsBox data={operacion} empresa={empresa} />
                      </CardContent>
                    </Collapse>
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={16}>
                {operacion.puedeReintentarCeder &&
                  <Grid item xs={12} sm={12} md={12}>
                    <Button variant='contained' color='secondary'
                            disabled={esEjecutivo}
                            className={classes.btnReintentarCesion}
                            onClick={this.openModalReintentarCesion}>
                      Reintentar Cesión
                    </Button>
                  </Grid>
                }
                <Grid item xs={12} sm={12} md={12}>
                  <div className='tabs'>
                    <Tabs value={activeTab} onChange={this.onChangeTab} classes={{ indicator: 'indicator' }}>
                      <Tab label={this.getTabName(operacion.idEstado)} classes={{ root: 'tabs-factotal' }} />
                    </Tabs>
                    {activeTab === 0 &&
                      <DocumentTable
                        classes={classes}
                        puedeCeder={intencionCeder}
                        inicioCesion={inicioCesion}
                        descargarAdjunto={this.descargarAdjuntoDocumento}
                        documentos={operacion.documentos}
                      />
                    }
                  </div>
                </Grid>
              </Grid>
              {intencionCeder &&
                <Grid container spacing={24} id='detalle-proceso'>
                  <SeleccionCertificadoCesionPartial idCertificadoCesion={this.state.idCertificadoCesion}
                                                     passwordCertificadoCesion={this.state.passwordCertificadoCesion}
                                                     handleSelectCertificadoCesion={this.handleSelectCertificadoCesion}
                                                     onChangeClaveCertificado={this.onChange}
                                                     enModal={false}

                  />

                  <Grid item xs={12} md={12}>
                    <div className='cuadro-dos'>
                      <h5>Terminos y Condiciones</h5>
                      <p className={classes.termsLink}>Al ACEPTAR Y CEDER el usuario y la sociedad a que representa
                        adhieren a los <a
                          onClick={this.handlerTermsModal}>Términos y Condiciones</a>. En estos documentos encontrarás
                        información respecto de cómo se regula
                        contractualmente nuestro negocio, cómo es utilizada la información que nos entregas para poder
                        cursar tu operación de factoring y la definición de procesamiento de dato basado en nuestro
                        interés legítimo. <span
                          style={{ display: this.state.mostrarCheckSuscripcionAutomatica ? '' : 'none' }}>Asimismo, también podrás autorizarnos a utilizar tus datos personales y
                      empresariales para otros fines como la sincronización de DTE desde el SII y así obtener más
                      beneficios.</span>
                      </p>
                      <div style={{ display: this.state.mostrarCheckSuscripcionAutomatica ? '' : 'none' }}>
                        <br />
                        <Checkbox
                          color='primary'
                          checked={this.state.suscripcionAutomatica}
                          onChange={(event) => this.changeTermsConditions(event.target.checked)}
                          inputProps={{ 'aria-label': 'Selecionar' }}
                        />
                        Autorizo el uso de mis datos personales y empresariales para los fines adicionales.
                      </div>
                    </div>
                  </Grid>
                </Grid>
              }
              {intencionCeder &&
                <div style={{ marginBottom: '70px', height: '10px', width: '100%' }} />
              }
              <AcceptYieldModal
                open={this.state.abrirModalCesion}
                onAccept={this.aceptarOperacion}
                onClose={this.esconderModalAceptarOperacion}
              />
              <ConfirmacionSincronizacionAutomatica
                open={this.state.abrirModalConfirmacionSincronizacionAutomatica}
                onAutorizar={() => this.cierreConfirmacionSincronizacionAutomatica(true)}
                onContinuar={() => this.cierreConfirmacionSincronizacionAutomatica(false)}
              />
              <TermsAndConditions open={this.state.termsModal} onClose={this.handleCloseTerms}
                                  onAccept={this.aceptarYCerrar} />
              <FooterButtons desplegarCeder={intencionCeder && !esEjecutivo}
                             opcionFull={this.state.suscripcionAutomatica}
                             onClick={this.mostrarModalConfirmacionSincronizacionAutomatica}
                             disabled={!this.puedeEjecutarOperacion()}
              />
              <ReintentarCesionModal
                open={this.state.cesionErrorModal}
                onClose={this.handleErrorCesionModalClose}
                reintentoCesionDocumentosOperacion={this.reintentoCesionDocumentosOperacion}
                operacion={operacion}
              />
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  operacion: state.operacionesEnProceso.simulacion,
  empresa: state.factorx.empresa,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  autorizarOperacionDocumentos: (payload: Payloads.AutorizarOperacionPayload) => dispatch(fxOpActions.autorizarOperacion(payload)),
  reintentoCesionDocumentosOperacion: (payload: Payloads.ReintentoCesionDocumentosOperacionPayload) => dispatch(fxOpActions.reintentoCesionDocumentosOperacion(payload)),
  obtenerOperacion: (numeroOperacion: number, paraCeder: boolean) => dispatch(fxOpActions.obtenerOperacion(numeroOperacion, paraCeder)),
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(OperacionEnProcesoDetalle));

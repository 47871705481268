import React from 'react';
import Button from '@material-ui/core/Button';
import '../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import { recesionStyle } from './style';
// components
import Header from '../../sharedv2/Header/Header';
import Banner from '../../sharedv2/Banner/banner';
import Loader from '../../shared/Loader/Loader';
import SinCertificadoNotification from '../../sharedv2/SinCertificados/SinCertificados';
import Grid from '@material-ui/core/Grid';
import { FormControl, FormHelperText, TextField, WithStyles } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { formatRut, validaRut } from '../../../helpers/RutHelper';
import { connect } from 'react-redux';
import FormatDate from '../../../helpers/FormatDate';
import factorxApi from '../../../services/factorxApi';
import { formatNumber } from '../../../helpers/FormatNumber';
import actions from '../../main/reducer';
import { validaEmail } from '../../../helpers/EmailHelper';
import { AppState } from '../../../config/rootReducer';
import { esEjecutivoAsistente } from '../../../helpers/UserHelper';

interface IProps extends WithStyles<typeof recesionStyle> {
  // classes: any,
  theme: any;
  certificados: Core.Dto.Certificado[];
  empresa: Dto.Portal.Empresa;
  notificacion: (exito: boolean, mensaje: string) => void;
  esEjecutivo: boolean;
}

interface IState {
  estaCargando: boolean;
  razonSocialCesionario: string;
  razonSocialCesionarioMaxlength: number;
  razonSocialCesionarioChars: number;
  razonSocialCesionarioCharsError: boolean;
  direccionCesionario: string;
  direccionCesionarioMaxlength: number;
  direccionCesionarioChars: number;
  direccionCesionarioCharsError: boolean;
  emailCesionario: string;
  emailCesionarioMaxlength: number;
  emailCesionarioChars: number;
  emailCesionarioCharsError: boolean;
  rutCesionario: string;
  rutCesionarioError: boolean | null;
  contrasenia: string;
  certificado: string;
  fileSelected: File | undefined;
  validFile: boolean | null;
  folio: string;
  monto: string;
  tipoDte: string;
  errorAec: string;
}

class Recesion extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      estaCargando: true,
      razonSocialCesionario: '',
      razonSocialCesionarioMaxlength: 80,
      razonSocialCesionarioChars: 0,
      razonSocialCesionarioCharsError: false,
      direccionCesionario: '',
      direccionCesionarioMaxlength: 80,
      direccionCesionarioChars: 0,
      direccionCesionarioCharsError: false,
      emailCesionario: '',
      emailCesionarioMaxlength: 40,
      emailCesionarioChars: 0,
      emailCesionarioCharsError: false,
      contrasenia: '',
      certificado: '0',
      fileSelected: undefined,
      rutCesionario: '',
      rutCesionarioError: null,
      validFile: null,
      folio: '',
      monto: '',
      tipoDte: '',
      errorAec: ''
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      estaCargando: false
    });
  }

  handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.countsChars(event);
  };

  countsChars = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    if (event.target.value.length <= this.state[`${event.target.id}Maxlength`]) {
      // @ts-ignore
      this.setState({
        [`${event.target.id}Chars`]: event.target.value.length,
        [`${event.target.id}CharsError`]: false,
        [`${event.target.id}`]: event.target.value
      });
    } else {
      // @ts-ignore
      this.setState({
        [`${event.target.id}Chars`]: event.target.value.length,
        [`${event.target.id}CharsError`]: true
      });
    }
  };

  handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeRadio = (event: React.ChangeEvent<any>, value: string) => {
    // @ts-ignore
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  changeRutInput = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formatRut(event);
    this.setState({
      rutCesionarioError: !validaRut(event.target.value),
      rutCesionario: event.target.value
    });
  };


  handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      let file = files[0];
      const ext = file.name.split('.')[1].toLowerCase();
      let valid = (ext === 'xml' && file.type === 'text/xml');

      if (valid) {
        factorxApi.verificarAec(file)
                  .then(resp => {
                    if (resp.exito) {
                      this.setState({
                        folio: resp.folio.toString(),
                        monto: resp.monto.toString(),
                        tipoDte: resp.tipoDte,
                        errorAec: '',
                        validFile: true,
                        fileSelected: file
                      });
                    } else {
                      this.setState({
                        errorAec: resp.mensaje,
                        validFile: false,
                        fileSelected: undefined
                      });
                    }
                  });
      } else {
        this.setState({
          validFile: false,
          errorAec: 'Formato incorrecto (no XML)',
          fileSelected: undefined
        });
      }
    }

  };

  receder = () => {
    if (!this.puedeReceder() || this.props.esEjecutivo) return;
    this.setState({ estaCargando: true });
    if (this.state.fileSelected) {
      const payload: Payloads.RecederDocumentoPayload = {
        rutCesionario: this.state.rutCesionario,
        razonSocialCesionario: this.state.razonSocialCesionario,
        idCertificado: Number(this.state.certificado),
        direccionCesionario: this.state.direccionCesionario,
        correoElectronicoNotificacion: this.state.emailCesionario,
        contrasenaCertificado: this.state.contrasenia,
        aec: this.state.fileSelected
      };
      factorxApi.recederAec(payload)
                .then(respuesta => {
                  if (respuesta.exito) {
                    this.setState({ ...this.state, estaCargando: false, fileSelected: undefined, validFile: null });
                    if (respuesta.mensaje) {
                      this.props.notificacion(true, respuesta.mensaje);
                    }
                  } else {
                    this.props.notificacion(false, respuesta.mensaje);
                    this.setState({ estaCargando: false });
                  }
                });
    }
  };

  puedeReceder = (): boolean => {
    const { certificados, esEjecutivo } = this.props;
    const {
      razonSocialCesionarioChars,
      razonSocialCesionarioCharsError,
      direccionCesionarioCharsError,
      direccionCesionarioChars,
      emailCesionario,
      emailCesionarioChars,
      emailCesionarioCharsError,
      certificado,
      fileSelected,
      rutCesionarioError,
      validFile,
      contrasenia
    } = this.state;
    return (certificados.length > 0) && (contrasenia !== '') &&
      razonSocialCesionarioChars > 0 && !razonSocialCesionarioCharsError &&
      direccionCesionarioChars > 0 && !direccionCesionarioCharsError &&
      emailCesionarioChars > 0 && !emailCesionarioCharsError && validaEmail(emailCesionario) &&
      Number(certificado) > 0 && fileSelected !== undefined && validFile === true && rutCesionarioError !== null && !esEjecutivo;
  };


  render() {
    const { certificados, classes } = this.props;
    const {
      razonSocialCesionario,
      razonSocialCesionarioChars,
      razonSocialCesionarioCharsError,
      razonSocialCesionarioMaxlength,
      direccionCesionario,
      direccionCesionarioCharsError,
      direccionCesionarioChars,
      emailCesionario,
      emailCesionarioChars,
      emailCesionarioCharsError,
      direccionCesionarioMaxlength,
      emailCesionarioMaxlength,
      certificado,
      contrasenia,
      fileSelected,
      rutCesionario,
      rutCesionarioError,
      validFile,
      errorAec
    } = this.state;

    return (
      <div className='container'>
        {this.state.estaCargando ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Header
              title='Receder documento'
              breadcrum='Usted se encuentra en: Operaciones / Recesión'
            />
            <Banner text='Sigue las instrucciones para poder receder un documento a otra empresa.' />
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <form className={classes.form}>
                  <div className={classes.contentFormControl}>
                    <div className={classes.titleLabel}>
                      1. Cargue el archivo AEC (.XML) de la sesión anterior
                    </div>
                    <p className={classes.parraf}>
                      Una vez que Factotal haya cedido el DTE asociado a la devolución, recibirás un
                      email de confirmación de Datamart con un archivo adjunto. Descarga el AEC
                      (Archivo Electrónico de cesión), sin modificarlo y adjúntalo acá.
                    </p>
                    <div className={classes.uploadContent}>
                      <TextField
                        id='file'
                        label='Archivo AEC'
                        value={fileSelected ? fileSelected.name : ''}
                        className={classes.formControl}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <FormControl className={[classes.formControl, classes.ml].join(' ')}>
                        <TextField
                          // accept='text/xml'
                          id='fileSelected'
                          type='file'
                          required
                          className={classes.inputFile}
                          onChange={this.handleChangeFile}
                        />
                        <label htmlFor='fileSelected'>
                          <Button variant='outlined' color='primary' component='span'>
                            Adjuntar
                          </Button>
                        </label>
                      </FormControl>
                    </div>
                    {(!validFile && validFile !== null) &&
                      <FormHelperText className={classes.fileError} id='fileSelected-text'>{errorAec}</FormHelperText>
                    }
                    {validFile &&
                      <FormHelperText className={classes.fileOk} id='fileSelected-text'>
                        {this.state.tipoDte} número {formatNumber(Number(this.state.folio))} y
                        monto {formatNumber(Number(this.state.monto))}
                      </FormHelperText>
                    }

                  </div>
                  <div className={classes.contentFormControl}>
                    <div className={classes.titleLabel}>
                      2. Ingrese la información del nuevo cesionario
                    </div>
                    <p className={classes.parraf}>
                      Ingresa la información de la empresa a quien le cederá tus documentos
                    </p>
                    <div className={classes.formControlContent}>
                      <FormControl className={classes.formControl}>
                        <TextField id='rutCesionario'
                                   required
                                   label='RUT Cesionario'
                                   onChange={this.changeRutInput}
                                   value={rutCesionario}
                                   error={rutCesionarioError || false}
                        />
                        {rutCesionarioError &&
                          <FormHelperText className={classes.countLetterError} id='rut-cesionario-text'>Formato
                            Incorrecto</FormHelperText>
                        }
                      </FormControl>
                    </div>
                    <div className={classes.formControlContent}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id='razonSocialCesionario'
                          required
                          label='Razón Social Cesionario'
                          value={razonSocialCesionario}
                          onChange={this.handleChange}
                          error={razonSocialCesionarioCharsError}
                        />
                        <FormHelperText
                          className={
                            !razonSocialCesionarioCharsError ? classes.countLetter : classes.countLetterError
                          }
                          id='razon-social-text'
                        >
                          {razonSocialCesionarioChars}/{razonSocialCesionarioMaxlength}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.formControlContent}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id='direccionCesionario'
                          required
                          label='Dirección Cesionario'
                          value={direccionCesionario}
                          onChange={this.handleChange}
                          error={direccionCesionarioCharsError}
                        />
                        <FormHelperText
                          className={
                            !direccionCesionarioCharsError ? classes.countLetter : classes.countLetterError
                          }
                          id='direccion-text'
                        >
                          {direccionCesionarioChars}/{direccionCesionarioMaxlength}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div className={classes.formControlContent}>
                      <FormControl className={classes.formControl}>
                        <TextField
                          id='emailCesionario'
                          required
                          value={emailCesionario}
                          label='Correo Electrónico Cesionario'
                          onChange={this.handleChange}
                          error={emailCesionarioCharsError || !validaEmail(emailCesionario)}
                        />
                        <FormHelperText
                          className={
                            !emailCesionarioCharsError ? classes.countLetter : classes.countLetterError
                          }
                          id='correo-electronico-text'
                        >
                          {emailCesionarioChars}/{emailCesionarioMaxlength}
                        </FormHelperText>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.contentFormControl}>
                    <div className={classes.titleLabel}>
                      3. Selecciona certificado digital para la cesión
                    </div>
                    <p className={classes.parraf}>
                      Debes seleccionar un certificado digital para tu cesión online
                    </p>
                    {certificados.length === 0 && (
                      <SinCertificadoNotification />
                    )}
                    {certificados.length > 0 && (
                      <React.Fragment>
                        <div className={classes.radioButtonsContent}>
                          <RadioGroup
                            aria-label='certificado'
                            name='certificado'
                            id='certificado'
                            value={certificado}
                            onChange={this.handleChangeRadio}
                          >
                            {certificados.map((certificado) =>
                              <FormControlLabel
                                key={certificado.id}
                                value={certificado.id.toString()}
                                control={<Radio className={classes.radioButton} />}
                                label={
                                  <div className={classes.formControlLabelCheck}>
                                    {certificado.nombre} válido
                                    desde <span>{FormatDate(certificado.fechaEmision)} hasta {FormatDate(certificado.fechaVencimiento)}</span>
                                  </div>
                                }
                                labelPlacement='end'
                              />
                            )}
                          </RadioGroup>
                        </div>
                        <p className={classes.parraf}>
                          INGRESE CONTRASEÑA DEL CERTIFICADO
                        </p>
                        <p className={classes.parraf}>
                          Para autorizar debe ingresar la clave del certificado digital seleccioando
                        </p>
                        <div className={classes.formControlContentPass}>
                          <FormControl className={classes.formControl}>
                            <TextField
                              id='contrasenia'
                              value={contrasenia}
                              type='password'
                              required
                              label='Contraseña Certificado'
                              autoComplete='false'
                              onChange={this.handleChangeInput}
                            />
                            <FormHelperText />
                          </FormControl>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                  <div className={classes.contentFormControl}>
                    <p className={classes.parraf}>
                      CONDICIONES
                    </p>
                    <p className={classes.parraf}>El usuario acepta transferir el dominio de la factura individualizada
                      en el AEC adjunto a la empresa </p>
                  </div>
                  <Button variant='contained' color='primary'
                          disabled={this.state.estaCargando || !this.puedeReceder()}
                          onClick={() => this.receder()}
                  >Ceder</Button>
                </form>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  certificados: state.factorx.empresa.certificados.filter(d => d.paraCeder),
  empresa: state.factorx.empresa,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: any) => ({
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(recesionStyle)(connect(mapStateToProps, mapDispatchToProps)(Recesion));

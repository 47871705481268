import factorxApi from '../../../services/factorxApi';
import { asyncActionType } from '../../../helpers/ActionsHelper';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import { Dispatch } from 'redux';

const cargarDatosGlobalAction = asyncActionType('@@FX_GLOBAL', 'FETCH');
const obtenerEmpresaUsuarioAction = asyncActionType('@@FX_EMPRESA_USUARIO', 'FETCH');
const subirCertificadoAction = asyncActionType('@@FX_EMPRESA_CERTIFICADO', 'SAVE');
const obtenerCertificadoAction = asyncActionType('@@FX_EMPRESA_CERTIFICADO', 'FETCH');
const eliminarCertificadoAction = asyncActionType('@@FX_EMPRESA_CERTIFICADO', 'DELETE');
const actualizarCertificadoAction = asyncActionType('@@FX_EMPRESA_CERTIFICADO', 'MODIFY');

const eliminarCuentaCorrienteAction = asyncActionType('@@FX_EMPRESA_CUENTACORRIENTE', 'DELETE');
const crearCuentaCorrienteAction = asyncActionType('@@FX_EMPRESA_CUENTACORRIENTE', 'SAVE');
const actualizarCuentaCorrienteAction = asyncActionType('@@FX_EMPRESA_CUENTACORRIENTE', 'MODIFY');

const cargarDatosEmpresaUsuarioThunk = () => (dispatch : Dispatch) => {
  dispatch(cargarDatosEmpresaUsuario.request());
  return factorxApi.getDatosEmpresaUsuario()
                   .then((response) => dispatch(cargarDatosEmpresaUsuario.succeed(response)))
                   .catch(error => error);
};

const cargarDatosEmpresaUsuario = Object.assign(cargarDatosEmpresaUsuarioThunk, {
  request: () => ({
    type: obtenerEmpresaUsuarioAction.PENDING
  }),
  succeed: (response : Mensaje.ObtenerUsuarioPortalRespuesta) => ({
    type: obtenerEmpresaUsuarioAction.SUCCESS,
    empresa: response.productos[0].empresas.filter(e => e.defecto)[0],
    usuario: response.usuario
  })
});

const subirCertificadoThunk = (payload : Payloads.ArchivoCertificado) => (dispatch : Dispatch) => {
  return new Promise<Mensaje.AgregarCertificadoRespuesta>(function (resolve, reject) {
    dispatch(subirCertificado.request());
    return factorxApi.subirCertificado(payload)
                     .then((response : Mensaje.AgregarCertificadoRespuesta) => {
                       if (response.exito) {
                         factorxApi.listarCertificados()
                                   .then(respCert => {
                                     if (respCert.exito) {
                                       dispatch(cargarCertificados.succeed(respCert));
                                     }
                                   });
                       }
                       resolve(response);
                     })
                     .catch(error => error);
  });
};


const subirCertificado = Object.assign(subirCertificadoThunk, {
  request: () => ({
    type: subirCertificadoAction.PENDING
  }),
  succeed: (response : Mensaje.AgregarCertificadoRespuesta) => ({
    type: subirCertificadoAction.SUCCESS
  })
});


const cargarCertificadosThunk = () => (dispatch : Dispatch) => {
  dispatch(cargarCertificados.request());
  return factorxApi.listarCertificados()
                   .then((response) => dispatch(cargarCertificados.succeed(response)))
                   .catch(error => error);
};


const cargarCertificados = Object.assign(cargarCertificadosThunk, {
  request: () => ({
    type: obtenerCertificadoAction.PENDING
  }),
  succeed: (response : Mensaje.ListarCertificadoRespuesta) => ({
    type: obtenerCertificadoAction.SUCCESS,
    certificados: response.exito ? response.certificados : []
  })
});

const eliminarCertificadoThunk = (id : number) => (dispatch : Dispatch) : Promise<Mensaje.EliminarCertificadoRespuesta> => {
  return new Promise(function (resolve, reject) {
    dispatch(eliminarCertificado.request());
    return factorxApi.eliminarCertificado(id)
                     .then((response : Mensaje.EliminarCertificadoRespuesta) => {
                       dispatch(eliminarCertificado.succeed(response.exito ? Number(id) : -1));
                       resolve(response);
                     })
                     .catch(error => error);
  });
};


const eliminarCertificado = Object.assign(eliminarCertificadoThunk, {
  request: () => ({
    type: eliminarCertificadoAction.PENDING
  }),
  succeed: (id : number) => ({
    type: eliminarCertificadoAction.SUCCESS,
    id: id
  })
});


const actualizarCertificadoThunk = (payload : Payloads.ActualizarCertificadoPayload) => (dispatch : Dispatch) : Promise<Mensaje.GuardarCertificadoRespuesta> => {
  return new Promise(function (resolve, reject) {
    dispatch(actualizarCertificado.request());
    return factorxApi.actualizarCertificado(payload)
                     .then((response : Mensaje.GuardarCertificadoRespuesta) => {
                       dispatch(actualizarCertificado.succeed({
                         id: response.exito ? Number(payload.idCertificado) : -1,
                         paraCeder: payload.paraCeder,
                         paraContrato: payload.paraContrato
                       }));
                       resolve(response);
                     })
                     .catch(error => error);
  });
};


const actualizarCertificado = Object.assign(actualizarCertificadoThunk, {
  request: () => ({
    type: actualizarCertificadoAction.PENDING
  }),
  succeed: (payload : any) => ({
    type: actualizarCertificadoAction.SUCCESS,
    payload: payload
  })
});

const cargarDatosGlobalThunk = () => (dispatch : Dispatch) => {
  dispatch(cargarDatosGlobal.request());
  return factorxApi.cargarGlobal()
                   .then((response : Core.Mensaje.Respuesta.Global.Obtener) => {
                     if (response.exito) {
                       dispatch(cargarDatosGlobal.succeed({
                         bancos: response.bancos,
                         comunas: response.comunas,
                         tipoCuentas: response.tipoCuentas,
                         proveedoresFacturacion: response.proveedoresFacturacion
                       }));
                     }
                   })
                   .catch(error => error);
};

const cargarDatosGlobal = Object.assign(cargarDatosGlobalThunk, {
  request: () => ({
    type: cargarDatosGlobalAction.PENDING
  }),
  succeed: (payload : any) => ({
    type: cargarDatosGlobalAction.SUCCESS,
    payload: payload
  })
});


const eliminarCuentaCorrienteThunk = (id : number) => (dispatch : Dispatch) : Promise<Mensaje.EliminarFormaPagoRespuesta> => {
  dispatch(eliminarCuentaCorriente.request());
  return factorxApi.eliminarCuentaCorriente(id)
                   .then((response) => {
                     dispatch(eliminarCuentaCorriente.succeed(response.exito ? Number(id) : -1));
                     return response;
                   })
                   .catch(error => error);
};


const eliminarCuentaCorriente = Object.assign(eliminarCuentaCorrienteThunk, {
  request: () => ({
    type: eliminarCuentaCorrienteAction.PENDING
  }),
  succeed: (id : number) => ({
    type: eliminarCuentaCorrienteAction.SUCCESS,
    id: id
  })
});

const crearCuentaCorrienteThunk = (payload : Payloads.CrearFormaPagoPayload) => (dispatch : Dispatch) : Promise<Mensaje.AgregarFormaPagoRespuesta> => {
  dispatch(crearCuentaCorriente.request());
  return factorxApi.agregarCuentaCorriente(payload)
                   .then((response) => {
                     if (response.exito) {
                       factorxApi.listarCuentaCorriente()
                                 .then(respCta => {
                                   if (respCta.exito) {
                                     dispatch(crearCuentaCorriente.succeed(respCta));
                                   }
                                 });
                     }
                     return response;
                   })
                   .catch(error => error);
};


const crearCuentaCorriente = Object.assign(crearCuentaCorrienteThunk, {
  request: () => ({
    type: crearCuentaCorrienteAction.PENDING
  }),
  succeed: (payload : any) => ({
    type: crearCuentaCorrienteAction.SUCCESS,
    payload: payload
  })
});


const actualizarCuentaCorrienteThunk = (payload : Payloads.ActualizarFormaPagoPayload) => (dispatch : Dispatch) : Promise<Mensaje.GuardarFormaPagoRespuesta> => {
  dispatch(actualizarCuentaCorriente.request());
  return factorxApi.actualizarCuentaCorriente(payload)
                   .then((response) => {
                     if (response.exito) {
                       dispatch(actualizarCuentaCorriente.succeed(response));
                     }
                     return response;
                   })
                   .catch(error => error);
};


const actualizarCuentaCorriente = Object.assign(actualizarCuentaCorrienteThunk, {
  request: () => ({
    type: actualizarCuentaCorrienteAction.PENDING
  }),
  succeed: (payload : Mensaje.GuardarFormaPagoRespuesta) => ({
    type: actualizarCuentaCorrienteAction.SUCCESS,
    payload: payload
  })
});

const limpiarRenovacion = () => {
  const timer = Number(sessionStorage.getItem('_handlerTimeOut'));
  // console.log('limpiarRenovacion: ' + timer);

  if (timer !== 0) {
    clearTimeout(timer);
    sessionStorage.removeItem('_handlerTimeOut');
  }
};


const agendarRenovacion = () => {
  const tkn = sessionStorage.getItem('fx-token') || '';
  const _idEmpresaPais : Comun.Dto.IdEmpresaPais = JSON.parse(sessionStorage.getItem('fx-id') || '');

  if (tkn !== '') {
    let jwt : any = jwt_decode(tkn);
    let fechaExpiracion = moment(new Date(jwt.exp * 1000));
    const ms = fechaExpiracion.diff(moment()) - 1000 * 60 * 3;
    //var duracion = moment.duration(fechaExpiracion.diff(moment()));
    // console.log('setTimeout ' + jwt.email, _idEmpresaPais, ms);
    limpiarRenovacion();
    const renovar = setTimeout(() => dispararRenovacionToken(_idEmpresaPais), ms);
    sessionStorage.setItem('_handlerTimeOut', renovar.toString());
    // console.log('timerConfigurado ' + jwt.email, renovar);
  }
};

const dispararRenovacionToken = (idEmpresaPais : Comun.Dto.IdEmpresaPais) => {
  return new Promise(function (resolve, reject) {
    factorxApi.renovarToken(idEmpresaPais)
              .then(resp => {
                if (resp && resp.exito) {
                  sessionStorage.setItem('fx-token', resp.token || '');
                  sessionStorage.setItem('fx-id', JSON.stringify(resp.idEmpresaPais));
                  agendarRenovacion();
                  resolve(resp);
                }
              });
  });

};

export default {
  obtenerEmpresaUsuarioAction,
  subirCertificadoAction,
  obtenerCertificadoAction,
  eliminarCertificadoAction,
  actualizarCertificadoAction,
  cargarDatosEmpresaUsuario,
  cargarDatosGlobalAction,
  eliminarCuentaCorrienteAction,
  crearCuentaCorrienteAction,
  actualizarCuentaCorrienteAction,
  subirCertificado,
  cargarCertificados,
  eliminarCertificado,
  actualizarCertificado,
  cargarDatosGlobal,
  eliminarCuentaCorriente,
  crearCuentaCorriente,
  actualizarCuentaCorriente,
  agendarRenovacion,
  limpiarRenovacion
};

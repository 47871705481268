import config from '../config/api';
import { instance } from './factoringApi';
import { buildQueryString } from '../helpers/UrlHelper';
import { authHeader } from '../helpers/AuthHeader';

const apiUserUrl = config.apiUri;

const getEmpresas = (params: Request.RegistroCivil.Empresas): Promise<Response.RegistroCivil.Empresas> => {
  const queryString = buildQueryString(params, []);

  return instance.get(`${apiUserUrl}registroCivil/empresas?${queryString}`, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const getInformeInscripcionesTransferencias = (params: Request.RegistroCivil.InformeInscripcionesTransferencias): Promise<Response.RegistroCivil.InformeInscripcionesTransferencias> => {
  const queryString = buildQueryString(params, []);

  return instance.get(`${apiUserUrl}registroCivil/informesInscripcionesTransferencias?${queryString}`, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const getEmpresaContrato = (idEmpresa: string, params: Request.RegistroCivil.EmpresaContratos): Promise<Response.RegistroCivil.EmpresaContratos> => {
  const queryString = buildQueryString(params, []);

  return instance.get(`${apiUserUrl}registroCivil/${idEmpresa}/contratosEmpresa?${queryString}`, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const nuevoContrato = (idEmpresa: string, params: Request.RegistroCivil.NuevoContrato): Promise<Response.RegistroCivil.NuevoContrato> => {

  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/nuevoContratoEmpresa`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const persistirInscripcion = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.Inscripcion): Promise<Response.RegistroCivil.Inscripcion> => {

  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/inscripcion`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const enviarInscripcion = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.EnviarInscripcion): Promise<Response.RegistroCivil.EnviarInscripcion> => {

  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/enviarInscripcion`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const persistirTransferencia = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.Transferencia): Promise<Response.RegistroCivil.Transferencia> => {

  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/transferencia`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const enviarTransferencia = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.EnviarTransferencia): Promise<Response.RegistroCivil.EnviarTransferencia> => {

  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/enviarTransferencia`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const getTerminacionesDisponiblesPPU = (params: Request.RegistroCivil.TerminacionPPU): Promise<Response.RegistroCivil.TerminacionPPU> => {
  return instance.post(`${apiUserUrl}registroCivil/terminacionesPPU`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};

const cargaDocumento = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.CargaDocumentos): Promise<Response.RegistroCivil.CargaDocumentos> => {
  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/cargaDocumento`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
const obtenerDocumentosInscripcion = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.ObtenerDocumentosInscripcion): Promise<Response.RegistroCivil.ObtenerDocumentosInscripcion> => {
  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/obtenerDocumentosInscripcion`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
const obtenerDocumentosTransferencia = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.ObtenerDocumentosTransferencia): Promise<Response.RegistroCivil.ObtenerDocumentosTransferencia> => {
  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/obtenerDocumentosTransferencia`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
const obtenerHistorialInscripcion = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.ObtenerHistorialInscripcion): Promise<Response.RegistroCivil.ObtenerHistorialInscripcion> => {
  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/obtenerHistorialInscripcion`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
const obtenerHistorialTransferencia = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.ObtenerHistorialTransferencia): Promise<Response.RegistroCivil.ObtenerHistorialTransferencia> => {
  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/obtenerHistorialTransferencia`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
const obtenerCertificadoAnotacionesVigentes = (params: Request.RegistroCivil.ObtenerCertificadoAnotacionesVigentes): Promise<Response.RegistroCivil.ObtenerCertificadoAnotacionesVigentes> => {
  return instance.post(`${apiUserUrl}registroCivil/obtenerCertificadoAnotacionesVigentes`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
const descargarDocumento = (idEmpresa: string, idContrato: string, params: Request.RegistroCivil.DescargarDocumento): Promise<Response.RegistroCivil.DescargarDocumento> => {
  return instance.post(`${apiUserUrl}registroCivil/${idEmpresa}/${idContrato}/descargaDocumento`, params, { headers: authHeader() })
                 .catch(error => error)
                 .then(response => response.data);
};
export default {
  getEmpresas, getEmpresaContrato, nuevoContrato, persistirInscripcion, enviarInscripcion, persistirTransferencia,
  enviarTransferencia, getTerminacionesDisponiblesPPU, cargaDocumento, obtenerDocumentosInscripcion, obtenerDocumentosTransferencia,
  obtenerHistorialInscripcion, obtenerHistorialTransferencia, obtenerCertificadoAnotacionesVigentes, descargarDocumento,
  getInformeInscripcionesTransferencias
};

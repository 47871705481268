import React, { FC } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Collapse, List, ListItem } from '@material-ui/core';
import { useHistory } from 'react-router';
import routes from '../../../config/routes';
import IcoCarteraBlack from '../../../images/icons/ico-cartera-black.svg';
import { menuSidebarStyle } from './menuSidebarStyle';
import FatherMenuItem from './FatherMenuItem';

interface IMenuVendorFinance extends WithStyles<typeof menuSidebarStyle> {
  miniActive : boolean;
  toogleCollapse : (menu : string, fatherCollapse : string) => any;
  isCollapse : string;
  trackingSidebar : (param : string) => any;
  activeRoute : (router : string) => any;
  checkCollapse : (collapse : string[]) => any;
}

const MenuVendorFinance : FC<IMenuVendorFinance> = (props) => {
  const history = useHistory();
  const { classes, miniActive, isCollapse, trackingSidebar, toogleCollapse, activeRoute, checkCollapse } = props;
  const fatherCollapse = 'openVendor';

  const goTo = (to : any, track : string) => {
    trackingSidebar(track);
    history.push(to);
  };

  const handlerToggleCollapse = (collapse : string) => {
    toogleCollapse(collapse, fatherCollapse);
  };

  return (
    <ListItem classes={{ root: classes.listItemContainer }}>
      <FatherMenuItem
        title={'LEASING TECNOLÓGICO'}
        miniActive={miniActive}
        openWhen={['openVendor', 'openVendorOpe', 'openVendorExc']}
        click={() => handlerToggleCollapse('openVendor')}
        collapse={isCollapse}
        fatherCollapse={fatherCollapse}
      />
      <Collapse
        classes={{
          container: classes.collapseContainer,
          entered: classes.collapseEntered
        }}
        in={checkCollapse(['openVendor', 'openVendorOpe', 'openVendorExc'])}
        unmountOnExit
      >
        <List>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.vendor.contratos, 'contratos')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoCarteraBlack} />
              <span>Contratos</span>
            </div>
          </ListItem>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.vendor.bienes, 'bienes')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoCarteraBlack} />
              <span>Bienes</span>
            </div>
          </ListItem>
        </List>
      </Collapse>
    </ListItem>
  );
};

export default withStyles(menuSidebarStyle)(MenuVendorFinance);

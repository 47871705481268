import React, { FC, useState } from 'react';
import cx from 'classnames';
import { TrackingSidebar } from '../../helpers/SidebarTrackingHelper';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Hidden from '@material-ui/core/Hidden';
import { Button, Theme, WithStyles } from '@material-ui/core';
import MiTableroButton from './sidebar/MiTableroButton';
import MenuFactoring from './sidebar/MenuFactoring';
import SidebarWrapper from './sidebar/SidebarWrapper';
import LogoSidebar from './sidebar/LogoSidebar';
import MenuLeasing from './sidebar/MenuLeasing';
import MenuVendorFinance from './sidebar/MenuVendorFinance';
import { drawerMiniWidth, drawerWidth } from '../../styles/jss/generalStyle';
import IcoCerrarSession from '../../images/icons/ico-cerrar-session.svg';
import { useHistory } from 'react-router';
import MenuUser from './sidebar/MenuUser';
import scssTool from '../../styles/scssTool';
import { ActionLogout } from '../general/login/actions';
import { AppState } from '../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';

const drawerStyle = (theme : Theme) => ({
  paperDrawer: {
    position: 'absolute' as 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear',
    overflow: 'hidden' as 'hidden'
  },
  drawerPaper: {
    border: 'none',
    position: 'fixed' as 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 1032,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    textDecoration: 'none',
    // overflow: 'auto',
    boxShadow: '0',
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed' as 'fixed',
      height: '100%'
    },
    nested: {},
    '&:before,&:after': {
      position: 'absolute' as 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      display: 'block',
      top: '0',
      textDecoration: 'none'
    }
  },
  drawerPaperMini: {
    width: `${drawerMiniWidth}px!important`
  },
  cerraSessionContainer: {
    width: '100%',
    ...scssTool.flex('center', 'row', 'center')
  },
  cerrarSessionRoot: {
    height: '49px',
    backgroundColor: 'transparent' as 'transparent',
    border: '1px solid #606060',
    borderRadius: '5px',
    width: '90%'
  },
  cerrarSessionLabel: {
    ...scssTool.font(12, 400, 15),
    color: '#606060',
    '& img': {
      marginRight: '6px'
    }
  }
});

interface IProps extends WithStyles<typeof drawerStyle> {
  miniActive : boolean;
  user : Comun.Dto.LoginUserInfo;
  empresa : Dto.EnterpriseProfile;
  ruta : string;
  dispatch : any
}

const SidebarPublico : FC<IProps> = (props) => {
  const history = useHistory();
  const { classes, empresa, miniActive } = props;
  const [localMiniActive, setLocalMiniActive] = useState<boolean>(false);
  const [isCollapse, setCollapse] = useState<string>('');

  const toogleCollapse = (menu : string, fatherCollapse : string) => {
    if (isCollapse === menu && fatherCollapse !== menu) {
      setCollapse(fatherCollapse);
    } else {
      setCollapse(menu);
    }
    if (isCollapse !== '') {
      if (fatherCollapse === menu) {
        setCollapse('');
      }
    }
  };

  const trackingSidebar = (param : string) => {
    TrackingSidebar(param)().then((data) => data);
  };

  const activeRoute = (routeName : string) => {
    return history.location.pathname.indexOf(routeName) > -1;
  };

  // Checkear acá cual está abierto
  const checkCollapse = (collapses : string[]) => {
    let f = false;
    collapses.forEach((c) => {
      if (isCollapse === c) {
        f = true;
      }
    });
    return f;
  };

  const logOutClick = () => {
    props.dispatch(ActionLogout());
  };

  return (
    <div>
      <Hidden implementation='css'>
        <Drawer
          onMouseOver={() => setLocalMiniActive(false)}
          onMouseOut={() => setLocalMiniActive(true)}
          anchor={'right'}
          variant='permanent'
          open
          classes={{
            paper: cx(classes.paperDrawer, classes.drawerPaper, {
              [classes.drawerPaperMini]: miniActive
            })
          }}
        >
          <LogoSidebar miniActive={miniActive && localMiniActive} />
          <SidebarWrapper
            headerLinks={
              <MenuUser
                miniActive={miniActive && localMiniActive}
                toogleCollapse={toogleCollapse}
                trackingSidebar={trackingSidebar}
                activeRoute={activeRoute}
                isCollapse={isCollapse}
                checkCollapse={checkCollapse}
                empresa={empresa}
              />
            }
            links={
              <React.Fragment>
                <List style={{ flexGrow: 1, width: '100%' }}>
                  <ListItem>
                    <MiTableroButton
                      miniActive={miniActive && localMiniActive}
                    />
                  </ListItem>
                  {empresa.factoring && (
                    <MenuFactoring
                      miniActive={miniActive && localMiniActive}
                      toogleCollapse={toogleCollapse}
                      trackingSidebar={trackingSidebar}
                      activeRoute={activeRoute}
                      isCollapse={isCollapse}
                      checkCollapse={checkCollapse}
                    />
                  )}
                  {empresa.leasing && (
                    <MenuLeasing
                      miniActive={miniActive && localMiniActive}
                      toogleCollapse={toogleCollapse}
                      trackingSidebar={trackingSidebar}
                      activeRoute={activeRoute}
                      isCollapse={isCollapse}
                      checkCollapse={checkCollapse}
                    />
                  )}
                  {empresa.vendor && (
                    <MenuVendorFinance
                      miniActive={miniActive && localMiniActive}
                      toogleCollapse={toogleCollapse}
                      trackingSidebar={trackingSidebar}
                      activeRoute={activeRoute}
                      isCollapse={isCollapse}
                      checkCollapse={checkCollapse}
                    />
                  )}
                </List>
                <div className={classes.cerraSessionContainer}>
                  <Button
                    classes={{
                      root: classes.cerrarSessionRoot,
                      label: classes.cerrarSessionLabel
                    }}
                    onClick={logOutClick}
                  >
                    <img src={IcoCerrarSession} />
                    Cerrar sesión
                  </Button>
                </div>
              </React.Fragment>
            }
            miniActive={miniActive && localMiniActive}
          />
        </Drawer>
      </Hidden>
    </div>
  );
};

const mapStateToProps = (state : AppState) => ({});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, null, AnyAction>) => ({
  dispatch: (action : AnyAction) => dispatch(action)
});


export default withStyles(drawerStyle)(connect(mapStateToProps, mapDispatchToProps)(SidebarPublico));

import React, { FC } from "react";
import { Button, WithStyles } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import scssTool from "../../../styles/scssTool";
import classnames from "classnames";

const styleTabs = (theme: Theme) => ({
  rootTab: {
    background: "#82BC00",
    width: "auto",
    height: "52px",
    ...scssTool.flex("center", "row", "center"),
    marginRight: "4px",
    borderRadius: "0px",
    padding: '0px 16px',
    "&:hover": {
      background: "#82BC00",
    },
  },
  labelTab: {
    ...scssTool.font(18, 800, 25),
    color: "#fff",
  },
  inactive: {
    background: "#E6E6E6",
    "& $labelTab": {
      color: "#C4C4C4",
      fontWeight: 400,
    },
    "&:hover": {
      background: "#82BC00",
      "& $labelTab": {
        color: "#fff",
      },
    },
  }
});

interface ICustomTab extends WithStyles<typeof styleTabs> {
  click: (active: number) => any;
  activeTab: number;
  title: string;
  number: number;
}

const CustomTab: FC<ICustomTab> = (props) => {
  const { classes, click, activeTab, title, number } = props;

  return (
    <Button
      classes={{ root: classes.rootTab, label: classes.labelTab }}
      className={classnames({
        [classes.inactive]: activeTab !== number,
      })}
      onClick={() => click(number)}
    >
      {title}
    </Button>
  );
};

export default withStyles(styleTabs)(CustomTab);

import { Theme } from '@material-ui/core';

const bigHeaderStyle = (theme : Theme) => ({
  headerBigContent: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    height: '60px'
  },
  flexgrow: {
    display: 'flex' as 'flex',
    flexGrow: 1,
    alignItems: 'center' as 'cemter',
    height: '38px'
  },
  normalTitle: {
    fontSize: '16px',
    lineHeight: '17px',
    color: '#2E2A25',
    margin: '0px 8px'
  },
  estadoContrato: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    textTransform: 'capitalize' as 'capitalize',
    color: '#82BC00'
  },
  greenButton: {
    background: '#82BC00',
    borderRadius: '4px',
    border: '1px solid #82BC00',
    color: '#fff',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
    marginRight: '12px',
    '&:hover': {
      color: '#fff'
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)'
    }
  },
  greyButton: {
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid #82BC00',
    color: '#82BC00',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      color: '#82BC00'
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)'
    }
  }
});

export default bigHeaderStyle;

const facEmitidasFactoringState = ({
    filterParameters: { },
    rows: [],
    pagination: {
        currentPage: 0,
        top: 0,
        totalItems: 0,
        totalPages: 0,
        from: 0,
        to: 0,
    },
    tipoDocumento : 0,
    pageSize: 0,
    isValidForm: true,
    validationMessages: {
        numeroDocumentoDesde: '',
        numeroDocumentoHasta: ''
    },
});
export default facEmitidasFactoringState;
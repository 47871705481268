import React from 'react';
import Button from '@material-ui/core/Button';
import '../../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import Header from '../../../sharedv2/Header/Header';
import Loader from '../../../shared/Loader/Loader';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableCuentas from './TableCuentas';
import AgregarCuentaModal from './AgregarCuenta';
import EliminarCuentaModal from './EliminarCuenta';
import fxActions from '../actions';
import { connect } from 'react-redux';
import { WithStyles } from '@material-ui/core';
import { AppState } from '../../../../config/rootReducer';
import actions from '../../../main/reducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { esEjecutivoAsistente } from '../../../../helpers/UserHelper';

interface IProps extends WithStyles<typeof HistoricoStyle> {
  formasPago: Comun.Dto.FormaPago[];
  esEjecutivo: boolean;
  notificacion: (exito: boolean, mensaje: string) => void;
  cargarDatosEmpresaUsuario: () => void;
  eliminarCuentaCorriente: (id: number) => Promise<Mensaje.EliminarFormaPagoRespuesta>;
  actualizarCuentaCorriente: (payload: Payloads.ActualizarFormaPagoPayload) => Promise<Mensaje.GuardarFormaPagoRespuesta>;
}

interface IState {
  loading: boolean;
  activeTab: number;
  agregarModal: boolean;
  cuentaSelected: any;
  eliminarModal: boolean;
}

class CuentasBancarias extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: true,
      activeTab: 0,
      agregarModal: false,
      eliminarModal: false,
      cuentaSelected: {}
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  toogleModalAgregar = () => {
    this.setState((state) => ({
      agregarModal: !state.agregarModal
    }));
  };

  saveCuenta = () => {
    this.setState((state: IState) => ({ agregarModal: false }));
    this.props.notificacion(true, 'La cuenta corriente fue agregada exitosamente.');
  };

  openEliminar = (e: Comun.Dto.FormaPago) => {
    this.setState((state: IState) => ({
      eliminarModal: true,
      cuentaSelected: e
    }));
  };

  closeEliminar = () => {
    this.setState((state: IState) => ({ eliminarModal: false }));
  };

  onDeleteCuenta = (idCuenta: number) => {
    this.props.eliminarCuentaCorriente(idCuenta)
        .then((r) => {
          if (r.exito) {
            this.closeEliminar();
            this.props.notificacion(true, 'La cuenta corriente fue eliminada exitosamente.');
          }
        });
  };

  handleChange = (cuentaCorriente: Comun.Dto.FormaPago, predeterminada: boolean, habilitada: boolean) => {
    const payload: Payloads.ActualizarFormaPagoPayload = {
      idCuentaCorriente: Number(cuentaCorriente.id),
      predeterminada: predeterminada,
      habilitada: habilitada
    };
    this.props.actualizarCuentaCorriente(payload)
        .then((r) => {
          if (r.exito) {
            this.props.notificacion(true, 'La cuenta corriente fue actualizada exitosamente.');
          }
        });
  };

  render() {
    const { classes, formasPago } = this.props;
    const { activeTab, agregarModal, eliminarModal, cuentaSelected } = this.state;

    return (
      <div className='container'>
        {this.state.loading || formasPago == null ? <Loader /> :
          <React.Fragment>
            <Header title='Cuentas Bancarias' breadcrum='Usted se encuentra en: Administración / Cuentas Bancarias'>
              <Button variant='contained' color='primary' onClick={this.toogleModalAgregar}>
                Agregar
              </Button>
            </Header>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`tabs ${classes.tabsBackground}`}>
                  <Tabs value={activeTab} classes={{ indicator: 'indicator' }}>
                    <Tab label='CUENTAS BANCARIAS' classes={{ root: 'tabs-factotal' }} />
                  </Tabs>
                  <TableCuentas data={formasPago} onDelete={this.openEliminar}
                                handleChange={this.handleChange} />
                </div>
              </Grid>
            </Grid>
            <AgregarCuentaModal
              open={agregarModal}
              onClose={this.toogleModalAgregar}
              onSave={this.saveCuenta}
            />
            <EliminarCuentaModal
              open={eliminarModal}
              onClose={this.closeEliminar}
              onDelete={this.onDeleteCuenta}
              cuenta={cuentaSelected}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  formasPago: state.factorx.empresa.formasPago,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  eliminarCuentaCorriente: (id: number) => dispatch(fxActions.eliminarCuentaCorriente(id)),
  actualizarCuentaCorriente: (payload: Payloads.ActualizarFormaPagoPayload) => dispatch(fxActions.actualizarCuentaCorriente(payload)),
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(CuentasBancarias));

import * as React from 'react';
import { Theme, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AgregarCertificadoDialog from './AgregarCertificadoDialog';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import scssTool from '../../../../styles/scssTool';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const agregarCertificadoModalStyle = (theme: Theme) => ({
  dialogTitleContainer: {
    ...scssTool.flex('center', 'row', 'space-between'),
    paddingBottom: '24px',
    borderBottom: '1px solid #E5E6E6',
    marginBottom: '32px'
  },
  dialogTitle: {
    ...scssTool.font(20, 400, 23),
    color: '#555759'
  },
  buttonClose: {
    colo: '#555759'
  },
  paperContainer: {
    padding: '24px 45px',
    borderRadius: '7px',
    width: '60%'
  }
});

interface IAgregarCertificadoModalProps extends WithStyles<typeof agregarCertificadoModalStyle> {
  onSave: (certificado: File, clave: string) => void;
  open: boolean;
  onClose: () => void;
}

class AgregarCertificadoModal extends React.Component<IAgregarCertificadoModalProps> {
  state = {};

  onSave = (certificado: File, clave: string) => {
    this.props.onSave(certificado, clave);
  };

  render() {
    const { classes, onClose, open } = this.props;
    return (
      <Dialog onClose={onClose} open={open} classes={{ paper: classes.paperContainer }}>
        <div className={classes.dialogTitleContainer}>
          <span className={classes.dialogTitle}>Carga de certificado digital</span>
          <IconButton className={classes.buttonClose} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <AgregarCertificadoDialog onSave={this.onSave} />
      </Dialog>
    );
  }
}

export default withStyles(agregarCertificadoModalStyle)(AgregarCertificadoModal);

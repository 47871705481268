const SVGIconsStyle = {
  ExcelButton: {
    zIndex: 1,
    '& svg path': {
      fill: '#007934'
    }
  },
  PDFButton: {
    zIndex: 1,
    '& svg path': {
      fill: '#790000'
    }
  },
  CesionIcon: {
    width: 60,
    margin: 'auto',
    '& path': {
      fill: '#82bc00'
    }
  },
  DoneIcon: {
    width: 60,
    margin: 'auto',
    '& path': {
      fill: '#82bc00'
    }
  }
};

export default SVGIconsStyle;

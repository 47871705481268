import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import SVGIcons from '../SVGIcons/SVGIcons';

import modalMsgStyle from '../../../styles/jss/components/modalMsgStyle';

function ModalMsg({...props}) {
  const {classes, openModal, closeModalMsg, title, text} = props;

  const handleClose = () => {
    closeModalMsg();
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={handleClose}
    >
      <div className={classes.modalPaper}>
        <div className={classes.modalHeader}>
          <SVGIcons icon="done" />
        </div>
        <div className={classes.modalContent}>
          <Typography className={classes.modalContentTitle} variant="h6" id="modal-title">
            {title}
          </Typography>
          <Typography className={classes.modalContentSubTitle} variant="subtitle1" id="simple-modal-description">
            {text}
          </Typography>
        </div>
        <div className={classes.modalFooter}>
          <Button
            variant="contained"
            size="large"
            onClick={handleClose}>
            Cerrar
          </Button>
        </div>
      </div>
    </Modal>
  );
}

ModalMsg.defaultProps = {
  openModal: false
};

ModalMsg.propTypes = {
  classes: PropTypes.object.isRequired,
  openModal: PropTypes.bool,
  closeModalMsg: PropTypes.func
};

export default withStyles(modalMsgStyle)(ModalMsg);

import {format} from "date-fns";

const descargaArchivo = (bytes: number[], nombre: string) => {
  const archivo = base64ToArrayBuffer(bytes);
  const blob = new Blob([archivo]);
  const fileName = nombre;
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
function base64ToArrayBuffer(base64: any) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
export const convertStringToDate = (date: string): Date | undefined => {
  if (date === '' || date === undefined || date == null) return undefined;
  const dateParts = date.split('T')[0].split('-')
    .map(Number);
  const retValue = new Date();
  retValue.setFullYear(dateParts[0], dateParts[1] - 1, dateParts[2]);
  return retValue;
};
export const convertDateToStringYMD = (date: Date | undefined | null): string => {
  if (date === undefined || date === null) return '';
  console.log(format(date, 'yyyy-MM-dd') + 'T:00:00:00Z');
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate()
    .toString();
  if (month.length === 1) month = '0' + month;
  if (day.length === 1) day = '0' + day;
  return date.getFullYear() + '-' + month + '-' + day + 'T00:00:00.0000000Z';
};
export const convertDateToStringDMY = (date: Date | undefined | null): string => {
  if (date === undefined || date === null) return '';
  console.log(format(date, 'yyyy-MM-dd') + 'T:00:00:00Z');
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate()
    .toString();
  if (month.length === 1) month = '0' + month;
  if (day.length === 1) day = '0' + day;
  return day + '-' + month + '-' + date.getFullYear() ;
};

export default{descargaArchivo, convertStringToDate, convertDateToStringYMD, convertDateToStringDMY};
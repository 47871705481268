import { createActions, createReducer } from 'reduxsauce';

export interface IMainState {
  isLoading: boolean,
  isLoggedIn: boolean,
  isMenuOpen: boolean,
  isSnackbarOpen: boolean,
  snackColor: string,
  snackMessage: string,
  snackTimeout: number,
  userInfo: Comun.Dto.LoginUserInfo | undefined
}

const { Types, Creators } = createActions({
    setLoading: ['isLoading'],
    logIn: ['userInfo'],
    logOut: null,
    openMenu: null,
    closeMenu: null,
    openSnackBar: ['snackExito', 'snackMessage', 'snackTimeout'],
    closeSnackBar: null
  },
  {
    prefix: '@@MAIN/'
  });


const INITIAL_STATE: IMainState = {
  isLoading: false,
  isLoggedIn: isLogin(),
  isMenuOpen: true,
  isSnackbarOpen: false,
  snackColor: '',
  snackMessage: '',
  snackTimeout: 6000,
  userInfo: getUser()
};

function isLogin(): boolean {
  const sessionUser = sessionStorage.getItem('user');
  if (sessionUser === null) return false;
  let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionUser);
  return !!user;
}

function getUser(): Comun.Dto.LoginUserInfo | undefined {
  const sessionUser = sessionStorage.getItem('user');
  if (sessionUser === null) return undefined;
  let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionUser);
  return user;
}

const setLoading = (state: IMainState, { loading }: { loading: boolean }) => ({ ...state, isLoading: loading });
const logIn = (state: IMainState, { userInfo }: { userInfo: Comun.Dto.LoginUserInfo }) => ({ ...state, isLoggedIn: isLogin(), userInfo: userInfo });
const logOut = (state: IMainState) => ({ ...state, isLoggedIn: false });
const openMenu = (state: IMainState) => ({ ...state, isMenuOpen: true });
const openSnackBar = (state: IMainState, {
  snackExito,
  snackMessage,
  snackTimeout
}: { snackExito: boolean, snackMessage: string, snackTimeout: number }) => ({
  ...state,
  isSnackbarOpen: true,
  snackColor: snackExito ? 'green' : 'red',
  snackMessage: snackMessage,
  snackTimeout: snackTimeout || 6000
});
const closeMenu = (state: IMainState) => ({ ...state, isMenuOpen: false });
const closeSnackBar = (state: IMainState) => ({ ...state, isSnackbarOpen: false });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOADING]: setLoading,
  [Types.LOG_IN]: logIn,
  [Types.LOG_OUT]: logOut,
  [Types.CLOSE_MENU]: closeMenu,
  [Types.OPEN_MENU]: openMenu,
  [Types.OPEN_SNACK_BAR]: openSnackBar,
  [Types.CLOSE_SNACK_BAR]: closeSnackBar
});

export const MainTypes = Types;
export default Creators;

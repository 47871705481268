import {
  Button,
  FormControl,
  IconButton,
  Select,
  Switch,
  TextField,
  Theme
} from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, {FC, useEffect, useState} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import contratosGeneralStyle from '../../contratosGeneralStyle';
import { CODIGO_NOTARIA, COMUNAS, MONEDAS, NATURALEZA, TIPODOCUMENTO } from '../Selectores';
import { TModeloTransferencia } from '../../Contrato/transferencia';
import Datetime from 'react-datetime';

interface IProps extends WithStyles<typeof contratosGeneralStyle> {
  modelo: TModeloTransferencia;
  setState: (modelo: keyof TModeloTransferencia, value: string) => void;
}

const DocumentoTransferencia: FC<IProps> = (props) => {
  const { classes, modelo, setState } = props;
  const [open, setOpen] = useState(false);
  const [fechaFactura, setFechaFactura]= useState(new Date(modelo.fecha));


  const setFechaDocumento=(fecha:any)=>{
    let dt = new Date(fecha);
    setFechaFactura(dt);
    setState('fecha', dt.toUTCString());
    }
  return (
    <div className={classes.greySeccion}>
      <div className={classes.seccionHeader}>
        <div className={classes.leftSeccionHeader}>
          <div className={classes.titleSeccionHeader}>Documento transferencia</div>
          <div className={classes.subtSeccionHeader}>
            Ingrese la información del documento que sustenta la transferencia
          </div>
        </div>
        <div className={classes.openButton} onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</div>
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={classes.containerSeccion}>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Naturaleza</div>
            <Select
              value={modelo.naturaleza}
              onChange={(e) => setState('naturaleza', e.target.value)}
              className={[classes.selectField].join(' ')}
              classes={{ outlined: classes.outlinesSelect }}
              displayEmpty={true}
              variant='outlined'
            >
              {NATURALEZA.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
            </Select>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Tipo documento</div>
            <Select
              value={modelo.tipoDocumento}
              onChange={(e) => setState('tipoDocumento', e.target.value)}
              className={classes.selectField}
              classes={{
                outlined: classes.outlinesSelect
              }}
              displayEmpty={true}
              variant='outlined'
            >
              {TIPODOCUMENTO.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
            </Select>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>RUT Emisor</div>
              <TextField
                value={modelo.rutEmisor}
                onChange={(e) => setState('rutEmisor', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='99666555-7'
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Fecha factura</div>
              <TextField
                value={modelo.fecha}
                onChange={(e) => setState('fecha', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='12/12/2020'
              />
              {/*<Datetime value={fechaFactura}
                          onChange={(e) => setFechaDocumento(e)}
                        timeFormat={false}
                        className={[classes.textField, classes.midleInput].join(' ')}
                        closeOnSelect={true}
              ></Datetime>*/}
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Número</div>
              <TextField
                value={modelo.folioFactura}
                onChange={(e) => setState('folioFactura', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123456'
              />
            </div>
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Lugar</div>
            <Select
              value={modelo.idComunaLugar.toString()}
              onChange={(e) => setState('idComunaLugar', e.target.value)}
              className={[classes.selectField].join(' ')}
              classes={{ outlined: classes.outlinesSelect }}
              displayEmpty={true}
              variant='outlined'
            >
              {COMUNAS.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
            </Select>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Moneda</div>
              <Select
                value={modelo.tipoMoneda}
                onChange={(e) => setState('tipoMoneda', e.target.value)}
                className={[classes.selectField, classes.midleInput].join(' ')}
                classes={{ outlined: classes.outlinesSelect }}
                displayEmpty={true}
                variant='outlined'
              >
                {MONEDAS.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
              </Select>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Monto factura</div>
              <TextField
                value={modelo.monto}
                onChange={(e) => setState('monto', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123456789765434567876543'
              />
            </div>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Kilometraje</div>
              <TextField
                value={modelo.kilometraje}
                onChange={(e) => setState('kilometraje', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='100000'
              />
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Código Notaría</div>
              <Select
                value={modelo.codigoNotaria}
                onChange={(e) => setState('codigoNotaria', e.target.value)}
                className={[classes.selectField].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >
                {CODIGO_NOTARIA.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
              </Select>
            </div>
          </div>

        </div>
      </Collapse>
    </div>
  );
};

export default withStyles(contratosGeneralStyle)(DocumentoTransferencia);

import React from 'react';
import { Button, Grid, WithStyles, withStyles } from '@material-ui/core';
import DashboardStyle from '../../styles/dashboardStyle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CardContent from '@material-ui/core/CardContent';
import { formatNumber } from '../../helpers/FormatNumber';
import CartImg from '../../images/icons/cart-icon.svg';
import SearchImg from '../../images/Search_2.svg';
import HandsImg from '../../images/hands.svg';
import IcoGreenWarning from '../../images/ico-green-warning.svg';
import { cargarEjecutivos, FilterResumenCarteraFactoring } from './actions';
import factorxApi from '../../services/factorxApi';
import LoaderComp from '../sharedv2/Loader/LoaderComp';
import ComparteDatos from './components/ComparteDatos';
import AgilizaOperaciones from './components/AgilizaOperaciones';
import { connect } from 'react-redux';
import { AppState } from '../../config/rootReducer';
import EjecutivoContainer from './components/EjecutivoContainer';
import BorderSeparator from '../sharedv2/BorderSeparator/BorderSeparator';
import IcoStrongArrowRight from '../../images/icons/strong-arrow-right.svg';
import routes from '../../config/routes';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import actions from '../main/reducer';

interface StateProps {
  empresa: Dto.Portal.Empresa;
  rows: State.IDashboardInfo;
}

interface IProps extends WithStyles<typeof DashboardStyle>, StateProps, RouteComponentProps {
  dispatch: any;
  notificacion: (exito: boolean, mensaje: string) => void;
}

interface IState {
  ejecutivos: any[];
  loading: boolean;
  showCarousel: string;
  cantidadOperacionesDisponibles: number | undefined;
  deplegarOpcionSuscripcionMiPyme: boolean;
  suscripcionYaConfigurada: boolean;
  certificadoYaConfigurado: boolean;
}

class Factoring extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      ejecutivos: [],
      loading: true,
      showCarousel: 'none',
      cantidadOperacionesDisponibles: undefined,
      deplegarOpcionSuscripcionMiPyme: false,
      suscripcionYaConfigurada: false,
      certificadoYaConfigurado: false
    };
    // this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount = async () => {
    this.props.dispatch(FilterResumenCarteraFactoring({}));
    await Promise.all([this.loadEjecutivos(), this.cargarCantidadOperacionesDisponibles(), this.obtenerFacturador()]);

    const sincs = (this.props.empresa.sincronizacion || []).filter(d => d.tipo === 1);
    const sincDte = sincs.length === 1 ? sincs[0] : undefined;

    const suscripcionYaConfigurada = sincDte !== undefined && sincDte.estado === 1;
    const certificadoYaConfigurado = this.props.empresa.certificados.length > 0;

    this.setState({ suscripcionYaConfigurada: suscripcionYaConfigurada, certificadoYaConfigurado: certificadoYaConfigurado });
  };

  cargarCantidadOperacionesDisponibles = async () => {
    const resp = await factorxApi.getCantidadOperacionesDisponibles();
    if (resp && resp.exito) {
      this.setState({ cantidadOperacionesDisponibles: resp.cantidad });
    }
  };

  obtenerFacturador = async () => {
    const resp = await factorxApi.getFacturador();
    if (resp && resp.exito) {
      this.setState({ deplegarOpcionSuscripcionMiPyme: resp.codigoFacturador.toLowerCase() === 'mipyme' });
    }
  };

  loadEjecutivos = async () => {
    const resp = await cargarEjecutivos();
    if (resp)
      this.setState({
        ejecutivos: (resp.data || []).reverse(),
        loading: false,
        showCarousel: ''
      });
    else this.setState({ loading: false, showCarousel: 'none' });
  };

  goToNuevaOperacion = () => {
    this.props.history.push(routes.factoring.operaciones.solicitud);
  };

  goToOperacionesEnProceso = () => {
    this.props.history.push(routes.factoring.operaciones.proceso);
  };

  goToDisponibles = () => {
    this.props.history.push(routes.factoring.operaciones.proceso);
  };

  render() {
    const { rows, classes } = this.props;
    const { ejecutivos, loading } = this.state;
    const ejecutivo = ejecutivos && ejecutivos.length > 0 ? ejecutivos[0] : undefined;

    return (
      <>
        {loading ? (
          <LoaderComp />
        ) : (
          <React.Fragment>
            <CardContent>
              <Grid container spacing={16}>
                <Grid item xs={12} md={6}>
                  <AgilizaOperaciones deplegarOpcionSuscripcionMiPyme={this.state.deplegarOpcionSuscripcionMiPyme}
                                      suscripcionYaConfigurada={this.state.suscripcionYaConfigurada}
                                      certificadoYaConfigurado={this.state.certificadoYaConfigurado}
                                      notificacion={this.props.notificacion}
                  />
                  <Grid container style={{ marginTop: '16px' }}>
                    <Grid item xs={12} md={7}>
                      <div className={classes.carteraContainer}>
                        <div className={classes.carteraMontoContainer}>
                          <div className={classes.carteraTitle}>Cartera vigente</div>
                          <div className={classes.carteraMonto}>
                            $ {rows && rows.carteraTotal ? formatNumber(rows.carteraTotal) : 0}
                            <img src={IcoStrongArrowRight} className={classes.arrowFowardIcon} />
                          </div>
                        </div>

                        <div className={classes.carteraSubTitle}>Vigente</div>
                        <div className={classes.carteraTitleVigente}>
                          $ {rows && rows.vigente ? formatNumber(rows.vigente) : 0}
                        </div>
                        <div className={classes.carteraSubTitle}>Moroso</div>
                        <div className={classes.carteraTitleMoroso}>
                          $ {rows && rows.morosa ? formatNumber(rows.morosa) : 0}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <div className={classes.containerLitleBoxOperacion}>
                        <Button
                          classes={{ root: classes.itemBoxOperacion, label: classes.itemBoxLabel }}
                          onClick={this.goToNuevaOperacion}
                        >
                          <img src={CartImg} width='21' alt='' /> Ingresa tu operación
                        </Button>
                        <Button
                          classes={{ root: classes.itemBoxOperacion, label: classes.itemBoxLabel }}
                          onClick={this.goToOperacionesEnProceso}
                        >
                          <img src={SearchImg} width='21' alt='' /> Sigue tus Operaciones
                        </Button>
                        <Button
                          classes={{ root: classes.itemBoxOperacion, label: classes.itemBoxLabel }}
                          onClick={this.goToDisponibles}
                        >
                          <img src={HandsImg} width='21' alt='' /> Cede tus facturas
                        </Button>
                        {this.state.cantidadOperacionesDisponibles !== undefined &&
                        <div className={classes.opDisponibles}>
                          <img src={IcoGreenWarning}
                               alt='' /> {this.state.cantidadOperacionesDisponibles} {this.state.cantidadOperacionesDisponibles > 1 ? 'operaciones disponibles' : 'operación disponible'}
                        </div>
                        }
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.leftContent}>
                    <BorderSeparator />
                    <div className={classes.agilizarColumn}>
                      <ComparteDatos />
                      {ejecutivo &&
                      <EjecutivoContainer
                        titulo={'Datos ejecutivo'}
                        nombre={ejecutivo.nombreCompleto}
                        email={ejecutivo.correoElectronico}
                        telefono={ejecutivo.telefonoOficina}
                        direccion={''}
                      />
                      }
                    </div>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </React.Fragment>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  empresa: state.factorx.empresa,
  rows: state.dashboard.rows
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  dispatch: (action: any) => dispatch(action),
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(DashboardStyle)(connect(mapStateToProps, mapDispatchToProps)(withRouter(Factoring)));

// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { obtenerCarteraFactoring, obtenerExcelCarteraFactoring, obtenerPdfCarteraFactoring, setEventTrackingExcel, setTrackingSidebar } from './actions';
import xlsx from 'xlsx';
import moment from 'moment';
import 'moment/locale/es';
import ReactTable from 'react-table';
import Moment from 'moment/moment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';
import NavPills from '../../shared/NavPills/NavPills';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../shared/RangeCalendar/RangeCalendar';
import PaginationBase from '../../shared/Pagination/PaginationBase';
import Loader from '../../shared/Loader/Loader';
import { onChangeOnlyNumericHelper, onChangeRangNumericHelper, validarRangoNroDoc } from '../../../helpers/ValidateNumeric';
import { formatNumber } from '../../../helpers/FormatNumber';
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

moment.locale('es');

interface IRowRecord<T> {
  value: any;
  original: T;
}

interface StateProps {
  rows: Dto.CarteraRow[][];
  paginacion: State.Paginacion[];
  total: Dto.CarteraTotal[];
  loading: boolean[];
}

interface DispatchProps {
  obtenerCarteraFactoring: (filter: Filters.CarteraFactoring) => Promise<void>;
  obtenerPdfCarteraFactoring: (filter: Filters.CarteraFactoring) => Promise<void>;
  obtenerExcelCarteraFactoring: (filter: Filters.CarteraFactoring) => Promise<Response.Cartera>;
  setEventTrackingExcel: (filter: string) => Promise<void>;
  setTrackingSidebar: (filter: string) => Promise<void>;
}

interface ITableProps {
  columns: any[];
  totalItemsPerPage: number;
  totalItems: number;
  nameTable: string;
  pages: number;
  loading: boolean;
  noTable: number;
  nomDataTable: string;
  totalTable: string;
  paginationTable: string;
  data: Dto.CarteraRow[];
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof HistoricoStyle> {
}

interface IState {
  activeTab: number;
  openCalendar: boolean;
  anchorElCalendar: any;
  expanded: boolean;
  numDocumentosFrom: string;
  numDocumentosTo: string;
  calendarValue: {
    desde: string
    hasta: string
  };
  estadoF: string;
  numeOperacion: string;
  nombreDeudor: string;
  estadoDTE: string;
  fechaVencimientoDesde: string;
  fechaVencimientoHasta: string;
  tipoDocumento: string;
}

class Cartera extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openCalendar: false,
      anchorElCalendar: null,
      expanded: true,
      activeTab: 1,
      numDocumentosFrom: '',
      numDocumentosTo: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      fechaVencimientoDesde: moment(new Date()).format('DD/MM/YYYY'),
      fechaVencimientoHasta: moment(new Date()).format('YYYY-MM-DD'),
      estadoF: '',
      estadoDTE: '',
      numeOperacion: '',
      tipoDocumento: '',
      nombreDeudor: ''
    };
    this.fetchData = this.fetchData.bind(this);
  }

  onChangeTab = (active: number) => this.setState({ activeTab: active });

  getFilters = (page: number, pageSize: number, tipoDocumento: any, sorted: any): Filters.CarteraFactoring => {
    return {
      numeroDocumentoDesde: this.state.numDocumentosFrom,
      numeroDocumentoHasta: this.state.numDocumentosTo,
      fechaVencimientoDesde: (this.state.calendarValue.desde) ? moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      fechaVencimientoHasta: (this.state.calendarValue.hasta) ? moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      estadoDocumento: this.state.estadoF,
      numeroOperacion: this.state.numeOperacion,
      deudor: this.state.nombreDeudor,
      estadoDte: this.state.estadoDTE,
      numeroPagina: page,
      tamanoPagina: pageSize,
      tipoDocumento: tipoDocumento,
      ascending: sorted && sorted[0] && sorted[0].desc ? true : sorted && sorted[0] ? false : null,
      orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : null,
      isExportPdf: null
    };
  };

  getSortIdColumn(column: string): number | null {
    let _id = null;
    switch (column) {
      case 'CodFamilia':
        _id = 1;
        break;
      case 'CodTipoDocto':
        _id = 2;
        break;
      case 'TipoDocumento':
        _id = 3;
        break;
      case 'numeroDocumento':
        _id = 4;
        break;
      case 'montoDocumento':
        _id = 5;
        break;
      case 'Monto Financiado':
        _id = 6;
        break;
      case 'Retencion':
        _id = 7;
        break;
      case 'abonos':
        _id = 8;
        break;
      case 'saldos':
        _id = 9;
        break;
      case 'CodEstado':
        _id = 10;
        break;
      case 'estadoDocumento':
        _id = 11;
        break;
      case 'fechaVencimiento':
        _id = 12;
        break;
      case 'numeroOperacion':
        _id = 13;
        break;
      case 'CodEstadoDTE':
        _id = 14;
        break;
      case 'estadoDte':
        _id = 15;
        break;
      case 'CodGestionCob':
        _id = 16;
        break;
      case 'GestionCob':
        _id = 17;
        break;
      case 'FechaOperacion':
        _id = 18;
        break;
      case 'rutDeudor':
        _id = 19;
        break;
      case 'nombreDeudor':
        _id = 20;
        break;
    }
    return _id;
  }

  fetchData = (state: any, tipoDocumento: number, dataProp: string, dataTotal: string, dataPagination: string) => {
    if (this.props.loading[tipoDocumento]) return;
    this.props.obtenerCarteraFactoring(this.getFilters(state.page, state.pageSize, tipoDocumento, state.sorted)).then(() => {
    });
  };

  buttonGetPDF = async () => {
    const { activeTab } = this.state;
    let tipoDocumento: number = activeTab;

    const paginationPage = this.props.paginacion[tipoDocumento].page;
    const paginationPageSize = this.props.paginacion[tipoDocumento].pageSize;

    await this.props.obtenerPdfCarteraFactoring(this.getFilters(paginationPage, paginationPageSize, tipoDocumento, null));
  };

  buttonGetExcel = async () => {
    const { activeTab } = this.state;
    let tipoDocumento: number = activeTab;

    const paginationTotalItems = this.props.paginacion[activeTab].count;
    const data = await this.props.obtenerExcelCarteraFactoring(this.getFilters(0, paginationTotalItems, tipoDocumento, null));
    this.exportExcel(data, tipoDocumento);
    await this.props.setEventTrackingExcel('carteraExcel');
  };

  exportExcel = (cartera: Response.Cartera, tipoDocumento: number) => {
    let workbook = xlsx.utils.book_new();
    let formatted: any[] = [];
    if (cartera.data && cartera.data.length) {
      formatted = cartera.data.map(d => {
        let formattedOrder = {
          'DEUDOR': d.nombreDeudor,
          'RUT DEUDOR': d.rutDeudor,
          'N° DOCUMENTO': d.numeroDocumento,
          'MONTO DOCUMENTO': formatNumber(d.montoDocumento),
          'ABONOS': formatNumber(d.abonos),
          'SALDOS': formatNumber(d.saldos),
          'ESTADO DOCUMENTO': d.estadoDocumento,
          'FECHA VENCIMIENTO': Moment(d.fechaVencimiento).format('DD/MM/YYYY'),
          'N° OPERACIÓN': d.numeroOperacion,
          'ESTADO DTE': d.estadoDte.substr(0, 30)
        };
        return formattedOrder;
      });
    }
    let orderSheet = xlsx.utils.json_to_sheet(formatted);

    orderSheet['!cols'] = [
      { wpx: 250 }, //column 1
      { wpx: 110 }, //column 2
      { wpx: 100 }, //column 3
      { wpx: 130 }, //Monto documento
      { wpx: 88 }, //abonos
      { wpx: 88 }, //saldos
      { wpx: 165 }, //estado documento
      { wpx: 130 }, //fecha vencimiento
      { wpx: 100 }, //N° operación
      { wpx: 200 } //Estado DTE
    ];

    if (tipoDocumento === 1)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'FACTURAS');
    else if (tipoDocumento === 2)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'CHEQUES');
    else if (tipoDocumento === 3)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'LETRAS');
    else if (tipoDocumento === 4)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'PAGARES');

    xlsx.writeFile(workbook, `Cartera - ${Moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  handleExpandClick = () => {
    this.setState((state) => ({ ...state, expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter: any, row: any, column: any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  handleFiltrar = async () => {
    const facturaTable = this.refs.facturaTable as any;
    const chequeTable = this.refs.chequeTable as any;
    const letraTable = this.refs.letraTable as any;
    const pagareTable = this.refs.pagareTable as any;
    if (facturaTable) {
      facturaTable.state.page = 0;
      facturaTable.fireFetchData();
    }
    if (chequeTable) {
      chequeTable.state.page = 0;
      chequeTable.fireFetchData();
    }
    if (letraTable) {
      letraTable.state.page = 0;
      letraTable.fireFetchData();
    }
    if (pagareTable) {
      pagareTable.state.page = 0;
      pagareTable.fireFetchData();
    }

    if (!this.checkInputFilter()) {
      await this.props.setTrackingSidebar('carteraBuscar');
    }

  };

  // @ts-ignore
  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => this.setState({ [e.target.name]: e.target.value });

  // @ts-ignore
  onChangeRangNumeric = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ [e.target.name]: onChangeRangNumericHelper(e) });

  // @ts-ignore
  onChangeOnlyNumeric = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ [e.target.name]: onChangeOnlyNumericHelper(e) });

  // @ts-ignore
  onBlur = e => this.setState({ [e.target.name]: e.target.value });

  handleStatesCalendar = (params: any) => this.setState(params);

  cleanFilters = () => {
    this.setState({
      numDocumentosFrom: '',
      numDocumentosTo: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      estadoF: '',
      numeOperacion: '',
      nombreDeudor: ''
    }, async () => {
      await this.handleFiltrar();
    });
  };

  checkInputFilter = () =>
    !(this.state.numDocumentosFrom.length > 0 ||
      this.state.numDocumentosTo.length > 0 ||
      this.state.calendarValue.desde ||
      this.state.calendarValue.hasta ||
      this.state.estadoF ||
      this.state.numeOperacion.length > 0 ||
      this.state.nombreDeudor.length > 4);

  componentDidMount() {
    this.cleanFilters();
  }

  tableProps = (params: ITableProps) => ({
    columns: params.columns,
    defaultPageSize: 10,
    minRows: params.totalItemsPerPage && params.totalItemsPerPage <= params.totalItems ? params.totalItemsPerPage : 10,
    showPaginationBottom: params.totalItemsPerPage !== params.totalItems,
    PaginationComponent: PaginationBase,
    previousText: 'Anterior',
    nextText: 'Siguiente',
    noDataText: 'Sin información',
    pageText: 'Pagina',
    ofText: 'de',
    rowsText: 'filas',
    resizable: false,
    defaultFilterMethod: this.defaultFilterMethod,
    filterable: true,
    showFilters: false,
    loadingText: <Loader loadTable />,
    manual: true,
    data: params.data,
    ref: params.nameTable,
    onFetchData: (state: any) => this.fetchData(state, params.noTable, params.nomDataTable, params.totalTable, params.paginationTable),
    pages: params.pages,
    totalItems: params.totalItems,
    loading: params.loading
  });

  columnsTable = (total: Dto.CarteraTotal | undefined) => [
    {
      Header: 'DEUDOR',
      accessor: 'nombreDeudor',
      headerClassName: this.props.classes.HeaderTable,
      width: 150,
      className: this.props.classes.TDTable,
      // Cell: (props) => <div title="asd" style={{ textTransform: "capitalize" }}><div>{props.value.toLowerCase()}</div></div>,
      Footer: (
        <span className={this.props.classes.TDTableTotalFooter}>
            <strong>TOTAL</strong>
          </span>
      )
    },
    {
      Header: 'RUT DEUDOR',
      accessor: 'rutDeudor',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 70,
      id: 'rut',
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableNoOperacion + ' ' + this.props.classes.TDTableCenterAlign
    },
    {
      Header: 'N° DOCUMENTO',
      accessor: 'numeroDocumento',
      id: 'numeroDocumento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 70,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign
    },
    {
      Header: 'MONTO DOCUMENTO',
      accessor: 'montoDocumento',
      id: 'montoDocumento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableRightAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableRightAlign,
      width: 100,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => formatNumber(props.value),
      Footer: (
        <span>
            <strong>{total && total.montoDocumento ? formatNumber(total.montoDocumento) : 0}</strong>
          </span>
      )
    },
    {
      Header: 'ABONOS',
      accessor: 'abonos',
      id: 'abonos',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableRightAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableRightAlign,
      width: 100,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => formatNumber(props.value),
      Footer: (
        <span>
            <strong>{total && total.abonos ? formatNumber(total.abonos) : 0}</strong>
          </span>
      )
    },
    {
      Header: 'SALDO',
      accessor: 'saldos',
      id: 'saldos',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableRightAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableRightAlign,
      width: 100,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => formatNumber(props.value),
      Footer: (
        <span>
            <strong>{total && total.saldos ? formatNumber(total.saldos) : 0}</strong>
          </span>
      )
    },
    {
      Header: 'ESTADO DOCUMENTO',
      accessor: 'estadoDocumento',
      id: 'estadoDocumento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 80
    },
    {
      Header: 'FECHA VENCIMIENTO',
      accessor: 'fechaVencimiento',
      id: 'fechaVencimiento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 80,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => Moment(props.value).format('DD/MM/YYYY')
    },
    {
      Header: 'N° OPERACIÓN',
      accessor: 'numeroOperacion',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      id: 'numeroOperacion',
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableNoOperacion + ' ' + this.props.classes.TDTableCenterAlign,
      width: 80
    },
    {
      Header: 'ESTADO DTE',
      accessor: 'estadoDte',
      id: 'estadoDte',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 220,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => <div
        className={this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign}>{props.value.substr(0, 30)}</div>
    }
  ];

  listTabs = (rows: Dto.CarteraRow[][]) => [
    {
      tabButton: 'FACTURAS',
      hidden: rows[1].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'facturaTable',
            columns: this.columnsTable(this.props.total[1]),
            loading: this.props.loading[1],
            data: rows[1],
            pages: this.props.paginacion[1].maxPages,
            totalItems: this.props.paginacion[1].count,
            totalItemsPerPage: this.props.paginacion[1].pageSize,
            noTable: 1,
            nomDataTable: 'dataFactura',
            totalTable: 'totalFactura',
            paginationTable: 'paginationFactura'
          })}
        />
      )
    },
    {
      tabButton: 'CHEQUES',
      hidden: rows[2].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'chequeTable',
            columns: this.columnsTable(this.props.total[2]),
            loading: this.props.loading[2],
            data: rows[2],
            pages: this.props.paginacion[2].maxPages,
            totalItems: this.props.paginacion[2].count,
            totalItemsPerPage: this.props.paginacion[2].pageSize,
            noTable: 2,
            nomDataTable: 'dataCheque',
            totalTable: 'totalCheque',
            paginationTable: 'paginationCheque'
          })}
        />
      )
    }
    ,
    {
      tabButton: 'LETRAS',
      hidden: rows[3].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'letraTable',
            columns: this.columnsTable(this.props.total[3]),
            loading: this.props.loading[3],
            data: rows[3],
            pages: this.props.paginacion[3].maxPages,
            totalItems: this.props.paginacion[3].count,
            totalItemsPerPage: this.props.paginacion[3].pageSize,
            noTable: 3,
            nomDataTable: 'dataLetra',
            totalTable: 'totalLetra',
            paginationTable: 'paginationLetra'
          })}
        />
      )
    },
    {
      tabButton: 'PAGARÉS',
      hidden: rows[4].length !== 0,
      tabContent: (<ReactTable
        {...this.tableProps({
          nameTable: 'pagareTable',
          columns: this.columnsTable(this.props.total[4]),
          loading: this.props.loading[4],
          data: rows[4],
          pages: this.props.paginacion[4].maxPages,
          totalItems: this.props.paginacion[4].count,
          totalItemsPerPage: this.props.paginacion[4].pageSize,
          noTable: 4,
          nomDataTable: 'dataPagare',
          totalTable: 'totalPagare',
          paginationTable: 'paginationPagare'
        })}
      />)
    }
  ];

  render() {
    const { classes, rows } = this.props;
    const { openCalendar, anchorElCalendar, calendarValue } = this.state;

    // console.log(this.props.paginacion[1]);

    const tabs = this.listTabs(rows);
    let activa = tabs.findIndex(d => d.hidden);
    activa = activa === -1 ? 0 : activa;

    return (
      <div>
        <div>
          <Breadcrumb category='Usted se encuentra en: Factoring / Cartera' textAlign='right' />
          <Typography variant='h5' gutterBottom className={classes.sectionTitle}>
            Cartera
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement='top'>
                      <IconButton
                        className={classnames(classes.expand, {
                          // [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label='Show more'>
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h5' className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color='primary' variant='outlined' onClick={() => this.cleanFilters()} style={{ marginLeft: 14 }}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm} autoComplete='off'>
                    <GridContainer>
                      <GridItem xs={2}>
                        <FormControl fullWidth>
                          <FormLabel
                            style={{
                              fontSize: 11,
                              top: 0,
                              left: 0,
                              position: 'absolute'
                            }}
                          >N&deg; Documentos</FormLabel>
                          <GridContainer style={{ marginTop: 8 }}>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id='numDocumentosFrom'
                                name='numDocumentosFrom'
                                placeholder='Desde'
                                value={this.state.numDocumentosFrom}
                                onChange={this.onChangeRangNumeric}
                                onBlur={validarRangoNroDoc}
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id='numDocumentosTo'
                                name='numDocumentosTo'
                                value={this.state.numDocumentosTo}
                                placeholder='Hasta'
                                onChange={this.onChangeRangNumeric}
                                onBlur={validarRangoNroDoc}
                              />
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id='fechaVencimiento'
                            name='fechaVencimiento'
                            label='Fecha Vencimiento'
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}>
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor='estadoF'>Estado documento</InputLabel>
                          <Select
                            className={classes.filterFormSelectField}
                            value={this.state.estadoF}
                            onChange={this.onChange}
                            displayEmpty
                            input={
                              <Input name='estadoF' id='estadoF' />
                            }
                            name='estadoF'>
                            <MenuItem value=''>
                              Todos
                            </MenuItem>
                            <MenuItem value={'Vigente'}>Vigente</MenuItem>
                            <MenuItem value={'Moroso'}>Moroso</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id='numeOperacion'
                            name='numeOperacion'
                            value={this.state.numeOperacion}
                            label='N&deg; Operación'
                            placeholder='000000'
                            //onChange={this.onChange}
                            onChange={this.onChangeOnlyNumeric}
                            //onBlur={validarRangoNroDoc}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}
                      >
                        <FormControl fullWidth>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id='nombreDeudor'
                            name='nombreDeudor'
                            value={this.state.nombreDeudor}
                            label='Deudor'
                            placeholder='Nombre'
                            onChange={this.onChange}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer} xs={2}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={this.handleFiltrar}
                            disabled={this.checkInputFilter()}>
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ position: 'relative' }}>
            {(rows[1].length + rows[2].length + rows[3].length + rows[4].length) > 0 &&
              <GridItem className={classes.gridPDFExcelButtons}>
                <Tooltip title='Descargar PDF' placement='top'>
                  <IconButton onClick={() => this.buttonGetPDF()}>
                    <SVGIcons id='CartIconDescargarPDF' icon='pdf' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Descargar Planilla Excel' placement='top'>
                  <IconButton onClick={() => this.buttonGetExcel()}>
                    <SVGIcons id='CartIconDescargarExcel' icon='excel' />
                  </IconButton>
                </Tooltip>
              </GridItem>}
            <NavPills
              color='info'
              onChangeTab={active => this.setState({ activeTab: active })}
              tabs={tabs} />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  obtenerCarteraFactoring: (payload: Filters.CarteraFactoring) => dispatch(obtenerCarteraFactoring(payload)),
  obtenerPdfCarteraFactoring: (payload: Filters.CarteraFactoring) => dispatch(obtenerPdfCarteraFactoring(payload)),
  obtenerExcelCarteraFactoring: (payload: Filters.CarteraFactoring) => dispatch(obtenerExcelCarteraFactoring(payload)),
  setEventTrackingExcel: (payload: string) => dispatch(setEventTrackingExcel(payload)),
  setTrackingSidebar: (payload: string) => dispatch(setTrackingSidebar(payload))
});


const mapStateToProps = (state: AppState): StateProps => ({
  rows: state.cartera.rows,
  paginacion: state.cartera.paginacion,
  total: state.cartera.total,
  loading: state.cartera.loading
});


export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(Cartera));

// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

// componentes material ui
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';

// componentes custom
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';

// @material-ui/icons
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';
import { obtenerBienesVendor } from '../actions';
import TableContainer from '../../sharedv2/Table/TableContainer';
import { onChangeOnlyNumericHelper } from '../../../helpers/ValidateNumeric';
import { RouteComponentProps } from 'react-router-dom';
import FiltersHeader from '../../sharedv2/FiltersHeader/FiltersHeader';
import Loader from '../../shared/Loader/Loader';
import { esEjecutivoAsistente } from '../../../helpers/UserHelper';

const style = {
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#82BC00',
    textDecoration: 'none'
  }
};

interface RouteParams {
  id : string
}

interface StateProps {
  bienes : Dto.Vendor.BienesRow[],
  paginacion : State.Paginacion,
  esEjecutivo : boolean
}

interface DispatchProps {
  obtenerBienesVendor : (payload : Request.Vendor.Bienes) => Promise<void>
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof HistoricoStyle>, RouteComponentProps<RouteParams> {
}

interface IState extends State.ICalendar {
  expanded : boolean,
  openCalendar : boolean,
  anchorElCalendar? : any,
  loading : boolean,
  contrato : string,
  tipoBien : string,
  marca : string,
}

class BienesVendor extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = {
      expanded: true,
      openCalendar: false,
      anchorElCalendar: null,
      loading: false,
      contrato: '',
      tipoBien: '',
      marca: '',
      calendarValue: {
        desde: '',
        hasta: ''
      }
    };
  }

  async componentDidMount() {
    this.setState((state) => ({ ...state, contrato: (this.props.match.params.id || '') }), async () => {
      await this.obtenerBienes(0, 10);
    });
  }

  // @ts-ignore
  onChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => this.setState({ [e.target.name]: e.target.value });

  // @ts-ignore
  onChangeOnlyNumeric = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ [e.target.name]: onChangeOnlyNumericHelper(e) });

  obtenerBienes = (pagina : number, tamano : number) => {
    this.setState({ loading: true });
    return this.props.obtenerBienesVendor(this.getFilters(pagina, tamano))
               .then(() => {
                 this.setState({ loading: false });
               });
  };

  search = async () => {
    await this.obtenerBienes(0, 10);
  };

  getFilters = (pagina : number, tamano : number) : Request.Vendor.Bienes => {
    return {
      numeroPagina: pagina + 1,
      tamanoPagina: tamano,
      contrato: Number(this.state.contrato),
      tipoBien: this.state.tipoBien,
      marca: this.state.marca,
      estado: ''
    };
  };

  cleanFilters = () => {
    this.setState({
      contrato: '',
      tipoBien: '',
      marca: '',
      calendarValue: {
        desde: '',
        hasta: ''
      }
    }, async () => {
      await this.search();
    });
  };

  toggleExpand = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter : any, row : any, column : any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  handleStatesCalendar = (calendar : State.ICalendar) => {
    this.setState(calendar);
  };

  tableInfo : TC.Fila[] = [
    {
      column: 'N° CONTRATO',
      relation: 'numeroContrato'
    },
    {
      column: 'TIPO DE BIEN',
      relation: 'tipoBien'
    },
    // {
    //   column: 'MARCA',
    //   relation: 'marca'
    // },
    // {
    //   column: 'MODELO',
    //   relation: 'modelo'
    // },
    // {
    //   column: 'AÑO',
    //   relation: 'agno'
    // },
    {
      column: 'CANTIDAD',
      relation: 'cantidad'
    },
    // {
    //   column: 'SEGURO',
    //   relation: 'seguro'
    //   // Cell: (row : IRowRecord<Dto.VendorBienesRow>) => {
    //   //   return <div><SearchIcon className={classes.TDTableCenterIcon} /></div>;
    //   // }
    // }
  ];

  render() {
    const { classes, paginacion, bienes, esEjecutivo } = this.props;
    return (
      this.state.loading ?
        <Loader /> :
        <div>
          <div>
            <Breadcrumb category="Usted se encuentra en: Leasing Tecnológico / Bienes" textAlign="right" />
            <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
              Bienes
            </Typography>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <FiltersHeader cleanFilters={this.cleanFilters} expanded={this.state.expanded} toggleExpand={this.toggleExpand} />
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                  <CardContent className={classes.filterCardContent}>
                    <form className={classes.filterForm}>
                      <GridContainer>
                        <GridItem xs={2}>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id="contrato"
                            name="contrato"
                            label="Contrato"
                            placeholder="Contrato"
                            value={this.state.contrato}
                            onChange={this.onChangeOnlyNumeric}
                          />
                        </GridItem>
                        <GridItem xs={3}>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id="tipoBien"
                            name="tipoBien"
                            label="Tipo de bien"
                            placeholder="Tipo de bien"
                            value={this.state.tipoBien}
                            onChange={this.onChange}
                          />
                        </GridItem>
                        <GridItem xs={2}>
                          <FormControl fullWidth>
                            <TextField
                              className={classes.filterFormtextField}
                              InputLabelProps={{ shrink: true }}
                              id="marca"
                              name="marca"
                              value={this.state.marca}
                              label="Marca"
                              placeholder="Marca"
                              onChange={this.onChange}
                            />
                          </FormControl>
                        </GridItem>
                        <GridItem className={classes.filterFormButtonContainer} xs={2}>
                          <FormControl className={classes.filterFormButtonBody}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.search}>
                              Buscar
                            </Button>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardContent>
                </Collapse>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {/*<GridItem align="right" className={classes.gridPDFExcelButtonsSinPestaña}>*/}
              {/*  <Tooltip title="Descargar PDF" placement="top">*/}
              {/*    <IconButton>*/}
              {/*      <SVGIcons icon="pdf" />*/}
              {/*    </IconButton>*/}
              {/*  </Tooltip>*/}
              {/*  <Tooltip title="Descargar Planilla Excel" placement="top">*/}
              {/*    <IconButton>*/}
              {/*      <SVGIcons icon="excel" />*/}
              {/*    </IconButton>*/}
              {/*  </Tooltip>*/}
              {/*</GridItem>*/}

              <TableContainer classes={classes}
                              data={bienes}
                              pagination={paginacion}
                              tableInfo={this.tableInfo}
                              obtenerDatos={this.obtenerBienes}
              />
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

const mapStateToProps = (state : AppState) : StateProps => ({
  bienes: state.vendor.bienes,
  paginacion: state.vendor.bienesPaginacion,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch : ThunkDispatch<any, any, AnyAction>) : DispatchProps => ({
  obtenerBienesVendor: (payload : Request.Vendor.Bienes) => dispatch(obtenerBienesVendor(payload))
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(BienesVendor));

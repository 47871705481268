import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '../CustomButtons/Button';
import headerLinksStyle from '../../../styles/jss/components/headerLinksStyle';
import { ActionLogout } from '../../general/login/actions';
import { AppState } from '../../../config/rootReducer';
import { WithStyles } from '@material-ui/core';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import IcoAtencionCliente from '../../../images/icons/ico-atencion-cliente-green.svg';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import routes from '../../../config/routes';

interface IProps extends WithStyles<typeof headerLinksStyle>, RouteComponentProps {
  rtlActive: boolean,
  dispatch: any
}

interface IState {
  open: boolean,
  buttonClicked: Element | undefined,
}

class HeaderLinks extends React.Component<IProps, IState> {
  state = {
    open: false,
    buttonClicked: undefined
  };

  logOutClick = () => {
    this.props.dispatch(ActionLogout());
  };

  render() {
    const { classes, rtlActive } = this.props;

    return (
      <div>
        <Button
          onClick={() => {
            this.props.history.push(routes.user.atencionCliente);
          }}
          classes={{
            // root: classes.btnAtencionCliente,
            // label: classes.btnAtencionClienteSpan
          }}
        >
          <img src={IcoAtencionCliente} />
          ATENCIÓN AL CLIENTE
        </Button>
        {/* <Button
          color="transparent"
          simple
          aria-label="Cerrar Sesión"
          className={
            rtlActive ? classes.buttonLinkRTL : classes.buttonLinkSalir
          }
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
          onClick={this.logOutClick}
        >
          <ExitToApp
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <span className={classes.linkTextSalir}>{"Cerrar Sesión"}</span>
        </Button> */}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({
  dispatch: (action: AnyAction) => dispatch(action)
});

export default withStyles(headerLinksStyle)(connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderLinks)));


import React, { FC } from 'react';
import cx from 'classnames';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { Button, Theme } from '@material-ui/core';
import scssTool from '../../../styles/scssTool';
import FactotalTheme from '../../../styles/FactotalTheme';
import { useHistory } from 'react-router';
import routes from '../../../config/routes';

const styleMiTableroButton = (theme : Theme) => ({
  goToMiTablero: {
    background: '#FFFFFF',
    borderRadius: '5px',
    width: '100%',
    height: '41px',
    ...scssTool.flex('center', 'row', 'center'),
    '&:hover': {
      background: '#FFFFFF'
    },
    '&.mini': {
      width: '50px',
      minWidth: '50px'
    }
  },
  gotToSpanTablero: {
    ...scssTool.font(14, 'bold', 25),
    color: FactotalTheme.palette.primary.main,
    '&.hide': {
      display: 'none' as 'none',
      transition: 'all 0.5s ease-in-out'
    }
  },
  goToTableroIcon: {
    color: FactotalTheme.palette.primary.main,
    marginRight: '8px',
    transition: 'all 0.3s ease-in-out',
    '&.noMargen': {
      transition: 'all 0.3s ease-in-out',
      marginRight: '0px'
    }
  }
});

interface IMiTableroButton extends WithStyles<typeof styleMiTableroButton> {
  miniActive : boolean;
}

const MiTableroButton : FC<IMiTableroButton> = (props) => {
  const { classes, miniActive } = props;
  const history = useHistory();

  const goTo = () => {
    history.push(routes.miTablero);
  };
  return (
    <Button classes={{ root: cx(classes.goToMiTablero, { mini: miniActive }) }} onClick={goTo}>
      <DashboardIcon
        className={cx(classes.goToTableroIcon, {
          noMargen: miniActive
        })}
      />
      <span
        className={cx(classes.gotToSpanTablero, {
          hide: miniActive
        })}
      >
        Mi tablero
      </span>
    </Button>
  );
};

export default withStyles(styleMiTableroButton)(MiTableroButton);

//External
import React, {KeyboardEvent, MouseEvent} from 'react';
// @ts-ignore
import SweetAlert from 'react-bootstrap-sweetalert';
import { SubscribeClient} from './actions';
import ReCAPTCHA from 'react-google-recaptcha';
import {formatRut, validaRut} from '../../../helpers/RutHelper';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import { Autocomplete } from "@autocomplete/material-ui";
// componentes material ui
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CssBaseline from '@material-ui/core/CssBaseline';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import AccountBox from '@material-ui/icons/AccountBox';
import PersonPin from '@material-ui/icons/PersonPin';
import Lock from '@material-ui/icons/Lock';
import withStyles from '@material-ui/core/styles/withStyles' ;
import InputAdornment from '@material-ui/core/InputAdornment';
// componentes custom
import SubscriberStyle from '../../../styles/SubscriberStyle';
import bgImage from '../../../images/background.webp';
import logo from '../../../images/logo-factotal.svg';
import {CardContent, FormControl, FormHelperText, WithStyles} from '@material-ui/core';
import {Phone, TextFields} from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoaderComp from "../../sharedv2/Loader/LoaderComp";

interface IProps extends WithStyles<typeof SubscriberStyle>, RouteComponentProps {
    subscribe: (username: string, password: string) => void,
    handleSubscribe: (subscribeInfo: Response.Subscribe) => void
}
interface IState {
    SelectedComuna:Response.ComunaDto,
    SendRegistro:{
        rutEmpresa: string,
        nombreEmpresa: string,
        direccionEmpresa: string,
        emailEmpresa: string,
        comuna: string,
        telefono: string,
        claveSII: string,
        checkTermCond: boolean,
        checkCaptcha: boolean
    },
    comunas:[],
    captchaToken: boolean;
    RutEmpresa: string;
    RutEmpresaErrorMessage: string;
    isValidRut: boolean;
    ClaveSII: string;
    onSubmit:any;
    ClaveSIIErrorMessage: string;
    isValidClaveSII: boolean;
    isValidComuna:boolean;
    isValidTelefono:boolean;
    NameErrorMessage: string;
    isValidName: boolean;
    isValidEmailEmpresa: boolean;
    EmailEmpresaErrorMessage: string,
    isSelectCompany: boolean;
    isEnlaceRecuperar: boolean;
    codigoRecuperar: string;
    CheckTermCondErrorMessage: string;
    alert: JSX.Element | undefined;
    RutRecuperar: string;
    RutRecuperarErrorMessage: string;
    isValidRutRecuperar: boolean;
    correoRecuperacion: string;
    openModal: boolean;
    isRecuperar: boolean;
    isValidcodigoCrearClave: boolean;
    codigoCrearClaveErrorMessage: string;
    RutCrear: string;
    NombreEmpresa:string,
    codigoCrearClave: string;
    checkedG: boolean;
    buttonCheckboxTerms: boolean;
    isValidClaveNueva: boolean;
    claveNuevaErrorMessage: string;
    isValidClaveNuevaDos: boolean;
    claveNuevaDosErrorMessage: string;
    isNuevaContrasena: boolean;
    nuevaClave: string;
    isValidClave: boolean;
    nuevaClaveDos: string;
    isValidClaveDos: boolean;
    companyList: Dto.EnterpriseProfile[];
    companyListRender: Dto.EnterpriseProfile[];
    loginData: Comun.Dto.LoginUserInfo | undefined;
    load:boolean;
    expired: string;
    callback:string;
    recaptchaLoaded:boolean;
    ComunaErrorMessage:string,
    TelefonoErrorMessage:string,
    EmailEmpresa:string,
    Telefono:string,
    CheckTermCond: boolean,
    loading:boolean,
    Text:string
}

class Subscriber extends React.Component<IProps, IState> {
    setText=(e:any)=>{
        if(e!== undefined){
            console.log(e);
            this.setState({SelectedComuna:e, Text:e.cmuNombre});
        }

    }
    SelectedComuna:any ;
    ComunasArray:Response.ComunaDto[] =[
        {
            cmuCodigo: 1,
            cmuNombre: "Algarrobo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 1
        },
        {
            cmuCodigo: 2,
            cmuNombre: "Alhue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 2
        },
        {
            cmuCodigo: 3,
            cmuNombre: "Alto Del Carmen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 3
        },
        {
            cmuCodigo: 4,
            cmuNombre: "Ancud",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 4
        },
        {
            cmuCodigo: 5,
            cmuNombre: "Andacollo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 5
        },
        {
            cmuCodigo: 6,
            cmuNombre: "Angol",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 6
        },
        {
            cmuCodigo: 7,
            cmuNombre: "Antartica",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 7
        },
        {
            cmuCodigo: 8,
            cmuNombre: "Antofagasta",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 8
        },
        {
            cmuCodigo: 9,
            cmuNombre: "Antuco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 9
        },
        {
            cmuCodigo: 10,
            cmuNombre: "Arauco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 10
        },
        {
            cmuCodigo: 11,
            cmuNombre: "Arica",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 15,
            ciuCodigo: 11
        },
        {
            cmuCodigo: 12,
            cmuNombre: "Aysen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 12
        },
        {
            cmuCodigo: 13,
            cmuNombre: "Buin",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 13
        },
        {
            cmuCodigo: 14,
            cmuNombre: "Bulnes",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 14
        },
        {
            cmuCodigo: 15,
            cmuNombre: "Cabildo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 15
        },
        {
            cmuCodigo: 16,
            cmuNombre: "Cabrero",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 16
        },
        {
            cmuCodigo: 17,
            cmuNombre: "Calama",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 17
        },
        {
            cmuCodigo: 18,
            cmuNombre: "Calbuco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 18
        },
        {
            cmuCodigo: 19,
            cmuNombre: "Caldera",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 19
        },
        {
            cmuCodigo: 21,
            cmuNombre: "Calera de Tango",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 20
        },
        {
            cmuCodigo: 22,
            cmuNombre: "Calle Larga",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 21
        },
        {
            cmuCodigo: 23,
            cmuNombre: "Camarones",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 22
        },
        {
            cmuCodigo: 24,
            cmuNombre: "Camina",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 23
        },
        {
            cmuCodigo: 25,
            cmuNombre: "Canela",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 24
        },
        {
            cmuCodigo: 26,
            cmuNombre: "Cañete",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 25
        },
        {
            cmuCodigo: 27,
            cmuNombre: "Carahue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 26
        },
        {
            cmuCodigo: 28,
            cmuNombre: "Cartagena",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 243
        },
        {
            cmuCodigo: 29,
            cmuNombre: "Casablanca",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 27
        },
        {
            cmuCodigo: 30,
            cmuNombre: "Castro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 28
        },
        {
            cmuCodigo: 31,
            cmuNombre: "Catemu",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 29
        },
        {
            cmuCodigo: 32,
            cmuNombre: "Cauquenes",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 30
        },
        {
            cmuCodigo: 33,
            cmuNombre: "Cerrillos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 34,
            cmuNombre: "Cerro Navia",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 35,
            cmuNombre: "Chaiten",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 31
        },
        {
            cmuCodigo: 36,
            cmuNombre: "Chañaral",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 32
        },
        {
            cmuCodigo: 37,
            cmuNombre: "Chanco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 33
        },
        {
            cmuCodigo: 38,
            cmuNombre: "Chepica",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 34
        },
        {
            cmuCodigo: 39,
            cmuNombre: "Chiguayante",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 35
        },
        {
            cmuCodigo: 40,
            cmuNombre: "Chile Chico",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 36
        },
        {
            cmuCodigo: 41,
            cmuNombre: "Chillan",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 37
        },
        {
            cmuCodigo: 42,
            cmuNombre: "Chillan Viejo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 38
        },
        {
            cmuCodigo: 43,
            cmuNombre: "Chimbarongo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 39
        },
        {
            cmuCodigo: 44,
            cmuNombre: "Chonchi",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 40
        },
        {
            cmuCodigo: 45,
            cmuNombre: "Cisnes",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 41
        },
        {
            cmuCodigo: 46,
            cmuNombre: "Cobquecura",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 42
        },
        {
            cmuCodigo: 47,
            cmuNombre: "Cochamo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 43
        },
        {
            cmuCodigo: 48,
            cmuNombre: "Cochrane",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 44
        },
        {
            cmuCodigo: 49,
            cmuNombre: "Codegua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 45
        },
        {
            cmuCodigo: 50,
            cmuNombre: "Coelemu",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 46
        },
        {
            cmuCodigo: 51,
            cmuNombre: "Coihueco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 47
        },
        {
            cmuCodigo: 52,
            cmuNombre: "Coinco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 48
        },
        {
            cmuCodigo: 53,
            cmuNombre: "Colbun",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 49
        },
        {
            cmuCodigo: 54,
            cmuNombre: "Colchane",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 50
        },
        {
            cmuCodigo: 55,
            cmuNombre: "Colina",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 51
        },
        {
            cmuCodigo: 56,
            cmuNombre: "Collipulli",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 52
        },
        {
            cmuCodigo: 57,
            cmuNombre: "Coltauco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 53
        },
        {
            cmuCodigo: 58,
            cmuNombre: "Combarbala",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 54
        },
        {
            cmuCodigo: 59,
            cmuNombre: "Concepcion",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 56
        },
        {
            cmuCodigo: 60,
            cmuNombre: "Conchali",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 61,
            cmuNombre: "Con-Con",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 55
        },
        {
            cmuCodigo: 62,
            cmuNombre: "Constitucion",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 273
        },
        {
            cmuCodigo: 63,
            cmuNombre: "Contulmo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 57
        },
        {
            cmuCodigo: 64,
            cmuNombre: "Copiapo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 58
        },
        {
            cmuCodigo: 65,
            cmuNombre: "Coquimbo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 59
        },
        {
            cmuCodigo: 66,
            cmuNombre: "Coronel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 60
        },
        {
            cmuCodigo: 67,
            cmuNombre: "Corral",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 61
        },
        {
            cmuCodigo: 68,
            cmuNombre: "Coyhaique",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 62
        },
        {
            cmuCodigo: 69,
            cmuNombre: "Cunco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 63
        },
        {
            cmuCodigo: 70,
            cmuNombre: "Curacautin",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 64
        },
        {
            cmuCodigo: 71,
            cmuNombre: "Curacavi",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 65
        },
        {
            cmuCodigo: 72,
            cmuNombre: "Curaco de Velez",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 66
        },
        {
            cmuCodigo: 73,
            cmuNombre: "Curanilahue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 67
        },
        {
            cmuCodigo: 74,
            cmuNombre: "Curarrehue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 68
        },
        {
            cmuCodigo: 75,
            cmuNombre: "Curepto",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 69
        },
        {
            cmuCodigo: 76,
            cmuNombre: "Curico",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 70
        },
        {
            cmuCodigo: 77,
            cmuNombre: "Dalcahue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 71
        },
        {
            cmuCodigo: 78,
            cmuNombre: "Diego de Almagro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 72
        },
        {
            cmuCodigo: 79,
            cmuNombre: "Doñihue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 73
        },
        {
            cmuCodigo: 80,
            cmuNombre: "El Bosque",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 81,
            cmuNombre: "El Carmen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 74
        },
        {
            cmuCodigo: 82,
            cmuNombre: "El Monte",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 75
        },
        {
            cmuCodigo: 83,
            cmuNombre: "El Quisco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 76
        },
        {
            cmuCodigo: 84,
            cmuNombre: "El Tabo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 77
        },
        {
            cmuCodigo: 85,
            cmuNombre: "Empedrado",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 78
        },
        {
            cmuCodigo: 86,
            cmuNombre: "Ercilla",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 79
        },
        {
            cmuCodigo: 87,
            cmuNombre: "Estacion Central",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 88,
            cmuNombre: "Florida",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 80
        },
        {
            cmuCodigo: 89,
            cmuNombre: "Freire",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 81
        },
        {
            cmuCodigo: 90,
            cmuNombre: "Freirina",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 82
        },
        {
            cmuCodigo: 91,
            cmuNombre: "Fresia",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 83
        },
        {
            cmuCodigo: 92,
            cmuNombre: "Frutillar",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 84
        },
        {
            cmuCodigo: 93,
            cmuNombre: "Futaleufu",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 85
        },
        {
            cmuCodigo: 94,
            cmuNombre: "Futrono",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 86
        },
        {
            cmuCodigo: 95,
            cmuNombre: "Galvarino",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 87
        },
        {
            cmuCodigo: 96,
            cmuNombre: "Gorbea",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 88
        },
        {
            cmuCodigo: 97,
            cmuNombre: "Gral. Lagos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 89
        },
        {
            cmuCodigo: 98,
            cmuNombre: "Graneros",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 90
        },
        {
            cmuCodigo: 99,
            cmuNombre: "Guaitecas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 91
        },
        {
            cmuCodigo: 100,
            cmuNombre: "Hijuelas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 92
        },
        {
            cmuCodigo: 101,
            cmuNombre: "Hualaihue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 93
        },
        {
            cmuCodigo: 102,
            cmuNombre: "Hualañe",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 94
        },
        {
            cmuCodigo: 103,
            cmuNombre: "Hualqui",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 95
        },
        {
            cmuCodigo: 104,
            cmuNombre: "Huara",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 96
        },
        {
            cmuCodigo: 105,
            cmuNombre: "Huasco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 97
        },
        {
            cmuCodigo: 106,
            cmuNombre: "Huechuraba",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 107,
            cmuNombre: "Illapel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 98
        },
        {
            cmuCodigo: 108,
            cmuNombre: "Independencia",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 109,
            cmuNombre: "Iquique",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 99
        },
        {
            cmuCodigo: 110,
            cmuNombre: "Isla de Maipo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 100
        },
        {
            cmuCodigo: 111,
            cmuNombre: "Isla De Pascua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 101
        },
        {
            cmuCodigo: 112,
            cmuNombre: "Juan Fernandez",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 102
        },
        {
            cmuCodigo: 113,
            cmuNombre: "La Cisterna",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 114,
            cmuNombre: "La Cruz",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 219
        },
        {
            cmuCodigo: 115,
            cmuNombre: "La Estrella",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 104
        },
        {
            cmuCodigo: 116,
            cmuNombre: "La Florida",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 117,
            cmuNombre: "La Granja",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 118,
            cmuNombre: "La Higuera",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 105
        },
        {
            cmuCodigo: 119,
            cmuNombre: "La Ligua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 106
        },
        {
            cmuCodigo: 120,
            cmuNombre: "La Pintana",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 121,
            cmuNombre: "La Reina",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 122,
            cmuNombre: "La Serena",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 107
        },
        {
            cmuCodigo: 123,
            cmuNombre: "La Union",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 108
        },
        {
            cmuCodigo: 124,
            cmuNombre: "Lago Ranco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 109
        },
        {
            cmuCodigo: 125,
            cmuNombre: "Lago Verde",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 110
        },
        {
            cmuCodigo: 126,
            cmuNombre: "Laguna Blanca",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 111
        },
        {
            cmuCodigo: 127,
            cmuNombre: "Laja",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 112
        },
        {
            cmuCodigo: 128,
            cmuNombre: "Lampa",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 113
        },
        {
            cmuCodigo: 129,
            cmuNombre: "Lanco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 114
        },
        {
            cmuCodigo: 130,
            cmuNombre: "Las Cabras",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 115
        },
        {
            cmuCodigo: 131,
            cmuNombre: "Las Condes",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 132,
            cmuNombre: "Lautaro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 116
        },
        {
            cmuCodigo: 133,
            cmuNombre: "Lebu",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 117
        },
        {
            cmuCodigo: 134,
            cmuNombre: "Licanten",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 118
        },
        {
            cmuCodigo: 135,
            cmuNombre: "Limache",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 119
        },
        {
            cmuCodigo: 136,
            cmuNombre: "Linares",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 120
        },
        {
            cmuCodigo: 137,
            cmuNombre: "Litueche",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 121
        },
        {
            cmuCodigo: 138,
            cmuNombre: "Llanquihue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 122
        },
        {
            cmuCodigo: 139,
            cmuNombre: "Llay-Llay",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 123
        },
        {
            cmuCodigo: 140,
            cmuNombre: "Lo Barnechea",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 141,
            cmuNombre: "Lo Espejo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 142,
            cmuNombre: "Lo Prado",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 143,
            cmuNombre: "Lolol",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 124
        },
        {
            cmuCodigo: 144,
            cmuNombre: "Loncoche",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 125
        },
        {
            cmuCodigo: 145,
            cmuNombre: "Longavi",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 126
        },
        {
            cmuCodigo: 146,
            cmuNombre: "Lonquimay",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 127
        },
        {
            cmuCodigo: 147,
            cmuNombre: "Los Alamos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 128
        },
        {
            cmuCodigo: 148,
            cmuNombre: "Los Andes",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 129
        },
        {
            cmuCodigo: 149,
            cmuNombre: "Los Angeles",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 130
        },
        {
            cmuCodigo: 150,
            cmuNombre: "Los Lagos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 131
        },
        {
            cmuCodigo: 151,
            cmuNombre: "Los Muermos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 132
        },
        {
            cmuCodigo: 152,
            cmuNombre: "Los Sauces",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 133
        },
        {
            cmuCodigo: 153,
            cmuNombre: "Los Vilos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 134
        },
        {
            cmuCodigo: 154,
            cmuNombre: "Lota",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 135
        },
        {
            cmuCodigo: 155,
            cmuNombre: "Lumaco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 136
        },
        {
            cmuCodigo: 156,
            cmuNombre: "Machali",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 137
        },
        {
            cmuCodigo: 157,
            cmuNombre: "Macul",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 158,
            cmuNombre: "Mafil",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 138
        },
        {
            cmuCodigo: 159,
            cmuNombre: "Maipu",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 160,
            cmuNombre: "Malloa",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 139
        },
        {
            cmuCodigo: 161,
            cmuNombre: "Marchigue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 140
        },
        {
            cmuCodigo: 162,
            cmuNombre: "Maria Elena",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 141
        },
        {
            cmuCodigo: 163,
            cmuNombre: "Maria Pinto",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 142
        },
        {
            cmuCodigo: 164,
            cmuNombre: "Maule",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 143
        },
        {
            cmuCodigo: 165,
            cmuNombre: "Maullin",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 144
        },
        {
            cmuCodigo: 166,
            cmuNombre: "Mejillones",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 145
        },
        {
            cmuCodigo: 167,
            cmuNombre: "Melipeuco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 146
        },
        {
            cmuCodigo: 168,
            cmuNombre: "Melipilla",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 147
        },
        {
            cmuCodigo: 169,
            cmuNombre: "Molina",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 148
        },
        {
            cmuCodigo: 170,
            cmuNombre: "Monte Patria",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 149
        },
        {
            cmuCodigo: 171,
            cmuNombre: "Mulchen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 150
        },
        {
            cmuCodigo: 172,
            cmuNombre: "Nacimiento",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 151
        },
        {
            cmuCodigo: 173,
            cmuNombre: "Nancagua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 152
        },
        {
            cmuCodigo: 174,
            cmuNombre: "Navarino",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 153
        },
        {
            cmuCodigo: 175,
            cmuNombre: "Navidad",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 154
        },
        {
            cmuCodigo: 176,
            cmuNombre: "Negrete",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 155
        },
        {
            cmuCodigo: 177,
            cmuNombre: "Ninhue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 156
        },
        {
            cmuCodigo: 178,
            cmuNombre: "Niquen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 157
        },
        {
            cmuCodigo: 179,
            cmuNombre: "Nogales",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 158
        },
        {
            cmuCodigo: 180,
            cmuNombre: "Nueva Imperial",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 159
        },
        {
            cmuCodigo: 181,
            cmuNombre: "Ñuñoa",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 182,
            cmuNombre: "OHiggins",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 160
        },
        {
            cmuCodigo: 183,
            cmuNombre: "Olivar",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 161
        },
        {
            cmuCodigo: 184,
            cmuNombre: "Ollague",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 162
        },
        {
            cmuCodigo: 185,
            cmuNombre: "Olmue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 163
        },
        {
            cmuCodigo: 186,
            cmuNombre: "Osorno",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 164
        },
        {
            cmuCodigo: 187,
            cmuNombre: "Ovalle",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 165
        },
        {
            cmuCodigo: 188,
            cmuNombre: "Padre Las Casas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 166
        },
        {
            cmuCodigo: 189,
            cmuNombre: "Paiguano",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 167
        },
        {
            cmuCodigo: 190,
            cmuNombre: "Paillaco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 168
        },
        {
            cmuCodigo: 191,
            cmuNombre: "Paine",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 169
        },
        {
            cmuCodigo: 192,
            cmuNombre: "Palena",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 170
        },
        {
            cmuCodigo: 193,
            cmuNombre: "Palmilla",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 171
        },
        {
            cmuCodigo: 194,
            cmuNombre: "Panguipulli",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 172
        },
        {
            cmuCodigo: 195,
            cmuNombre: "Panquehue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 173
        },
        {
            cmuCodigo: 196,
            cmuNombre: "Papudo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 174
        },
        {
            cmuCodigo: 197,
            cmuNombre: "Paredones",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 175
        },
        {
            cmuCodigo: 198,
            cmuNombre: "Parral",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 176
        },
        {
            cmuCodigo: 199,
            cmuNombre: "Pedro Aguirre Cerda",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 200,
            cmuNombre: "Pelarco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 177
        },
        {
            cmuCodigo: 201,
            cmuNombre: "Pelluhue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 178
        },
        {
            cmuCodigo: 202,
            cmuNombre: "Pemuco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 179
        },
        {
            cmuCodigo: 203,
            cmuNombre: "Peñaflor",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 180
        },
        {
            cmuCodigo: 204,
            cmuNombre: "Peñalolen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 205,
            cmuNombre: "Pencahue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 181
        },
        {
            cmuCodigo: 206,
            cmuNombre: "Penco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 182
        },
        {
            cmuCodigo: 207,
            cmuNombre: "Peralillo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 183
        },
        {
            cmuCodigo: 208,
            cmuNombre: "Perquenco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 184
        },
        {
            cmuCodigo: 209,
            cmuNombre: "Petorca",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 185
        },
        {
            cmuCodigo: 210,
            cmuNombre: "Peumo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 186
        },
        {
            cmuCodigo: 211,
            cmuNombre: "Pica",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 187
        },
        {
            cmuCodigo: 212,
            cmuNombre: "Pichidegua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 189
        },
        {
            cmuCodigo: 213,
            cmuNombre: "Pichilemu",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 190
        },
        {
            cmuCodigo: 214,
            cmuNombre: "Pinto",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 191
        },
        {
            cmuCodigo: 215,
            cmuNombre: "Pirque",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 192
        },
        {
            cmuCodigo: 216,
            cmuNombre: "Pitrufquen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 193
        },
        {
            cmuCodigo: 217,
            cmuNombre: "Placilla",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 250
        },
        {
            cmuCodigo: 218,
            cmuNombre: "Portezuelo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 194
        },
        {
            cmuCodigo: 219,
            cmuNombre: "Porvenir",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 195
        },
        {
            cmuCodigo: 220,
            cmuNombre: "Pozo Almonte",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 196
        },
        {
            cmuCodigo: 221,
            cmuNombre: "Primavera",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 197
        },
        {
            cmuCodigo: 222,
            cmuNombre: "Providencia",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 223,
            cmuNombre: "Puchuncavi",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 198
        },
        {
            cmuCodigo: 224,
            cmuNombre: "Pucon",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 199
        },
        {
            cmuCodigo: 225,
            cmuNombre: "Pudahuel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 226,
            cmuNombre: "Puente Alto",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 227,
            cmuNombre: "Puerto Varas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 203
        },
        {
            cmuCodigo: 228,
            cmuNombre: "Puerto Montt",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 200
        },
        {
            cmuCodigo: 229,
            cmuNombre: "Puerto Natales",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 201
        },
        {
            cmuCodigo: 230,
            cmuNombre: "Puerto Octay",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 202
        },
        {
            cmuCodigo: 231,
            cmuNombre: "Pumanque",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 204
        },
        {
            cmuCodigo: 232,
            cmuNombre: "Punitaqui",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 205
        },
        {
            cmuCodigo: 233,
            cmuNombre: "Punta Arenas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 206
        },
        {
            cmuCodigo: 234,
            cmuNombre: "Puqueldon",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 207
        },
        {
            cmuCodigo: 235,
            cmuNombre: "Puren",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 208
        },
        {
            cmuCodigo: 236,
            cmuNombre: "Purranque",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 209
        },
        {
            cmuCodigo: 237,
            cmuNombre: "Putaendo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 210
        },
        {
            cmuCodigo: 238,
            cmuNombre: "Putre",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 211
        },
        {
            cmuCodigo: 239,
            cmuNombre: "Puyehue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 212
        },
        {
            cmuCodigo: 240,
            cmuNombre: "Queilen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 213
        },
        {
            cmuCodigo: 241,
            cmuNombre: "Quellon",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 214
        },
        {
            cmuCodigo: 242,
            cmuNombre: "Quemchi",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 215
        },
        {
            cmuCodigo: 243,
            cmuNombre: "Quilaco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 216
        },
        {
            cmuCodigo: 244,
            cmuNombre: "Quilicura",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 245,
            cmuNombre: "Quilleco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 217
        },
        {
            cmuCodigo: 246,
            cmuNombre: "Quillon",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 218
        },
        {
            cmuCodigo: 247,
            cmuNombre: "Quillota",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 219
        },
        {
            cmuCodigo: 248,
            cmuNombre: "Quilpue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 220
        },
        {
            cmuCodigo: 249,
            cmuNombre: "Quinchao",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 221
        },
        {
            cmuCodigo: 250,
            cmuNombre: "Quinta de Tilcoco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 222
        },
        {
            cmuCodigo: 251,
            cmuNombre: "Quinta Normal",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 252,
            cmuNombre: "Quintero",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 223
        },
        {
            cmuCodigo: 253,
            cmuNombre: "Quirihue",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 224
        },
        {
            cmuCodigo: 254,
            cmuNombre: "Rancagua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 225
        },
        {
            cmuCodigo: 255,
            cmuNombre: "Ranquil",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 226
        },
        {
            cmuCodigo: 256,
            cmuNombre: "Rauco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 227
        },
        {
            cmuCodigo: 257,
            cmuNombre: "Recoleta",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 258,
            cmuNombre: "Renaico",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 228
        },
        {
            cmuCodigo: 259,
            cmuNombre: "Renca",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 260,
            cmuNombre: "Rengo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 229
        },
        {
            cmuCodigo: 261,
            cmuNombre: "Requinoa",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 230
        },
        {
            cmuCodigo: 262,
            cmuNombre: "Retiro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 231
        },
        {
            cmuCodigo: 263,
            cmuNombre: "Rinconada",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 232
        },
        {
            cmuCodigo: 264,
            cmuNombre: "Rio Bueno",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 233
        },
        {
            cmuCodigo: 265,
            cmuNombre: "Rio Claro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 234
        },
        {
            cmuCodigo: 266,
            cmuNombre: "Rio Hurtado",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 235
        },
        {
            cmuCodigo: 267,
            cmuNombre: "Rio Ibañez",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 236
        },
        {
            cmuCodigo: 268,
            cmuNombre: "Rio Negro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 237
        },
        {
            cmuCodigo: 269,
            cmuNombre: "Rio Verde",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 238
        },
        {
            cmuCodigo: 270,
            cmuNombre: "Romeral",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 239
        },
        {
            cmuCodigo: 271,
            cmuNombre: "Saavedra",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 240
        },
        {
            cmuCodigo: 272,
            cmuNombre: "Sagrada Familia",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 241
        },
        {
            cmuCodigo: 273,
            cmuNombre: "Salamanca",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 242
        },
        {
            cmuCodigo: 274,
            cmuNombre: "San Antonio",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 243
        },
        {
            cmuCodigo: 275,
            cmuNombre: "San Bernardo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 244
        },
        {
            cmuCodigo: 276,
            cmuNombre: "San Carlos",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 245
        },
        {
            cmuCodigo: 277,
            cmuNombre: "San Clemente",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 246
        },
        {
            cmuCodigo: 278,
            cmuNombre: "San Esteban",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 247
        },
        {
            cmuCodigo: 279,
            cmuNombre: "San Fabian",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 248
        },
        {
            cmuCodigo: 280,
            cmuNombre: "San Felipe",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 249
        },
        {
            cmuCodigo: 281,
            cmuNombre: "San Fernando",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 250
        },
        {
            cmuCodigo: 282,
            cmuNombre: "San Francisco de Mostazal",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 251
        },
        {
            cmuCodigo: 283,
            cmuNombre: "San Gregorio",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 252
        },
        {
            cmuCodigo: 284,
            cmuNombre: "San Ignacio",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 253
        },
        {
            cmuCodigo: 285,
            cmuNombre: "San Javier",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 254
        },
        {
            cmuCodigo: 286,
            cmuNombre: "San Joaquin",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 287,
            cmuNombre: "San Jose de la Mariquina",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 255
        },
        {
            cmuCodigo: 288,
            cmuNombre: "San Jose de Maipo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 256
        },
        {
            cmuCodigo: 289,
            cmuNombre: "San Juan de la Costa",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 257
        },
        {
            cmuCodigo: 290,
            cmuNombre: "San Miguel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 291,
            cmuNombre: "San Nicolas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 258
        },
        {
            cmuCodigo: 292,
            cmuNombre: "San Pablo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 259
        },
        {
            cmuCodigo: 293,
            cmuNombre: "San Pedro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 219
        },
        {
            cmuCodigo: 294,
            cmuNombre: "San Pedro de Atacama",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 260
        },
        {
            cmuCodigo: 295,
            cmuNombre: "San Pedro de la Paz",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 56
        },
        {
            cmuCodigo: 296,
            cmuNombre: "San Rafael",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 261
        },
        {
            cmuCodigo: 297,
            cmuNombre: "San Ramon",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 298,
            cmuNombre: "San Rosendo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 262
        },
        {
            cmuCodigo: 299,
            cmuNombre: "San Vicente",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 263
        },
        {
            cmuCodigo: 300,
            cmuNombre: "Santa Barbara",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 264
        },
        {
            cmuCodigo: 301,
            cmuNombre: "Santa Cruz",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 265
        },
        {
            cmuCodigo: 302,
            cmuNombre: "Santa Juana",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 266
        },
        {
            cmuCodigo: 303,
            cmuNombre: "Santa Maria",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 267
        },
        {
            cmuCodigo: 304,
            cmuNombre: "Santiago",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 305,
            cmuNombre: "Santo Domingo",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 269
        },
        {
            cmuCodigo: 306,
            cmuNombre: "Sierra Gorda",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 270
        },
        {
            cmuCodigo: 307,
            cmuNombre: "Talagante",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 272
        },
        {
            cmuCodigo: 308,
            cmuNombre: "Talca",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 273
        },
        {
            cmuCodigo: 309,
            cmuNombre: "Talcahuano",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 274
        },
        {
            cmuCodigo: 310,
            cmuNombre: "Tal-Tal",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 271
        },
        {
            cmuCodigo: 311,
            cmuNombre: "Temuco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 275
        },
        {
            cmuCodigo: 312,
            cmuNombre: "Teno",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 276
        },
        {
            cmuCodigo: 313,
            cmuNombre: "Teodoro Schmidt",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 277
        },
        {
            cmuCodigo: 314,
            cmuNombre: "Tierra Amarilla",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 278
        },
        {
            cmuCodigo: 315,
            cmuNombre: "Til-Til",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 279
        },
        {
            cmuCodigo: 316,
            cmuNombre: "Timaukel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 280
        },
        {
            cmuCodigo: 317,
            cmuNombre: "Tirua",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 281
        },
        {
            cmuCodigo: 318,
            cmuNombre: "Tocopilla",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 2,
            ciuCodigo: 282
        },
        {
            cmuCodigo: 319,
            cmuNombre: "Tolten",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 283
        },
        {
            cmuCodigo: 320,
            cmuNombre: "Tome",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 284
        },
        {
            cmuCodigo: 321,
            cmuNombre: "Torres del Paine",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 12,
            ciuCodigo: 285
        },
        {
            cmuCodigo: 322,
            cmuNombre: "Tortel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 11,
            ciuCodigo: 286
        },
        {
            cmuCodigo: 323,
            cmuNombre: "Traiguen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 287
        },
        {
            cmuCodigo: 324,
            cmuNombre: "Treguaco",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 288
        },
        {
            cmuCodigo: 325,
            cmuNombre: "Tucapel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 289
        },
        {
            cmuCodigo: 326,
            cmuNombre: "Valdivia",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 327,
            cmuNombre: "Vallenar",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 291
        },
        {
            cmuCodigo: 328,
            cmuNombre: "Valparaiso",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 292
        },
        {
            cmuCodigo: 329,
            cmuNombre: "Vichuquen",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 293
        },
        {
            cmuCodigo: 330,
            cmuNombre: "Victoria",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 294
        },
        {
            cmuCodigo: 331,
            cmuNombre: "Vicuña",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 295
        },
        {
            cmuCodigo: 332,
            cmuNombre: "Vilcun",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 296
        },
        {
            cmuCodigo: 333,
            cmuNombre: "Villa Alegre",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 297
        },
        {
            cmuCodigo: 334,
            cmuNombre: "Villa Alemana",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 298
        },
        {
            cmuCodigo: 335,
            cmuNombre: "Villarrica",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 299
        },
        {
            cmuCodigo: 336,
            cmuNombre: "Viña del Mar",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 300
        },
        {
            cmuCodigo: 337,
            cmuNombre: "Vitacura",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 268
        },
        {
            cmuCodigo: 338,
            cmuNombre: "Yerbas Buenas",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 7,
            ciuCodigo: 301
        },
        {
            cmuCodigo: 339,
            cmuNombre: "Yumbel",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 302
        },
        {
            cmuCodigo: 340,
            cmuNombre: "Yungay",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 303
        },
        {
            cmuCodigo: 341,
            cmuNombre: "Zapallar",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 304
        },
        {
            cmuCodigo: 342,
            cmuNombre: "La Calera",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 103
        },
        {
            cmuCodigo: 343,
            cmuNombre: "PICHIDANGUI",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 188
        },
        {
            cmuCodigo: 344,
            cmuNombre: "PADRE HURTADO",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 272
        },
        {
            cmuCodigo: 346,
            cmuNombre: "CORRAL",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 347,
            cmuNombre: "SAN JOSE DE LA MARIQUINA",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 348,
            cmuNombre: "MAFIL",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 349,
            cmuNombre: "LANCO",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 350,
            cmuNombre: "LOS LAGOS",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 351,
            cmuNombre: "FUTRONO",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 352,
            cmuNombre: "PANGUIPULLI",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 354,
            cmuNombre: "LA UNION",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 108
        },
        {
            cmuCodigo: 356,
            cmuNombre: "LAGO RANCO",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 108
        },
        {
            cmuCodigo: 358,
            cmuNombre: "CAMARONES",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 15,
            ciuCodigo: 11
        },
        {
            cmuCodigo: 359,
            cmuNombre: "PARINACOTA",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 15,
            ciuCodigo: 305
        },
        {
            cmuCodigo: 360,
            cmuNombre: "PUTRE",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 15,
            ciuCodigo: 305
        },
        {
            cmuCodigo: 361,
            cmuNombre: "GENERAL LAGOS",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 15,
            ciuCodigo: 305
        },
        {
            cmuCodigo: 362,
            cmuNombre: "RAPEL",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 154
        },
        {
            cmuCodigo: 363,
            cmuNombre: "NAVIDAD",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 154
        },
        {
            cmuCodigo: 364,
            cmuNombre: "ALTO HOSPICIO",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 1,
            ciuCodigo: 99
        },
        {
            cmuCodigo: 365,
            cmuNombre: "TONGOY",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 4,
            ciuCodigo: 59
        },
        {
            cmuCodigo: 366,
            cmuNombre: "HUALPEN",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 56
        },
        {
            cmuCodigo: 367,
            cmuNombre: "MARIQUINA",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 14,
            ciuCodigo: 290
        },
        {
            cmuCodigo: 368,
            cmuNombre: "PUERTO SAAVEDRA",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 275
        },
        {
            cmuCodigo: 369,
            cmuNombre: "ENTRELAGOS",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 10,
            ciuCodigo: 164
        },
        {
            cmuCodigo: 370,
            cmuNombre: "Diego de Almagro",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 3,
            ciuCodigo: 32
        },
        {
            cmuCodigo: 371,
            cmuNombre: "PENA BLANCA",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 5,
            ciuCodigo: 27
        },
        {
            cmuCodigo: 372,
            cmuNombre: "ALTO BIO BIO",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 8,
            ciuCodigo: 130
        },
        {
            cmuCodigo: 373,
            cmuNombre: "CHOLCHOL",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 9,
            ciuCodigo: 275
        },
        {
            cmuCodigo: 374,
            cmuNombre: "MOSTAZAL",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 6,
            ciuCodigo: 308
        },
        {
            cmuCodigo: 999,
            cmuNombre: ".",
            cmuCodTesoreria: 0,
            cmuDdn: 0,
            regCodigo: 13,
            ciuCodigo: 0
        }
    ];
    captchaDemo: ReCAPTCHA | null = null;
    onLoadRecaptcha = (value: any) => {

        if (this.captchaDemo && value != null) {
            this.setState({ captchaToken: false });
        }
    };
    handleTerminosYCondiciones = () => {
        this.setState({
            alert: (
                <SweetAlert
                    info
                    scrollbarPadding
                    style={{ display: 'block', marginTop: '-430px' }}
                    title={
                        <Typography variant='h6' align='center' component='p' gutterBottom>
                            Términos y Condiciones
                        </Typography>
                    }
                    showConfirm={false}>
                    <Typography variant='body1' gutterBottom component='p' style={{overflowY:'scroll', maxHeight:'500px'}}>

                        <p style={{textAlign:'left'}}>LOS SIGUIENTES TÉRMINOS Y CONDICIONES CORRESPONDEN A LOS SEÑALADOS Y ESTABLECIDOS PARA EL
                            PORTAL DE SERVICIOS FINANCIEROS DE FACTOTAL, RUT 96.660.790-4, HACIENDO USO DE SU MARCA
                            REGISTRA FACTOTAL, POR LO QUE CUALQUIER TIPO DE REFERENCIA EN EL PORTAL ACTUAL DE FACTOTAL
                            SE ENTIENDE HECHA LA REFERENCIA Y AUTORIZACIONES A FACTOTAL, EN ADELANTE “FACTOTAL” O
                            WWW.FACTOTAL.COM (PORTAL) Y SUS APLICACIONES PARA DISPOSITIVOS MÓVILES (APLICACIONES).</p>
                        <p style={{textAlign:'left'}}>EL ACCESO A Y/O USO DE LOS SERVICIOS QUE FACTOTAL PONE A DISPOSICIÓN DE TODO AQUEL QUE
                            UTILIZA EL PORTAL (USUARIO) ESTÁ SUJETO A LAS PRESENTES CONDICIONES GENERALES DESDE EL
                            MOMENTO DEL INGRESO AL PORTAL Y APLICACIONES Y A LAS CONDICIONES DE ACCESO Y TRATAMIENTO DE
                            DATOS QUE ACEPTÓ CUANDO INGRESÓ A LA PLATAFORMA, LAS QUE SERÁN VINCULANTES PARA EL USUARIO
                            DESDE SU ACEPTACIÓN O CONSENTIMIENTO.</p>
                        <p style={{textAlign:'left'}}>AL INGRESAR SUS CREDENCIALES DE ACCESO (CLAVE DEL SII), EL USUARIO OTORGA A FACTOTAL UN
                            MANDATO ESPECIAL CON REPRESENTACIÓN, DELEGABLE Y REVOCABLE, PARA ACTUAR A NOMBRE DEL USUARIO
                            EN EL ACCESO A SISTEMAS INFORMÁTICOS CON DICHAS CREDENCIALES, Y AUTORIZA LA INCLUSIÓN Y
                            ALMACENAMIENTO DE LOS DATOS RELACIONADOS CON INFORMACIÓN TRIBUTARIA EN LA BASE DE DATOS DE
                            FACTOTAL, SOLO CON EL FIN DE QUE ESTE REALICE DE FORMA SEGUIDA, PERIÓDICA, INCLUSIVE DIARIA
                            Y AUTOMATIZADA LA CONSULTA, EXTRACCIÓN Y PROCESAMIENTO DE INFORMACIÓN TRIBUTARIA QUE FIGURE
                            CON SU USUARIO EN EL SII, COMO BALANCES, DECLARACIONES DE IMPUESTOS, RENTA, FACTURACIÓN,
                            FACTURAS CEDIDAS Y A QUIEN, DATOS PERSONALES Y EMPRESARIALES, LIBROS DE COMPRAS Y VENTAS,
                            IVAS, ETC, TAMBIÉN REALIZAR CONSULTAS A TESORERÍA GENERAL DE LA REPÚBLICA, CON EL FIN DE
                            AUTOMATIZAR LOS PROCESOS DE EVALUACIÓN FINANCIERA DE LA EMPRESA Y FACILITARLE ESTO A LA
                            EMPRESA, EL ACCESO AL SISTEMA DEL SII QUEDARÁ REGISTRADO AUTOMÁTICAMENTE EL EMAIL
                            CONTACTO@FACTOTAL.COM O UNO SIMILAR. ADICIONALMENTE FACTOTAL ALMACENARÁ DE FORMA ENCRIPTADA
                            SOLO CON LA FINALIDAD DE FACILITAR EL INGRESO LA O LAS CLAVES DE ACCESO, EL RESTO DE LA
                            INFORMACIÓN, COMO FACTURAS, INFORMACIÓN EXTRAÍDA Y CERTIFICADOS DIGITALES, NO SE ENCUENTRA
                            ENCRIPTADA, PERO SI RESGUARDADA CON LAS MEDIDAS Y CIBERSEGURIDAD ADECUADAS PARA ELLO.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL, FUNCIONA SOBRE UN MOTOR TECNOLÓGICO QUE PROCESA LA INFORMACIÓN DE LA EMPRESA
                            RECOPILADA EN EL SII Y OTRAS FUENTES DE INFORMACIÓN DE ACCESO PÚBLICO. EN ESTE ÚLTIMO
                            SENTIDO, ES MUY IMPORTANTE QUE TENGA PRESENTE QUE POR EL HECHO DE ACEPTAR ESTOS TÉRMINOS DE
                            SERVICIO, USTED NOS AUTORIZA Y COMPROMETE A OTORGAR ACCESO A SU CUENTA EN EL PORTAL DEL SII
                            Y ASÍ PODAMOS ACCEDER A LA INFORMACIÓN TRIBUTARIA Y COMERCIAL DE SU EMPRESA QUE NOS
                            PERMITIRÁ ANALIZAR EN LÍNEA SU SALUD FINANCIERA, Y EN FORMA AUTOMATIZADA UNA ESTIMACIÓN DE
                            SU FLUJO DE CAJA, APROXIMACIÓN DE SUS ESTADOS FINANCIEROS, COMPORTAMIENTO DE PAGO DE
                            COMPROMISOS TRIBUTARIO, TRANSFERENCIA DE CRÉDITOS Y OTROS, CON EL FIN DE PODER OTORGARLE
                            OTROS SERVICIOS COMPLEMENTARIOS.</p>
                        <p style={{textAlign:'left'}}>ASIMISMO, DISPONIBILIZAR FUNCIONES QUE INTERACTÚAN ANTE EL SII COMO SON ACEPTAR, RECLAMAR, O
                            RECHAZAR FACTURAS, COMO ASIMISMO EN EL EVENTO QUE SE ENCUENTRE DISPONIBLE REALIZAR FACTURAS,
                            CEDERLAS, ETC. TODO LO ANTERIOR SIEMPRE Y CUANDO LOS SERVIDORES DEL SII CON LOS CUALES
                            ESTAMOS INTEGRADOS NO SE ENCUENTREN CAÍDOS, NO CABIÉNDOLE RESPONSABILIDAD ALGUNA A FACTOTAL
                            EN DICHO ESCENARIO.</p>
                        <p style={{textAlign:'left'}}>EN EL CASO DE LA INFORMACIÓN Y ACCIONES QUE SE ENCUENTRAN EN EL PORTAL DEL SII, PODREMOS
                            ACCEDER, CÓMO HEMOS SEÑALADO, POR EL HECHO DE CONTAR, A PARTIR DE SU ACEPTACIÓN DE ESTOS
                            TÉRMINOS DE SERVICIO, CON SU AUTORIZACIÓN.</p>
                        <p style={{textAlign:'left'}}>AL ACEPTAR LOS TÉRMINOS Y CONDICIONES, Y POR ENDE, AL HACER USO DE LOS SERVICIOS DE FACTOTAL,
                            CONFORME A LA DESCRIPCIÓN QUE SE ENTREGÓ, USTED DECLARA ACTUAR A NOMBRE Y EN REPRESENTACIÓN
                            DE UNA EMPRESA O ENTIDAD LEGÍTIMAMENTE CONSTITUIDA, Y ASIMISMO, DECLARA QUE ESTÁ INVESTIDO
                            DE LAS SUFICIENTES FACULTADES PARA OBLIGAR A DICHA EMPRESA O ENTIDAD A CUMPLIR LOS TÉRMINOS
                            Y CONDICIONES DE USO DE NUESTRO SERVICIO. ASIMISMO, USTED DECLARA ACEPTAR QUE FACTOTAL SÓLO
                            PARTICIPA EN EL PROCESO DE RECOPILACIÓN Y ANÁLISIS DE INFORMACIÓN PARA QUE PUEDA EJECUTAR
                            SUS LABORES DE ANÁLISIS FINANCIERO. EL RESULTADO DE ESTE ANÁLISIS Y LAS DECISIONES O
                            ACCIONES QUE USTED ADOPTE PARA SU EMPRESA CON LA INFORMACIÓN QUE FACTOTAL LE PROPORCIONE SON
                            DE SU EXCLUSIVA RESPONSABILIDAD. FINALMENTE, USTED TAMBIÉN ACEPTA Y DECLARA QUE LA
                            INFORMACIÓN QUE SEA CAPTURADA POR FACTOTAL DESDE SU PORTAL EN EL SII, ES VERÍDICA Y QUE SE
                            APEGA LA VERDAD, SIENDO TAMBIÉN DE SU EXCLUSIVA RESPONSABILIDAD CUALQUIER DISTORSIÓN O ERROR
                            QUE DICHA INFORMACIÓN CONTENGA, AÚN CUANDO SE TRATE DE SU INFORMACIÓN OBTENIDA DESDE EL
                            PORTAL DEL SII.</p>
                        <p style={{textAlign:'left'}}>SE DECLARA QUE NO EXISTIRÁ CONFIDENCIALIDAD CUANDO LA INFORMACIÓN SEA DE DOMINIO PÚBLICO.</p>
                        <p style={{textAlign:'left'}}>TODA LA ACTIVIDAD DE FACTOTAL, SE ENMARCA EN EL CUMPLIMIENTO DE LA LEY DE PROTECCIÓN A LA
                            VIDA PRIVADA Y TRATAMIENTO DE DATOS PERSONALES EN LA REPÚBLICA DE CHILE, LEY NÚMERO 19.628,
                            SOBRE PROTECCIÓN DE LA VIDA PRIVADA, PROMULGADA EL 18 DE AGOSTO DE 1999, Y PUBLICADA EL 28
                            DE AGOSTO DEL MISMO AÑO. DICHA LEY FUE MODIFICADA POR LA LEY NÚMERO 20.575, QUE ESTABLECE EL
                            PRINCIPIO DE FINALIDAD EN EL TRATAMIENTO DE DATOS PERSONALES, Y QUE FUERA PROMULGADA EL 14
                            DE FEBRERO DE 2012, Y PUBLICADA EL 17 DE FEBRERO DE 2012.</p>
                        <p style={{textAlign:'left'}}>ESTOS TÉRMINOS PUEDEN SER ACTUALIZADOS OCASIONALMENTE CON LOS COMENTARIOS DE LOS USUARIOS O
                            POR MEJORAS EN NUESTROS SERVICIOS. DE SER NECESARIAS DICHAS ACTUALIZACIONES EN LOS TÉRMINOS
                            O EN EL MODO EN QUE FACTOTAL VA A UTILIZAR SUS DATOS, SE LO COMUNICAREMOS OPORTUNAMENTE
                            MEDIANTE LA PUBLICACIÓN DE UN AVISO MEDIANTE UNA NOTIFICACIÓN DIRECTA.</p>
                        <p style={{textAlign:'left'}}>ES OBLIGACIÓN DE LOS USUARIOS, REVISAR PERIÓDICAMENTE LAS ACTUALIZACIONES DE ESTOS TÉRMINOS
                            DE SERVICIO Y MANTENERSE INFORMADO DEL MODO EN QUE FACTOTAL PROTEGE SU INFORMACIÓN.</p>
                        <p style={{textAlign:'left'}}>LOS USUARIOS PODRÁN PONER TÉRMINO EN TODO MOMENTO A LA RELACIÓN CON FACTOTAL, EL CUAL DARÁ DE
                            BAJA TODO SERVICIO DE EXTRACCIÓN DE INFORMACIÓN PROVISTO POR FACTOTAL. LA APLICACIÓN DE LOS
                            TÉRMINOS FINALIZARÁ INMEDIATAMENTE DESPUÉS DE LA RESCISIÓN DE ESTOS TÉRMINOS DE SERVICIO,
                            ANTERIORMENTE VIGENTES ENTRE FACTOTAL Y CADA UNO DE LOS USUARIOS. EL TÉRMINO DE CONTRATO O
                            FINALIZACIÓN DE LA RELACIÓN REGIDA POR LOS TÉRMINOS SÓLO AFECTARÁ A LAS TRANSACCIONES
                            REALIZADAS A PARTIR DE ESA FECHA.</p>
                        <p style={{textAlign:'left'}}>PRODUCIDA LA RESCISIÓN DE LA RELACIÓN, LOS DERECHOS Y LAS OBLIGACIONES SE MANTENDRÁN CON EL
                            PROPÓSITO DE PROTEGER AQUELLAS RESPONSABILIDADES QUE PUEDAN SURGIR POSTERIORMENTE Y COMO
                            CONSECUENCIA DE LA RELACIÓN CON LOS USUARIOS.</p>
                        <h3>POLÍTICA DE PRIVACIDAD</h3>
                        <p style={{textAlign:'left'}}>LA PRESENTE POLÍTICA DE PRIVACIDAD TIENE POR FINALIDAD INFORMAR A LOS USUARIOS DEL
                            TRATAMIENTO DE LOS DATOS EMPRESARIALES QUE SE RECOGEN, PUBLICAN, PROCESAN E INTERCAMBIAN
                            ESTÁ EN CUMPLIMIENTO A LAS LEGISLACIONES VIGENTES.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL RESPETA SU PRIVACIDAD Y SE COMPROMETE A PROTEGERLA Y A PROTEGER SU INFORMACIÓN
                            CONFIDENCIAL. ESTA POLÍTICA DE PRIVACIDAD (JUNTO CON NUESTROS TÉRMINOS DE USO Y CUALQUIER
                            DOCUMENTO AL QUE SE HAGA REFERENCIA EN EL MISMO) INDICA LOS CRITERIOS PARA EL TRATAMIENTO DE
                            LA INFORMACIÓN PERSONAL QUE HAYAMOS RECOPILADO DE USTED O QUE USTED NOS HAYA
                            PROPORCIONADO.</p>
                        <p style={{textAlign:'left'}}>LOS DATOS APORTADOS POR LAS DIFERENTES FUENTES DE INFORMACIÓN SOLO SERÁN UTILIZADOS PARA LOS
                            FINES QUE FUERON SUMINISTRADOS, SALVO QUE PROVENGAN DE FUENTES ACCESIBLES AL PÚBLICO.</p>
                        <p style={{textAlign:'left'}}>LOS DATOS QUE PUDIERAN ESTAR IMPLICADOS EN RELACIÓN CON LOS DOCUMENTOS ELECTRÓNICOS, FACTURA
                            ELECTRÓNICA, INFORMACIÓN DE PAGO SUMINISTRADA U OTROS DE SIMILARES CARACTERÍSTICAS ESTARÁN
                            SOMETIDOS A LA GARANTÍA DE SER ACCESIBLE SOLAMENTE PARA FACTOTAL O QUIENES ACTÚEN BAJO SU
                            REPRESENTACIÓN O MANDATO.</p>
                        <p style={{textAlign:'left'}}>LOS DATOS RECOGIDOS PODRÁN SER CONSERVADOS Y TRATADOS CON FINES ESTADÍSTICOS, SIEMPRE Y
                            CUANDO SEA IMPOSIBLE LA IDENTIFICACIÓN DE SUS TITULARES, UTILIZANDO MEDIOS TÉCNICOS
                            RAZONABLES.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL NO REVELARÁ LOS DATOS DE LA EMPRESA A TERCEROS, SALVO QUE ÉSTOS ACTÚEN EN SU
                            REPRESENTACIÓN O EL TITULAR DEL MISMO LO INDIQUE O POR IMPERATIVO LEGAL. SI TERCERAS PARTES
                            SE PUSIESEN EN CONTACTO CON NOSOTROS SOLICITANDO DATOS DE USUARIOS, LES INDICARÍAMOS QUE SE
                            LOS SOLICITEN DIRECTAMENTE A USTEDES O QUE REQUERIRÍAMOS DE EXPRESA AUTORIZACIÓN DE VUESTRA
                            PARTE A NOSOTROS PARA OTORGARLOS. A TAL FIN, LES PODRÍAMOS PROPORCIONAR INFORMACIÓN DE
                            CONTACTO BÁSICA. SI NOS VIÉSEMOS OBLIGADOS A REVELAR SUS DATOS DE USUARIOS A TERCEROS,
                            EMPLEARÍAMOS TODOS LOS ESFUERZOS COMERCIALMENTE RAZONABLES PARA NOTIFICÁRSELO POR
                            ADELANTADO, A MENOS QUE NOS LO IMPIDA LA LEY.</p>
                        <p style={{textAlign:'left'}}>POLITICA DE PRIVACIDAD PAGINA Y PORTAL, POLITICA DE PROCESAMIENTO DE DATOS CON COOKIES</p>
                        <p style={{textAlign:'left'}}>LOS SIGUIENTES TÉRMINOS Y CONDICIONES CORRESPONDEN AL PORTAL DE SERVICIOS FINANCIEROS DE
                            FACTOTAL, RUT 96.660.790-4, HACIENDO USO DE SU MARCA REGISTRA FACTOTAL, POR LO QUE CUALQUIER
                            TIPO DE REFERENCIA EN EL PORTAL ACTUAL DE FACTOTAL SE ENTIENDE HECHA LA REFERENCIA Y
                            AUTORIZACIONES A FACTOTAL, EN ADELANTE “FACTOTAL” O WWW.FACTOTAL.COM (PORTAL) Y SUS
                            APLICACIONES PARA DISPOSITIVOS MÓVILES (APLICACIONES), CONTIENE LOS TÉRMINOS Y POLÍTICAS QUE
                            REGULAN EL USO DEL PORTAL Y APLICACIONES (POLÍTICAS GENERALES) Y PROPORCIONA A SUS
                            VISITANTES DIVERSA INFORMACIÓN SOBRE SUS PRODUCTOS Y/O SERVICIOS.</p>
                        <p style={{textAlign:'left'}}>EL ACCESO A Y/O USO DE LOS SERVICIOS QUE FACTOTAL PONE A DISPOSICIÓN DE TODO AQUEL QUE
                            UTILIZA EL PORTAL (USUARIO) ESTÁ SUJETO A LAS PRESENTES CONDICIONES GENERALES DESDE EL
                            MOMENTO DEL INGRESO AL PORTAL Y APLICACIONES, LAS QUE SERÁN VINCULANTES PARA EL USUARIO
                            DESDE SU ACEPTACIÓN O CONSENTIMIENTO.</p>
                        <h3>CONDICIONES DE USO</h3>
                        <p style={{textAlign:'left'}}>LAS PRESENTES CONDICIONES DE USO DEL PORTAL DE FACTOTAL CHILE CONTIENEN LOS TÉRMINOS,
                            CONDICIONES, Y POLÍTICAS QUE REGULAN EL USO DEL PORTAL (POLÍTICAS GENERALES). ASIMISMO,
                            PROPORCIONA A SUS VISITANTES DIVERSA INFORMACIÓN, CONTENIDO, PRODUCTOS Y/O SERVICIOS
                            (SERVICIOS).</p>
                        <p style={{textAlign:'left'}}>EL USO DEL PORTAL ESTÁ CONDICIONADO A QUE EL USUARIO LO UTILICE DE ACUERDO A LA LEGISLACIÓN
                            VIGENTE, Y A ESTAS POLÍTICAS GENERALES; Y PARA ACCEDER A ELLOS DEBERÁ EMPLEAR LOS
                            PROCEDIMIENTOS ESTABLECIDOS O, EN SU DEFECTO, LOS QUE GENERALMENTE SE EMPLEAN EN INTERNET,
                            PERO, EN CASO ALGUNO, PODRÁ PONER EN RIESGO EL PORTAL, LOS SERVICIOS Y/O CONTENIDO DEL
                            MISMO.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL PODRÁ, POR PROBLEMAS TÉCNICOS, TALES COMO CORTE OPERACIONAL, CORTE DE ENERGÍA
                            ELÉCTRICA Y/O CASO FORTUITO O FUERZA MAYOR, SUSPENDER TEMPORALMENTE EL SUMINISTRO DE ESTOS
                            SERVICIOS. ASIMISMO, FACTOTAL PODRÁ EFECTUAR MANTENCIONES A LOS SISTEMAS QUE IMPORTEN UNA
                            SUSPENSIÓN TEMPORAL DE ESTOS MISMOS SERVICIOS, LO QUE SERÁ INFORMADO AL CLIENTE POR LOS
                            MEDIOS QUE HABITUALMENTE EMPLEA PARA ESTOS EFECTOS, TALES COMO NOTIFICACIÓN POR CORREO
                            ELECTRÓNICO, NOTIFICACIONES ELECTRÓNICAS U OTROS AUTORIZADOS.</p>
                        <p style={{textAlign:'left'}}>EL USUARIO DEBERÁ UTILIZAR LOS PROCEDIMIENTOS Y/O MEDIOS DE SEGURIDAD, IDENTIFICACIÓN E
                            INTEGRIDAD QUE FACTOTAL HA IMPLEMENTADO O PONGA EN USO EN EL FUTURO, Y QUE PUDIEREN ESTAR
                            ASOCIADOS A LOS ELEMENTOS REQUERIDOS PARA SU UTILIZACIÓN. EL USUARIO ENTIENDE Y ACEPTA QUE
                            DEBERÁ TENER LA DILIGENCIA Y CUIDADO QUE LAS PERSONAS EMPLEAN ORDINARIAMENTE EN SUS NEGOCIOS
                            PROPIOS PARA EVITAR VIRUS, INFECCIONES, CABALLOS DE TROYA ENTRE OTRAS; PARA TALES EFECTOS,
                            DEBERÁ UTILIZAR LAS MEDIDAS DE SEGURIDAD NECESARIAS.</p>
                        <h3>CONDICIONES DE ACCESO</h3>
                        <p style={{textAlign:'left'}}>ES DE CARGO DEL USUARIO OBTENER EL ACCESO A INTERNET Y/O WORLD WIDE WEB ASÍ COMO OBTENER E
                            IMPLEMENTAR LOS EQUIPOS Y/O INSTRUMENTOS QUE LE PERMITAN EL ACCESO A ESTA RED; DE TAL MANERA
                            QUE EL USUARIO DEBE PROVEERSE DE TODO LO NECESARIO PARA EL ADECUADO USO DEL PORTAL.</p>
                        <h3>SERVICIOS</h3>
                        <p style={{textAlign:'left'}}> FACTOTAL PROVEE AL USUARIO DIVERSOS SERVICIOS FINANCIEROS; EL USUARIO ACEPTA QUE EL FACTOTAL
                            NO PRESTA ASESORÍA EN MATERIA LEGAL, TRIBUTARIO, CONTABLE, NI EN GENERAL, SEÑALA ACCIONES A
                            SEGUIR, DE TAL MANERA QUE EL USUARIO ES LIBRE DE ACTUAR Y LO HACE BAJO SU PROPIA
                            RESPONSABILIDAD.</p>
                        <p style={{textAlign:'left'}}>EL USUARIO ENTIENDE Y ACEPTA QUE ALGUNO O ALGUNOS DE LOS SERVICIOS PRESENTADOS EN O A TRAVÉS
                            DEL PORTAL Y APLICACIONES SON DESARROLLADOS Y/O ENTREGADOS POR TERCEROS.</p>
                        <p style={{textAlign:'left'}}>A LA MAYORÍA DE LOS SERVICIOS QUE SON OTORGADOS POR O A TRAVÉS DEL PORTAL Y APLICACIONES SE
                            ACCEDE A TRAVÉS DE UNA CLAVE SECRETA QUE HA SIDO PROPORCIONADA AL USUARIO CON
                            ANTERIORIDAD.</p>
                        <p style={{textAlign:'left'}}>EL USUARIO DEBERÁ NOTIFICAR AL FACTOTAL CUANDO CREA QUE SU IDENTIFICACIÓN, FIRMA ELECTRÓNICA,
                            CUENTA Y/O CLAVE SECRETA HA SIDO UTILIZADA POR UN TERCERO, Y FACTOTAL FRENTE A ESTA
                            NOTIFICACIÓN ENTREGARÁ AL USUARIO UN NÚMERO O CÓDIGO DE RECEPCIÓN DEL AVISO Y LA FECHA Y
                            HORA DE SU RECEPCIÓN, ADEMÁS DE BLOQUEAR INMEDIATAMENTE LAS MISMAS.</p>
                        <p style={{textAlign:'left'}}>ES DE RESPONSABILIDAD EXCLUSIVA DEL USUARIO EL INGRESO Y EL USO Y MANEJO DE LA CLAVE SECRETA,
                            DEBIENDO MANEJARLA CON LA DEBIDA CONFIDENCIALIDAD; DICHA CLAVE SERÁ CONSIDERADA COMO FIRMA
                            ELECTRÓNICA PARA LOS EFECTOS DE REALIZAR LAS OPERACIONES EN EL PORTAL.</p>
                        <p style={{textAlign:'left'}}>LA INFORMACIÓN OBTENIDA POR O A TRAVÉS DEL PORTAL Y/O CUALESQUIERA DE LOS SERVICIOS QUE POR
                            ESTE MEDIO SE OFRECE U OTORGA ES PARA EL USO PERSONAL DEL USUARIO, OBLIGÁNDOSE ÉSTE A
                            MANTENER LA DEBIDA CONFIDENCIALIDAD; LE QUEDA PROHIBIDA AL USUARIO LA CESIÓN, REPRODUCCIÓN,
                            RECIRCULACIÓN, RETRANSMISIÓN, COMERCIALIZACIÓN Y/O DISTRIBUCIÓN, EN TODO O PARTE, Y
                            CUALQUIER FORMA DE LOS CONTENIDOS DEL PORTAL.</p>

                        <h3>CONFIDENCIALIDAD</h3>
                        <p style={{textAlign:'left'}}>FACTOTAL DECLARA QUE HA TOMADO LAS MEDIDAS NECESARIAS EN SUS INSTALACIONES Y SISTEMAS QUE
                            GARANTIZAN LA CONFIDENCIALIDAD DE LOS DATOS INGRESADOS POR EL USUARIO AL PORTAL.</p>
                        <p style={{textAlign:'left'}}>EL USUARIO SE OBLIGA A PROPORCIONAR INFORMACIÓN AUTÉNTICA, CIERTA, VERDADERA, FIDEDIGNA,
                            COMPLETA Y ACTUALIZADA CUANDO ASÍ SEA REQUERIDO EN O A TRAVÉS DEL PORTAL.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL TRATARÁ LOS DATOS DE CARÁCTER PERSONAL, QUE SEAN INGRESADOS POR EL USUARIO O
                            AQUELLOS QUE OBRAN EN PODER DE FACTOTAL POR CUALQUIER CAUSA, CON LA DEBIDA CONFIDENCIALIDAD
                            Y DE ACUERDO A LAS NORMAS VIGENTES.</p>
                        <p style={{textAlign:'left'}}>PARA LOS EFECTOS DE LA LEY 19.628 SOBRE PROTECCIÓN DE DATOS DE CARÁCTER PERSONAL EL CLIENTE
                            AUTORIZA A FACTOTAL A DIVULGAR A LAS AUTORIDADES COMPETENTES O COMPARTIR EXCLUSIVAMENTE CON
                            TERCEROS CON QUIENES SUSCRIBA ALIANZAS O ACUERDOS COMERCIALES, CIERTOS DATOS PERSONALES COMO
                            EL NÚMERO DE RUT, DOMICILIO, ACTIVIDAD, CORREO ELECTRÓNICO, ACTIVIDAD O INFORMACIÓN
                            RELACIONADA A PRODUCTOS CONTRATADOS CON FACTOTAL QUE NO SE ENCUENTREN SUJETO A SECRETO
                            BANCARIO, CUANDO ELLO SE REALICE CON EL PROPÓSITO DE FACILITAR EL DESARROLLO,
                            MATERIALIZACIÓN Y/O IMPLEMENTACIÓN DE ACCIONES COMERCIALES, SEAN DE CARÁCTER GENERAL O
                            PERSONAL, CUANDO ELLO FUERE NECESARIO O CONVENIENTE A FIN DE AGREGAR NUEVOS ATRIBUTOS O
                            BENEFICIOS A SUS CLIENTES O MEJORAR LA CALIDAD DE PRESTACIÓN DE SERVICIOS Y/O PRODUCTOS DE
                            FACTOTAL.</p>
                        <p style={{textAlign:'left'}}>RESPONSABLE DEL TRATAMIENTO.</p>
                        <p style={{textAlign:'left'}}>LE HACEMOS NOTAR QUE IVÁN AGUILERA R., HA SIDO DESIGNADO COMO LA PERSONA ENCARGADA DE DAR
                            TRÁMITE A LAS SOLICITUDES RELATIVAS A LOS DATOS PERSONALES Y DE FOMENTAR LA PROTECCIÓN DE
                            DATOS PERSONALES, A QUIEN PUEDE UBICAR Y CONTACTAR DIRECTAMENTE EN LA SIGUIENTE CUENTA DE
                            CORREO ELECTRÓNICO: IAGUILERA@FACTOTAL.COM</p>
                        <p style={{textAlign:'left'}}>DATOS PERSONALES SUJETOS A TRATAMIENTO</p>
                        <p style={{textAlign:'left'}}>PARA LLEVAR A CABO LAS FINALIDADES DESCRITAS ANTERIORMENTE, RECABAREMOS PARA SU TRATAMIENTO
                            LOS SIGUIENTES DATOS PERSONALES, DE LOS CUALES USTED ES TITULAR:</p>
                        <ol>
                            <li style={{textAlign:'left'}}>NOMBRE COMPLETO;</li>
                            <li style={{textAlign:'left'}}>CORREO ELECTRÓNICO;</li>
                            <li style={{textAlign:'left'}}>NÚMERO DE TELÉFONO/MÓVIL;</li>
                            <li style={{textAlign:'left'}}>DOMICILIO;</li>
                            <li style={{textAlign:'left'}}>FECHA Y LUGAR DE NACIMIENTO;</li>
                            <li style={{textAlign:'left'}}>NACIONALIDAD;</li>
                            <li style={{textAlign:'left'}}>ESTADO CIVIL;</li>
                            <li style={{textAlign:'left'}}>DATOS DE IDENTIFICACIÓN, CONTACTO Y LABORALES;</li>
                            <li style={{textAlign:'left'}}>DATOS PATRIMONIALES Y/O FINANCIEROS;</li>
                            <li style={{textAlign:'left'}}>DATOS DE DEUDA VIGENTE; Y LOS SIGUIENTES DATOS DE LA EMPRESA, A LA CUAL USTED
                                REPRESENTA: (I) RAZÓN SOCIAL;
                            </li>
                            <li style={{textAlign:'left'}}>DOMICILIO;</li>
                            <li style={{textAlign:'left'}}>DATOS DE IDENTIFICACIÓN Y CONTACTO;</li>
                            <li style={{textAlign:'left'}}>DATOS PATRIMONIALES Y/O FINANCIEROS;</li>
                            <li style={{textAlign:'left'}}>DATOS DE DEUDA VIGENTE;</li>
                            <li style={{textAlign:'left'}}>DATOS SOBRE INGRESOS Y EGRESOS;</li>
                            <li style={{textAlign:'left'}}>DATOS DE LOS LIBROS DE COMPRA Y VENTA (LOS “DATOS PERSONALES”).</li>
                        </ol>

                        <p style={{textAlign:'left'}}>LOS DATOS PERSONALES PODRÁN SER RECABADOS PERSONALMENTE O A TRAVÉS DE NUESTRO SITIO WEB O APP
                            O POR CUALQUIER OTRO MEDIO ELECTRÓNICO, TELEFÓNICO, VIDEO, U OTRA TECNOLOGÍA Y, EN SU CASO,
                            A TRAVÉS DE AGENTES O EJECUTIVOS. LO ANTERIOR PARA QUE SEA POSIBLE DAR SEGUIMIENTO A UNA
                            SOLICITUD DE CREACIÓN DE UN USUARIO REALIZADA POR USTED, BRINDARLE NUESTROS SERVICIOS
                            CONSISTENTES EN EL OTORGAMIENTO DE CRÉDITOS, FACTORING, PRONTO PAGO, LEASING, CONFIRMING U
                            OTROS QUE SE ENCUENTREN PROMOCIONADOS EN EL SITIO, DE CONFORMIDAD CON LOS TÉRMINOS Y
                            CONDICIONES DEL SITIO.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL PODRÁ REALIZAR LOS ANÁLISIS, REPORTES, VIDEOLLAMADAS Y/O FOTOGRAFÍAS, REALIZAR
                            ENTREVISTAS PRESENCIALES O POR MEDIOS REMOTOS, (MEDIOS ELECTRÓNICOS O CUALQUIER OTRA
                            TECNOLOGÍA), ASÍ COMO LAS ACCIONES NECESARIAS PARA COMPROBAR POR SÍ O A TRAVÉS DE TERCEROS
                            CONTRATADOS PARTICULARMENTE PARA ELLO, LA VERACIDAD DE LOS DATOS QUE FUERON PROPORCIONADOS
                            POR USTED.</p>
                        <p style={{textAlign:'left'}}>FACTOTAL NO RECABA DATOS PERSONALES SENSIBLES.</p>

                        <h3>FINALIDADES</h3>
                        <p style={{textAlign:'left'}}>LOS DATOS PERSONALES QUE RECABAMOS SERÁN UTILIZADOS PARA EL CUMPLIMIENTO DE LA RELACIÓN
                            CONTRACTUAL DERIVADO DE LA SOLICITUD Y/O CONTRATACIÓN DE NUESTROS SERVICIOS. VERIFICAR,
                            CONFIRMAR Y VALIDAR SU IDENTIDAD Y DE LA EMPRESA A LA QUE REPRESENTA, ASÍ́ COMO LA VERACIDAD
                            DE LA INFORMACIÓN QUE PROPORCIONA COMO PROPIA, REFERENCIAS, OBLIGADOS SOLIDARIOS, AVALES,
                            SEGÚN RESULTE APLICABLE DE ACUERDO CON LOS SERVICIOS CONTRATADOS CON FACTOTAL. ADMINISTRAR,
                            OPERAR Y DAR SEGUIMIENTO A LOS SERVICIOS Y PRODUCTOS QUE SOLICITA O CONTRATA CON FACTOTAL.
                            REALIZAR EL ANÁLISIS DE LA INFORMACIÓN PROPORCIONADA POR USTED, A FIN DE DETERMINAR CONFORME
                            A LAS DISPOSICIONES LEGALES VIGENTES EL NIVEL DE RIESGO, VIABILIDAD DE PAGO, PERFIL
                            TRANSACCIONAL, Y CUALQUIER OTRA EVALUACIÓN QUE SEA REQUERIDA POR LA NORMATIVA PARA EL
                            OTORGAMIENTO DE SERVICIOS FINANCIEROS. FORMALIZAR LA RELACIÓN JURÍDICA ENTRE FACTOTAL Y EL
                            TITULAR DE LOS DATOS PERSONALES. INCLUIRLO EN NUESTRA BASE DE DATOS DE MANERA DIRECTA O A
                            TRAVÉS DE UN TERCERO. PROCESAR LAS SOLICITUDES QUE REALICE POR MEDIO DE NUESTROS MEDIOS
                            ELECTRÓNICOS. CREAR SU PERFIL COMO USUARIO EN NUESTRA APP. VERIFICACIÓN E INVESTIGACIÓN DE
                            CAPACIDAD CREDITICIA. CONSULTAR Y REALIZAR INVESTIGACIONES CREDITICIAS ANTE SOCIEDADES DE
                            INFORMACIÓN CREDITICIA QUE ESTIME CONVENIENTE. PARA CUMPLIR CON DISPOSICIONES DE
                            CONOCIMIENTO DEL CLIENTE (KNOW YOUR CLIENT O KYC) Y PREVENCIÓN DE LAVADO DE DINERO, YA SEA
                            PARA FACTOTAL O PARA SUS FILIALES O SUBSIDIARIAS, CONFORME LO PREVÉ LA LEGISLACIÓN
                            RESPECTIVA. PARA PROCESOS DE COBRANZA QUE PUDIERAN DERIVAR DE DICHA RELACIÓN JURÍDICA. PARA
                            LLEVAR A CABO ANÁLISIS DE RIESGO Y VIABILIDAD LOS PRODUCTOS Y SERVICIOS OFRECIDOS POR
                            FACTOTAL, Y CUMPLIR CON REGULACIONES, POLÍTICAS Y MEDIDAS DE SEGURIDAD.</p>

                        <h3>FINALIDADES NO NECESARIAS.</h3>
                        <p style={{textAlign:'left'}}>ENVÍO DE OFERTAS, PROMOCIONES, MERCADOTECNIA O EVENTOS ESPECIALES QUE PUEDAN SER DE INTERÉS
                            DEL USUARIO. OFRECER UNA AMPLIACIÓN O MEJORA DE LOS SERVICIOS FINANCIEROS CONTRATADOS.
                            INFORMAR SOBRE ACTUALIZACIONES DE SERVICIOS. LLEVAR A CABO INTELIGENCIA COMERCIAL, REALIZAR
                            INVESTIGACIONES DE MERCADO, DARLE VISIBILIDAD A SU ACTIVIDAD EN NUESTROS MEDIOS
                            ELECTRÓNICOS, SEGUIMIENTO DE CALIDAD DE NUESTROS MEDIOS ELECTRÓNICOS, EVALUACIÓN Y MEJORA DE
                            NUESTROS MEDIOS ELECTRÓNICOS Y ENVIAR NUESTRO BOLETÍN DE NOTICIAS Y OFERTAS. ENVIAR Y
                            RECIBIR COMUNICACIONES A TRAVÉS DE NUESTROS MEDIOS ELECTRÓNICOS RELACIONADO CON NUESTROS
                            SERVICIOS. EL TITULAR DE LOS DATOS PERSONALES PODRÁ́ NEGARSE AL TRATAMIENTO DE SUS DATOS
                            PARA LAS FINALIDADES NO NECESARIAS, ENVIANDO UN CORREO ELECTRÓNICO A LA DIRECCIÓN
                            CONTACTO@FACTOTAL.COM EN EL ENTENDIDO DE QUE, EN CASO DE NO RECIBIR LA SOLICITUD
                            CORRESPONDIENTE, SE ENTENDERÁ́ POR CONSENTIDO EL USO DE SU INFORMACIÓN PARA TODAS LAS
                            FINALIDADES ANTES MENCIONADAS.</p>

                        <h3>TRANSFERENCIA DE DATOS PERSONALES.</h3>
                        <p style={{textAlign:'left'}}>FACTOTAL PODRÁ COMPARTIR SUS DATOS PERSONALES, COMERCIALES, ECONÓMICOS, FINANCIEROS,
                            CONTABLES Y LEGALES CON: (I) SUS SOCIEDADES CONTROLADORAS, SUBSIDIARIAS O AFILIADAS BAJO EL
                            CONTROL COMÚN DE FACTOTAL, ASÍ COMO A SU SOCIEDAD MATRIZ O A CUALQUIER SOCIEDAD DE SU GRUPO
                            CORPORATIVO QUE OPERE BAJO LOS MISMOS PROCESOS Y POLÍTICAS INTERNAS, CON LA FINALIDAD QUE
                            DICHOS RELACIONADOS PUEDAN OFRECER SUS PRODUCTOS Y SERVICIOS; (II) CON AUTORIDADES
                            COMPETENTES, EN CASO DE SER SOLICITADOS, EN TÉRMINOS DE LA LEGISLACIÓN APLICABLE; (III) A
                            SOCIEDADES O COMPAÑÍAS ENCARGADAS DE INVESTIGACIONES DE FRAUDE Y RECOLECCIÓN DE PAGOS; (IV)
                            CON OTRAS EMPRESAS EN CASO DE SER ADQUIRIDOS, FUSIONADOS O CUALQUIER OTRA OPERACIÓN SIMILAR
                            POR ESA COMPAÑÍA; (V) TERCEROS, INCLUIDOS ENTIDADES FINANCIERAS, PARA CESIÓN O DESCUENTO DE
                            CARTERA DE CRÉDITO, ENDOSO O CUALQUIER OTRA FORMA DE NEGOCIACIÓN DE LA CARTERA CREDITICIA;
                            (VI) SOCIOS COMERCIALES CON QUIENES SE TENGAN CELEBRADOS CONTRATOS PARA LA COMERCIALIZACIÓN
                            DE PRODUCTOS Y/O SERVICIOS EN BENEFICIO DE LOS CLIENTES; (VII) TERCEROS PROVEEDORES O
                            PRESTADORES DE SERVICIOS CON LOS QUE FACTOTAL TENGA OBLIGACIONES LEGALES ADQUIRIDAS; (VIII)
                            AUTORIDADES FINANCIERAS Y SOCIEDADES DE INFORMACIÓN CREDITICIA CON LA FINALIDAD DE DAR
                            CUMPLIMIENTO A LAS DISPOSICIONES LEGALES; Y (IX) PROGRAMADORES PARA EL MANTENIMIENTO Y
                            GESTIÓN DE LOS MEDIOS ELECTRÓNICOS.</p>
                        <p style={{textAlign:'left'}}>LOS TERCEROS Y LAS ENTIDADES RECEPTORES DE DATOS PERSONALES ASUMEN LAS MISMAS OBLIGACIONES
                            Y/O RESPONSABILIDADES DE FACTOTAL, DE CONFORMIDAD CON LO DESCRITO EN EL PRESENTE AVISO DE
                            PRIVACIDAD.</p>
                        <p style={{textAlign:'left'}}>EJERCICIO DE DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN (ARCO) Y REVOCACIÓN
                            DEL CONSENTIMIENTO.</p>
                        <p style={{textAlign:'left'}}>EL TITULAR DE LOS DATOS PERSONALES O SU REPRESENTANTE LEGAL TIENE DERECHO A (I) ACCEDER A SUS
                            DATOS PERSONALES EN POSESIÓN DE FACTOTAL Y CONOCER LOS DETALLES DEL TRATAMIENTO DE LOS
                            MISMOS; (II) RECTIFICARLOS EN CASO DE ESTAR DESACTUALIZADOS, SER INEXACTOS O ESTAR
                            INCOMPLETOS; (III) CANCELARLOS CUANDO CONSIDERE QUE NO ESTÁN SIENDO UTILIZADOS CONFORME A
                            LOS PRINCIPIOS, DEBERES Y OBLIGACIONES APLICABLES; Y (IV) OPONERSE AL TRATAMIENTO DE LOS
                            MISMOS PARA FINES ESPECÍFICOS. EL EJERCICIO DE LOS DERECHOS ARCO DEBE HACERSE POR ESCRITO,
                            LA SOLICITUD DEBERÁ SER PRESENTADA EN NUESTRO DOMICILIO DIRIGIDO AL ENCARGADO DE PROTECCIÓN
                            DE DATOS PERSONALES IDENTIFICADO EN ESTE AVISO DE PRIVACIDAD, ACOMPAÑADA DE LA SIGUIENTE
                            INFORMACIÓN Y DOCUMENTACIÓN: (I) DATOS DE IDENTIFICACIÓN Y COPIA DE LA IDENTIFICACIÓN DEL
                            TITULAR DE LOS DATOS PERSONALES Y EN SU CASO, DE SU REPRESENTANTE LEGAL; (II) LA DESCRIPCIÓN
                            DE MANERA CLARA Y PRECISA DE LOS DATOS PERSONALES RESPECTO DE LOS CUALES BUSCA EJERCER LOS
                            DERECHOS ARCO, ASÍ COMO EL DERECHO O DERECHOS QUE DESEA EJERCER; Y (III) DOMICILIO PARA OÍR
                            Y RECIBIR LA CONTESTACIÓN DE FACTOTAL, Y, EN SU CASO, FUTURAS COMUNICACIONES Y/O
                            NOTIFICACIONES, O BIEN SU DESEO DE QUE NUESTRA CONTESTACIÓN Y/O FUTURAS NOTIFICACIONES O
                            CONTESTACIONES SEAN ENVIADAS A TRAVÉS DE CORREO ELECTRÓNICO, INDICANDO LA RESPECTIVA
                            CUENTA.</p>
                        <p style={{textAlign:'left'}}>USTED PODRÁ OBTENER EJERCER SUS DERECHOS ARCO ENVIANDO UN MAIL A CONTACTO@FACTOTAL.COM</p>
                        <p style={{textAlign:'left'}}>LA RESPUESTA A LAS SOLICITUDES QUE CUMPLAN CON LOS REQUISITOS ANTES MENCIONADOS PODRÁ SER
                            NOTIFICADA AL TITULAR O SU REPRESENTANTE LEGAL VÍA CORREO ELECTRÓNICO EN UN PLAZO NO MAYOR A
                            20 (VEINTE) DÍAS HÁBILES CONTADOS A PARTIR DE SU RECEPCIÓN.</p>

                        <h3>COOKIES</h3>
                        <p style={{textAlign:'left'}}>EN FACTOTAL.COM UTILIZAMOS COOKIES PROPIAS Y DE TERCEROS CON EL OBJETIVO MEJORAR NUESTRO
                            SERVICIO Y PROPORCIONARTE UNA MEJOR EXPERIENCIA EN TU NAVEGACIÓN. EN ESTE DOCUMENTO TE
                            CONTAREMOS DE FORMA CLARA Y PRECISA QUÉ SON LAS COOKIES Y CUÁL ES SU FUNCIÓN.</p>
                        <p style={{textAlign:'left'}}>DEBES SABER QUE, SI LO PREFIERES, SIEMPRE PUEDES DESHABILITAR SU FUNCIONAMIENTO O CAMBIAR SU
                            CONFIGURACIÓN.</p>

                        <h3>¿QUÉ SON LAS COOKIES?</h3>
                        <p style={{textAlign:'left'}}>ES UN ARCHIVO QUE CONTIENE PEQUEÑAS CANTIDADES DE DATOS SOBRE LA CONDUCTA DE NAVEGACIÓN QUE
                            SE ENVÍAN DESDE UN SITIO WEB QUE SE VISITA AL DISPOSITIVO QUE SE ESTÁ USANDO PARA NAVEGAR,
                            YA SEA UN COMPUTADOR, UN SMARTPHONE O UNA TABLET. LAS COOKIES SE UTILIZAN PARA ENTREGAR
                            EXPERIENCIAS Y CONTENIDOS PERSONALIZADOS Y DE MEJOR CALIDAD, SEGÚN TUS INTERESES DENTRO Y
                            FUERA DE NUESTRO SITIO.</p>

                        <ul>
                            <li style={{textAlign:'left'}}>COOKIES PROPIAS: SON AQUELLAS QUE SE ENVÍAN A TU EQUIPO DESDE NUESTROS PROPIOS
                                SERVIDORES, EQUIPOS O DOMINIOS, DESDE DONDE PRESTAMOS EL SERVICIO O DISPONEMOS LA
                                INFORMACIÓN QUE NOS SOLICITAS.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES DE TERCEROS: SON AQUELLAS QUE SE ENVÍAN A TU EQUIPO DESDE UN EQUIPO O DOMINIO
                                QUE NO ES GESTIONADO POR NOSOTROS, SINO POR OTRA ENTIDAD COLABORADORA. COMO, POR
                                EJEMPLO, LAS USADAS POR REDES SOCIALES, O POR CONTENIDO EXTERNO COMO GOOGLE MAPS, GOOGLE
                                ANALYTICS.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES DE SESIÓN: SON COOKIES TEMPORALES QUE PERMANECEN EN EL EQUIPO DEL USUARIO HASTA
                                QUE ABANDONAS LA PÁGINA O EL SITIO WEB, POR LO QUE NO QUEDA REGISTRADA EN EL DISCO DURO
                                DE TU ORDENADOR PERMANENTEMENTE. LA INFORMACIÓN OBTENIDA POR MEDIO DE ESTAS COOKIES,
                                SIRVEN PARA ANALIZAR PAUTAS DE TRÁFICO EN LA WEB, LO QUE NOS PERMITE PROPORCIONAR UNA
                                MEJOR EXPERIENCIA PARA MEJORAR EL CONTENIDO Y FACILITAR SU USO.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES PERSISTENTES: SON ALMACENADAS EN EL DISCO DURO Y NUESTRA WEB LAS LEE CADA VEZ
                                QUE REALIZAS UNA NUEVA VISITA. UNA COOKIE PERMANENTE POSEE UNA FECHA DE EXPIRACIÓN
                                DETERMINADA, LA COOKIE DEJARÁ DE FUNCIONAR DESPUÉS DE ESA FECHA. O AL MOMENTO DE BORRAR
                                COOKIES DEL NAVEGADOR. ESTAS COOKIES LAS UTILIZAMOS, GENERALMENTE, PARA FACILITAR LOS
                                SERVICIOS DE COMPRA Y REGISTRO.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES TÉCNICAS: SON AQUELLAS NECESARIAS PARA LA NAVEGACIÓN Y EL BUEN FUNCIONAMIENTO DE
                                NUESTRO SITIO WEB. PERMITEN, POR EJEMPLO, CONTROLAR EL TRÁFICO Y LA COMUNICACIÓN DE
                                DATOS, ACCEDER A PARTES DE ACCESO RESTRINGIDO, REALIZAR EL PROCESO DE COMPRA DE UN
                                PEDIDO, UTILIZAR ELEMENTOS DE SEGURIDAD, ALMACENAR CONTENIDOS PARA PODER DIFUNDIR VÍDEOS
                                O COMPARTIR CONTENIDOS A TRAVÉS DE REDES SOCIALES.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES DE PERSONALIZACIÓN: SON AQUÉLLAS QUE TE PERMITEN ACCEDER AL SERVICIO CON UNAS
                                CARACTERÍSTICAS PREDEFINIDAS, EN FUNCIÓN DE UNA SERIE DE CRITERIOS, COMO POR EJEMPLO EL
                                IDIOMA, EL TIPO DE NAVEGADOR A TRAVÉS DEL CUAL SE ACCEDE AL SERVICIO, LA CONFIGURACIÓN
                                REGIONAL DESDE DONDE SE ACCEDE AL SERVICIO, ETC.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES DE ANÁLISIS: SON AQUÉLLAS QUE NOS PERMITEN CUANTIFICAR EL NÚMERO DE USUARIOS Y
                                ASÍ REALIZAR LA MEDICIÓN Y ANÁLISIS ESTADÍSTICO DE LA UTILIZACIÓN QUE HACEN LOS USUARIOS
                                DE LOS SERVICIOS PRESTADOS. PARA ELLO SE ANALIZA SU NAVEGACIÓN EN NUESTRA PÁGINA WEB CON
                                EL FIN DE MEJORAR LA OFERTA DE PRODUCTOS Y SERVICIOS QUE OFRECEMOS.
                            </li>
                            <li style={{textAlign:'left'}}>COOKIES PUBLICITARIAS: SON AQUÉLLAS QUE PERMITEN LA GESTIÓN, DE LA FORMA MÁS EFICAZ
                                POSIBLE, DE LOS ESPACIOS PUBLICITARIOS QUE SE PUDIERAN INCLUIR EN NUESTRA PÁGINA O SITIO
                                WEB. COOKIES DE PUBLICIDAD COMPORTAMENTAL: ESTAS COOKIES ALMACENAN INFORMACIÓN DEL
                                COMPORTAMIENTO DE LOS USUARIOS OBTENIDA A TRAVÉS DE LA OBSERVACIÓN CONTINUADA. GRACIAS A
                                ELLAS, PODEMOS CONOCER LOS HÁBITOS DE NAVEGACIÓN QUE TIENES EN INTERNET Y MOSTRARTE
                                PUBLICIDAD RELACIONADA CON TU PERFIL DE NAVEGACIÓN.
                            </li>
                        </ul>

                        <h3>INFORMACIÓN REGISTRADA</h3>
                        <p style={{textAlign:'left'}}>EXCLUYENDO LA DIRECCIÓN IP, NUESTRO SITIO NO ALMACENA DATOS PERSONALES MEDIANTE LAS COOKIES,
                            EXCEPTUANDO SE GENERE UN REGISTRO DE USUARIOS EN EL SITIO WEB, PARA ACCEDER A NUEVO
                            CONTENIDO, NOTIFICACIONES E INFORMACIÓN SOBRE NUEVOS PRODUCTOS Y SERVICIOS.</p>
                        <p style={{textAlign:'left'}}>LA DIRECCIÓN IP SÓLO ESTÁ DISPONIBLE PARA LAS PLATAFORMAS DE ANALÍTICA Y MEDIOS, CUYA
                            INFORMACIÓN NO ESTÁ DISPONIBLE PARA LAS PERSONAS QUE MANEJAN LOS DATOS Y NO PUEDE SER
                            DESCARGADA.</p>

                        <h3>DECLARACIÓN</h3>
                        <p style={{textAlign:'left'}}>EN ESTE ACTO Y POR EL PRESENTE, EL USUARIO RECONOCE HABER LEÍDO POR VÍA ELECTRÓNICA, Y
                            COMPRENDIDO, EL CONTENIDO ÍNTEGRO DEL PRESENTE AVISO DE PRIVACIDAD, Y QUE AL PRESIONAR EL
                            BOTÓN DE “REGISTRARME” EN EL SITIO, ACEPTA EXPRESA, INEQUÍVOCA E IRREVOCABLEMENTE LAS
                            ESTIPULACIONES DEL MISMO. EL DOCUMENTO ELECTRÓNICO DE AVISO DE PRIVACIDAD SERÁ ARCHIVADO EN
                            LA BASE DE DATOS DE FACTOTAL Y SERÁ ACCESIBLE AL USUARIO EN SU PERFIL DE USUARIO.</p>

                        <h3>CONSENTIMIENTO</h3>
                        <p style={{textAlign:'left'}}>SE ENTENDERÁ QUE AL UTILIZAR NUESTROS MEDIOS ELECTRÓNICOS Y/O CONTRATAR LOS SERVICIOS QUE
                            OFRECEMOS PARA LAS FINALIDADES AQUÍ PREVISTAS, EXPRESAMENTE RECONOCE Y ACEPTA EL PRESENTE
                            AVISO DE PRIVACIDAD, POR LO QUE DICHO CONSENTIMIENTO NOS OTORGA LA FACULTAD PARA PROCEDER AL
                            TRATAMIENTO DE LOS DATOS PERSONALES EN LA FORMA EN QUE SE SEÑALA EN EL PRESENTE AVISO DE
                            PRIVACIDAD.</p>

                        <h3>MODIFICACIONES AL AVISO DE PRIVACIDAD.</h3>
                        <p style={{textAlign:'left'}}>EL PRESENTE AVISO DE PRIVACIDAD PUEDE SUFRIR MODIFICACIONES, CAMBIOS O ACTUALIZACIONES
                            DERIVADAS DE NUEVOS REQUERIMIENTOS LEGALES; DE NECESIDADES PROPIAS DE FACTOTAL, POR LOS
                            PRODUCTOS O SERVICIOS QUE OFRECEMOS; DE NUESTRAS PRÁCTICAS DE PRIVACIDAD; DE CAMBIOS EN
                            NUESTRO MODELO DE NEGOCIO, O POR OTRAS CAUSAS.</p>

                        <h3>COMPETENCIA</h3>
                        <p style={{textAlign:'left'}}>LA LEGISLACIÓN APLICABLE SERÁ LA DE LA REPÚBLICA DE CHILE.</p>
                        <p style={{textAlign:'left'}}>LA ILEGALIDAD, INVALIDACIÓN Y/O NULIDAD DE ALGUNA O ALGUNAS DE LAS CLÁUSULAS CONTENIDAS EN
                            ESTAS POLÍTICAS GENERALES NO INVALIDARÁ O ANULARÁ LAS DEMÁS.</p>

                    </Typography>
                    <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                            style={{justifyContent: 'center'}}>Cerrar</Button>
                </SweetAlert>
            )
        });
    };
    comunas: Response.ComunaDto[] = this.ComunasArray;
    ComunaArray: Response.ComunaDto[] = this.ComunasArray;

    constructor(props: IProps) {
        super(props);
        this.comunas = this.ComunasArray;
        this.SelectedComuna = {
            cmuCodigo: 0,
            cmuNombre: "",
            cmuDdn: 0,
            ciuCodigo: 0,
            regCodigo: 0,
            cmuCodTesoreria: 0
        };
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        // this.verifyCallback = this.verifyCallback.bind(this);
        this.state = {
            SelectedComuna: {
                cmuCodigo: 0,
                cmuNombre: "",
                cmuDdn: 0,
                ciuCodigo: 0,
                regCodigo: 0,
                cmuCodTesoreria: 0
            },
            SendRegistro: {
                rutEmpresa: "",
                nombreEmpresa: "",
                direccionEmpresa: "",
                emailEmpresa: "",
                comuna: "",
                telefono: "",
                claveSII: "",
                checkTermCond: true,
                checkCaptcha: true
            },
            comunas: [],
            ComunaErrorMessage: '',
            TelefonoErrorMessage: '',
            isValidComuna: false,
            isValidTelefono: false,
            alert: undefined,
            ClaveSII: "",
            onSubmit: undefined,
            ClaveSIIErrorMessage: "",
            NameErrorMessage: "",
            RutCrear: "",
            RutEmpresaErrorMessage: "",
            RutRecuperar: "",
            RutRecuperarErrorMessage: "",
            captchaToken: false,
            checkedG: false,
            codigoCrearClaveErrorMessage: "",
            codigoRecuperar: "",
            companyList: [],
            CheckTermCondErrorMessage: "",
            companyListRender: [],
            isEnlaceRecuperar: false,
            isNuevaContrasena: false,
            isRecuperar: false,
            isSelectCompany: false,
            isValidClave: false,
            isValidClaveDos: false,
            isValidClaveSII: true,
            isValidName: true,
            isValidEmailEmpresa: true,
            EmailEmpresaErrorMessage: '',
            loginData: undefined,
            openModal: false,
            RutEmpresa: '',
            NombreEmpresa: '',
            nuevaClave: '',
            nuevaClaveDos: '',
            isValidRut: true,
            isValidClaveNueva: true,
            isValidRutRecuperar: true,
            isValidcodigoCrearClave: true,
            isValidClaveNuevaDos: true,
            claveNuevaErrorMessage: '',
            claveNuevaDosErrorMessage: '',
            codigoCrearClave: '',
            buttonCheckboxTerms: true,
            correoRecuperacion: '',
            load: false,
            expired: '',
            callback: '',
            recaptchaLoaded: false,
            EmailEmpresa: '',
            Telefono: '',
            CheckTermCond: false,
            loading: false,
            Text: ''
        };
        this.hideAlert = this.hideAlert.bind(this);
        this.showRecuperar = this.showRecuperar.bind(this);
        this.recaptchaRef = React.createRef();

    };

    async componentDidMount() {
        this.comunas = this.ComunaArray;
        this.setState({load: true});

    }

    recaptchaRef = React.createRef();
    handleInputEnter = (e: KeyboardEvent<any>) => {
        if (e.key === 'Enter') this.handleClick(e as any);
    };
    handleClick = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        let _isValidRut = validaRut(this.state.RutEmpresa);
    }
    handleSendRegistro = () => {

        this.setState({
            ClaveSIIErrorMessage: "",
            TelefonoErrorMessage: "",
            RutEmpresaErrorMessage: "",
            EmailEmpresaErrorMessage: "",
            NameErrorMessage: "",
        })

        let SendRegistro = {
            rutEmpresa: this.state.RutEmpresa,
            nombreEmpresa: this.state.NombreEmpresa,
            direccionEmpresa: '',
            emailEmpresa: this.state.EmailEmpresa,
            comuna: this.state.SelectedComuna.cmuCodigo,
            telefono: this.state.Telefono,
            claveSII: this.state.ClaveSII,
            checkTermCond: this.state.CheckTermCond ? this.state.CheckTermCond : false,
            checkCaptcha: true
        };
        if (this.state.CheckTermCond) {
            this.setState({loading: true});
            this.setState({
                ClaveSIIErrorMessage: "",
                TelefonoErrorMessage: "",
                RutEmpresaErrorMessage: "",
                EmailEmpresaErrorMessage: "",
                NameErrorMessage: "",
                CheckTermCondErrorMessage: ""
            });

            SubscribeClient(SendRegistro).then(
                res => {
                    console.log(res);
                    if (res.status === 200 || res.status === 201 || res.status === 204) {
                        if (res.data.existOnSonda && res.data.existOnPortal) {
                            this.setState({
                                loading: false,
                                RutEmpresa: "",
                                NombreEmpresa: "",
                                EmailEmpresa: "",
                                SelectedComuna: {
                                    cmuCodigo: 0,
                                    cmuNombre: "",
                                    cmuDdn: 0,
                                    ciuCodigo: 0,
                                    regCodigo: 0,
                                    cmuCodTesoreria: 0
                                },
                                Telefono: "",
                                ClaveSII: "",
                                CheckTermCond: false,
                                alert: (
                                    <SweetAlert
                                        success
                                        style={{display: 'block', marginTop: '-100px'}}
                                        title={
                                            <Typography variant='h6' align='center' component='p' gutterBottom>
                                                Datos actualizados
                                            </Typography>
                                        }
                                        showConfirm={false}>
                                        <Typography variant='body1' gutterBottom component='p'>
                                            Tus datos han sido actualizados correctamente
                                        </Typography>
                                        <Button variant='contained' className={this.props.classes.button}
                                                onClick={() => this.hideAlertPost()}
                                                style={{justifyContent: 'center'}}>Cerrar</Button>
                                    </SweetAlert>
                                )
                            });
                        } else if (res.data.existOnSonda && !res.data.existOnPortal) {
                            this.setState({
                                loading: false,
                                RutEmpresa: "",
                                NombreEmpresa: "",
                                EmailEmpresa: "",
                                SelectedComuna: {
                                    cmuCodigo: 0,
                                    cmuNombre: "",
                                    cmuDdn: 0,
                                    ciuCodigo: 0,
                                    regCodigo: 0,
                                    cmuCodTesoreria: 0
                                },
                                Telefono: "",
                                ClaveSII: "",
                                CheckTermCond: false,
                                alert: (
                                    <SweetAlert
                                        success
                                        style={{display: 'block', marginTop: '-100px'}}
                                        title={
                                            <Typography variant='h6' align='center' component='p' gutterBottom>
                                                Etapa 1 Lista
                                            </Typography>
                                        }
                                        showConfirm={false}>
                                        <Typography variant='body1' gutterBottom component='p'>
                                            Finaliza tu registro ingresando el código enviado a tu correo.
                                        </Typography>
                                        <Button variant='contained' className={this.props.classes.button}
                                                onClick={() => this.hideAlertPost()}
                                                style={{justifyContent: 'center'}}>Cerrar</Button>
                                    </SweetAlert>
                                )
                            });
                        } else if (!res.data.existOnSonda && res.data.existOnPortal) {
                            this.setState({
                                loading: false,
                                RutEmpresa: "",
                                NombreEmpresa: "",
                                EmailEmpresa: "",
                                SelectedComuna: {
                                    cmuCodigo: 0,
                                    cmuNombre: "",
                                    cmuDdn: 0,
                                    ciuCodigo: 0,
                                    regCodigo: 0,
                                    cmuCodTesoreria: 0
                                },
                                Telefono: "",
                                ClaveSII: "",
                                CheckTermCond: false,
                                alert: (
                                    <SweetAlert
                                        info
                                        style={{display: 'block', marginTop: '-100px'}}
                                        title={
                                            <Typography variant='h6' align='center' component='p' gutterBottom>
                                                Datps Actualizados
                                            </Typography>
                                        }
                                        showConfirm={false}>
                                        <Typography variant='body1' gutterBottom component='p'>
                                            Tus datos han sido actualizados
                                        </Typography>
                                        <Button variant='contained' className={this.props.classes.button}
                                                onClick={() => this.hideAlertPost()}
                                                style={{justifyContent: 'center'}}>Cerrar</Button>
                                    </SweetAlert>
                                )
                            });
                        } else {
                            this.setState({
                                loading: false,
                                RutEmpresa: "",
                                NombreEmpresa: "",
                                EmailEmpresa: "",
                                SelectedComuna: {
                                    cmuCodigo: 0,
                                    cmuNombre: "",
                                    cmuDdn: 0,
                                    ciuCodigo: 0,
                                    regCodigo: 0,
                                    cmuCodTesoreria: 0
                                },
                                Telefono: "",
                                ClaveSII: "",
                                CheckTermCond: false,
                                alert: (
                                    <SweetAlert
                                        success
                                        style={{display: 'block', marginTop: '-100px'}}
                                        title={
                                            <Typography variant='h6' align='center' component='p' gutterBottom>
                                                Etapa 1 Lista
                                            </Typography>
                                        }
                                        showConfirm={false}>
                                        <Typography variant='body1' gutterBottom component='p'>
                                            Finaliza tu registro ingresando el código enviado a tu correo.
                                        </Typography>
                                        <Button variant='contained' className={this.props.classes.button}
                                                onClick={() => this.hideAlertPost()}
                                                style={{justifyContent: 'center'}}>Cerrar</Button>
                                    </SweetAlert>
                                )
                            });
                            //(!res.data.existOnSonda && !res.data.existOnPortal)
                        }
                    } else if (res.status === 400) {
                        if (res.hasOwnProperty('errors')) {
                            if (res.errors.hasOwnProperty('ClaveSII')) {
                                this.setState({ClaveSIIErrorMessage: res.errors.ClaveSII})
                            }
                            if (res.errors.hasOwnProperty('Telefono')) {
                                this.setState({TelefonoErrorMessage: res.errors.Telefono})
                            }
                            if (res.errors.hasOwnProperty('RutEmpresa')) {
                                this.setState({RutEmpresaErrorMessage: res.errors.RutEmpresa})
                            }
                            if (res.errors.hasOwnProperty('EmailEmpresa')) {
                                this.setState({EmailEmpresaErrorMessage: res.errors.EmailEmpresa})
                            }
                            if (res.errors.hasOwnProperty('NombreEmpresa')) {
                                this.setState({NameErrorMessage: res.errors.NombreEmpresa})
                            }
                            this.setState(
                                {loading: false}
                            )
                        } else {
                            this.setState({
                                alert: (
                                    <SweetAlert
                                        error
                                        style={{display: 'block', marginTop: '-100px'}}
                                        title={
                                            <Typography variant='h6' align='center' component='p' gutterBottom>
                                                ¡Error!
                                            </Typography>
                                        }
                                        showConfirm={false}>
                                        <Typography variant='body1' gutterBottom component='p'>
                                            Revise su formulario nuevamente antes de enviar la información nuevamente
                                        </Typography>
                                        <Button variant='contained' className={this.props.classes.button}
                                                onClick={() => this.hideAlert()}
                                                style={{justifyContent: 'center'}}>Cerrar</Button>
                                    </SweetAlert>
                                )
                            });
                            this.setState(
                                {loading: false}
                            )
                        }
                    } else {
                        this.setState({
                            alert: (
                                <SweetAlert
                                    error
                                    style={{display: 'block', marginTop: '-100px'}}
                                    title={
                                        <Typography variant='h6' align='center' component='p' gutterBottom>
                                            ¡Error!
                                        </Typography>
                                    }
                                    showConfirm={false}>
                                    <Typography variant='body1' gutterBottom component='p'>
                                        El rut o clave ingresada son incorrectas.
                                    </Typography>
                                    <Button variant='contained' className={this.props.classes.button}
                                            onClick={() => this.hideAlert()}
                                            style={{justifyContent: 'center'}}>Cerrar</Button>
                                </SweetAlert>
                            )
                        });
                        this.setState(
                            {loading: false}
                        )
                    }
                }
            ).catch(error => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log("errooooooor");
                console.log(error);
                this.setState(
                    {loading: false}
                )
            });
        } else {
            this.setState({CheckTermCondErrorMessage: " Debe Aceptar los términos y condiciones antes de enviar el formulario"})
            this.setState({loading: false});
        }
    };
    onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // @ts-ignore
        this.setState({[e.target.name]: e.target.value});
    };
    onCheckTermAndServ = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        // @ts-ignore
        this.setState({[e.target.name]: !this.state.CheckTermCond});
        if (!this.state.CheckTermCond) {
            this.setState({CheckTermCondErrorMessage: ""})
        }

    };
    hideAlert = () => {

        this.setState({alert: undefined});

    };
    hideAlertPost=()=>{
        this.props.history.push('/');
    }
    onChangeRut = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        // @ts-ignore
        this.setState({RutEmpresa: formatRut(e)});
    };
    onChangeTelefono = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        let value = e.target.value.toString().replaceAll(/[^0-9]/g,"");
        this.setState({Telefono: value});
    }
    showRecuperar() {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{display: 'block', marginTop: '-100px'}}
                    title={
                        <Typography variant='h6' align='center' component='p' gutterBottom>
                            ¡Código de recuperación!
                        </Typography>
                    }
                    showConfirm={false}>
                    <Typography variant='body1' gutterBottom component='p'>
                        Un mensaje con el código ha sido enviado a su correo electrónico edfr........tg@fthnjltda.cl
                        registrado en
                        el sitio.
                    </Typography>
                    <Button variant='contained' className={this.props.classes.button} onClick={() => this.hideAlert()}
                            style={{justifyContent: 'center'}}>Cerrar</Button>
                </SweetAlert>
            ),
            isRecuperar: false
        });
    }
    getOptions = (q: string) => {
        let reg = q.toString().toLowerCase();

        let query = new RegExp(reg);
        return this.comunas.filter(o => query.test(o.cmuNombre.toLowerCase()));
    };
    render() {

        const {classes} = this.props;
        const {openModal, correoRecuperacion} = this.state;
        return (
            <React.Fragment>
                <CssBaseline/>
                {this.state.alert}
                <div
                    className={classes.fullPage}
                    style={{backgroundImage: 'url(' + bgImage + ')'}}
                >

                    <Grid container justify='center' style={{
                        marginBottom: 'auto',
                        marginTop: 'auto',
                        minWidth: this.state.isSelectCompany || this.state.isEnlaceRecuperar || this.state.isNuevaContrasena || this.state.isRecuperar ? '990px' : '700px'
                    }}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Grid container>

                                        {(<Grid item xs={8} md={8} lg={8} className={classes.gridLoginContent} key='SubscribeForm'>
                                                <Paper className={classes.paper}>
                                                    <PersonPin className={classes.icon}/>
                                                    <Typography variant='h3' style={{
                                                        color: '#2E2A25',
                                                        fontSize: '2rem',
                                                        lineHeight: '3.5rem'
                                                    }}>Formulario de Registro</Typography>
                                                    <Typography variant='caption' paragraph style={{
                                                        color: '#555759',
                                                        fontSize: '1rem',
                                                        marginBottom: '0',
                                                        lineHeight: '1.2rem'
                                                    }}>
                                                        Ingrese los datos solicitados para el registro
                                                    </Typography>
                                                </Paper>

                                                    <Grid container spacing={8} alignItems='flex-end'>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel shrink htmlFor='RutEmpresa'>Rut
                                                                    Empresa</InputLabel>
                                                                <Input
                                                                    classes={{
                                                                        // adornedStart: classes.loginFormAdornedStart,
                                                                        focused: classes.SubscribeFormInputFocused
                                                                    }}
                                                                    inputProps={{
                                                                        maxLength: 10,
                                                                        style: {paddingLeft: 10, paddingRight: 10}
                                                                    }}
                                                                    value={this.state.RutEmpresa}
                                                                    error={!this.state.isValidRut}
                                                                    onChange={this.onChangeRut}
                                                                    id='RutEmpresa'
                                                                    name='RutEmpresa'
                                                                    // label="Ingrese su RUT"
                                                                    placeholder='Ingrese el RUT de su Empresa'
                                                                    autoFocus
                                                                    // maxLength={10}
                                                                    startAdornment={<InputAdornment position='start'
                                                                                                    style={{
                                                                                                        paddingTop: 18,
                                                                                                        paddingBottom: 18
                                                                                                    }}><AccountBox
                                                                        className={classes.iconLine}/></InputAdornment>}
                                                                    onKeyPress={this.handleInputEnter}
                                                                />
                                                                <FormHelperText id='component-error-text'
                                                                                error>{this.state.RutEmpresaErrorMessage}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container spacing={8} alignItems='flex-end'>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel shrink htmlFor='NombreEmpresa'>Nombre
                                                                    Empresa</InputLabel>
                                                                <Input
                                                                    inputProps={{
                                                                        style: {paddingLeft: 10, paddingRight: 10}
                                                                    }}
                                                                    value={this.state.NombreEmpresa}
                                                                    error={!this.state.isValidName}
                                                                    onChange={this.onChange}
                                                                    name='NombreEmpresa'
                                                                    type='text'
                                                                    id='NombreEmpresa'
                                                                    // label="Ingrese su contraseña"
                                                                    placeholder='Ingrese el nombre de su empresa'
                                                                    startAdornment={<InputAdornment position='start'
                                                                                                    style={{
                                                                                                        paddingTop: 18,
                                                                                                        paddingBottom: 18
                                                                                                    }}><TextFields
                                                                        className={classes.iconLine}/></InputAdornment>}
                                                                    autoComplete='current-password'
                                                                    onKeyPress={this.handleInputEnter}/>
                                                                <FormHelperText id='component-error-text2'
                                                                                error>{this.state.NameErrorMessage}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid container spacing={8} alignItems='flex-end'>
                                                        <Grid item  xs={12} md={12} lg={12}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel shrink htmlFor='EmailEmpresa'>Email
                                                                    Empresa</InputLabel>
                                                                <Input
                                                                    inputProps={{
                                                                        style: {paddingLeft: 10, paddingRight: 10}
                                                                    }}
                                                                    value={this.state.EmailEmpresa}
                                                                    error={!this.state.isValidEmailEmpresa}
                                                                    onChange={this.onChange}
                                                                    name='EmailEmpresa'
                                                                    type='text'
                                                                    id='EmailEmpresa'
                                                                    // label="Ingrese su contraseña"
                                                                    placeholder='Ingrese el email de su empresa'
                                                                    startAdornment={<InputAdornment position='start'
                                                                                                    style={{
                                                                                                        paddingTop: 18,
                                                                                                        paddingBottom: 18
                                                                                                    }}><TextFields
                                                                        className={classes.iconLine}/></InputAdornment>}
                                                                    autoComplete='current-password'
                                                                    onKeyPress={this.handleInputEnter}/>
                                                                <FormHelperText id='component-error-text3'
                                                                                error>{this.state.EmailEmpresaErrorMessage}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid container spacing={8} alignItems='flex-end'>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel shrink htmlFor='ClaveSII'>Clave
                                                                    SII</InputLabel>
                                                                <Input
                                                                    error={!this.state.isValidClaveSII}
                                                                    value={this.state.ClaveSII}
                                                                    onChange={this.onChange}
                                                                    name='ClaveSII'
                                                                    type='Password'
                                                                    id='ClaveSII'
                                                                    // label="Ingrese su contraseña"
                                                                    placeholder='Ingrese la Clave de ingreso a SII de su empresa'
                                                                    startAdornment={<InputAdornment position='start'
                                                                                                    style={{
                                                                                                        paddingTop: 18,
                                                                                                        paddingBottom: 18
                                                                                                    }}><Lock
                                                                        className={classes.iconLine}/></InputAdornment>}
                                                                    autoComplete='current-password'
                                                                    onKeyPress={this.handleInputEnter}/>
                                                                <FormHelperText id='component-error-text4'
                                                                                error>{this.state.ClaveSIIErrorMessage}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container spacing={8} alignItems='flex-end'>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <FormControl required fullWidth>

                                                                <Autocomplete
                                                                    label={"Comuna"}
                                                                    onChange={this.setText}
                                                                    value={this.state.SelectedComuna}
                                                                    valueToKey= {(o)=>o.cmuCodigo}
                                                                    valueToDisplay = {(o)=>o.cmuNombre}
                                                                    selectOnBlur={false}
                                                                    requireMatch
                                                                    inputProps={{
                                                                        id:"AutoCompleteComuna"
                                                                    }}

                                                                    getOptions={this.getOptions}
                                                                    optionToDisplay={(o)=> o.cmuNombre}
                                                                    optionToKey={(o)=> o.cmuCodigo}
                                                                    query={this.state.Text}
                                                                />
                                                                <FormHelperText id='component-error-text5'
                                                                                error>{this.state.ComunaErrorMessage}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>


                                                    <Grid container spacing={8} alignItems='flex-end'>
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            <FormControl required fullWidth>
                                                                <InputLabel shrink
                                                                            htmlFor='Telefono'>Teléfono</InputLabel>
                                                                <Input
                                                                    error={!this.state.isValidClaveSII}
                                                                    onChange={this.onChangeTelefono}
                                                                    name='Telefono'
                                                                    id='Telefono'
                                                                    inputProps={{
                                                                        pattern: '[0-9]+',
                                                                        maxLength: '9',
                                                                        min:0,
                                                                        max: 999999999
                                                                    }}
                                                                    value={this.state.Telefono}
                                                                    // label="Ingrese su contraseña"
                                                                    placeholder='Ingrese el teléfono de la empresa'
                                                                    startAdornment={<InputAdornment position='start'
                                                                                                    style={{
                                                                                                        paddingTop: 18,
                                                                                                        paddingBottom: 18
                                                                                                    }}><Phone
                                                                        className={classes.iconLine}/></InputAdornment>}
                                                                    autoComplete='current-password'/>
                                                                <FormHelperText id='component-error-text6'
                                                                                error>{this.state.TelefonoErrorMessage}</FormHelperText>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>


                                                <Grid container spacing={8} alignItems='flex-end'
                                                      className={classes.CheckLabel1} >
                                                    <Grid item xs={12} md={12} lg={12}>
                                                        <FormControlLabel
                                                            label={ <Button
                                                                size='small'
                                                                variant='text'
                                                                disableRipple={true}
                                                                classes={{
                                                                    root: classes.buttonSubscriberRoot
                                                                }}
                                                                onClick={this.handleTerminosYCondiciones}>
                                                                Términos y condiciones
                                                            </Button>}
                                                            control={<Checkbox
                                                                name='CheckTermCond'
                                                                id='CheckTermCond'
                                                                inputProps={{
                                                                    style: {paddingLeft: 5, paddingRight: 5}
                                                                }}
                                                                onChange={this.onCheckTermAndServ}
                                                            />
                                                            }
                                                        />
                                                        <FormHelperText id='component-error-text7'
                                                                        error>{this.state.CheckTermCondErrorMessage}</FormHelperText>

                                                    </Grid>
                                                </Grid>


                                                    <Grid container spacing={8} alignItems='flex-start'
                                                          className={classes.CheckLabel2} >
                                                        <Grid item xs={12} md={12} lg={12}>
                                                            {this.state.loading ? <LoaderComp >Generando solicitud, <br></br> Esto puede tardar un momento</LoaderComp>:<div></div>}

                                                            {!this.state.loading?<ReCAPTCHA
                                                                ref={(el) => {
                                                                    this.captchaDemo = el;
                                                                }}
                                                                size='normal'
                                                                data-theme='dark'
                                                                // render="explicit"
                                                                sitekey='6Lc-NQwqAAAAALMTOAVeDyAveFqKccgb4xzNJ8rV'
                                                                onChange={this.onLoadRecaptcha}
                                                                hl={'es'}
                                                            />:<div></div>}
                                                        </Grid>
                                                    </Grid>

                                            {!this.state.loading?<Button variant='contained' className={classes.button}
                                                        onClick={this.handleSendRegistro}>Registrar</Button>:<div></div>}
                                            </Grid>
                                        )}
                                        <Grid
                                            item
                                            xs={4} md={4} lg={4}
                                            className={classes.gridLoginImage2}
                                            style={{}}
                                        >
                                            <img className={classes.media} src={logo} alt='...'/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <footer>
                        <Typography variant='subtitle1' align='center' component='p'
                                    className={classes.textoFooterLogin}>
                            {'. Todos los derechos reservados a '}<a
                            href='https://www.factotal.com'>{'@ '}{new Date().getFullYear()}{' Factotal S.A.'}</a>
                        </Typography>
                    </footer>
                </div>
            </React.Fragment>
        );
    }

}

export default withStyles(SubscriberStyle)(withRouter(Subscriber));
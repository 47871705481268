// External
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { saveInformacionComercial } from './actions';

// componentes material ui
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import { validateNumerico } from '../../../helpers/ValidateNumeric';

// componentes custom
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import ModalComponent from '../../shared/ModalMsg/ModalMsg';

//estilos
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { Theme } from '@material-ui/core';
import { WithStyles } from '@material-ui/core/styles/withStyles';

const styleMisDatos = (theme: Theme) => ({
  container: {},
  TextField: {}
});

interface IProps extends WithStyles<typeof styleMisDatos> {
}


interface IState {
  user: Comun.Dto.LoginUserInfo;
  openModal: boolean;
}

class MisDatos extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    let user = JSON.parse(sessionStorage.getItem('user') || '');
    this.state = {
      user: user,
      openModal: false
    };
  }

  guardar = () => {

    // const direccionComercial = document.getElementById('direccion_comercial')?.value;
    // const telefonoComercial = document.getElementById('telefono_comercial')?.value;
    // const celularUsuario = document.getElementById('celular')?.value;
    //
    // const paramsObject = {
    //   address: direccionComercial,
    //   enterprisePhone: telefonoComercial,
    //   phone: celularUsuario
    // };
    //
    // saveInformacionComercial(paramsObject)
    //   .then(isSave => {
    //     if (isSave) this.setState({ openModal: true });
    //   });

  };

  render() {
    const { classes } = this.props;
    const { openModal } = this.state;
    return (
      <div>
        <ModalComponent
          openModal={openModal}
          title='¡Datos guardados con éxito!'
          text=''
          closeModalMsg={() => {
            this.setState({ openModal: false });
          }} />
        <Breadcrumb category='Usted se encuentra en: Administración / Mis datos' textAlign='right' />
        <form className={classes.container} noValidate autoComplete='off'>
          <Grid container spacing={16} direction='row' alignItems='flex-start' justify='flex-start'>
            <Grid item md={12}>
              <Typography variant='h5'>
                Mis Datos
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title='Datos empresa'
                />
                <CardContent>
                  <Grid container>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        disabled
                        className={classes.TextField}
                        id='nombre'
                        label='Nombre'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        value={this.state.user.empresas[0].socialReason}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        disabled
                        className={classes.TextField}
                        id='nombre'
                        label='Razón Social'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        value={this.state.user.empresas[0].socialReason}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        disabled
                        className={classes.TextField}
                        id='nombre'
                        label='Rut'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        value={this.state.user.empresas[0].rut.toString() + '-' + this.state.user.empresas[0].dv}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardHeader
                  title='Datos usuario'
                />
                <CardContent>
                  <Grid container>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        disabled
                        className={classes.TextField}
                        id='nombre'
                        label='Nombre'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        value={this.state.user.userName}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        disabled
                        className={classes.TextField}
                        id='nombre'
                        label='Rut'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        value={this.state.user.userRut}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        disabled
                        className={classes.TextField}
                        id='nombre'
                        label='Email'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        value={this.state.user.userMail}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Grid container>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        className={classes.TextField}
                        id='direccion_comercial'
                        label='Dirección comercial'
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 100 }}
                        style={{ width: '100%' }}
                        defaultValue={this.state.user.empresas[0].address}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        className={classes.TextField}
                        id='telefono_comercial'
                        label='Teléfono comercial'
                        margin='normal'
                        onChange={validateNumerico}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                        defaultValue={this.state.user.empresas[0].phone}
                      />
                    </Grid>
                    <Grid item xs={4} style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                      <TextField
                        className={classes.TextField}
                        id='celular'
                        label='Celular usuario'
                        margin='normal'
                        onChange={validateNumerico}
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ maxLength: 12 }}
                        style={{ width: '100%' }}
                        defaultValue={this.state.user.userPhone}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Grid container justify='flex-end'>
                    <Grid item xs={1}>
                      <Button
                        variant='outlined'
                        color='primary'
                        style={{ width: '90%' }}>
                        CANCELAR
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        variant='contained'
                        color='primary'
                        style={{ width: '90%', padding: 0, marginLeft: '10%' }}
                        onClick={this.guardar}
                      >
                        CONFIRMAR
                      </Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </form>
        <div>&nbsp;</div>
        <Card style={{ background: 'rgb(239, 240, 240)' }}>
          <Grid container>
            <Grid item lg={9} xs={12}>
              <CardContent>
                <Grid container>
                  <Grid item xs={1} lg={1}>
                    <InfoIcon color='primary' fontSize='large'></InfoIcon>
                  </Grid>
                  <Grid item xs={6} lg={8}>
                    <Typography variant='h2' color='primary' style={{ paddingTop: '7px' }}>
                      <b>IMPORTANTE</b>
                    </Typography>
                    <p>Se debe recordar que los datos se encuentran asociados al contrato firmado.</p>
                  </Grid>
                </Grid>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.main.isLoading
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({
  dispatch: (action: any) => {
    dispatch(action);
  }
});

export default withStyles(styleMisDatos)(connect(mapStateToProps, mapDispatchToProps)(MisDatos));

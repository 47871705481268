import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useState } from 'react';
import dialogStyle from '../../../styles/jss/components/dialogStyle';
import DialogTitle from '../../sharedv2/DialogTitle/DialogTitle';
import { invertirFecha } from '../../../helpers/FormatDate';

interface IProps extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  onSave: (request: Request.RegistroCivil.NuevoContrato) => any;
  open: boolean;
  creandoContrato: boolean;
}

const NuevoContrato: FC<IProps> = (props) => {
  const { classes, onClose, onSave, open, creandoContrato } = props;
  const [numero, setNumero] = useState('');
  const [monto, setMonto] = useState('');
  const [fecha, setFecha] = useState('');

  const saveContrato = () => {
    const request: Request.RegistroCivil.NuevoContrato = {
      fecha: invertirFecha(fecha),
      monto: Number(monto),
      numero: numero
    };
    onSave(request);
  };

  return (
    <Dialog
      onClose={onClose}
      className={classes.root}
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <div className={classes.containerWhite}>
        <DialogTitle onClose={onClose} classes={{ root: classes.dialogTitle }}>
          Nuevo Contrato
        </DialogTitle>
        <DialogContent
          classes={{
            root: classes.dialogContent
          }}
        >
          <div className={classes.formulario}>
            <div className={classes.fluidInputContainer}>
              <div className={classes.inputContainer}>
                <div className={classes.labelSelectModal}>Número Contrato</div>
                <TextField
                  id='numero'
                  className={[classes.textField, classes.textFieldMargin].join(' ')}
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  margin='normal'
                  variant='outlined'
                  placeholder='123123'
                />
              </div>
              <div className={classes.inputContainer}>
                <div className={classes.labelSelectModal}>Fecha Contrato</div>
                <TextField
                  id='fecha'
                  className={[classes.textField, classes.textFieldMargin].join(' ')}
                  value={fecha}
                  onChange={(e) => setFecha(e.target.value)}
                  margin='normal'
                  variant='outlined'
                  placeholder='12-01-2020'
                />
              </div>
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.labelSelectModal}>Monto</div>
              <TextField
                id='monto'
                className={[classes.textField, classes.textFieldMargin].join(' ')}
                value={monto}
                onChange={(e) => setMonto(e.target.value)}
                margin='normal'
                variant='outlined'
                placeholder='12000000'
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='outlined' color='primary' disabled={creandoContrato} className={classes.greenButton} onClick={saveContrato}>
            Crear
          </Button>
          <Button variant='outlined' className={classes.greyButton} disabled={creandoContrato} onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(dialogStyle)(NuevoContrato);

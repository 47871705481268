import leasingApi from '../../services/leasingApi';
import { asyncActionType } from '../../helpers/ActionsHelper';
import { Dispatch } from 'redux';
import { ActionT } from '../../config/rootReducer';

export interface IFetchBienesSuccess {
  bienes: Dto.Leasing.BienesRow[];
  paginacion: Dto.Paginacion;
}

export interface IFetchContratosSuccess {
  contratos: Dto.Leasing.ContratosVigentesRow[];
  paginacion: Dto.Paginacion;
}

export interface IFetchPending {
  tamanoPagina: number;
}

export const fetchBienesAction = asyncActionType('@@BIENES_LEASING', 'FETCH');

const _obtenerBienesLeasingThunk = (filters: Request.Leasing.Bienes) => (dispatch: Dispatch): Promise<void> => {
  dispatch(obtenerBienesLeasing.request(filters));
  return leasingApi.getBienes(filters)
                   .then((response) => dispatch(obtenerBienesLeasing.succeed(response)))
                   .catch(error => error);
};

export const obtenerBienesLeasing = Object.assign(_obtenerBienesLeasingThunk, {
  request: (request: Request.Leasing.Bienes): ActionT<IFetchPending> => ({
    type: fetchBienesAction.PENDING,
    payload: {
      tamanoPagina: request.tamanoPagina
    }
  }),
  succeed: (response: Response.Leasing.Bienes): ActionT<IFetchBienesSuccess> => ({
    type: fetchBienesAction.SUCCESS,
    payload: {
      bienes: response.data,
      paginacion: {
        ...response.paginacion,
        numeroPagina: response.paginacion.numeroPagina - 1
      }
    }
  })
});

export const fetchContratosAction = asyncActionType('@@CONTRATO_LEASING', 'FETCH');

const _obtenerContratosLeasingThunk = (filters: Request.Leasing.ContratosVigentes) => (dispatch: Dispatch): Promise<void> => {
  dispatch(obtenerContratosLeasing.request(filters));
  return leasingApi.getContratos(filters)
                   .then((response) => dispatch(obtenerContratosLeasing.succeed(response)))
                   .catch(error => error);
};

export const obtenerContratosLeasing = Object.assign(_obtenerContratosLeasingThunk, {
  request: (request: Request.Leasing.ContratosVigentes): ActionT<IFetchPending> => ({
    type: fetchContratosAction.PENDING,
    payload: {
      tamanoPagina: request.tamanoPagina
    }
  }),
  succeed: (response: Response.Leasing.ContratosVigentes): ActionT<IFetchContratosSuccess> => ({
    type: fetchContratosAction.SUCCESS,
    payload: {
      contratos: response.data,
      paginacion: {
        ...response.paginacion,
        numeroPagina: response.paginacion.numeroPagina - 1,
        totalPagina: response.paginacion.totalPagina
      }
    }
  })
});


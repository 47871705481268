import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DialogActions from '@material-ui/core/DialogActions';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface IEliminarCertificadoModalProps extends WithStyles<typeof SAcceptYieldModal>, RouteComponentProps {
  onDelete : (id : number) => void
  onClose : () => void
  open : boolean
  certificado : Core.Dto.Certificado
}

class EliminarCertificadoModal extends React.Component<IEliminarCertificadoModalProps> {
  constructor(props : IEliminarCertificadoModalProps) {
    super(props);

  }


  render() {
    const { classes, onClose, open, certificado, onDelete } = this.props;

    return (
      <Dialog onClose={onClose} className={classes.rootDeleteModal} open={open}
              PaperProps={{ className: [classes.dialogPaper, classes.dialogPaperDelete].join(' ') }}>
        <div className={[classes.checkIcon, classes.chekIconDelete].join(' ')}><DeleteOutlinedIcon />
        </div>
        <h1>Desea eliminar este certificado digital</h1>
        <p className={classes.messageDelete}>¿Está seguro que desea <span className="red">eliminar</span> el certificado
          digital para <span>{certificado.nombre}</span>? </p>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button variant="outlined" onClick={() => onDelete(certificado.id)} className={classes.redButton}>
            Eliminar
          </Button>
          <Button variant="outlined" className={classes.greyButton} onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(withRouter(EliminarCertificadoModal));

import React from 'react';

export const formatRut = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  let valor = e.target.value.replace('.', '')
               .replace('-', '')
               .replace(/[^0-9(k|K)]+/gi, '')
               .replace('(', '')
               .replace(')', '')
               .replace('|', '');
  return e.target.value = valor.length > 0 ? (valor.slice(0, -1) + '-' + valor.slice(-1)
                                                                              .toUpperCase()) : ('');
};

export const funcDV = (T : number) => {
  let M = 0, S = 1;
  for (; T; T = Math.floor(T / 10))
    S = (S + T % 10 * (9 - M++ % 6)) % 11;
  return S ? S - 1 : '10';
};

export const validaRut = (rutCompleto : string) => {

  rutCompleto = rutCompleto.replace('‐', '-')
                           .replace('K', 'k');

  if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
    return false;
  let tmp = rutCompleto.split('-');
  let digv = tmp[1].replace('k', '10');
  let rut = tmp[0];

  return (Number(funcDV(Number(rut))) === Number(digv));
};

export const validaCaracterRut = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
  if (isNaN(Number(e.target.value))) {
    if (e.target.value.indexOf('k') === -1 && e.target.value.indexOf('K') === -1)
      e.target.value = '';
  }
};

import { Theme } from '@material-ui/core';
import scssTool from '../../styles/scssTool';

const style = (_theme : Theme) => ({
  space: {
    margin: '0px 8px'
  },
  afterHeader: {
    height: '50px',
    ...scssTool.flex('center', 'row', 'flex-start')
  },
  greenButton: {
    background: '#82BC00',
    borderRadius: '4px',
    border: '1px solid #82BC00',
    color: '#fff',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      color: '#fff'
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)'
    }
  },
  redButton: {
    background: '#fff',
    border: '1px solid #CD2F2F',
    borderRadius: '5px',
    color: '#CD2F2F',
    width: 131,
    height: 45,
    '&:hover': {
      background: '#CD2F2F',
      color: '#fff'
    }
  },
  greyButton: {
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid #82BC00',
    color: '#82BC00',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      color: '#82BC00'
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)'
    }
  },
  titleSecciones: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#102333'
  },
  greySeccion: {
    background: '#EFF0F0',
    borderRadius: '6px',
    padding: '16px',
    margin: '16px 0px'
  },
  seccionHeader: {
    height: '50px',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between'
  },
  leftSeccionHeader: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    flexGrow: 1
  },
  titleSeccionHeader: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '22px',
    color: '#000000'
  },
  subtSeccionHeader: {
    fontSize: '15px',
    lineHeight: '22px',
    color: 'rgba(0, 0, 0, 0.4)',
    marginTop: '4px'
  },
  bagdetContainer: {
    background: 'rgba(0, 0, 0, 0.04)',
    border: '1px solid rgba(0, 0, 0, 0.24)',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '24px',
    padding: '4px 16px',
    margin: '0px 16px',
    '& span': {
      color: 'rgba(0, 0, 0, 0.5)',
      fontSize: '12px',
      lineHeight: '20px'
    }
  },
  containerInputToRight: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    width: '70%'
  },
  containerInputFull: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    width: '100%'
  },
  inputContainer: {
    width: '70%',
    marginBottom: '32px'
  },
  labelnputField: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#3C3C3C',
    textTransform: 'uppercase' as 'uppercase'
  },
  labelSelectModal: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#3C3C3C'
  },
  textField: {
    width: '95%',
    minWidth: '310px',
    margin: '8px 0px 0px 0px',
    '& input': {
      boxSizing: 'border-box' as 'border-box',
      borderRadius: '5px',
      background: '#FFFFFF',
      borderColor: '#95989A'
    }
  },
  selectField: {
    border: '1px solid #95989A',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '5px',
    background: '#FFFFFF',
    borderBottom: '1px solid #95989A',
    height: '55px',
    width: '95%',
    marginTop: '8px',
    '&::before': {
      borderBottom: 'none'
    },
    '& .underline': {
      borderBottom: 'none'
    }
  },
  middleSelect: {
    maxWidth: '250px',
    minWidth: 'inherit',
    width: '85%'
  },
  litleSelect: {
    maxWidth: '110px',
    minWidth: 'inherit',
    width: '85%'
  },
  litleInput: {
    maxWidth: '110px',
    minWidth: 'inherit',
    width: '85%'
  },
  midleInput: {
    maxWidth: '250px',
    minWidth: 'inherit',
    width: '85%'
  },
  outlinesSelect: {
    padding: '13.5px 14px',
    height: '55px',
    boxSizing: 'border-box' as 'border-box',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  containerSeccion: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    marginTop: '32px'
  },
  openButton: {
    cursor: 'pointer' as 'pointer'
  },
  outlinedButton: {
    marginLeft: '14px',
    width: '130px',
    textTransform: 'capitalize' as 'capitalize',
    marginBottom: '8px',
    alignSelf: 'flex-end' as 'flex-end'
  },
  marginTable: {
    marginBottom: '16px'
  },
  titleInformacion: {
    fontSize: '18px',
    lineHeight: '21px',
    color: '#6E6E6E',
    margin: '24px 0px 32px 0px'
  },
  btnCloseContainer: {
    position: 'absolute' as 'absolute',
    right: '22px',
    top: '16px',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  buttonContainer: {
    ...scssTool.flex('center', 'row', 'flex-end'),
    width: '100%',
  },
  backButton: {
    ...scssTool.font(16, 400, 17),
    '& svg': {
      color: '#2E2A25',
      width: '21px',
      height: '21px',
      marginRight: '12px'
    }
  },
  inputError: {
    "&:invalid": {
      border: "red solid 2px"
    }
  }
});

export default style;

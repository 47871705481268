import React, { ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import '../../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import Header from '../../../sharedv2/Header/Header';
import Loader from '../../../shared/Loader/Loader';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableCertificados from './TableCertificados';
import AgregarCertificadoModal from './AgregarCertificado';
import EliminarCertificadoModal from './EliminarCertificado';
import { connect } from 'react-redux';
import fxActions from '../actions';
import actions from '../../../main/reducer';
import { AppState } from '../../../../config/rootReducer';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { ThunkDispatch } from 'redux-thunk';
import { esEjecutivoAsistente } from '../../../../helpers/UserHelper';

interface ICertificadosProps extends WithStyles<typeof HistoricoStyle> {
  eliminarCertificado: (id: number) => Promise<Mensaje.EliminarCertificadoRespuesta>;
  actualizarCertificado: (payload: Payloads.ActualizarCertificadoPayload) => Promise<Mensaje.GuardarCertificadoRespuesta>;
  notificacion: (exito: boolean, mensaje: string) => void;
  certificados: Core.Dto.Certificado[];
  esEjecutivo: boolean;
}

interface IState {
  loading: boolean;
  activeTab: number;
  agregarCertificadoModal: boolean;
  eliminarModal: boolean;
  certificadoSelected: Core.Dto.Certificado;
}

class Certificados extends React.Component<ICertificadosProps, IState> {

  constructor(props: ICertificadosProps) {
    super(props);
    this.state = {
      loading: true,
      activeTab: 0,
      agregarCertificadoModal: false,
      eliminarModal: false,
      certificadoSelected: {} as Core.Dto.Certificado
    };
  }

  componentDidMount() {
    this.setState({
      loading: false
    });
  }

  openModalAgregarCertificado = () => {
    if (this.props.esEjecutivo) return;
    this.setState((state: IState) => ({
      agregarCertificadoModal: !state.agregarCertificadoModal
    }));
  };

  closeCertificado = () => {
    this.setState((state: IState) => ({
      agregarCertificadoModal: !state.agregarCertificadoModal
    }));
  };

  saveCertificado = () => {
    this.props.notificacion(true, 'El certificado digital fue agregado exitosamente.');
    this.setState((state: IState) => ({
      agregarCertificadoModal: !state.agregarCertificadoModal
    }));
  };

  openEliminar = (certificado: Core.Dto.Certificado) => {
    if (this.props.esEjecutivo) return;
    this.setState((state: IState) => ({
      eliminarModal: true,
      certificadoSelected: certificado
    }));
  };

  closeEliminar = () => {
    this.setState((state: IState) => ({
      eliminarModal: false
    }));
  };

  onDeleteCertificado = (idCertificado: number) => {
    this.props.eliminarCertificado(idCertificado)
        .then((r: Mensaje.EliminarCertificadoRespuesta) => {
          if (r.exito) {
            this.closeEliminar();
            this.props.notificacion(true, 'El certificado digital fue eliminado exitosamente.');
          }
        });
  };

  handleChange = (certificado: Core.Dto.Certificado, ceder: boolean, contrato: boolean) => {
    if (this.props.esEjecutivo) return;
    const payload: Payloads.ActualizarCertificadoPayload = {
      idCertificado: Number(certificado.id),
      paraCeder: ceder,
      paraContrato: contrato
    };
    this.props.actualizarCertificado(payload)
        .then((r: Mensaje.GuardarCertificadoRespuesta) => {
          if (r.exito) {
            this.props.notificacion(true, 'El certificado digital fue actualizado exitosamente.');
          }
        });
  };

  render() {
    const { classes, certificados, esEjecutivo } = this.props;
    const { activeTab } = this.state;
    return (
      <div className='container'>
        {this.state.loading || certificados == null ? <Loader /> :
          <React.Fragment>
            <Header title='Certificados' breadcrum='Usted se encuentra en: Administración / Certificados'>
              <Button variant='contained' color='primary' disabled={esEjecutivo}
                      onClick={this.openModalAgregarCertificado}>Agregar</Button>
            </Header>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`tabs ${classes.tabsBackground}`}>
                  <Tabs value={activeTab} classes={{ indicator: 'indicator' }}>
                    <Tab label='CERTIFICADOS' classes={{ root: 'tabs-factotal' }} />
                  </Tabs>
                  <TableCertificados data={certificados}
                                     esEjecutivo={esEjecutivo}
                                     onDelete={this.openEliminar}
                                     handleChange={this.handleChange} />
                </div>
              </Grid>
            </Grid>
            <AgregarCertificadoModal
              open={this.state.agregarCertificadoModal}
              onClose={this.closeCertificado}
              onSave={this.saveCertificado}
            />
            <EliminarCertificadoModal
              open={this.state.eliminarModal}
              onClose={this.closeEliminar}
              onDelete={this.onDeleteCertificado}
              certificado={this.state.certificadoSelected}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  certificados: state.factorx.empresa.certificados,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  eliminarCertificado: (id: number) => dispatch(fxActions.eliminarCertificado(id)),
  actualizarCertificado: (payload: Payloads.ActualizarCertificadoPayload) => dispatch(fxActions.actualizarCertificado(payload)),
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(Certificados));

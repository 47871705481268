// External
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { FilterFacEmitidasFactoring, GetPDF, GetIndividualPDF, GetExcel, EventTrackingExcel,TrackingSidebar } from "./actions";
import xlsx from "xlsx";
import moment from 'moment'
import 'moment/locale/es';
import ReactTable from "react-table";
import Moment from "moment/moment";

// componentes material ui
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import FormLabel from '@material-ui/core/FormLabel';
import Typography from "@material-ui/core/Typography";
import CardMedia from '@material-ui/core/CardMedia';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Search";

// componentes custom
import Breadcrumb from "../../shared/Breadcrumb/Breadcrumb";
import GridItem from "../../shared/Grid/GridItem";
import GridContainer from "../../shared/Grid/GridContainer";
import SVGIcons from "../../shared/SVGIcons/SVGIcons";
import NavPills from "../../shared/NavPills/NavPills";
import RangeCalendar from "../../shared/RangeCalendar/RangeCalendar";
import PaginationBase from "../../shared/Pagination/PaginationBase";
import Loader from "../../shared/Loader/Loader"
import { formatNumber } from "../../../helpers/FormatNumber";
import { validateNumerico, onChangeRangNumericHelper } from "../../../helpers/ValidateNumeric";

// Importación de estilo para tabla
import HistoricoStyle from "../../../styles/jss/components/extendedFormsStyle";

class FacturasEmitidas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      statusAction: false,
      isPageOpSelected: false,
      pageAnteriorVisitada: 0,
      pageOpSelected: 0,
      pageActual: 0,
      idSeleccion: null,
      expanded: true,
      loading: true,
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      data: props.rows,
      dataFactura: props.rows,
      dataPagare: props.rows,
      dataLetra: props.rows,
      dataCheque: props.rows,
      tipoDocumento: '',
      activeTab: 1,
      urlImg: null,
      pdfFile: null,
      openCalendar: false,
      anchorElCalendar: null,
      numeroFactura: '',
      numeroFacturaDesde: '',
      numeroFacturaHasta: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      fechaDesde: moment(new Date()).format("DD/MM/YYYY"),
      fechaVencimientoHasta: moment(new Date()).format("YYYY-MM-DD"),
      montoDesde: '',
      montoHasta: '',
    };
    this.fetchData = this.fetchData.bind(this);
  }

  onChangeTab = (active) => this.setState((state) => ({ activeTab: active }));

  getFilters(page, pageSize, tipoDocumento, sorted) {
    return {
      nroFacturaDesde: this.state.numeroFacturaDesde,
      nroFacturaHasta: this.state.numeroFacturaHasta,
      fechaDesde: (this.state.calendarValue.desde) ? Moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      fechaHasta: (this.state.calendarValue.hasta) ? Moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      montoDesde: this.state.montoDesde,
      montoHasta: this.state.montoHasta,
      page: page,
      pageSize: pageSize,
      tipoDocumento: tipoDocumento,
      ascending: sorted && sorted[0] && sorted[0].desc ? false : sorted && sorted[0] ? true : null,
      orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : ""
    };
  }

  getSortIdColumn(column) {
    let _id = null;
    switch (column) {
      case "TipoDocumento":
        _id = 1;
        break;
      case "numeroFactura":
        _id = 2;
        break;
      case "fecha":
        _id = 3;
        break;
      case "montoTotal":
        _id = 4;
        break;
    }
    return _id
  }

  _loading = (b, t) => {
    let loadingTable = "loading" + t;
    let _obj = {}
    _obj[loadingTable] = b;
    return _obj;
  }

  fetchData(state, tipoDocumento, dataProp, dataPagination) {
    let _obj = {};
    _obj[dataProp] = [];
    _obj[dataPagination] = {
      currentPage: 0,
      top: 0,
      totalItems: 0,
      totalPages: 0,
      from: 0,
      to: 0,
    };
    this.setState({
      ...this._loading(true, tipoDocumento),
      ...{ statusAction: true },
      ..._obj
    })
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(FilterFacEmitidasFactoring(this.getFilters(state.page, state.pageSize, tipoDocumento, state.sorted)))])
    )
      .then((data) => {
        let _obj = {};
        if (data[0] && data[0].rows && data[0].pagination) {
          _obj[dataProp] = data[0].rows ? data[0].rows : {};
          _obj[dataPagination] = data[0].pagination ? data[0].pagination : {}
        }
        this.setState({ ...{ statusAction: false }, ...this._loading(false, tipoDocumento), ..._obj })
      });
  }

  buttonGetPDF = () => {
    let paginationPage, paginationPageSize;
    switch (this.state.activeTab) {
      case 1:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 2:
        paginationPage = this.state.paginationCheque.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 3:
        paginationPage = this.state.paginationLetra.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 4:
        paginationPage = this.state.paginationPagare.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      default:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
    }
    if (this.state.statusAction === true) return;
    this.setState({ ...this._loading(true, this.state.activeTab), ...{ statusAction: true } });
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetPDF(this.getFilters(paginationPage, paginationPageSize, this.state.activeTab)))])
    ).then(() => {
      this.setState({
        ...{ statusAction: false },
        ...this._loading(false, this.state.activeTab)
      })
    })
  };

  buttonGetExcel = (b) => {
    if (this.state.statusAction === true) return;
    this.setState({ ...{ statusAction: true }, ...this._loading(true, this.state.activeTab) })
    let paginationTotalItems;
    switch (this.state.activeTab) {
      case 1:
        paginationTotalItems = this.state.paginationFactura.totalItems;
        break;
      case 2:
        paginationTotalItems = this.state.paginationCheque.totalItems;
        break;
      case 3:
        paginationTotalItems = this.state.paginationLetra.totalItems;
        break;
      case 4:
        paginationTotalItems = this.state.paginationPagare.totalItems;
        break;
      default:
        paginationTotalItems = this.state.paginationFactura.totalItems;
    }
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetExcel(this.getFilters(0, paginationTotalItems, this.state.activeTab)))])
    ).then((data) => {
      this.setState({
        ...{ statusAction: false },
        ...this._loading(false, this.state.activeTab),
      }, () => {
        this.exportExcel(data[0])
      })
      return this.props.dispatch(
        dispatch => Promise.all([dispatch(EventTrackingExcel("facturasEmitidasExcel"))])
        ).then(data => data)
    })
  };

  exportExcel = (data) => {
    let workbook = xlsx.utils.book_new();
    let formattedData = [];
    if (data.data.length > 0) {
      formattedData = data.data.map(d => {
        let formattedOrder = {
          "N° FACTURA": d.numeroFactura,
          "FECHA FACTURA": d.fecha ? Moment(d.fecha).format("DD/MM/YYYY") : "",
          "MONTO TOTAL": (d.montoTotal),
        };
        return formattedOrder;
      })
    }
    let orderSheet = xlsx.utils.json_to_sheet(formattedData);

    orderSheet["!cols"] = [
      { wpx: 90 }, //N° faactura
      { wpx: 110 }, //Fecha factura
      { wpx: 100 }, //Monto toal
    ]

    xlsx.utils.book_append_sheet(workbook, orderSheet, "FACTURAS");
    xlsx.writeFile(workbook, `Facturas Emitidas - ${Moment().format("YYYY-MM-DD-hh-mm")}.xlsx`);
  };

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  handleFiltrar = () => {
    this.refs.facturasTable.state.page = 0;
    this.refs.facturasTable.fireFetchData();
    if(!this.checkInputFilter()){
      Promise.all([new Promise(TrackingSidebar("facturasEmitidasBuscar"))])
      .then(data=>data)
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeRangNumeric = (e) => this.setState({ [e.target.name]: onChangeRangNumericHelper(e) });

  handleClickTable = (event, row, id) => {
    const { currentTarget } = event;
    const { activeTab, pageActual } = this.state;
    this.setState({
      ...this._loading(true, activeTab),
      ...{
        idSeleccion: id,
        isPageOpSelected: true,
        pageOpSelected: pageActual
      }
    })
    for (var i of document.getElementsByClassName("active")) { if (i.nodeName === "svg") i.classList.remove("active") }
    currentTarget.classList.add("active");
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetIndividualPDF({ numeroFactura: row.numeroFactura, tipoDocumento: row.tipoDocumento }))])
    ).then((data) => {
      this.setState(this._loading(false, activeTab));
      if (data && data[0] && data[0].data && data[0].data.documento)
        this.setState({ pdfFile: "data:application/pdf;base64," + data[0].data.documento });
    });
  };

  callbackcambioPagina(pageIndex) {
    this.setState({ pageActual: pageIndex });
    for (var i of document.getElementsByClassName("active")) { if (i.nodeName === "svg") i.classList.remove("active") }
    if (pageIndex > this.state.pageAnteriorVisitada)
      this.setState({ pageAnteriorVisitada: pageIndex }, () => {
        if (this.state.isPageOpSelected == true && pageIndex == this.state.pageOpSelected && document.getElementById(this.state.idSeleccion))
          document.getElementById(this.state.idSeleccion).classList.add("active");
      });
    else
      this.setState({ pageAnteriorVisitada: pageIndex }, () => {
        if (this.state.isPageOpSelected == true && pageIndex == this.state.pageOpSelected && document.getElementById(this.state.idSeleccion))
          document.getElementById(this.state.idSeleccion).classList.add("active");
      });
  }

  handleStatesCalendar = params => this.setState(params);

  cleanFilters() {
    this.setState({
      numeroFacturaDesde: '',
      numeroFacturaHasta: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      montoDesde: '',
      montoHasta: ''
    }, () => {
      this.handleFiltrar();
    })
  }

  checkInputFilter = () => {
    return this.state.calendarValue.desde ||
      this.state.calendarValue.hasta ||
      this.state.numeroFacturaDesde.length > 0 ||
      this.state.numeroFacturaHasta.length > 0 ||
      this.state.montoDesde.length > 0 ||
      this.state.montoHasta.length > 0 ? false : true
  }

  render() {
    const { classes } = this.props;
    const { openCalendar, anchorElCalendar, calendarValue, dataFactura, dataCheque,
      dataLetra, dataPagare } = this.state;
    const columnsFact = [
      {
        Header: "N° FACTURA",
        accessor: "numeroFactura",
        headerClassName: classes.HeaderTable,
        width: "100%",
        id: "numeroFactura",
        className: classes.TDTable + " " + classes.TDTableNoOperacion,
      },
      {
        Header: "FECHA FACTURA",
        accessor: "fecha",
        headerClassName: classes.HeaderTable + " " + this.props.classes.TDTableCenterAlign,
        width: 80,
        className: classes.TDTable + " " + this.props.classes.TDTableCenterAlign,
        Cell: (row) => <div>{row.original && row.original.fecha ? Moment(row.original.fecha).format("DD/MM/YYYY") : ""}</div>,
      },
      {
        Header: "DETALLE",
        headerClassName: classes.HeaderTable + " " + classes.TDTableCenterAlign,
        width: "100%",
        className: classes.TDTable + " " + classes.TDTableCenterAlign,
        Cell: (row) => <div>
          <SearchIcon
            className={classes.TDTableCenterIcon}
            onClick={(e) => this.handleClickTable(e, row.original, `filaFact${row.index}`)}
            id={`filaFact${row.index}`}
          />
        </div>,
      },
    ];

    const tableProps = (params) => ({
      columns: params.columns,
      defaultPageSize: 10,
      minRows: params.totalItemsPerPage && params.totalItemsPerPage <= params.totalItems ? params.totalItemsPerPage : 10,
      showPaginationBottom: params.totalItemsPerPage === params.totalItems ? false : true,
      PaginationComponent: PaginationBase,
      previousText: "Anterior",
      nextText: "Siguiente",
      noDataText: "Sin información",
      pageText: "Pagina",
      ofText: "de",
      rowsText: "filas",
      resizable: false,
      defaultFilterMethod: this.defaultFilterMethod,
      filterable: true,
      showFilters: false,
      loadingText: <Loader loadTable />,
      manual: true,
      data: params.data,
      ref: params.nameTable,
      onFetchData: (state) => this.fetchData(state, params.noTable, params.nomDataTable, params.paginationTable),
      pages: params.pages,
      totalItems: params.totalItems,
      loading: params.loading,
    })

    return (
      <div>
        <div>
          <Breadcrumb category="Usted se encuentra en: Factoring / Facturas emitidas" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Facturas emitidas
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12}>
            <Card>
              <CardHeader
                action={(
                  <div>

                    <Tooltip title={this.state.expanded ? "Colapsar" : "Ampliar"} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="h5" className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color="primary" variant="outlined" onClick={() => this.cleanFilters()} style={{ marginLeft: 14 }}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={3} >
                        <FormControl fullWidth>
                          <FormLabel
                            component="legend"
                            style={{
                              fontSize: 11,
                              top: 0,
                              left: 0,
                              position: "absolute"
                            }}
                          >N&deg; Factura</FormLabel>
                          <GridContainer style={{ marginTop: 8 }}>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id="numeroFacturaDesde"
                                name="numeroFacturaDesde"
                                value={this.state.numeroFacturaDesde}
                                placeholder="Desde"
                                onChange={this.onChangeRangNumeric}
                                onBlur={validateNumerico}
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id="numeroFacturaHasta"
                                name="numeroFacturaHasta"
                                value={this.state.numeroFacturaHasta}
                                placeholder="Hasta"
                                onChange={this.onChangeRangNumeric}
                                onBlur={validateNumerico}
                              />
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id="fechaFactura"
                            name="fechaFactura"
                            label="Fecha Factura"
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={3} >
                        <FormControl fullWidth>
                          <FormLabel
                            component="legend"
                            style={{
                              fontSize: 11,
                              top: 0,
                              left: 0,
                              position: "absolute"
                            }}
                          >Monto</FormLabel>
                          <GridContainer style={{ marginTop: 8 }}>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id="montoDesde"
                                name="montoDesde"
                                value={this.state.montoDesde}
                                placeholder="Desde"
                                onChange={this.onChangeRangNumeric}
                                onBlur={validateNumerico}
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id="montoHasta"
                                value={this.state.montoHasta}
                                name="montoHasta"
                                placeholder="Hasta"
                                onChange={this.onChangeRangNumeric}
                                onBlur={validateNumerico}
                              />
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer} >
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleFiltrar}
                            disabled={this.checkInputFilter()}
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={7} style={{ position: "relative" }}>
            {(dataFactura.length > 0 || dataCheque.length > 0 || dataLetra.length > 0 || dataPagare.length > 0) &&
              <GridItem className={classes.gridPDFExcelButtons}>
                <Tooltip title="Descargar PDF" placement="top">
                  <IconButton onClick={() => this.buttonGetPDF()}>
                    <SVGIcons id="FEIconDescargarPdf" icon="pdf" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Descargar Planilla Excel" placement="top">
                  <IconButton onClick={() => this.buttonGetExcel()}>
                    <SVGIcons id="FEIconDescargarExcel" icon="excel" />
                  </IconButton>
                </Tooltip>
              </GridItem>}
            <NavPills
              limitWidthPill
              color="info"
              onChangeTab={active => this.setState({ activeTab: active })}
              tabs={[
                {
                  tabButton: "FACTURAS",
                  hidden: !!this.state.dataFactura.length,
                  tabContent: (
                    <ReactTable
                      {...tableProps({
                        nameTable: "facturasTable",
                        columns: columnsFact,
                        loading: this.state.loading1,
                        data: this.state.dataFactura,
                        noTable: 1,
                        nomDataTable: "dataFactura",
                        paginationTable: "paginationFactura",
                        pages: this.props.paginationFactura.totalPages,
                        totalItems: this.props.paginationFactura.totalItems,
                        totalItemsPerPage: this.props.paginationFactura.top
                      })}
                      onPageChange={(pageIndex) => this.callbackcambioPagina(pageIndex)}
                    />
                  )
                },
              ]}
            />
          </GridItem>

          <GridItem xs={5} style={{ padding: 8 }}>
            <Card style={{ height: "100%", display: "flex", alignItems: "center", minHeight: 400 }}>
              {this.state.pdfFile ? (
                <CardContent className={classes.facEmitPDFContainer}>
                  <CardMedia
                    className={classes.cardMediaFacEmitidas}
                    component="iframe"
                    src={this.state.pdfFile}
                  />
                </CardContent>
              ) : (
                  <div style={{
                    color: "#A9ACAD",
                    textAlign: "center",
                    border: "5px dashed #E5EAD9",
                    padding: "10%",
                    margin: "auto",
                    fontSize: ".86rem"
                  }}>
                    <span style={{ width: "100%", float: "left" }}>Seleccione ver detalle</span>
                    <span style={{ width: "100%", float: "left" }}>para visualizar aquí</span>
                    <span style={{ width: "100%", float: "left" }}>su Factura.</span>
                  </div>
                )}

            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading,
  tipoDocumento: state.facturasEmitidas.tipoDocumento,
  rows: state.facturasEmitidas.rows,
  pagination: state.facturasEmitidas.pagination,
  pageSize: state.facturasEmitidas.pageSize,
  paginationLetra: state.facturasEmitidas.pagination,
  paginationFactura: state.facturasEmitidas.pagination,
  paginationCheque: state.facturasEmitidas.pagination,
  paginationPagare: state.facturasEmitidas.pagination,
});

FacturasEmitidas.propTypes = {
  classes: PropTypes.object.isRequired,
  tipoDocumento: PropTypes.number,
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
};

export default withStyles(HistoricoStyle)(connect(mapStateToProps)(FacturasEmitidas));

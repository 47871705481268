const SubscriberState = ({
    Subscriber: {
        rutEmpresa: "",
        nombreEmpresa: "",
        direccionEmpresa: "",
        emailEmpresa: "",
        comuna: "",
        telefono: "",
        claveSII: "",
        checkTermCond:false,
        checkCaptcha:false
    }
});

export default SubscriberState;

import React, { FC, useRef } from 'react';
import { drawerMiniWidth, drawerWidth } from '../../../styles/jss/generalStyle';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import cx from 'classnames';
import scssTool from '../../../styles/scssTool';

const sidebarWrapperStyle = (theme : Theme) => ({
  sidebarWrapper: {
    position: 'relative' as 'relative',
    height: 'calc(100vh - 75px)',
    overflow: 'auto',
    width: drawerWidth,
    zIndex: 4,
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    background: '#D5D9DB',
    paddingBottom: '30px',
    textDecoration: 'none',
    ...scssTool.flex('flex-start', 'column'),
    '&.mini': {
      width: `${drawerMiniWidth}px!important`
    },
    '&.hideScroll': {
      overflow: 'hidden !important'
    }
  }
});

interface ISidebarWrapper extends WithStyles<typeof sidebarWrapperStyle> {
  links : JSX.Element;
  headerLinks? : JSX.Element;
  miniActive : boolean;
}

const SidebarWrapper : FC<ISidebarWrapper> = (props) => {

  const { classes, links, headerLinks, miniActive } = props;
  const sidebarWrapper = useRef(null);

  return (
    <div className={cx(classes.sidebarWrapper, {
      hide: miniActive
    })} ref={sidebarWrapper}>
      {headerLinks}
      {links}
    </div>
  );
};

export default withStyles(sidebarWrapperStyle)(SidebarWrapper);

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment/moment';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';
import Typography from '@material-ui/core/Typography';
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';
import TableContainer from '../../sharedv2/Table/TableContainer';
import { obtenerContratosVendor } from '../actions';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import routes from '../../../config/routes';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { onChangeOnlyNumericHelper } from '../../../helpers/ValidateNumeric';
import FiltersHeader from '../../sharedv2/FiltersHeader/FiltersHeader';
import Loader from '../../shared/Loader/Loader';
import Popover from '@material-ui/core/Popover';
import vendorApi from '../../../services/vendorApi';
import { formatNumber } from '../../../helpers/FormatNumber';
import { esEjecutivoAsistente } from '../../../helpers/UserHelper';

const style = {
  link: {
    // display: 'flex',
    align: 'center',
    alignItems: 'center',
    fontSize: '12px',
    color: '#82BC00',
    textDecoration: 'none'
  }
};

interface StateProps {
  contratos : Dto.Vendor.ContratosVigentesRow[],
  paginacion : State.Paginacion,
  esEjecutivo : boolean
}

interface DispatchProps {
  obtenerContratosVendor : (payload : Request.Vendor.ContratosVigentes) => Promise<void>;
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof HistoricoStyle> {
}

interface IState extends State.ICalendar {
  loading : boolean,
  expanded : boolean,
  anchorElPopOver : any,
  tipoContrato : string,
  numeroContrato : string,
  cuotas : Dto.Vendor.CuotasRow[]
}

class ContratosVendor extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = {
      loading: false,
      expanded: true,
      anchorElPopOver: null,
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numeroContrato: '',
      tipoContrato: '',
      cuotas: []
    };
  }

  async componentDidMount() {
    await this.obtenerContratosVendor(0, 10);
  }

  obtenerContratosVendor = (pagina : number, tamano : number) => {
    this.setState({ loading: true });
    return this.props.obtenerContratosVendor(this.getFilters(pagina, tamano))
               .then(() => {
                 this.setState({ loading: false });
               });
  };

  search = async () => {
    await this.obtenerContratosVendor(0, 10);
  };

  getFilters = (pagina : number, tamano : number) : Request.Vendor.ContratosVigentes => {
    return {
      numeroPagina: pagina + 1,
      tamanoPagina: tamano,
      tipoContrato: this.state.tipoContrato,
      numeroContrato: Number(this.state.numeroContrato)
    };
  };


  toggleExpand = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter : any, row : any, column : any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  onChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  // @ts-ignore
  onChangeOnlyNumeric = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ [e.target.name]: onChangeOnlyNumericHelper(e) });

  handleStatesCalendar = (calendar : State.ICalendar) => {
    this.setState(calendar);
  };

  cargarCuotas = (event : React.ChangeEvent<any>, numeroContrato : number) => {
    const { currentTarget } = event;
    // this.setState({ loading: true });
    return vendorApi.getCuotas({ contrato: numeroContrato })
                    .then((response) => {
                      if (response && response.data) {
                        this.setState({ anchorElPopOver: currentTarget, cuotas: response.data });
                      } else {
                        this.setState({ loading: false });
                      }

                    });
  };

  handleCloseTable = () => {
    this.setState({ anchorElPopOver: null });
  };

  cleanFilters = () => {
    this.setState({
      numeroContrato: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      tipoContrato: ''
    }, async () => {
      await this.search();
    });
  };

  tableInfoCuota : TC.Fila[] = [
    {
      column: 'N° Cuota',
      relation: 'numeroCuota'
    },
    {
      column: 'Fecha Venc.',
      relation: 'fechaVencimiento'
    },
    {
      column: 'Valor Cuota',
      relation: 'valorCuota',
      renderData: (contrato : Dto.Vendor.CuotasRow) => (
        <Fragment>{contrato.valorCuota == null ? '' : contrato.moneda + ' ' + formatNumber(contrato.valorCuota, contrato.moneda === 'UF' ? 2 : 0)}</Fragment>)
    },
    {
      column: 'Estado Pago',
      relation: 'estadoPago'
    }
  ];

  tableInfoContrato : TC.Fila[] = [
    {
      column: 'N° CONTRATO',
      relation: 'numeroContrato'
    },
    {
      column: 'TIPO',
      relation: 'tipoOperacion'
    },
    {
      column: 'FECHA DE INICIO',
      relation: 'fechaContrato',
      renderData: (contrato : Dto.Vendor.ContratosVigentesRow) => (
        <Fragment>{Moment(contrato.fechaContrato).format('DD/MM/YYYY')}</Fragment>)
    },
    {
      column: 'CANTIDAD de Bienes',
      relation: 'cantidadBienes',
      align: 'center',
      headAlign: 'center',
      renderData: (contrato : Dto.Vendor.ContratosVigentesRow) => (
        <Link style={style.link}
              to={routes.vendor.bienesContrato(contrato.numeroContrato.toString())}>
          <SearchIcon color='primary' style={{ marginRight: '4px' }} /><span style={{ position: 'relative', top: -7 }}>{contrato.cantidadBienes}</span>
        </Link>)
    },
    // {
    //   column: 'VALOR CUOTAS',
    //   relation: 'valorCuota',
    //   align: 'right',
    //   renderData: (contrato : Dto.Vendor.ContratosVigentesRow) => (
    //     <Fragment>{formatNumber(contrato.valorCuota, 2)}</Fragment>)
    // },
    {
      column: 'CUOTAS TOTALES',
      relation: 'detalleContrato',
      align: 'center',
      headAlign: 'center',
      renderData: (contrato : Dto.Vendor.ContratosVigentesRow) => (
        <Button style={style.link}
                onClick={(e) => this.cargarCuotas(e, contrato.numeroContrato)}
        >
          <SearchIcon color='primary' style={{ marginRight: '4px' }} />{contrato.detalleContrato}</Button>)
    },
    {
      column: 'CUOTAS PAGADAS',
      relation: 'cuotasPagadas',
      align: 'center',
      headAlign: 'center'
    },
    {
      column: 'CUOTAS MOROSAS',
      relation: 'cuotasMorosas',
      align: 'center',
      headAlign: 'center'
    }
  ];

  render() {
    const { classes, paginacion, contratos, esEjecutivo } = this.props;
    const { anchorElPopOver, calendarValue } = this.state;

    const open = Boolean(anchorElPopOver);

    return (
      this.state.loading ?
        <Loader /> :
        <div>
          <Popover
            id='simple-popover'
            open={open}
            // anchorEl={anchorElPopOver}
            onClose={this.handleCloseTable}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            style={{ maxHeight: 450 }}
          >
            <div className={classes.TablePopUpContainer}>
              <TableContainer classes={classes}
                              data={this.state.cuotas}
                              pagination={undefined}
                              tableInfo={this.tableInfoCuota}
              />
            </div>
          </Popover>
          <div>
            <Breadcrumb category='Usted se encuentra en: Leasing Tecnológico / Contratos' textAlign='right' />
            <Typography variant='h5' gutterBottom className={classes.sectionTitle}>
              Contratos
            </Typography>
          </div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <FiltersHeader cleanFilters={this.cleanFilters} expanded={this.state.expanded} toggleExpand={this.toggleExpand} />
                <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
                  <CardContent className={classes.filterCardContent}>
                    <form className={classes.filterForm}>
                      <GridContainer>
                        <GridItem xs={3}>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id='numeroContrato'
                            name='numeroContrato'
                            value={this.state.numeroContrato}
                            label='N&deg; Contrato'
                            placeholder='000000'
                            onChange={this.onChangeOnlyNumeric}
                          />
                        </GridItem>
                        <GridItem xs={3}>
                          <FormControl fullWidth>
                            <InputLabel shrink htmlFor='tipoContrato'>Tipo de contrato</InputLabel>
                            <Select
                              className={classes.filterFormSelectField}
                              value={this.state.tipoContrato}
                              onChange={this.onChange}
                              displayEmpty
                              input={
                                <Input name='tipoContrato' id='tipoContrato' />
                              }
                              name='tipoContrato'>
                              <MenuItem value=''>
                                Todos
                              </MenuItem>
                              <MenuItem value={'Operativo'}>Operativo</MenuItem>
                              <MenuItem value={'Financiero'}>Financiero</MenuItem>
                              <MenuItem value={'Cesión contrato'}>Cesión contrato</MenuItem>
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem className={classes.filterFormButtonContainer}>
                          <FormControl className={classes.filterFormButtonBody}>
                            <Button
                              variant='outlined'
                              color='primary'
                              onClick={this.search}>
                              Buscar
                            </Button>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardContent>
                </Collapse>
              </Card>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} style={{ position: 'relative' }}>
              {/*<GridItem className={classes.gridPDFExcelButtons}>*/}
              {/*  <Tooltip title="Descargar PDF" placement="top">*/}
              {/*    <IconButton>*/}
              {/*      <SVGIcons icon="pdf" />*/}
              {/*    </IconButton>*/}
              {/*  </Tooltip>*/}
              {/*  <Tooltip title="Descargar Planilla Excel" placement="top">*/}
              {/*    <IconButton>*/}
              {/*      <SVGIcons icon="excel" />*/}
              {/*    </IconButton>*/}
              {/*  </Tooltip>*/}
              {/*</GridItem>*/}
              <TableContainer classes={classes}
                              data={contratos}
                              pagination={paginacion}
                              tableInfo={this.tableInfoContrato}
                              obtenerDatos={this.obtenerContratosVendor}
              />
            </GridItem>
          </GridContainer>
        </div>
    );
  }
}

const mapStateToProps = (state : AppState) : StateProps => ({
  contratos: state.vendor.contratos,
  paginacion: state.vendor.contratosPaginacion,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch : ThunkDispatch<any, any, AnyAction>) : DispatchProps => ({
  obtenerContratosVendor: (payload : Request.Vendor.ContratosVigentes) => dispatch(obtenerContratosVendor(payload))
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(ContratosVendor));

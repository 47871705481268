import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { modalStyle } from '../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../../sharedv2/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Grid, MenuItem, WithStyles } from '@material-ui/core';
import { handleChangeState } from '../../../helpers/PageStateHelper';
import Loader from '../../shared/Loader/Loader';
import { solicitudCertificado } from '../../../services/leasingApi';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { obtenerBienesLeasing } from '../actions';
import { AppState } from '../../../config/rootReducer';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';

interface StateProps {
  bienes : Dto.Leasing.BienesRow[],
}

interface DispatchProps {
  obtenerBienesLeasing : (payload : Request.Leasing.Bienes) => Promise<void>
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof modalStyle> {
  onClose : () => void;
  open : boolean;
  tipoSolicitud : string;
  requiereBien : boolean;
}

interface IModelo {
  mensaje : string,
  telefono : string,
  idBien : string,
  correoElectronico : string,
  necesidad : string,
}

const iniModelo = () => ({ mensaje: '', telefono: '', correoElectronico: '', necesidad: '', idBien: '' });

const SolicitudContacto : React.FC<IProps> = (props : IProps) => {
  const { classes, onClose, open } = props;
  const [modelo, setModelo] = useState<IModelo>(iniModelo());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setModelo(iniModelo());
  }, [open]);

  const handlerEnviar = () => {
    if (modeloValido) {
      setLoading(true);
      const request : Request.Leasing.SolicitudCertificadoEjecutivo = {
        ...modelo,
        idBien: modelo.idBien,
        tipoCertificado: props.tipoSolicitud
      };
      solicitudCertificado(request).then(r => {
        setLoading(false);
        if (r) props.onClose();
      });
    }
  };

  const handleChange = (event : React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => handleChangeState(modelo, setModelo)(event);

  useEffect(() => {
    if (props.requiereBien) {
      setLoading(true);
      props.obtenerBienesLeasing({ numeroPagina: 1, tamanoPagina: 500 }).then(() => {
        setLoading(false);
      });
    }
  }, [props.requiereBien]);

  const modeloValido = useMemo(() => {
    return modelo.mensaje.length > 0 && modelo.telefono.length > 0 &&
      modelo.correoElectronico.length > 0 && modelo.necesidad.length > 0 &&
      (!props.requiereBien || (props.requiereBien && modelo.idBien.length > 0));
  }, [modelo]);

  return (
    <Dialog
      onClose={onClose}
      className={classes.root}
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle
        onClose={onClose}
        subtitle='Envíanos tu solicitud y nos pondremos en contacto a la brevedad'
      >
        Solicitud de contacto
      </DialogTitle>
      <DialogContent style={{ padding: '0 24px 31px' }}>
        {loading ? <Loader /> :
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <div className={classes.titleForm}>Cuéntanos cómo podemos ayudarte</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.labelForm}>Tipo de solicitud</div>
              <input
                type='text'
                className={classes.textField}
                name='tipoSolicitud'
                disabled={true}
                value={props.tipoSolicitud}
              />
              <div className={classes.labelForm}>Teléfono móvil</div>
              <input
                type='text'
                className={classes.textField}
                name='telefono'
                placeholder='Ej. +56998177104'
                value={modelo.telefono}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.labelForm}>Necesidad</div>
              <input
                type='text'
                className={classes.textField}
                name='necesidad'
                placeholder='Ej. viaje'
                value={modelo.necesidad}
                onChange={handleChange}
              />
              <div className={classes.labelForm}>Email</div>
              <input
                type='text'
                className={classes.textField}
                name='correoElectronico'
                placeholder='Ej. mail@mail.cl'
                value={modelo.correoElectronico}
                onChange={handleChange}
              />
            </Grid>
            {props.requiereBien &&
            <Grid item xs={12} md={12}>
              <div className={classes.labelForm}>Bien</div>
              <Select
                className={[classes.textField].join(' ')}
                value={modelo.idBien}
                name="idBien"
                displayEmpty
                fullWidth
                onChange={handleChange}
              >
                {props.bienes.map((bien, idx) => {
                  return <MenuItem key={idx} value={bien.idBien}>{bien.tipoBien}, patente {bien.patente}</MenuItem>;
                })}
              </Select>
            </Grid>
            }
            <Grid item xs={12} md={12}>
              <div className={classes.labelForm}>Mensaje</div>
              <textarea
                rows={5}
                className={[classes.textField, 'area'].join(' ')}
                name='mensaje'
                placeholder='En qué podemos ayudarte...'
                value={modelo.mensaje}
                onChange={handleChange}
              />
            </Grid>
          </Grid>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          classes={{ root: classes.buttonEnviar, label: classes.labelButton, disabled: classes.buttonEnviarDisabled }}
          disabled={!modeloValido}
          onClick={handlerEnviar}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const mapStateToProps = (state : AppState) : StateProps => ({
  bienes: state.leasing.bienes
});

const mapDispatchToProps = (dispatch : ThunkDispatch<any, any, AnyAction>) : DispatchProps => ({
  obtenerBienesLeasing: (payload : Request.Leasing.Bienes) => dispatch(obtenerBienesLeasing(payload))
});


export default withStyles(modalStyle)(connect(mapStateToProps, mapDispatchToProps)(SolicitudContacto));

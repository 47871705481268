import React, { FC, useEffect, useState } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Header from '../../components/sharedv2/Header/Header';
import HistoricoStyle from '../../styles/jss/components/extendedFormsStyle';
import { Button, Card, CardHeader, Grid, IconButton, Tab, Tabs, TextField, Tooltip, Typography } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '../sharedv2/Table/TableContainer';
import { Link } from 'react-router-dom';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import routes from '../../config/routes';
import { obtenerEmpresas } from './actions';
import { AppState } from '../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';

export interface IContratosData {
  idContrato: string;
  rutCliente: string;
  numeroContrato: string;
  fechaSolicitud: string;
  razonSocial: string;
  encargado: string;
  qtyContratos: number;
  estado?: string;
  monto?: string | number;
  bienes?: [];
  firmado?: string;
}

interface StateProps {
  empresas: Dto.RegistroCivil.Empresa[];
  paginacion: State.Paginacion;
}

interface DispatchProps {
  obtenerEmpresas: (payload: Request.RegistroCivil.Empresas) => Promise<void>;
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof HistoricoStyle> {
}

const mapStateToProps = (state: AppState): StateProps => ({
  empresas: state.registroCivil.empresas.items,
  paginacion: state.registroCivil.empresas.paginacion
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  obtenerEmpresas: (payload: Request.RegistroCivil.Empresas) => dispatch(obtenerEmpresas(payload))
});

const Contratos: FC<IProps> = (props) => {
  const { classes, empresas, paginacion } = props;
  const [expanded, setExpanded] = useState(true);
  const [empresa, setEmpresa] = useState('');
  const [encargado, setEncargado] = useState('');
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    searchSolicitudes();
  }, []);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const cleanFilters = () => {
    setEmpresa('');
    setEncargado('');
    searchSolicitudes();
  };

  const searchSolicitudes = () => {
    obtenerDatos(1, 10);
  };

  const obtenerDatos = async (pagina: number, tamano: number) => {
    await props.obtenerEmpresas({ filtroEmpresa: empresa, encargado: encargado, numeroPagina: pagina, tamanoPagina: tamano });
  };


  const tableInfo = [
    {
      column: 'ID',
      relation: 'idContrato',
      renderData: (contrato: Dto.RegistroCivil.Empresa) => contrato.idEmpresa
    },
    {
      column: 'Rut Empresa',
      relation: 'rutEmpresa',
      renderData: (contrato: Dto.RegistroCivil.Empresa) => contrato.rut
    },
    {
      column: 'Razón Social',
      relation: 'razonSocial',
      renderData: (contrato: Dto.RegistroCivil.Empresa) => contrato.razonSocial
    },
    {
      column: 'Encargado',
      relation: 'encargado',
      renderData: (contrato: Dto.RegistroCivil.Empresa) => contrato.encargado
    },

    {
      column: 'Acciones',
      relation: '',
      renderData: (contrato: Dto.RegistroCivil.Empresa) => (
        <div style={{ display: 'flex' as 'flex', justifyContent: 'flex-end' as 'flex-end' }}>
          <Link
            to={`${routes.registroVehiculos.contratosEmpresa(contrato.idEmpresa.toString())}`}>
            <SearchOutlinedIcon />
          </Link>
        </div>
      )
    }
  ];

  return (
    <React.Fragment>
      <Header title='Contratos' />
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              action={
                <div>
                  <Tooltip title={expanded ? 'Colapsar' : 'Ampliar'} placement='top'>
                    <IconButton
                      onClick={handleExpandClick}
                      aria-expanded={expanded}
                      aria-label='Show more'
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              }
              title={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant='h5' className={classes.filterTitle}>
                    Filtros de Búsqueda
                  </Typography>
                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={cleanFilters}
                    style={{ marginLeft: 14 }}
                  >
                    Limpiar
                  </Button>
                </div>
              }
            />
            <Collapse in={expanded} timeout='auto' unmountOnExit>
              <CardContent className={classes.filterCardContent}>
                <form className={classes.filterForm}>
                  <div className={classes.collapseForm}>
                    <FormControl fullWidth className={classes.maxWidthInput}>
                      <TextField
                        className={[classes.filterFormtextField, classes.maxWidthInput].join(' ')}
                        id='empresa'
                        name='empresa'
                        value={empresa}
                        label='Empresa'
                        placeholder='Ej. 100'
                        onChange={(e) => setEmpresa(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    <FormControl fullWidth className={classes.maxWidthInput}>
                      <TextField
                        className={[classes.filterFormtextField, classes.maxWidthInput].join(' ')}
                        id='encargado'
                        name='encargado'
                        value={encargado}
                        label='Encargado'
                        placeholder='Ej. Todos'
                        onChange={(e) => setEncargado(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                    {/*<FormControl fullWidth className={classes.maxWidthInput}>*/}
                    {/*  <TextField*/}
                    {/*    className={[classes.filterFormtextField, classes.maxWidthInput].join(' ')}*/}
                    {/*    id='flag'*/}
                    {/*    name='flag'*/}
                    {/*    value={flag}*/}
                    {/*    label='Flag'*/}
                    {/*    placeholder='Ej. Todos'*/}
                    {/*    onChange={(e) => setFlag(e.target.value)}*/}
                    {/*    InputLabelProps={{ shrink: true }}*/}
                    {/*  />*/}
                    {/*</FormControl>*/}
                  </div>
                  <div className={classes.collapseFormButtons}>
                    <Button variant='outlined' color='primary' onClick={searchSolicitudes}>
                      Buscar
                    </Button>
                  </div>
                </form>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={12} md={12}>
          <div className='tabs'>
            <Tabs value={activeTab} classes={{ indicator: 'indicator' }}>
              <Tab label='EMPRESA' classes={{ root: 'tabs-factotal' }} />
            </Tabs>
            {activeTab === 0 &&
              <TableContainer
                classes={classes}
                data={empresas}
                pagination={paginacion}
                tableInfo={tableInfo}
                obtenerDatos={obtenerDatos}
              />
            }
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(Contratos));


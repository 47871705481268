import {
  changeStatusUsuario,
  crearUsuario,
  downloadTrackingUsersApi,
  editUsuario,
  getDetalleUsuarios,
  updateUserEnterpriseApi
} from '../../../services/factoringApi';
import { asyncActionType } from '../../../helpers/ActionsHelper';
import { Dispatch } from 'redux';

export const fetchAction = asyncActionType('@@ADMIN_USER', 'FETCH');

export const getListaUsuariosAction = () => (dispatch : Dispatch) : Promise<Dto.User[]> => {
  return getDetalleUsuarios()
    .then(response => response)
    .catch(error => error);
};
export const updateUserEnterpriseAction = (jobId : string, rutEmpresa : string) => (dispatch : Dispatch) => {
  return updateUserEnterpriseApi(jobId, rutEmpresa)
    .then(response => response)
    .catch(error => error);
};
export const downloadTrackingUsersAction = () => (dispatch : Dispatch) => {
  return downloadTrackingUsersApi()
    .then(response => response)
    .catch(error => error);
};

export const crearUsuarioAction = (params : Dto.CreateUser) => (dispatch : Dispatch) : Promise<boolean> => {
  return crearUsuario(params)
    .then(response => response)
    .catch(error => error);
};

export const editUsuarioAction = (params : Dto.User) => (dispatch : Dispatch) : Promise<boolean> => {
  return editUsuario(params)
    .then(response => response)
    .catch(error => error);
};

export const changeStatusUsuarioAction = (params : Dto.ChangeStatusUser) => (dispatch : Dispatch) : Promise<boolean> => {
  return changeStatusUsuario(params)
    .then(response => response)
    .catch(error => error);
};

export default {
  fetchAction,
  downloadTrackingUsersAction,
  updateUserEnterpriseAction,
  getListaUsusariosAction: getListaUsuariosAction,
  crearUsuarioAction,
  editUsuarioAction,
  changeStatusUsuarioAction
};

import { Button, IconButton, Select, TextField } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { DeleteOutlined, ExpandLess, ExpandMore, EditOutlined } from '@material-ui/icons';
import contratosGeneralStyle from '../contratosGeneralStyle';
import TableContainer from '../../sharedv2/Table/TableContainer';
import CloseIcon from '@material-ui/icons/Close';
import RectangleContainer from '../../sharedv2/RectangleContainer/RectangleContainer';
import { CALIDAD, COMUNAS, ICalidad, IVehiculo, VEHICULOS_LM } from './Selectores';
import { iniModeloCompradorVendedor, TModeloCompradorVendedor } from '../Contrato/view';

const iniCalidad = (): ICalidad => ({ texto: '', id: '', personaNatural: true });

interface IProp extends WithStyles<typeof contratosGeneralStyle> {
  personas: TModeloCompradorVendedor[];
  aceptarPersonas: (personas: TModeloCompradorVendedor[]) => void;
  elemento: string;
  titulo: string;
}

const Personas: FC<IProp> = (props) => {
  //const { classes, personas, elemento, titulo } = props;
  const { classes, elemento, titulo } = props;
  const [open, setOpen] = useState(false);
  const [openAgregarPersona, setOpenAgregarPersona] = useState(false);
  const [calidad, setCalidad] = useState<ICalidad>(iniCalidad);

  const [modelo, setModelo] = useState<TModeloCompradorVendedor>(iniModeloCompradorVendedor());
  const [isNotValidForm, setIsNotValidForm]=useState(false);
  const [personas, setPersonas]=useState(props.personas);

  // const [compradores, setCompradores] = useState<TModeloCompradorVendedor[]>([]);

  const setStateModelo = (field: keyof TModeloCompradorVendedor, value: any) => {
    setModelo({ ...modelo, [field]: value });
  };
  useEffect(()=>{
    if(props.personas){
      setPersonas(props.personas);
    }
  },[props.personas])

  useEffect(() => {
    const tv = CALIDAD.find(r => r.id === modelo.calidad.toString());
    setCalidad(tv || iniCalidad());
  }, [modelo.calidad]);

  useEffect(()=>{
    if(modelo.calidad==0 || modelo.calle.length==0 ||
      modelo.runRut.length==0 || modelo.apellidoMaterno.length==0 ||
      modelo.apellidoPaterno.length==0 || modelo.numeroDomicilio.length==0 ||
      modelo.correoElectronico.length==0 || modelo.telefono.length==0){
      setIsNotValidForm(true);
    }
    else{
      setIsNotValidForm(false);
    }
  },[modelo])

  const tableInfo = [
    {
      column: 'Calidad',
      relation: 'calidad',
      align: 'center',
      renderData: (elemento: TModeloCompradorVendedor) => elemento.calidad
    },
    {
      column: 'RUT o RUN',
      relation: 'rut',
      align: 'center',
      renderData: (elemento: TModeloCompradorVendedor) => elemento.runRut
    },
    {
      column: 'Nombre o Razón Social',
      relation: 'nombre',
      align: 'center',
      renderData: (elemento: TModeloCompradorVendedor) => `${elemento.nombresRazon} ${elemento.apellidoPaterno} ${elemento.apellidoMaterno}`
    },
    {
      column: 'Acciones',
      relation: '',
      align: 'center',
      renderData: (elemento: TModeloCompradorVendedor) => (
        <>
          <IconButton onClick={() => editarElemento(elemento)}>
            <EditOutlined />
          </IconButton>
          <IconButton onClick={() => deleteElemento(elemento)}>
            <DeleteOutlined />
          </IconButton>
        </>
      )
    }
  ];

  const editarElemento = (elemento: TModeloCompradorVendedor) => {
    setModelo(elemento);
    setOpenAgregarPersona(true);
  };

  const deleteElemento = (elemento: TModeloCompradorVendedor) => {
    debugger;
    const personaIndex = personas.findIndex(d => d.idPersona === elemento.idPersona);
    if (personaIndex !== -1){
      let tmpPersonas=props.personas;
      tmpPersonas.splice(personaIndex, 1);
      setPersonas([...tmpPersonas]);
      setModelo(iniModeloCompradorVendedor());
    }
  };

  const agregarPersona = () => {
    const personaIndex = personas.findIndex(d => d.idPersona === modelo.idPersona);
    if (personaIndex === -1) {
      let tmpPersonas=personas;
      tmpPersonas.push(modelo);
      setPersonas(tmpPersonas);
    } else {
      personas[personaIndex] = { ...modelo };
    }
    props.aceptarPersonas(personas);
    setModelo(iniModeloCompradorVendedor());
    setOpenAgregarPersona(false);
  };

  return (
    <div className={classes.greySeccion}>
      <div className={classes.seccionHeader}>
        <div className={classes.leftSeccionHeader}>
          <div className={classes.titleSeccionHeader}>{elemento}</div>
          <div className={classes.subtSeccionHeader}>{titulo}</div>
        </div>
        <div className={classes.openButton} onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={classes.containerSeccion}>
          <RectangleContainer>
            <Button color='primary' variant='outlined' className={classes.outlinedButton} onClick={() => setOpenAgregarPersona(!openAgregarPersona)}>
              Agregar {elemento}
            </Button>
            <TableContainer
              classes={classes}
              data={personas}
              tableInfo={tableInfo}
              obtenerDatos={() => {
              }}
              pagination={undefined}
            />
          </RectangleContainer>
          {openAgregarPersona && (
            <RectangleContainer>
              <div className={classes.btnCloseContainer}>
                <IconButton onClick={() => setOpenAgregarPersona(false)}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div className={classes.titleInformacion}>Información Persona o Empresa</div>
              <div className={classes.inputContainer}>
                <div className={classes.labelnputField}>Calidad</div>
                <Select
                  value={modelo.calidad.toString()}
                  onChange={(e) => setStateModelo('calidad', e.target.value)}
                  className={[classes.selectField, classes.middleSelect].join(' ')}
                  classes={{
                    outlined: classes.outlinesSelect
                  }}
                  displayEmpty={true}
                  variant='outlined'
                >
                  {CALIDAD.map((v, i) => (<MenuItem value={v.id}>{v.texto}</MenuItem>))}
                </Select>
              </div>

              <div className={[classes.containerInputFull].join(' ')}>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>{calidad.personaNatural ? 'RUN' : 'RUT'}</div>
                  <TextField
                    value={modelo.runRut}
                    onChange={(e) => setStateModelo('runRut', e.target.value)}
                    className={[classes.textField, classes.midleInput].join(' ')}
                    margin='normal'
                    variant='outlined'
                    placeholder='99876876-2'
                  />
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>{calidad.personaNatural ? 'Nombre' : 'Razón Social'}</div>
                  <TextField
                    value={modelo.nombresRazon}
                    onChange={(e) => setStateModelo('nombresRazon', e.target.value)}
                    className={classes.textField}
                    margin='normal'
                    variant='outlined'
                    placeholder={calidad.personaNatural ? 'Nombre' : 'Razón Social'}
                  />
                </div>
              </div>
              {calidad.personaNatural && <>
                <div className={[classes.containerInputFull].join(' ')}>
                  <div className={classes.inputContainer}>
                    <div className={classes.labelnputField}>Apellido Paterno</div>
                    <TextField
                      value={modelo.apellidoPaterno}
                      onChange={(e) => setStateModelo('apellidoPaterno', e.target.value)}
                      className={classes.textField}
                      margin='normal'
                      variant='outlined'
                      placeholder='Apellido paterno'
                    />
                  </div>
                  <div className={classes.inputContainer}>
                    <div className={classes.labelnputField}>Apellido Materno</div>
                    <TextField
                      value={modelo.apellidoMaterno}
                      onChange={(e) => setStateModelo('apellidoMaterno', e.target.value)}
                      className={classes.textField}
                      margin='normal'
                      variant='outlined'
                      placeholder='Apellido materno'
                    />
                  </div>
                </div>
              </>}

              <div className={[classes.containerInputFull].join(' ')}>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Comuna</div>
                  <Select
                    value={modelo.idComuna.toString()}
                    onChange={(e) => setStateModelo('idComuna', e.target.value)}
                    className={[classes.selectField].join(' ')}
                    classes={{ outlined: classes.outlinesSelect }}
                    displayEmpty={true}
                    variant='outlined'
                  >
                    {COMUNAS.map((v, i) => (<MenuItem value={v.id}>{v.texto}</MenuItem>))}
                  </Select>
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Calle</div>
                  <TextField
                    value={modelo.calle}
                    onChange={(e) => setStateModelo('calle', e.target.value)}
                    className={[classes.textField, classes.midleInput].join(' ')}
                    margin='normal'
                    variant='outlined'
                    placeholder='Calle'
                  />
                  <TextField
                    value={modelo.numeroDomicilio}
                    onChange={(e) => setStateModelo('numeroDomicilio', e.target.value)}
                    className={[classes.textField, classes.litleInput].join(' ')}
                    margin='normal'
                    variant='outlined'
                    placeholder='3245'
                  />
                </div>
              </div>
              <div className={[classes.containerInputFull].join(' ')}>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Teléfono</div>
                  <TextField
                    value={modelo.telefono}
                    onChange={(e) => setStateModelo('telefono', e.target.value)}
                    className={classes.textField}
                    margin='normal'
                    variant='outlined'
                    placeholder='Teléfono'
                  />
                </div>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Correo electrónico</div>
                  <TextField
                    value={modelo.correoElectronico}
                    onChange={(e) => setStateModelo('correoElectronico', e.target.value)}
                    className={classes.textField}
                    margin='normal'
                    variant='outlined'
                    placeholder='Correo electrónico'
                  />
                </div>
              </div>
              <div className={classes.buttonContainer}>
                <Button variant='contained' color='primary' onClick={agregarPersona} disabled={isNotValidForm}>
                  Aceptar
                </Button>
              </div>

            </RectangleContainer>
          )}

        </div>
      </Collapse>
    </div>
  );
};

export default withStyles(contratosGeneralStyle)(Personas);

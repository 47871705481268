import { Theme } from '@material-ui/core';

const recesionStyle = (theme : Theme) => ({
  form: {
    margin: '16px 0px'
  },
  titleLabel: {
    fontWeight: 'bold' as 'bold',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#3C3C3C'
  },
  parraf: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#3C3C3C'
  },
  uploadContent: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'start'
  },
  contentFormControl: {
    marginBottom: '32px'
  },
  formControlContent: {
    width: '100%',
    display: 'block',
    margin: '24px 0px'
  },
  ml: {
    marginLeft: '8px'
  },
  formControlContentPass: {
    width: '100%',
    display: 'block',
    margin: '8px 0px'
  },
  formControl: {
    width: '270px'
  },
  inputFile: {
    display: 'none'
  },
  countLetter: {
    fontSize: '12px',
    lineHeight: '13px',
    textAlign: 'right' as 'right',
    color: '#555759'
  },
  countLetterError: {
    fontSize: '12px',
    lineHeight: '13px',
    textAlign: 'right' as 'right',
    color: '#CD2F2F'
  },
  formControlLabelCheck: {
    fontSize: '12px',
    lineHeight: '14px',
    color: '#8C8C8C',
    '& span': {
      color: '#3C3C3C'
    }
  },
  radioButtonsContent: {
    paddingLeft: '8px',
    marginBottom: '24px'
  },
  radioButton: {
    color: '#82BC00',
    '&:checked': {
      color: '#82BC00'
    }
  },
  icon: {
    color: '#82BC00'
  },
  fileError: {
    float: 'left' as 'left',
    marginBottom: '16px',
    width: '100%',
    textAlign: 'left' as 'left',
    color: '#CD2F2F'
  },
  fileOk: {
    float: 'left' as 'left',
    marginBottom: '16px',
    width: '100%',
    textAlign: 'left' as 'left',
    color: '#3C3C3C'
  }

});

export { recesionStyle };

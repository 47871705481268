import React from "react";
import ReactDOM from "react-dom";
import Root from "./App";
import "./styles/style.css";
import "./fonts/Larsseit/Larsseit.css";
import { MuiThemeProvider } from '@material-ui/core/styles';
import FactotalTheme  from "./styles/FactotalTheme";


function App() {
  return (
    <MuiThemeProvider theme={FactotalTheme}>
      <Root />
    </MuiThemeProvider>
  );
}

ReactDOM.render(<App />, document.getElementById("root"));

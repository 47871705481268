import React, { FC } from 'react';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import HeaderLinks from './HeaderLinks';
import Button from '../../shared/CustomButtons/Button';
import headerStyle from '../../../styles/jss/components/headerStyle';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof headerStyle> {
  fixed? : boolean,
  miniActive : boolean,
  sidebarMinimize : () => void,
  rtlActive : boolean,
  color : 'primary' | 'info' | 'success' | 'warning' | 'danger'
}

const Header : FC<IProps> = (props : IProps) => {
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    ' ' +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
  return (
    <AppBar className={props.fixed ? (classes.appBar + appBarClasses + ' ' + classes.fixedBar) : (classes.appBar + appBarClasses)}>
      <Toolbar className={classes.container}>
        {/*<Hidden smDown implementation="css">*/}
        <Hidden implementation="css">
          <div className={sidebarMinimize + ' ' + (props.miniActive ? 'miniSidebar' : 'normalSidebar')}>
            <input type="hidden" value="v2" />
            <Button
              justIcon
              round
              color="white"
              onClick={props.sidebarMinimize}
            >
              {props.miniActive ? (
                  <KeyboardArrowRight className={classes.sidebarMiniIcon} />
                )
                : (
                  <KeyboardArrowLeft className={classes.sidebarMiniIcon} />
                )}
            </Button>
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/*
          <Button href="#" className={classes.title} color="transparent">
            {makeBrand()}
          </Button> */}
        </div>
        {/*<Hidden smDown implementation="css">*/}
        <Hidden implementation="css">
          <HeaderLinks rtlActive={false} />
        </Hidden>
        {/*<Hidden mdUp implementation="css">
        <Hidden implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>*/}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(headerStyle)(Header);

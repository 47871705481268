// External
import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  FilterExcedentesDisponibles,
  GetPDF,
  GetExcel,
  sendSolicitudExcedentesDisponibles,
  EventTrackingExcel,
  TrackingSidebar
} from './actions';
import xlsx from 'xlsx';

// datepicker
import 'react-datetime/css/react-datetime.css';

// Table
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Moment from 'moment/moment';

// componentes material ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Lens from '@material-ui/icons/ArrowRightOutlined';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// componentes custom
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../../shared/Grid/GridItem';
import GridContainer from '../../../shared/Grid/GridContainer';
import NavPills from '../../../shared/NavPills/NavPills';
import SVGIcons from '../../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../../shared/RangeCalendar/RangeCalendar';
import Loader from '../../../shared/Loader/Loader';
import PaginationBase from '../../../shared/Pagination/PaginationBase';
import {formatNumber} from '../../../../helpers/FormatNumber';

// Importación de estilo de talba
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';

const CheckboxTableFacturas = checkboxHOC(ReactTable);

class ExcedentesDisponibles extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      expanded: true,
      loading: true,
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      data: props.rows,
      dataFactura: props.rows,
      dataPagare: props.rows,
      dataLetra: props.rows,
      dataCheque: props.rows,
      totalFactura: 0,
      totalPagare: 0,
      totalLetra: 0,
      totalCheque: 0,
      tipoDocumento: '',
      nombreDeudor: '',
      comentariosSolicitud: '',
      activeTab: 1,
      Total: 0,
      selection: [],
      selectionRows: [],
      selectAll: false,
      selectionCheques: [],
      selectAllCheques: false,
      selectionLetras: [],
      selectAllLetras: false,
      selectionPagares: [],
      selectAllPagares: false,
      openModal1: false,
      openModal2: false,
      openmodal3: false,
      openCalendar: false,
      anchorElCalendar: null,
      calendarValue: {
        desde: '',
        hasta: ''
      },
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      },
      statusAction: false,
      userData: JSON.parse(sessionStorage.getItem('user'))
    };
    this.fetchData = this.fetchData.bind(this);
  }

  onChangeTab = (active) => this.setState({activeTab: active});

  getFilters(page, pageSize, tipoDocumento, sorted) {
    return {
      fechaDesde: (this.state.calendarValue.desde) ? Moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      fechaHasta: (this.state.calendarValue.hasta) ? Moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      nombreDeudor: this.state.nombreDeudor,
      page: page,
      pageSize: pageSize,
      tipoDocumento: tipoDocumento,
      ascending: sorted && sorted[0] && sorted[0].desc ? true : sorted && sorted[0] ? false : null,
      orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : ''
    };
  }

  getSortIdColumn(column) {
    let _id = null;
    switch (column) {
      case 'numeroDocumento':
        _id = 1;
        break;
      case 'TipoDocumento':
        _id = 2;
        break;
      case 'NumOperacion':
        _id = 3;
        break;
      case 'RutDeudor':
        _id = 4;
        break;
      case 'nombreDeudor':
        _id = 5;
        break;
      case 'montoDocumento':
        _id = 6;
        break;
      case 'MontoFinanciado':
        _id = 7;
        break;
      case 'fechaVencimiento':
        _id = 8;
        break;
      case 'fechaPago':
        _id = 9;
        break;
      case 'DiasMora':
        _id = 10;
        break;
      case 'montoMora':
        _id = 11;
        break;
      case 'saldoExcedente':
        _id = 12;
        break;
    }
    return _id;
  }

  _loading = (b, t) => {
    let loadingTable = 'loading' + t;
    let _obj = {};
    _obj[loadingTable] = b;
    return _obj;
  };

  fetchData(state, tipoDocumento, dataProp, dataTotal, dataPagination) {
    let _obj = {};
    _obj[dataProp] = [];
    _obj[dataTotal] = {};
    _obj[dataPagination] = {
      currentPage: 0,
      top: 0,
      totalItems: 0,
      totalPages: 0,
      from: 0,
      to: 0
    };
    this.setState({
      ...this._loading(true, tipoDocumento),
      ...{statusAction: true},
      ..._obj
    });
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(FilterExcedentesDisponibles(this.getFilters(state.page, state.pageSize, tipoDocumento, state.sorted)))])
    )
      .then((data) => {
        let _obj = {};
        if (data[0] && data[0].tipoDocumento) {
          _obj[dataProp] = data[0].rows ? data[0].rows : {};
          _obj[dataPagination] = data[0].pagination ? data[0].pagination : {};
          _obj[dataTotal] = data[0].total ? data[0].total : {};
        }
        this.setState({...{statusAction: false}, ...this._loading(false, tipoDocumento), ..._obj});
      });
  }

  buttonGetPDF = () => {
    let paginationPage, paginationPageSize;
    switch (this.state.activeTab) {
      case 1:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 2:
        paginationPage = this.state.paginationCheque.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 3:
        paginationPage = this.state.paginationLetra.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 4:
        paginationPage = this.state.paginationPagare.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      default:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
    }
    if (this.state.statusAction === true) return;
    this.setState({...this._loading(true, this.state.activeTab), ...{statusAction: true}});
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetPDF(this.getFilters(paginationPage, paginationPageSize, this.state.activeTab)))])
    ).then(() => {
      this.setState({
        ...{statusAction: false},
        ...this._loading(false, this.state.activeTab)
      });
    });
  };

  buttonGetExcel = (b) => {
    if (this.state.statusAction === true) return;
    this.setState({...{statusAction: true}, ...this._loading(true, this.state.activeTab)});
    let paginationTotalItems;
    switch (this.state.activeTab) {
      case 1:
        paginationTotalItems = this.state.paginationFactura.totalItems;
        break;
      case 2:
        paginationTotalItems = this.state.paginationCheque.totalItems;
        break;
      case 3:
        paginationTotalItems = this.state.paginationLetra.totalItems;
        break;
      case 4:
        paginationTotalItems = this.state.paginationPagare.totalItems;
        break;
      default:
        paginationTotalItems = this.state.paginationFactura.totalItems;
    }
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetExcel(this.getFilters(0, paginationTotalItems, this.state.activeTab)))])
    ).then((data) => {
      this.setState({
        ...{statusAction: false},
        ...this._loading(false, this.state.activeTab)
      }, () => {
        this.exportExcel(data[0]);
      });
      return this.props.dispatch(
        dispatch => Promise.all([dispatch(EventTrackingExcel('excedentesDisponiblesExcel'))])
      ).then(data => data);
    });
  };

  exportExcel = (data) => {
    let workbook = xlsx.utils.book_new();
    let formattedData = [];
    if (data.data.length > 0) {
      formattedData = data.data.map(d => {
        let formattedOrder = {
          'N° DOCUMENTO': d.numeroDocumento,
          'DEUDOR': d.nombreDeudor,
          'MONTO DOCUMENTO': (d.montoDocumento),
          'RETENCIÓN': (d.montoRetencion),
          'FECHA VENCIMIENTO': d.fechaVencimiento ? Moment(d.fechaVencimiento).format('DD/MM/YYYY') : '',
          'FECHA PAGO': d.fechaPago ? Moment(d.fechaPago).format('DD/MM/YYYY') : '',
          'MONTO MORA': (d.montoMora),
          'SALDO EXCEDENTES': (d.saldoExcedente)
          //"N° OPERACIÓN": d.numeroOperacion,          
        };
        return formattedOrder;
      });
    }
    let orderSheet = xlsx.utils.json_to_sheet(formattedData);

    orderSheet['!cols'] = [
      {wpx: 90}, //N° Documento
      {wpx: 230}, //Deudor
      {wpx: 120}, //Monto documento
      {wpx: 80}, //Retención
      {wpx: 120}, //Fecha vencimineto
      {wpx: 90}, //Fecha pago
      {wpx: 110}, //Monto mora
      {wpx: 130}, //Fecha vencimiento
      {wpx: 130} //Saldo excedentes
    ];

    xlsx.utils.book_append_sheet(workbook, orderSheet, 'FACTURAS');
    xlsx.writeFile(workbook, `Excedentes Disponibles -${Moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  handleFiltrar = (_this) => {
    let _data = this._getNameParamsActiveTab(this.state.activeTab);
    this.fetchData(this.state, this.state.activeTab, _data[0], _data[1], _data[2]);
    if (!this.checkInputFilter()) {
      Promise.all([new Promise(TrackingSidebar('excedentesDisponiblesBuscar'))])
        .then(data => data);
    }
  };

  _getNameParamsActiveTab(num) {
    if (!num) num = this.state.activeTab;
    switch (num) {
      case 1:
        return ['dataFactura', 'totalFactura', 'paginationFactura', 'facturasTable'];
      case 2:
        return ['dataCheque', 'totalCheque', 'paginationCheque', 'chequesTable'];
      case 3:
        return ['dataLetra', 'totalLetra', 'paginationLetra', 'letrasTable'];
      case 4:
        return ['dataPagare', 'totalPagare', 'paginationPagare', 'pagaresTable'];
      default:
        return ['dataFactura', 'totalFactura', 'paginationFactura', 'facturasTable'];
    }
  }

  handleExpandClick = () => this.setState((state) => ({expanded: !state.expanded}));

  handleOpenModal = (n) => {
    if (n === 1)
      this.setState({openModal1: true});
    else if (n === 2) {
      let _obj = {
        'fecha': Date.now(),
        'userId': 0,
        'enterpriseId': 0,
        'monto': this.state.totalFactura.saldoExcedenteTotal,
        'detalle': [],
        'comentario': this.state.comentariosSolicitud
      };
      let _detalleObj = this.state.selectionRows.map(e => {
        return {
          'id': e._id,
          'numeroDocumento': e.numeroDocumento,
          'nombreDeudor': e.nombreDeudor,
          'montoDocumento': e.montoDocumento,
          'fechaVencimiento': e.fechaVencimiento,
          'saldo': e.saldoExcedente
        };
      });
      _obj.detalle = _detalleObj;
      return this.props.dispatch(
        dispatch => Promise.all([dispatch(sendSolicitudExcedentesDisponibles(_obj))])
      )
        .then((data) => {
          if (data[0]) {
            this.setState({
              openModal1: false,
              openModal2: true,
              openModal3: false,
              selectionRows: [],
              selection: []
            }, () => {
              this.handleFiltrar();
            });
          } else {
            this.setState({
              openModal1: false,
              openModal2: false,
              openModal3: true,
              selectionRows: [],
              selection: []
            }, () => {
              this.handleFiltrar();
            });
          }
          return this.props.dispatch(
            dispatch => Promise.all([dispatch(TrackingSidebar('excedentesDisponiblesSolicitar'))])
          ).then(data => data);
        });
    }

  };

  handleCloseModal = (n) => {
    if (n === 1)
      this.setState({openModal1: false});
    else if (n === 2)
      this.setState({openModal2: false});
    else
      this.setState({openModal1: false, openModal2: false, openModal3: false});
  };

  defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  onChange = (e) => this.setState({[e.target.name]: e.target.value});

  toggleSelection = (key, shift, row) => {
    if (row.disabled) return;
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    let selection = [...this.state.selection];
    let selectionRows = [...this.state.selectionRows];
    const keyIndex = selection.indexOf(row._id);
    const KeyIndexRows = selectionRows.map((el) => el._id).indexOf(row._id);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selection.push(row._id);
    }
    if (KeyIndexRows >= 0) {
      // it does exist so we will remove it using destructing
      selectionRows = [
        ...selectionRows.slice(0, KeyIndexRows),
        ...selectionRows.slice(KeyIndexRows + 1)
      ];
    } else {
      // it does not exist so add it
      selectionRows.push(row);
    }
    // update the state
    this.setState({selection, selectionRows});

    //contar
    var total = 0;
    selection.forEach(item => {
      total++;
    });

    this.setState({Total: total});
  };

  toggleAll = (a, b, c, d) => {
    return;
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    const selectionRows = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.refs[this._getNameParamsActiveTab()[3]].getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        if (!item._original.disabled) {
          selection.push(item._original._id);
          selectionRows.push(item._original);
        }
      });
    }

    this.setState({selectAll, selection, selectionRows});
    //contar
    var total = 0;
    selection.forEach(item => {
      total++;
    });
    this.setState({Total: total});
  };

  isSelected = key => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection === undefined ? undefined : this.state.selection.includes(key);
  };

  handleStatesCalendar = params => this.setState(params);

  cleanFilters() {
    this.setState({
      calendarValue: {
        desde: '',
        hasta: ''
      },
      nombreDeudor: ''
    }, () => {
      this.handleFiltrar();
    });
  }

  checkInputFilter = () => {
    return this.state.calendarValue.desde ||
    this.state.calendarValue.hasta ||
    this.state.nombreDeudor.length > 4 ? false : true;
  };

  render() {
    const {toggleSelection, toggleAll, isSelected} = this;
    const {openCalendar, anchorElCalendar, calendarValue, dataFactura, dataCheque, dataPagare, dataLetra} = this.state;
    const {classes} = this.props;

    const userData = this.state.userData;//: Comun.Dto.LoginUserInfo

    var columns = (totalTable) => [
      {
        Header: 'N° DOCUMENTO',
        accessor: 'numeroDocumento',
        headerClassName: classes.HeaderTable,
        className: classes.TDTable + ' ' + classes.TDTableNoOperacion + ' ' + classes.TDTableLeftAlign,
        id: 'numeroDocumento',
        width: 100
      },
      {
        Header: 'DEUDOR',
        accessor: 'nombreDeudor',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        width: 270,
        className: classes.TDTable + ' ' + classes.TDTableLeftAlign + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (props) => <div title='asd' style={{textTransform: 'capitalize'}}>
          <div>{props.value.toLowerCase()}</div>
        </div>
      },
      {
        Header: 'MONTO DOCUMENTO',
        accessor: 'montoDocumento',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value)
      },
      {
        Header: 'RETENCIÓN',
        accessor: 'montoRetencion',
        sortable: false,
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value)
      },
      {
        Header: 'FECHA VENCIMIENTO',
        accessor: 'fechaVencimiento',
        sortable: false,
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: classes.TDTable + ' ' + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row) =>
          <div>{row.original && row.original.fechaVencimiento ? Moment(row.original.fechaVencimiento).format('DD/MM/YYYY') : ''}</div>
      },
      {
        Header: 'FECHA PAGO',
        accessor: 'fechaPago',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row) =>
          <div>{row.original && row.original.fechaPago ? Moment(row.original.fechaPago).format('DD/MM/YYYY') : ''}</div>
      },
      {
        Header: 'MONTO MORA',
        accessor: 'montoMora',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value),
        Footer: (<span
          style={{float: 'left'}}><strong>Solicitado: {totalTable && totalTable.solicitado ? formatNumber(totalTable.solicitado) : 0}</strong></span>)
      },
      {
        Header: 'SALDO EXCEDENTES',
        accessor: 'saldoExcedente',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value),
        Footer: (
          <span><strong>Total: {totalTable && totalTable.saldoExcedenteTotal ? formatNumber(totalTable.saldoExcedenteTotal) : 0}</strong></span>)
      }
    ];
    const checkboxPropsFacturas = {
      //selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        if (r && r.original && r.original._id) {
          const selected = this.isSelected(r.original._id);
          return {
            className: classes.TDTable + ' ' + classes.TDTableRightAlign + ' ' + classes.TDTableFilaCheck + ' ' + (r.original.disabled == true || userData.empresas[0].executiveType === 3 ? classes.TDTableDisabled : ''),
            style: {
              fontWeight: selected ? 'bold' : 'normal',
              backgroundColor: selected ? '#E5EAD9' : 'transparent',
              borderColor: selected ? '#D6DEC8' : 'rgba(0,0,0,0.1)'
            }
          };
        } else return {};
      }
    };
    const tableProps = (params) => ({
      columns: params.columns,
      defaultPageSize: 10,
      minRows: params.totalItemsPerPage && params.totalItemsPerPage <= params.totalItems ? params.totalItemsPerPage : 10,
      showPaginationBottom: params.totalItemsPerPage !== params.totalItems,
      PaginationComponent: PaginationBase,
      previousText: 'Anterior',
      nextText: 'Siguiente',
      noDataText: 'Sin información',
      pageText: 'Pagina',
      ofText: 'de',
      rowsText: 'filas',
      resizable: false,
      defaultFilterMethod: this.defaultFilterMethod,
      filterable: true,
      showFilters: false,
      loadingText: <Loader loadTable />,
      manual: true,
      data: params.data,
      ref: params.nameTable,
      onFetchData: (state) => this.fetchData(state, params.noTable, params.nomDataTable, params.totalTable, params.nomPaginationTable),
      pages: params.pages,
      totalItems: params.totalItems,
      loading: params.loading,
      ...checkboxPropsFacturas
    });

    return (
      <div>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.openModal1 ? true : false}
        >
          <div className={classes.modalPaper}>
            <div className={classes.modalHeader}>
              <SVGIcons icon='done' />
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalContentTitleStep1} variant='h6' id='modal-title'>
                Condiciones de solicitud
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <Lens />
                  </ListItemIcon>
                  <ListItemText style={{fontSize: '1rem'}} primary='Esta solicitud queda sujeta a morosidad.' />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Lens />
                  </ListItemIcon>
                  <ListItemText style={{fontSize: '1rem'}}
                                primary='Si la solicitud es realizada posterior a las 14:00 horas, ésta será operada el día hábil siguiente.' />
                </ListItem>
              </List>
            </div>
            <div className={classes.modalFooterStep1}>
              <Typography className={classes.modalContentSubTitle} variant='subtitle1' id='simple-modal-description'>
                ¿Confirma envío de solitud?
              </Typography>
              <Button
                variant='contained'
                size='large'
                onClick={() => this.handleOpenModal(2)}>
                Enviar
              </Button>
              <Button
                variant='contained'
                size='large'
                onClick={() => this.handleCloseModal()}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.openModal2 ? true : false}
        >
          <div className={classes.modalPaper}>
            <div className={classes.modalHeader}>
              <SVGIcons icon='done' />
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalContentTitle} variant='h6' id='modal-title'>
                ¡Solicitud enviada con éxito!
              </Typography>
              <Typography className={classes.modalContentSubTitle} variant='subtitle1' id='simple-modal-description'>
                Estimado Cliente, se ha enviado solicitud a su ejecutivo comercial.<br />
                Esta solicitud será analizada y recibirá una respuesta dentro de 24 horas.
              </Typography>
            </div>
            <div className={classes.modalFooter}>
              <Button
                variant='contained'
                size='large'
                onClick={() => this.handleCloseModal()}>
                Cerrar
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={this.state.openModal3 ? true : false}
        >
          <div className={classes.modalPaper}>
            <div className={classes.modalHeader}>
              <SVGIcons icon='error_outline' />
            </div>
            <div className={classes.modalContent}>
              <Typography className={classes.modalContentTitle} variant='h6' id='modal-title'
                          style={{lineHeight: 'normal'}}>
                Ocurrio un problema al momento de intentar solicitar los excedentes.
              </Typography>
              <Typography className={classes.modalContentSubTitle} variant='subtitle1' id='simple-modal-description'>
                Estimado Cliente, intentar mas tarde.<br />
                Si el problema persisite, porfavor comunicarse con equipo Factotal.
              </Typography>
            </div>
            <div className={classes.modalFooter}>
              <Button
                variant='contained'
                size='large'
                onClick={() => this.handleCloseModal()}>
                Cerrar
              </Button>
            </div>
          </div>
        </Modal>
        <div>
          <Breadcrumb category='Usted se encuentra en: Factoring / Excedentes / Disponibles' textAlign='right' />
          <div style={{display: 'flex', alignItems: 'center', marginBottom: 5}}>
            <Typography variant='h5' gutterBottom className={classes.sectionTitle}
                        style={{margin: 0, marginRight: 10}}>
              Excedentes Pendientes Totales
            </Typography>
            <FormControl style={{float: 'left', margin: 0, display: 'none'}}>
              <Button
                variant='contained'
                color='secondary'
                size='small'
                disabled={true}
              >
                Solicitar excedente
              </Button>
            </FormControl>
          </div>

        </div>
        <GridContainer>
          <GridItem xs={userData.perfiles.indexOf(3) === -1 && userData.empresas[0].executiveType !== 3 ? 5 : 12}>
            <Card style={{height: '100%'}}>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement='top'>
                      <IconButton
                        className={classnames(classes.expand, {
                          // [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label='Show more'
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant='h5' className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color='primary' variant='outlined' onClick={() => this.cleanFilters()}
                            style={{marginLeft: 14}}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={5}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id='FcFVenchOp'
                            name='FchOp'
                            label='Fecha Vencimiento'
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={4}>
                        <TextField
                          className={classes.filterFormtextField}
                          id='nomDeu'
                          name='nombreDeudor'
                          value={this.state.nombreDeudor}
                          label='Nombre Deudor'
                          placeholder='Nombre'
                          onChange={this.onChange}
                          InputLabelProps={{shrink: true}}
                        />
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer} xs={2}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={this.handleFiltrar}
                            disabled={this.checkInputFilter()}
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
          {userData.perfiles.indexOf(3) === -1 && userData.empresas[0].executiveType !== 3 ?
            <GridItem xs={7}>
              <Card style={{height: '100%'}}>
                <CardHeader
                  title='Solicitud de excedentes'
                  subheader='Para solicitar seleccione algún excedente de la tabla.'
                  classes={{
                    title: classes.GreenCardHeaderTitle,
                    subheader: classes.GreenCardHeaderSubTitle
                  }}
                  style={{position: 'relative'}}
                />
                <CardContent style={{paddingTop: 2}}>
                  <GridContainer>
                    <GridItem xs={3}>
                      <TextField
                        id='totalSelect'
                        label='Seleccionados'
                        style={{paddingLeft: 5, paddingRight: 5, width: '100%'}}
                        InputLabelProps={{shrink: true}}
                        value={this.state.Total}
                        disabled
                      />
                    </GridItem>
                    <GridItem xs={7}>
                      <TextField
                        id='Coment'
                        label='Comentarios'
                        placeholder='Comentarios'
                        name='comentariosSolicitud'
                        multiline
                        inputProps={{maxLength: 300}}
                        style={{paddingLeft: 5, paddingRight: 5, width: '100%'}}
                        InputLabelProps={{shrink: true}}
                        onChange={this.onChange}
                      />
                    </GridItem>
                    <GridItem xs={1}>
                      <FormControl className={classes.filterFormButtonBody}>
                        <Button
                          variant='contained'
                          color='primary'
                          style={{marginTop: 3.5, marginBottom: 2.5}}
                          onClick={() => this.handleOpenModal(1)}
                          disabled={this.state.Total === 0 || this.state.comentariosSolicitud.trim().length === 0}
                        >
                          Solicitar
                        </Button>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardContent>
              </Card>
            </GridItem>
            : ''}
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{position: 'relative'}}>
            {(dataFactura.length > 0 || dataCheque.length > 0 || dataLetra.length > 0 || dataPagare.length > 0) &&
              <GridItem className={classes.gridPDFExcelButtons}>
                <Tooltip title='Descargar PDF' placement='top'>
                  <IconButton onClick={() => this.buttonGetPDF()}>
                    <SVGIcons id='ExDIconDescargarPdf' icon='pdf' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Descargar Planilla Excel' placement='top'>
                  <IconButton onClick={() => this.buttonGetExcel()}>
                    <SVGIcons id='ExDIconDescargarExcel' icon='excel' />
                  </IconButton>
                </Tooltip>
              </GridItem>}
            <NavPills
              onChangeTab={active => this.setState({activeTab: active})}
              color='info'
              tabs={[
                {
                  tabButton: 'FACTURAS',
                  hidden: !!this.state.dataFactura.length,
                  tabContent: (
                    <CheckboxTableFacturas
                      className={classes.TDTableHeaderDisabled}
                      {...tableProps({
                        nameTable: 'facturasTable',
                        columns: columns(this.state.totalFactura),
                        loading: this.state.loading1,
                        data: this.state.dataFactura,
                        pages: this.props.paginationFactura.totalPages,
                        totalItems: this.props.paginationFactura.totalItems,
                        totalItemsPerPage: this.props.paginationFactura.top,
                        noTable: 1,
                        nomDataTable: 'dataFactura',
                        nomPaginationTable: 'paginationFactura',
                        totalTable: 'totalFactura'
                      })}
                    />
                  )
                }
                ,
                {
                  tabButton: 'CHEQUES',
                  hidden: !!this.state.dataCheque.length,
                  tabContent: (
                    <CheckboxTableFacturas
                      {...tableProps({
                        nameTable: 'chequesTable',
                        columns: columns(this.state.totalCheque),
                        loading: this.state.loading2,
                        data: this.state.dataCheque,
                        pages: this.props.paginationCheque.totalPages,
                        totalItems: this.props.paginationCheque.totalItems,
                        totalItemsPerPage: this.props.paginationCheque.top,
                        noTable: 2,
                        nomDataTable: 'dataCheque',
                        nomPaginationTable: 'paginationCheque',
                        totalTable: 'totalCheque'
                      })}
                    />
                  )
                },
                {
                  tabButton: 'LETRAS',
                  hidden: !!this.state.dataLetra.length,
                  tabContent: (
                    <CheckboxTableFacturas
                      {...tableProps({
                        nameTable: 'letrasTable',
                        columns: columns(this.state.totalLetra),
                        loading: this.state.loading3,
                        data: this.state.dataLetra,
                        pages: this.props.paginationLetra.totalPages,
                        totalItems: this.props.paginationLetra.totalItems,
                        totalItemsPerPage: this.props.paginationLetra.top,
                        noTable: 3,
                        nomDataTable: 'dataLetra',
                        nomPaginationTable: 'paginationLetra',
                        totalTable: 'totalLetra'
                      })}
                    />
                  )
                },
                {
                  tabButton: 'PAGARÉS',
                  hidden: !!this.state.dataPagare.length,
                  tabContent: (
                    <CheckboxTableFacturas
                      {...tableProps({
                        nameTable: 'pagaresTable',
                        columns: columns(this.state.totalPagare),
                        loading: this.state.loading4,
                        data: this.state.dataPagare,
                        pages: this.props.paginationPagare.totalPages,
                        totalItems: this.props.paginationPagare.totalItems,
                        totalItemsPerPage: this.props.paginationPagare.top,
                        noTable: 4,
                        nomDataTable: 'dataPagare',
                        nomPaginationTable: 'paginationPagare',
                        totalTable: 'totalLetra'
                      })}
                    />
                  )
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading,
  rows: state.excedentesDisponibles.rows,
  total: state.excedentesDisponibles.total,
  pagination: state.excedentesDisponibles.pagination,
  pageSize: state.excedentesDisponibles.pageSize,
  tipoDocumento: state.cartera.tipoDocumento,
  paginationLetra: state.excedentesDisponibles.pagination,
  paginationFactura: state.excedentesDisponibles.pagination,
  paginationCheque: state.excedentesDisponibles.pagination,
  paginationPagare: state.excedentesDisponibles.pagination
});

ExcedentesDisponibles.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired
};

export default withStyles(HistoricoStyle)(connect(mapStateToProps)(ExcedentesDisponibles));

import { AnyAction } from 'redux';

export const reducer = (state = {}, action : AnyAction) => {
  switch (action.type) {
    // case fetchAction.PENDING:
    //     {
    //         return Object.assign({}, state, action);
    //     }
    //
    // case fetchAction.SUCCESS:
    //     {
    //         return Object.assign({}, state, action);
    //     }

    default:
      return state;
  }
};

export default reducer;

import React, { FC } from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import IcoGreyLocation from '../../../images/ico-grey-location.svg';
import IcoGreyPhone from '../../../images/ico-grey-phone.svg';
import IcoBlackPhone from '../../../images/group.png';
import scssTool from '../../../styles/scssTool';

const styleFactoring = (theme: Theme) => ({
  ejecutivoDataContainer: {
    ...scssTool.flex('flex-start', 'column', 'flex-end'),
    padding: '32px 16px 0px',
    flexGrow: 1
  },
  ejecutivoDataTitle: {
    ...scssTool.font(16, 400, 16),
    color: '#434343',
    fontFamily: scssTool.family.lato,
    textTransform: 'uppercase' as 'uppercase',
    marginBottom: '18px'
  },
  ejecutivoDataSubTitle: {
    ...scssTool.font(14, 400, 24),
    color: '#434343',
    fontFamily: scssTool.family.lato,
    ...scssTool.flex('center', 'row', 'center'),
    marginBottom: '11px',
    '& img': {
      marginRight: '16px'
    }
  }
});


interface IMesaComercialContainer extends WithStyles<typeof styleFactoring> {
}

const MesaComercialContainer: FC<IMesaComercialContainer> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.ejecutivoDataContainer}>
      <div className={classes.ejecutivoDataTitle}>Mesa comercial</div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoBlackPhone} alt='' />+56 9 6155 4639
      </div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoGreyPhone} alt='' />+56 2 2685 5900
      </div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoGreyLocation} alt='' />Andrés Bello 2233, piso 7, Providencia, Santiago
      </div>
    </div>
  );
};

export default withStyles(styleFactoring)(MesaComercialContainer);

import facEmitidasFactoringState from './state';
import { fetchAction } from "./actions";

export const reducer = (state = facEmitidasFactoringState, action) => {
    switch (action.type) {
        case fetchAction.PENDING: {
            return Object.assign({}, state, action);
        }

        case fetchAction.SUCCESS: {
            return Object.assign({}, state, action);
        }
        
        default:
            return state;  
    }
}

export default reducer;
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, {FC, useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import DoneIcon from '../../../../images/icons/icons-bienes/done_24px_outlined.svg';
import ArrowIcon from '../../../../images/icons/icons-bienes/arrow_right_24px_outlined.svg';
import dialogStyle from '../../../../styles/jss/components/dialogStyle';

export interface IResultadoEnvioInscripcion {
  ppu: string;
  fecha: string;
  hora: string;
  oficina: string;
  solicitud: string;
  observaciones: string[];
  success: boolean;
  message: string;
}

export interface IResultadoEnvioTransferencia {
  ppu: string;
  fecha: string;
  hora: string;
  oficina: string;
  solicitud: string;
  observaciones: string[];
  success: boolean;
  message: string;
}

interface IProps extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  onEnviarInscripcion?: () => Promise<IResultadoEnvioInscripcion>;
  onEnviarTransferencia?: () => Promise<IResultadoEnvioTransferencia>;
  open: boolean;
}

const ConfirmarSolicitud: FC<IProps> = (props) => {
  const { classes, onClose, open } = props;
  const [result, setResult] = useState<IResultadoEnvioInscripcion | undefined>(undefined);

  const [inscripcionTransferenciaEnviada, setInscripcionTransferenciaEnviada]= useState(false);

  useEffect(()=>{
    if(open) {
      setResult(undefined);
      setInscripcionTransferenciaEnviada(false);
    }
  },[open])
  const enviarSolicitud = async () => {
    if (props.onEnviarInscripcion && !inscripcionTransferenciaEnviada) {
      const resultado = await props.onEnviarInscripcion();
      setResult(resultado);
      setInscripcionTransferenciaEnviada(true);
    } else if (props.onEnviarTransferencia && !inscripcionTransferenciaEnviada) {
      const resultado = await props.onEnviarTransferencia();
      setResult(resultado);
      setInscripcionTransferenciaEnviada(true);
    }
    else{
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: classes.dialogPaperGreyVariant }}>
      <div className={[classes.containerGreyVariant, classes.heightAuto].join(' ')}>
        <div className={classes.floatingHeaderIcon}>
          <img src={DoneIcon} alt='' />
        </div>
        <DialogContent
          classes={{ root: classes.dialogContent }}>
          {!result && (
            <>
              <div className={classes.bigTitleStep}>Confirmar envío a Registro Civil</div>
             {/* <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Se ha solicitado nuevamente la cesión</div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Una vez autorizada le enviaremos una notificación</div>
              </div>*/}
            </>
          )}
          {result && result.success && (
            <>
              <div className={classes.bigTitleStep}>Solicitud Ingresada</div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Fecha: {result.fecha} Hora: {result.hora}</div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Solicitud: {result.solicitud}</div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Oficina: {result.oficina}</div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>PPU: {result.ppu}</div>
              </div>
              {result.observaciones.length > 0 && <>
                <div className={classes.containerInfo}>
                  <div className='obs'>Observaciones</div>
                </div>
                {result.observaciones.map((obs, idx) => <div key={idx} className={classes.containerInfo}><img src={ArrowIcon} alt='' />
                  <div className={classes.textinfo}>{obs}</div>
                </div>)}
              </>}
            </>
          )}
          {result && !result.success && (
            <>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Error: {result.message}</div>
              </div>
            </>
          )

          }
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='outlined' color='primary' className={classes.greenButton} onClick={enviarSolicitud}>
            {!result ? 'Continuar' : 'Cerrar'}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(dialogStyle)(ConfirmarSolicitud);

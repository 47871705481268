import * as React from 'react';
import { FC, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { SAcceptYieldModal } from '../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Checkbox, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import factorxApi from '../../../services/factorxApi';
import { validaRut } from '../../../helpers/RutHelper';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import actions from '../../main/reducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Banner from '../../factoring/operaciones/enProceso/components/Banner/Banner';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { AppState } from '../../../config/rootReducer';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface OwnProps {
  open: boolean;
  onClose: () => void;
}

interface StateProps {
  sincronizacion: Core.Dto.Sincronizacion | undefined;
  proveedores: Dto.Portal.ProveedoresFacturacion[];
}

interface DispatchProps {
  notificacion: (exito: boolean, mensaje: string) => void;
}

interface IProps extends OwnProps, StateProps, DispatchProps, WithStyles<typeof SAcceptYieldModal> {

}

interface ILabel {
  usuario: string;
  password: string;
}

const defaultOtros: ILabel = {
  usuario: 'Usuario',
  password: 'Clave'
};
const defaultMiPyme: ILabel = {
  usuario: 'Rut Representante Legal',
  password: 'Clave Representante Legal'
};
const defaultNuboxSii: ILabel = {
  usuario: 'Rut Usuario',
  password: 'Clave'
};

interface IState {
  terminosCondiciones: boolean,
  proveedorId: string,
  estadoSincro: number,
  labels: ILabel,
  usuario: string,
  clave: string,
  file: File | undefined,
  usaCertificado: boolean,
  errors: any,
  termsModal: boolean,
  estaCargando: boolean
}

const mapStateToProps = (state: AppState): StateProps => ({
  sincronizacion: state.factorx.empresa.sincronizacion.find(d => d.tipoDesc === 'Dte'),
  proveedores: state.factorx.global.proveedoresFacturacion
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

const ConfiguracionSincronizacionDte: FC<IProps> = (props) => {

  const [state, setState] = React.useState<IState>({
    terminosCondiciones: false,
    estadoSincro: 0,
    labels: defaultMiPyme,
    proveedorId: 'mipyme',
    errors: {},
    termsModal: false,
    estaCargando: false,
    clave: '',
    file: undefined,
    usaCertificado: false,
    usuario: ''
  });

  const [proveedores, setProveedores] = React.useState<Dto.Portal.ProveedoresFacturacion[]>([]);

  useEffect(() => {
    setProveedores([{ id: 'sii', nombre: 'Sii empresa' }, ...props.proveedores]);
  }, [props.proveedores]);

  const getLabels = (proveedor: string): ILabel => {
    if (proveedor === 'mipyme') return defaultMiPyme;
    else if (proveedor === 'nubox' || proveedor === 'sii') return defaultNuboxSii;
    return defaultOtros;
  };

  useEffect(() => {
    let proveedorId = ((props.sincronizacion || { servicio: '' }).servicio) || 'mipyme';
    let estadoSincro = (props.sincronizacion || { estado: 0 }).estado;
    setState((st) => ({
      ...st,
      labels: getLabels(proveedorId),
      clave: '',
      file: undefined,
      proveedorId: proveedorId,
      estadoSincro: estadoSincro,
      usuario: '',
      usaCertificado: false
    }));
  }, []);

  const handleProveedorChange = (ev: React.ChangeEvent<any>) => {
    let ns = state;
    ns.proveedorId = ev.target.value;
    if (ev.target.value !== 'mipyme') {
      ns.usaCertificado = false;
      ns.file = undefined;
    }
    ns.labels = getLabels(ev.target.value);
    setState({
      ...ns,
      errors: evaluar(ns)
    });
  };

  const handlePropertyChange = (name: string, value: any) => {
    const file = (name === 'usaCertificado' && !value) ? undefined : state.file;
    const ns = {
      ...state,
      file: file,
      [name]: value
    };
    setState({
      ...ns,
      errors: evaluar(ns)
    });
  };

  const changeTermsConditions = (checked: boolean) => {
    handlePropertyChange('terminosCondiciones', checked);
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const f = files[0];
      const f2 = (f && f.type === 'application/x-pkcs12') ? f : undefined;
      handlePropertyChange('file', f2);
    }
  };

  const evaluar = (state: IState) => {
    let errors: any = {};

    if (!state.clave) {
      errors.clave = 'La clave es requerida';
    }
    if (state.usaCertificado) {
      if (!state.file) {
        errors.file = 'El certificado es requerido';
      } else {
        if (state.file.type !== 'application/x-pkcs12') {
          errors.file = 'El formato del archivo no es el correcto';
        }
      }
    } else {
      if (!state.usuario) {
        if (state.proveedorId === 'mipyme') {
          errors.usuario = 'El rut del representante legal es requerido';
        } else {
          errors.usuario = 'El usuario es requerido';
        }
      } else {
        if (state.proveedorId === 'mipyme') {
          if (!validaRut(state.usuario)) {
            errors.usuario = 'El rut del representante legal es incorrecto';
          }
        }
      }
    }
    if (!state.terminosCondiciones) {
      errors.terminosCondiciones = 'Debe aceptar los términos y condiciones';
    }
    return errors;
  };

  const puedeGuardar = () => {
    return Object.getOwnPropertyNames(state.errors).length === 0;
  };

  const handleUpdateSyncDetails = (field: string) => (ev: React.ChangeEvent<any>) => {
    let { value } = ev.target;
    handlePropertyChange(field, value);
  };

  const handleGuardar = async () => {
    const modelo: Payloads.EdicionSincronizacionDteEmpresaPayload = {
      clave: state.clave,
      file: state.file,
      proveedor: state.proveedorId,
      usuario: state.usuario
    };
    setState((st) => ({ ...st, estaCargando: true }));
    const respuesta = await factorxApi.modificarRegistroSincronizacionDte(modelo);
    setState((st) => ({ ...st, estaCargando: false }));
    if (respuesta.exito) {
      props.notificacion(true, 'La información del registro de conexión al facturador fue actualizada');
      props.onClose();
    } else {
      props.notificacion(false, respuesta.mensaje);
    }
  };

  const handlerTermsModal = () => {
    setState((st) => ({ ...st, termsModal: true }));
  };

  const handleCloseTerms = () => {
    setState((st) => ({ ...st, termsModal: false }));
  };

  const aceptarYCerrar = () => {
    changeTermsConditions(true);
    handleCloseTerms();
  };

  const { classes, onClose, open } = props;
  const paperClass = [classes.dialogPaper, classes.uploadXml, classes.dialogPapersOr].join(' ');

  return (
    <Dialog
      onClose={onClose}
      className={classes.root}
      open={open}
      PaperProps={{ className: paperClass }}
    >
      <DialogTitle onClose={onClose}>Servicio de sincronización de documentos</DialogTitle>
      <DialogContent>
        {proveedores != null && <form>
          <Grid container className={classes.gridDialog}>
            <Grid item xs={12}>
              {state.estadoSincro === 0 && (
                <Banner
                  text='Hemos detectado que usted no posee vinculado el certificado digital. Para
                    activar la carga
                    automática de documentos, debe cargar su certificado.' type='danger' />
              )}
              {state.estadoSincro === 1 && (
                <Banner
                  text='La sincronización está correctamente configurada'
                  type='success' />
              )}
              {state.estadoSincro === 2 && (
                <Banner
                  text='La contraseña asociada a la fuente de origen de sus documentos cambió, por
                    favor ingresar la nueva contraseña para reactivar la sincronización' type='warning' />
              )}
            </Grid>
            <Grid item xs={12}>
              <FormLabel className={classes.inputLabel}>Seleccione Facturador Electrónico</FormLabel>
              <Select
                value={state.proveedorId}
                name='proveedor'
                fullWidth
                onChange={handleProveedorChange}
              >
                {proveedores.map(proveedor => <MenuItem key={proveedor.id} value={proveedor.id}>{proveedor.nombre}</MenuItem>)}
              </Select>
            </Grid>

            {state.proveedorId === 'mipyme' && (
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <RadioGroup
                  aria-label='certificado'
                  name='certificado'
                  value={state.usaCertificado ? '1' : '0'}
                  onChange={(ev: React.ChangeEvent<any>) => {
                    if (ev.target.value === '1') {
                      handlePropertyChange('usaCertificado', true);
                    } else {
                      handlePropertyChange('usaCertificado', false);
                    }
                    // props.setErrors({})
                  }}
                  className={classes.formControlDialog}
                >
                  <FormControlLabel className={classes.labelRadioButton} value='1'
                                    control={<Radio />}
                                    label='Certificado Digital Represtenante Legal (.pfx)' />
                  <FormControlLabel className={classes.labelRadioButton} value='0'
                                    control={<Radio />}
                                    label='RUT y Clave Represtenante Legal SII' />

                </RadioGroup>

              </Grid>
            )}
            <legend style={{ marginTop: 15 }}>{state.proveedorId !== 'mipyme' ? 'Credenciales proveedor' : (
              state.usaCertificado
                ? 'Certificado digital representante legal'
                : 'Credenciales SII representante legal'
            )}</legend>
            <Grid container style={{ marginTop: 15 }}>
              <Grid item xs={12}>
                {state.proveedorId !== 'mipyme' ? (
                  <TextField
                    fullWidth
                    label={state.labels.usuario}
                    value={state.usuario}
                    onChange={handleUpdateSyncDetails('usuario')}
                    error={state.errors.usuario !== undefined}
                    placeholder=''
                  />
                ) : (
                  state.usaCertificado ? (
                      <Grid container>
                        <Grid item xs={8}>

                          <TextField
                            fullWidth
                            id='fileText'
                            label='Certificado digital (.pfx)'
                            value={state.file ? state.file.name : ''}
                            // className={classes.formControl}
                            error={state.errors.file !== undefined}
                            InputProps={{
                              readOnly: true
                            }}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl
                            // className={[classes.formControl, classes.ml].join(' ')}
                          >
                            <TextField
                              // accept='application/x-pkcs12'
                              id='file'
                              type='file'
                              required
                              error={state.file === undefined}
                              className={classes.inputFile}
                              onChange={handleChangeFile}
                            />
                            <label htmlFor='file'>
                              <Button variant='outlined' color='primary' component='span'>
                                Adjuntar
                              </Button>
                            </label>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ) :
                    <TextField
                      fullWidth
                      label='RUT representante legal'
                      value={state.usuario}
                      onChange={handleUpdateSyncDetails('usuario')}
                      error={state.errors.usuario !== undefined}
                      placeholder=''
                    />
                )}
              </Grid>
              <Grid item xs={12} style={{ marginTop: 15 }}>
                <TextField
                  fullWidth
                  label={state.proveedorId !== 'mipyme' ? state.labels.password : (
                    state.usaCertificado ? 'Clave certificado digital' : 'Contraseña SII'
                  )}
                  onChange={handleUpdateSyncDetails('clave')}
                  error={state.errors.clave !== undefined}
                  type='password'
                  placeholder='***********'
                />
                {/*{state.mensajeError && (*/}
                {/*  <FormHelperText>{state.mensajeError}</FormHelperText>*/}
                {/*)}*/}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.gridDialog}
            justify='flex-start'
            style={{ marginTop: 15 }}
          >
            <Grid item xs={12}>
              <div className={classes.dialogChekbox}>
                <Checkbox
                  color='primary'
                  checked={state.terminosCondiciones}
                  onChange={(event) => changeTermsConditions(event.target.checked)}
                  inputProps={{ 'aria-label': 'Selecionar' }}
                />
                <div className={classes.termsLink}>
                  Acepto los <a onClick={handlerTermsModal}>Términos y Condiciones</a>
                </div>
              </div>
            </Grid>
          </Grid>
        </form>}
        <TermsAndConditions open={state.termsModal} onClose={handleCloseTerms}
                            onAccept={aceptarYCerrar} />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button variant='contained'
                onClick={handleGuardar}
                disabled={!puedeGuardar() || state.estaCargando}
                color='primary'>
          {state.estaCargando ?
            <CircularProgress size={20} />
            : 'Autorizar'}
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={onClose}
          className={classes.cancelButtom}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(SAcceptYieldModal)(connect(mapStateToProps, mapDispatchToProps)(ConfiguracionSincronizacionDte));

import { Button, Grid, Tab, Tabs, withStyles, WithStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../config/rootReducer';
import FormularioSiniestro from './Components/FormularioSiniestro';
import PlazosContainer from './Components/PlazosContainer';
import { styleLeasing } from './style';
import SolicitudContacto from './Components/SolicitudContacto';
import { getResumenCliente } from '../../services/leasingApi';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import actions from '../main/reducer';
import { Link } from 'react-router-dom';
import routes from '../../config/routes';
import CardContent from '@material-ui/core/CardContent';
import EjecutivoContainer from '../factoring/components/EjecutivoContainer';
import BorderSeparator from '../sharedv2/BorderSeparator/BorderSeparator';
import LoaderComp from '../sharedv2/Loader/LoaderComp';

interface StateProps {
}

interface IProps extends StateProps, WithStyles<typeof styleLeasing> {
  notificacion : (exito : boolean, mensaje : string) => void;
}

const Leasing : FC<IProps> = (props) => {
  const { classes } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [siniestrosForm, setSiniestrosForm] = useState(false);
  const [openSolicitudContacto, setOpenSolicitudContacto] = useState(false);
  const [tipoSolicitud, setTipoSolicitud] = useState('');
  const [requiereBien, setRequiereBien] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resumenCliente, setResumenCliente] = useState<Dto.Leasing.ResumenCliente | undefined>(
    undefined
  );

  useEffect(() => {
    setLoading(true);
    getResumenCliente().then((resp : Dto.Leasing.ResumenCliente) => {
      setResumenCliente(resp);
      setLoading(false);
    });
  }, []);

  const onChangeTab = (event : React.ChangeEvent<any>, value : number) => {
    setActiveTab(value);
  };

  const onFormularioSiniestro = () => {
    setSiniestrosForm(() => !siniestrosForm);
  };

  const abrirModalSolicitud = (tipo : string, requiereBien : boolean) => {
    setTipoSolicitud(tipo);
    setRequiereBien(requiereBien);
    setOpenSolicitudContacto(true);
  };

  return (
    <React.Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12} md={12}>
          {loading ? <LoaderComp /> : (
            <CardContent>
              {!siniestrosForm && resumenCliente && (
                <Grid container spacing={16}>
                  <Grid item xs={12} md={6}>
                    <div className={classes.contratosVigentesContainer}>
                      <Link
                        to={routes.leasing.contratos}
                        className={classes.contratosVigentesNumero}
                      >
                        {resumenCliente.cantidadContratosVigentes}
                      </Link>
                      <Link
                        to={routes.leasing.contratos}
                        className={classes.contratosVigentesTitle}
                      >
                        Contratos
                        <br />
                        Vigentes
                      </Link>
                      <Button
                        classes={{
                          root: classes.otroBtnRequerimientos,
                          label: classes.otroBtnRequerimientosLabel
                        }}
                        onClick={() => abrirModalSolicitud('Solicitud de Contacto', false)}
                      >
                        Solicita un nuevo contrato
                      </Button>
                    </div>
                    <div className={classes.otrosReqCon}>
                      {/*<Button*/}
                      {/*  onClick={() => false}*/}
                      {/*  classes={{*/}
                      {/*    root: classes.btnOtroReq,*/}
                      {/*    label: classes.otrosReqLabel*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  ¿Tienes otro tipo de requerimiento?*/}
                      {/*</Button>*/}
                      <br />
                      <br />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={classes.leftContainer}>
                      <BorderSeparator />
                      <EjecutivoContainer
                        titulo={'Ejecutivo'}
                        nombre={resumenCliente.nombreEjecutivo}
                        email={resumenCliente.correoEjecutivo}
                        telefono={resumenCliente.telefonoEjecutivo}
                        direccion={resumenCliente.direccionEjecutivo}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Tabs
                      value={activeTab}
                      onChange={onChangeTab}
                      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    >
                      <Tab
                        label='SOLICITA TU CERTIFICADO EN LÍNEA'
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabsSelected
                        }}
                      />
                      <Tab
                        label='INFORMA TU SINIESTRO'
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabsSelected
                        }}
                      />
                    </Tabs>
                    {activeTab === 0 && (
                      <div className={classes.tabContent}>
                        <div className={classes.tabContentTitle}>
                          Selecciona el certificado que deseas solicitar
                        </div>
                        <div className={classes.certificadosContent}>
                          <div className={classes.certificadosContainer}>
                            <div
                              className={classes.certificadoBox}
                              onClick={() => abrirModalSolicitud('Tarjeta Copec', false)}
                            >
                              Tarjeta Copec
                            </div>

                            <div
                              className={classes.certificadoBox}
                              onClick={() => abrirModalSolicitud('Duplicado Padrón', true)}
                            >
                              Duplicado Padrón
                            </div>
                            <div
                              className={classes.certificadoBox}
                              onClick={() =>
                                abrirModalSolicitud('Poder Ministerio transporte', false)
                              }
                            >
                              Poder Ministerio transporte
                            </div>
                          </div>
                          <div className={classes.certificadosContainer}>

                            <div
                              className={classes.certificadoBox}
                              onClick={() => abrirModalSolicitud('Certificado deuda', false)}
                            >
                              Certificado deuda
                            </div>
                            <div
                              className={classes.certificadoBox}
                              onClick={() => abrirModalSolicitud('Duplicado PPU', true)}
                            >
                              Duplicado PPU
                            </div>
                            <div
                              className={classes.certificadoBox}
                              onClick={() => abrirModalSolicitud('Otros', true)}
                            >
                              Otros
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === 1 && (
                      <div className={classes.tabContent}>
                        <div className={classes.tabContentTitle}>
                          ¿Cuáles son los pasos a seguir en caso de siniestro?
                        </div>
                        <Grid container>
                          <Grid item xs={12} md={7}>
                            <div className={classes.pasosSeguirContainer}>
                              <div className={classes.itemPasosASeguir}>
                                <div className={classes.pasosNumero}>1</div>
                                <div className={classes.textPasos}>
                                  Llama al <strong>600 411 1000</strong>
                                </div>
                              </div>
                              <div className={classes.itemPasosASeguir}>
                                <div className={classes.pasosNumero}>2</div>
                                <div className={classes.textPasos}>
                                  Completa el siguiente{' '}
                                  <span
                                    className={classes.underline}
                                    onClick={onFormularioSiniestro}
                                  >
                                    Formulario de denuncia siniestro
                                  </span>
                                </div>
                              </div>
                              <div className={classes.itemPasosASeguir}>
                                <div className={classes.pasosNumero}>3</div>
                                <div className={classes.textPasos}>
                                  Adjunta los documentos solicitados: Cedula identidad, Padrón,
                                  Licencia de conducir, formulario de siniestro descargado y firmado
                                  a mano.
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <PlazosContainer />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
              {siniestrosForm && (
                <div className={classes.tabContent}>
                  <div className={classes.tabContentBigTitle}>Formulario de denuncia siniestro</div>
                  <FormularioSiniestro
                    close={() => setSiniestrosForm(false)}
                    notificacion={props.notificacion}
                  />
                </div>
              )}
            </CardContent>
          )}
        </Grid>
      </Grid>
      <SolicitudContacto
        open={openSolicitudContacto}
        onClose={() => setOpenSolicitudContacto(!openSolicitudContacto)}
        tipoSolicitud={tipoSolicitud}
        requiereBien={requiereBien}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state : AppState) : StateProps => ({});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, null, AnyAction>) => ({
  notificacion: (exito : boolean, mensaje : string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(styleLeasing)(connect(mapStateToProps, mapDispatchToProps)(Leasing));

import {
  containerFluid,
  dangerColor,
  defaultBoxShadow,
  defaultFont,
  drawerMiniWidth,
  drawerWidth,
  infoColor,
  primaryColor,
  warningColor
} from '../generalStyle';

import FactotalTheme from '../../FactotalTheme';
import { Theme } from '@material-ui/core';

const headerStyle = (theme : Theme) => ({
  appBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none' as 'none',
    borderBottom: '0',
    marginBottom: '0',
    position: 'absolute' as 'absolute', // Posición fija de appBar para scroll
    width: '100%',
    // paddingTop: "10px",
    zIndex: 1029,
    color: '#555555',
    border: '0',
    borderRadius: '0px',
    padding: '10px 0',
    transition: 'all 150ms ease 0s',
    minHeight: '50px',
    display: 'block'
  },
  container: {
    ...containerFluid,
    minHeight: '50px'
  },
  flex: {
    flex: 1
  },
  title: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none' as 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    '&:hover,&:focus': {
      background: 'transparent'
    }
  },
  primary: {
    backgroundColor: primaryColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  success: { // Color Barra "AppBar" y Textos de la barra
    backgroundColor: FactotalTheme.palette.grey.A200,
    color: FactotalTheme.palette.grey[200],
    boxShadow: '0',
    padding: '3px 0'
  },
  warning: {
    backgroundColor: warningColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor,
    color: '#FFFFFF',
    ...defaultBoxShadow
  },
  sidebarMinimize: {
    float: 'left' as 'left',
    padding: '0 0 0 15px',
    display: 'block',
    color: '#555555',
    position: 'relative' as 'relative', // Posición de botón de minimización
    '&.miniSidebar': {
      marginLeft: `${drawerMiniWidth}px!important`
    },
    '&.normalSidebar': {
      marginLeft: `${drawerWidth}px!important`
    }
  },
  sidebarMinimizeRTL: {
    padding: '0 15px 0 0 !important'
  },
  sidebarMiniIcon: {
    width: '20px',
    height: '17px'
  },
  fixedBar: {
    position: 'fixed' as 'fixed'
    // top: 0,
  }
});

export default headerStyle;

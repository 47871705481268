const loginState = ({
  usuario: {
    isValid: false,
    name: null,
    company: null,
    accessToken: null,
    expiresIn: null,
    tokenType: null,
    refreshToken: null
  }
});

export default loginState;

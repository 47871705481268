import React, {FC} from "react"
import Typography from "@material-ui/core/Typography"
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles"
import Grid from "@material-ui/core/Grid"
import {IconButton, Theme} from "@material-ui/core"
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme: Theme) => ({
  wrapper: {
    paddingTop: 27,
    paddingBottom: 17,
    borderBottom: '1px solid #dbdbdb',
    '&:first-child': {
      borderTop: '1px solid #dbdbdb',
    }
  },
  titleText: {
    fontSize: 16,
  },
  xmlIconWrapper: {
    marginRight: 25
  },
  xmlIcon: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 38
    }
  },
  closeIconWrapper: {
    marginLeft: 'auto'
  }
});

interface IProps extends WithStyles<typeof styles> {
  document: File,
  onDelete?: (id: string) => void
}

const UploadedCertificateListItem: FC<IProps> = (props) => {
  const {
    classes,
    document,
    onDelete
  } = props;
  return (
    <Grid
      container
      className={classes.wrapper}
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h2" className={classes.titleText}>{document.name}</Typography>
      </Grid>
      <Grid item className={classes.closeIconWrapper}>
        <IconButton onClick={onDelete ? () => onDelete(document.name) : undefined}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
};

export default withStyles(styles)(UploadedCertificateListItem)

import axios from 'axios';
import config from '../config/api';
import 'moment/locale/es';
import { factorxHeader } from '../helpers/FactorXHeader';
import { getBase64, getBase64Promise } from '../helpers/Base64ToBlob';
import { buildQueryString } from '../helpers/UrlHelper';

const fxUrlApi = config.fxUrlApi;
const instanceFX = axios.create();

const registrarEmpresa = (data: Response.Login): Promise<{ token: string, idEmpresaPais: any, exito: boolean }> => {
  if (data.empresas === undefined) data.empresas = [];
  let detalle = data.empresas[0];
  let payload = {
    RutUsuario: data.userRut,
    NombreUsuario: data.userName,
    TelefonoUsuario: data.userPhone,
    CorreoElectronicoUsuario: data.userMail,
    IdEmpresa: detalle.enterpriseId,
    RutEmpresa: detalle.rut + '-' + detalle.dv.toUpperCase(),
    RazonSocial: detalle.socialReason,
    Direccion: detalle.address,
    TelefonoEmpresa: detalle.phone
  };
  return instanceFX
    .post(`${fxUrlApi}core/Registrar`,
      payload,
      {
        // headers: factorXHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};


const cargarGlobal = () => {
  const url = `${fxUrlApi}core/ObtenerGlobal`;
  return instanceFX.get(url, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const getCantidadOperacionesDisponibles = (): Promise<Mensaje.ObtenerCantidadOperacionesDisponiblesCesionBackOfficeRespuesta> => {
  let payload: Publico.BackOffice.CantidadOperacionesDisponiblesCesion = {};
  return instanceFX
    .post(`${fxUrlApi}factoring/FtCantidadOperacionesDisponiblesCesion`,
      payload, { headers: factorxHeader() })
    .catch(error => error)
    .then(response => response.data);
};

const getOperacionesEnProceso = (params: Filters.OperacionesProceso): Promise<Mensaje.ObtenerOperacionesBackOfficeRespuesta> => {
  params.pagina = params.pagina ? params.pagina : 1;
  params.tamano = params.tamano ? params.tamano : 10;
  let payload: Publico.BackOffice.ObtenerOperaciones = {
    paginaActual: params.pagina,
    paginaTamano: params.tamano,
    fechaInicio: params.fechaDesde || '',
    fechaTermino: params.fechaHasta || '',
    numeroOperacion: Number(params.numeroOperacion)
  };
  return instanceFX
    .post(`${fxUrlApi}factoring/FtObtenerOperaciones`, //${userEnterpriseRut()}
      payload, { headers: factorxHeader() })
    .catch(error => error)
    .then(response => response.data);
};

const getOperacion = (numeroOperacion: number, paraCeder: boolean) => {
  let payload: Publico.BackOffice.ObtenerOperacion = {
    numeroOperacion: numeroOperacion
  };
  const url = `${fxUrlApi}factoring/${paraCeder ? 'FtGenerarOperacion' : 'FtObtenerOperacion'}`;
  return instanceFX
    .post(url, //${userEnterpriseRut()}
      payload, { headers: factorxHeader() })
    .catch(error => error)
    .then(response => response.data);
};


const verificarPreFactibilidadDocumento = (invoiceId: string): Promise<Factoring.Mensaje.Respuesta.Documento.Factibilidad> => {
  const url = `${fxUrlApi}factoring/VerificaFactibilidadOperar`;

  const modelo: Factoring.Mensaje.Requerimiento.Documento.Factibilidad = {
    idDocumento: Number(invoiceId),
    cesionExterna: false
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);

};

const getDatosEmpresaUsuario = () => {
  const url = `${fxUrlApi}core/ObtenerUsuario`;
  return instanceFX.post(url, {}, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);

};

const getFacturador = (): Promise<Mensaje.ObtenerFacturadorEmpresaRespuesta> => {
  return instanceFX
    .get(`${fxUrlApi}core/ObtenerFacturador`, { headers: factorxHeader() })
    .catch(error => error)
    .then(response => response.data);
};

const subirCertificado = (payload: Payloads.ArchivoCertificado) => new Promise<Mensaje.AgregarCertificadoRespuesta>(resolve => {
  const url = `${fxUrlApi}core/AgregarCertificado`;
  return getBase64(payload.file, (result: any) => {
    let base64 = result.split(',')[1];

    const modelo: Publico.Empresa.AgregarCertificado = {
      certificado: base64,
      clave: payload.password
    };
    instanceFX.post(url, modelo, { headers: factorxHeader() })
              .catch(error => error)
              .then(response => resolve(response.data));
  });
});

const actualizarCertificado = (payload: Payloads.ActualizarCertificadoPayload) => {
  const url = `${fxUrlApi}core/GuardarCertificado`;
  const modelo: Publico.Empresa.GuardarCertificado = {
    idCertificado: payload.idCertificado,
    paraCeder: payload.paraCeder,
    paraContrato: payload.paraContrato,
    porDefecto: false
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const eliminarCertificado = (id: number) => {
  const url = `${fxUrlApi}core/EliminarCertificado`;
  const modelo: Publico.Empresa.EliminarCertificado = {
    idCertificado: id
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const listarCertificados = () => {
  const url = `${fxUrlApi}core/ListarCertificados`;
  const modelo: Publico.Empresa.ListarCertificado = {};

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const agregarFormaPago = (payload: Payloads.CrearFormaPagoPayload): Promise<Mensaje.AgregarFormaPagoRespuesta> => {
  const url = `${fxUrlApi}core/AgregarFormaPago`;
  const modelo: Publico.Empresa.AgregarFormaPago = {
    tipoCuenta: payload.tipo,
    mismaInstitucion: false,
    tipoPago: 1,
    numero: payload.numero,
    predeterminada: payload.predeterminada,
    habilitada: payload.habilitada,
    idBanco: Number(payload.banco),
    esDeTercero: false,
    identificadorTercero: undefined,
    razonSocialTercero: undefined,
    idMoneda: 1
  };
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const actualizarFormaPago = (payload: Payloads.ActualizarFormaPagoPayload): Promise<Mensaje.GuardarFormaPagoRespuesta> => {
  const url = `${fxUrlApi}core/GuardarFormaPago`;
  const modelo: Publico.Empresa.GuardarFormaPago = {
    idFormaPago: payload.idCuentaCorriente,
    predeterminada: payload.predeterminada,
    habilitada: payload.habilitada
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const eliminarFormaPago = (accountId: number) => {
  const url = `${fxUrlApi}core/EliminarFormaPago`;
  const modelo: Publico.Empresa.EliminarFormaPago = {
    idCuentaCorriente: accountId
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const listarFormaPago = () => {
  const url = `${fxUrlApi}core/ListarFormaPago`;
  const modelo: Publico.Empresa.ListarFormasPago = {};

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const subirXmlDocumento = (file: File, idDocumento: number | undefined) => new Promise<Mensaje.SubirDocumentoRespuesta>(async resolve => {
  const url = `${fxUrlApi}documento/DocumentoXmlSubirUsuario`;
  return getBase64(file, (result: any) => {
    let base64 = result.split(',')[1];
    const modelo: Publico.Documento.Subir = {
      contenidoBase64: base64,
      idDocumento: idDocumento
    };

    instanceFX.post(url, modelo, { headers: factorxHeader() })
              .catch(error => error)
              .then(response => resolve(response.data));
  });
});

const solicitarOperacion = (payload: Payloads.SolicitarOperacionPayload) => {
  const url = `${fxUrlApi}factoring/FtSolicitarOperacion`;

  const modelo: Factoring.Mensaje.Requerimiento.Operacion.Solicitar = {
    idsDocumento: payload.ids.map(Number),
    idPublicoSimulacion: undefined
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);

};

const autorizarOperacion = (payload: Payloads.AutorizarOperacionPayload) => {
  const url = `${fxUrlApi}factoring/OperacionAutorizar`;

  const modelo: Factoring.Mensaje.Requerimiento.Operacion.Autorizar = {
    idExterno: payload.numeroOperacion,
    // habilitaSincronizacionDte: payload.habilitaSincronizacionDte,
    idOperacion: undefined
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);

};

const reintentoCesionDocumentosOperacion = (payload: Payloads.ReintentoCesionDocumentosOperacionPayload) => {
  const url = `${fxUrlApi}factoring/OperacionReintentoCesionDocumentos`;
  const modelo: Factoring.Mensaje.Requerimiento.Operacion.ReintentoCesionDocumentos = {
    idExterno: payload.numeroOperacion,
    idsDocumentosAutorizados: payload.idsDocumentosAutorizados.map(Number),
    idOperacion: undefined
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);

};

const actualizarIdentidadCesionOperacion = (modelo: Factoring.Mensaje.Requerimiento.Operacion.ActualizarIdentidadCesion) => {
  const url = `${fxUrlApi}factoring/OperacionActualizarIdentidadCesion`;
  return instanceFX.post<Factoring.Mensaje.Respuesta.Operacion.ActualizarIdentidadCesion>(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const descargaArchivoOperacion = (idOperacion: string, idDocumento: string, nombre: string) => new Promise<Payloads.DescargaArchivoPayload>(async resolve => {
  const url = `${fxUrlApi}factoring/OperacionDescargarArchivo`;

  const modelo: Factoring.Mensaje.Requerimiento.Operacion.DescargarArchivo = {
    idDocumento: idDocumento !== '' ? Number(idDocumento) : undefined,
    idOperacion: Number(idOperacion),
    nombre: nombre
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => {
                     const respuesta = response.data;
                     if (respuesta.exito) {
                       resolve(
                         { bytes: respuesta.bytes, nombre: respuesta.nombre }
                       );
                     }
                   });
});

const verificarAec = (file: File) => new Promise<Documento.Mensaje.Respuesta.Documento.VerificarAec>(async resolve => {
  const url = `${fxUrlApi}documento/DocumentoVerificarAec`;

  return getBase64(file, (result: any) => {
    let base64 = result.split(',')[1];
    const modelo: Documento.Mensaje.Requerimiento.Documento.VerificarAec = {
      contenido: base64
    };

    instanceFX.post(url, modelo, { headers: factorxHeader() })
              .catch(error => error)
              .then(response => resolve(response.data));
  });
});

const recederAec = (payload: Payloads.RecederDocumentoPayload) => new Promise<Documento.Mensaje.Respuesta.Documento.RecederEmpresa>(async resolve => {
  const url = `${fxUrlApi}documento/DocumentoRecederEmpresa`;

  return getBase64(payload.aec, (result: any) => {
    let base64 = result.split(',')[1];
    const modelo: Documento.Mensaje.Requerimiento.Documento.RecederEmpresa = {
      contenido: base64,
      contrasenaCertificado: payload.contrasenaCertificado,
      correoElectronicoNotificacion: payload.correoElectronicoNotificacion,
      direccionCesionario: payload.direccionCesionario,
      idCertificado: payload.idCertificado,
      razonSocialCesionario: payload.razonSocialCesionario,
      identificadorCesionario: { idPais: 1, valor: payload.rutCesionario },
      contrasenaIngresoSucursal: ''
    };

    instanceFX.post(url, modelo, { headers: factorxHeader() })
              .catch(error => error)
              .then(response => resolve(response.data));

  });
});

const enviarArchivosEjecutivo = (payload: Payloads.EnviarArchivosEjecutivoPayload) => new Promise<Mensaje.EnviarArchivosEjecutivoRespuesta>(async resolve => {
  Promise.all(payload.archivos.map(getBase64Promise))
         .then((contents: Payloads.ContenidoArchivo[]) => {
           const url = `${fxUrlApi}correo/NotificacionEnviarArchivosEjecutivo`;

           const modelo: Publico.BackOffice.EnviarArchivosEjecutivo = {
             numeroOperacion: payload.numeroOperacion,
             archivos: contents.map(c => {
               return { nombre: c.file.name, mime: c.file.type, contenido: c.content.split(',')[1] };
             })
           };

           return instanceFX.post(url, modelo, { headers: factorxHeader() })
                            .catch(error => error)
                            .then(response => resolve(response.data));
         });

});

const obtenerDocumentos = (filtros: Filters.Documentos): Promise<Payloads.ObtenerDocumentosExitosoPayload> => {
  const url = `${fxUrlApi}factoring/DocumentosBuscar`;

  const modelo: Factoring.Mensaje.Requerimiento.Documento.Buscar = {
    identificadorReceptor: filtros.razonSocialDeudor || '',
    folios: filtros.folios,
    paginaActual: filtros.pagina,
    paginaTamano: filtros.tamano,
    filtrarComoIntermediario: false,
    tipoDte: Number(filtros.tipoDte || '0'),
    fechaEmisionInicio: (filtros.fechaEmision && filtros.fechaEmision.fechaDesde) || '',
    fechaEmisionTermino: (filtros.fechaEmision && filtros.fechaEmision.fechaHasta) || '',
    soloAbonoEnLinea: false,
    incluyeTodos: false,
    soloDeudoresConLineaPagadora: false
  };

  const getStatus = (doc: Factoring.Dto.Documento.Seleccionable): Comun.Dto.Status => {
    if (doc.colorOperar === 0) {
      return {
        level: 'error',
        message: doc.mensajeError || 'Favor contacte a su ejecutivo comercial para evaluar',
        title: 'Cesión de documento'
      };
    } else if (doc.colorOperar === 3) {
      return {
        level: 'neutral',
        message: 'Factura factible de financiar, sujeto a confirmación comercial',
        title: 'Cesión de documento'
      };
    } else if (doc.colorOperar === 5) {
      return {
        level: 'success',
        message: 'Factura disponible para financiar',
        title: 'Cesión de documento'
      };
    }

    return {
      level: 'neutral',
      message: 'Factura factible de financiar, sujeto a confirmación comercial',
      title: 'Cesión de documento'
    };
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then((response) => {
                     const respuesta: Factoring.Mensaje.Respuesta.Documento.Buscar = response.data;
                     if (respuesta.exito) {
                       const payload: Payloads.ObtenerDocumentosExitosoPayload = {
                         documentos: respuesta.documentos.map(doc => {
                           const inv: Comun.Dto.Invoice = {
                             ...doc,
                             status: getStatus(doc)
                           };
                           return inv;
                         }),
                         filtros: filtros,
                         hayMasRegistros: respuesta.hayMasRegistros
                       };
                       return payload;
                     } else {
                       return {
                         documentos: [],
                         filtros: filtros,
                         hayMasRegistros: false
                       };
                     }
                   });
};

const generarSimulacion = (ids: string[]): Promise<Factoring.Mensaje.Respuesta.Simulacion.Generar> => {
  const url = `${fxUrlApi}factoring/SimulacionGenerar`;

  const modelo: Factoring.Mensaje.Requerimiento.Simulacion.Generar = {
    idsDocumento: ids.map(Number),
    nombre: '',
    idPublicoSimulacion: undefined
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() });
};

const actualizarFechaVencimiento = (payload: Payloads.ActualizarFechaVencimientoPayload) => new Promise(async resolve => {
  const url = `${fxUrlApi}documento/DocumentoModificarFechaVencimiento`;

  const modelo: Publico.Documento.ActualizarFechaVencimiento = {
    idsDocumento: payload.ids.map(Number),
    fechaVencimiento: payload.date
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => {
                     const respuesta: Mensaje.ActualizarFechaVencimientoRespuesta = response.data;
                     if (respuesta.exito) {
                       resolve(respuesta);
                     }
                   });
});

const renovarToken = (idEmpresaPais: Comun.Dto.IdEmpresaPais) => new Promise<Mensaje.RenovarTokenRespuesta>(async resolve => {
  const url = `${fxUrlApi}core/RenovarToken`;

  const modelo: Portal.RenovarToken = {
    idEmpresaPais: idEmpresaPais
  };
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(respuesta => {
                     resolve(respuesta.data);
                   });

});

const subirAnexoDocumento = (id: string, file: File) => new Promise<Mensaje.AdjuntarArchivoDocumentoRespuesta>(async resolve => {
  const url = `${fxUrlApi}documento/DocumentoAdjuntos`;
  return getBase64(file, (result: any) => {
    let base64 = result.split(',')[1];
    const modelo: Mensaje.Documento.AdjuntarArchivo = {
      contenido: base64,
      idDocumento: Number(id),
      mime: file.type,
      nombre: file.name

    };

    instanceFX.post(url, modelo, { headers: factorxHeader() })
              .catch(error => error)
              .then(response => resolve(response.data));
  });
});

const eliminarAnexoDocumento = (id: string, nombre: string) => new Promise<Mensaje.EliminarArchivoDocumentoRespuesta>(async resolve => {
  const modelo: Mensaje.Documento.EliminarArchivo = {
    idDocumento: Number(id),
    nombre: nombre
  };
  const url = `${fxUrlApi}documento/DocumentoAdjuntos?${buildQueryString(modelo, [])}`;

  return instanceFX.delete(url, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(respuesta => {
                     resolve(respuesta.data);
                   });

});

const modificarRegistroSincronizacionTgr = (payload: Payloads.EdicionSincronizacionServicioEmpresaPayload) => {
  const url = `${fxUrlApi}core/SincronizarTgr`;
  const modelo: Publico.Cliente.RegistroSincronizarTgr = {
    clave: payload.clave
  };
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const modificarRegistroSincronizacionPfi = (payload: Payloads.EdicionSincronizacionServicioEmpresaPayload) => {
  const url = `${fxUrlApi}core/SincronizarPfi`;
  const modelo: Publico.Cliente.RegistroSincronizarPfi = {
    clave: payload.clave
  };
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const modificarRegistroSincronizacionAec = (payload: Payloads.EdicionSincronizacionServicioEmpresaPayload) => {
  const url = `${fxUrlApi}core/SincronizarAec`;
  const modelo: Publico.Cliente.RegistroSincronizarAec = {
    clave: payload.clave
  };
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const modificarRegistroSincronizacionCte = (payload: Payloads.EdicionSincronizacionServicioEmpresaPayload) => {
  const url = `${fxUrlApi}core/SincronizarCte`;
  const modelo: Publico.Cliente.RegistroSincronizarCte = {
    clave: payload.clave
  };
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};


const modificarRegistroSincronizacionDte = (payload: Payloads.EdicionSincronizacionDteEmpresaPayload) => new Promise<Mensaje.RegistroSincronizarDteRespuesta>(async resolve => {
  const url = `${fxUrlApi}core/SincronizarDte`;

  if (payload.file) {
    return getBase64(payload.file, (result: any) => {
      let base64 = result.split(',')[1];

      const modelo: Publico.Cliente.RegistroSincronizarDte = {
        usuarioProveedor: '',
        certificado: base64,
        clave: payload.clave,
        idProveedorFacturacion: payload.proveedor

      };
      return instanceFX.post(url, modelo, { headers: factorxHeader() })
                       .catch(error => error)
                       .then(response => resolve(response.data));
    });
  } else {
    const modelo: Publico.Cliente.RegistroSincronizarDte = {
      usuarioProveedor: payload.usuario || '',
      certificado: '',
      clave: payload.clave,
      idProveedorFacturacion: payload.proveedor
    };
    return instanceFX.post(url, modelo, { headers: factorxHeader() })
                     .catch(error => error)
                     .then(response => resolve(response.data));
  }
});

const eliminarRegistroSincronizacionDte = () => {
  const url = `${fxUrlApi}core/EliminarSincronizarDte`;

  const modelo: Publico.Cliente.EliminarSincronizarDte = {};
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const eliminarRegistroSincronizacionTgr = () => {
  const url = `${fxUrlApi}core/EliminarSincronizarTgr`;

  const modelo: Publico.Cliente.EliminarSincronizarTgr = {};
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};
const eliminarRegistroSincronizacionPfi = () => {
  const url = `${fxUrlApi}core/EliminarSincronizarPfi`;

  const modelo: Publico.Cliente.EliminarSincronizarPfi = {};
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};
const eliminarRegistroSincronizacionAec = () => {
  const url = `${fxUrlApi}core/EliminarSincronizarAec`;

  const modelo: Publico.Cliente.EliminarSincronizarAec = {};
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};
const eliminarRegistroSincronizacionCte = () => {
  const url = `${fxUrlApi}core/EliminarSincronizarCte`;

  const modelo: Publico.Cliente.EliminarSincronizarCte = {};
  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};


const tutorialesDisponiblesParaUsuario = (): Promise<Mensaje.ListarTutorialesDisponiblesParaUsuarioRespuesta> => {
  const url = `${fxUrlApi}core/DisponiblesParaUsuario`;

  return instanceFX.get(url, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const obtenerSiguienteTutorialParaUsuario = (): Promise<Mensaje.ObtenerSiguienteTutorialParaUsuarioRespuesta> => {
  const url = `${fxUrlApi}core/ObtenerSiguienteTutorialParaUsuario`;

  return instanceFX.get(url, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

const registrarVisualizacionTutorial = (idTutorial: number): Promise<Mensaje.RegistrarVisualizacionRespuesta> => {
  const url = `${fxUrlApi}core/RegistrarVisualizacion`;

  const modelo: Publico.Portal.RegistrarVisualizacion = {
    idTutorial: idTutorial
  };

  return instanceFX.post(url, modelo, { headers: factorxHeader() })
                   .catch(error => error)
                   .then(response => response.data);
};

export default {
  registrarEmpresa,
  getOperacionesEnProceso,
  getOperacion,
  verificarPreFactibilidadDocumento,
  getDatosEmpresaUsuario,
  subirCertificado,
  listarCertificados,
  eliminarCertificado,
  actualizarCertificado,
  cargarGlobal,
  eliminarCuentaCorriente: eliminarFormaPago,
  agregarCuentaCorriente: agregarFormaPago,
  listarCuentaCorriente: listarFormaPago,
  actualizarCuentaCorriente: actualizarFormaPago,
  subirAnexoDocumento,
  subirXmlDocumento,
  solicitarOperacion,
  autorizarOperacion,
  descargaArchivoOperacion,
  verificarAec,
  recederAec,
  enviarArchivosEjecutivo,
  obtenerDocumentos,
  generarSimulacion,
  actualizarFechaVencimiento,
  modificarRegistroSincronizacionDte,
  modificarRegistroSincronizacionTgr,
  modificarRegistroSincronizacionPfi,
  modificarRegistroSincronizacionAec,
  modificarRegistroSincronizacionCte,
  eliminarRegistroSincronizacionDte,
  eliminarRegistroSincronizacionTgr,
  eliminarRegistroSincronizacionPfi,
  eliminarRegistroSincronizacionAec,
  eliminarRegistroSincronizacionCte,
  reintentoCesionDocumentosOperacion,
  getCantidadOperacionesDisponibles,
  renovarToken,
  eliminarAnexoDocumento,
  tutorialesDisponiblesParaUsuario,
  registrarVisualizacionTutorial,
  obtenerSiguienteTutorialParaUsuario,
  actualizarIdentidadCesionOperacion,
  getFacturador
};

import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import { IconButton, Theme, WithStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';

const styles = (theme: Theme) => ({
  wrapper: {
    paddingTop: 27,
    paddingBottom: 17,
    borderBottom: '1px solid #dbdbdb',
    '&:first-child': {
      borderTop: '1px solid #dbdbdb'
    }
  },
  titleText: {
    fontSize: 16
  },
  xmlIconWrapper: {
    marginRight: 25
  },
  xmlIcon: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: 38
    }
  },
  closeIconWrapper: {
    marginLeft: 'auto'
  }
});

interface IProps extends WithStyles<typeof styles> {
  archivo: Comun.Dto.Archivo;
  onDelete?: (id: string, nombre: string) => void;
}

const UploadedDocumentListItem: FC<IProps> = (props) => {
  const {
    classes,
    archivo,
    onDelete
  } = props;
  return (
    <Grid
      container
      className={classes.wrapper}
      alignItems='center'
    >
      <Grid item className={classes.xmlIconWrapper}>
        <SVGIcons icon='xml' />
      </Grid>
      <Grid item>
        <Typography variant='h2' className={classes.titleText}>{archivo.nombre}</Typography>
      </Grid>
      <Grid item className={classes.closeIconWrapper}>
        <IconButton onClick={onDelete ? () => onDelete(archivo.id, archivo.nombre) : undefined}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(UploadedDocumentListItem);

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import configureStore from './config/store';
import Main from './components/main/view';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Switch } from 'react-router-dom';

const history = createBrowserHistory();
const { PUBLIC_URL } = process.env;

const { store } = configureStore(history);

class App extends Component {
  render() {
    return <React.Fragment>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <BrowserRouter basename={PUBLIC_URL}>
            <Switch>
              <Main />
            </Switch>
          </BrowserRouter>
        </Provider>
      </MuiPickersUtilsProvider>
    </React.Fragment>;
  }
}

export default App;

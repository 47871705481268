// External
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
//import { FilterCarteraFactoring, GetPDF, GetExcel } from "./actions";
// datepicker
import moment from 'moment';
import 'moment/locale/es';

// componentes material ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormLabel from '@material-ui/core/FormLabel';

// componentes custom
import Typography from '@material-ui/core/Typography';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../shared/RangeCalendar/RangeCalendar';

// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Estilo para tabla
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';

// Helpers
import { onChangeRangNumericHelper, validarRangoNroDoc } from '../../../helpers/ValidateNumeric';
import { WithStyles } from '@material-ui/core';

moment.locale('es');

interface IHistorico {
}

interface IProps extends WithStyles<typeof HistoricoStyle> {
}

interface IState extends State.ICalendar {
  expanded : boolean,
  data : IHistorico[],
  openCalendar : boolean,
  anchorElCalendar? : any,
  activeTab : number,
  numeroOperacion : string,
  numeroDocumentoDesde : string,
  numeroDocumentoHasta : string,
  // fechaVencimientoDesde : string,
  estadoDocumento : string,
  estadoDTE : string,
  nombreDeudor : string,

}

class Catalogo extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = {
      openCalendar: false,
      anchorElCalendar: null,
      expanded: true,
      activeTab: 1,
      data: [],
      numeroDocumentoDesde: '',
      numeroDocumentoHasta: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      // fechaVencimientoDesde: moment(new Date())
      //   .format('DD/MM/YYYY'),
      // fechaVencimientoHasta: moment(new Date())
      //   .format('YYYY-MM-DD'),
      estadoDocumento: '',
      estadoDTE: '',
      numeroOperacion: '',
      nombreDeudor: ''
    };
    //this.fetchData = this.fetchData.bind(this);
  }

  buttonGetExcel = () => {
  };

  buttonGetPDF = () => {
  };

  onChangeTab = (active : number) => {
    this.setState((state : IState) => ({ activeTab: active }));
  };

  // getFilters(page : number, pageSize : number) {
  //   return {
  //     numeroDocumentoDesde: this.state.numeroDocumentoDesde,
  //     numeroDocumentoHasta: this.state.numeroDocumentoHasta,
  //     fechaVencimientoDesde: (this.state.calendarValue.desde) ? moment(this.state.calendarValue.desde, 'DD/MM/YYYY')
  //       .format('YYYY-MM-DD') : null,
  //     fechaVencimientoHasta: (this.state.calendarValue.hasta) ? moment(this.state.calendarValue.hasta, 'DD/MM/YYYY')
  //       .format('YYYY-MM-DD') : null,
  //     estadoDocumento: this.state.estadoDocumento,
  //     numeroOperacion: this.state.numeroOperacion,
  //     Deudor: this.state.nombreDeudor,
  //     estadoDte: this.state.estadoDTE,
  //     page: page,
  //     pageSize: pageSize
  //   };
  // }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter : any, row : any, column : any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  handleFiltrar = () => {
    // this.refs.facturaTable.state.page = 0;
    // this.refs.chequeTable.state.page = 0;
    // this.refs.letraTable.state.page = 0;
    // this.refs.pagareTable.state.page = 0;
    // this.refs.facturaTable.fireFetchData();
    // this.refs.chequeTable.fireFetchData();
    // this.refs.letraTable.fireFetchData();
    // this.refs.pagareTable.fireFetchData();
  };

  onChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelectChange = (e : React.ChangeEvent<HTMLSelectElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRangNumeric = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.target.value = onChangeRangNumericHelper(e);
    this.onChange(e);
    //   this.setState({ [e.target.name]: e.target.value });
  };

  onChangeOnlyNumeric = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const valor = e.target.value.replace(/[^0-9]+/gi, '')
                   .replace('(', '')
                   .replace(')', '')
                   .replace('|', '');
    e.target.value = valor;
    this.onChange(e);
    //this.setState({[e.target.name]: e.target.value })
  };

  handleStatesCalendar = (calendar : State.ICalendar) => {
    this.setState(calendar);
  };

  handleToggle = (event : React.ChangeEvent<any>) => {
    this.setState(state => ({ openCalendar: !state.openCalendar }));
    this.setState({ anchorElCalendar: event.currentTarget });
  };


  handleCloseCalendar = () => {
    this.setState({
      anchorElCalendar: null,
      openCalendar: false
    });
  };

  render() {
    const { classes } = this.props;
    const { openCalendar, anchorElCalendar, calendarValue } = this.state;

    return (
      <div>
        <div>
          <Breadcrumb category="Usted se encuentra en: Leasing / Catálogo de productos" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Catálogo de productos
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          // [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title="Filtros de Búsqueda"
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm} autoComplete="off">
                    <GridContainer>
                      <GridItem xs={2}
                                style={{
                                  width: '14.2%',
                                  maxWidth: '14.2%',
                                  flexBasis: '14.2%'
                                }}>
                        <FormControl fullWidth>
                          <FormLabel
                            // component="legend"
                            style={{
                              fontSize: 11,
                              top: 0,
                              left: 0,
                              position: 'absolute'
                            }}
                          >N&deg; Documentos</FormLabel>
                          <GridContainer style={{ marginTop: 8 }}>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id="numeroDocumentoDesde"
                                name="numeroDocumentoDesde"
                                placeholder="Desde"
                                onChange={this.onChangeRangNumeric}
                                onBlur={validarRangoNroDoc}
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id="numeroDocumentoHasta"
                                name="numeroDocumentoHasta"
                                placeholder="Hasta"
                                onChange={this.onChangeRangNumeric}
                                onBlur={validarRangoNroDoc}
                              />
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}
                                style={{
                                  width: '14.2%',
                                  maxWidth: '14.2%',
                                  flexBasis: '14.2%'
                                }}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id="fechaVencimiento"
                            name="fechaVencimiento"
                            label="Fecha Vencimiento"
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}
                                style={{
                                  width: '14.2%',
                                  maxWidth: '14.2%',
                                  flexBasis: '14.2%'
                                }}>
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor="estadoDocumento">Estado documento</InputLabel>
                          <Select
                            className={classes.filterFormSelectField}
                            value={this.state.estadoDocumento}
                            onChange={this.onSelectChange}
                            displayEmpty
                            input={
                              <Input name="estadoDocumento" id="estadoDocumento" />
                            }
                            name="estadoDocumento"
                          >
                            <MenuItem value="">
                              Todos
                            </MenuItem>
                            <MenuItem value={'Vigente'}>Vigente</MenuItem>
                            <MenuItem value={'Moroso'}>Moroso</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}
                                style={{
                                  width: '14.2%',
                                  maxWidth: '14.2%',
                                  flexBasis: '14.2%'
                                }}>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id="numeroOperacion"
                            name="numeroOperacion"
                            label="N&deg; Operación"
                            placeholder="000000"
                            onChange={this.onChangeOnlyNumeric}
                            onBlur={this.onChange}
                            inputProps={{
                              maxLength: '7'
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}
                                style={{
                                  width: '14.2%',
                                  maxWidth: '14.2%',
                                  flexBasis: '14.2%'
                                }}>
                        <FormControl fullWidth>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id="nombreDeudor"
                            name="nombreDeudor"
                            label="Deudor"
                            placeholder="Nombre"
                            onChange={this.onChange}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}
                                style={{
                                  width: '14.2%',
                                  maxWidth: '14.2%',
                                  flexBasis: '14.2%'
                                }}>
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor="estadoDTE">Estado DTE</InputLabel>
                          <Select
                            className={classes.filterFormSelectField}
                            value={this.state.estadoDTE}
                            onChange={this.onSelectChange}
                            displayEmpty
                            input={
                              <Input name="estadoDTE" id="estadoDTE" />
                            }
                            name="estadoDTE"
                          >
                            <MenuItem value="">
                              Todos
                            </MenuItem>
                            <MenuItem value={'Cedido'}>Cedido</MenuItem>
                            <MenuItem value={'Rechazado'}>Rechazado</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer} xs={2}
                                style={
                                  {
                                    width: '14.2%',
                                    maxWidth: '14.2%',
                                    flexBasis: '14.2%'
                                  }}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleFiltrar}
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ position: 'relative' }}>
            <GridItem className={classes.gridPDFExcelButtons}>
              <Tooltip title="Descargar PDF" placement="top">
                <IconButton onClick={() => this.buttonGetPDF()}>
                  <SVGIcons icon="pdf" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descargar Planilla Excel" placement="top">
                <IconButton onClick={() => this.buttonGetExcel()}>
                  <SVGIcons icon="excel" />
                </IconButton>
              </Tooltip>
            </GridItem>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(HistoricoStyle)(Catalogo);

import { ISendMailToExecutiveAtencionClienteApiPayload, sendMailToExecutiveAtencionClienteApi } from '../../../services/factoringApi';


export const SendMailToExecutive = (params : ISendMailToExecutiveAtencionClienteApiPayload) => {
  return sendMailToExecutiveAtencionClienteApi(params)
    .then(response => response)
    .catch(error => error);
};

export default { SendMailToExecutive };

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import Done from '@material-ui/icons/Done';
import ArrowRight from '@material-ui/icons/ArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof SAcceptYieldModal> {
  onClose: () => void;
  open: boolean;
  onAceptar: () => void;
  enviandoSolicitud: boolean;
}


class SolicitarConfirm extends React.Component<IProps> {

  handleClose = () => {
    this.props.onClose();
  };

  handlerContinuar = () => {
    this.props.onAceptar();
  };

  render() {
    const { classes, onClose, open, enviandoSolicitud } = this.props;

    return (
      <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: classes.dialogPaper }}>
        <div className={classes.checkIcon}><Done /></div>
        <h1>Confirmación de la Solicitud</h1>
        <p><ArrowRight />Esta solicitud queda sujeta a evaluación comercial y de riesgo.</p>
        <p><ArrowRight />Si la solicitud es realizada posterior a las 16:00 horas, ésta será procesada el día hábil
          siguiente.</p>
        <div className={classes.containerButtons}>
          <p>¿Confirma envío de solicitud?</p>
          <Button onClick={() => this.handlerContinuar()} disabled={enviandoSolicitud}>
            {enviandoSolicitud ?
              <CircularProgress size={20} />
              : 'Continuar'}
          </Button>
          <Button className={classes.secondaryButton} onClick={onClose}>Cancelar</Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(SolicitarConfirm);

import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import AccountCircle from '@material-ui/icons/AccountCircle';
import sidebarAdminStyle from '../../styles/jss/components/sidebarStyle';
import { Button, WithStyles } from '@material-ui/core';
import { IRuta } from './dashboard';
import IcoCerrarSession from '../../images/icons/ico-cerrar-session.svg';
import { ActionLogout } from '../general/login/actions';
import { AppState } from '../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';

let ps: any;

interface ISidebarWrapperProps {
  className: string;
  user: JSX.Element;
  links: JSX.Element;
  headerLinks?: JSX.Element;
}

interface ISidebarWrapperState {
}

class SidebarWrapper extends React.Component<ISidebarWrapperProps, ISidebarWrapperState> {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      // @ts-ignore
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const {
      className,
      user,
      headerLinks,
      links
    } = this.props;
    return (
      <div className={className} ref='sidebarWrapper'>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

interface IProps extends WithStyles<typeof sidebarAdminStyle>, RouteComponentProps {
  miniActive: boolean;
  logo: string;
  logoText: string;
  image: string | undefined;
  routes: IRuta[];
  bgColor: string;
  user: Comun.Dto.LoginUserInfo;
  dispatch: any;
}

interface IState {
  openAvatar: boolean;
  miniActive: boolean;
}

class SidebarInterno extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      openAvatar: this.activeRoute('/administracion'),
      miniActive: true
    };
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName: string) {
    return this.props.history.location.pathname.indexOf(routeName) > -1;
  }

  // openCollapse(collapse) {
  //   const st = {};
  //   st[collapse] = !this.state[collapse];
  //   this.setState(st);
  // }

  logOutClick = () => {
    this.props.dispatch(ActionLogout());
  };

  render() {
    const {
      classes,
      logo,
      image,
      logoText,
      bgColor
    } = this.props;
    const itemText = classes.itemText + ' ' + cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
    });
    const collapseTitleText = classes.itemTextTitulo + ' ' + cx({
      [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive
    });
    const collapseItemText = classes.collapseItemText + ' ' + cx({
      [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive
    });
    const userWrapperClass = classes.user + ' ' + cx({ [classes.whiteAfter]: bgColor === 'white' });
    const caret = classes.caret;
    const collapseItemMini = classes.collapseItemMini;
    const photo = classes.photo;
    const itemIcon = classes.itemIcon;
    const user = (

      <div className={userWrapperClass + ' ' + classes.userBg}>
        <div className={photo}>
          <AccountCircle className={classes.avatarImg} />
        </div>
        <List className={classes.listUser + ' ' + classes.userBg}>
          <ListItem className={classes.item + ' ' + classes.userItem + ' ' + classes.userBg}>
            <NavLink
              to={'#'}
              className={classes.itemLink + ' ' + classes.userCollapseButton + ' ' + classes.userSpacing}
              //onClick={() => this.openCollapse("openAvatar")}
            >
              <ListItemText
                primary={
                  this.props.user.userName ?
                    <div style={{ color: '#F9F9F9' }}>
                      <div style={{ textTransform: 'capitalize' }}>
                        {this.props.user.userName.toLowerCase()}
                      </div>
                    </div>
                    : ''}

                disableTypography={true}
              />
            </NavLink>
          </ListItem>
        </List>
      </div>
    );

    const links = (
      <List>
        {this.props.routes.filter(r => r.hide !== true).map((route, idx) => {
          const activeRoute = this.activeRoute(route.path);
          return <ListItem key={'link_' + idx} className={classes.collapseItem}>
            <NavLink
              to={route.path}
              className={classes.itemLink + ' ' + classes.userCollapseLinks + ' ' + cx({ [' ' + classes.collapseActive]: activeRoute })}
            >
              <ListItemIcon className={itemIcon + ' ' + cx({ [' ' + classes.itemIconActive]: activeRoute })}>
                {route.icon ? route.icon : <></>}
              </ListItemIcon>

              <ListItemText
                primary={route.name}
                disableTypography={true}
                className={collapseItemText + ' ' + cx({ [' ' + classes.collapseItemTextActive]: activeRoute })}
              />
            </NavLink>
          </ListItem>;
        })}
      </List>
    );

    const logoNormal = classes.logoNormal + ' ' +
      cx({
        [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive
      });
    const logoMini = classes.logoMini;
    const logoClasses = classes.logo + ' ' + cx({ [classes.whiteAfter]: bgColor === 'white' });
    const brand = (
      <div className={logoClasses}>
        <div className={logoMini}>
          <img src={logo} alt='logo' className={classes.img} />
        </div>
        <div className={logoNormal}>
          {logoText}
        </div>
      </div>
    );
    const drawerPaper = classes.drawerPaper + ' ' + cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive
    });
    const sidebarWrapper = cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive
    });
    // @ts-ignore
    const backGroundClass = classes[bgColor + 'Background'];
    return (
      <div ref='mainPanel'>
        <Hidden implementation='css'>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={'right'}
            variant='permanent'
            open
            classes={{
              paper: drawerPaper + ' ' + backGroundClass
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            <div className={classes.cerraSessionContainer}>
              <Button
                classes={{
                  root: classes.cerrarSessionRoot,
                  label: classes.cerrarSessionLabel
                }}
                onClick={this.logOutClick}
              >
                <img src={IcoCerrarSession} />
                Cerrar sesión
              </Button>
            </div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: 'url(' + image + ')' }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({
  dispatch: (action: AnyAction) => dispatch(action)
});


export default withStyles(sidebarAdminStyle)(connect(mapStateToProps, mapDispatchToProps)(SidebarInterno));

import React from 'react';
import { convertBase64ToBlob } from '../../../helpers/Base64ToBlob';
import { DownloadIndividualPdf } from "./actions";
export default class PDFViewer extends React.Component {

    constructor(props) {
        super(props);
        this.viewerRef = React.createRef();
        this.backend = new props.backend();
        this.state = {
            fileURL: "",
        }
    }
    componentDidMount() {
        const { base64 } = this.props;
        this.backend.init(base64);
    }

    printPDF() {
        const { base64 } = this.props;
        const blob = convertBase64ToBlob(base64, "application/pdf");
        const fileURL = URL.createObjectURL(blob);
        const myWindow = window.open(fileURL);
        myWindow.print();
    }
    downloadPdf() {
        const { namePdf } = this.props;
        Promise.all([new Promise(DownloadIndividualPdf(namePdf))])
       .then(data=>data)
    }
    render() {
        const { base64, namePdf } = this.props;
        return (
            <div>
                <div id="pdfViewer">
                    <div className="row header-pdf">
                        <div className="col-md-6 vertical-center" style={{ display: "inline" }}>{namePdf}</div>
                        <div className="col-md-6" style={{ display: "inline", float: "right", marginRight: 20 }}>
                            <a href="#" onClick={() => this.printPDF()}>
                                <ion-icon class="icons" name="print"></ion-icon>
                            </a>
                        </div>
                        <div className="col-md-6" style={{ display: "inline", float: "right", marginRight: 20 }}>
                            <a href={`data:application/pdf;base64,${base64}`} download={`${namePdf}.pdf`} 
                            onClick={() => this.downloadPdf()}>
                                <ion-icon id="btnDescargarPDFViewer" class="icons" name="download"></ion-icon>
                            </a>
                        </div>
                    </div>
                </div>
                <div style={{ padding: "10px 0 10px 0" }}>
                    <ion-icon id="prev" name="arrow-round-back"></ion-icon>
                    &nbsp; &nbsp;
                     <span id="page_num"></span> / <span id="page_count"></span>
                    &nbsp; &nbsp;
                    <ion-icon id="next" name="arrow-round-forward"></ion-icon>
                    &nbsp; &nbsp;
                    <ion-icon id="zoominbutton" name="add-circle-outline"></ion-icon>
                    &nbsp; &nbsp;
                    <ion-icon id="zoomoutbutton" name="remove-circle-outline"></ion-icon>
                </div>
                <div ref={this.viewerRef} id='viewer' style={{ width: '100%', height: '400px', overflowY: 'scroll' }}>
                    <canvas id="the-canvas"></canvas>
                </div>
            </div >
        )
    }
}

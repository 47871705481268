import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../style';
import Dialog from '@material-ui/core/Dialog';
import Done from '@material-ui/icons/Done';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { WithStyles } from '@material-ui/core';

interface IConfirmacionSincronizacionAutomaticaProps extends WithStyles<typeof SAcceptYieldModal> {
  onContinuar : () => void,
  onAutorizar : () => void,
  open : boolean
}

class ConfirmacionSincronizacionAutomatica extends React.Component<IConfirmacionSincronizacionAutomaticaProps> {

  handleContinuar = () => {
    this.props.onContinuar();
  };

  handlerAutorizar = () => {
    this.props.onAutorizar();
  };

  render() {
    const { classes, open } = this.props;

    return (
      <Dialog onClose={this.handleContinuar} className={classes.root} open={open}
              PaperProps={{ className: classes.dialogPaper }}>
        <div className={classes.checkIcon}><Done /></div>
        <h1>Beneficios de automatizar tu carga de DTE</h1>
        <p><ArrowRight />Permitirá que tus operaciones se curse más rápidamente ya que anticiparemos el procesamiento de tus documentos.</p>
        <p><ArrowRight />Accede a ofertas de negocios y promociones exclusivas.</p>
        <div className={classes.containerButtons}>
          <p>Autorizo el uso de mis datos personales y empresariales para los fines adicionales.</p>
          <Button onClick={this.handlerAutorizar}>Autorizar</Button>
          <Button className={classes.secondaryButton} onClick={this.handleContinuar}>Continuar</Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(ConfirmacionSincronizacionAutomatica);

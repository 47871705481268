const hostname = window && window.location && window.location.hostname;

let apiUri = 'https://api-portal-wu.azurewebsites.net/api/';
let fxUrlApi = 'https://prod-api-publica-spf-factotal.azurewebsites.net/api/';
let idleTimeout = 180000;
let apiFactotalCore = 'https://apisonda.factotal.cl/FactotalCoreAPI/api/';
switch (true) {
  case hostname.includes('intranet-ft.azurewebsites.net'): {
    apiFactotalCore ='https://apisonda.factotal.cl/FactotalCoreAPITST/api/';
    apiUri = 'https://apifactotal-dev.azurewebsites.net/api/';
    fxUrlApi = 'https://qa-api-publica-spf-factotal.azurewebsites.net/api/';
    break;
  }
  case hostname.includes('intranet-ft-qa.azurewebsites.net'): {
    apiFactotalCore='https://apisonda.factotal.cl/FactotalCoreAPITST/api/';
    apiUri = 'https://apifactotal-qa.azurewebsites.net/api/';
    fxUrlApi = 'https://qa-api-publica-spf-factotal.azurewebsites.net/api/';
    break;
  }
  case hostname.includes('localhost'): {
    apiFactotalCore='https://apisonda.factotal.cl/FactotalCoreAPITST/api/';
    apiUri = 'https://apifactotal-dev.azurewebsites.net/api/';
    fxUrlApi = 'https://qa-api-publica-spf-factotal.azurewebsites.net/api/';
    idleTimeout = 18000000;
    break;
  }
  default: {
    break;
  }
}

// apiUri = 'https://apifactotal-qa.azurewebsites.net/api/';
// fxUrlApi = 'https://qa-api-publica-spf-factotal.azurewebsites.net/api/';

const config = {
  apiUri,
  apiFactotalCore,
  fxUrlApi,
  idleTimeout
};

export default config;

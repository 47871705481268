export function factorxHeader() {
  // return authorization header with jwt token
  let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
  let fxToken = sessionStorage.getItem('fx-token') || '';
  if (user && user.accessToken) {
    return { 'Authorization': 'Bearer ' + fxToken };
  } else {
    return {};
  }
}


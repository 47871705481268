import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LoaderSpinner from 'react-loader-spinner'
import Style from "../../../styles/jss/components/extendedFormsStyle";

function Loader({ ...props }) {
  return (
    props.loadTable ?
      <LoaderSpinner type="Bars" color="#82BC00"/> 
    :
      <div className={props.classes.customLoader}>
        <div className={props.classes.customLoaderContent}>
          <LoaderSpinner type="Bars" color="#82BC00" /> 
        </div>         
      </div>
  );
}

Loader.propTypes = {
  classes: PropTypes.object.isRequired,
  loadTable: PropTypes.bool
};

export default withStyles(Style)(Loader);

import React, { FC } from 'react';
import { Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import scssTool from '../../styles/scssTool';
import IcoDashedGreenArrow from '../../images/icons/ico-dashed-green-arrow.svg';
import IcoClienteCotiza from '../../images/icons/ico-cliente-cotiza.svg';
import IcoCreditia from '../../images/icons/ico-crediticia.svg';
import IcoFirmaContrato from '../../images/icons/ico-firmar-contrato.svg';
import IcoFtLeasing from '../../images/icons/ico-ft-leasing.svg';
import IcoEntregaEquipo from '../../images/icons/ico-entrega-equipo.svg';
import IcoDesembolsoOperacion from '../../images/icons/ico-desombolso-operacion.svg';
import EjecutivoContainer from '../factoring/components/EjecutivoContainer';
import FactotalTheme from '../../styles/FactotalTheme';
import BorderSeparator from '../sharedv2/BorderSeparator/BorderSeparator';
import NegociosForm from '../shared/NegociosForm/form';
import MesaComercialContainer from '../factoring/components/MesaComercialContainer';

const leasingStyle = (theme : Theme) => ({
  containerFac: {
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
    padding: '0px 10px'
  },
  leftContainer: {
    ...scssTool.flex('flex-start', 'row'),
    height: '100%'
  },
  facTitle: {
    ...scssTool.font(16, 'bold', 24),
    color: '#000000'
  },
  facParraf: {
    ...scssTool.font(14, 400, 18),
    color: '#414141',
    margin: '16px 0px',
  },
  formContactoColumn: {
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
  },
  btnSolicitar: {
    background: '#94C11F',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    width: '196px',
    height: '59px',
    '&:hover': {
      background: '#94C11F',
    },
  },
  btnSolicitarLabel: {
    ...scssTool.font(16, 'bold', 24),
    color: 'white',
  },
  inputTextfield: {
    width: '100%',
    marginBottom: '12px',
  },
  listColumnContainer: {
    ...scssTool.flex('center', 'column', 'center'),
    width: '80px',
  },
  listIconContainer: {
    ...scssTool.flex('flex-start', 'row', 'space-between'),
    height: '180px',
    width: '100%',
  },
  greenCircle: {
    background: FactotalTheme.palette.primary.main,
    width: '18px',
    height: '18px',
    borderRadius: '18px',
    ...scssTool.font(12, 'bold', 17),
    ...scssTool.flex('center', 'row', 'center'),
    color: '#fff',
    marginBottom: '10px',
  },
  listTitle: {
    ...scssTool.font(12, 400, 17),
    color: '#414141',
    marginTop: '16px',
    width: '100%',
    textAlign: 'center' as 'center',
  },
  icoListInside: {
    height: '50px',
    ...scssTool.flex('center', 'column', 'center'),
  },
  contentIconDashed: {
    ...scssTool.flex('center', 'column', 'center'),
    height: '80%',
  },
});

interface ILeasingProducto extends WithStyles<typeof leasingStyle> {
}

const LeasingProducto : FC<ILeasingProducto> = (props) => {
  const { classes } = props;

  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={7}>
        <div className={classes.containerFac}>
          <div className={classes.facTitle}>Solicita tu Leasing</div>
          <div className={classes.facParraf}>
            Leasing es una alternativa de financiamiento que cuenta con excelentes ventajas
            financieras y tributarias para adquirir bienes de capital que lo ayuden al desarrollo de
            su actividad empresarial.
          </div>
          <div className={classes.listIconContainer}>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>1</div>
              <div className={classes.icoListInside}>
                <img src={IcoClienteCotiza} alt='' />
              </div>
              <div className={classes.listTitle}>Cliente cotiza equipo a financiar</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>2</div>
              <div className={classes.icoListInside}>
                <img src={IcoCreditia} alt='' />
              </div>
              <div className={classes.listTitle}>Evaluación comercial y crediticia</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>3</div>
              <div className={classes.icoListInside}>
                <img src={IcoFirmaContrato} alt='' />
              </div>
              <div className={classes.listTitle}>Firma de contrato</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>4</div>
              <div className={classes.icoListInside}>
                <img src={IcoFtLeasing} alt='' />
              </div>
              <div className={classes.listTitle}>FT Leasing adquiere equipo</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>5</div>
              <div className={classes.icoListInside}>
                <img src={IcoEntregaEquipo} alt='' />
              </div>
              <div className={classes.listTitle}>Entrega de equipo al cliente</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>6</div>
              <div className={classes.icoListInside}>
                <img src={IcoDesembolsoOperacion} alt='' />
              </div>
              <div className={classes.listTitle}>
                Cliente paga sus cuotas pudiendo quedarse con el equipo al final del contrato
              </div>
            </div>
          </div>
          <NegociosForm textoBoton={'Solicitar leasing'} tipoNegocioForm={'Leasing'} />
        </div>
      </Grid>
      <Grid item xs={12} md={5}>
        <div className={classes.leftContainer}>
          <BorderSeparator />
          <MesaComercialContainer />
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(leasingStyle)(LeasingProducto);

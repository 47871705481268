// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import { TrackingSidebar } from '../factoring/actions';
import TableroFactoring from './factoring';
import TableroLeasing from './leasing';
import TableroVendor from './vendor';
// componentes material ui
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import ImgLeasing from '../../images/LeasingBanner.png';
import ImgVendor from '../../images/VendorBanner.jpg';
import ImgFactoring from '../../images/FactoringBanner.jpg';
// Estilos
import DashboardStyle from '../../styles/dashboardStyle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from '../../config/rootReducer';
import { WithStyles } from '@material-ui/core';
import TabsDashboard from './components/TabsDashboard';
import BannerSolicitar from './components/BannerSolicitar';
import routes from '../../config/routes';
import SlideLeasing from './components/SlideLeasing';

interface IProps extends WithStyles<typeof DashboardStyle>, RouteComponentProps {
  userInfo: Comun.Dto.LoginUserInfo | undefined;
}

interface IState {
  activeTab: number;
}

class DashBoard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: 1
    };
  }

  componentDidMount = async () => {
    const empresa = this.props.userInfo?.empresas[0];

    const esFactoring = empresa && empresa.factoring;
    const esLeasing = empresa && empresa.leasing;
    const esVendor = empresa && empresa.vendor;

    if (esFactoring) {
      this.setState({ activeTab: 1 });
    } else if (esLeasing) {
      this.setState({ activeTab: 2 });
    } else if (esVendor) {
      this.setState({ activeTab: 3 });
    }
  };

  // async trackingSidebar(param : string) {
  //   await TrackingSidebar(param);
  //   switch (param) {
  //     case 'leasing':
  //       window.open('https://www.factotal.cl/leasing', '_blank');
  //       break;
  //     case 'vendor':
  //       window.open('https://www.factotal.cl/vendor_finance', '_blank');
  //       break;
  //     case 'seguros':
  //       window.open('https://www.factotal.cl/seguros', '_blank');
  //       break;
  //     default:
  //       break;
  //   }
  // }

  render() {
    const { classes } = this.props;

    const empresa = this.props.userInfo?.empresas[0];

    return (
      <div>
        <Grid
          container
          spacing={16}
          direction='row'
          alignItems='flex-start'
          justify='flex-start'
        >
          <Grid item xs={12}>
            <Card
              classes={{
                root: classes.newElevationCard
              }}
            >
              <TabsDashboard
                setActive={(active) => this.setState({ activeTab: active })}
                activeTab={this.state.activeTab}
              />
              {this.state.activeTab === 1 && empresa && (
                <TableroFactoring empresa={empresa} />
              )}
              {this.state.activeTab === 2 && empresa && (
                <TableroLeasing empresa={empresa} />
              )}
              {this.state.activeTab === 3 && empresa && (
                <TableroVendor empresa={empresa} />
              )}
            </Card>
          </Grid>
          <Grid item xs={12}>
            {this.state.activeTab === 1 && <SlideLeasing />}
            {this.state.activeTab === 2 && (
              <>
                <BannerSolicitar
                  img={ImgFactoring}
                  titulo='Haz factoring con nosotros'
                  subtitulo='Obtén el capital que necesitas adelantando tus facturas.'
                  botonTexto='Solicitar factoring'
                  action={() =>
                    this.props.history.push(routes.factoring.producto)
                  }
                />
                <br />
                <BannerSolicitar
                  img={ImgVendor}
                  titulo='¿Buscas leasing tecnológico?'
                  subtitulo='Encuéntralo también en nuestro sitio Factotal.'
                  botonTexto='Solicitar leasing tecnológico'
                  action={() => this.props.history.push(routes.vendor.producto)}
                />
              </>
            )}
            {this.state.activeTab === 3 && (
              <>
                <BannerSolicitar
                  img={ImgFactoring}
                  titulo='Haz factoring con nosotros'
                  subtitulo='Obtén el capital que necesitas adelantando tus facturas.'
                  botonTexto='Solicitar factoring'
                  action={() =>
                    this.props.history.push(routes.factoring.producto)
                  }
                />
                <br />
                <BannerSolicitar
                  img={ImgLeasing}
                  titulo='¿Buscas leasing?'
                  subtitulo='Encuéntralo también en nuestro sitio Factotal.'
                  botonTexto='Solicitar leasing'
                  action={() =>
                    this.props.history.push(routes.leasing.producto)
                  }
                />
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.main.isLoading,
  userInfo: state.main.userInfo
});

export default withStyles(DashboardStyle)(
  connect(mapStateToProps)(withRouter(DashBoard))
);

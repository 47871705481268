import axios from 'axios';
import config from '../config/api';
import { buildQueryString } from '../helpers/UrlHelper';
import { authHeader } from '../helpers/AuthHeader';
import { logout } from './userApi';
import 'moment/locale/es';
import { crearEmpresaParams } from '../components/admin/empresa/actions';

const apiUserUrl = config.apiUri;

export const instance = axios.create();

instance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const { config, response: { status } } = error;
  const originalRequest = config;
  if (status === 401) {
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
      const payload: Dto.RefreshTokenRequest = { refreshToken: user.refreshToken };
      return axios.post(`${apiUserUrl}auth/login/refresh`, payload)
                  .then((res) => {
                    isAlreadyFetchingAccessToken = false;
                    user.accessToken = res.data.accessToken;
                    sessionStorage.setItem('user', JSON.stringify(user));
                    // window.location.reload();
                    return res;
                  })
                  .catch(e => {
                    logout();
                    return e;
                  });
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken: string) => {
        originalRequest.headers.Authorization = 'Bearer ' + accessToken;
        resolve(axios(originalRequest));
      });
    });
    return retryOriginalRequest;
  }
  return Promise.reject(error);
});

let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function addSubscriber(callback: any) {
  subscribers.push(callback);
}

export const userEnterpriseRut = () => {
  const userInfo: Response.Login = JSON.parse(sessionStorage.getItem('user') || '');
  let _rut = userInfo.empresas !== undefined && userInfo.empresas[0] && userInfo.empresas[0].rut && userInfo.empresas[0].dv ? userInfo.empresas[0].rut.toString() + '-' + userInfo.empresas[0].dv : null;
  if (_rut == null) return null;
  return _rut;
};

const userEnterpriseId = () => {
  const userInfo: Response.Login = JSON.parse(sessionStorage.getItem('user') || '');
  let _rut = userInfo.empresas !== undefined && userInfo.empresas[0] && userInfo.empresas[0].enterpriseId ? userInfo.empresas[0].enterpriseId : null;
  if (_rut == null) return null;
  return _rut;
};

export const getCarteraFactoring = (params: Filters.CarteraFactoring | Filters.CobranzaFactoring): Promise<Response.Cartera | Blob> => {
  params.numeroPagina = params.numeroPagina ? params.numeroPagina + 1 : 1;
  params.tamanoPagina = params.tamanoPagina ? params.tamanoPagina : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/carterafactoring?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        } :
        // params.isExportExcel ?
        //   {
        //     responseType: 'blob',
        //     headers: { ...authHeader() }
        //   }
        //   :
        {
          headers: authHeader()
        }
    )
    .catch(error => error)
    .then(response => response.data);
};

export const getDetalleCarteraFactoring = (params: Filters.DetalleCobranzaFactoring): Promise<Response.DetalleCobranza | Blob> => {
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/detallecobranza?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        : {
          headers: authHeader()
        }
    )
    .catch(error => error)
    .then(response => response.data);
};

export const getFacEmitidasFactoring = (params: any) => {
  params.page = params.page ? params.page + 1 : 1;
  params.pageSize = params.pageSize ? params.pageSize : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/facturasemitidas?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        params.isExportExcel ?
          {
            responseType: 'blob',
            headers: { ...authHeader() }
          }
          :
          {
            headers: authHeader()
          }
    )
    .catch(error => error)
    .then(response => response.data);
};

export const getFacturasEmitidas = (params: any) => {
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/facturasEmitidas?${queryString}`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};


export const getFacEmitidasIndividualPDF = (params: any) => {
  return instance
    .get(`${apiUserUrl}factoring/${params.numeroFactura}/facturapdf/${params.tipoDocumento}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        {
          headers: authHeader()
        }
    )
    .catch(error => error)
    .then(response => response);
};


export const getResumenCartera = (params: any) => {
  params.page = params.page ? params.page + 1 : 1;
  params.pageSize = params.pageSize ? params.pageSize : 10;
  params.rut = userEnterpriseRut();
  const queryString = buildQueryString(params, []);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/resumencartera?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        {
          headers: authHeader()
        })
    .catch(error => error)
    .then(response => response.data);
};

export const getEjecutivos = () => {
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/contactos`,
      {
        headers: authHeader()
      })
    .catch(error => error)
    .then(response => response.data);
};

export const getDetalleCobranza = (params: any) => {
  params.page = params.page ? params.page + 1 : 1;
  params.pageSize = params.pageSize ? params.pageSize : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/detallecobranza?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        params.isExportExcel ?
          {
            responseType: 'blob',
            headers: { ...authHeader() }
          }
          : { headers: authHeader() }
    )
    .catch(error => error)
    .then(response => response.data);
};

export const getHistoricoOperaciones = (params: any) => {
  params.page = params.page ? params.page + 1 : 1;
  params.pageSize = params.pageSize ? params.pageSize : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/historicooperaciones?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        } :
        params.isExportExcel ?
          {
            responseType: 'blob',
            headers: { ...authHeader() }
          }
          : { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};
export const eventTrackingToExcel = (params: string) => {
  return instance
    .get(`${apiUserUrl}factoring/trackingBotonToExcel/${userEnterpriseRut()}/${params}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response);

};
export const trackingIndividualPdf = (params: any) => {
  let eventName = params.split('-') ? params.split('-')[1].toLowerCase() : '';
  let numeroOperacion = params.split('-') ? parseInt(params.split('-')[0]) : 0;
  return instance
    .get(`${apiUserUrl}factoring/trackingIndividualPdf/${userEnterpriseRut()}/${eventName}PdfIndividual/${numeroOperacion}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response);

};
export const trackingSidebar = (params: string) => {
  return instance
    .get(`${apiUserUrl}factoring/trackingSidebar/${userEnterpriseRut()}/${params}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response);
};
export const getExcedenteCursadoIndividualPDF = (params: any) => {
  buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/liquidacionexcedentecursado/${params.numLiquidacion}/`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        {
          headers: authHeader()
        }
    )
    .catch(error => error)
    .then(response => response);
};

export const getHistoricoOperacionesIndividualPDF = (params: any) => {
  buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/liquidacionhistoricooperacion/${params.numeroOperacion}/${params.tipoDocumento}/`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        {
          headers: authHeader()
        }
    )
    .catch(error => error)
    .then(response => response);
};

export const getDetalleExcedentesDisponibles = (params: any) => {
  params.page = params.page ? params.page + 1 : 1;
  params.pageSize = params.pageSize ? params.pageSize : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/detalleexcedentes?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        params.isExportExcel ?
          {
            responseType: 'blob',
            headers: { ...authHeader() }
          }
          : { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const solicitudExcedentesDisponibles = (params: any) => {
  let userEnterpriseData: Response.Login = JSON.parse(sessionStorage.getItem('user') || '');
  params.userId = userEnterpriseData.userId;
  params.enterpriseId = userEnterpriseId();
  params.enterpriseRut = userEnterpriseRut();
  return instance
    .post(`${apiUserUrl}factoring/solicitudexcedente`,
      params,
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
          ...authHeader()
        }
      })
    .catch(error => error)
    .then(response => response.data);
};

export const getDetalleExcedentesHistorico = (params: any) => {
  params.page = params.page ? params.page + 1 : 1;
  params.pageSize = params.pageSize ? params.pageSize : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/detalleexcedentescursados?${queryString}`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        : { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const getDetalleExcedentesHistoricoPDF = (params: any) => {
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}factoring/mockPdf/1`,
      params.isExportPdf ?
        {
          responseType: 'blob',
          headers: {
            'Accept': 'application/pdf',
            ...authHeader()
          }
        }
        :
        {
          headers: authHeader()
        }
    )
    .catch(error => error)
    .then(response => response);
};

export const getFacturaPdf = (params: any) => {
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/facturapdf/${params.numeroFactura}`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const getRutEjecutivo = (params: any) => {
  return instance
    .get(`${apiUserUrl}factoring/${userEnterpriseRut()}/ejecutivo`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const getDetalleEmpresas = (): Promise<Dto.EnterpriseProfile[]> => {
  return instance
    .post(`${apiUserUrl}Util/getEnterprise`, null, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const crearEmpresa = (params: crearEmpresaParams): Promise<boolean> => {
  let _rut = params.rut.split('-');
  let data: Dto.ImportEnterprise = {
    enterpriseProfiles: [
      {
        enterpriseId: 0,
        rut: Number(_rut[0]),
        dv: _rut[1],
        completeRut: _rut[0] + '-' + _rut[1],
        socialReason: params.socialReason,
        address: params.address,
        phone: params.phone,
        executiveType: 0,
        executiveTypeDescription: 'string',
        email: '',
        enable: true,
        factoring: false,
        leasing: false,
        vendor: false,
        registroCivil: false
      }
    ],
    token: params.token
  };
  return instance
    .post(`${apiUserUrl}Util/importEnterprise`, data, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const editEmpresa = (params: Dto.EditEnterprise): Promise<boolean> => {
  return instance
    .post(`${apiUserUrl}Util/editEnterprise`, params, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data === true);
};

export const changeStatusEmpresa = (params: Dto.ChangeStatusEnterprise): Promise<boolean> => {
  return instance
    .post(`${apiUserUrl}Util/changeStatusEnterprise`, params, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data === true);
};

export const getDetalleUsuarios = (): Promise<Dto.User[]> => {
  return instance
    .get(`${apiUserUrl}Users/getUsers`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export const crearUsuario = (params: Dto.CreateUser): Promise<boolean> => {
  return instance
    .post(`${apiUserUrl}Users/createUser`, params, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data === true);
};
export const updateUserEnterpriseApi = (jobId: string, rutEmpresa: string) => {
  return instance
    .get(`${apiUserUrl}Users/updateUsersEnterprise?jobId=${jobId}&rutEmpresa=${rutEmpresa}`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response);
};
export const downloadTrackingUsersApi = () => {
  return instance
    .post(`${apiUserUrl}Util/downloadTrackingUsers`, null, { headers: authHeader() })
    .catch(error => error)
    .then(response => response);
};

export const editUsuario = (params: Dto.User) => {
  return instance
    .post(`${apiUserUrl}Users/editUser`, params, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data === true);
};

export const changeStatusUsuario = (params: Dto.ChangeStatusUser): Promise<boolean> => {
  return instance
    .post(`${apiUserUrl}Users/changeStatusUser`, params, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data === true);
};

export const saveInfoComercial = (paramsObject: any) => {
  paramsObject.userRut = userEnterpriseRut();
  const queryString = buildQueryString(paramsObject, []);
  return instance
    .get(`${apiUserUrl}auth/userProfile/save?${queryString}`, { headers: authHeader() });
};

export const generateMarketingAccountAPI = (params: { rutUsuario: string }): Promise<Dto.UserProfile> => {

  let userData: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
  const params2 = {
    rutEmpresa: userData && userData.empresas && userData.empresas[0].rut && userData.empresas[0].dv ? userData.empresas[0].rut.toString() + '-' + userData.empresas[0].dv : '',
    rutUsuario: params.rutUsuario
  };
  const queryString = buildQueryString(params2, []);
  return instance
    .get(`${apiUserUrl}auth/userProfile/generateMarketingAccount?${queryString}`, { headers: authHeader() })
    .then(r => r.data)
    .catch(c => c);
};

export interface ISendMailToExecutiveAtencionClienteApiPayload {
  nameContactNegocio?: string,
  emailContactNegocio?: string,
  phoneContactNegocio?: string,
  messageContactNegocio?: string,
  typeNegocio?: string,
  isContactNegocio?: boolean,
  rut?: string,
  name?: string,
  contactName?: string,
  phone?: string,
  comments?: string,
}

export const sendMailToExecutiveAtencionClienteApi = (params: ISendMailToExecutiveAtencionClienteApiPayload) => {
  const queryString = buildQueryString(params, []);
  return instance
    .get(`${apiUserUrl}auth/userProfile/sendMailAtencionCliente?${queryString}`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};
export const getMarketingUsersAPI = (): Promise<Dto.UserProfile[]> => {
  return instance
    .get(`${apiUserUrl}auth/userProfile/getMarketingUsers`, { headers: authHeader() })
    .then(r => r.data)
    .catch(c => c);
};

export const getDetalleUsuario = () => {
  return instance
    .get(`${apiUserUrl}auth/login/carterafactoring`, { headers: authHeader() })
    .catch(error => error)
    .then(response => response.data);
};

export default {
  getCarteraFactoring,
  getDetalleCobranza,
  getDetalleExcedentesDisponibles,
  getDetalleExcedentesHistorico,
  getRutEjecutivo,
  getFacturaPdf,
  getFacturasEmitidas,
  getHistoricoOperaciones,
  getResumenCartera,
  getFacEmitidasIndividualPDF,
  getHistoricoOperacionesIndividualPDF,
  getDetalleExcedentesHistoricoPDF,
  eventTrackingToExcel,
  trackingSidebar,
  getExcedenteCursadoIndividualPDF,
  getEjecutivos,
  solicitudExcedentesDisponibles,
  generateMarketingAccountAPI,
  updateUserEnterpriseApi,
  sendMailToExecutiveAtencionClienteApi,
  saveInfoComercial,
  getDetalleUsuario,
  getMarketingUsersAPI
};

import { Button, Select, TextField } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useMemo, useState } from 'react';
import contratosGeneralStyle from '../../contratosGeneralStyle';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { CALIDAD, COMBUSTIBLE, COMUNAS, IVehiculo, REGIONES, TIPO_CARGA_PESOS, VEHICULOS_LM, COLORES } from '../Selectores';
import { TModeloVehiculo, TModeloIV } from '../../Contrato/primeraInscripcion';
import registroCivilApi from '../../../../services/registroCivilApi';

const iniTipoVehiculo = (): IVehiculo => ({ texto: '', id: '', impuestoVerde: false, carga: false, citModelo: false });

interface IProps extends WithStyles<typeof contratosGeneralStyle> {
  modelo: TModeloVehiculo;
  modeloIV: TModeloIV;
  setState: (modelo: keyof TModeloVehiculo, value: string) => void;
  setStateIV: (modelo: keyof TModeloIV, value: string) => void;
  region: string;
  setRegion: (region: string) => void;
  color: string;
  setColor: (color: string) => void;
}

const InscripcionVehiculo: FC<IProps> = (props) => {
  const { classes, modelo, modeloIV, setState, setStateIV, region, setRegion, color, setColor } = props;
  const [open, setOpen] = useState(false);
  const [tipoVehiculo, setTipoVehiculo] = useState<IVehiculo>(iniTipoVehiculo);
  const years = () => Array.from({ length: 8 }, (el, index) => new Date().getFullYear() - index + 1);

  let tiposPPUInicial: number[] = [];
  const [tiposPPU, setTiposPPU] = useState(tiposPPUInicial);

  useEffect(() => {
    const tv = VEHICULOS_LM.find(r => r.id === modelo.tipoVehiculo.toString());
    setTipoVehiculo(tv || iniTipoVehiculo());
  }, [modelo.tipoVehiculo]);

  useEffect(() => {
    registroCivilApi.getTerminacionesDisponiblesPPU({
      idRegion: Number(region),
      tipoPlaca: 'A'
    }).then(resp => {
      if (resp && resp.terminaciones) {
        setTiposPPU(resp.terminaciones);
        console.log(tiposPPU);
      }

    });
  }, [region]);

  const correspondeImpuestoVerde = useMemo(() => {
    if (tipoVehiculo.impuestoVerde === false) return false;
    if (tipoVehiculo.impuestoVerde === true) return true;
    const asi = Number(modelo.asientos);
    const pbv = Number(modelo.pesoBrutoVehicular.replaceAll(',', '.')) / (Number(modelo.tipoPesoBruto) === 1 ? 1000 : 1);
    const car = Number(modelo.carga.replaceAll(',', '.')) / (Number(modelo.tipoCarga) === 1 ? 1000 : 1);
    if (tipoVehiculo.id === '4') { //camioneta
      return pbv < 3.86 && car < 2.0;
    } else if (tipoVehiculo.id === '5') { //camioneta
      return asi < 10 && pbv < 3.86;
    }
    return false;
  }, [tipoVehiculo, modelo.carga, modelo.tipoCarga, modelo.pesoBrutoVehicular, modelo.tipoPesoBruto, modelo.asientos]);

  const resolverColor = (_idColor: string) => {
    const selectedColor = COLORES.find(a => a.key === _idColor.split(';')[1]);
    // @ts-ignore
    setColor(selectedColor.id + ';' + selectedColor.key);
    // @ts-ignore
    return selectedColor.id + ';' + selectedColor.key;
  };
  return (
    <div className={classes.greySeccion}>
      <div className={classes.seccionHeader}>
        <div className={classes.leftSeccionHeader}>
          <div className={classes.titleSeccionHeader}>Vehículo</div>
          <div className={classes.subtSeccionHeader}>
            Ingrese la información relativa al vehículo
          </div>
        </div>
        <div className={classes.openButton} onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={classes.containerSeccion}>
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Región</div>
              <Select
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                className={[classes.selectField].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >
                {REGIONES.map((v, i) => (<MenuItem value={v.id}>{v.texto}</MenuItem>))}
              </Select>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Placa terminada en</div>
              <Select
                value={modelo.terminacionPpu}
                onChange={(e) => setState('terminacionPpu', e.target.value)}
                className={[classes.selectField, classes.midleInput].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >

                {tiposPPU.map(x => (
                  <MenuItem key={x} value={x}>{x}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Tipo Vehículo</div>
            <Select
              value={modelo.tipoVehiculo}
              onChange={(e) => setState('tipoVehiculo', e.target.value)}
              className={classes.selectField}
              classes={{
                outlined: classes.outlinesSelect
              }}
              displayEmpty={true}
              variant='outlined'
            >
              <MenuItem value={''} selected>Seleccione Vehículo</MenuItem>
              {VEHICULOS_LM.map((x, i) => (<MenuItem value={x.id}>{x.texto}</MenuItem>))}
            </Select>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Marca</div>
              <TextField
                value={modelo.marca}
                onChange={(e) => setState('marca', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='MITSUBISHI'
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Modelo</div>
              <TextField
                value={modelo.modelo}
                onChange={(e) => setState('modelo', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='IMPREZA'
              />
            </div>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Combustible</div>
              <Select
                value={modelo.combustible}
                onChange={(e) => setState('combustible', e.target.value)}
                className={[classes.selectField, classes.middleSelect].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >
                <MenuItem value={''} selected>Seleccione Vehículo</MenuItem>
                {COMBUSTIBLE.map((x, i) => (<MenuItem value={x.id}>{x.texto}</MenuItem>))}
              </Select>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Año Fabricación</div>
              <Select
                value={modelo.anoFabricacion}
                onChange={(e) => setState('anoFabricacion', e.target.value)}
                className={[classes.selectField, classes.litleSelect].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >
                {years().map((x, i) => (<MenuItem value={x}>{x}</MenuItem>))}
              </Select>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Asientos</div>
              <TextField
                value={modelo.asientos}
                onChange={(e) => setState('asientos', e.target.value)}
                className={[classes.textField, classes.litleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='4'
              />
            </div>
          </div>

          {tipoVehiculo.carga && <>
            <div className={[classes.containerInputToRight].join(' ')}>
              <div className={classes.inputContainer}>
                <div className={classes.labelnputField}>CARGA</div>
                <TextField
                  value={modelo.carga}
                  onChange={(e) => setState('carga', e.target.value)}
                  className={[classes.textField, classes.midleInput].join(' ')}
                  margin='normal'
                  variant='outlined'
                  placeholder='1800 o 1.8 (Si selecciona Kilogramos o Toneladas)'
                />
              </div>
              <div className={classes.inputContainer}>
                <div className={classes.labelnputField}>TIPO CARGA</div>
                <Select
                  value={modelo.tipoCarga}
                  onChange={(e) => setState('tipoCarga', e.target.value)}
                  className={[classes.selectField, classes.midleInput].join(' ')}
                  classes={{
                    outlined: classes.outlinesSelect
                  }}
                  displayEmpty={true}
                  variant='outlined'
                >
                  <MenuItem value={''} selected>Seleccione tipo de carga</MenuItem>
                  {TIPO_CARGA_PESOS.map((x, i) => (<MenuItem value={x.id}>{x.texto}</MenuItem>))}
                </Select>
              </div>
            </div>
          </>}
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Peso Bruto</div>
              <TextField
                value={modelo.pesoBrutoVehicular}
                onChange={(e) => setState('pesoBrutoVehicular', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='1800 o 1.8 (Si selecciona Kilogramos o Toneladas)'
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>TIPO PESO BRUTO VEHICULAR</div>
              <Select
                value={modelo.tipoPesoBruto}
                onChange={(e) => setState('tipoPesoBruto', e.target.value)}
                className={[classes.selectField, classes.midleInput].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >
                <MenuItem value={''} selected>Seleccione tipo de peso bruto vehicular</MenuItem>
                {TIPO_CARGA_PESOS.map((x, i) => (<MenuItem value={x.id}>{x.texto}</MenuItem>))}
              </Select>
            </div>
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Color</div>
            <Select
              value={color}
              /*onChange={(e) => setColor(e.target.value)}*/
              onChange={(e) => setState('color', resolverColor(e.target.value))}
              className={[classes.selectField].join(' ')}
              classes={{
                outlined: classes.outlinesSelect
              }}
              displayEmpty={true}
              variant='outlined'
            >
              {COLORES.map((v, i) => (<MenuItem value={v.id + ';' + v.key}>{v.texto}</MenuItem>))}
            </Select>
            {/*<TextField
              value={modelo.color}
              onChange={(e) => setState('color', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='Blanco'
            />*/}
          </div>
          {tipoVehiculo.citModelo && <>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>CIT MODELO</div>
              <TextField
                value={modelo.citModelo}
                onChange={(e) => setState('citModelo', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
              />
            </div>
          </>}
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número Motor</div>
            <TextField
              value={modelo.nroMotor}
              onChange={(e) => setState('nroMotor', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número Chasis</div>
            <TextField
              value={modelo.chasis}
              onChange={(e) => setState('chasis', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número Serie</div>
            <TextField
              value={modelo.nroSerie}
              onChange={(e) => setState('nroSerie', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número VIN</div>
            <TextField
              value={modelo.nroVin}
              onChange={(e) => setState('nroVin', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          {correspondeImpuestoVerde && <>
            <div className={classes.titleSeccionHeader}>
              Impuesto Verde
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>CID</div>
              <TextField
                value={modeloIV.cid}
                onChange={(e) => setStateIV('cid', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>CIT</div>
              <TextField
                value={modeloIV.cit}
                onChange={(e) => setStateIV('cit', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Monto impuesto</div>
              <TextField
                value={modeloIV.montoImpuesto}
                onChange={(e) => setStateIV('montoImpuesto', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
                inputProps={{ className: classes.inputError, pattern: '^[0-9]*\\.?[0-9]*$' }}
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Monto factura</div>
              <TextField
                value={modeloIV.montoFactura}
                onChange={(e) => setStateIV('montoFactura', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
                inputProps={{ className: classes.inputError, pattern: '^[0-9]*\\.?[0-9]*$' }}
              />
            </div>
          </>}
        </div>
      </Collapse>
    </div>
  );
};

export default withStyles(contratosGeneralStyle)(InscripcionVehiculo);

import { Theme } from '@material-ui/core';
import FactotalTheme from '../../FactotalTheme';
import scssTool from '../../scssTool';

const dialogStyle = (theme: Theme) => ({
  root: {
    '& h1': {
      color: '#000000',
      fontSize: '30px',
      textAlign: 'center' as 'center',
      fontWeight: 'normal' as 'normal',
    },
    '& p': {
      color: '#555759',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        color: '#555759',
      },
    },
  },
  containerTable: {
    background: '#F4F4F4',
    borderRadius: '10px',
    padding: '16px 24px',
    marginBottom: '24px'
  },
  containerSeccion: {
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
    width: '100%',
  },
  labelnputField: {
    ...scssTool.font(14, 400, 16),
    color: '#3C3C3C',
    marginBottom: '8px',
  },
  midleInput: {
    maxWidth: '160px',
    minWidth: 'inherit',
    width: '85%',
  },
  midleWidthInput: {
    width: '50%',
    margin: '0px'
  },
  dialogPaper: {
    width: '750px',
    minHeight: '300px',
    boxSizing: 'border-box' as 'border-box',
    overflow: 'inherit',
    padding: '0px',
    lineHeight: '21px',
    position: 'absolute' as 'absolute',
    maxWidth: 'none',
    borderRadius: '8px',
    background: ' linear-gradient(rgb(130, 188, 0) 25%, #ffffff 75%)',
  },
  dialogPaperGreyVariant: {
    width: '750px',
    minHeight: '300px',
    boxSizing: 'border-box' as 'border-box',
    overflow: 'inherit',
    padding: '0px',
    lineHeight: '21px',
    position: 'absolute' as 'absolute',
    maxWidth: 'none',
    borderRadius: '8px',
    background: ' linear-gradient(rgb(130, 188, 0) 25%, rgb(130, 188, 0) 75%)',
    '&.responsive': {
      width: '80%',
      height: '70%'
    }
  },
  dialogContent: {
    padding: '0px 0px 16px 16px',
    flexGrow: 1,
  },
  containerWhite: {
    width: `calc(100% - 8px)`,
    height: `calc(100% - 8px)`,
    background: '#fff',
    borderRadius: '8px',
    margin: '4px auto',
    padding: '24px 36px 24px 26px',
    boxSizing: 'border-box' as 'border-box',
  },
  containerGreyVariant: {
    height: '80vh',
    width: `calc(100% - 8px)`,
    borderRadius: '8px',
    margin: '4px auto',
    padding: '24px 36px 24px 26px',
    boxSizing: 'border-box' as 'border-box',
    background: 'rgb(235, 235, 235)',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
  },
  obs: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    color: '#000000',
  },
  height5: {
    height: '60vh',
  },
  heightAuto: {
    height: '100%',
  },
  bigTitle: {
    fontSize: '32px',
    lineHeight: '47px',
    color: '#000000',
    marginTop: '33px',
    textAlign: 'center' as 'center',
  },
  NewtitleModal: {
    ...scssTool.font(17, 600, 22),
    color: '#000000',
    marginTop: '42px',
  },
  subtitleModal: {
    ...scssTool.font(15, 400, 22),
    color: 'rgba(0,0,0, 0.4)',
    marginBottom: '24px',
  },
  bigTitleStep: {
    fontSize: '28px',
    lineHeight: '21px',
    color: '#000000',
    margin: '52px 0px 32px',
    textAlign: 'center' as 'center',
  },
  stepTitle: {
    fontSize: '20px',
    lineHeight: '21px',
    textAlign: 'center' as 'center',
    color: 'rgba(0, 0, 0, 0.6)',
    marginTop: '18px',
  },
  dialogTitle: {
    color: '#3C3C3C',
    fontSize: '20px',
    lineHeight: '23px',
    fontFamily: 'Arial',
    margin: '0 6px 24px 16px',
    padding: '0px 0px 5px 0px',
    borderBottom: '1px solid #82BC00',
  },
  containerOptions: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    padding: '16px',
    marginTop: '32px',
    justifyContent: 'space-evenly' as 'space-evenly',
  },
  boxesName: {
    fontSize: '17px',
    lineHeight: '23px',
    color: 'rgba(0, 0, 0, 0.6)',
  },
  tipoBoxes: {
    background: 'rgba(130, 188, 0, 0.06)',
    borderRadius: '10px',
    padding: '16px',
    width: '160px',
    height: '120px',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    border: '4px solid rgba(130, 188, 0, 0.06)',
    textAlign: 'center' as 'center',
    '& img': {
      width: '42px',
      marginBottom: '9px',
    },
    '&:hover': {
      transition: 'all 0.3s ease-in-out',
      border: '4px solid #82BC00',
    },
  },
  selectedTipoBoxes: {
    border: '4px solid #82BC00',
    borderRadius: '10px',
    background: 'rgba(130, 188, 0, 0.3)',
    transition: 'all 0.3s ease-in-out',
  },
  formulario: {
    width: '100%',
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
  },
  fluidInputContainer: {
    ...scssTool.flex('center', 'row', 'space-between')
  },
  inputContainer: {
    width: 'auto',
    marginBottom: '32px',
    marginRight: '21px'
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  floatingHeaderIcon: {
    background: 'rgba(130, 188, 0, 0.8)',
    borderRadius: '13px',
    width: '100px',
    height: '80px',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    position: 'absolute' as 'absolute',
    top: '-40px',
    left: '50%',
    marginLeft: '-50px',
  },
  mRight: {
    marginRight: '8px' as any,
  },
  paperDialogtitle: {
    borderBottom: 'none',
    marginBottom: 0,
  },
  midleDialogContent: {
    display: 'flex',
    height: '100%',
    flexGrow: 1,
  },
  dialogConfirm: {
    height: '60%',
  },
  dialogPaperWarning: {
    border: '3px solid #B58D00',
  },
  dialogPaperDelete: {
    border: '3px solid #CD2F2F',
  },
  checkIcon: {
    position: 'absolute' as 'absolute',
    width: '100px',
    height: '80px',
    left: '50%',
    top: '-40px',
    background: 'rgba(130, 188, 0, 0.8)',
    borderRadius: '13px',
    marginLeft: '-50px',
    textAlign: 'center' as 'center',
    '& svg': {
      color: '#fff',
      fontSize: '80px',
    },
  },
  checkIconWarning: {
    background: 'rgba(255, 220, 98, 0.8)',
  },
  chekIconDelete: {
    background: 'rgba(205, 47, 47, 0.9)',
  },
  messageDelete: {
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center' as 'center',
    margin: '32px 0px',
    color: '#8C8C8C',
    '& span': {
      color: '#000000',
    },
    '& .red': {
      color: '#CD2F2F',
    },
  },
  containerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& p': {
      marginRight: '20px' as any,
    },
    '& button': {
      background: '#82BC00',
      border: '1px solid #82BC00',
      borderRadius: '4px',
      width: '100px',
      height: '40px',
      textAlign: 'center' as 'center',
      fontSize: '12px',
      lineHeight: '17px',
      color: '#fff',
      marginRight: '20px' as any,
      '&:hover': {
        color: '#82BC00',
        background: '#FFF',
        fontSize: '13px',
      },
    },
    '& button:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)',
    },
  },
  secondaryButton: {
    width: '80px !important',
    background: '#FFFFFF !important',
    border: '1px solid #82BC00 !important',
    color: '#82BC00 !important',
  },
  borderButton: {
    border: '1px solid #82BC00',
    borderRadius: '4px',
    height: '32px',
    padding: '0px 16px',
    ...scssTool.flex('center', 'row', 'center'),
    color: '#82BC00'
  },
  uploadfileContainer: {
    height: '42px',
    width: '50%'
  },
  // TODO Transformar input a component
  textField: {
    width: '318px',
    height: '42px',
    background: '#FFFFFF',
    border: '1px solid #95989A',
    borderRadius: '5px',
    margin: '0px',
    ...scssTool.font(14, 400, 16),
    '& fieldset': {
      border: '0px'
    },
    '& input': {
      padding: '12px 8px'
    },
    '&.sinMargin': {
      width: '100%',
    },
    '&.litle': {
      width: '93px',
    },
    '&.more-litle': {
      width: '50px',
    },
    '&.midle': {
      width: '148px',
    }
  },
  textFieldMargin: {
    margin: '8px 0px 0px 0px',
  },
  // end
  // TODO Transformar todos los botones a 1 s�lo boton
  greenButton: {
    background: '#82BC00',
    borderRadius: '4px',
    color: '#fff',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      color: '#82BC00',
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)',
    },
  },
  redButton: {
    background: '#fff',
    border: '1px solid #CD2F2F',
    borderRadius: '5px',
    color: '#CD2F2F',
    width: 131,
    height: 45,
    '&:hover': {
      background: '#CD2F2F',
      color: '#fff',
    },
  },
  greyButton: {
    background: '#fff',
    borderRadius: '4px',
    border: '1px solid #82BC00',
    color: '#82BC00',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
    '&:hover': {
      color: '#82BC00',
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)',
    },
  },
  titleModal: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#484848',
  },
  labelSelectModal: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#3C3C3C',
  },
  errorLabel: {
    color: '#CD2F2F !important',
    fontSize: '14px',
    lineHeight: '16px',
    padding: '8px 0px',
    margin: '0px',
  },
  buttonUpload: {
    background: '#82BC00',
    boxShadow: '0px 2px 15px rgba(1, 213, 159, 0.401005)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    marginTop: '31px',
    padding: '8px 32px',
    '&:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)',
      boxShadow: '0px 2px 15px rgba(130, 180, 0, .3)',
    },
  },
  uploadDocument: {
    boxSizing: 'border-box' as 'border-box',
    padding: '16px 0px',
    minHeight: '20px',
  },
  passwordForm: {
    boxSizing: 'border-box' as 'border-box',
    borderTop: '1px solid #E5E6E6',
    padding: '32px 0px',
  },
  titlePass: {
    fontSize: '16px',
    lineHeight: '18px',
    color: '#3C3C3C',
  },
  TableFTC: {
    marginTop: '2px',
    borderSpacing: '0 2px',
    borderCollapse: 'separate' as 'separate',
  },
  HeaderTableFCT: {
    backgroundColor: FactotalTheme.palette.grey[200],
    textAlign: 'center' as 'center',
  },
  TableRowFCT: {
    paddingRight: '0px' as '0px',
    height: '30px',
  },
  TableThFCT: {
    paddingRight: '0px' as '0px',
    color: '#FFFFFF',
    fontSize: '10px',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'normal' as 'normal',
    letterSpacing: '1px',
  },
  TableRowBodyFCT: {
    height: '31px',
    color: FactotalTheme.palette.grey[500],
    fontSize: '12px',
    borderBottom: 'none',
    '& td:last-child': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderTop: '1px solid rgba(224, 224, 224, 1)',
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)',
    },
  },
  TableCellFCT: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  dialogActions: {
    justifyContent: 'flex-start',
    padding: '0px 10px',
    margin: '0px',
  },
  labelForm: {
    marginTop: '32px',
    marginBottom: '8px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#3C3C3C',
  },
  textfieldPass: {
    width: '209px',
  },
  outlinePrimeryButton: {
    border: '1px solid #82BC00',
    background: '#fff !important',
    color: '#82BC00 !important',
  },
  gridDialog: {
    marginBottom: '32px',
  },
  uploadContent: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  fullwidth: {
    width: '100%',
  },
  middleWidth: {
    width: '80%',
  },
  formControlDialog: {
    width: '100%',
    display: 'contents',
  },
  inputFile: {
    display: 'none',
  },
  dialogChekbox: {
    display: 'flex',
    alignItems: 'center',
  },
  termsLink: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#8C8C8C',
    '& a': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  dialogActionsTerm: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  labelRadioButton: {
    fontSize: '12px',
    lineHeight: '14px',
    marginLeft: '-8px',
    marginBottom: '8px',
    '& [class*=body2]': {
      color: '#8C8C8C !important',
    },
  },
  inputLabel: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#3C3C3C',
    marginBottom: '16px',
    display: 'block',
  },
  fixTop: {
    marginTop: '16px',
  },
  passInput: {
    width: '210px',
  },
  dialogScrollContent: {
    padding: '8px 16px 32px 0px',
    marginTop: '16px',
    height: '55vh',
    '& ul': {
      color: '#555759',
    },
    '& h2': {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#3C3C3C',
    },
    '& table': {
      color: '#555759',
    },
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    color: '#555759',
  },
  tableTerms: {
    borderCollapse: 'collapse' as 'collapse',
  },
  dialogPapersOr: {
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  termsConditionTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3C3C3C',
  },
  uploadXml: {
    width: '700px !important',
    height: '85vh !important',
    maxWidth: 'none',
  },
  cancelButtom: {
    fontWeight: 'normal' as 'normal',
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize',
  },
  tableHeader: {
    textAlign: 'center' as 'center',
  },
  clearSpace: {
    height: '32px',
  },
  cardReq: {
    background: '#E5E6E6',
    borderRadius: '5px',
    padding: '8px',
    display: 'flex',
    alignItems: 'flex-start',
    margin: '16px 0px',
  },
  cardReqIcon: {
    marginRight: '16px' as any,
  },
  cardReqContent: {
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
  cardReqTitle: {
    fontWeight: 'normal' as 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#102333',
  },
  cardReqSubtitle: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#828282',
    marginBottom: '4px',
  },
  cardReqFunc: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: 'rgba(16, 35, 51, 0.65)',
  },
  dialogTitleTerms: {
    margin: '0px',
    padding: '0px 0px 23px 0px',
    borderBottom: 'none',
    '& h6': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#3C3C3C',
    },
  },
  dialogTermsTabRoot: {
    background: '#F5F6FA',
    border: '1px solid #686B6D',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '10px',
    width: '100%',
    height: '45px',
  },
  dialogTermIndicator: {
    height: '100%',
    background: '#6F777B',
    zIndex: 1,
  },
  termDialogTab: {
    width: '50%',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center' as 'center',
    color: '#555759',
    maxWidth: 'none',
  },
  dialogTermsTabsSelected: {
    background: '#6F777B',
    color: '#FFFFFF',
    zIndex: 2,
  },
  dialogTermLabelContainer: {
    paddingTop: '3px',
  },
  containerInfo: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    height: '46px',
    marginBottom: '8px',
  },
  textinfo: {
    fontSize: '14px',
    lineHeight: '21px',
    color: '#555759',
  },
  inputRowContainer: {
    width: '70%',
    ...scssTool.flex('center', 'row', 'flex-start')
  },
});

export default dialogStyle;

import { containerFluid, drawerMiniWidth, drawerWidth, transition } from '../generalStyle';
import { Theme } from '@material-ui/core';

const appStyle = (theme : Theme) => ({
  wrapper: {
    position: 'relative' as 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both' as 'both'
    }
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: 'auto' as 'auto',
    position: 'relative' as 'relative',
    float: 'right' as 'right',
    ...transition,
    maxHeight: '100%',
    width: `calc(100% - ${drawerWidth}px)`,
    overflowScrolling: 'touch'
  },
  content: {
    marginTop: '60px',
    padding: '25px',
    minHeight: 'calc(100vh - 152px)'
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px'
  },
  mainPanelSidebarMini: {
    width: `calc(100% - ${drawerMiniWidth}px)`
    /*[theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerMiniWidth}px)`
    }*/
  },
  mainPanelWithPerfectScrollbar: {
    //overflow: "hidden !important"
  },
  Snackbar: {
    width: 390,
    height: 210,
    background: '#FFFFFF',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.136609)',
    borderRadius: '7px',
    '& .line-header': {
      borderRadius: '7px 7px 0px 0px',
      width: '100%',
      height: '10px',
      backgroundColor: '#82BC00'
    },
    '& .content': {
      padding: '16px',
      '& .title': {
        fontSize: '16px',
        lineHeight: '18px',
        color: '#95989A',
        display: 'flex',
        alignItems: 'center' as 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #82BC00',
        padding: '0px 0px 8px 0px'
      },
      '& .desc': {
        display: 'flex',
        alignItems: 'center' as 'center',
        justifyContent: 'space-between',
        padding: '16px 8px',
        '& .circle': {
          minWidth: 9,
          minHeight: 9,
          borderRadius: 9,
          margin: '0px 16px 0px 0px',
          '&.green': {
            backgroundColor: '#82BC00'
          },
          '&.red': {
            backgroundColor: '#D75959'
          }
        },
        '& .t': {
          fontWeight: 'normal' as 'normal',
          fontSize: '16px',
          lineHeight: '18px',
          color: '#8C8C8C'

        }
      }
    }
  },
  chatContainer: {
    position: 'fixed' as 'fixed',
    right: '64px',
    bottom: '46px',
    zIndex: 100
  },
  buttonChatfloating: {
    background: '#82BC00',
    width:'54px',
    height: '54px',
    '&:hover': {
      background: '#82BC00',
    }
  }
});

export default appStyle;

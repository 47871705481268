import { ButtonBase, IconButton } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useMemo, useState } from 'react';
import contratosGeneralStyle from '../contratosGeneralStyle';
import { useHistory } from 'react-router';
import BigHeaderContent from '../components/BigHeaderContent/BigHeaderContent';
import InscripcionVehiculo from '../components/Vehiculo/InscripcionVehiculo';
import DocumentoCompraVenta from '../components/Documentos/DocumentoCompraVenta';
import Personas from '../components/Personas';
import ConfirmarSolicitud, { IResultadoEnvioInscripcion } from '../components/Modales/ConfirmarSolicitud';
import CargarDocumentoModal from '../components/Modales/CargarDocumentoModal';
import ActualizarRegistroModal from '../components/Modales/ActualizarRegistroModal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HistorialModal from '../components/Modales/HistorialModal';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { cargarInscripcion, enviarInscripcion, persistirInscripcion } from '../actions';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { TipoVehiculo, tvToString } from './nuevoBien';
import routes from '../../../config/routes';
import { invertirFecha } from '../../../helpers/FormatDate';
import { TModeloCompradorVendedor } from './view';
import { VEHICULOS_LM } from '../components/Selectores';
import InformacionInscripcion from '../components/InformacionInscripcion/InformacionInscripcion';
import CertificadoAnotacionesVigentesModal from '../components/Modales/CertificadoAnotacionesVigentesModal';

export type TModeloVehiculo = Dto.RegistroCivil.InscripcionVehiculo;
export type TModeloIV = Dto.RegistroCivil.ImpuestoVerde;
export type TModeloCompraVenta = Dto.RegistroCivil.DocumentoCompraVenta;

const iniModeloCompraVenta = (): TModeloCompraVenta => ({
  fecha: '',
  emisor: '',
  folioFactura: '',
  idComunaLugar: '0' as any,
  montoBrutoTotal: '',
  rutEmisor: '',
  tipoFactura: '0' as any,
  tipoMoneda: '0' as any
});

const iniModeloVehiculo = (): TModeloVehiculo => ({
  anoFabricacion: new Date().getFullYear(),
  asientos: '',
  carga: '',
  chasis: '',
  citModelo: '',
  color: '',
  marca: '',
  combustible: '',
  modelo: '',
  nroMotor: '',
  nroSerie: '',
  nroVin: '',
  pesoBrutoVehicular: '',
  tipoVehiculo: '0' as any,
  puertas: '',
  terminacionPpu: '',
  tipoCarga: '0' as any,
  tipoPesoBruto: '0' as any
});

const iniModeloIV = (): TModeloIV => ({
  cid: '',
  cit: '',
  montoFactura: '',
  montoImpuesto: ''
});

interface RouteParams {
  id: string;
  tipoVehiculo: TipoVehiculo;
}

interface StateProps {
  empresa: Dto.RegistroCivil.Empresa | undefined;
  contrato: Dto.RegistroCivil.Contrato | undefined;
  inscripcion: Dto.RegistroCivil.Inscripcion | undefined;
}

interface DispatchProps {
  guardarInscripcion: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.Inscripcion) => Promise<Response.RegistroCivil.Inscripcion>;
  enviarInscripcion: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.EnviarInscripcion) => Promise<Response.RegistroCivil.EnviarInscripcion>;
  cargarInscripcion: (idInscripcion: number) => void;
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof contratosGeneralStyle>, RouteComponentProps<RouteParams> {
}

const mapStateToProps = (state: AppState): StateProps => ({
  empresa: state.registroCivil.empresa,
  contrato: state.registroCivil.contrato,
  inscripcion: state.registroCivil.inscripcion
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  guardarInscripcion: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.Inscripcion) => dispatch(persistirInscripcion(idEmpresa, idContrato, payload)),
  enviarInscripcion: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.EnviarInscripcion) => dispatch(enviarInscripcion(idEmpresa, idContrato, payload)),
  cargarInscripcion: (idInscripcion: number) => dispatch(cargarInscripcion(idInscripcion))
});

const PrimeraInscripcion: FC<IProps> = (props) => {
  const { classes, inscripcion, empresa, contrato } = props;
  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [openCargarDocumento, setOpenCargardocumento] = useState(false);
  const [openActualizarRegistro, setActualizarRegistro] = useState(false);
  const [openHistorial, setOpenHistorial] = useState(false);
  const [cargando, setCargando] = useState(false);

  const [modeloVehiculo, setModeloVehiculo] = useState<TModeloVehiculo>(iniModeloVehiculo());
  const [modeloIV, setModeloIV] = useState<TModeloIV>(iniModeloIV());
  const [modeloCompraVenta, setModeloCompraVenta] = useState<TModeloCompraVenta>(iniModeloCompraVenta());
  const [region, setRegion] = useState('13');
  const [color, setColor] = useState('');

  const [compradores, setCompradores] = useState<TModeloCompradorVendedor[]>([]);

  const [documentos, setDocumentos] = useState<Comun.Dto.ArchivoContenido[]>([]);
  const [showCargarArchivo, setShowCargarArchivo] = useState(false);
  const [showEnviar, setShowEnviar] = useState(true);
  const [showGuardar, setShowGuardar] = useState(true);
  const [showHistorial, setShowHistorial] = useState(false);
  const [tipoSolicitud, setTipoSolicitud] = useState(1);
  const [showInformacion, setShowInformacion] = useState(false);
  const [isNotValidForm, setIsNotValidForm] = useState(false);
  const [openCertificadoAntecedentes, setOpenCertificadoAntecedentes] = useState(false);
  const [showObtenerCAV, setShowObtenerCAV] = useState(false);

  useEffect(() => {
    setModeloVehiculo(inscripcion?.vehiculo || iniModeloVehiculo());
    setModeloIV(inscripcion?.impuestoVerde || iniModeloIV());
    setModeloCompraVenta(inscripcion?.documentoCompraVenta || iniModeloCompraVenta());
    setCompradores(inscripcion?.listaAdquiere || []);
    setRegion(inscripcion?.idRegion?.toString() || '');
    setColor(inscripcion?.vehiculo?.color?.toString() || '');
    if (inscripcion?.numeroSolicitud) {
      setShowCargarArchivo(true);
      setShowEnviar(false);
      setShowGuardar(false);
      setShowInformacion(true);
      setShowObtenerCAV(true);
    } else {
      setShowInformacion(false);
      setShowObtenerCAV(false);
    }
    if (inscripcion?.idInscripcion) {
      setShowHistorial(true);
    }
  }, [inscripcion]);

  const setStateCompraVenta = (field: keyof TModeloCompraVenta, value: any) => {
    setModeloCompraVenta({ ...modeloCompraVenta, [field]: value });
  };

  const setStateVehiculo = (field: keyof TModeloVehiculo, value: any) => {
    if (field === 'tipoVehiculo') {
      const [carga, tipoCarga] = handleCargaVehiculo(value);
      setModeloVehiculo({ ...modeloVehiculo, carga: carga, tipoCarga: Number(tipoCarga), [field]: value });
    } else {
      setModeloVehiculo({ ...modeloVehiculo, [field]: value });
    }
  };

  const setStateIV = (field: keyof TModeloIV, value: any) => {
    setModeloIV({ ...modeloIV, [field]: value });
  };

  const handleCargaVehiculo = (tipoVehiculo: string): string[] => {
    const tv = VEHICULOS_LM.find(r => r.id === tipoVehiculo);
    if (tv && tv.carga) {
      return ['', ''];
    } else if (tv && !tv.carga) {
      return ['0', '2'];
    }
    return ['', ''];
  };
  const titulo = useMemo(() => {
    if (props.contrato === undefined) return '';
    return `Nueva inscripción ${tvToString(props.match.params.tipoVehiculo)} contrato # ${props.contrato.numero}`;
  }, [props.match.params.tipoVehiculo, props.contrato]);

  useEffect(() => {
    if (props.match.params.id !== '') {
      props.cargarInscripcion(Number(props.match.params.id));
    }
  }, [props.match.params.id]);

  const history = useHistory();

  const setEnviarInscripcion = async (): Promise<IResultadoEnvioInscripcion> => {
    let exito: boolean = false;
    let mensaje: string = '';
    if (empresa && contrato && inscripcion) {

      setCargando(true);
      const payload: Request.RegistroCivil.EnviarInscripcion = {
        idInscripcion: inscripcion.idInscripcion
      };
      const respuesta = await props.enviarInscripcion(empresa.idEmpresa.toString(), contrato.idContrato.toString(), payload);
      exito = respuesta.success;
      mensaje = respuesta.message;
      setCargando(false);
    }
    return {
      fecha: 'PRUEBA',
      hora: 'PRUEBA',
      oficina: 'PRUEBA',
      observaciones: ['prueba', 'prueba'],
      ppu: '?????',
      solicitud: 'PRUEBA PRUEBA',
      success: exito,
      message: mensaje
    };
  };
  useEffect(() => {
    if (modeloVehiculo.marca.length < 2 ||
      Number(modeloVehiculo.tipoVehiculo) == 0 ||
      modeloVehiculo.modelo.length < 1 ||
      modeloVehiculo.combustible.length == 0 ||
      color.length == 0 || region.length == 0 || modeloVehiculo.tipoPesoBruto == 0 ||
      modeloVehiculo.pesoBrutoVehicular.length == 0 || modeloVehiculo.nroMotor.length == 0 ||
      modeloVehiculo.chasis.length == 0 || modeloVehiculo.nroSerie.length == 0 ||
      modeloVehiculo.nroVin.length == 0 || modeloCompraVenta.fecha.length == 0 ||
      modeloCompraVenta.emisor.length == 0 || modeloCompraVenta.rutEmisor.length == 0 ||
      modeloCompraVenta.folioFactura.length == 0 || Number(modeloCompraVenta.tipoFactura) != 0 ||
      Number(modeloCompraVenta.idComunaLugar) == 0 || modeloCompraVenta.montoBrutoTotal.length == 0 ||
      compradores.length == 0) {
      setIsNotValidForm(true);
    } else {
      setIsNotValidForm(false);
    }

  }, [modeloVehiculo, modeloCompraVenta, compradores]);

  /*const validarCompradores = ()=>{
    let retorno= false;
    if(compradores.length==0){
      retorno=false;
    }
    else{

    }
    return retorno;
  }*/

  const onGrabar = async () => {
    if (empresa && contrato) {
      setCargando(true);
      const payload: Request.RegistroCivil.Inscripcion = {
        idInscripcion: inscripcion?.idInscripcion,
        documentoCompraVenta: {
          ...modeloCompraVenta,
          fecha: modeloCompraVenta.fecha !== '' ? invertirFecha(modeloCompraVenta.fecha) : ''
        },
        vehiculo: modeloVehiculo,
        idRegion: Number(region),
        impuestoVerde: modeloIV,
        listaAdquiere: compradores,
        tipoVehiculo: props.match.params.tipoVehiculo,
        observaciones: ''
      };
      const respuesta = await props.guardarInscripcion(empresa.idEmpresa.toString(), contrato.idContrato.toString(), payload);
      setCargando(false);
    }
  };

  const onCargarArchivo = () => {
    setOpenCargardocumento(!openCargarDocumento);
  };

  const onAbrirHistorial = () => {
    setOpenHistorial(!openHistorial);
  };
  const onObtenerCAV = () => {
    setOpenCertificadoAntecedentes(!openCertificadoAntecedentes);
  };

  const onActualizarRegistro = () => {
    setActualizarRegistro(false);
  };

  const aceptarCompradores = (personas: TModeloCompradorVendedor[]) => {
    setCompradores([...personas]);
  };

  const onBack = () => {
    history.push(routes.registroVehiculos.contratosEmpresa(props.match.params.id));
  };

  // console.log(modeloComprador, modeloCompraVenta, modeloVehiculo, modeloIV);

  return (
    <>
      <div className={classes.afterHeader}>
        <ButtonBase aria-label='cerrar' onClick={onBack} className={classes.backButton}>
          <ArrowBackIcon /> Volver
        </ButtonBase>
      </div>
      <BigHeaderContent titulo={titulo} estado='' cargando={cargando} onGrabar={onGrabar}
                        onEnviar={() => setOpenConfirmar(!openConfirmar)} onCargarArchivo={onCargarArchivo}
                        onVolver={onBack} showCargarArchivo={showCargarArchivo} showEnviar={showEnviar}
                        showGuardar={showGuardar} onAbrirHistorial={onAbrirHistorial} showHistorial={showHistorial}
                        onObtenerCAV={onObtenerCAV} showObtenerCAV={showObtenerCAV} notValid={isNotValidForm} />
      <div className={classes.titleSecciones}>Secciones</div>
      <InscripcionVehiculo modelo={modeloVehiculo}
                           modeloIV={modeloIV}
                           setState={setStateVehiculo}
                           setStateIV={setStateIV}
                           region={region}
                           setRegion={setRegion}
                           color={color}
                           setColor={setColor}
      />
      <DocumentoCompraVenta modelo={modeloCompraVenta} setState={setStateCompraVenta} />
      <Personas personas={compradores} aceptarPersonas={aceptarCompradores}
                titulo='Ingrese la información relativa al comprador del vehículo' elemento='Comprador' />
      <ConfirmarSolicitud open={openConfirmar} onClose={() => setOpenConfirmar(!openConfirmar)} onEnviarInscripcion={setEnviarInscripcion} />
      {showInformacion && <InformacionInscripcion inscripcion={inscripcion}></InformacionInscripcion>}
      <CargarDocumentoModal open={openCargarDocumento}
                            empresa={empresa}
                            contrato={contrato}
                            inscripcion={inscripcion}
                            onClose={() => setOpenCargardocumento(!openCargarDocumento)}
                            onCargarArchivo={onCargarArchivo}
                            tipoSolicitud={tipoSolicitud}
      />
      <CertificadoAnotacionesVigentesModal open={openCertificadoAntecedentes}
                                           empresa={empresa}
                                           contrato={contrato}
                                           inscripcion={inscripcion}
                                           onClose={() => setOpenCertificadoAntecedentes(!openCertificadoAntecedentes)}
      />
      <ActualizarRegistroModal open={openActualizarRegistro} onClose={() => setActualizarRegistro(!openActualizarRegistro)}
                               onActualizarRegistro={onActualizarRegistro} />
      <HistorialModal open={openHistorial} onClose={() => setOpenHistorial(!openHistorial)} inscripcion={inscripcion} empresa={empresa}
                      contrato={contrato}
      />
    </>
  );
};

export default withStyles(contratosGeneralStyle)(connect(mapStateToProps, mapDispatchToProps)(PrimeraInscripcion));

import { Theme } from '@material-ui/core';

const modalMsgStyle = (theme : Theme) => {
  return {
    modalPaper: {
      backgroundColor: 'rgba(255,255,255, .85)',
      width: 870,
      height: 300,
      margin: 'auto',
      top: 0,
      position: 'absolute' as 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '1rem',
      borderRadius: '1rem',
      boxShadow: '0px 0px 0px 3px #82BC00',
      '&:focus': {
        outline: 'none'
      }
    },
    modalHeader: {
      height: 80,
      width: 100,
      backgroundColor: 'rgba(130,188,0,.8)',
      borderRadius: '.8rem',
      position: 'relative' as 'relative',
      display: 'flex',
      margin: 'auto',
      top: -56,
      '& > div': {
        margin: 'auto'
      },
      '& svg': {
        height: 60,
        width: 60,
        margin: 'auto',
        '& path': {
          fill: '#fff',
          '&[fill="none"]': {
            fill: 'none'
          }
        }
      }
    },
    modalContent: {
      textAlign: 'center' as 'center'
    },
    modalContentTitle: {
      fontSize: '2rem',
      color: '#2E2A25',
      marginBottom: '2rem'
    },
    modalContentSubTitle: {
      fontSize: '1rem',
      lineHeight: '2rem'
    },
    modalFooter: {
      marginTop: '4rem',
      '& button': {
        backgroundColor: '#82BC00',
        fontSize: '.85rem',
        margin: 'auto',
        display: 'flex',
        '&:hover': {
          backgroundColor: '#82BC00'
        },
        '&:focus': {
          backgroundColor: '#82BC00'
        }
      }
    },
    filterFormtextField: {
      fontSize: '1rem',
      width: '100%',
      '& input': {
        marginTop: '.4rem'
      }
    }
  };
};

export default modalMsgStyle;

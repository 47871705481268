import { IAdminDatosUsuarios } from './reducer';

const datosUsuarios : IAdminDatosUsuarios = ({
  empresa: {
    rut: '',
    nombre: '',
    razonSocial: '',
    direccionComercial: '',
    telefonoComercial: ''
  },
  usuario: {
    rut: '',
    nombre: '',
    email: '',
    celular: ''
  },
  isLoading: false
});


export default datosUsuarios;

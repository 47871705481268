// External
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";

// datepicker
import "react-datetime/css/react-datetime.css";

// Table
import ReactTable from "react-table";
import PaginationBase from "../../../shared/Pagination/PaginationBase";
import Moment from "moment/moment";

// componentes material ui
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

// componentes custom
import Breadcrumb from "../../../shared/Breadcrumb/Breadcrumb";
import GridItem from "../../../shared/Grid/GridItem";
import GridContainer from "../../../shared/Grid/GridContainer";
import NavPills from "../../../shared/NavPills/NavPills";
import Typography from "@material-ui/core/Typography";
import SVGIcons from "../../../shared/SVGIcons/SVGIcons";

// @material-ui/icons
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// importación de tema para tablas
import HistoricoStyle from "../../../../styles/jss/components/extendedFormsStyle";

const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

const newHistorico = () => ({
  numoperacion: Math.floor(Math.random() * 100),
  fechoperacion: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  documentos: Math.floor(Math.random() * 30),
  age: Math.floor(Math.random() * 30),
  deudor: "",
  valdocumento: Math.floor(Math.random() * 100),
  valfinanciado: Math.floor(Math.random() * 100),
  retencion: Math.floor(Math.random() * 100),
  totcosoperacion: Math.floor(Math.random() * 100),
  CtaCliente: Math.floor(Math.random() * 100),
  liqgirado: Math.floor(Math.random() * 100),
  fechVencimiento: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  fechUltPago: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  monpagado: Math.floor(Math.random() * 100),
  saldocumento: Math.floor(Math.random() * 100),
});

export function makeData(len = 5553) {
  return range(len).map((d) => ({
    ...newHistorico(),
    children: range(10).map(newHistorico),
  }));
}

class SeguimientoOperaciones extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      data: makeData(),
    };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  onChangeNumOperacion = (e) => this.refs.facturasTable.filterColumn(this.state.columns[0], e.target.value);

  iconExcel = () => {
    return <svg version="1.1" width="24" height="24" viewBox="0 0 24 24">
            <path d="M16.2,17H14.2L12,13.2L9.8,17H7.8L11,12L7.8,7H9.8L12,10.8L14.2,7H16.2L13,12M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z" />
            </svg>
  }
  
  iconPDF = () => {    
    return <svg version="1.1" width="24" height="24" viewBox="0 0 24 24">
        <path d="M11.43,10.94C11.2,11.68 10.87,12.47 10.42,13.34C10.22,13.72 10,14.08 9.92,14.38L10.03,14.34V14.34C11.3,13.85 12.5,13.57 13.37,13.41C13.22,13.31 13.08,13.2 12.96,13.09C12.36,12.58 11.84,11.84 11.43,10.94M17.91,14.75C17.74,14.94 17.44,15.05 17,15.05C16.24,15.05 15,14.82 14,14.31C12.28,14.5 11,14.73 9.97,15.06C9.92,15.08 9.86,15.1 9.79,15.13C8.55,17.25 7.63,18.2 6.82,18.2C6.66,18.2 6.5,18.16 6.38,18.09L5.9,17.78L5.87,17.73C5.8,17.55 5.78,17.38 5.82,17.19C5.93,16.66 6.5,15.82 7.7,15.07C7.89,14.93 8.19,14.77 8.59,14.58C8.89,14.06 9.21,13.45 9.55,12.78C10.06,11.75 10.38,10.73 10.63,9.85V9.84C10.26,8.63 10.04,7.9 10.41,6.57C10.5,6.19 10.83,5.8 11.2,5.8H11.44C11.67,5.8 11.89,5.88 12.05,6.04C12.71,6.7 12.4,8.31 12.07,9.64C12.05,9.7 12.04,9.75 12.03,9.78C12.43,10.91 13,11.82 13.63,12.34C13.89,12.54 14.18,12.74 14.5,12.92C14.95,12.87 15.38,12.85 15.79,12.85C17.03,12.85 17.78,13.07 18.07,13.54C18.17,13.7 18.22,13.89 18.19,14.09C18.18,14.34 18.09,14.57 17.91,14.75M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M17.5,14.04C17.4,13.94 17,13.69 15.6,13.69C15.53,13.69 15.46,13.69 15.37,13.79C16.1,14.11 16.81,14.3 17.27,14.3C17.34,14.3 17.4,14.29 17.46,14.28H17.5C17.55,14.26 17.58,14.25 17.59,14.15C17.57,14.12 17.55,14.08 17.5,14.04M8.33,15.5C8.12,15.62 7.95,15.73 7.85,15.81C7.14,16.46 6.69,17.12 6.64,17.5C7.09,17.35 7.68,16.69 8.33,15.5M11.35,8.59L11.4,8.55C11.47,8.23 11.5,7.95 11.56,7.73L11.59,7.57C11.69,7 11.67,6.71 11.5,6.47L11.35,6.42C11.33,6.45 11.3,6.5 11.28,6.54C11.11,6.96 11.12,7.69 11.35,8.59Z" />
      </svg>
  }

  render() {
    const { classes } = this.props;
    // const { data } = this.state;
    const columns = [
      {
        Header: "N° OPERACIÓN",
        accessor: "numoperacion",
        headerClassName: classes.HeaderTable,
        width: 100,
        id: "numoperacion",
        // filterable: false,
        className: classes.TDTable +" "+ classes.TDTableNoOperacion,
      },
      {
        Header: "FECHA OPERACIÓN",
        accessor: "fechoperacion",
        headerClassName: classes.HeaderTable,
        width: 100,
        className: classes.TDTable,
        Cell: (row) => <div>
          {row.original && row.original.fechoperacion ? Moment(row.original.fechoperacion).format("DD/MM/YYYY") : ""}
        </div>,
      },
      {
        Header: "MONTO OPERACIÓN",
        accessor: "monpagado",
        headerClassName: classes.HeaderTable+ " " + classes.TDTableRightAlign,
        width: 100,
        className: classes.TDTable+ " " + classes.TDTableRightAlign,
      },
      {
        Header: "ESTADO OPERACIÓN",
        headerClassName: classes.HeaderTable,
        width: "100%",
        className: classes.TDTable,
        Cell: (row) => {
          return <Stepper alternativeLabel className={classes.divSteper}>
              <Step key="" completed>
                <StepLabel>Ingresada</StepLabel>
              </Step>
              <Step key="">
                <StepLabel icon={<FiberManualRecord/>}>En revisión</StepLabel>
              </Step>
              <Step key="">
                <StepLabel icon={<FiberManualRecord/>}>Aprobada</StepLabel>
              </Step>
              <Step key="">
                <StepLabel icon={<FiberManualRecord/>}>Girada</StepLabel>
              </Step>
        </Stepper>

        },
      },
    ];
    return (
      <div>
        <div>
          <Breadcrumb category="Usted se encuentra en: Factoring / Operaciones / Seguimiento" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Seguimiento operaciones en curso
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>                    
                    <Tooltip title={this.state.expanded ? "Colapsar" : "Ampliar"} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title="Filtros de Búsqueda"
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={4} md={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="NumeroOp"
                          label="N&deg; Operación"                          
                          placeholder="000000"                          
                          onChange={this.onChangeNumOperacion}
                        />
                      </GridItem>
                      <GridItem xs={4} md={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="FchOp"
                          label="Fecha Operación"                          
                          placeholder="Desde - Hasta"                          
                        />
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                          >
                          Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>                                                            
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{position: "relative"}}>
            <GridItem className={classes.gridPDFExcelButtons}>
              <Tooltip title="Descargar PDF" placement="top">
                <IconButton>
                  <SVGIcons icon="pdf" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descargar Planilla Excel" placement="top">
                <IconButton>
                  <SVGIcons icon="excel" />
                </IconButton>
              </Tooltip>
            </GridItem>
              <NavPills
                color="info"
                tabs={[
                  {
                    tabButton: "FACTURAS",
                    hidden: !!this.state.data.length,
                    tabContent: (
                      <ReactTable

                        ref="facturasTable"
                        data={this.state.data}
                        filterable={false}
                        columns={columns}
                        defaultPageSize={10}
                        showPaginationBottom
                        PaginationComponent={PaginationBase}
                        previousText="Anterior"
                        nextText="Siguiente"
                        loadingText="cargando..."
                        noDataText="Sin información"
                        pageText="Pagina"
                        ofText="de"
                        rowsText="filas"
                        resizable={false}
                        style={{
                          //height: "400px", // forzar el scroll vertical
                        }}
                        defaultFilterMethod={this.defaultFilterMethod}
                      />
                    ),
                  },
                  {
                    tabButton: "CHEQUES",
                    hidden: !!this.state.data.length,
                    tabContent: (
                      <ReactTable
                        ref="chequesTable"
                        data={this.state.data}
                        filterable={false}
                        columns={columns}
                        defaultPageSize={10}
                        showPaginationBottom
                        PaginationComponent={PaginationBase}
                        previousText="Anterior"
                        nextText="Siguiente"
                        loadingText="cargando..."
                        noDataText="Sin información"
                        pageText="Pagina"
                        ofText="de"
                        rowsText="filas"
                        resizable={false}
                        style={{
                          //height: "400px", // forzar el scroll vertical
                        }}
                        defaultFilterMethod={this.defaultFilterMethod}
                      />
                    ),
                  },
                  {
                    tabButton: "LETRAS",
                    hidden: !!this.state.data.length,
                    tabContent: (
                      <ReactTable
                        ref="letrasTable"
                        data={this.state.data}
                        filterable={false}
                        columns={columns}
                        defaultPageSize={10}
                        showPaginationBottom
                        PaginationComponent={PaginationBase}
                        previousText="Anterior"
                        nextText="Siguiente"
                        loadingText="cargando..."
                        noDataText="Sin información"
                        pageText="Pagina"
                        ofText="de"
                        rowsText="filas"
                        resizable={false}
                        style={{
                          //height: "400px", // forzar el scroll vertical
                        }}
                        defaultFilterMethod={this.defaultFilterMethod}
                      />
                    ),
                  },
                  {
                    tabButton: "PAGARÉS",
                    hidden: !!this.state.data.length,
                    tabContent: (
                      <ReactTable
                        ref="pagaresTable"
                        data={this.state.data}
                        filterable={false}
                        columns={columns}
                        defaultPageSize={10}
                        showPaginationBottom
                        PaginationComponent={PaginationBase}
                        previousText="Anterior"
                        nextText="Siguiente"
                        loadingText="cargando..."
                        noDataText="Sin información"
                        pageText="Pagina"
                        ofText="de"
                        rowsText="filas"
                        resizable={false}
                        style={{
                          //height: "400px", // forzar el scroll vertical
                        }}
                        defaultFilterMethod={this.defaultFilterMethod}
                      />
                    ),
                  },
                ]}
              />

          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: (action) => { dispatch(action); },
});

SeguimientoOperaciones.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(SeguimientoOperaciones));

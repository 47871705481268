import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import Drawer from '@material-ui/core/Drawer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatNumber } from '../../../../helpers/FormatNumber';
import IconButton from '@material-ui/core/IconButton';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { formatStringDateYMD } from '../../../../helpers/FormatDate';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof HistoricoStyle> {
  open: boolean;
  onClose: () => void;
  facturas: Comun.Dto.Invoice[];
  totalFacturas: number;
  onDelete: (id: string) => void;
  onDeleteAll: () => void;
  onSolicitar: () => void;
}

const FacturasSeleccionadas: FC<IProps> = (props: IProps) => {
  const {
    classes,
    open,
    onClose,
    facturas,
    totalFacturas,
    onDelete,
    onDeleteAll,
    onSolicitar
  } = props;

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <div className={classes.drawer}>
        <IconButton aria-label='delete' className={classes.drawerCloseButton} onClick={onClose}>
          <ChevronRightIcon />
        </IconButton>
        <div className={classes.drawerTitle}>FACTURAS SELECCIONADAS</div>
        <Table className={classes.TableFTC} aria-label='simple table'>
          <TableHead className={classes.HeaderTableFCT}>
            <TableRow className={classes.TableRowFCT}>
              <TableCell className={classes.TableThFCT}>Nº documento</TableCell>
              <TableCell align='right' className={classes.TableThFCT}>
                Deudor
              </TableCell>
              <TableCell align='right' className={classes.TableThFCT}>
                Vencimiento
              </TableCell>
              <TableCell align='right' className={classes.TableThFCT}>
                Monto Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {facturas.map((row: Comun.Dto.Invoice) => (
              <TableRow
                key={row.id}
                className={[classes.TableRowBodyFCT].join(' ')}
              >
                <TableCell scope='row' className={classes.TableCellFCT}>
                  <IconButton aria-label='delete' onClick={() => onDelete(row.id)}>
                    <CancelOutlinedIcon />
                  </IconButton>
                  {row.folio + ' ' + row.tipoDoc.descripcion}
                </TableCell>
                <TableCell align='right' className={classes.TableCellFCT}>
                  {row.razonSocialReceptor}
                </TableCell>
                <TableCell align='right' className={classes.TableCellFCT}>
                  {formatStringDateYMD(row.fechaVencimiento)}
                </TableCell>
                <TableCell align='right' className={classes.TableCellFCT}>
                  {`$${formatNumber(row.monto)}`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.drawerResume}>
          <span className={classes.drawerCount}>Cantidad: {facturas.length}</span>
          <span>Total: {`$${formatNumber(totalFacturas)}`}</span>
        </div>
        <div className={classes.actionsButtons}>
          <div className={classes.deleteAll} onClick={onDeleteAll}>Eliminar Todos</div>
          <Button variant='contained' color='primary' onClick={onSolicitar} disabled={totalFacturas <= 0}>
            Solicitar
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default withStyles(HistoricoStyle)(FacturasSeleccionadas);

import { getEjecutivos, getResumenCartera, trackingSidebar } from '../../services/factoringApi';
import { asyncActionType } from '../../helpers/ActionsHelper';
import { Dispatch } from 'redux';

export const fetchAction = asyncActionType('@@DASHBOARD_FACTORING', 'FETCH');

export const fetchResumenCarteraFactoring = () => ({
  type: fetchAction.PENDING
});

export const resumenCarteraFactoringFetched = (response : any[]) => ({
  type: fetchAction.SUCCESS,
  rows: response
});

export const FilterResumenCarteraFactoring = (params : any) => (dispatch : Dispatch) => {
  dispatch(fetchResumenCarteraFactoring());

  getResumenCartera(params)
    .then((response) => {
      dispatch(resumenCarteraFactoringFetched(response));
    })
    .catch(error => error);
};

export const cargarEjecutivos = () => getEjecutivos().then(response => response).catch(error => error);

export const TrackingSidebar = (params : string) => () => {
  return trackingSidebar(params)
    .then(response => response)
    .catch(error => error);
};

// External
import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  EventTrackingExcel,
  FilterOperacionesHistoricas,
  GetExcel,
  GetIndividualPDF,
  GetPDF,
  TrackingSidebar
} from './actions';
import xlsx from 'xlsx';
import ReactTable from 'react-table';
import Moment from 'moment/moment';

// componentes material ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// componentes custom
import Breadcrumb from '../../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../../shared/Grid/GridItem';
import GridContainer from '../../../shared/Grid/GridContainer';
import NavPills from '../../../shared/NavPills/NavPills';
import SVGIcons from '../../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../../shared/RangeCalendar/RangeCalendar';
import Loader from '../../../shared/Loader/Loader';
import PaginationBase from '../../../shared/Pagination/PaginationBase';
import {formatNumber} from '../../../../helpers/FormatNumber';
import {onChangeOnlyNumericHelper} from '../../../../helpers/ValidateNumeric';
import PDFViewer from '../../../general/pdfViewer/PDFViewer';
import PDFJSBackend from '../../../../back/pdfjs';

// Importación de estilo para tabla
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';

const divSinPdf = (
  <div style={{
    height: 40,
    color: '#A9ACAD',
    textAlign: 'center',
    border: '5px dashed #E5EAD9',
    marginTop: 110,
    padding: 40,
    fontSize: '.86rem'
  }}>
    <span style={{width: '100%', float: 'left'}}>Seleccione ver detalle</span>
    <span style={{width: '100%', float: 'left'}}>para visualizar aquí</span>
    <span style={{width: '100%', float: 'left'}}>su Factura.</span>
  </div>
);

class OperacionesHistoricas extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      expanded: true,
      anchorEl: null,
      loading: true,
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      data: props.rows,
      dataFactura: props.rows,
      dataPagare: props.rows,
      dataLetra: props.rows,
      dataCheque: props.rows,
      tipoDocumento: '',
      activeTab: 1,
      openCalendar: false,
      anchorElCalendar: null,
      numOperacion: '',
      namePdf: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: null,
          key: 'selection'
        }
      },
      statusAction: false,
      sinData: false
    };
    this.fetchData = this.fetchData.bind(this);
    this.myViewer = React.createRef();
  }

  onChangeTab = (active) => {
    this.setState((state) => ({activeTab: active}));
  };

  _loading = (b, t) => {
    let _obj = {};
    _obj['loading' + t] = b;
    return _obj;
  };

  fetchData(state, tipoDocumento, dataProp, dataPagination) {
    let _obj = {};
    _obj[dataProp] = [];
    _obj[dataPagination] = {
      currentPage: 0,
      top: 0,
      totalItems: 0,
      totalPages: 0,
      from: 0,
      to: 0
    };
    this.setState({
      ...this._loading(true, tipoDocumento),
      ...{statusAction: true},
      ..._obj
    });
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(FilterOperacionesHistoricas(this.getFilters(state.page, state.pageSize, tipoDocumento, state.sorted)))])
    )
      .then((data) => {
        if (data[0] && data[0].pagination && data[0].rows) {
          _obj[dataPagination] = data[0].pagination ? data[0].pagination : {};
          _obj[dataProp] = data[0].rows ? data[0].rows : {};
          this.setState({sinData: false});
        } else {
          this.setState({sinData: true});
        }
        this.setState({...{statusAction: false}, ...this._loading(false, tipoDocumento), ..._obj});
      });
  }

  buttonGetPDF = () => {
    let paginationPage, paginationPageSize;
    switch (this.state.activeTab) {
      case 1:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 2:
        paginationPage = this.state.paginationCheque.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 3:
        paginationPage = this.state.paginationLetra.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      case 4:
        paginationPage = this.state.paginationPagare.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
        break;
      default:
        paginationPage = this.state.paginationFactura.currentPage - 1;
        paginationPageSize = this.state.paginationFactura.top;
    }
    if (this.state.statusAction === true) return;
    this.setState({...this._loading(true, this.state.activeTab), ...{statusAction: true}});
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetPDF(this.getFilters(paginationPage, paginationPageSize, this.state.activeTab)))])
    ).then(() => {
      this.setState({
        ...{statusAction: false},
        ...this._loading(false, this.state.activeTab)
      });
    });
  };

  buttonGetExcel = (b) => {
    if (this.state.statusAction === true) return;
    this.setState({...{statusAction: true}, ...this._loading(true, this.state.activeTab)});
    let paginationTotalItems;
    switch (this.state.activeTab) {
      case 1:
        paginationTotalItems = this.state.paginationFactura.totalItems;
        break;
      case 2:
        paginationTotalItems = this.state.paginationCheque.totalItems;
        break;
      case 3:
        paginationTotalItems = this.state.paginationLetra.totalItems;
        break;
      case 4:
        paginationTotalItems = this.state.paginationPagare.totalItems;
        break;
      default:
        paginationTotalItems = this.state.paginationFactura.totalItems;
    }
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetExcel(this.getFilters(0, paginationTotalItems, this.state.activeTab)))])
    ).then((data) => {
      this.setState({
        ...{statusAction: false},
        ...this._loading(false, this.state.activeTab)
      }, () => {
        this.exportExcel(data[0]);

      });
      return this.props.dispatch(
        dispatch => Promise.all([dispatch(EventTrackingExcel('operacionHistoricaExcel'))])
      ).then(data => data);
    });
  };

  exportExcel = (data) => {
    let workbook = xlsx.utils.book_new();
    let formattedData = [];
    if (data.data.length > 0) {
      formattedData = data.data.map(d => {
        let formattedOrder = {
          'N° OPERACIÓN': d.numeroOperacion,
          'FECHA OPERACIÓN': d.fechaOperacion ? Moment(d.fechaOperacion).format('DD/MM/YYYY') : '',
          'MONTO OPERACIÓN': d.montoOperacion,
          'MONTO GIRADO': (d.montoLiquido)
        };
        return formattedOrder;
      });
    }
    let orderSheet = xlsx.utils.json_to_sheet(formattedData);

    orderSheet['!cols'] = [
      {wpx: 100}, //N° Operación
      {wpx: 130}, //Fecha operación
      {wpx: 140}, //Monto operación
      {wpx: 130}, //Monto documento
      {wpx: 110} //Monto girado
    ];

    xlsx.utils.book_append_sheet(workbook, orderSheet, 'FACTURAS');
    xlsx.writeFile(workbook, `Operaciones Historicas - ${Moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  getFilters(page, pageSize, tipoDocumento, sorted) {
    return {
      numeroOperacion: this.state.numOperacion,
      fechaOperacionDesde: (this.state.calendarValue.desde) ? Moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      fechaOperacionHasta: (this.state.calendarValue.hasta) ? Moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      page: page,
      pageSize: pageSize,
      tipoDocumento: tipoDocumento,
      ascending: sorted && sorted[0] && sorted[0].desc ? false : sorted && sorted[0] ? true : null,
      orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : ''
    };
  }

  getSortIdColumn(column) {
    let _id = null;
    switch (column) {
      case 'numeroOperacion':
        _id = 1;
        break;
      case 'fechaOperacion':
        _id = 2;
        break;
      case 'FormaPago':
        _id = 3;
        break;
      case 'montoOperacion':
        _id = 4;
        break;
      case 'MontoDocumento':
        _id = 5;
        break;
      case 'MontoAnticipado':
        _id = 6;
        break;
      case 'MontoNoAnticipado':
        _id = 7;
        break;
      case 'Retencion':
        _id = 8;
        break;
      case 'DifPrecio':
        _id = 9;
        break;
      case 'Comision':
        _id = 10;
        break;
      case 'IVAComision':
        _id = 11;
        break;
      case 'GastosOperacion':
        _id = 12;
        break;
      case 'IVAGastosOperacion':
        _id = 13;
        break;
      case 'GastosNotariales':
        _id = 13;
        break;
      case 'IVAGastosNotariales':
        _id = 14;
        break;
      case 'OtrosGastos':
        _id = 15;
        break;
      case 'OtrosDescuentos':
        _id = 16;
        break;
      case 'montoLiquido':
        _id = 16;
        break;
      case 'BancoDestino':
        _id = 17;
        break;
      case 'CodBancoDestino':
        _id = 18;
        break;
      case 'Familia':
        _id = 19;
        break;
      case 'CodFamilia':
        _id = 20;
        break;
      case 'NumTotalDocumento':
        _id = 21;
        break;
      case 'PorcFinanciado':
        _id = 22;
        break;
      case 'PorcComision':
        _id = 23;
        break;
      case 'Estado':
        _id = 24;
        break;
      case 'CodEstadoWorkflow':
        _id = 25;
        break;
      case 'EstadoWorkflow':
        _id = 26;
        break;
    }
    return _id;
  }

  handleFiltrar = () => {
    this.refs.facturasTable.state.page = 0;
    this.refs.chequesTable.state.page = 0;
    this.refs.letrasTable.state.page = 0;
    this.refs.pagaresTable.state.page = 0;
    this.refs.facturasTable.fireFetchData();
    this.refs.chequesTable.fireFetchData();
    this.refs.letrasTable.fireFetchData();
    this.refs.pagaresTable.fireFetchData();

    if (!this.checkInputFilter()) {
      Promise.all([new Promise(TrackingSidebar('historicoOperacionesBuscar'))])
        .then(data => data);
    }
  };

  handleExpandClick = () => {
    this.setState((state) => ({expanded: !state.expanded}));
  };

  defaultFilterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  onChange = (e) => this.setState({[e.target.name]: e.target.value});

  onChangeOnlyNumeric = (e) => this.setState({[e.target.name]: onChangeOnlyNumericHelper(e)});

  handleClickTable = (event, row, id) => {
    const documentPdfId = document.getElementById('renderPDFId');
    let renderPDF = (divSinPdf);
    ReactDOM.render(renderPDF, documentPdfId);
    this.setState(this._loading(true, this.state.activeTab));
    const {currentTarget} = event;
    this.setState({idSeleccion: id});
    let {pageActual} = this.state;
    this.setState({isPageOpSelected: true, pageOpSelected: pageActual});

    for (var i of document.getElementsByClassName('active')) {
      if (i.nodeName === 'svg') i.classList.remove('active');
    }
    currentTarget.classList.add('active');
    return this.props.dispatch(
      dispatch => Promise.all([dispatch(GetIndividualPDF({
        numeroOperacion: row.numeroOperacion,
        tipoDocumento: this.state.activeTab
      }))])
    ).then((data) => {
      this.setState(this._loading(false, this.state.activeTab));
      if (data && data[0] && data[0].data && data[0].data.documento) {
        renderPDF = (
          <PDFViewer
            ref={this.myViewer}
            backend={PDFJSBackend}
            namePdf={`${row.numeroOperacion}-Operaciones`}
            eventName="Operaciones"
            base64={data[0].data.documento} />);
        ReactDOM.render(renderPDF, documentPdfId);
      }
    });
  };

  handleStatesCalendar = params => {
    this.setState(params);
  };

  cleanFilters() {
    this.setState({
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numOperacion: ''
    }, () => {
      this.handleFiltrar();
    });
  }

  checkInputFilter = () => {
    return this.state.calendarValue.desde ||
    this.state.calendarValue.hasta ||
    this.state.numOperacion.length > 0 ? false : true;
  };

  render() {
    let showFactura = false;
    let showCheques = false;
    let showLetras = false;
    let showPagares = false;
    const {classes, rows} = this.props;
    const {
      openCalendar, anchorElCalendar, calendarValue, dataFactura, dataCheque,
      dataLetra, dataPagare, sinData
    } = this.state;
    const cheques = [];
    const facturas = [];
    const letras = [];
    const pagares = [];
    rows.forEach(element => {
      if (element.detalle.find(x => x.tipoDocumento === 'Factura Electronica' || x.tipoDocumento === 'Factura Exenta Electronica')) {
        showFactura = true;
        if (!sinData) facturas.push(element);
      }
      if (element.detalle.find(x => x.tipoDocumento === 'Cheque por Cobrar')) {
        showCheques = true;
        if (!sinData) cheques.push(element);
      }
      if (element.detalle.find(x => x.tipoDocumento === 'Letra por Cobrar')) {
        showLetras = true;
        if (!sinData) letras.push(element);
      }
      if (element.detalle.find(x => x.tipoDocumento === 'Pagare por Cobrar')) {
        showPagares = true;
        if (!sinData) pagares.push(element);
      }
    });

    const columns = [
      {
        Header: 'N° OPERACIÓN',
        accessor: 'numeroOperacion',
        headerClassName: classes.HeaderTable,
        id: 'numeroOperacion',
        className: classes.TDTable + ' ' + classes.TDTableNoOperacion
      },
      {
        Header: 'FECHA OPERACIÓN',
        accessor: 'fechaOperacion',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row) => <div>
          {row.original && row.original.fechaOperacion ? Moment(row.original.fechaOperacion).format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        Header: 'MONTO OPERACIÓN',
        accessor: 'montoOperacion',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableTotalOperacionHeader + ' ' + classes.TDTableRightAlign,
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value)
      },
      {
        Header: 'MONTO GIRADO',
        accessor: 'montoLiquido',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: props => formatNumber(props.value)
      },
      {
        Header: 'LIQUIDACIÓN',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign,
        Cell: (row) => <div>
          <SearchIcon
            className={classes.TDTableCenterIcon}
            onClick={(e) => this.handleClickTable(e, row.original, `filaCheq${row.index}`)}
            id={`filaCheq${row.index}`}
          />
        </div>
      }
    ];

    const tableProps = (params) => ({
      columns: params.columns,
      defaultPageSize: 10,
      minRows: params.totalItemsPerPage && params.totalItemsPerPage <= params.totalItems ? params.totalItemsPerPage : 10,
      showPaginationBottom: params.totalItemsPerPage === params.totalItems ? false : true,
      PaginationComponent: PaginationBase,
      previousText: 'Anterior',
      nextText: 'Siguiente',
      noDataText: 'Sin información',
      pageText: 'Pagina',
      ofText: 'de',
      rowsText: 'filas',
      resizable: false,
      defaultFilterMethod: this.defaultFilterMethod,
      filterable: true,
      showFilters: false,
      loadingText: <Loader loadTable />,
      manual: true,
      data: params.data,
      ref: params.nameTable,
      onFetchData: (state) => this.fetchData(state, params.noTable, params.nomDataTable, params.paginationTable),
      pages: params.pages,
      totalItems: params.totalItems,
      loading: params.loading
    });

    const listTabs = [
      {
        tabButton: 'FACTURAS',
        hidden: !!showFactura,
        tabContent: (
          <ReactTable
            {...tableProps({
              nameTable: 'facturasTable',
              columns: columns,
              loading: this.state.loading1,
              data: facturas,
              pages: this.props.paginationFactura.totalPages,
              totalItems: this.props.paginationFactura.totalItems,
              totalItemsPerPage: this.props.paginationFactura.top,
              noTable: 1,
              nomDataTable: 'dataFactura',
              paginationTable: 'paginationFactura'
            })}
          />
        )
      },
      {
        tabButton: 'CHEQUES',
        hidden: !!showCheques,
        tabContent: (
          <ReactTable
            {...tableProps({
              nameTable: 'chequesTable',
              columns: columns,
              loading: this.state.loading2,
              data: cheques,
              pages: this.props.paginationCheque.totalPages,
              totalItems: this.props.paginationCheque.totalItems,
              totalItemsPerPage: this.props.paginationCheque.top,
              noTable: 2,
              nomDataTable: 'dataCheque',
              paginationTable: 'paginationCheque'
            })}
          />
        )
      },
      {
        tabButton: 'LETRAS',
        hidden: !!showLetras,
        tabContent: (
          <ReactTable
            {...tableProps({
              nameTable: 'letrasTable',
              columns: columns,
              loading: this.state.loading3,
              data: letras,
              pages: this.props.paginationLetra.totalPages,
              totalItems: this.props.paginationLetra.totalItems,
              totalItemsPerPage: this.props.paginationLetra.top,
              noTable: 3,
              nomDataTable: 'dataLetra',
              paginationTable: 'paginationLetra'
            })}
          />
        )
      },
      {
        tabButton: 'PAGARÉS',
        hidden: !!showPagares,
        tabContent: (
          <ReactTable
            {...tableProps({
              nameTable: 'pagaresTable',
              columns: columns,
              loading: this.state.loading4,
              data: pagares,
              pages: this.props.paginationPagare.totalPages,
              totalItems: this.props.paginationPagare.totalItems,
              totalItemsPerPage: this.props.paginationPagare.top,
              noTable: 4,
              nomDataTable: 'dataPagare',
              paginationTable: 'paginationPagare'
            })}
          />
        )
      }
    ];

    listTabs.sort(function (a, b) {
      return b.hidden - a.hidden;
    });
    return (
      <div>
        <div>
          <Breadcrumb category="Usted se encuentra en: Factoring / Operaciones / Histórico" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Operaciones
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          // [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Typography variant="h5" className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color="primary" variant="outlined" onClick={() => this.cleanFilters()}
                            style={{marginLeft: 14}}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer container direction="row" alignItems="center" wrap="nowrap">
                      <GridItem xs={4} md={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{shrink: true}}
                          id="NumeroOp"
                          name="numOperacion"
                          label="N&deg; Operación"
                          value={this.state.numOperacion}
                          placeholder="000000"
                          onChange={this.onChangeOnlyNumeric}
                        />
                      </GridItem>
                      <GridItem xs={4} md={3}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id="FchOp"
                            name="FchOp"
                            label="Fecha Operación"
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.handleFiltrar}
                            disabled={this.checkInputFilter()}
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={7} style={{position: 'relative'}}>
            {(dataFactura.length > 0 || dataCheque.length > 0 || dataLetra.length > 0 || dataPagare.length > 0) &&
            <GridItem className={classes.gridPDFExcelButtons}>
              <Tooltip title="Descargar PDF" placement="top">
                <IconButton
                  onClick={() => this.buttonGetPDF()}
                >
                  <SVGIcons id="OpHIconDescargarPdf" icon="pdf" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descargar Planilla Excel" placement="top">
                <IconButton onClick={() => this.buttonGetExcel()}>
                  <SVGIcons id="OpHIconDescargarExcel" icon="excel" />
                </IconButton>
              </Tooltip>
            </GridItem>}
            <NavPills
              color="info"
              onChangeTab={active => this.setState({activeTab: active})}
              tabs={listTabs}
            />
          </GridItem>
          <GridItem xs={5} style={{padding: 8}}>
            <Card style={{height: '100%', display: 'flex', alignItems: 'center', minHeight: 400}}>
              <CardContent id="renderPDFId" className={classes.facEmitPDFContainer}>
                {divSinPdf}
              </CardContent>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading,
  rows: state.operacionesHistoricas.rows,
  pagination: state.operacionesHistoricas.pagination,
  pageSize: state.operacionesHistoricas.pageSize,
  paginationLetra: state.operacionesHistoricas.pagination,
  paginationFactura: state.operacionesHistoricas.pagination,
  paginationCheque: state.operacionesHistoricas.pagination,
  paginationPagare: state.operacionesHistoricas.pagination
});

OperacionesHistoricas.propTypes = {
  classes: PropTypes.object.isRequired,
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired
};
export default withStyles(HistoricoStyle)(connect(mapStateToProps)(OperacionesHistoricas));



import React from 'react';
import { WithStyles } from '@material-ui/core';
import DashboardStyle from '../../styles/dashboardStyle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Leasing from '../leasing/view';
import LeasingProducto from '../leasing/producto';

interface IProps extends WithStyles<typeof DashboardStyle>, RouteComponentProps {
  empresa : Dto.EnterpriseProfile;
}

interface IState {
}

class TableroLeasing extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { empresa } = this.props;
    return (empresa.leasing ? <Leasing /> : <LeasingProducto />);
  }
}

export default withStyles(DashboardStyle)(withRouter(TableroLeasing));

import {createMuiTheme} from '@material-ui/core/styles';
import shadows from '@material-ui/core/styles/shadows';

const palette = {
  primary: {
    light: '#98DC01',
    main: '#82BC00',
    dark: '#608C00',
    contrastText: 'white'
  },
  secondary: {
    light: '#00A245',
    main: '#007934',
    dark: '#00411D',
    contrastText: '#2E2A25',
    white: '#FFFFFF'
  },
  dark: '#2E2A25',
  grey: {
    50: '#BDBFC0',
    100: '#A9ACAD',
    200: '#95989A',
    300: '#7B7E81',
    400: '#686B6D',
    500: '#555759',
    600: '#4B4D4F',
    700: '#424345',
    800: '#383A3B',
    900: '#2F3031',
    A100: '#F9F9F9',
    A200: '#EFF0F0',
    A400: '#E5E6E6',
    A700: '#DBDCDD'
  },
  text: {
    primary: 'rgba(46, 42, 37, 0.87)',
    secondary: 'rgba(46, 42, 37, 0.54)',
    disabled: 'rgba(46, 42, 37, 0.38)',
    hint: 'rgba(46, 42, 37, 0.38)',
    divider: 'rgba(46, 42, 37, 0.12)'
  },
  background: {
    paper: 'white',
    default: '#F9F9F9'
  },
  action: {
    active: 'rgba(130, 188, 0, 0.54)',
    hover: 'rgba(130, 188, 0, 0.08)',
    hoverOpacity: 0.08,
    selected: 'rgba(130, 188, 0, 0.14)',
    disabled: 'rgba(130, 188, 0, 0.26)',
    disabledBackground: 'rgba(130, 188, 0, 0.12)'
  },
  'shadows': [
    'none',
    '0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px -2px rgba(0, 0, 0, 0.1);',
    '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
    '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
    '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
    '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
    '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
  ]
};
const themeFacTotal = createMuiTheme({
  palette,
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Larsseit',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontSize: '1rem' as any,
    fontWeightMedium: 400,
    // color: palette.grey[500] as string,
    h4: {
      fontSize: '1.2em',
      lineHeight: '1.2em',
      fontWeight: 700,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.grey[100]
    },
    h3: {
      fontSize: '1.875em',
      lineHeight: '1.875em',
      fontWeight: 500,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.primary.main
    },
    h2: {
      fontSize: '1.125em',
      lineHeight: '1.125em',
      fontWeight: 500,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.grey[300]
    },
    h1: {
      fontSize: '0.8em',
      lineHeight: '0.8em',
      fontWeight: 500,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.primary.dark
    },
    h5: {
      fontSize: '1rem',
      lineHeight: '1.125rem',
      fontWeight: 500,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.grey[500]
    },
    h6: {
      fontSize: '.875rem',
      lineHeight: '1rem',
      fontWeight: 500,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.grey[300]
    },
    subtitle1: {
      fontSize: '.75rem',
      lineHeight: '.875rem',
      fontWeight: 400,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.grey[500]
    },
    body1: {
      fontSize: '.75rem',
      lineHeight: '.875rem',
      fontWeight: 400,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      color: palette.grey[400]
    },
    body2: {
      //fontSize: ".75rem",
      //lineHeight: ".875rem",
      fontWeight: 400,
      fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(',')
      //color: palette.grey[400],
    },
    button: {
      textTransform: 'none' as 'none'
    }
  },
  overrides: {
    MuiInput: {

      underline: {
        '&:after, &:hover:after': {
          borderBottom: '1px solid #82BC00'
        },
        '&:before': {
          borderBottom: '1px solid #82BC00'
        },
        '&:after': {
          borderBottom: '1px solid #82BC00'
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: '1px solid #82BC00'
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: palette.grey[500],
        '& $focused': {
          color: palette.primary.main
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: '.75rem'
      }
    },
    MuiSvgIcon: {
      root: {
        color: palette.grey[200]
      }
    },
    MuiPaper: {
      root: {
        elevation: 0,
        boxShadow: shadows[0]
      }
    },
    MuiCard: {
      root: {
        overflow: 'unset' as 'unset',
        borderRadius: 0,
        boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 0px 1px -2px rgba(0, 0, 0, 0.1);'
      }
    },
    MuiCardContent: {
      root: {
        paddingLeft: '12px !important',
        paddingRight: '12px !important' as '12px !important'
      }
    },
    MuiButton: {
      root: {
        color: '#2E2A25',
        fontSize: '1rem',
        minHeight: 30
      },
      contained: {
        boxShadow: 'none' as 'none',
        fontSize: '.8rem',
        background: palette.primary.main
      },
      outlinedPrimary: {
        color: '#82BC00',
        border: '1px solid #82BC00'
      },
      outlined: {
        boxShadow: 'none' as 'none',
        background: 'none',
        fontSize: '.75rem',
        textTransform: 'uppercase' as 'uppercase',
        fontWeight: 600,
        lineHeight: '.75rem'
      },
      /* Styles applied to the root element if `size="small"`. */
      sizeSmall: {
        fontSize: '.625rem'
      },
      /* Styles applied to the root element if `size="large"`. */
      sizeLarge: {
        fontSize: '.875rem'
      },
      /* Styles applied to the root element if `fullWidth={true}`. */
      fullWidth: {
        width: '100%'
      }
    },
    MuiIconButton: {
      root: {
        padding: '0 2px'
      }
    },
    MuiTabs: {
      root: {
        minHeight: 38,
        marginTop: 4
      }
    }
  }
});

export default themeFacTotal;

import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { Theme } from '@material-ui/core';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

interface ITablePaginationActionsProps extends TablePaginationActionsProps, WithStyles<typeof actionsStyles> {

}

const actionsStyles = (theme : Theme) => ({
  root: {
    flexShrink: 0
  }
});

export class TablePaginationActions extends React.Component<ITablePaginationActionsProps> {

  handleFirstPageButtonClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    this.props.onChangePage(event, 0);
  };

  handleBackButtonClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    this.props.onChangePage(event, this.props.page - 1);
  };

  handleNextButtonClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    this.props.onChangePage(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event : React.MouseEvent<HTMLButtonElement>) => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1)
    );
  };

  render() {
    const { count, rowsPerPage, page } = this.props;

    return (
      <div className="paginatorFTC">
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="Primera"
          className="paginatorIcon">
          <FirstPageIcon />
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="Anterior"
          className="paginatorIcon">
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Siguiente"
          className="paginatorIcon">
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Ultima"
          className="paginatorIcon">
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}


export const TablePaginationActionsWrapped = withStyles(actionsStyles, { withTheme: true })(
  TablePaginationActions
);

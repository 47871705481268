import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Loader from '../../../shared/Loader/Loader';
import { formatNumber } from '../../../../helpers/FormatNumber';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CloseIcon from '@material-ui/icons/Close';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import Header from '../../../sharedv2/Header/Header';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import FacturasSeleccionadas from './FacturasSeleccionadas';
import SolicitarConfirm from './SolicitarConfirm';
import CargaManualXml from './CargaManualXml';
import CargaAutomaticaXml from '../../../sharedv2/ConfiguracionSincronizacion/ConfiguracionSincronizacionDte';
import Checkbox from '@material-ui/core/Checkbox';
import {
  actualizarFechaVencimiento,
  limpiarSimulacion,
  obtenerDocumentos,
  quitarDocumento,
  resetFiltrosDocumentos,
  seleccionarDocumento,
  solicitarOperacion,
  vaciarDocumentos
} from './actions';
import { formatStringDate, formatStringDateYMD } from '../../../../helpers/FormatDate';
import TableContainer from '../../../sharedv2/Table/TableContainer';

import RefreshIcon from '@material-ui/icons/Refresh';
import actions from '../../../main/reducer';
import ErrorEnSolicitudOperacion from './ErrorEnSolicitudOperacion';
import fxActions from '../../../general/fxEmpresa/actions';
import { AppState } from '../../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';
import routes from '../../../../config/routes';
import { esEjecutivoAsistente } from '../../../../helpers/UserHelper';

const tableInfo = (classes: any,
                   handleCheckFactura: (event: React.ChangeEvent<HTMLInputElement>) => void,
                   isChecked: (invoice: Comun.Dto.Invoice) => boolean,
                   handleDateChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
                   deshabilitarCheckbox: boolean): TC.Fila[] => [
  {
    column: 'Nº documento',
    relation: 'folio',
    disabled: (row: Comun.Dto.Invoice) => !row.puedeOperar,
    renderData: (row: Comun.Dto.Invoice) => {
      let disabled = !row.puedeOperar || deshabilitarCheckbox;//el checkbox se deshabilita para ejecutivos
      return (<span>
        <Checkbox
          value={row.id}
          checked={row.puedeOperar && isChecked(row)}
          disabled={disabled}
          onChange={handleCheckFactura}
          inputProps={{ 'aria-label': 'Seleccionar' }}
        /> {row.folio}
      </span>);
    }
  }, {
    column: 'Tipo documento',
    relation: 'tipoDteDesc',
    disabled: (row: Comun.Dto.Invoice) => !row.puedeOperar,
    renderData: (row: Comun.Dto.Invoice) => {
      if (row.puedeOperar) {
        return <React.Fragment>{row.tipoDoc.descripcion}</React.Fragment>;
      } else {
        return <React.Fragment><InfoIcon className={classes.TableCellFCTERRORCONTENTINFO} />
          <span>{row.mensajeError}</span></React.Fragment>;
      }
    },
    colspan: (row: Comun.Dto.Invoice) => row.puedeOperar ? 1 : 5
  }, {
    column: 'Deudor',
    relation: 'razonSocialReceptor',
    hiddenFnc: (row: Comun.Dto.Invoice) => !row.puedeOperar
  }, {
    column: 'Emisión',
    relation: 'fechaEmision',
    hiddenFnc: (row: Comun.Dto.Invoice) => !row.puedeOperar,
    renderData: (row: Comun.Dto.Invoice) => <React.Fragment>{formatStringDate(row.fechaEmision)}</React.Fragment>
  }, {
    column: 'Vencimiento',
    relation: 'fechaVencimiento',
    hiddenFnc: (row: Comun.Dto.Invoice) => !row.puedeOperar,
    renderData: (row: Comun.Dto.Invoice) => (<React.Fragment>
        {row.puedeOperar ?
          <TextField
            id='date'
            name={row.id}
            type='date'
            value={formatStringDateYMD(row.fechaVencimiento)}
            className={(row.fechaVencimiento === '' || row.fechaInvalida) ? classes.datePickerVencErr : classes.datePickerVenc}
            onChange={handleDateChange}
            variant='standard'
            InputLabelProps={{
              shrink: true
            }}
          /> : undefined}
      </React.Fragment>
    )
  }, {
    column: 'Monto total',
    relation: 'numeroOperacion',
    hiddenFnc: (row: Comun.Dto.Invoice) => !row.puedeOperar,
    align: 'right',
    renderData: (row: Comun.Dto.Invoice) => <React.Fragment>{`$${formatNumber(row.monto)}`}</React.Fragment>
  }
];

interface DispatchProps {
  limpiarEstado: () => Promise<any>;
  clearFilters: () => Promise<any>;
  vaciarDocumentos: () => Promise<any>;
  obtenerDocumentos: (filters: Filters.Documentos) => Promise<any>;
  seleccionarDocumento: (doc: Comun.Dto.Invoice) => Promise<any>;
  quitarDocumento: (doc: Comun.Dto.Invoice) => Promise<any>;
  solicitarOperacion: (payload: Payloads.SolicitarOperacionPayload) => Promise<any>;
  actualizarFechaVencimiento: (payload: Payloads.ActualizarFechaVencimientoPayload) => Promise<any>;
  notificacion: (exito: boolean, mensaje: string, timeout?: number) => any;
  cargarDatosEmpresaUsuario: () => void;
}

interface IProps extends DispatchProps, WithStyles<typeof HistoricoStyle> {
  documentos: Comun.Dto.Invoice[];
  seleccionados: Comun.Dto.Invoice[];
  pagination: State.Paginacion;
  empresa: Dto.Portal.Empresa;
  history: any;
  esEjecutivo: boolean;
}

interface IState {
  activeTab: number;
  rutDeudor: string;
  folioFactura: string;
  openFacturasSeleccionadas: boolean;
  solicitarModal: boolean;
  uploadAutomaticaXml: boolean;
  uploadManualXml: boolean;
  termsModal: boolean;
  firstTime: boolean;
  enviandoSolicitud: boolean;
  documentosRetirados: Factoring.Dto.Documento.Seleccionable[];
  errorSolicitarModal: boolean;
  cargandoPagina: boolean;
}

class OperacionSolicitud extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      activeTab: 0,
      rutDeudor: '',
      folioFactura: '',
      openFacturasSeleccionadas: false,
      solicitarModal: false,
      uploadAutomaticaXml: false,
      uploadManualXml: false,
      termsModal: false,
      firstTime: false,
      enviandoSolicitud: false,
      documentosRetirados: [],
      errorSolicitarModal: false,
      cargandoPagina: false
    };

    this.obtenerDocumentos = this.obtenerDocumentos.bind(this);
    this.resetDocumentos = this.resetDocumentos.bind(this);
    this.actualizarDocumentos = this.actualizarDocumentos.bind(this);
  }

  componentDidMount() {
    this.props.clearFilters();
    this.props.limpiarEstado()
        .then(() => {
          this.resetDocumentos();
        });
  }

  resetDocumentos() {
    const pag: State.Paginacion = this.props.pagination;
    this.obtenerDocumentos(0, pag.pageSize);
  }

  actualizarDocumentos() {
    const pag: State.Paginacion = this.props.pagination;
    this.obtenerDocumentos(pag.page, pag.pageSize);
  }

  obtenerDocumentos(pagina: number, tamano: number) {
    this.setState({ cargandoPagina: true });
    return this.props.obtenerDocumentos(this.getFilters(pagina, tamano))
               .then((data) => {
                 this.setState({ cargandoPagina: false });
               });
  }

  getFilters(pagina: number, tamano: number): Filters.Documentos {
    return {
      fechaEmision: undefined,
      folios: this.state.folioFactura,
      pagina: pagina + 1,
      tamano: tamano,
      razonSocialDeudor: this.state.rutDeudor,
      tipoDte: ''
    };
  }

  onChangeTab = (event: React.ChangeEvent<any>, activeTab: string) => this.setState({ activeTab: Number(activeTab) });

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleClearInput = (target: string) => {
    // @ts-ignore
    this.setState({ [target]: '' });
  };

  handleLimpiarFiltros = () => {
    this.setState({
      folioFactura: '',
      rutDeudor: ''
    });
  };

  openShop = () => {
    if (!this.state.firstTime) {
      this.setState({
        openFacturasSeleccionadas: true,
        firstTime: true
      });
    }
  };

  handleCheckFactura = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.esEjecutivo) return;
    const id = event.target.value;
    const select = this.props.documentos.find((el: Comun.Dto.Invoice) => el.id === id);

    if (!select) return;

    if (event.target.checked) {
      if (select.fechaVencimiento === '') {
        this.props.notificacion(false, 'Debe definir la fecha de vencimiento antes de seleccionar el documento', 5000);
      } else {
        this.props.seleccionarDocumento(select);
        this.openShop();
      }
    } else {
      this.props.quitarDocumento(select);
    }
  };

  isChecked = (invoice: Comun.Dto.Invoice): boolean => {
    const checked = this.props.seleccionados.find((el: Comun.Dto.Invoice) => el.id === invoice.id);
    return !!checked;
  };

  deleteSelected = (id: string) => {
    const select = this.props.seleccionados.find((el: Comun.Dto.Invoice) => el.id === id);
    if (select) {
      this.props.quitarDocumento(select);
    }
  };

  deseleccionarTodos = () => {
    this.props.vaciarDocumentos();
  };

  handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.actualizarFechaVencimiento({
      date: event.target.value,
      ids: [event.target.name]
    });
  };

  closeFacturasSeleccionadas = () => {
    this.setState((state: IState) => ({ openFacturasSeleccionadas: false }));
  };

  openFacturasSeleccionadas = () => {
    this.setState((state: IState) => ({ openFacturasSeleccionadas: true }));
  };

  handleSolicitar = () => {
    this.setState((state: IState) => {
      return {
        solicitarModal: true,
        openFacturasSeleccionadas: false
      };
    });
  };

  handleAceptarSolicitud = () => {
    this.setState((state) => ({ enviandoSolicitud: true }));
    const payload: Payloads.SolicitarOperacionPayload = {
      ids: this.props.seleccionados.map(d => d.id)
    };
    this.props.solicitarOperacion(payload)
        .then((r: Factoring.Mensaje.Respuesta.Operacion.Solicitar) => {
          this.setState((state) => ({ enviandoSolicitud: false }));
          if (r.exito) {
            this.props.vaciarDocumentos();
            this.setState((state) => ({ solicitarModal: false }));
            if (r.todosAceptados) {
              this.props.notificacion(true, 'Solicitud enviada satisfactoriamente');
              this.props.history.push(routes.miTablero);
            } else {
              if (r.documentosNoAceptados.length > 0) {
                this.setState((state: IState) => ({ errorSolicitarModal: true, documentosRetirados: r.documentosNoAceptados }));
              }
              if (r.documentosConError.length > 0) {
                this.props.notificacion(true, r.mensaje);
              }
            }
          } else {
            this.props.notificacion(true, r.mensaje, 12000);
          }
        });
  };

  handleErrorSolicitarClose = () => {
    this.setState({
      errorSolicitarModal: false
    });
    this.resetDocumentos();
  };

  handleSolicitarClose = () => {
    this.setState({
      solicitarModal: false
    });
  };

  handleToggleAutomaticXml = () => {
    this.props.cargarDatosEmpresaUsuario();
    this.setState((state: IState) => ({ uploadAutomaticaXml: !state.uploadAutomaticaXml }));
  };

  handleToggleManualXml = () => {
    this.setState((state: IState) => ({ uploadManualXml: !state.uploadManualXml }));
  };

  // submitManualXml = () => {
  //   this.setState({
  //     uploadManualXml: false,
  //     termsModal: false
  //   });
  // };

  render() {
    const { classes, documentos, pagination, seleccionados, esEjecutivo, empresa } = this.props;
    const {
      cargandoPagina,
      activeTab,
      rutDeudor,
      folioFactura,
      openFacturasSeleccionadas,
      solicitarModal,
      uploadAutomaticaXml,
      uploadManualXml,
      errorSolicitarModal,
      documentosRetirados,
      enviandoSolicitud
    } = this.state;

    const montoSeleccionado = seleccionados.reduce((acc, cur) => acc + cur.monto, 0);

    return (
      <div className='container'>
        {cargandoPagina ? (
          <Loader />
        ) : (
          <div>
            <Header
              title='Solicitud de Negocios'
              breadcrum='Usted se encuentra en: Factoring / Operaciones / Solicitud'
            >
              {!empresa.sincronizacionDteCorrecta &&
                <Button
                  variant='contained'
                  onClick={this.handleToggleAutomaticXml}
                  className='btn-internal-header'
                  disabled={esEjecutivo}
                  color='primary'
                >
                  Carga Automática XML
                </Button>
              }
              <Button
                variant='outlined'
                className='btn-internal-header'
                color='primary'
                onClick={this.handleToggleManualXml}
              >
                Carga Manual XML
              </Button>
            </Header>
            <Grid container spacing={16}>
              <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                  <div className={classes.cardHeader}>
                    <span className={classes.cardHeaderTitle}>Filtros de Búsqueda</span>
                    <Button variant='outlined' color='primary' onClick={this.handleLimpiarFiltros}>
                      Limpiar
                    </Button>
                  </div>
                  <div className={classes.cardBody}>
                    <form className={classes.cardBodyForm}>
                      <FormControl>
                        <InputLabel htmlFor='rutDeudor'>RUT Deudor</InputLabel>
                        <Input
                          name='rutDeudor'
                          value={rutDeudor}
                          onChange={this.onChange}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='Clear'
                                onClick={() => this.handleClearInput('rutDeudor')}
                              >
                                <CloseIcon fontSize='small' />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <InputLabel htmlFor='folioFactura'>Folio</InputLabel>
                        <Input
                          name='folioFactura'
                          value={folioFactura}
                          onChange={this.onChange}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='Clear'
                                onClick={() => this.handleClearInput('folioFactura')}
                              >
                                <CloseIcon fontSize='small' />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        <FormHelperText id='mi-texto-de-ayuda'>
                          Seleccionar multiples Folios
                        </FormHelperText>
                      </FormControl>
                      <Button variant='outlined'
                              onClick={() => this.resetDocumentos()}
                              color='primary'>
                        Buscar
                      </Button>
                    </form>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                  <div className={classes.cardHeader}>
                    <span className={classes.cardHeaderTitleBold}>Solicitud Curse</span>
                  </div>
                  <div className={classes.cardBodyCurse}>
                    <form className={classes.cardBodyForm}>
                      <TextField
                        id='cantidadFacturas'
                        label='Cantidad'
                        value={seleccionados.length}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <TextField
                        id='montoFacturas'
                        label='Monto'
                        value={'$' + formatNumber(montoSeleccionado)}
                        InputProps={{
                          readOnly: true
                        }}
                      />
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={this.openFacturasSeleccionadas}
                        disabled={seleccionados.length <= 0}
                      >
                        Ver Seleccion
                      </Button>
                    </form>
                  </div>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <div className='tabs'>
                  <Tabs value={activeTab} classes={{ indicator: classes.indicator }}>
                    <Tab label='FACTURAS DISPONIBLES' classes={{ root: classes.tabFactotal }} />
                    <Tab
                      label='ACTUALIZAR'
                      icon={<RefreshIcon className={classes.tabFactotalIcon} />}
                      onClick={this.actualizarDocumentos}
                      classes={{
                        root: classes.tabFactotal,
                        wrapper: classes.tabFactotalIconWrapper,
                        labelContainer: classes.tabFactotalIconLabel
                      }}
                    />
                  </Tabs>
                  <div>
                    <TableContainer classes={classes}
                                    data={documentos}
                                    pagination={pagination}
                                    tableInfo={tableInfo(classes, this.handleCheckFactura, this.isChecked, this.handleDateChange, esEjecutivo)}
                                    obtenerDatos={this.obtenerDocumentos} />

                  </div>
                </div>
              </Grid>
            </Grid>
            <FacturasSeleccionadas
              open={openFacturasSeleccionadas}
              onClose={this.closeFacturasSeleccionadas}
              facturas={seleccionados}
              totalFacturas={montoSeleccionado}
              onDelete={this.deleteSelected}
              onSolicitar={this.handleSolicitar}
              onDeleteAll={this.deseleccionarTodos}
            />
            <SolicitarConfirm open={solicitarModal}
                              onAceptar={this.handleAceptarSolicitud}
                              enviandoSolicitud={enviandoSolicitud}
                              onClose={this.handleSolicitarClose} />
            <ErrorEnSolicitudOperacion open={errorSolicitarModal}
                                       onClose={this.handleErrorSolicitarClose}
                                       documentos={documentosRetirados} />
            <CargaAutomaticaXml
              open={uploadAutomaticaXml}
              onClose={this.handleToggleAutomaticXml}
            />
            <CargaManualXml
              open={uploadManualXml}
              onClose={this.handleToggleManualXml}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  documentos: state.operacionesSolicitud.rows,
  seleccionados: state.operacionesSolicitud.selected,
  pagination: state.operacionesSolicitud.pagination,
  empresa: state.factorx.empresa,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>): DispatchProps => ({
  limpiarEstado: () => dispatch(limpiarSimulacion()),
  clearFilters: () => dispatch(resetFiltrosDocumentos()),
  obtenerDocumentos: (filters: Filters.Documentos) => dispatch(obtenerDocumentos(filters)),
  solicitarOperacion: (payload: Payloads.SolicitarOperacionPayload) => dispatch(solicitarOperacion(payload)),
  actualizarFechaVencimiento: (payload: Payloads.ActualizarFechaVencimientoPayload) => dispatch(actualizarFechaVencimiento(payload)),
  seleccionarDocumento: (payload: Comun.Dto.Invoice) => dispatch(seleccionarDocumento(payload)),
  quitarDocumento: (payload: Comun.Dto.Invoice) => dispatch(quitarDocumento(payload)),
  vaciarDocumentos: () => dispatch(vaciarDocumentos()),
  notificacion: (exito: boolean, mensaje: string, timeout?: number) => dispatch(actions.openSnackBar(exito, mensaje, timeout)),
  cargarDatosEmpresaUsuario: () => dispatch(fxActions.cargarDatosEmpresaUsuario())
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(OperacionSolicitud));

import { Button, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { FC } from 'react';
import UploadIcon from '../../../images/icons/upload_icon_24px.svg';

const style = (theme : Theme) => ({
  /** custom upload button */
  rootUploadButton: {
    height: '48px',
    marginBottom: '16px'
  },
  buttonUpload: {
    padding: '13px 18px',
    background: '#F8F8F8',
    width: '100%'
  },
  buttonUploadLabel: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#414141',
    justifyContent: 'space-between' as 'space-between'
  },
  inputRoot: {
    display: 'none'
  }
});

interface IProps extends WithStyles<typeof style> {
  onupload : (file : File | undefined) => void;
  label : string;
  value : File | undefined;
  id : string,
  accept? : string
}

const UploadDocumentoInput : FC<IProps> = (props) => {
  const { classes, onupload, label, value, id, accept } = props;

  const nombreArchivo = value === undefined ? '' : ', ' + value.name;

  const onChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    const file = (e.target.files && e.target.files[0]);
    if (file) {
      onupload(file || undefined);
    }
  };

  return (
    <div className={classes.rootUploadButton}>
      <input
        accept={accept || '*'}
        className={classes.inputRoot}
        id={'contained-button-file-' + id}
        type='file'
        onChange={onChange}
      />
      <label htmlFor={'contained-button-file-' + id}>
        <Button
          id={id}
          name={id}
          variant='contained'
          classes={{ root: classes.buttonUpload, label: classes.buttonUploadLabel }}
          component='span'
        >
          {label} {nombreArchivo}<img src={UploadIcon} alt='' />
        </Button>
      </label>
    </div>
  );
};

export default withStyles(style)(UploadDocumentoInput);

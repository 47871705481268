import vendorApi from '../../services/vendorApi';
import { asyncActionType } from '../../helpers/ActionsHelper';
import { Dispatch } from 'redux';
import { ActionT } from '../../config/rootReducer';

export interface IFetchBienesSuccess {
  bienes : Dto.Vendor.BienesRow[],
  paginacion : Dto.Paginacion
}

export interface IFetchContratosSuccess {
  contratos : Dto.Vendor.ContratosVigentesRow[],
  paginacion : Dto.Paginacion
}

export interface IFetchPending {
  tamanoPagina : number
}

export const fetchBienesAction = asyncActionType('@@BIENES_VENDOR', 'FETCH');

const _obtenerBienesVendorThunk = (filters : Request.Vendor.Bienes) => (dispatch : Dispatch) : Promise<void> => {
  dispatch(obtenerBienesVendor.request(filters));
  return vendorApi.getBienes(filters)
                  .then((response) => dispatch(obtenerBienesVendor.succeed(response)))
                  .catch(error => error);
};

export const obtenerBienesVendor = Object.assign(_obtenerBienesVendorThunk, {
  request: (request : Request.Vendor.Bienes) : ActionT<IFetchPending> => ({
    type: fetchBienesAction.PENDING,
    payload: {
      tamanoPagina: request.tamanoPagina
    }
  }),
  succeed: (response : Response.Vendor.Bienes) : ActionT<IFetchBienesSuccess> => ({
    type: fetchBienesAction.SUCCESS,
    payload: {
      bienes: response.data,
      paginacion: {
        ...response.paginacion,
        numeroPagina: response.paginacion.numeroPagina - 1
      }
    }
  })
});

export const fetchContratosAction = asyncActionType('@@CONTRATO_VENDOR', 'FETCH');

const _obtenerContratosVendorThunk = (filters : Request.Vendor.ContratosVigentes) => (dispatch : Dispatch) : Promise<void> => {
  dispatch(obtenerContratosVendor.request(filters));
  return vendorApi.getContratos(filters)
                  .then((response) => dispatch(obtenerContratosVendor.succeed(response)))
                  .catch(error => error);
};

export const obtenerContratosVendor = Object.assign(_obtenerContratosVendorThunk, {
  request: (request : Request.Vendor.ContratosVigentes) : ActionT<IFetchPending> => ({
    type: fetchContratosAction.PENDING,
    payload: {
      tamanoPagina: request.tamanoPagina
    }
  }),
  succeed: (response : Response.Vendor.ContratosVigentes) : ActionT<IFetchContratosSuccess> => ({
    type: fetchContratosAction.SUCCESS,
    payload: {
      contratos: response.data,
      paginacion: {
        ...response.paginacion,
        numeroPagina: response.paginacion.numeroPagina - 1,
        totalPagina: response.paginacion.totalPagina
      }
    }
  })
});


import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { validaRut } from '../../helpers/RutHelper';
import { SendMailToExecutive } from './actions';
import ModalComponent from '../shared/ModalMsg/ModalMsg';
import Loader from '../shared/Loader/Loader';
import { ISendMailToExecutiveAtencionClienteApiPayload } from '../../services/factoringApi';
import { AppState } from '../../config/rootReducer';

interface IProps {
  dispatch: any;
}

interface IState {
  user: Comun.Dto.LoginUserInfo;
  enterpriseRut: string;
  enterpriseName: string;
  contactName: string;
  phone: string;
  comments: string;
  errorGenerated: boolean;
  loading: boolean;
  rutErrorMessage: string;
  openModal: boolean;
}

class AtencionClienteForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
    this.state = {
      user: user,
      enterpriseRut: '',
      enterpriseName: '',
      contactName: '',
      phone: '',
      comments: '',
      errorGenerated: false,
      loading: false,
      rutErrorMessage: '',
      openModal: false
    };
  }

  componentDidMount() {
    const { user } = this.state;
    this.setState({
      enterpriseRut: user.empresas[0].rut.toString() + '-' + user.empresas[0].dv,
      enterpriseName: user.empresas[0].socialReason
    });

  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    switch (e.target.id) {

      case 'enterpriseRut':
        this.setState({ enterpriseRut: e.target.value });
        break;
      case 'enterpriseName':
        this.setState({ enterpriseName: e.target.value });
        break;
      case 'contactName':
        this.setState({ contactName: e.target.value });
        break;
      case 'contactPhone':
        this.setState({ phone: e.target.value });
        break;
      case 'comments':
        this.setState({ comments: e.target.value });
        break;
      default:
        break;
    }
  };

  SendMailSolicitud = () => {

    let _ValidRut = validaRut(this.state.enterpriseRut);
    if (this.state.enterpriseRut.trim() === '') {
      this.setState({ rutErrorMessage: 'El RUT es requerido' });
      return false;
    } else if (!_ValidRut) {
      this.setState({ rutErrorMessage: 'El Rut no es válido' });
      return false;
    }
    this.setState({
      errorGenerated: false,
      loading: true,
      rutErrorMessage: ''
    });
    const params: ISendMailToExecutiveAtencionClienteApiPayload = {
      rut: this.state.enterpriseRut,
      name: this.state.enterpriseName,
      contactName: this.state.contactName,
      phone: this.state.phone,
      comments: this.state.comments
    };
    return this.props.dispatch((dispatch: any) => Promise.all([dispatch(SendMailToExecutive(params))]))
               .then((data: any) => {
                 if (data && data[0] !== undefined) {
                   this.setState({
                     openModal: true,
                     loading: false
                   });
                 } else {
                   this.setState({ errorGenerated: true, loading: false });
                 }
               });
  };

  render() {
    const { openModal, errorGenerated, loading } = this.state;

    return (
      <div>
        {loading ? <Loader /> : ''}
        <ModalComponent
          openModal={openModal}
          title='¡Correo enviado con éxito!'
          text=''
          closeModalMsg={() => {
            this.setState({ openModal: false });
          }} />
        {errorGenerated ?
          <ModalComponent
            openModal={errorGenerated}
            title='Ha ocurrido un error'
            text='Estimado Cliente, intentar mas tarde. Si el problema persisite, porfavor comunicarse con equipo Factotal.'
            closeModalMsg={() => {
              this.setState({ errorGenerated: false });
            }} /> : ''}
        <p>Formulario Nueva Solicitud</p>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant='h2' color='primary' style={{ marginBottom: 10/*, marginLeft: "8%"*/ }}>
              <b>Ingrese su Solicitud</b>
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControl /*className={classes.filterFormButtonBody}*/>
              <Typography variant='caption' style={{ marginRight: 14 }}>
                <b>Rut Empresa: </b>
              </Typography>
              <FormHelperText id='component-error-text' error />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id='enterpriseRut'
              placeholder='12345678-9'
              style={{ width: '140%' }}
              // onChange={this.handleChange}
              value={this.state.enterpriseRut}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true
              }}
            />
            <FormHelperText id='component-error-text' error>{this.state.rutErrorMessage}</FormHelperText>
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={2}>
            <FormControl>
              <Typography variant='caption' style={{ marginRight: 14 }}>
                <b>Nombre Empresa: </b>
              </Typography>
              <FormHelperText id='component-error-text' error />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <TextField
              id='enterpriseName'
              placeholder='Nombre Empresa'
              style={{ width: '140%' }}
              value={this.state.enterpriseName}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true
              }}
            />
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={12}>
            <div className='col-8'>
              <Typography variant='h2' color='primary' style={{ marginBottom: 10/*, marginLeft: "8%"*/ }}>
                <b>Datos Contacto</b>
              </Typography>
            </div>

          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <Typography variant='caption' style={{ marginRight: 14 }}>
                <b>Nombre: </b>
              </Typography>
              <FormHelperText id='component-error-text' error />
            </FormControl>
          </Grid>
          <Grid item xs={5}>

            <TextField
              id='contactName'
              placeholder='Nombre'
              style={{ width: '140%' }}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={2}>
            <FormControl /*className={classes.filterFormButtonBody}*/>
              <Typography variant='caption' style={{ marginRight: 14 }}>
                <b>Fono: </b>
              </Typography>
              <FormHelperText id='component-error-text' error />
            </FormControl>
          </Grid>
          <Grid item xs={5}>

            <TextField
              id='contactPhone'
              placeholder='987015562'
              style={{ width: '140%' }}
              onChange={this.handleChange}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={2}>
            <FormControl /*className={classes.filterFormButtonBody}*/>
              <Typography variant='caption' style={{ marginRight: 14 }}>
                <b>Comentario: </b>
              </Typography>
              <FormHelperText id='component-error-text' error />
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            {/* <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Minimum 3 rows" /> */}
            <TextField
              id='comments'
              placeholder='Comentarios...'
              multiline
              style={{ width: '140%' }}
              inputProps={{ maxLength: 300 }}
              InputLabelProps={{ shrink: true }}
              onChange={this.handleChange}

            />
          </Grid>
          <Grid item xs={5} />
          <Grid item xs={6}>
            <FormControl /*className={classes.filterFormButtonBody}*/>
              <Button
                variant='outlined'
                color='primary'
                onClick={this.SendMailSolicitud}
              >
                Enviar
              </Button>
              <FormHelperText id='component-error-text' error />
            </FormControl>
          </Grid>

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  isLoading: state.main.isLoading
});

export default connect(mapStateToProps)(AtencionClienteForm);

const bannerStyle = (theme) => ({
  banner: {
    width: '100%',
    height: '60px',
    padding: '11px 63px 9px 26px',
    boxSizing: 'border-box',
    background: 'rgba(130, 188, 0, 0.1)',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& svg': {
      marginRight: '17px'
    },
    '& span': {
      margin: '0px',
      fontSize: '14px',
      color: '#6F777B',
      fontStyle: 'normal',
      fontWeight: '500',
    }
  },
  icon: {
    color: '#82BC00'
  }
});

export { bannerStyle };

import excedentesHistoricoState from "./state";
import { fetchAction } from "./actions";

export const reducer = (state = excedentesHistoricoState, action) => {
    switch (action.type) {
        case fetchAction.PENDING:
            {
                return Object.assign({}, state, action);
            }

        case fetchAction.SUCCESS:
            {
                return Object.assign({}, state, action);
            }

        default:
            return state;
    }
}

export default reducer;
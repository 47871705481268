import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SAcceptYieldModal } from '../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { TextField, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import factorxApi from '../../../services/factorxApi';
import actions from '../../main/reducer';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '../DialogTitle/DialogTitle';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  sincronizacion?: Core.Dto.Sincronizacion;
}

interface StateProps {
  empresa: Dto.Portal.Empresa;
}

interface DispatchProps {
  notificacion: (exito: boolean, mensaje: string) => void;
}

interface IProps extends OwnProps, StateProps, DispatchProps, WithStyles<typeof SAcceptYieldModal> {

}

interface IState {
  terminosCondiciones: boolean,
  clave: string,
  errors: IState,
  termsModal: boolean,
  estaCargando: boolean
}

class ConfiguracionSincronizacionServicios extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      terminosCondiciones: false,
      clave: '',
      errors: {} as IState,
      termsModal: false,
      estaCargando: false
    };
  }


  componentDidMount(): void {
    this.setState({
      ...this.state,
      clave: ''
    });
  }

  handlePropertyChange = (name: string, value: any) => {
    const ns = {
      ...this.state,
      [name]: value
    };
    this.setState({
      ...ns,
      errors: this.evaluar(ns)
    });
  };

  changeTermsConditions = (checked: boolean) => {
    this.handlePropertyChange('terminosCondiciones', checked);
  };

  evaluar = (state: IState) => {
    let errors: any = {};

    if (!state.clave) {
      errors.clave = 'La clave es requerida';
    }
    if (!state.terminosCondiciones) {
      errors.terminosCondiciones = 'Debe aceptar los términos y condiciones';
    }
    return errors;
  };

  puedeGuardar = () => {
    return Object.getOwnPropertyNames(this.state.errors).length === 0;
  };

  handleUpdateSyncDetails = (field: string) => (ev: React.ChangeEvent<any>) => {
    let { value } = ev.target;
    this.handlePropertyChange(field, value);
  };

  handleGuardar = () => {
    if (this.props.sincronizacion) {
      const modelo: Payloads.EdicionSincronizacionServicioEmpresaPayload = {
        clave: this.state.clave
      };
      const tipo = this.props.sincronizacion.tipo;
      const api = tipo === 2 ?
        factorxApi.modificarRegistroSincronizacionPfi(modelo) :
        tipo === 3 ?
          factorxApi.modificarRegistroSincronizacionAec(modelo) :
          tipo === 4 ?
            factorxApi.modificarRegistroSincronizacionTgr(modelo) :
            tipo === 5 ?
              factorxApi.modificarRegistroSincronizacionCte(modelo) : undefined;
      if (api) {
        this.setState({ estaCargando: true });
        api.then(respuesta => {
          this.setState({ estaCargando: false });
          if (respuesta.exito) {
            this.props.notificacion(true, 'La información del registro de sincronización fue actualizada');
            this.props.onClose();
          } else {
            this.props.notificacion(false, respuesta.mensaje);
          }
        });
      }
    }
  };

  handlerTermsModal = () => {
    this.setState({
      termsModal: true
    });
  };

  handleCloseTerms = () => {
    this.setState({
      termsModal: false
    });
  };

  aceptarYCerrar = () => {
    this.changeTermsConditions(true);
    this.handleCloseTerms();
  };

  render() {
    const { classes, onClose, open, sincronizacion } = this.props;
    const paperClass = [classes.dialogPaper, classes.uploadXml, classes.dialogPapersOr].join(' ');
    return (
      sincronizacion ? <Dialog
        onClose={onClose}
        className={classes.root}
        open={open}
        PaperProps={{ className: paperClass }}
      >
        <DialogTitle onClose={onClose}>Servicio de {sincronizacion.tipoDesc}</DialogTitle>
        <DialogContent>
          <form>
            <Grid container className={classes.gridDialog}>
              {/*<Grid item xs={12}>*/}
              {/*  {this.state.estadoSincro === 0 && (*/}
              {/*    <Banner*/}
              {/*      text="Hemos detectado que usted no posee vinculado el certificado digital. Para*/}
              {/*      activar la carga*/}
              {/*      automática de documentos, debe cargar su certificado." type="danger" />*/}
              {/*  )}*/}
              {/*  {this.state.estadoSincro === 1 && (*/}
              {/*    <Banner*/}
              {/*      text="La sincronización está correctamente configurada"*/}
              {/*      type="success" />*/}
              {/*  )}*/}
              {/*  {this.state.estadoSincro === 2 && (*/}
              {/*    <Banner*/}
              {/*      text="La contraseña asociada a la fuente de origen de sus documentos cambió, por*/}
              {/*      favor ingresar la nueva contraseña para reactivar la sincronización" type="warning" />*/}
              {/*  )}*/}
              {/*</Grid>*/}
              <legend style={{ marginTop: 15 }}>Credenciales SII empresa</legend>
              <Grid container style={{ marginTop: 15 }}>
                <Grid item xs={12}>
                  {this.props.empresa.idEmpresaPais &&
                    <TextField
                      fullWidth
                      label='RUT empresa'
                      disabled={true}
                      value={this.props.empresa.idEmpresaPais.valor}
                    />
                  }
                </Grid>
                <Grid item xs={12} style={{ marginTop: 15 }}>
                  <TextField
                    fullWidth
                    label='Contraseña SII'
                    onChange={this.handleUpdateSyncDetails('clave')}
                    error={this.state.errors.clave !== undefined}
                    type='password'
                    placeholder='***********'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.gridDialog}
              justify='flex-start'
              style={{ marginTop: 15 }}
            >
              <Grid item xs={12}>
                <div className={classes.dialogChekbox}>
                  <Checkbox
                    color='primary'
                    checked={this.state.terminosCondiciones}
                    onChange={(event) => this.changeTermsConditions(event.target.checked)}
                    inputProps={{ 'aria-label': 'Selecionar' }}
                  />
                  <div className={classes.termsLink}>
                    Acepto los <a onClick={this.handlerTermsModal}>Términos y Condiciones</a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
          <TermsAndConditions open={this.state.termsModal} onClose={this.handleCloseTerms}
                              onAccept={this.aceptarYCerrar} />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='contained'
                  onClick={this.handleGuardar}
                  disabled={!this.puedeGuardar() || this.state.estaCargando}
                  color='primary'>
            {this.state.estaCargando ?
              <CircularProgress size={20} />
              : 'Autorizar'}
          </Button>
          <Button
            variant='outlined'
            color='primary'
            onClick={onClose}
            className={classes.cancelButtom}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog> : null
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  empresa: state.factorx.empresa
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  notificacion: (exito: boolean, mensaje: string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(SAcceptYieldModal)(connect(mapStateToProps, mapDispatchToProps)(ConfiguracionSincronizacionServicios));

import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import React, {FC, useState} from "react";
import contratosGeneralStyle from "../../contratosGeneralStyle";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import Collapse from '@material-ui/core/Collapse';
import {TextField} from "@material-ui/core";

interface IProps  extends WithStyles<typeof contratosGeneralStyle> {
  inscripcion?: Dto.RegistroCivil.Inscripcion;
}

const InformacionIscripcion: FC<IProps> = (props)=>{
  const {classes, inscripcion} = props;
  const [open, setOpen] = useState(false);

  return(<div className={classes.greySeccion}>
    <div className={classes.seccionHeader}>
      <div className={classes.leftSeccionHeader}>
        <div className={classes.titleSeccionHeader}>Información de la Inscripción</div>
        <div className={classes.subtSeccionHeader}>
          Información relacionada a la inscripción entregada por el Registro Civil
        </div>
      </div>
      <div className={classes.openButton} onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</div>
    </div>
    <Collapse in={open} timeout='auto' unmountOnExit>
      <div className={[classes.containerInputToRight].join(' ')}>

        <div className={classes.inputContainer}>
          <div className={classes.labelnputField}>Oficina</div>
          <TextField
            value={inscripcion?.nombreOficina}
            className={[classes.textField].join(' ')}
            margin='normal'
            disabled={true}
            variant='outlined'
            placeholder='MITSUBISHI'
          />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.labelnputField}>PPU</div>
          <TextField
            value={inscripcion?.ppu}
            className={[classes.textField, classes.midleInput].join(' ')}
            margin='normal'
            disabled={true}
            variant='outlined'
            placeholder='IMPREZA'
          />
        </div>
        <div className={classes.inputContainer}>
          <div className={classes.labelnputField}>Número Solicitud</div>
          <TextField
            value={inscripcion?.numeroSolicitud}
            className={[classes.textField, classes.midleInput].join(' ')}
            margin='normal'
            disabled={true}
            variant='outlined'
            placeholder='IMPREZA'
          />
        </div>
      </div>
    </Collapse>
  </div>)
}
export default withStyles(contratosGeneralStyle)(InformacionIscripcion);
import { Theme } from '@material-ui/core';

const sinCertificadoStyle = (theme : Theme) => ({
  noDocumentContainer: {
    padding: '8px 0px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    '& .info': {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        marginLeft: '16px',
        paddingRight: '57px'
      }
    }
  }
});

export { sinCertificadoStyle };

import React, { FC } from 'react';
import cx from 'classnames';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Collapse, List, ListItem } from '@material-ui/core';
import { useHistory } from 'react-router';
import routes from '../../../config/routes';
import IcoMisdatos from '../../../images/icons/ico-misdatos.svg';
import IcoMiscertificados from '../../../images/icons/ico-miscertificados.svg';
import IcoTutoriales from '../../../images/icons/ico-tutoriales.svg';
import IcoatencionCliente from '../../../images/icons/ico-atencion-cliente.svg';
import IcoSubcripciones from '../../../images/icons/ico-subscripciones.svg';
import { menuSidebarStyle } from './menuSidebarStyle';
import FatherMenuItem from './FatherMenuItem';

interface IMenuUser extends WithStyles<typeof menuSidebarStyle> {
  miniActive: boolean;
  toogleCollapse: (menu: string, fatherCollapse: string) => any;
  isCollapse: string;
  trackingSidebar: (param: string) => any;
  activeRoute: (router: string) => any;
  checkCollapse: (collapse: string[]) => any;
  empresa: Dto.EnterpriseProfile;
}

const MenuUser: FC<IMenuUser> = (props) => {
  const history = useHistory();
  const {
    classes,
    miniActive,
    isCollapse,
    trackingSidebar,
    toogleCollapse,
    activeRoute,
    checkCollapse,
    empresa
  } = props;
  const fatherCollapse = 'menuUser';

  const goTo = (to: any, track: string) => {
    trackingSidebar(track);
    history.push(to);
  };

  const handlerToggleCollapse = (collapse: string) => {
    toogleCollapse(collapse, fatherCollapse);
  };

  return (
    <ListItem classes={{ root: classes.listItemContainer }}>
      <FatherMenuItem
        title={empresa.socialReason ? empresa.socialReason : ''}
        miniActive={miniActive}
        openWhen={['menuUser']}
        click={() => handlerToggleCollapse('menuUser')}
        collapse={isCollapse}
        fatherCollapse={fatherCollapse}
        version={'white'}
      />
      <Collapse
        classes={{
          container: classes.collapseContainer,
          entered: cx(classes.collapseEntered, 'white')
        }}
        in={checkCollapse(['menuUser'])}
        unmountOnExit
      >
        <List>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.user.misDatos, 'misDatos')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoMisdatos} />
              <span
                className={cx({
                  activeLink: activeRoute(routes.user.misDatos)
                })}
              >
                Mis datos
              </span>
            </div>
          </ListItem>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.user.certificados, 'certificados')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoMiscertificados} />
              <span
                className={cx({
                  activeLink: activeRoute(routes.user.certificados)
                })}
              >
                Certificados
              </span>
            </div>
          </ListItem>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.user.atencionCliente, 'atencionCliente')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoatencionCliente} />
              <span
                className={cx({
                  activeLink: activeRoute(routes.user.atencionCliente)
                })}
              >
                Atención al Cliente
              </span>
            </div>
          </ListItem>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.user.videoTutoriales, 'videoTutoriales')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoTutoriales} />
              <span
                className={cx({
                  activeLink: activeRoute(routes.user.videoTutoriales)
                })}
              >
                Tutoriales
              </span>
            </div>
          </ListItem>
          <ListItem
            className={classes.menuNavLink}
            onClick={() => goTo(routes.user.subscripciones, 'subscripciones')}
          >
            <div className={classes.linkTextContainer}>
              <img src={IcoSubcripciones} />
              <span
                className={cx({
                  activeLink: activeRoute(routes.user.subscripciones)
                })}
              >
                Subscripciones
              </span>
            </div>
          </ListItem>
        </List>
      </Collapse>
    </ListItem>
  );
};

export default withStyles(menuSidebarStyle)(MenuUser);

import FormHelperText from "@material-ui/core/FormHelperText"
import * as React from "react"

const FormErrorText = ({children}) => <FormHelperText
    error
    style={{
        fontSize: 14,
        fontWeight: 500,
        marginTop: 18
    }}
>* {children}</FormHelperText>

export default FormErrorText
import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Theme, WithStyles, withStyles } from '@material-ui/core';
import scssTool from '../../../styles/scssTool';
import AgregarCertificadoModal from '../../general/fxEmpresa/Certificados/AgregarCertificado';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CargaAutomaticaXml from '../../sharedv2/ConfiguracionSincronizacion/ConfiguracionSincronizacionDte';
import factorxApi from '../../../services/factorxApi';

const styleComparteDatos = (theme: Theme) => ({
  containerAgilizaOperaciones: {
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    padding: '0px 16px',
    border: '1px solid #82BC00',
    boxSizing: 'border-box' as 'border-box',
    ...scssTool.flex('center', 'row', 'space-between'),
    width: '100%',
    height: '100px'
  },
  columnContainer: {
    ...scssTool.flex('flex-start', 'column', 'center'),
    height: '100%'
  },
  agilizaT: {
    ...scssTool.font(16, 'bold', 17),
    color: '#000000',
    fontFamily: scssTool.family.lato,
    marginBottom: '8px',
    paddingRight: '16px'
  },
  buttonFtc: {
    width: '234px',
    height: '43px',
    background: '#94C11F',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '0px',
    '&:hover': {
      background: '#94C11F'
    }
  },
  buttonFtcLabel: {
    ...scssTool.font(14, 'bold', 16),
    color: '#ffffff',
    fontFamily: scssTool.family.lato
  },
  icoBtnFtc: {
    marginRight: '5px'
  },
  containerBtnFtc: {
    ...scssTool.flex('center', 'row', 'center'),
    padding: '40px',
    width: '50%'
  },
  checkBoxContainer: {
    ...scssTool.flex('center', 'row', 'flex-start'),
    ...scssTool.font(12, 600, 24),
    color: 'rgba(67, 67, 67, 0.6)',
    fontFamily: scssTool.family.lato
  }
});

interface IProps extends WithStyles<typeof styleComparteDatos>, RouteComponentProps {
  deplegarOpcionSuscripcionMiPyme: boolean;
  suscripcionYaConfigurada: boolean;
  certificadoYaConfigurado: boolean;
  notificacion: (exito: boolean, mensaje: string) => void;
}

const AgilizaOperaciones: FC<IProps> = (props) => {
  const { classes, deplegarOpcionSuscripcionMiPyme, suscripcionYaConfigurada } = props;
  const [open, setOpen] = useState(false);
  const [uploadAutomaticaXml, setUploadAutomaticaXml] = useState(false);
  const [checkedF, setCheckedF] = useState(false);

  const puedeConfigurarTodoUnClick = deplegarOpcionSuscripcionMiPyme && !suscripcionYaConfigurada;

  useEffect(() => {
    setCheckedF((puedeConfigurarTodoUnClick));
  }, [puedeConfigurarTodoUnClick]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleChange = () => {
    setCheckedF(!checkedF);
  };

  const handleOnSave = async (certificado: File, clave: string) => {
    if (puedeConfigurarTodoUnClick && checkedF) {
      const modelo: Payloads.EdicionSincronizacionDteEmpresaPayload = {
        clave: clave,
        file: certificado,
        proveedor: 'mipyme',
        usuario: ''
      };
      const respuesta = await factorxApi.modificarRegistroSincronizacionDte(modelo);
      if (respuesta.exito) {
        props.notificacion(true, 'La información del registro de conexión al facturador fue actualizada');
        // props.onClose();
      } else {
        props.notificacion(false, respuesta.mensaje);
      }
    }
  };

  // console.log('SUS,SUSCONF,checkedF,puedeConfigurarTodoUnClick', deplegarOpcionSuscripcionMiPyme, suscripcionYaConfigurada, checkedF, puedeConfigurarTodoUnClick);

  return (
    <div>
      <div className={classes.containerAgilizaOperaciones}>
        <div className={classes.columnContainer}>
          <div className={classes.agilizaT}>
            Agiliza tus operaciones cediendo a través de Factotal.cl
          </div>
          <div className={classes.checkBoxContainer}>
            {puedeConfigurarTodoUnClick &&
            <>
              <Checkbox
                color='primary'
                checked={checkedF}
                onChange={handleChange}
                value='checkedF'
              />
              <span>Sincronizar facturas y XML</span>
            </>
            }
          </div>
        </div>
        <Button
          onClick={() => setOpen(true)}
          classes={{ root: classes.buttonFtc, label: classes.buttonFtcLabel }}
        >
          Carga tu certificado digital
        </Button>
      </div>
      <AgregarCertificadoModal
        open={open}
        onClose={() => setOpen(false)}
        onSave={handleOnSave}
      />
      <CargaAutomaticaXml
        open={uploadAutomaticaXml}
        onClose={() => setUploadAutomaticaXml(false)}
      />
    </div>
  );
};

export default withRouter(withStyles(styleComparteDatos)(AgilizaOperaciones));

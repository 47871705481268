import React, { FC } from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import IcoGreyPerson from '../../../images/ico-grey-person.svg';
import IcoGreyEmail from '../../../images/ico-grey-email.svg';
import IcoGreyLocation from '../../../images/ico-grey-location.svg';
import IcoGreyPhone from '../../../images/ico-grey-phone.svg';
import scssTool from '../../../styles/scssTool';

const styleFactoring = (theme: Theme) => ({
  ejecutivoDataContainer: {
    ...scssTool.flex('flex-start', 'column', 'flex-end'),
    padding: '16px',
    flexGrow: 1,
    marginTop: '18px'
  },
  ejecutivoDataTitle: {
    ...scssTool.font(16, 400, 16),
    color: '#434343',
    fontFamily: scssTool.family.lato,
    textTransform: 'uppercase' as 'uppercase',
    marginBottom: '18px'
  },
  ejecutivoDataSubTitle: {
    ...scssTool.font(14, 400, 24),
    color: '#434343',
    fontFamily: scssTool.family.lato,
    ...scssTool.flex('center', 'row', 'center'),
    marginBottom: '11px',
    '& img': {
      marginRight: '16px'
    }
  }
});


interface IEjecutivoContainer extends WithStyles<typeof styleFactoring> {
  titulo: string;
  nombre: string;
  email: string;
  telefono: string;
  direccion: string;
}

const EjecutivoContainer: FC<IEjecutivoContainer> = (props) => {
  const { classes, nombre, email, telefono, direccion, titulo } = props;

  return (
    <div className={classes.ejecutivoDataContainer}>
      <div className={classes.ejecutivoDataTitle}>{titulo}</div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoGreyPerson} alt='' />
        {nombre}
      </div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoGreyEmail} alt='' />
        {email}
      </div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoGreyPhone} alt='' />
        {telefono}
      </div>
      <div className={classes.ejecutivoDataSubTitle}>
        <img src={IcoGreyLocation} alt='' />
        {direccion || 'Andrés Bello 2233, piso 7, Providencia, Santiago'}
      </div>
    </div>
  );
};

export default withStyles(styleFactoring)(EjecutivoContainer);

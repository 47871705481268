import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useState } from 'react';
import MoneyIcon from '../../../../images/icons/monetization_on_24px.svg';
import ArrowIcon from '../../../../images/icons/icons-bienes/arrow_right_24px_outlined.svg';
import dialogStyle from '../../../../styles/jss/components/dialogStyle';
import clsx from 'clsx';

interface IProps extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  open: boolean;
}

const ImpuestoTransferenciaModal: FC<IProps> = (props) => {
  const { classes, onClose, open } = props;
  const [result, setResult] = useState(false);

  useEffect(() => {

  }, [result]);
  
  const handleBottom = () => {
    console.log('handleBottom');
    if (!result) {
      setResult(true);
    } else {
      // onGrabar();
    }
  };

  return (
    <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: classes.dialogPaperGreyVariant }}>
      <div className={[classes.containerGreyVariant, classes.heightAuto].join(' ')}>
        <div className={classes.floatingHeaderIcon}>
          <img src={MoneyIcon} alt='' />
        </div>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}>
          {!result && (
            <React.Fragment>
              <div className={classes.NewtitleModal}>Impuesto transferencia</div>
              <div className={classes.subtitleModal}>Ingrese la infromación del impuesto de transferencia</div>
              <div className={classes.containerSeccion}>
                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Código CID (Formulario 23)</div>
                  <TextField id='fecha' className={clsx(classes.textField, 'sinMargin')} margin='normal' variant='outlined' placeholder='123123123123123123123' />
                </div>

                <div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Monto pagado</div>
                  <TextField id='fecha' className={clsx(classes.midleWidthInput)} margin='normal' variant='outlined' placeholder='99.876.876-2' />
                </div>
              </div>
            </React.Fragment>
          )}
          {result && (
            <React.Fragment>
              <div className={classes.bigTitle}>Pago enviado</div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Fecha: 2018/05/14 Hora: 1558</div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Código CID 1231312312312 </div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Estado: Envío exitoso</div>
              </div>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='outlined' color='primary' className={classes.greenButton} onClick={handleBottom}>
            {!result ? 'Grabar' : 'Cerrar'}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(dialogStyle)(ImpuestoTransferenciaModal);

import axios from 'axios';
import config from '../config/api';
import { instance, userEnterpriseRut } from './factoringApi';
import { buildQueryString } from '../helpers/UrlHelper';
import { authHeader } from '../helpers/AuthHeader';
import { getBase64Promise } from '../helpers/Base64ToBlob';

const apiUserUrl = config.apiUri;

// export const saveSiniestro = (params: any) => {
//   axios.post(`${apiUserUrl}/siniestroSve`, params);
// };
//
// export const getSiniestro = (id: string) => {
//
//   let queryString = `ìd=${id}`;
//   return instance.get(`${apiUserUrl}/siniestro?${queryString}`);
// };

export const getResumenCliente = (): Promise<Dto.Vendor.ResumenCliente> => {
  return instance
    .get(`${apiUserUrl}vendor/${userEnterpriseRut()}/resumenCliente`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

const getBienes = (params: Request.Vendor.Bienes): Promise<Response.Vendor.Bienes> => {
  params.numeroPagina = params.numeroPagina ? params.numeroPagina : 1;
  params.tamanoPagina = params.tamanoPagina ? params.tamanoPagina : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}vendor/${userEnterpriseRut()}/bienes?${queryString}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

const getContratos = (params: Request.Vendor.ContratosVigentes): Promise<Response.Vendor.ContratosVigentes> => {
  params.numeroPagina = params.numeroPagina ? params.numeroPagina : 1;
  params.tamanoPagina = params.tamanoPagina ? params.tamanoPagina : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}vendor/${userEnterpriseRut()}/contratos?${queryString}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

const getCuotas = (params: { contrato: number }): Promise<Response.Vendor.Cuotas> => {
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}vendor/${userEnterpriseRut()}/cuotas?${queryString}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};


export const solicitudCertificado = (params: Request.Vendor.SolicitudCertificadoEjecutivo): Promise<boolean> =>
  instance
    .post(`${apiUserUrl}vendor/${userEnterpriseRut()}/solicitudCertificado`,
      params,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);


export const denunciarSiniestro = (payload: Request.Vendor.DenunciaSiniestro, cedula: File): Promise<boolean> => {
  const url = `${apiUserUrl}vendor/${userEnterpriseRut()}/denunciaSiniestro`;
  return getBase64Promise(cedula).then((resp: Payloads.ContenidoArchivo) => {
    payload.cedulaIdentidad = resp.content.split(',')[1];
    return instance.post(url, payload, { headers: authHeader() })
                   .catch(error => error)
                   .then(response => response.data);
  });
};

export default { getBienes, getContratos, getCuotas };

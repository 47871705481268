import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import dialogStyle from "../../../../styles/jss/components/dialogStyle";
import React, {FC, useEffect, useState} from "react";
import registroCivilApi from "../../../../services/registroCivilApi";
import {Dialog, DialogActions, DialogContent} from "@material-ui/core";
import clsx from "clsx";
import SearchBig from "../../../../images/icons/search_big.svg";
import RectangleContainer from "../../../sharedv2/RectangleContainer/RectangleContainer";
import HistorialTransferenciaTabla from './HistorialTransferenciaTabla';

interface IHistorialTransferenciaModal extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  open: boolean;
  transferencia?: Dto.RegistroCivil.Transferencia;
  empresa?: Dto.RegistroCivil.Empresa;
  contrato?: Dto.RegistroCivil.Contrato;
}

const HistorialTransferenciaModal: FC<IHistorialTransferenciaModal>= (props)=>{

  const { classes, onClose, open, transferencia, empresa, contrato } = props;

  const [historial, setHistorial]=useState<Dto.RegistroCivil.HistorialTransferencia[]>([]);

  useEffect(()=>{
    debugger;
    if(transferencia && empresa && contrato){
      let request: Request.RegistroCivil.ObtenerHistorialTransferencia={
        idTransferencia: transferencia.idTransferencia
      };
      registroCivilApi.obtenerHistorialTransferencia(empresa.idEmpresa.toString(), contrato.idContrato.toString(), request)
        .then(resp=>{
          if( resp && resp.success){
            resp.historial.map(hist=>{
              setHistorial(historial=>[...historial, hist])
            })
          }
        });
    }
  },[]);
  return (
    <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: clsx(classes.dialogPaperGreyVariant, 'responsive') }}>
      <div className={[classes.containerGreyVariant, classes.heightAuto].join(' ')}>
        <div className={classes.floatingHeaderIcon}>
          <img src={SearchBig} alt='' />
        </div>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}>
          <div className={classes.NewtitleModal}>Historial</div>
          <div className={classes.subtitleModal}>Eventos asociados al bien</div>
          <RectangleContainer>
            <HistorialTransferenciaTabla historial={historial} />
          </RectangleContainer>
        </DialogContent>
        <DialogActions className={classes.dialogActions}></DialogActions>
      </div>
    </Dialog>
  );
};
export default withStyles(dialogStyle)(HistorialTransferenciaModal);
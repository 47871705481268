import factorxApi from '../../../../services/factorxApi';
import { asyncActionType } from '../../../../helpers/ActionsHelper';
import { uid } from 'uid';
import actions from '../../../main/reducer';
import { Dispatch } from 'redux';

export const fetchOperacionesAction = asyncActionType('@@OPERACIONESENPROCESO_FACTORING', 'FETCH');
export const fetchOperacionAction = asyncActionType('@@OPERACION_FACTORING', 'FETCH');
export const verificarPrefactibilidadAction = asyncActionType('@@VERIFICARPREFACTIBILIDAD_FACTORING', 'FETCH');
export const subirDocumentoAction = asyncActionType('@@DOCUMENTO', 'UPLOAD');
export const autorizarOperacionAction = asyncActionType('@@OPERACION', 'AUTHORIZE');
export const reintentoCesionDocumentosOperacionAction = asyncActionType('@@OPERACION', 'REINTENT');
export const eliminarAnexoDocumentoSimulacionAction = asyncActionType('@@DOCUMENTO', 'DELETE');

const obtenerOperacionesEnProcesoThunk = (filters : Filters.OperacionesProceso) => (dispatch : Dispatch) => {
  dispatch(obtenerOperacionesEnProceso.request(filters));
  return factorxApi.getOperacionesEnProceso(filters)
                   .then((response) => dispatch(obtenerOperacionesEnProceso.succeed(response)))
                   .catch(error => error);
};

const obtenerOperacionesEnProceso = Object.assign(obtenerOperacionesEnProcesoThunk, {
  request: (request : Filters.OperacionesProceso) => ({
    type: fetchOperacionesAction.PENDING,
    pagination: {
      pageSize: request.tamano
    }
  }),
  succeed: (response : Mensaje.ObtenerOperacionesBackOfficeRespuesta) => ({
    type: fetchOperacionesAction.SUCCESS,
    rows: response.exito ? response.operaciones : [],
    pagination: {
      pageSize: response.paginaTamano,
      page: response.paginaActual - 1,
      count: response.cantidadRegistros,
      maxPages: response.cantidadPaginas - 1,
      from: response.registroDesde,
      to: response.registroHasta
    }
  })
});


const obtenerOperacionThunk = (numeroOperacion : number, paraCeder : boolean) => (dispatch : Dispatch) : Promise<Mensaje.ObtenerOperacionBackOfficeRespuesta> => {
  dispatch(obtenerOperacion.request());
  return factorxApi.getOperacion(numeroOperacion, paraCeder)
                   .then((response) => {
                     dispatch(obtenerOperacion.succeed(response));
                     return response;
                   })
                   .catch(error => error);
};

const obtenerOperacion = Object.assign(obtenerOperacionThunk, {
  request: () => ({
    type: fetchOperacionAction.PENDING,
    simulacion: null
  }),
  succeed: (response : Mensaje.ObtenerOperacionBackOfficeRespuesta) => ({
    type: fetchOperacionAction.SUCCESS,
    simulacion: response.exito ? response.operacion : null
  })
});


const verificarPreFactibilidadDocumentoThunk = (idDocumento : string) => (dispatch : Dispatch) =>
  new Promise<Factoring.Mensaje.Respuesta.Documento.Factibilidad>(async function (resolve, reject) {
    dispatch(verificarPreFactibilidadDocumento.request(idDocumento));
    try {
      const respuesta = await factorxApi.verificarPreFactibilidadDocumento(idDocumento);
      if (respuesta.exito) {
        dispatch(verificarPreFactibilidadDocumento.succeed({
          id: idDocumento,
          resultado: respuesta.resultado,
          existeXml: respuesta.existeXml,
          mensaje: respuesta.mensaje
        }));
      } else {
        dispatch(verificarPreFactibilidadDocumento.succeed({
          resultado: false,
          existeXml: respuesta.existeXml,
          id: idDocumento,
          mensaje: respuesta.mensaje
        }));
      }
      resolve(respuesta);
    } catch (e) {
      dispatch(verificarPreFactibilidadDocumento.succeed({
        id: idDocumento,
        resultado: false,
        existeXml: false,
        mensaje: 'Error en la verificación'
      }));
    }

  });

const verificarPreFactibilidadDocumento = Object.assign(verificarPreFactibilidadDocumentoThunk, {
  request: (invoiceId : string) => ({
    type: verificarPrefactibilidadAction.PENDING,
    payload: invoiceId
  }),
  succeed: (payload : Payloads.ResultadoVerificarPrefactibilidadPayload) => ({
    type: verificarPrefactibilidadAction.SUCCESS,
    payload: payload
  })
});


const subirAnexoDocumentoThunk = (payload : Payloads.ArchivoSubidoPayload) => {
  const uploadingDocument = {
    attachmentId: uid(),
    file: payload.file,
    progress: 0,
    invoiceId: payload.invoiceId
  };
  return async (dispatch : Dispatch) => new Promise<Mensaje.AdjuntarArchivoDocumentoRespuesta>(function (resolve, reject) {
    // dispatch(subirAnexoDocumento.request(uploadingDocument));
    try {
      factorxApi.subirAnexoDocumento(payload.invoiceId, payload.file)
                .then((resp) => {
                  if (resp.exito) {
                    dispatch(subirAnexoDocumento.succeed(uploadingDocument));
                  } else {
                    // dispatch(subirAnexoDocumento.failure({
                    //   invoiceId: uploadingDocument.invoiceId,
                    //   attachmentId: uploadingDocument.attachmentId,
                    //   error: resp.mensaje
                    // }));
                  }
                  resolve(resp);
                });
    } catch (error) {
      // dispatch(subirAnexoDocumento.failure({
      //   invoiceId: uploadingDocument.invoiceId,
      //   attachmentId: uploadingDocument.attachmentId,
      //   error
      // }));
    }
  });
};

const subirAnexoDocumento = Object.assign(subirAnexoDocumentoThunk, {
  request: () => ({
    type: subirDocumentoAction.PENDING,
    payload: null
  }),
  succeed: (payload : Payloads.ArchivoSubidoPayload) => ({
    type: subirDocumentoAction.SUCCESS,
    payload: payload
  }),
  failure: () => ({
    type: subirDocumentoAction.FAILURE,
    payload: null
  })
});

const _eliminarAnexoDocumentoSimulacionThunk = (payload : Payloads.EliminarAnexoDocumentoSimulacionPayload) => {
  const uploadingDocument : Payloads.EliminarAnexoDocumentoSimulacionPayload = {
    attachmentId: payload.attachmentId,
    invoiceId: payload.invoiceId,
    nombre: payload.nombre
  };
  // console.log(uploadingDocument);
  return async (dispatch : Dispatch) => {
    const resp = await factorxApi.eliminarAnexoDocumento(payload.invoiceId, payload.nombre);
    if (resp.exito) {
      dispatch(eliminarAnexoDocumentoSimulacion.succeed(uploadingDocument));
      dispatch(actions.openSnackBar(true, resp.mensaje));
    } else {
      dispatch(actions.openSnackBar(false, resp.mensaje));
    }
  };
};

const eliminarAnexoDocumentoSimulacion = Object.assign(_eliminarAnexoDocumentoSimulacionThunk, {
  succeed: (payload : Payloads.EliminarAnexoDocumentoSimulacionPayload) => ({
    type: eliminarAnexoDocumentoSimulacionAction.SUCCESS,
    payload: payload
  })
});

const autorizarOperacionThunk = (payload : Payloads.AutorizarOperacionPayload) => (dispatch : Dispatch) : Promise<boolean> => {
  return factorxApi.autorizarOperacion(payload)
                   .then((result) => {
                     if (result.exito) {
                       dispatch(actions.openSnackBar(true, 'La operación ha sido enviada'));
                     } else {
                       dispatch(actions.openSnackBar(false, result.mensaje));
                     }
                     return result.exito;
                   })
                   .catch(error => error);

};

const autorizarOperacion = Object.assign(autorizarOperacionThunk, {
  // request: () => ({
  //   type: autorizarOperacionAction.PENDING
  // }),
  // succeed: (payload: any) => ({
  //   type: autorizarOperacionAction.SUCCESS,
  //   payload: payload
  // }),
  // failure: (payload: any) => ({
  //   type: autorizarOperacionAction.FAILURE,
  //   payload: payload
  // })
});


const _reintentoCesionDocumentosOperacionThunk = (payload : Payloads.ReintentoCesionDocumentosOperacionPayload) => (dispatch : Dispatch) : Promise<boolean> => {
  return factorxApi.reintentoCesionDocumentosOperacion(payload)
                   .then((result) => {
                     if (result.exito) {
                       dispatch(actions.openSnackBar(true, 'Los documentos han sido enviados'));
                     } else {
                       dispatch(actions.openSnackBar(false, result.mensaje));
                     }
                     return result.exito;
                   });

};

const reintentoCesionDocumentosOperacion = Object.assign(_reintentoCesionDocumentosOperacionThunk, {
  // request: () => ({
  //   type: reintentoCesionDocumentosOperacionAction.PENDING
  // }),
  // succeed: (payload : any) => ({
  //   type: reintentoCesionDocumentosOperacionAction.SUCCESS,
  //   payload: payload
  // }),
  // failure: (payload : any) => ({
  //   type: reintentoCesionDocumentosOperacionAction.FAILURE,
  //   payload: payload
  // })
});


export default {
  fetchOperacionesAction,
  fetchOperacionAction,
  verificarPrefactibilidadAction,
  autorizarOperacionAction,
  reintentoCesionDocumentosOperacionAction,
  obtenerOperacionesEnProceso,
  obtenerOperacion,
  verificarPreFactibilidadDocumento,
  subirAnexoDocumento,
  autorizarOperacion,
  reintentoCesionDocumentosOperacion,
  eliminarAnexoDocumentoSimulacion
};//, GetPDF, GetExcel


import { getHistoricoOperaciones, getHistoricoOperacionesIndividualPDF,eventTrackingToExcel, trackingSidebar } from '../../../../services/factoringApi';
import { asyncActionType } from '../../../../helpers/ActionsHelper';
import FileDownload from 'js-file-download';
import Moment from "moment/moment";

export const fetchAction = asyncActionType('@@HISTORICOOPERACIONES_FACTORING', 'FETCH')

export const fetchHistoricoOperacionesFactoring = (request) => ({
    type: fetchAction.PENDING,
    pageSize: request.pageSize
});

export const historicoOperacionesFactoringFetched = (response) => ({
    type: fetchAction.SUCCESS,
    rows: response.data,
    pagination: {
        currentPage: response.paginacion.numeroPagina,
        top: response.paginacion.top,
        totalItems: response.paginacion.totalRegistro,
        totalPages: response.paginacion.totalPagina,
        from: response.paginacion.registroDesde,
        to: response.paginacion.registroHasta
    }
});

export const docpdf = (response) => ({
    data: response
})

export const FilterOperacionesHistoricas = (params) => (dispatch) => {
    dispatch(fetchHistoricoOperacionesFactoring(params));
    return getHistoricoOperaciones(params)
        .then((response) =>  dispatch(historicoOperacionesFactoringFetched(response)))        
        .catch(error => error);
};

export const GetPDF = (params) => (dispatch) => {    
    params.isExportPdf = true;
    return getHistoricoOperaciones(params)
        .then(response => FileDownload(response, `Operaciones - Histórico ${Moment().format("YYYY-MM-DD-hh-mm")}` + ".pdf"))
        .catch(error => error);
}

export const GetExcel = (params) => (dispatch) => {    
    return getHistoricoOperaciones(params)
        .then(response => response)
        .catch(error => error);
}
export const EventTrackingExcel = (params) => (dispatch) => {    
    return eventTrackingToExcel(params)
        .then(response => response)
        .catch(error => error);
}
export const GetIndividualPDF = (params) => () => {    
    return getHistoricoOperacionesIndividualPDF(params)
        .then(response => response)
        .catch(error => error);
}
export const TrackingSidebar = (params) => () => {    
    return trackingSidebar(params)
        .then(response => response)
        .catch(error => error);
}

export default { TrackingSidebar, fetchAction, FilterOperacionesHistoricas, GetPDF, GetExcel };

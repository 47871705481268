import { dangerColor, defaultFont, drawerMiniWidth, drawerWidth, primaryBoxShadow, primaryColor, roseColor, successColor, warningColor } from '../generalStyle';

import FactotalTheme from '../../FactotalTheme';
import { Theme } from '@material-ui/core';
import scssTool from '../../scssTool';

const sidebarStyle = (theme: Theme) => ({
  drawerPaper: {
    border: 'none',
    position: 'fixed' as 'fixed',
    top: '0',
    bottom: '0',
    left: '0',
    zIndex: 1032,
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    textDecoration: 'none',
    // overflow: 'auto',
    boxShadow: '0',
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      position: 'fixed' as 'fixed',
      height: '100%'
    },
    nested: {},
    '&:before,&:after': {
      position: 'absolute' as 'absolute',
      zIndex: 3,
      width: '100%',
      height: '100%',
      display: 'block',
      top: '0',
      textDecoration: 'none'
    }
  },
  blackBackground: {
    color: '#FFFFFF',
    '&:after': {
      background: '#000',
      opacity: '.8'
    }
  },
  blueBackground: {
    color: '#FFFFFF',
    '&:after': {
      background: FactotalTheme.palette.grey.A400,
      opacity: '.93'
    }
  },
  whiteBackground: {
    color: '#3C4858',
    '&:after': {
      background: FactotalTheme.palette.grey.A400,
      opacity: '.93'
    }
  },
  whiteAfter: {
    '&:after': {
      backgroundColor: 'hsla(0,0%,71%,.3) !important'
    }
  },
  drawerPaperMini: {
    width: `${drawerMiniWidth}px!important`
  },
  logo: {
    padding: '10px 0px 8px',
    margin: '0',
    display: 'block',
    position: 'relative' as 'relative',
    zIndex: 4,
    backgroundColor: FactotalTheme.palette.primary.main,
    '&:after': {
      //content: "\"\"",
      position: 'absolute' as 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: FactotalTheme.palette.primary.main
    }
  },
  logoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    float: 'left' as 'left',
    textAlign: 'center' as 'center',
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '25px',
    marginRight: '13px',
    color: 'inherit'
  },
  logoNormal: {
    ...defaultFont,
    transition: 'all 300ms linear',
    display: 'block',
    opacity: '1',
    transform: 'translate3d(0px, 0, 0)',
    padding: '0px',
    marginTop: '21px',
    fontSize: '1.4rem', // Tamaño texto de "factotal" al costado del logo
    textDecoration: 'none', // Elimina el "underline"
    whiteSpace: 'nowrap' as 'nowrap',
    fontWeight: 300,
    lineHeight: '1.4rem',
    overflow: 'hidden',
    '&,&:hover,&:focus': {
      color: FactotalTheme.palette.primary.contrastText // Color texto asociado al logo
    }
  },
  logoNormalSidebarMini: {
    opacity: '0',
    transform: 'translate3d(-25px, 0, 0)'
  },
  img: {
    width: '35px',
    verticalAlign: 'middle',
    border: '0'
  },
  background: {
    position: 'absolute' as 'absolute',
    zIndex: 1,
    height: '100%',
    width: '100%',
    display: 'block',
    top: '0',
    left: '0',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    transition: 'all 300ms linear'
  },
  listUser: {
    marginTop: '0px',
    paddingLeft: '0',
    paddingTop: '0px',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: '#F9F9F9',
    textDecoration: 'none',
    backgroundColor: FactotalTheme.palette.primary.main,
    '&:before,&:after': {
      display: 'table',
      //content: "\" \"",
      textDecoration: 'none'
    },
    '&:after': {
      clear: 'both' as 'both',
      textDecoration: 'none'
    }
  },
  item: {
    color: 'inherit',
    position: 'relative' as 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '0',
    padding: '0'
  },
  userItem: {
    '&:last-child': {
      paddingBottom: '0px'
    }
  },
  itemLink: {
    textDecoration: 'none',
    transition: 'all 300ms linear',
    margin: '5px 0 0 10px',
    borderRadius: '0',
    position: 'relative' as 'relative',
    display: 'block',
    padding: '5px 10px',
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    lineHeight: 'normal',
    '&:hover': {
      color: `${FactotalTheme.palette.grey.A100}`,
      outline: 'none',
      backgroundColor: FactotalTheme.palette.grey[600],
      boxShadow: 'none',
      textDecoration: 'none'
    },
    '&,&:hover': {
      color: `${FactotalTheme.palette.grey.A100}`,
      textDecoration: 'none'
    },
    '&.active:hover': {
      '& div': {
        color: `${FactotalTheme.palette.grey.A100}`
      }
    }
  },
  itemIcon: {
    color: FactotalTheme.palette.grey[50],
    width: '30px',
    height: '24px',
    float: 'left' as 'left',
    position: 'inherit' as 'inherit',
    top: '0px',
    marginRight: '15px',
    textAlign: 'center' as 'center',
    verticalAlign: 'middle',
    opacity: '0.8'
  },
  itemText: {
    // color: FactotalTheme.palette.grey700,
    margin: '0',
    transform: 'translate3d(0px, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative' as 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap' as 'nowrap',
    textDecoration: 'none',
    letterSpacing: '0.03em',
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase' as 'uppercase',
    lineHeight: '1.8rem'
  },
  itemTextTitulo: {
    color: `${FactotalTheme.palette.grey[800]} !Important`,
    ...defaultFont,
    margin: '2px 0 0',
    // transform: "translate3d(0px, 0, 0)",
    transform: 'translateX(0px)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    position: 'relative' as 'relative',
    display: 'block',
    height: 'auto',
    whiteSpace: 'nowrap' as 'nowrap',
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    fontSize: '.75rem',
    fontWeight: 600,
    lineHeight: '1.8rem',
    '&:after': {
      //content: '""',
      position: 'absolute' as 'absolute',
      bottom: '0',
      right: '20px',
      height: '1px',
      width: 'calc(100% - 20px)',
      backgroundColor: FactotalTheme.palette.grey[400]
    }
  },
  userItemText: {
    lineHeight: '22px',
    textDecoration: 'none',
    // color: FactotalTheme.palette.dark,
    textTransform: 'none' as 'none',
    minHeight: 22
  },
  itemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
    textDecoration: 'none'
  },
  collapseList: {
    marginTop: '0',
    textDecoration: 'none'
  },
  collapseItem: {
    position: 'relative' as 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
    outline: 'none',
    '&.active, &:hover, &.active:hover': {
      color: `${FactotalTheme.palette.grey.A100} !Important`
    }
  },
  collapseItemTextActive: {
    color: `${FactotalTheme.palette.grey[500]} !Important`,
    margin: '0',
    position: 'absolute' as 'absolute',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap' as 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    textDecoration: 'none',
    fontSize: '.8em',
    fontWeight: 600,
    letterSpacing: '0.03em',
    lineHeight: '1.8em',
    '&.active:hover': {
      color: FactotalTheme.palette.grey.A100
    }
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: FactotalTheme.palette.grey.A200,
    borderLeft: `5px solid ${FactotalTheme.palette.primary.main}`,
    boxShadow: 'none',
    textDecoration: 'none',
    borderRadius: '0',
    color: 'black'
  },
  itemIconActive: {
    color: FactotalTheme.palette.primary.main,
    marginRight: '10px'
  },
  collapseItemLink: {
    color: `${FactotalTheme.palette.grey[500]} !Important`,
    textDecoration: 'none',
    transition: 'all 300ms linear',
    margin: '0 15px',
    borderRadius: '0',
    position: 'relative' as 'relative',
    display: 'block',
    padding: '10px',
    backgroundColor: 'transparent',
    ...defaultFont,
    width: 'auto',
    '&:hover': {
      outline: 'none',
      //backgroundColor: "rgba(200, 200, 200, 0.2)",
      boxShadow: 'none',
      textDecoration: 'none'
    },
    '&,&:hover': {
      color: 'inherit',
      textDecoration: 'none'
    }
  },
  collapseItemMini: {
    color: 'inherit',
    ...defaultFont,
    textTransform: 'uppercase' as 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    position: 'relative' as 'relative',
    float: 'left' as 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
    textDecoration: 'none'
  },
  collapseTitleMini: {
    color: `${FactotalTheme.palette.grey[500]} !Important`,
    ...defaultFont,
    textTransform: 'uppercase' as 'uppercase',
    width: '30px',
    marginRight: '15px',
    textAlign: 'center' as 'center',
    letterSpacing: '1px',
    position: 'relative' as 'relative',
    float: 'left' as 'left',
    display: 'inherit',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    fontSize: '14px',
    textDecoration: 'none'
  },
  collapseTitleHide: {
    display: 'none !Important'
  },
  collapseItemText: {
    color: '#555759',
    margin: '0',
    position: 'relative' as 'relative',
    transform: 'translateX(0px)',
    opacity: '1',
    whiteSpace: 'nowrap' as 'nowrap',
    display: 'block',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    textDecoration: 'none',
    letterSpacing: '0.03em',
    fontSize: '.75rem',
    fontWeight: 400,
    textTransform: 'uppercase' as 'uppercase',
    lineHeight: '1.8rem'
  },
  collapseItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
    textDecoration: 'none'
  },
  caret: {
    marginTop: '13px',
    position: 'absolute' as 'absolute',
    right: '18px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    textDecoration: 'none'
  },
  userCaret: {
    marginTop: '10px'
  },
  caretActive: {
    transform: 'rotate(90deg)',
    textDecoration: 'none'
  },
  purple: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: primaryColor,
      textDecoration: 'none',
      ...primaryBoxShadow
    }
  },
  blue: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      // backgroundColor: FactotalTheme.palette.grey600, // Color de fondo menú segundo nivel
      textDecoration: 'none',
      boxShadow:
        '0 12px 20px -10px rgba(0,188,212,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(0,188,212,.2)'
    }
  },
  green: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: successColor,
      textDecoration: 'none',
      boxShadow:
        '0 12px 20px -10px rgba(76,175,80,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(76,175,80,.2)'
    }
  },
  orange: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: warningColor,
      textDecoration: 'none',
      boxShadow:
        '0 12px 20px -10px rgba(255,152,0,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(255,152,0,.2)'
    }
  },
  red: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: dangerColor,
      textDecoration: 'none',
      boxShadow:
        '0 12px 20px -10px rgba(244,67,54,.28), 0 4px 20px 0 rgba(0,0,0,.12), 0 7px 8px -5px rgba(244,67,54,.2)'
    }
  },
  white: {
    '&,&:hover,&:focus': {
      color: '#3C4858',
      backgroundColor: '#FFFFFF',
      textDecoration: 'none',
      boxShadow:
        '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(60,72,88,.4)'
    }
  },
  rose: {
    '&,&:hover,&:focus': {
      color: '#FFFFFF',
      backgroundColor: roseColor,
      textDecoration: 'none',
      boxShadow:
        '0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(233,30,99,.4)'
    }
  },
  userBg: {
    background: FactotalTheme.palette.grey[200]
  },
  user: {
    background: FactotalTheme.palette.grey[200],
    paddingBottom: '10px',
    paddingTop: '10px',
    margin: '0 auto 0'
  },
  photo: {
    transition: 'all 300ms linear',
    width: '24px',
    height: '24px',
    overflow: 'hidden',
    display: 'block',
    position: 'relative' as 'relative',
    float: 'left' as 'left',
    zIndex: 5,
    marginRight: '15px',
    borderRadius: '50%',
    marginLeft: '22px',
    boxShadow: '0'
  },
  avatarImg: {
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    border: '0',
    color: FactotalTheme.palette.grey[500]
  },
  userCollapseButton: {
    textDecoration: 'none',
    '&:hover': {
      background: 'none',
      textDecoration: 'none'
    }
  },
  userCollapseLinks: {
    textDecoration: 'none',
    '& a:hover': {
      color: `${FactotalTheme.palette.grey.A100} !Important`
    },
    '&:hover': {
      color: `${FactotalTheme.palette.grey.A100} !Important`,
      textDecoration: 'none'
    }
  },
  userSpacing: {
    margin: '0',
    padding: '0'
  },
  caretSpacing: {
    right: '40px'
  },
  collapseUlList: {
    backgroundColor: FactotalTheme.palette.grey[400],
    margin: '0 0 0 25px',
    padding: '5px 0 15px',
    '& li': {
      margin: '0',
      padding: '0',
      '& a': {
        margin: '5px',
        padding: '5px'
      }
    }
  },
  collapseUlListUser: {
    backgroundColor: FactotalTheme.palette.grey[300],
    color: `${FactotalTheme.palette.grey[900]} !Important`,
    margin: '0',
    padding: '0',
    '& li': {
      margin: '0',
      padding: '0',
      '& a': {
        margin: '5px',
        padding: '5px',
        '& svg': {
          color: `${FactotalTheme.palette.grey[100]} !Important`
        }
      }
    }
  },
  collapseAvatar: {
    marginTop: '10px',
    marginLeft: '30px'
  },
  cerraSessionContainer: {
    width: '100%',
    ...scssTool.flex('center', 'row', 'center')
  },
  cerrarSessionRoot: {
    height: '49px',
    backgroundColor: 'transparent' as 'transparent',
    border: '1px solid #606060',
    borderRadius: '5px',
    width: '90%'
  },
  cerrarSessionLabel: {
    ...scssTool.font(12, 400, 15),
    color: '#606060',
    '& img': {
      marginRight: '6px'
    }
  }
});

export default sidebarStyle;

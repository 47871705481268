import React, { FC } from "react";
import cx from "classnames";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { Theme } from "@material-ui/core";
import scssTool from "../../../styles/scssTool";
import FactotalTheme from "../../../styles/FactotalTheme";
import logo from "../../../images/icons/logoFactotal.svg";

const logoStyle = (theme: Theme) => ({
  logoContainer: {
    ...scssTool.flex("center", "row", "center"),
    height: "60px",
    background: FactotalTheme.palette.primary.main,
  },
  ologo: {
    width: "32px",
  },
  textLogo: {
    ...scssTool.font(20, 400, 20),
    color: "#fff",
    height: "100%",
    paddingBottom: "20px",
    paddingLeft: "6px",
    ...scssTool.flex("flex-end", "row", "center"),
    "&.mini": {
      display: "none" as "none",
    },
  },
});

interface ILogoSidebar extends WithStyles<typeof logoStyle> {
  miniActive: boolean;
}

const LogoSidebar: FC<ILogoSidebar> = (props) => {
  const { classes, miniActive } = props;

  return (
    <div className={classes.logoContainer}>
      <img src={logo} alt="logo" className={classes.ologo} />
      <div
        className={cx(classes.textLogo, {
          mini: miniActive,
        })}
      >
        factotal
      </div>
    </div>
  );
};

export default withStyles(logoStyle)(LogoSidebar);

import React, { Component } from 'react';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

class Header extends Component {

  render() {
    return(
      <div id="InternalHeader">
        <div className="info-page">
          <div className="title">{ this.props.title }</div>
          <Breadcrumb route={ this.props.breadcrum } />
        </div>
        <div className="buttons">{ this.props.children }</div>
      </div>
    )
  }
}

export default Header;
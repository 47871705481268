// TODO Implementar function onGrabar, si es que requiere parametros
import { Button, Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import FileCopyIcon from '../../../../images/icons/file_copy_big_24px.svg';
import ArrowIcon from '../../../../images/icons/icons-bienes/arrow_right_24px_outlined.svg';
import dialogStyle from '../../../../styles/jss/components/dialogStyle';
import clsx from 'clsx';
import RangeCalendar from '../../../shared/RangeCalendar/RangeCalendar';

interface IActualizarRegistroModal extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  onActualizarRegistro: () => any;
  open: boolean;
}

const ActualizarRegistroModal: FC<IActualizarRegistroModal> = (props) => {
  const { classes, onClose, open, onActualizarRegistro } = props;
  const history = useHistory();
  const [selectedTipo, setSelectedTipo] = useState('');
  const [tipoInscripcion, setTipoInscripcion] = useState('');
  const [result, setResult] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [anchorElCalendar, setAnchorElCalendar] = useState(null);
  const [calendarValue, setCalendar] = useState({
    desde: '',
    hasta: ''
  });

  useEffect(() => {

  }, [result]);

  const handleBottom = () => {
    console.log('handleBottom');
    if (!result) {
      setResult(true);
    } else {
      onActualizarRegistro();
    }
  };

  const handleStatesCalendar = (params : any) => {
    setCalendar(params);
  };

  return (
    <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: classes.dialogPaperGreyVariant }}>
      <div className={[classes.containerGreyVariant, classes.heightAuto].join(' ')}>
        <div className={classes.floatingHeaderIcon}>
          <img src={FileCopyIcon} alt='' />
        </div>
        <DialogContent
          classes={{
            root: classes.dialogContent,
          }}>
          <div className={classes.NewtitleModal}>Reingreso</div>
          <div className={classes.subtitleModal}>Ingrese la infromación para el re-ingreso de la solicitud.</div>
          <div className={classes.inputRowContainer}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>PPU</div>
              <TextField id='ppu' className={clsx(classes.textField, 'litle')} margin='normal' variant='outlined' placeholder='XJDR43' />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>DV</div>
              <TextField id='dv' className={clsx(classes.textField, 'more-litle')} margin='normal' variant='outlined' placeholder='1' />
            </div>
          </div>
          <div className={classes.containerSeccion}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Nro Solicitud</div>
              <TextField id='nroSolicitud' className={clsx(classes.textField, 'midle')} margin='normal' variant='outlined' placeholder='99.567.890-0' />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Fecha solicitud rechazada</div>
              <TextField id='fechaSolicitudRechazada' className={clsx(classes.textField, 'midle')} margin='normal' variant='outlined' placeholder='Fecha' />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Nro resolución exenta</div>
              <TextField id='fechaResolucionExenta' className={clsx(classes.textField, 'midle')} margin='normal' variant='outlined' placeholder='123212312312' />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Fecha res exenta</div>
              <TextField id='fechaResExenta' className={clsx(classes.textField, 'midle')} margin='normal' variant='outlined' placeholder='Fecha' />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='outlined' color='primary' className={classes.greenButton} onClick={handleBottom}>
            Solicitar reingreso
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(dialogStyle)(ActualizarRegistroModal);

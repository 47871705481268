import { Button, FormControl, Grid, Input, MenuItem, Select, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import UploadDocumentoInput from '../../sharedv2/UploadDocumentoInput/UploadDocumentoInput';
import { handleChangeState } from '../../../helpers/PageStateHelper';
import { validaRut } from '../../../helpers/RutHelper';
import { validaEmail } from '../../../helpers/EmailHelper';
import { denunciarSiniestro, formularioSiniestro } from '../../../services/leasingApi';
import { getNameFile } from '../../../helpers/FileHelper';
import leasingApi from '../../../services/leasingApi';

const style = (theme : Theme) => ({
  /** FORMS */
  flexColumnContainer: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    marginBottom: '16px'
  },
  circleIcon: {
    marginRight: '13px',
    width: '31px',
    height: '31px',
    minWidth: '31px',
    minHeight: '31px',
    borderRadius: '31px',
    backgroundColor: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#FFFFFF',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center'
  },
  labelFormSinientro: {
    fontWeight: 600,
    fontSize: '17px',
    lineHeight: '25px',
    color: '#414141'
  },
  buttonGenerar: {
    padding: '18px 16px',
    backgroundColor: '#94C11F',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    alignSelf: 'center' as 'center',
    marginTop: '24px',
    '&:hover': {
      backgroundColor: '#94C11F'
    },
    '&:disabled': {
      opacity: .6
    }
  },
  buttonGenerarLabel: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: 'rgba(255, 255, 255, 0.87)'
  },
  formContainer: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column'
  },
  textField: {
    width: '100%',
    border: '1px solid #CACED8',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '4px',
    padding: '13px 18px',
    fontSize: '14px',
    lineHeight: '24px',
    marginBottom: '16px',
    height: '48px',
    '& $underline:before': {
      borderBottom: 'none'
    },
    '&.bottom': {
      marginBottom: '46px'
    }
  },
  message: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '22px',
    letterSpacing: '0.315px',
    color: '#414141',
    margin: '10px 0px',
    marginBottom: '24px'
  },
  volverBtn: {
    alignSelf: 'flex-end' as 'flex-end'
  },
  borderDashedLeft: {
    borderLeft: '1px dashed #000000'
  }
});

interface IProps extends WithStyles<typeof style> {
  close : () => void;
  notificacion : (exito : boolean, mensaje : string, timeout? : number) => any,
}

interface IModelo {
  entidadAseguradora : string,
  contrato : string,
  idBien : string,
  correoElectronicoDenunciante : string,
  nombreApellidoDenunciante : string,
  rutDenunciante : string,
  telefonoDenunciante : string,
  padron : File | undefined,
  cedula : File | undefined,
  formulario : File | undefined,
  licencia : File | undefined,
}

const iniModelo = () : IModelo => ({
  entidadAseguradora: '',
  contrato: '',
  idBien: '',
  correoElectronicoDenunciante: '',
  nombreApellidoDenunciante: '',
  rutDenunciante: '',
  telefonoDenunciante: '',
  padron: undefined,
  licencia: undefined,
  formulario: undefined,
  cedula: undefined
});

const FormularioSiniestro : FC<IProps> = (props) => {
  const { classes, close } = props;
  const [modelo, setModelo] = useState<IModelo>(iniModelo());
  const [contratos, setContratos] = useState<Dto.Leasing.ContratosVigentesRow[]>([]);
  const [bienes, setBienes] = useState<Dto.Leasing.BienesRow[]>([]);

  const handleChange = (event : React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => handleChangeState(modelo, setModelo)(event);

  const cargarContratos = useCallback(async () => {
    const resp = await leasingApi.getContratos({ numeroPagina: 1, tamanoPagina: 1000 });
    if (resp.data && resp.data.length > 0) {
      setContratos(resp.data);
    }
  }, []);

  useEffect(() => {
    if (modelo.contrato !== '') {
      leasingApi.getBienes({ numeroPagina: 1, tamanoPagina: 1000, contrato: Number(modelo.contrato) }).then(resp => {
        if (resp.data && resp.data.length > 0) {
          setBienes(resp.data);
        }
      });
    } else {
      setBienes([]);
      setModelo({ ...modelo, idBien: '' });
    }
  }, [modelo.contrato]);

  const modeloValido = useMemo(() => {
    return  modelo.contrato.length > 0 && modelo.idBien.length > 0 &&
      modelo.nombreApellidoDenunciante.length > 0 && modelo.telefonoDenunciante.length > 0 &&
      modelo.rutDenunciante.length > 0 && modelo.correoElectronicoDenunciante.length > 0 &&
      modelo.licencia !== undefined && modelo.cedula !== undefined &&
      modelo.padron !== undefined && modelo.formulario !== undefined &&
      validaRut(modelo.rutDenunciante) &&
      validaEmail(modelo.correoElectronicoDenunciante);
  }, [modelo]);

  const descargarFormulario = async () => {
    await formularioSiniestro({});
  };

  useEffect(() => {
    cargarContratos();
  }, []);

  const denunciar = async () => {
    if (modelo.cedula && modelo.formulario && modelo.padron && modelo.licencia) {
      const payload : Request.Leasing.DenunciaSiniestro = {
        ...modelo,
        cedulaIdentidad: '',
        cedulaIdentidadName: getNameFile(modelo.cedula.name),
        numeroContrato: Number(modelo.contrato),
        formulario: '',
        formularioName: getNameFile(modelo.formulario.name),
        padron: '',
        padronName: getNameFile(modelo.padron.name),
        licencia: '',
        licenciaName: getNameFile(modelo.licencia.name)
      };
      const respuesta = await denunciarSiniestro(payload, modelo.cedula, modelo.padron, modelo.licencia, modelo.formulario);
      if (respuesta) {
        close();
        props.notificacion(true, 'La notificación siniestro se ha enviado exitosamente');
      } else {
        props.notificacion(false, 'La notificación siniestro no se ha podido enviar');
      }
    }
  };

  return (
    <Grid container spacing={32}>
      <Grid item xs={12} md={6}>
        <div className={classes.formContainer}>
          <div className={classes.flexColumnContainer}>
            <div className={classes.circleIcon}>1</div>
            <div className={classes.labelFormSinientro}>Seguro, contrato y bien</div>
          </div>
          <FormControl fullWidth>
            <Select
              className={[classes.textField].join(' ')}
              value={modelo.entidadAseguradora}
              onChange={handleChange}
              displayEmpty
              input={<Input name='entidadAseguradora' id='entidadAseguradora' />}
              name='entidadAseguradora'
            >
              <MenuItem value={''}>Selecciona la entidad aseguradora</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Select
              className={[classes.textField].join(' ')}
              value={modelo.contrato}
              onChange={handleChange}
              displayEmpty
              name='contrato'
            >
              <MenuItem value={''}>Selecciona el contrato</MenuItem>
              {contratos.map((contrato, idx) =>
                <MenuItem key={idx} value={contrato.numeroContrato.toString()}>N° {contrato.numeroContrato.toString()}, {contrato.detalleContrato}</MenuItem>)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <Select
              className={[classes.textField, 'bottom'].join(' ')}
              value={modelo.idBien}
              onChange={handleChange}
              displayEmpty
              // input={<Input name='idBien' id='idBien' />}
              name='idBien'
            >
              <MenuItem value={''}>Selecciona el bien</MenuItem>
              {bienes.map((bien, idx) =>
                <MenuItem key={idx} value={bien.idBien}>N° {bien.tipoBien}</MenuItem>)}
            </Select>
          </FormControl>
          <div className={classes.flexColumnContainer}>
            <div className={classes.circleIcon}>2</div>
            <div className={classes.labelFormSinientro}>Datos del denunciante</div>
          </div>
          <input
            type='text'
            className={classes.textField}
            name='nombreApellidoDenunciante'
            placeholder='Nombre y apellido'
            value={modelo.nombreApellidoDenunciante}
            onChange={handleChange}
          />
          <input
            type='text'
            className={classes.textField}
            name='rutDenunciante'
            placeholder='Rut'
            data-tipo={'rut'}
            value={modelo.rutDenunciante}
            onChange={handleChange}
          />
          <input
            type='text'
            className={classes.textField}
            name='correoElectronicoDenunciante'
            placeholder='Correo electrónico'
            value={modelo.correoElectronicoDenunciante}
            onChange={handleChange}
          />
          <input
            type='text'
            className={classes.textField}
            name='telefonoDenunciante'
            placeholder='Número de teléfono'
            value={modelo.telefonoDenunciante}
            onChange={handleChange}
          />
          <Button classes={{ root: classes.buttonGenerar, label: classes.buttonGenerarLabel }}
                  onClick={descargarFormulario}>
            Obtener formulario para imprimir
          </Button>
        </div>
      </Grid>
      <Grid item xs={12} md={6} className={classes.borderDashedLeft}>
        <div className={classes.flexColumnContainer}>
          <div className={classes.circleIcon}>3</div>
          <div className={classes.labelFormSinientro}>Documentos para la denuncia</div>
        </div>
        <div className={classes.message}>
          Adjunta los documentos para validar la denuncia de siniestro. Recuerda que{' '}
          <strong>debes imprimir, completar y firmar el formulario en el paso 2</strong> para adjuntarlo junto
          al resto de documentos.
        </div>
        <UploadDocumentoInput
          id={'cedula'}
          accept={'image/*'}
          onupload={(e : File | undefined) => setModelo({ ...modelo, cedula: e })}
          label={'Cédula de identidad'}
          value={modelo.cedula}
        />
        <UploadDocumentoInput
          id={'padron'}
          accept={'image/*'}
          onupload={(e : File | undefined) => setModelo({ ...modelo, padron: e })}
          label={'Padrón'}
          value={modelo.padron}
        />
        <UploadDocumentoInput
          id={'licencia'}
          accept={'image/*'}
          onupload={(e : File | undefined) => setModelo({ ...modelo, licencia: e })}
          label={'Licencia de conducir'}
          value={modelo.licencia}
        />
        <UploadDocumentoInput
          id={'formularioDenunciaFirmado'}
          accept={'*'}
          onupload={(e : File | undefined) => setModelo({ ...modelo, formulario: e })}
          label={'Formulario de denuncia firmado a mano'}
          value={modelo.formulario}
        />
        <Button classes={{ root: classes.buttonGenerar, label: classes.buttonGenerarLabel }}
                disabled={!modeloValido}
                onClick={denunciar}>
          Denunciar siniestro
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button className={classes.volverBtn} onClick={close}>Volver</Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(style)(FormularioSiniestro);

import { Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { FC } from 'react';
import XmlIcon from '../../../images/icons/XMLID_454.svg';
import LostIcon from '../../../images/icons/lost.svg';

const style = (theme: Theme) => ({
  plazosContainer: {
    background: '#F8F8F8',
    borderRadius: 8,
    padding: '24px',
  },
  plazoItem: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    '& img': {
      marginRight: '22px',
    },
    '&:first-child': {
      marginBottom: '32px',
    },
  },
  plazoItemContainer: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    '& span:nth-child(1)': {
      fontWeight: 300,
      fontSize: '12px',
      lineHeight: '12px',
      color: '#414141',
    },
    '& span:nth-child(2)': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#414141',
    },
  },
});

interface IPlazosContainer extends WithStyles<typeof style> {}

const PlazosContainer: FC<IPlazosContainer> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.plazosContainer}>
      <div className={classes.plazoItem}>
        <img src={XmlIcon} alt='' />
        <div className={classes.plazoItemContainer}>
          <span>Plazo para denuncia de siniestro</span>
          <span>10 días seguidos</span>
        </div>
      </div>
      <div className={classes.plazoItem}>
        <img src={LostIcon} alt='' />
        <div className={classes.plazoItemContainer}>
          <span>Plazo para denuncia de robo</span>
          <span>Inmediata</span>
        </div>
      </div>
    </div>
  );
};

export default withStyles(style)(PlazosContainer);

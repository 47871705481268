import { Theme } from '@material-ui/core';

const TermsAndConditionStyle = (theme : Theme) => ({
  root: {
    '& h1': {
      color: '#000000',
      fontSize: '30px',
      textAlign: 'center' as 'center',
      fontWeight: 'normal' as 'normal'
    }
  },
  rootXml: {
    width: '700px !important',
    height: '90vh !important',
    maxWidth: 'none'
  },
  dialogPaper: {
    width: '750px',
    height: '80%',
    border: '3px solid #82BC00',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '16px',
    overflow: 'inherit',
    padding: '50px 40px 40px 40px',
    lineHeight: '21px',
    position: 'absolute' as 'absolute'
  },
  dialogPapersOr: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  dialogTitleTerms: {
    margin: '0px',
    padding: '0px 0px 23px 0px',
    borderBottom: 'none',
    '& h6': {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#3C3C3C'
    }
  },
  dialogTermsTabRoot: {
    background: '#F5F6FA',
    border: '1px solid #686B6D',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '10px',
    width: '100%',
    height: '45px'
  },
  dialogTermIndicator: {
    height: '100%',
    background: '#6F777B',
    zIndex: 1
  },
  termDialogTab: {
    width: '50%',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center' as 'center',
    color: '#555759',
    maxWidth: 'none'
  },
  dialogTermsTabsSelected: {
    background: '#6F777B',
    color: '#FFFFFF',
    zIndex: 2
  },
  dialogTermLabelContainer: {
    paddingTop: '3px'
  },
  termsConditionTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3C3C3C'
  },
  termsConditionTitleBig: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#3C3C3C',
    textTransform: 'uppercase' as 'uppercase'
  },
  tableTerms: {
    borderCollapse: 'collapse' as 'collapse'
  },
  tableHeader: {
    textAlign: 'center' as 'center'
  },
  cardReq: {
    background: '#E5E6E6',
    borderRadius: '5px',
    padding: '16px 8px',
    display: 'flex',
    alignItems: 'flex-start',
    margin: '16px 0px'
  },
  cardReqIcon: {
    marginRight: '16px'
  },
  cardReqContent: {
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  cardReqTitle: {
    fontWeight: 'normal' as 'normal',
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#102333'
  },
  cardReqSubtitleLitle: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#828282'
  },
  cardReqSubtitle: {
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: '#828282',
    marginBottom: '4px',
    fontWeight: 600
  },
  pbold: {
    fontWeight: 600
  },
  cardReqFunc: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.0833333px',
    color: 'rgba(16, 35, 51, 0.65)',
    '& ul': {
      marginLeft: '0px !important',
      paddingLeft: '0px !important',
      fontWeight: 'normal' as 'normal',
      fontSize: '12px !important',
      lineHeight: '18px',
      color: '#828282 !important'
    }
  },
  disclaimer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    color: '#555759'
  },
  boxLitle: {
    padding: '16px 8px',
    display: 'flex',
    background: '#E5E6E6',
    alignItems: 'flex-start',
    borderRadius: '5px',
    '& p': {
      textAlign: 'center' as 'center'
    }
  },
  pcenter: {
    textAlign: 'center' as 'center'
  },
  dialogScrollContent: {
    padding: '8px 16px 32px 0px',
    marginTop: '16px',
    height: '55vh',
    '& ul': {
      marginLeft: '0px',
      paddingLeft: '32px',
      fontWeight: 'normal' as 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#000000'
    },
    '& h2': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: '#3C3C3C',
      marginTop: '32px'
    },
    '& p': {
      fontWeight: 'normal' as 'normal',
      fontSize: '14px',
      lineHeight: '18px',
      color: '#000000'
      // '& span': {
      //   fontWeight: 600
      // }
    },
    '& table': {
      color: '#555759'
    }
  },
  dialogActions: {
    justifyContent: 'flex-start'
  },
  cancelButtom: {
    fontWeight: 'normal' as 'normal',
    fontSize: '12px',
    textTransform: 'capitalize' as 'capitalize'
  }
});

export default TermsAndConditionStyle;

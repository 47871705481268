import { asyncActionType } from '../../../helpers/ActionsHelper';
import { userApi } from '../../../services/userApi';
import factorxApi from '../../../services/factorxApi';
import 'moment/locale/es';
import { Dispatch } from 'redux';
import { AxiosResponse } from 'axios';
import { puedeEntrarSitioAdmin } from '../../../helpers/UserHelper';

export const fetchAction = asyncActionType('@@FT_LOGIN', 'FETCH');

export const fetchLogin = () => ({
  type: fetchAction.PENDING
});

export const loginFail = () => ({
  type: fetchAction.FAILURE
});

export const ActionLogout = () => async (dispatch : Dispatch) => {
  await userApi.logout();
  return dispatch(loginFail());
};

export const ActionLogin = (params : Dto.LoginRequest) => (dispatch : Dispatch) : Promise<Comun.Dto.LoginUserInfo> => {
  return userApi.login(params)
                .then((response : AxiosResponse<Response.Login>) => {
                  response = (response as any).response || response; //en los 401 la respuesta llega dentro de otra respuesta
                  if (response && response.status && response.status !== 200) {
                    return { status: response.status, isValid: undefined } as Comun.Dto.LoginUserInfo;
                  }
                  const data : Response.Login = response.data;
                  const loginUserInfo : Comun.Dto.LoginUserInfo = { ...data, status: response.status, isValid: undefined };
                  if (data.empresas.length > 1 || (data.empresas.length === 1 && puedeEntrarSitioAdmin(loginUserInfo))) {
                    return loginUserInfo;
                  } else {
                    loginUserInfo.isValid = true;
                    sessionStorage.setItem('user', JSON.stringify(loginUserInfo));
                    if (data.empresas.length > 0) {
                      return factorxApi.registrarEmpresa(data)
                                       .then((respuesta) => {
                                         if (respuesta.exito) {
                                           sessionStorage.setItem('fx-token', respuesta.token);
                                           sessionStorage.setItem('fx-id', JSON.stringify(respuesta.idEmpresaPais));
                                           return loginUserInfo;
                                         }
                                       });
                    } else {
                      return loginUserInfo;
                    }
                  }
                })
                .catch(error => error);
};

export const recoverCode = (params : { rut : string }) => {
  return userApi.recoverCode(params)
                .then(response => response)
                .catch(error => {
                  if (!!error.response && error.response.status === 400) return false;
                });
};

export const validateRecoverCode = (params : Dto.ValidateRecoveryTokenRequest) => {
  return userApi.validateRecoverCode(params)
                .then(response => response)
                .catch(error => {
                  if (!!error.response && error.response.status === 400) return false;
                });
};

export const recoveryChangePassword = (params : Dto.PasswordRecoveryChangeRequest) => {
  return userApi.recoveryChangePassword(params)
                .then(response => response)
                .catch(error => {
                  if (!!error.response && error.response.status === 400) return false;
                });
};

export default { fetchAction, ActionLogin };

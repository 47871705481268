import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { formatNumber } from '../../../../../helpers/FormatNumber';
import { Theme } from '@material-ui/core';

const styles = (theme : Theme) => ({
  detailsTable: {
    width: '100%',
    height: 'auto',
    position: 'relative' as 'relative'
  },
  rowDetailsTable: {
    width: '100%',
    height: '30px',
    padding: '5px 13px',
    fontFamily: 'Arial',
    fontWeight: 'normal' as 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3A3A3A',
    boxSizing: 'border-box' as 'border-box'
  },
  columnsLeft: {
    textAlign: 'left' as 'left',
    float: 'left' as 'left',
    width: '50%'
  },
  columnsRight: {
    textAlign: 'right' as 'right',
    fontWeight: 'bold' as 'bold',
    float: 'right' as 'right',
    width: '50%'
  }
});

const DetailsBox = ({ classes, data, empresa } : { classes : any, data : Dto.BackOffice.Operacion, empresa : Dto.Portal.Empresa }) => {
  return (
    <Grid container spacing={24}>
      <Grid item xs={6}>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>Nombre Cliente:</div>
            <div className={classes.columnsRight}>{empresa.razonSocial}</div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>RUT Cliente:</div>
            <div className={classes.columnsRight}>{empresa.idEmpresaPais.valor}</div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>Fecha Operacion:</div>
            <div className={classes.columnsRight}>{data.fechaSolicitud}</div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>Tipo Documento:</div>
            <div className={classes.columnsRight}>{data.documentos[0].tipoDteDesc}</div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>Beneficiario:</div>
            <div className={classes.columnsRight}>
              {/*{data.NombreBeneficiario}*/}
            </div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>RUT Beneficiario:</div>
            <div className={classes.columnsRight}>
              {/*{data.RutBeneficiario}*/}
            </div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>Banco:</div>
            <div className={classes.columnsRight}></div>
          </div>
        </div>
        <div className={classes.detailsTable}>
          <div className={classes.rowDetailsTable}>
            <div className={classes.columnsLeft}>Tipo Cuenta:</div>
            <div className={classes.columnsRight}></div>
          </div>
        </div>
        {/*<div className={classes.detailsTable}>*/}
        {/*  <div className={classes.rowDetailsTable}>*/}
        {/*    <div className={classes.columnsLeft}>Nº Cuenta:</div>*/}
        {/*    <div className={classes.columnsRight}></div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Grid>
      <Grid item xs={6}>
        {data.idEstado < 3 ? (
          <div style={{
            padding: '20px 30px',
            display: 'flex',
            alignItems: 'center',
            height: '62%'
          }}><h1 style={{
            fontSize: '18px',
            lineHeight: '27px',
            textAlign: 'center',
            color: '#95989A',
            fontWeight: 200
          }}>INFORMACIÓN DE LA OFERTA SÓLO ESTARÁ DISPONIBLE UNA VEZ SUPERADA LA ETAPA DE REVISIÓN DE CRÉDITO</h1></div>
        ) : (
          <>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Monto Operación:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.documento)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Monto financiado:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.anticipado)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Diferencia de precio:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.diferenciaPrecio)}
                </div>
              </div>
            </div>
            {/*<div className={classes.detailsTable}>*/}
            {/*  <div className={classes.rowDetailsTable}>*/}
            {/*    <div className={classes.columnsLeft}>Diferencia de Precio:</div>*/}
            {/*    <div className={classes.columnsRight}>*/}
            {/*      ${formatMoney(-1)}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Comisión:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.comision)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>IVA Comisión:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.ivaComision)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Gasto Operación:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.gastosOperacion)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>IVA Gasto Operación:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.ivaGastosOperacion)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Gastos Notariales:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.gastosNotariales)}
                </div>
              </div>
            </div>
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>IVA Gastos Notariales:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.ivaGastosNotariales)}
                </div>
              </div>
            </div>
            {/*<div className={classes.detailsTable}>*/}
            {/*  <div className={classes.rowDetailsTable}>*/}
            {/*    <div className={classes.columnsLeft}>Cuenta Cliente:</div>*/}
            {/*    <div className={classes.columnsRight}>*/}
            {/*      ${formatMoney(-1)}*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className={classes.detailsTable}>
              <div className={classes.rowDetailsTable}>
                <div className={classes.columnsLeft}>Monto Pagado:</div>
                <div className={classes.columnsRight}>
                  ${formatNumber(data.montos.montoGirar)}
                </div>
              </div>
            </div>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(DetailsBox);

import registroCivilApi from '../../services/registroCivilApi';
import { asyncActionType } from '../../helpers/ActionsHelper';
import { Dispatch } from 'redux';
import { ActionT, AppState } from '../../config/rootReducer';

export interface IFetchPending {
  tamanoPagina: number;
}

export const fetchEmpresasAction = asyncActionType('@@EMPRESAS_REGISTRO_CIVIL', 'FETCH');

const _obtenerEmpresasThunk = (filters: Request.RegistroCivil.Empresas) => (dispatch: Dispatch): Promise<void> => {
  dispatch(obtenerEmpresas.request(filters));
  return registroCivilApi.getEmpresas(filters)
                         .then((response) => dispatch(obtenerEmpresas.succeed(response)))
                         .catch(error => error);
};

export const obtenerEmpresas = Object.assign(_obtenerEmpresasThunk, {
  request: (request: Request.RegistroCivil.Empresas): ActionT<IFetchPending> => ({
    type: fetchEmpresasAction.PENDING,
    payload: {
      tamanoPagina: request.tamanoPagina
    }
  }),
  succeed: (response: Response.RegistroCivil.Empresas): ActionT<Response.RegistroCivil.Empresas> => ({
    type: fetchEmpresasAction.SUCCESS,
    payload: {
      data: response.data,
      paginacion: {
        ...response.paginacion,
        numeroPagina: response.paginacion.numeroPagina - 1
      }
    }
  })
});


export const fetchEmpresaContratosAction = asyncActionType('@@CONTRATOS_REGISTRO_CIVIL', 'FETCH');

const _obtenerEmpresaContratosThunk = (idEmpresa: string, filters: Request.RegistroCivil.EmpresaContratos) => (dispatch: Dispatch): Promise<void> => {
  dispatch(obtenerEmpresaContratos.request());
  return registroCivilApi.getEmpresaContrato(idEmpresa, filters)
                         .then((response) => dispatch(obtenerEmpresaContratos.succeed(response)))
                         .catch(error => error);
};

export const obtenerEmpresaContratos = Object.assign(_obtenerEmpresaContratosThunk, {
  request: (): ActionT<any> => ({
    type: fetchEmpresaContratosAction.PENDING,
    payload: {}
  }),
  succeed: (response: Response.RegistroCivil.EmpresaContratos): ActionT<Response.RegistroCivil.EmpresaContratos> => ({
    type: fetchEmpresaContratosAction.SUCCESS,
    payload: {
      empresa: response.empresa,
      paginacionContratos: {
        ...response.paginacionContratos,
        numeroPagina: response.paginacionContratos.numeroPagina - 1
      }
    }
  })
});

export const seleccionarContratoAction = asyncActionType('@@CONTRATO_REGISTRO_CIVIL', 'SELECT');

export const _seleccionarContratoThunk = (idContrato: number) => (dispatch: Dispatch): void => {
  dispatch(seleccionarContrato.request(idContrato));
};
export const seleccionarContrato = Object.assign(_seleccionarContratoThunk, {
  request: (idContrato: number): ActionT<number> => ({
    type: seleccionarContratoAction.SUCCESS,
    payload: idContrato
  })
});

export const nuevoContratoEmpresaAction = asyncActionType('@@CONTRATO_EMPRESA_REGISTRO_CIVIL', 'CREAR');

const _nuevoContratoEmpresaThunk = (idEmpresa: string, request: Request.RegistroCivil.NuevoContrato) => (dispatch: Dispatch): Promise<void> => {
  dispatch(nuevoContratoEmpresa.request());
  return registroCivilApi.nuevoContrato(idEmpresa, request)
                         .then((response) => dispatch(nuevoContratoEmpresa.succeed(response)))
                         .catch(error => error);
};

export const nuevoContratoEmpresa = Object.assign(_nuevoContratoEmpresaThunk, {
  request: (): ActionT<any> => ({
    type: nuevoContratoEmpresaAction.PENDING,
    payload: {}
  }),
  succeed: (response: Response.RegistroCivil.NuevoContrato): ActionT<Response.RegistroCivil.NuevoContrato> => ({
    type: nuevoContratoEmpresaAction.SUCCESS,
    payload: {
      empresa: response.empresa,
      paginacionContratos: {
        ...response.paginacionContratos,
        numeroPagina: response.paginacionContratos.numeroPagina - 1
      }
    }
  })
});


export const persistirInscripcionAction = asyncActionType('@@INSCRIPCION_REGISTRO_CIVIL', 'CREAR');

const _persistirInscripcionThunk = (idEmpresa: string, idContrato: string, request: Request.RegistroCivil.Inscripcion) => (dispatch: Dispatch): Promise<Response.RegistroCivil.Inscripcion> => {
  dispatch(persistirInscripcion.request());
  return registroCivilApi.persistirInscripcion(idEmpresa, idContrato, request)
                         .then((response) => dispatch(persistirInscripcion.succeed(response)))
                         .catch(error => error);
};

export const persistirInscripcion = Object.assign(_persistirInscripcionThunk, {
  request: (): ActionT<any> => ({
    type: persistirInscripcionAction.PENDING,
    payload: {}
  }),
  succeed: (response: Response.RegistroCivil.Inscripcion): ActionT<Response.RegistroCivil.Inscripcion> => ({
    type: persistirInscripcionAction.SUCCESS,
    payload: {
      empresa: response.empresa,
      inscrip: response.inscrip,
      paginacionContratos: {
        ...response.paginacionContratos,
        numeroPagina: response.paginacionContratos.numeroPagina - 1
      }
    }
  })
});

export const limpiarInscripcionAction = asyncActionType('@@INSCRIPCION_REGISTRO_CIVIL', 'LIMPIAR');

const _limpiarInscripcionThunk = () => (dispatch: Dispatch) => {
  dispatch(limpiarInscripcion.request());
};

export const limpiarInscripcion = Object.assign(_limpiarInscripcionThunk, {
  request: (): ActionT<any> => ({
    type: limpiarInscripcionAction.SUCCESS,
    payload: {}
  })
});

export const limpiarTransferenciaAction = asyncActionType('@@TRANSFERENCIA_REGISTRO_CIVIL', 'LIMPIAR');

const _limpiarTransferenciaThunk = () => (dispatch: Dispatch) => {
  dispatch(limpiarTransferencia.request());
};

export const limpiarTransferencia = Object.assign(_limpiarTransferenciaThunk, {
  request: (): ActionT<any> => ({
    type: limpiarTransferenciaAction.SUCCESS,
    payload: {}
  })
});

export const cargarInscripcionAction = asyncActionType('@@INSCRIPCION_REGISTRO_CIVIL', 'CARGAR');

const _cargarInscripcionThunk = (idInscripcion: number) => (dispatch: Dispatch, getState: () => AppState): any => {
  const contrato = getState().registroCivil.contrato;
  if (contrato !== undefined) {
    const inscripcion = contrato.inscripciones.find(s => s.idInscripcion === idInscripcion);
    if (inscripcion) {
      dispatch(cargarInscripcion.succeed(inscripcion));
    }
  }
};

export const cargarInscripcion = Object.assign(_cargarInscripcionThunk, {
  succeed: (response: Dto.RegistroCivil.Inscripcion): ActionT<Dto.RegistroCivil.Inscripcion> => ({
    type: cargarInscripcionAction.SUCCESS,
    payload: response
  })
});

export const enviarInscripcionAction = asyncActionType('@@INSCRIPCION_REGISTRO_CIVIL', 'ENVIAR');

const _enviarInscripcionThunk = (idEmpresa: string, idContrato: string, request: Request.RegistroCivil.EnviarInscripcion) => (dispatch: Dispatch): Promise<Response.RegistroCivil.EnviarInscripcion> => {
  dispatch(enviarInscripcion.request());
  return registroCivilApi.enviarInscripcion(idEmpresa, idContrato, request)
                         .then((response) => {
                           dispatch(enviarInscripcion.succeed(response));
                           return response;
                         })
                         .catch(error => error);
};

export const enviarInscripcion = Object.assign(_enviarInscripcionThunk, {
  request: (): ActionT<any> => ({
    type: enviarInscripcionAction.PENDING,
    payload: {}
  }),
  succeed: (response: Response.RegistroCivil.EnviarInscripcion): ActionT<Response.RegistroCivil.EnviarInscripcion> => ({
    type: enviarInscripcionAction.SUCCESS,
    payload: response
  })
});


export const persistirTransferenciaAction = asyncActionType('@@TRANSFERENCIA_REGISTRO_CIVIL', 'CREAR');

const _persistirTransferenciaThunk = (idEmpresa: string, idContrato: string, request: Request.RegistroCivil.Transferencia) => (dispatch: Dispatch): Promise<Response.RegistroCivil.Transferencia> => {
  dispatch(persistirTransferencia.request());
  return registroCivilApi.persistirTransferencia(idEmpresa, idContrato, request)
                         .then((response) => dispatch(persistirTransferencia.succeed(response)))
                         .catch(error => error);
};

export const persistirTransferencia = Object.assign(_persistirTransferenciaThunk, {
  request: (): ActionT<any> => ({
    type: persistirTransferenciaAction.PENDING,
    payload: {}
  }),
  succeed: (response: Response.RegistroCivil.Transferencia): ActionT<Response.RegistroCivil.Transferencia> => ({
    type: persistirTransferenciaAction.SUCCESS,
    payload: {
      empresa: response.empresa,
      transfer: response.transfer,
      paginacionContratos: {
        ...response.paginacionContratos,
        numeroPagina: response.paginacionContratos.numeroPagina - 1
      }
    }
  })
});

export const cargarTransferenciaAction = asyncActionType('@@TRANSFERENCIA_REGISTRO_CIVIL', 'CARGAR');

const _cargarTransferenciaThunk = (idTransferencia: number) => (dispatch: Dispatch, getState: () => AppState): any => {
  const contrato = getState().registroCivil.contrato;
  if (contrato !== undefined) {
    const transferencia = contrato.transferencias.find(s => s.idTransferencia === idTransferencia);
    if (transferencia) {
      dispatch(cargarTransferencia.succeed(transferencia));
    }
  }
};

export const cargarTransferencia = Object.assign(_cargarTransferenciaThunk, {
  succeed: (response: Dto.RegistroCivil.Transferencia): ActionT<Dto.RegistroCivil.Transferencia> => ({
    type: cargarTransferenciaAction.SUCCESS,
    payload: response
  })
});

export const enviarTransferenciaAction = asyncActionType('@@TRANSFERENCIA_REGISTRO_CIVIL', 'ENVIAR');

const _enviarTransferenciaThunk = (idEmpresa: string, idContrato: string, request: Request.RegistroCivil.EnviarTransferencia) => (dispatch: Dispatch): Promise<Response.RegistroCivil.EnviarTransferencia> => {
  dispatch(enviarTransferencia.request());
  return registroCivilApi.enviarTransferencia(idEmpresa, idContrato, request)
                         .then((response) =>{
                           dispatch(enviarTransferencia.succeed(response));
                           return response;
                         })
                         .catch(error => error);
};

export const enviarTransferencia = Object.assign(_enviarTransferenciaThunk, {
  request: (): ActionT<any> => ({
    type: enviarTransferenciaAction.PENDING,
    payload: {}
  }),
  succeed: (response: Response.RegistroCivil.EnviarTransferencia): ActionT<Response.RegistroCivil.EnviarTransferencia> => ({
    type: enviarTransferenciaAction.SUCCESS,
    payload: response
  })
});

import * as React from 'react';
import '../../../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../style';
import Dialog from '@material-ui/core/Dialog';
import Done from '@material-ui/icons/Done';
import ArrowRight from '@material-ui/icons/ArrowRight';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import '../../../../sharedv2/custom_theme.scss';
import { formatNumber } from '../../../../../helpers/FormatNumber';
import SeleccionCertificadoCesionPartial from './SeleccionCertificadoCesionPartial';
import Grid from '@material-ui/core/Grid';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface IReintentarCesionModalProps extends RouteComponentProps, WithStyles<typeof SAcceptYieldModal> {
  operacion : Dto.BackOffice.Operacion,
  reintentoCesionDocumentosOperacion : (idCertificadoCesion : string, passwordCertificadoCesion : string, idsDocumentosAutorizados : string[]) => Promise<boolean>,
  open : boolean,
  onClose : () => void
}

interface IState {
  idCertificadoCesion? : number,
  passwordCertificadoCesion : string,
  idsSeleccionados : string[],
  estaCargando : boolean
}

class ReintentarCesionModal extends React.Component<IReintentarCesionModalProps, IState> {
  constructor(props : IReintentarCesionModalProps) {
    super(props);
    this.state = {
      idCertificadoCesion: undefined,
      passwordCertificadoCesion: '',
      idsSeleccionados: [],
      estaCargando: false
    };
    this.reintentoCesion = this.reintentoCesion.bind(this);
  }


  handleClose = () => {
    this.props.onClose();
  };

  handleSelectCertificadoCesion = (event : React.ChangeEvent<{}>, checked : boolean) => {
    const element = event.currentTarget as any;
    this.setState({
      idCertificadoCesion: element.value
    });
  };

  onChangeClaveCertificado = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheckFactura = (event : React.ChangeEvent<HTMLInputElement>, checked : boolean) => {
    const id = event.target.value;
    let ids = this.state.idsSeleccionados;
    if (event.target.checked) {
      ids.push(id);
    } else {
      const idx = ids.findIndex((el) => el === id);
      if (idx !== -1) {
        ids.splice(idx, 1);
      }
    }
    this.setState({ idsSeleccionados: ids });
  };

  puedeReintentarCeder = () => {
    return this.state.idsSeleccionados.length > 0 && this.state.idCertificadoCesion && this.state.passwordCertificadoCesion !== '';
  };

  reintentoCesion = () => {
    if (this.state.idCertificadoCesion) {
      this.setState({ estaCargando: true });
      this.props.reintentoCesionDocumentosOperacion(this.state.idCertificadoCesion.toString(), this.state.passwordCertificadoCesion, this.state.idsSeleccionados)
          .then((r : boolean) => {
            if (!r) {
              this.setState({ estaCargando: false });
            }
          });
    }
  };

  render() {
    const { classes, open, operacion } = this.props;
    const { estaCargando } = this.state;

    return (
      <Dialog onClose={this.handleClose} className={classes.root} open={open}
              PaperProps={{ className: [classes.dialogPaper, classes.dialogConfirm].join(' ') }}>
        <div className={classes.checkIcon}><Done /></div>
        <DialogContent className={classes.dialogContent}>
          <h1>Confirmar Reintento de Cesión</h1>
          <p><ArrowRight />Se reintentará la cesión de estos documentos</p>
          <Grid container spacing={24} id="detalle-proceso">

            <Table className={classes.TableFTC} aria-label="simple table">
              <TableHead className={classes.HeaderTableFCT}>
                <TableRow className={classes.TableRowFCT}>
                  <TableCell className={classes.TableThFCT}>Nº documento</TableCell>
                  <TableCell align="right" className={classes.TableThFCT}>Tipo Documento</TableCell>
                  <TableCell align="right" className={classes.TableThFCT}>Monto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {operacion.documentos.filter(doc => doc.puedeReintentarCeder)
                          .map((row) => (
                            <TableRow key={row.numeroDocumento} className={classes.TableRowBodyFCT}>
                              <TableCell scope="row" className={classes.TableCellFCT}>
                                <Checkbox
                                  key={row.id}
                                  value={row.id}
                                  color="primary"
                                  onChange={this.handleCheckFactura}
                                  inputProps={{ 'aria-label': 'Selecionar' }}
                                />
                                {row.numeroDocumento}
                              </TableCell>
                              <TableCell align="right" className={classes.TableCellFCT}>{row.tipoDteDesc}</TableCell>
                              <TableCell align="right"
                                         className={classes.TableCellFCT}>${formatNumber(row.montoDocumento)}</TableCell>
                            </TableRow>
                          ))}
              </TableBody>
            </Table>

            <SeleccionCertificadoCesionPartial idCertificadoCesion={this.state.idCertificadoCesion}
                                               passwordCertificadoCesion={this.state.passwordCertificadoCesion}
                                               handleSelectCertificadoCesion={this.handleSelectCertificadoCesion}
                                               onChangeClaveCertificado={this.onChangeClaveCertificado}
                                               enModal={true}
            />

          </Grid>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div className={classes.containerButtons}>
            <Button onClick={() => this.reintentoCesion()}
                    disabled={estaCargando || !this.puedeReintentarCeder()}>
              {estaCargando ?
                <CircularProgress size={20} />
                : 'Continuar'}
            </Button>
            <Button className={classes.outlinePrimeryButton} onClick={() => this.handleClose()}>Volver</Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(withRouter(ReintentarCesionModal));

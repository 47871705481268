import FactotalTheme from './FactotalTheme';
import bgImageFactoring from '../images/caluga_vendor.jpg';
import bgImageVendor from '../images/caluga_vendor.jpg';
import bgImageLeasing from '../images/caluga_leasing.jpg';
import bgImageSeguros from '../images/caluga_seguros.jpg';
import arrowB from '../images/arrowB.png';
import arrowF from '../images/arrowF.png';
import segurosAsesoria from '../images/seguros-asesoria.png';
import segurosCobertura from '../images/seguros-cobertura.png';
import segurosAlianzas from '../images/seguros-alianzas.png';
import { Theme } from '@material-ui/core';
import scssTool from './scssTool';

const DashboardStyle = (theme : Theme) => ({
  segurosAsesoria: {
    minHeight: 100,
    border: 'solid 0px white',
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    backgroundSize: 'auto',
    marginLeft: '1%',
    marginRight: '1%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + segurosAsesoria + ')'
  },
  segurosCobertura: {
    minHeight: 100,
    border: 'solid 0px white',
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    backgroundSize: 'auto',
    marginLeft: '1%',
    marginRight: '1%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + segurosCobertura + ')'
  },
  segurosAlianzas: {
    minHeight: 100,
    border: 'solid 0px white',
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    backgroundSize: 'auto',
    marginLeft: '1%',
    marginRight: '1%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + segurosAlianzas + ')'
  },
  cardHeader: {
    background: FactotalTheme.palette.primary.main,
    color: '#FFF',
    margin: '10px 15px',
    fontSize: '1em',
    fontWeight: 500,
    width: '100%',
    display: 'inline',
    borderRadius: '0',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    padding: '10px 15px',
    zIndex: 1
  },
  subtitleFactoringCard: { // Subtítulo de tarjetas
    fontSize: '1em',
    lineHeight: '1em',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.grey[100],
    padding: '5px 5px'
  },
  carteraTotalNum: {
    fontSize: '2.399em',
    lineHeight: '1.875em',
    fontWeight: 'bold' as 'bold',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.primary.light,
    padding: '0px 15px',
    textAlign: 'center' as 'center'
  },
  factoringCard: {
    background: FactotalTheme.palette.grey.A100,
    padding: '16px 30px'
  },
  vigenteTotalNum: { // Se utiliza en los números de "Vigente" y "Moroso"
    fontSize: '1.888em',
    lineHeight: '1.125em',
    fontWeight: 'bold' as 'bold',
    marginTop: '16px',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.grey[300],
    padding: '0px 15px',
    textAlign: 'center' as 'center'
  },
  vigenteSubtitle: {
    fontSize: '0.788em',
    lineHeight: '0.688em',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.primary.light,
    padding: '10px 15px',
    textAlign: 'center' as 'center',
    marginTop: '15px',
    display: 'block',
    fontWeight: 'bold' as 'bold'

  },
  morosoSubtitle: {
    fontSize: '0.788em',
    lineHeight: '0.688em',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.primary.light,
    padding: '10px 15px',
    marginTop: '15px',
    textAlign: 'center' as 'center',
    display: 'block'
  },
  carteraTotalSubtitle: {
    fontSize: '0.688em',
    lineHeight: '0.688em',
    fontWeight: 'bold' as 'bold',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.grey[300],
    padding: '10px 15px',
    textAlign: 'center' as 'center',
    display: 'block'
  },
  vendorCard: { // Tarjeta de fondo para Leasing y Vendor de contratos vigentes
    background: FactotalTheme.palette.grey.A100,
    padding: '0px 0px'
  },
  subNumber: { // Número reutilizable para Vendor y Leasing
    fontSize: '1.5em',
    lineHeight: '1.5em',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.primary.main,
    padding: '10px 15px',
    textAlign: 'center' as 'center',
    display: 'block'
  }, // Tarjeta de Factoring completa
  vendorSubNumber: {
    fontSize: '1.875em',
    lineHeight: '1.875em',
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.primary.main,
    padding: '10px 15px',
    textAlign: 'center' as 'center'
  },
  optionHeader: { // Título de segundo nivel del dashboard
    fontSize: '1em',
    lineHeight: '1em',
    fontWeight: 500,
    fontFamily: ['Larsseit', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: FactotalTheme.palette.grey[50],
    margin: '10px 15px',
    marginTop: 7
  },
  cardContent: {
    minWidth: 400,
    margin: 5
  },
  card: {
    minWidth: '100%',
    minHeight: 111,
    display: 'flex'
  },
  contactCard: {
    background: FactotalTheme.palette.grey.A200,
    margin: '15px 10px',
    fontSize: '0.688em'
  },
  arrowB: {
    minHeight: 50,
    width: '6%',
    border: 'solid 0px white',
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    backgroundSize: 'auto',
    cursor: 'pointer',
    marginLeft: '1%',
    marginRight: '1%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + arrowB + ')'
  },
  arrowF: {
    minHeight: 50,
    width: '6%',
    border: 'solid 0px white',
    backgroundColor: 'white',
    position: 'relative' as 'relative',
    backgroundSize: 'auto',
    cursor: 'pointer',
    marginRight: '1%',
    marginLeft: '2%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + arrowF + ')'
  },
  bgImageFactoring: {
    minHeight: 230,
    cursor: 'pointer',
    position: 'relative' as 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + bgImageFactoring + ')'
  },
  bgImageLeasing: {
    minHeight: 230,
    cursor: 'pointer',
    position: 'relative' as 'relative',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + bgImageLeasing + ')'
  },
  bgImageSeguros: {
    minHeight: 230,
    position: 'relative' as 'relative',
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + bgImageSeguros + ')'
  },
  bgImageVendor: {
    minHeight: 392,
    position: 'relative' as 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center' as 'center',
    backgroundImage: 'url(' + bgImageVendor + ')'
  },
  innerCardNoHaveFunction: {
    backgroundColor: 'rgba(0,65,29,.35)',
    position: 'absolute' as 'absolute',
    height: '100%',
    width: '100%'
  },
  customLoader: {
    position: 'absolute' as 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center' as 'center',
    textAlign: 'center' as 'center',
    zIndex: 999,
    background: 'rgba(255,255,255,0.8)',
    loaderContent: {
      margin: 'auto'
    }
  },
  dashMonto: {
    color: 'rgb(130, 188, 0)',
    fontSize: 20,
    textAlign: 'center' as 'center'
  },
  dashMontoLess: {
    color: 'rgba(46, 42, 37, 0.54)',
    fontSize: 16,
    textAlign: 'center' as 'center'
  },
  titleCartera: {
    textAlign: 'center' as 'center',
    color: 'rgba(46, 42, 37, 0.54)',
    fontSize: 20
  },
  titleCarteraLess: {
    textAlign: 'center' as 'center',
    color: 'rgb(130, 188, 0)',
    fontSize: 15
  },
  gridCartera: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-around'
  },
  space: {
    marginTop: '32px',
    width: '100%',
    display: 'block'
  },
  operacionesCard: {
    background: '#F9F9F9',
    borderRadius: '2px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center' as 'center',
    height: '90px'
  },
  operaTitle: {
    fontWeight: 'bold' as 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '0.315px',
    color: '#000000',
    marginLeft: '8px',
    cursor: 'pointer'
  },
  operaTitleWi: {
    fontWeight: 'bold' as 'bold',
    fontSize: '18px',
    lineHeight: '25px',
    letterSpacing: '0.315px',
    color: '#000000',
    marginLeft: '16px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start'
  },
  operacionDisponble: {
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#82BC00',
    display: 'flex',
    alignItems: 'center' as 'center'
  },
  warningIcon: {
    color: '#82BC00',
    marginRight: '8px'
  },
  pointer: {
    cursor: 'pointer'
  },
  // new styles
  newElevationCard: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    marginTop: '39px',
    padding: '42px 21px 32px',
    position: 'relative' as 'relative',
  },
  carteraContainer: {
    ...scssTool.flex("flex-start", "column", "flex-start"),
    padding: '24px',
    background: '#FFFFFF',
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    height: '239px',
    boxSizing: "border-box" as "border-box"
  },
  carteraMontoContainer: {
    borderBottom: "1px solid #E1E1E1",
    width: '100%',
    marginBottom: "16px",
  },
  carteraTitle: {
    ...scssTool.font(16, 400, 16),
    fontFamily: scssTool.family.lato,
    color: "#434343",
    marginBottom: "12px",
    width: '100%',
  },
  carteraMonto: {
    ...scssTool.font(24, 800, 16),
    fontFamily: scssTool.family.lato,
    color: "#82BC00",
    margin: "6px 0px 27px",
    ...scssTool.flex("center", "row", "space-between"),
  },
  arrowFowardIcon: {
    width: '15px',
    color: FactotalTheme.palette.primary.main
  },
  carteraSubTitle: {
    ...scssTool.font(14, 400, 16),
    color: "#434343",
    fontFamily: scssTool.family.lato,
    marginBottom: "4px"
  },
  carteraTitleVigente: {
    ...scssTool.font(18, 400, 16),
    color: "#82BC00",
    fontFamily: scssTool.family.lato,
    marginBottom: "16px"
  },
  carteraTitleMoroso: {
    ...scssTool.font(18, 400, 16),
    color: "#FF645A",
    fontFamily: scssTool.family.lato
  },
  containerLitleBoxOperacion: {
    ...scssTool.flex("flex-end", "column", "center"),
    padding: '16px 4px 16px 16px'
  },
  leftContent: {
    ...scssTool.flex("flex-start", "row", "flex-start"),
    width: "100%",
    height: "100%"
  },
  agilizarColumn: {
    ...scssTool.flex("flex-start", "column", "flex-start"),
    width: "100%",
  },
  itemBoxOperacion: {
    background: "#F9F9F9",
    borderRadius: "2px",
    height: "44px",
    width: "100%",
    minWidth: "157px",
    textAlign: "left" as "left",
    ...scssTool.flex("center", "row", "flex-start"),
    marginBottom: "6px",
    "& img": {
      marginRight: "16px"
    }
  },
  itemBoxLabel: {
    ...scssTool.font(14, "bold", 16),
    color: "#000000",
    fontFamily: scssTool.family.lato,
  },
  opDisponibles: {
    width: '100%',
    height: '20px',
    ...scssTool.flex("center", "row", "center"),
    color: "#82BC00",
    ...scssTool.font(10, 500, 16),
    background: "#F5FFE0",
    "& img": {
      marginRight: "6px"
    }
  }
});

export default DashboardStyle;

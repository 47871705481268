export interface IActionType {
  PENDING : string,
  SUCCESS : string,
  FAILURE : string
}

export const asyncActionType = (prefix : string, type : string) : IActionType => ({
  PENDING: `${prefix}/${type}_PENDING`,
  SUCCESS: `${prefix}/${type}_SUCCESS`,
  FAILURE: `${prefix}/${type}_FAILURE`
});


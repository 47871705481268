import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SAcceptYieldModal } from '../enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import UploadDialog from '../../../sharedv2/UploadDialog/UploadDialog';
import DialogTitle from '../../../sharedv2/DialogTitle/DialogTitle';
import { connect } from 'react-redux';
import { eliminarXmlSubido, eliminarXmlSubiendo, subirXmlDocumentos } from './actions';
import { AppState } from '../../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';

interface OwnProps {
  onClose: () => void;
  open: boolean;
}

interface StateProps {
  archivosXmlSubiendo: Comun.Dto.Archivo[];
  archivosXmlSubidos: Comun.Dto.Archivo[];
}

interface DispatchProps {
  uploadDocument: (file: File) => Promise<any>;
  deleteUploaded: (id: string) => Promise<any>;
  deleteUploading: (id: string) => Promise<any>;
}

interface IProps extends StateProps, OwnProps, DispatchProps, WithStyles<typeof SAcceptYieldModal> {
}


interface IState {
  loading: boolean;
}

class CargaManualXml extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleClose = () => {
    this.setState({
      loading: false
    });
    this.props.onClose();
  };

  loadingToogle = () => {
    this.setState((state) => ({
      loading: !state.loading
    }));
  };

  handlerContinuar = () => {
  };

  render() {
    const { classes, onClose, open, archivosXmlSubiendo, archivosXmlSubidos } = this.props;
    const { loading } = this.state;
    const paperClass = [classes.dialogPaper, classes.rootXml].join(' ');
    return (
      <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: paperClass }}>
        <DialogTitle onClose={onClose}>Carga manual de documentos</DialogTitle>
        <UploadDialog
          dropzoneProps={{
            title: 'Arrastre los archivos en formato XML',
            subtitle: 'de sus documentos tributarios electrónicos',
            accept: '.xml',
            type: 'XML'
          }}
          archivosSubiendo={archivosXmlSubiendo}
          archivosSubidos={archivosXmlSubidos}
          onUploadDocument={this.props.uploadDocument}
          onDeleteDocument={this.props.deleteUploaded}
          onDeleteUploadingDocument={this.props.deleteUploading}
          onClose={this.handleClose}
          loading={loading}

        />
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  archivosXmlSubidos: state.operacionesSolicitud.archivosXmlSubidos,
  archivosXmlSubiendo: state.operacionesSolicitud.archivosXmlSubiendo
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>): DispatchProps => ({
  uploadDocument: (file: File) => dispatch(subirXmlDocumentos(file, undefined)),
  deleteUploaded: (id: string) => dispatch(eliminarXmlSubido(id)),
  deleteUploading: (id: string) => dispatch(eliminarXmlSubiendo(id))
});

export default withStyles(SAcceptYieldModal)(connect(mapStateToProps, mapDispatchToProps)(CargaManualXml));

import React from 'react';
import { connect } from 'react-redux';

// componentes material ui
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { SendMailToExecutive } from './actions';
import ModalComponent from '../ModalMsg/ModalMsg';
import Loader from '../Loader/Loader';
import { ISendMailToExecutiveAtencionClienteApiPayload } from '../../../services/factoringApi';
import { AppState } from '../../../config/rootReducer';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, WithStyles } from '@material-ui/core';
import scssTool from '../../../styles/scssTool';
import { withStyles } from '@material-ui/core/styles';

const negocioFormStyle = (theme : Theme) => ({
  facTitleC: {
    ...scssTool.font(16, 'bold', 24),
    color: '#000000',
    marginTop: '27px'
  },
  formContactoColumn: {
    ...scssTool.flex('flex-start', 'column', 'flex-start')
  },
  btnSolicitar: {
    background: '#94C11F',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    width: '196px',
    height: '59px',
    '&:hover': {
      background: '#94C11F'
    }
  },
  btnSolicitarLabel: {
    ...scssTool.font(16, 'bold', 24),
    color: 'white'
  },
  inputTextfield: {
    width: '100%',
    marginBottom: '12px'
  }
});


interface IProps extends RouteComponentProps, WithStyles<typeof negocioFormStyle> {
  dispatch : any,
  textoBoton : string,
  tipoNegocioForm : string,
}

interface IState {
  user : Comun.Dto.LoginUserInfo,
  nameContactNegocio : string,
  emailContactNegocio : string,
  companyContactNegocio : string,
  phoneContactNegocio : string,
  messageContactNegocio : string,
  successGenerated : boolean,
  errorGenerated : boolean,
  loading : boolean,
  openModal : boolean,
}


class NegociosForm extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    let user : Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
    this.state = {
      user: user,
      nameContactNegocio: '',
      emailContactNegocio: '',
      companyContactNegocio: '',
      phoneContactNegocio: '',
      messageContactNegocio: '',
      openModal: false,
      successGenerated: false,
      errorGenerated: false,
      loading: false
    };
  }

  componentDidMount() {
  }

  handleChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  SendMailSolicitud = async () => {
    this.setState({
      successGenerated: false,
      errorGenerated: false,
      loading: true
    });
    const params : ISendMailToExecutiveAtencionClienteApiPayload = {
      nameContactNegocio: this.state.nameContactNegocio,
      emailContactNegocio: this.state.emailContactNegocio,
      name: this.state.companyContactNegocio,
      phoneContactNegocio: this.state.phoneContactNegocio,
      messageContactNegocio: this.state.messageContactNegocio,
      typeNegocio: this.props.tipoNegocioForm,
      isContactNegocio: true
    };
    const resp = await SendMailToExecutive(params);
    if (resp) {
      this.setState({ openModal: true, loading: false });
    } else {
      this.setState({ errorGenerated: true, loading: false });
    }
  };

  render() {
    const { openModal, errorGenerated, loading } = this.state;
    const { classes } = this.props;


    return (
      <div>
        {loading ? <Loader /> : ''}
        <ModalComponent
          openModal={openModal}
          title='¡Correo enviado con éxito!'
          text=''
          closeModalMsg={() => {
            this.setState({ openModal: false });
          }} />
        {errorGenerated ?
          <ModalComponent
            openModal={errorGenerated}
            title='Ha ocurrido un error'
            text='Estimado Cliente, intentar mas tarde. Si el problema persisite, porfavor comunicarse con equipo Factotal.'
            closeModalMsg={() => {
              this.setState({ errorGenerated: false });
            }} /> : ''}

        <Grid container spacing={16}>
          <Grid item xs={12}>
            <div className={classes.facTitleC}>Formulario de contacto</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.formContactoColumn}>
              <TextField
                classes={{ root: classes.inputTextfield }}
                name='nameContactNegocio'
                label='Nombre y apellido'
                placeholder='Nombre y apellido'
                variant='outlined'
                onChange={this.handleChange}
              />
              <TextField
                classes={{ root: classes.inputTextfield }}
                name='emailContactNegocio'
                label='Correo Electrónico'
                placeholder='Correo Electrónico'
                variant='outlined'
                onChange={this.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className={classes.formContactoColumn}>
              <TextField
                classes={{ root: classes.inputTextfield }}
                name='companyContactNegocio'
                label='Empresa'
                placeholder='Empresa'
                variant='outlined'
                onChange={this.handleChange}
              />
              <TextField
                classes={{ root: classes.inputTextfield }}
                name='phoneContactNegocio'
                label='Número de teléfono'
                placeholder='Número de teléfono'
                variant='outlined'
                onChange={this.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formContactoColumn}>
              <TextField
                classes={{ root: classes.inputTextfield }}
                name='messageContactNegocio'
                label='Mensaje'
                placeholder='Mensaje'
                variant='outlined'
                multiline
                onChange={this.handleChange}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.formContactoColumn}>
              <Button classes={{ root: classes.btnSolicitar, label: classes.btnSolicitarLabel }} onClick={this.SendMailSolicitud}>
                {this.props.textoBoton}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>

    );


  }
}

const mapStateToProps = (state : AppState) => ({
  isLoading: state.main.isLoading
});

export default withStyles(negocioFormStyle)(withRouter(connect(mapStateToProps)(NegociosForm)));

const formatDate = new Intl.DateTimeFormat('es-ES', {
  hour12: true,
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
}).format;

const FormatDate = (text: string) => text ? formatDate(new Date(text)) : text;

export const formatStringDate = (stringDate: string): string => {
  if (stringDate == null) return '';
  return stringDate.split('T')[0].split('-')
                                 .reduce((acc, part) => part + (acc != '' ? '-' : '') + acc, '');
};

export const formatStringDateYMD = (stringDate: string): string => {
  if (stringDate == null) return '';
  return stringDate.split('T')[0].split('-')
                                 .reduce((acc, part) => acc + (acc != '' ? '-' : '') + part, '');
};

export const convertToDate = (date: string): Date | undefined => {
  if (date == '') return undefined;
  const dateParts = date.split('T')[0].split('-')
                                      .map(Number);
  const retValue = new Date();
  retValue.setFullYear(dateParts[0], dateParts[1] - 1, dateParts[2]);
  return retValue;
};

export const invertirFecha = (date: string, separador: string = '-'): string => {
  if (date === undefined || date === null || date === '') return date || '';
  separador = separador || '-';
  const partes = date.split(separador);
  if (partes.length !== 3) return date;
  return partes[2] + separador + partes[1] + separador + partes[0];
};

export default FormatDate;

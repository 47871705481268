import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Theme, Typography, WithStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DropzoneContainer from '../Dropzone/Dropzone';
import UploadingDocumentListItem from '../UploadingDocumentListItem/UploadingDocumentListItem';
import UploadedDocumentListItem from '../UploadedDocumentListItem/UploadedDocumentListItem';
import FormErrorText from '../FormErrorText/FormErrorText';

const style = (theme: Theme) => ({
  dialogContent: {
    paddingTop: '5px',
    overflow: 'hidden',
    height: '75%',
    overflowY: 'auto' as 'auto'
  },
  buttonUpload: {
    background: '#82BC00',
    boxShadow: '0px 2px 15px rgba(1, 213, 159, 0.401005)',
    borderRadius: '5px',
    color: 'rgb(255, 255, 255)',
    marginTop: '31px',
    padding: '8px 32px',
    '&:disabled': {
      color: 'rgb(130, 180, 0)',
      background: 'rgba(0,0,0,.1)',
      borderColor: 'rgba(130, 180, 0, .3)',
      boxShadow: '0px 2px 15px rgba(130, 180, 0, .3)'
    }
  }
});

interface IUploadDialogProps extends WithStyles<typeof style> {
  dropzoneProps: any;
  onUploadDocument: (file: File) => Promise<any>;
  onDeleteDocument?: (id: string, nombre: string) => void;
  onDeleteUploadingDocument?: (id: string) => void;
  archivosSubiendo: Comun.Dto.Archivo[];
  archivosSubidos: Comun.Dto.Archivo[];
  loading: boolean;
  onClose: () => void;
}

class UploadDialog extends React.Component<IUploadDialogProps> {
  state = {
    open: false,
    tab: 'uploading',
    fileError: false,
    manyFiles: false
  };

  onChangeTab = (event: React.ChangeEvent<any>, newValue: string) => this.setState({ tab: newValue });

  onDropFile = (acceptedFiles: File[], rejectedFiles: File[]) => {
    let pendientes = 0;
    let terminados = 0;
    let terminoUno = () => {
      terminados += 1;
      if (terminados >= pendientes)
        this.setState({
          manyFiles: false
        });
    };
    if (acceptedFiles.length) {
      pendientes = acceptedFiles.length;
      this.setState({
        tab: 'uploading',
        manyFiles: acceptedFiles.length > 30
      });
      acceptedFiles.forEach(file => {
        this.props.onUploadDocument(file)
            .then(r => terminoUno());
      });
    }
    if (rejectedFiles.length) {
      this.setState({
        fileError: true,
        manyFiles: false
      });
    }
  };

  render() {
    const { loading, classes, archivosSubiendo, archivosSubidos, onClose } = this.props;
    // console.log(archivosSubiendo);
    return (
      <React.Fragment>
        <DialogContent className={classes.dialogContent}>
          <DropzoneContainer
            title='Arrastre los archivos en formato XML'
            subtitle='de sus documentos tributarios electrónicos'
            accept='.xml'
            type='XML'
            onDrop={this.onDropFile}
            name='files'
            classes={{}}
          />
          {this.state.manyFiles ? (
            <Typography variant='body1'>Estamos procesando la carga de tus documentos, esto puede tardar
              unos
              minutos</Typography>) : ''}
          {this.state.fileError ? (
            <FormErrorText>Formato inválido, adjunte un archivo
              con extensión {this.props.dropzoneProps.type}</FormErrorText>
          ) : ''}
          <Tabs
            value={this.state.tab}
            indicatorColor='primary'
            textColor='primary'
            onChange={this.onChangeTab}
          >
            <Tab value='uploading' label='En carga' />
            <Tab value='uploaded' label={'Archivos cargados (' + archivosSubidos.length + ')'} />
          </Tabs>
          {this.state.tab === 'uploading' &&
            <div>
              {(archivosSubiendo || []).map(
                archivo => <UploadingDocumentListItem key={archivo.id} archivo={archivo}
                                                      onDelete={this.props.onDeleteUploadingDocument} />
              )}
            </div>
          }
          {this.state.tab === 'uploaded' && (
            <div>
              {archivosSubidos.length ? archivosSubidos.map(
                archivo => <UploadedDocumentListItem
                  key={archivo.id}
                  archivo={archivo}
                  onDelete={this.props.onDeleteDocument}
                />
              ) : <Typography variant='body1'>No se ha cargado ningún archivo</Typography>}
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button color='primary'
                  className={classes.buttonUpload}
                  onClick={onClose} style={{ textAlign: 'left' }} disabled={loading}>
            {loading ?
              <CircularProgress size={20} />
              : 'Aceptar'}
          </Button>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default withStyles(style)(UploadDialog);



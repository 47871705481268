import factorxApi from '../../../../services/factorxApi';
import { asyncActionType } from '../../../../helpers/ActionsHelper';
import { uid } from 'uid';
import { asyncSimpleActionType } from '../../../../helpers/SimpleActionsHelper';
import { AppState } from '../../../../config/rootReducer';
import { Dispatch } from 'redux';

export const obtenerDocumentosAction = asyncActionType('@@DOCUMENTOS_FACTORING', 'FETCH');
export const subirXmlDocumentosAction = asyncActionType('@@XML_DOCUMENTOS_FACTORING', 'UPLOAD');
export const generarSimulacionAction = asyncActionType('@@SIMULACION_FACTORING', 'GENERAR');
export const solicitarOperacionAction = asyncActionType('@@OPERACION_FACTORING', 'SOLICITAR');
export const limpiarSimulacionAction = asyncActionType('@@SIMULACION_FACTORING', 'LIMPIAR');
export const filtrosDocumentosAction = asyncActionType('@@FILTROS_DOCUMENTOS_FACTORING', 'RESET');
export const fechaVencimientoAction = asyncActionType('@@FECHA_VENC_DOCUMENTO', 'SET');
export const eliminarXmlSubidoAction = asyncActionType('@@XML_DOCUMENTOS_FACTORING', 'DELETE_UPLOADED');
export const eliminarXmlSubiendoAction = asyncActionType('@@XML_DOCUMENTOS_FACTORING', 'DELETE_UPLOADING');

export const seleccionarDocumentoAction = asyncSimpleActionType('@@DOCS_SELECCIONADOS', 'SELECT');
export const quitarDocumentoAction = asyncSimpleActionType('@@DOCS_SELECCIONADOS', 'QUITAR');
export const vaciarDocumentosAction = asyncSimpleActionType('@@DOCS_SELECCIONADOS', 'VACIAR');

const _obtenerDocumentosThunk = (filters : Filters.Documentos) => {
  return async (dispatch : Dispatch, getState : () => AppState) => {
    const state : AppState = getState();

    dispatch(obtenerDocumentos.request());
    try {
      const localState = state.operacionesSolicitud;
      return factorxApi.obtenerDocumentos(filters)
                       .then(result => dispatch(obtenerDocumentos.succeed(result)));
    } catch (error) {
      dispatch(obtenerDocumentos.failure(error));
    }
  };
};

export const obtenerDocumentos = Object.assign(_obtenerDocumentosThunk, {
  request: () => ({
    type: obtenerDocumentosAction.PENDING
  }),
  succeed: (payload : Payloads.ObtenerDocumentosExitosoPayload) => ({
    type: obtenerDocumentosAction.SUCCESS,
    payload: payload
  }),
  failure: (payload : string) => ({
    type: obtenerDocumentosAction.FAILURE,
    payload: payload
  })
});

const _generarSimulacionThunk = (payload : Payloads.GenerarSimulacionPayload) => {
  return async (dispatch : Dispatch, getState : () => AppState) => {
    return new Promise(function (resolve, reject) {

      const state = getState();

      dispatch(generarSimulacion.request());
      try {
        factorxApi.generarSimulacion(payload.ids)
                  .then((resp : Factoring.Mensaje.Respuesta.Simulacion.Generar) => {
                    if (resp.exito && resp.simulacion) {
                      dispatch(generarSimulacion.succeed({
                        simulacion: resp.simulacion
                      }));
                    } else {
                      if (resp.mensajesError) {
                        resp.mensajesError.forEach(mensaje => {
                          // dispatch(createNotification({message: mensaje, type: "error"}))
                        });
                      }
                      if (resp.mensaje) {
                        // dispatch(createNotification({message: resp.mensaje, type: "error"}))
                      }
                      dispatch(generarSimulacion.failure(resp.mensaje));
                    }
                    resolve(resp);
                  });
      } catch (error) {
        dispatch(generarSimulacion.failure(error));
        // dispatch(createNotification({message: "Error en la generación", type: "error"}));
        resolve({ exito: false, mensaje: 'Error en la generación', mensajesError: [] });
      }
    });
  };
};

const generarSimulacion = Object.assign(_generarSimulacionThunk, {
  request: () => ({
    type: generarSimulacionAction.PENDING
  }),
  succeed: (payload : Payloads.GenerarSimulacionExitoPayload) => ({
    type: generarSimulacionAction.SUCCESS,
    payload: payload
  }),
  failure: (payload : string) => ({
    type: generarSimulacionAction.FAILURE,
    payload: payload
  })
});

const _solicitarOperacionThunk = (payload : Payloads.SolicitarOperacionPayload) => (dispatch : Dispatch, getState : () => any) => {
  return new Promise(function (resolve, reject) {
    dispatch(solicitarOperacion.request());
    try {
      const state = getState();
      factorxApi.solicitarOperacion(payload)
                .then((result : Factoring.Mensaje.Respuesta.Operacion.Solicitar) => {
                  if (result.exito) {
                    dispatch(solicitarOperacion.succeed());//result.operaciones
                  } else {
                    dispatch(solicitarOperacion.failure(result.mensaje));
                    // dispatch(createNotification({message: result.mensaje, type: "error"}))
                  }
                  resolve(result);
                });

    } catch (error) {
      dispatch(solicitarOperacion.failure('error'));
      // dispatch(createNotification({message: error, type: "error"}));
      resolve(false);
    }
  });
};

export const solicitarOperacion = Object.assign(_solicitarOperacionThunk, {
  request: () => ({
    type: solicitarOperacionAction.PENDING
  }),
  succeed: () => ({
    type: solicitarOperacionAction.SUCCESS,
    payload: null
  }),
  failure: (payload : string) => ({
    type: solicitarOperacionAction.FAILURE,
    payload: payload
  })
});

const _limpiarSimulacionThunk = () => {
  return async (dispatch : Dispatch, getState : () => AppState) => {
    dispatch(limpiarSimulacion.succeed());
  };
};

export const limpiarSimulacion = Object.assign(_limpiarSimulacionThunk, {
  succeed: () => ({
    type: limpiarSimulacionAction.SUCCESS
  })
});

const _resetFiltrosDocumentosThunk = () => {
  return async (dispatch : Dispatch, getState : () => AppState) => {
    dispatch(resetFiltrosDocumentos.succeed());
  };
};

export const resetFiltrosDocumentos = Object.assign(_resetFiltrosDocumentosThunk, {
  succeed: () => ({
    type: filtrosDocumentosAction.SUCCESS
  })
});

const _actualizarFechaVencimientoThunk = (payload : Payloads.ActualizarFechaVencimientoPayload) => {
  return async (dispatch : Dispatch, getState : () => AppState) => {
    const result = await factorxApi.actualizarFechaVencimiento(payload);
    dispatch(actualizarFechaVencimiento.succeed(payload));
  };
};

export const actualizarFechaVencimiento = Object.assign(_actualizarFechaVencimientoThunk, {
  succeed: (payload : Payloads.ActualizarFechaVencimientoPayload) => ({
    type: fechaVencimientoAction.SUCCESS,
    payload: payload
  })
});

const _subirXmlDocumentosThunk = (file : File, idDocumento : number | undefined) => {
  const uploadingDocument : Comun.Dto.Archivo = {
    id: uid(),
    nombre: file.name,
    progreso: 0,
    puedeEliminar: true,
    error: '',
    fecha: undefined
  };
  return async (dispatch : Dispatch) => new Promise<Mensaje.SubirDocumentoRespuesta>(function (resolve, reject) {
    dispatch(subirXmlDocumentos.request(uploadingDocument));
    try {
      factorxApi.subirXmlDocumento(file, idDocumento)
                .then((resp) => {
                  if (resp.exito) {
                    dispatch(subirXmlDocumentos.succeed(uploadingDocument.id));
                  } else {
                    dispatch(subirXmlDocumentos.failure({
                      id: uploadingDocument.id,
                      error: resp.mensaje
                    }));
                  }
                  resolve(resp);
                });
    } catch (error) {
      dispatch(subirXmlDocumentos.failure({
        id: uploadingDocument.id,
        error
      }));
    }
  });
};

export const subirXmlDocumentos = Object.assign(_subirXmlDocumentosThunk, {
  request: (file : Comun.Dto.Archivo) => ({
    type: subirXmlDocumentosAction.PENDING,
    payload: file
  }),
  succeed: (payload : string) => ({
    type: subirXmlDocumentosAction.SUCCESS,
    payload: payload
  }),
  failure: (payload : Payloads.SubirArchivoXmlErrorPayload) => ({
    type: subirXmlDocumentosAction.FAILURE,
    payload: payload
  })
});

const _eliminarXmlSubidoThunk = (id : string) => async (dispatch : Dispatch, getState : () => AppState) => dispatch(eliminarXmlSubido.succeed(id));

export const eliminarXmlSubido = Object.assign(_eliminarXmlSubidoThunk, {
  succeed: (payload : string) => ({
    type: eliminarXmlSubidoAction.SUCCESS,
    payload: payload
  })
});

const _eliminarXmlSubiendoThunk = (id : string) => async (dispatch : Dispatch, getState : () => AppState) => dispatch(eliminarXmlSubiendo.succeed(id));

export const eliminarXmlSubiendo = Object.assign(_eliminarXmlSubiendoThunk, {
  succeed: (payload : string) => ({
    type: eliminarXmlSubiendoAction.SUCCESS,
    payload: payload
  })
});

const _seleccionarDocumentoThunk = (doc : Comun.Dto.Invoice) => async (dispatch : Dispatch, getState : () => AppState) => {
  return dispatch(seleccionarDocumento.execute(doc));
};

export const seleccionarDocumento = Object.assign(_seleccionarDocumentoThunk, {
  execute: (doc : Comun.Dto.Invoice) => ({
    type: seleccionarDocumentoAction.EXECUTE,
    payload: doc
  })
});

const _quitarDocumentoThunk = (doc : Comun.Dto.Invoice) => async (dispatch : Dispatch, getState : () => AppState) => dispatch(quitarDocumento.execute(doc));

export const quitarDocumento = Object.assign(_quitarDocumentoThunk, {
  execute: (doc : Comun.Dto.Invoice) => ({
    type: quitarDocumentoAction.EXECUTE,
    payload: doc
  })
});

const _vaciarDocumentosThunk = () => async (dispatch : Dispatch, getState : () => AppState) => dispatch(vaciarDocumentos.execute());

export const vaciarDocumentos = Object.assign(_vaciarDocumentosThunk, {
  execute: () => ({
    type: vaciarDocumentosAction.EXECUTE
  })
});

export default {
  obtenerDocumentosAction,
  generarSimulacionAction,
  limpiarSimulacionAction,
  subirXmlDocumentosAction,
  obtenerDocumentos,
  limpiarSimulacion,
  resetFiltrosDocumentos,
  actualizarFechaVencimiento,
  fechaVencimientoAction,
  subirXmlDocumentos,
  eliminarXmlSubido,
  eliminarXmlSubidoAction,
  eliminarXmlSubiendo,
  eliminarXmlSubiendoAction,
  seleccionarDocumento,
  seleccionarDocumentoAction,
  quitarDocumento,
  quitarDocumentoAction,
  vaciarDocumentos,
  vaciarDocumentosAction,
  solicitarOperacion,
  solicitarOperacionAction
};


import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SAcceptYieldModal } from '../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../../sharedv2/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ytIcon from '../../../images/icons/yt-icon.svg';
import YouTube, { Options } from 'react-youtube';
import factorxApi from '../../../services/factorxApi';
import { WithStyles } from '@material-ui/core';

// https://developers.google.com/youtube/player_parameters
// <YouTube videoId={row.contenido} opts={optsYT} onReady={this._onReadyYT} />

interface IProps extends WithStyles<typeof SAcceptYieldModal> {
  videoSelected : Dto.Portal.Tutorial | undefined,
  onClose : () => void,
  open : boolean
}

interface IState {
  ultimoVisualizado : number
}

class OpenVideoDetails extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = { ultimoVisualizado: 0 };
    this.cerrarVideo = this.cerrarVideo.bind(this);
  }

  _onReadyYT = (event : React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event);
  };

  componentDidUpdate(prevProps : IProps, prevState : IState) {
    if (this.props.videoSelected && this.state.ultimoVisualizado !== this.props.videoSelected.id) {
      this.setState({ ultimoVisualizado: this.props.videoSelected.id });
      factorxApi.registrarVisualizacionTutorial(this.props.videoSelected.id)
                .then((resp) => {
                });
    }
  }

  cerrarVideo() {
    this.props.onClose();
    this.setState({ ultimoVisualizado: 0 });
  }

  render() {
    const { classes, open, videoSelected } = this.props;
    const paperClass = [classes.dialogPaper, classes.rootYTModal].join(' ');
    const optsYT : Options = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 0,
        controls: 0,
        disablekb: 1,
        enablejsapi: 1
      }
    };

    return (videoSelected &&
      <Dialog onClose={this.cerrarVideo} className={classes.root} open={open} PaperProps={{ className: paperClass }}>
        <DialogTitle onClose={this.cerrarVideo} classes={{ root: classes.paperDialogtitle }}>
          <div className={classes.alignCenter}><img src={ytIcon}
                                                    className={classes.mRight} /><span>Video Tutorial</span></div>
        </DialogTitle>
        <DialogContent>
          <div className={classes.midleDialogContent}>
            <div className={classes.ytVideoDialog}>
              <YouTube containerClassName={classes.ytVideoPlayer} videoId={videoSelected.urlRecurso} opts={optsYT}
                       onReady={this._onReadyYT} />
            </div>
            <div className={classes.ytVideoDesc}>
              <div className={classes.ytVideoTitle}>{videoSelected.nombre}</div>
              <div className={classes.ytVideoDescSub}>{videoSelected.descripcion}</div>
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button variant="outlined" className={classes.greyButton} onClick={this.cerrarVideo}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    ) || null;
  }
}

export default withStyles(SAcceptYieldModal)(OpenVideoDetails);

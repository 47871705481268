import React, { useEffect, useMemo, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { modalStyle } from '../../factoring/operaciones/enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '../../sharedv2/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Grid, WithStyles } from '@material-ui/core';
import { handleChangeState } from '../../../helpers/PageStateHelper';
import { solicitudCertificado } from '../../../services/vendorApi';
import Loader from '../../shared/Loader/Loader';

interface IProps extends WithStyles<typeof modalStyle> {
  onClose : () => void;
  open : boolean;
  tipoSolicitud : string;
}

interface IModelo {
  mensaje : string,
  telefono : string,
  correoElectronico : string,
  necesidad : string,
}

const iniModelo = () => ({ mensaje: '', telefono: '', correoElectronico: '', necesidad: '' });

const SolicitudContacto : React.FC<IProps> = (props : IProps) => {
  const { classes, onClose, open } = props;
  const [modelo, setModelo] = useState<IModelo>(iniModelo());
  const [enviando, setEnviando] = useState(false);

  useEffect(() => {
    setModelo(iniModelo());
  }, [open]);

  const handlerEnviar = () => {
    if (modeloValido) {
      setEnviando(true);
      const request : Request.Vendor.SolicitudCertificadoEjecutivo = {
        ...modelo,
        tipoCertificado: props.tipoSolicitud
      };
      solicitudCertificado(request).then(r => {
        setEnviando(false);
        if (r) props.onClose();
      });
    }
  };

  const handleChange = (event : React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => handleChangeState(modelo, setModelo)(event);

  const modeloValido = useMemo(() => {
    return modelo.mensaje.length > 0 && modelo.telefono.length > 0 && modelo.correoElectronico.length > 0 && modelo.necesidad.length > 0;
  }, [modelo]);

  return (
    <Dialog
      onClose={onClose}
      className={classes.root}
      open={open}
      PaperProps={{ className: classes.dialogPaper }}
    >
      <DialogTitle
        onClose={onClose}
        subtitle='Envíanos tu solicitud y nos pondremos en contacto a la brevedad'
      >
        Solicitud de contacto
      </DialogTitle>
      <DialogContent style={{ padding: '0 24px 31px' }}>
        {enviando ? <Loader /> :
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <div className={classes.titleForm}>Cuéntanos cómo podemos ayudarte</div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.labelForm}>Tipo de solicitud</div>
              <input
                type='text'
                className={classes.textField}
                name='tipoSolicitud'
                disabled={true}
                value={props.tipoSolicitud}
              />
              <div className={classes.labelForm}>Teléfono móvil</div>
              <input
                type='text'
                className={classes.textField}
                name='telefono'
                placeholder='Ej. +56998177104'
                value={modelo.telefono}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.labelForm}>Necesidad</div>
              <input
                type='text'
                className={classes.textField}
                name='necesidad'
                placeholder='Ej. viaje'
                value={modelo.necesidad}
                onChange={handleChange}
              />
              <div className={classes.labelForm}>Email</div>
              <input
                type='text'
                className={classes.textField}
                name='correoElectronico'
                placeholder='Ej. mail@mail.cl'
                value={modelo.correoElectronico}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div className={classes.labelForm}>Mensaje</div>
              <textarea
                rows={5}
                className={[classes.textField, 'area'].join(' ')}
                name='mensaje'
                placeholder='En qué podemos ayudarte...'
                value={modelo.mensaje}
                onChange={handleChange}
              />
            </Grid>
          </Grid>}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button
          classes={{ root: classes.buttonEnviar, label: classes.labelButton, disabled: classes.buttonEnviarDisabled }}
          disabled={!modeloValido}
          onClick={handlerEnviar}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(modalStyle)(SolicitudContacto);

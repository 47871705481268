import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import SVGIconsStyle from "../../../styles/jss/components/SVGIconsStyle";

function SVGIcons({ ...props }) {

  const excel = (c:string, id:string) => {
    return <div className={c}><svg id={id} version="1.1" width="24" height="24" viewBox="0 0 24 24">
      <path d="M16.2,17H14.2L12,13.2L9.8,17H7.8L11,12L7.8,7H9.8L12,10.8L14.2,7H16.2L13,12M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3Z" />
    </svg></div>
  }

  const pdf = (c:string, id:string) => {
    return <div className={c}><svg id={id} version="1.1" width="24" height="24" viewBox="0 0 24 24">
      <path d="M11.43,10.94C11.2,11.68 10.87,12.47 10.42,13.34C10.22,13.72 10,14.08 9.92,14.38L10.03,14.34V14.34C11.3,13.85 12.5,13.57 13.37,13.41C13.22,13.31 13.08,13.2 12.96,13.09C12.36,12.58 11.84,11.84 11.43,10.94M17.91,14.75C17.74,14.94 17.44,15.05 17,15.05C16.24,15.05 15,14.82 14,14.31C12.28,14.5 11,14.73 9.97,15.06C9.92,15.08 9.86,15.1 9.79,15.13C8.55,17.25 7.63,18.2 6.82,18.2C6.66,18.2 6.5,18.16 6.38,18.09L5.9,17.78L5.87,17.73C5.8,17.55 5.78,17.38 5.82,17.19C5.93,16.66 6.5,15.82 7.7,15.07C7.89,14.93 8.19,14.77 8.59,14.58C8.89,14.06 9.21,13.45 9.55,12.78C10.06,11.75 10.38,10.73 10.63,9.85V9.84C10.26,8.63 10.04,7.9 10.41,6.57C10.5,6.19 10.83,5.8 11.2,5.8H11.44C11.67,5.8 11.89,5.88 12.05,6.04C12.71,6.7 12.4,8.31 12.07,9.64C12.05,9.7 12.04,9.75 12.03,9.78C12.43,10.91 13,11.82 13.63,12.34C13.89,12.54 14.18,12.74 14.5,12.92C14.95,12.87 15.38,12.85 15.79,12.85C17.03,12.85 17.78,13.07 18.07,13.54C18.17,13.7 18.22,13.89 18.19,14.09C18.18,14.34 18.09,14.57 17.91,14.75M19,3H5C3.89,3 3,3.89 3,5V19C3,20.1 3.9,21 5,21H19C20.1,21 21,20.1 21,19V5C21,3.89 20.1,3 19,3M17.5,14.04C17.4,13.94 17,13.69 15.6,13.69C15.53,13.69 15.46,13.69 15.37,13.79C16.1,14.11 16.81,14.3 17.27,14.3C17.34,14.3 17.4,14.29 17.46,14.28H17.5C17.55,14.26 17.58,14.25 17.59,14.15C17.57,14.12 17.55,14.08 17.5,14.04M8.33,15.5C8.12,15.62 7.95,15.73 7.85,15.81C7.14,16.46 6.69,17.12 6.64,17.5C7.09,17.35 7.68,16.69 8.33,15.5M11.35,8.59L11.4,8.55C11.47,8.23 11.5,7.95 11.56,7.73L11.59,7.57C11.69,7 11.67,6.71 11.5,6.47L11.35,6.42C11.33,6.45 11.3,6.5 11.28,6.54C11.11,6.96 11.12,7.69 11.35,8.59Z" />
    </svg></div>
  }

  const cesionIcon = (c:string) => {
    return <div className={c}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 50.792">
        <g id="Group_26" data-name="Group 26" transform="translate(0.026)">
          <g id="Layer_5_1_" transform="translate(-0.026)">
            <g id="Group_25" data-name="Group 25">
              <g id="Group_23" data-name="Group 23" transform="translate(27.34)">
                <path id="Path_218" data-name="Path 218" d="M21.774,10.02C19.955,9.338,19.5,8.883,19.5,8.2s.455-1.364,1.819-1.364a5.066,5.066,0,0,1,2.046.455h.227a.489.489,0,0,0,.455-.455l.227-.909a.835.835,0,0,0-.227-.682c-.455,0-1.819-.227-2.046-.227h0V3.882a.6.6,0,0,0-.682-.682h-.455a.6.6,0,0,0-.682.682V5.019a.223.223,0,0,1-.227.227,3.223,3.223,0,0,0-2.728,3.41c0,2.046,1.591,2.955,3.41,3.637q2.046.682,2.046,2.046a1.837,1.837,0,0,1-2.046,1.591,12.064,12.064,0,0,1-2.5-.682h-.227c-.227,0-.455.227-.682.455L17,16.158a.835.835,0,0,0,.227.682,10.342,10.342,0,0,0,2.5.682.223.223,0,0,1,.227.227v.909a.6.6,0,0,0,.682.682h.682A.6.6,0,0,0,22,18.659V17.295a3.593,3.593,0,0,0,2.955-3.41C24.957,12.066,24.047,10.93,21.774,10.02Z" transform="translate(-5.633 4.075)" />
                <path id="Path_219" data-name="Path 219" d="M27.459,0a15.233,15.233,0,1,0,15,15.232A14.9,14.9,0,0,0,27.459,0Zm0,27.053A11.741,11.741,0,0,1,15.637,15.232a11.822,11.822,0,0,1,23.643,0A11.886,11.886,0,0,1,27.459,27.053Z" transform="translate(-12)" />
              </g>
              <g id="Group_24" data-name="Group 24" transform="translate(0 32.055)">
                <path id="Path_220" data-name="Path 220" d="M46,20.238A37.149,37.149,0,0,1,33.726,24.33c-4.092.682-11.367-.909-11.367-2.728,0-.682,6.593,1.591,10.458.682,2.955-.909,2.5-3.637.909-4.092S22.359,14.327,18.267,14.1a66.231,66.231,0,0,0-9.094,1.137c-.682.227-1.364.227-1.364.909C7.582,19.783,7.127,26.831,6.9,30.7c0,0,0,1.137.909.455.909-.909,2.273-1.819,3.637-1.364,2.046.455,19.1,3.637,21.37,2.955,3.41-.682,12.958-6.365,14.777-8.184C50.776,21.6,48.5,18.874,46,20.238Z" transform="translate(8.845 -14.1)" />
                <path id="Path_221" data-name="Path 221" d="M12.309,14.755,3.443,14.3a1.71,1.71,0,0,0-1.591,1.137L.033,30.214c-.227.682.227,1.137.909,1.364h10a1.273,1.273,0,0,0,1.364-1.137l1.137-14.322A1.441,1.441,0,0,0,12.309,14.755ZM6.853,29.532A2.148,2.148,0,0,1,4.58,27.258a2.5,2.5,0,0,1,5,0A2.7,2.7,0,0,1,6.853,29.532Z" transform="translate(0.026 -13.845)" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  }

  const doneIcon = (c:string) => {
    return <div className={c}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z" /><path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" /></svg>
    </div>
  }

  let returnContent = () => {
    let contentSVG = <div/>;
    const { icon, classes, id } = props;
    switch (icon) {
      case "excel":
        contentSVG = excel(classes.ExcelButton, id);
        break;
      case "pdf":
        contentSVG = pdf(classes.PDFButton, id);
        break;
      case "cesionIcon":
        contentSVG = cesionIcon(classes.CesionIcon);
        break;
      case "done":
        contentSVG = doneIcon(classes.DoneIcon);
        break;
      default:
        contentSVG = <div/>;
    }
    return contentSVG;
  }

  return (
    returnContent()
  );
}

export default withStyles(SVGIconsStyle)(SVGIcons);

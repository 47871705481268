import loginState from './state';
import { fetchAction } from './actions';
import { AnyAction } from 'redux';

export const reducer = (state = loginState, action : AnyAction) => {
  switch (action.type) {
    case fetchAction.PENDING: {
      return Object.assign({}, state, action);
    }

    case fetchAction.SUCCESS: {
      return Object.assign({}, state, action);
    }

    // case fetchAction.ERROR: {
    //   return Object.assign({}, state, action);
    // }
    //
    // case fetchAction.CLEAR: {
    //   return Object.assign({}, state, action);
    // }

    default:
      return state;
  }
};

import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// componentes material ui
import Grid from '@material-ui/core/Grid';

import DashboardTheme from '../../styles/dashboardStyle';
import ListItem from '@material-ui/core/ListItem';

class Seguros extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <div>
        <div id='seguros-wrap'>
          <div id='bajada' className='ico'>
            <h3>FT Corredora de Seguros</h3>
          </div>
          <div id='textos' className='clearfix'>
            <p>Sabemos que hay situaciones que no se pueden anticipar y que tu patrimonio es tu prioridad, por eso te
              entregamos asesoría para la protección que necesitas con una amplia gama de seguros. Cotiza tu seguro
              general, de vida y salud, y/o garantía y crédito con nosotros.</p>
            <h3>Beneficios y Ventajas de cotizar con nosotros:</h3>
            <Grid container>
              <Grid item xs={4}>
                <div className='column_tres'>
                  <div style={DashboardTheme.segurosAsesoria} alt='FT Corredores de Seguros' />
                  <h3>Asesoría Profesional</h3>
                  <p>Analizamos y diagnosticamos caso a caso identificando infraseguros, sobreseguros, riesgos no
                    cubiertos y buscamos optimizar costos mejorando la administración de la cartera de seguros. </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className='column_tres'>
                  <div style={DashboardTheme.segurosCobertura} alt='FT Corredores de Seguros' />
                  <h3>Cobertura en terreno a nivel nacional</h3>
                  <p>Contamos con 22 sucursales a lo largo de Chile. </p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className='column_tres'>
                  <div style={DashboardTheme.segurosAlianzas} alt='FT Corredores de Seguros' />
                  <h3>Alianzas estratégicas con compañías de seguros</h3>
                  <p>Tenemos alianzas con las mejores compañías de seguros y disponibilizamos variadas opciones para la
                    toma de decisiones. </p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className='dv-contact-Negocio-box greenFactotal'>
            <a href='/negocio-form/Seguros' style={{textDecoration: 'none'}}>
              <ListItem>
                <Link style={{textDecoration: 'none', marginTop: 5}}
                      to={{
                        pathname: '/negocio-form/Seguros'
                      }}
                >
                  Quiero que me contacten
                </Link>
              </ListItem>
            </a>
          </div>
        </div>
      </div>

    );


  }
}

const mapStateToProps = (state) => ({
  isLoading: state.main.isLoading
});

export default connect(mapStateToProps)(Seguros);

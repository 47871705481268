import { Button, Select, TextField } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useMemo, useState } from 'react';
import contratosGeneralStyle from '../../contratosGeneralStyle';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {COLORES, VEHICULOS_TRANSFERENCIA} from '../Selectores';
import { TModeloIT, TModeloVehiculo } from '../../Contrato/transferencia';

interface IProps extends WithStyles<typeof contratosGeneralStyle> {
  modelo: TModeloVehiculo;
  modeloIT: TModeloIT;
  setState: (modelo: keyof TModeloVehiculo, value: string) => void;
  setStateIT: (modelo: keyof TModeloIT, value: string) => void;
  color: string;
  setColor: (color: string) => void;
}

const TransferenciaVehiculo: FC<IProps> = (props) => {
  const { classes, modelo, modeloIT, setState, setStateIT, color, setColor } = props;
  const [open, setOpen] = useState(false);
  const years = () => Array.from({ length: 8 }, (el, index) => new Date().getFullYear() - index + 1);

  const correspondeImpuestoTransferencia = true;

  const resolverColor=(_idColor: string)=>{
    const selectedColor= COLORES.find(a=>a.key===_idColor.split(';')[1]);
    // @ts-ignore
    setColor(selectedColor.id+';'+selectedColor.key);
    // @ts-ignore
    return selectedColor.id+';'+selectedColor.key
  }

  return (
    <div className={classes.greySeccion}>
      <div className={classes.seccionHeader}>
        <div className={classes.leftSeccionHeader}>
          <div className={classes.titleSeccionHeader}>Vehículo</div>
          <div className={classes.subtSeccionHeader}>
            Ingrese la información relativa al vehículo
          </div>
        </div>
        <div className={classes.openButton} onClick={() => setOpen(!open)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={classes.containerSeccion}>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Tipo Vehículo</div>
            <Select
              value={modelo.tipoVehiculo}
              onChange={(e) => setState('tipoVehiculo', e.target.value)}
              className={classes.selectField}
              classes={{
                outlined: classes.outlinesSelect
              }}
              displayEmpty={true}
              variant='outlined'
            >
              <MenuItem value={''} selected>Seleccione Vehículo</MenuItem>
              {VEHICULOS_TRANSFERENCIA.map((x, i) => (<MenuItem value={x.id}>{x.texto}</MenuItem>))}
            </Select>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Marca</div>
              <TextField
                value={modelo.marca}
                onChange={(e) => setState('marca', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='MITSUBISHI'
              />
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Modelo</div>
              <TextField
                value={modelo.modelo}
                onChange={(e) => setState('modelo', e.target.value)}
                className={[classes.textField, classes.midleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='IMPREZA'
              />
            </div>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Año Fabricación</div>
              <Select
                value={modelo.anoFabricacion}
                onChange={(e) => setState('anoFabricacion', e.target.value)}
                className={[classes.selectField, classes.litleSelect].join(' ')}
                classes={{
                  outlined: classes.outlinesSelect
                }}
                displayEmpty={true}
                variant='outlined'
              >
                {years().map((x, i) => (<MenuItem value={x}>{x}</MenuItem>))}
              </Select>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>PPU</div>
              <TextField
                value={modelo.ppu}
                onChange={(e) => setState('ppu', e.target.value)}
                className={[classes.textField, classes.litleInput].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='AB0000-0'
              />
            </div>
          </div>

          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Color</div>
            <Select
              value={color}
              /*onChange={(e) => setColor(e.target.value)}*/
              onChange={(e) => setState('color', resolverColor(e.target.value))}
              className={[classes.selectField].join(' ')}
              classes={{
                outlined: classes.outlinesSelect
              }}
              displayEmpty={true}
              variant='outlined'
            >
              {COLORES.map((v, i) => (<MenuItem value={v.id+';'+v.key}>{v.texto}</MenuItem>))}
            </Select>
            {/*<TextField
              value={modelo.color}
              onChange={(e) => setState('color', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='Blanco'
            />*/}
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número Motor</div>
            <TextField
              value={modelo.nroMotor}
              onChange={(e) => setState('nroMotor', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número Chasis</div>
            <TextField
              value={modelo.chasis}
              onChange={(e) => setState('chasis', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número Serie</div>
            <TextField
              value={modelo.nroSerie}
              onChange={(e) => setState('nroSerie', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número VIN</div>
            <TextField
              value={modelo.nroVin}
              onChange={(e) => setState('nroVin', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123123123123123123'
            />
          </div>
          {correspondeImpuestoTransferencia && <>
            <div className={classes.titleSeccionHeader}>
              Impuesto de transferencia
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>CID</div>
              <TextField
                value={modeloIT.cid}
                onChange={(e) => setStateIT('cid', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
              />
            </div>

            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Monto</div>
              <TextField
                value={modeloIT.montoPagado}
                onChange={(e) => setStateIT('montoPagado', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123123123123123123'
              />
            </div>
          </>}
        </div>
      </Collapse>
    </div>
  );
};

export default withStyles(contratosGeneralStyle)(TransferenciaVehiculo);

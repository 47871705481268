import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../style';
import Dialog from '@material-ui/core/Dialog';
import Done from '@material-ui/icons/Done';
import ArrowRight from '@material-ui/icons/ArrowRight';
import CircularProgress from '@material-ui/core/CircularProgress';
import { WithStyles } from '@material-ui/core';

interface IAcceptYieldModalProps extends WithStyles<typeof SAcceptYieldModal> {
  onClose: () => void;
  onAccept: () => void;
  open: boolean;
}

interface IState {
  loading: boolean;
}

class AcceptYieldModal extends React.Component<IAcceptYieldModalProps, IState> {

  constructor(props: IAcceptYieldModalProps) {
    super(props);

    this.state = {
      loading: false
    };

  }

  handleClose = () => {
    this.setState((state) => ({ loading: false }));
    this.props.onClose();
  };

  loadingToogle = () => {
    this.setState((state) => ({
      loading: !state.loading
    }));
  };
  handlerContinuar = () => {
    this.loadingToogle();
    this.props.onAccept();
  };

  render() {
    const { classes, open } = this.props;
    const { loading } = this.state;

    return (
      <Dialog onClose={this.handleClose} className={classes.root} open={open}
              PaperProps={{ className: classes.dialogPaper }}>
        <div className={classes.checkIcon}><Done /></div>
        <h1>Operación Enviada a Ceder</h1>
        <p><ArrowRight />Esta solicitud queda sujeta a aprobación del Servicio de Impuestos Internos</p>
        <p><ArrowRight />Una vez autorizada le enviaremos una notificación</p>
        <div className={classes.containerButtons}>
          <p>¿Confirma envío de solicitud?</p>
          <Button onClick={() => this.handlerContinuar()} disabled={loading}>
            {loading ?
              <CircularProgress size={20} />
              : 'Continuar'}
          </Button>
          <Button className={classes.secondaryButton} onClick={() => this.handleClose()}>Cancelar</Button>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(AcceptYieldModal);

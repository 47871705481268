import * as React from 'react';
import '../../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SAcceptYieldModal } from '../enProceso/style';
import Dialog from '@material-ui/core/Dialog';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import ArrowRight from '@material-ui/icons/ArrowRight';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { formatNumber } from '../../../../helpers/FormatNumber';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof SAcceptYieldModal> {
  onClose : () => void,
  open : boolean,
  documentos : Factoring.Dto.Documento.Seleccionable[]
}

interface IState {
  loading : boolean
}

class ErrorEnSolicitudOperacion extends React.Component<IProps, IState> {

  constructor(props : IProps) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { classes, open, documentos } = this.props;

    return (
      <Dialog onClose={this.handleClose} className={classes.root} open={open}
              PaperProps={{ className: [classes.dialogPaper, classes.dialogConfirm, classes.dialogPaperWarning].join(' ') }}>
        <div className={[classes.checkIcon, classes.checkIconWarning].join(' ')}><ErrorOutline /></div>
        <DialogContent className={classes.dialogContent}>
          <h1>Hemos modificado tu solicitud</h1>
          <p><ArrowRight />Hemos eliminado los siguientes documentos debido a que fueron incluidos en una operación
            anterior.</p>
          <Table className={classes.TableFTC} aria-label="simple table">
            <TableHead className={classes.HeaderTableFCT}>
              <TableRow className={classes.TableRowFCT}>
                <TableCell className={classes.TableThFCT}>Nº documento</TableCell>
                <TableCell align="right" className={classes.TableThFCT}>Tipo Documento</TableCell>
                <TableCell align="right" className={classes.TableThFCT}>Monto</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documentos.map((row) => (
                <TableRow key={row.folio} className={classes.TableRowBodyFCT}>
                  <TableCell scope="row" className={classes.TableCellFCT}>{row.folio}</TableCell>
                  <TableCell align="right" className={classes.TableCellFCT}>{row.tipoDoc.descripcion}</TableCell>
                  <TableCell align="right" className={classes.TableCellFCT}>{'$' + formatNumber(row.monto)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <div className={classes.containerButtons}>
            <Button className={classes.outlinePrimeryButton} onClick={() => this.handleClose()}>Cerrar</Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(SAcceptYieldModal)(ErrorEnSolicitudOperacion);

import {
  Button,
  FormControl,
  IconButton,
  Select,
  Switch,
  TextField,
  Theme
} from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import contratosGeneralStyle from '../../contratosGeneralStyle';
import {  COMUNAS, MONEDAS } from '../Selectores';
import { TModeloCompraVenta } from '../../Contrato/primeraInscripcion';
import Datetime from "react-datetime";
import moment from "moment";

interface IProps extends WithStyles<typeof contratosGeneralStyle> {
  modelo: TModeloCompraVenta;
  setState: (modelo: keyof TModeloCompraVenta, value: string) => void;
}

const DocumentoCompraVenta: FC<IProps> = (props) => {
  const { classes, modelo, setState } = props;
  const [open, setOpen] = useState(false);
  //let dat = new Date(modelo.fecha);
  //const [fechaFactura, setFechaFactura]= useState(new Date(modelo.fecha));

  /*const setFechaDocumento=(fecha:any)=>{
    let dt = new Date(fecha);
    setFechaFactura(dt);
    setState('fecha', dt.toUTCString());
  }*/

  return (
    <div className={classes.greySeccion}>
      <div className={classes.seccionHeader}>
        <div className={classes.leftSeccionHeader}>
          <div className={classes.titleSeccionHeader}>Documento compra venta</div>
          <div className={classes.subtSeccionHeader}>
            Ingrese la información del documento que sustenta la inscripción
          </div>
        </div>
        <div className={classes.openButton} onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</div>
      </div>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <div className={classes.containerSeccion}>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Tipo documento</div>
            <Select
              value={'0'}
              className={classes.selectField}
              classes={{
                outlined: classes.outlinesSelect
              }}
              displayEmpty={true}
              variant='outlined'
            >
              <MenuItem value={'0'}>Factura electrónica</MenuItem>
            </Select>
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>RUT Emisor</div>
            <TextField
              value={modelo.rutEmisor}
              onChange={(e) => setState('rutEmisor', e.target.value)}
              className={[classes.textField, classes.midleInput].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='99666555-7'
              inputProps={{className: classes.inputError, pattern:"^(\\d{1,2}(?:[\\.]?\\d{3}){2}-[\\dkK])$"}}
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Emisor</div>
            <TextField
              value={modelo.emisor}
              onChange={(e) => setState('emisor', e.target.value)}
              className={[classes.textField].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='Razón social'
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Fecha factura</div>
            <TextField
              value={modelo.fecha}
              onChange={(e) => setState('fecha', e.target.value)}
              className={[classes.textField, classes.midleInput].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='DD-MM-AAAA'
              inputProps={{className: classes.inputError, pattern:"^(?:(?:31(\\/|-|\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\/|-|\\.)(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\/|-|\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\/|-|\\.)(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$"}}
            />
            {/*<Datetime value={fechaFactura}
                      onChange={(e) => setFechaDocumento(e)}
                      timeFormat={false}
                      className={[classes.textField, classes.midleInput].join(' ')}
                      closeOnSelect={true}
            ></Datetime>*/}
          </div>
          {/*<div className={classes.inputContainer}>*/}
          {/*  <div className={classes.labelnputField}>Naturaleza</div>*/}
          {/*  <Select*/}
          {/*    value={'cv'}*/}
          {/*    className={[classes.selectField, classes.middleSelect].join(' ')}*/}
          {/*    classes={{*/}
          {/*      outlined: classes.outlinesSelect*/}
          {/*    }}*/}
          {/*    displayEmpty={true}*/}
          {/*    variant='outlined'*/}
          {/*  >*/}
          {/*    <MenuItem value={'cv'}>Compraventa</MenuItem>*/}
          {/*    <MenuItem value={'adjudicacion'}>Adjudicación</MenuItem>*/}
          {/*    <MenuItem value={'transferencia'}>Transferencia</MenuItem>*/}
          {/*    <MenuItem value={'temate'}>Remate</MenuItem>*/}
          {/*  </Select>*/}
          {/*</div>*/}
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Número</div>
            <TextField
              value={modelo.folioFactura}
              onChange={(e) => setState('folioFactura', e.target.value)}
              className={[classes.textField, classes.midleInput].join(' ')}
              margin='normal'
              variant='outlined'
              placeholder='123456'
              inputProps={{className: classes.inputError, pattern:"^[0-9]*$"}}
            />
          </div>
          <div className={classes.inputContainer}>
            <div className={classes.labelnputField}>Lugar</div>
            <Select
              value={modelo.idComunaLugar.toString()}
              onChange={(e) => setState('idComunaLugar', e.target.value)}
              className={[classes.selectField].join(' ')}
              classes={{ outlined: classes.outlinesSelect }}
              displayEmpty={true}
              variant='outlined'
            >
              {COMUNAS.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
            </Select>
          </div>
          <div className={[classes.containerInputToRight].join(' ')}>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Moneda</div>
              <Select
                value={modelo.tipoMoneda}
                onChange={(e) => setState('tipoMoneda', e.target.value)}
                className={[classes.selectField, classes.midleInput].join(' ')}
                classes={{ outlined: classes.outlinesSelect }}
                displayEmpty={true}
                variant='outlined'
              >
                {MONEDAS.map((v, i) => (<MenuItem key={i} value={v.id}>{v.texto}</MenuItem>))}
              </Select>
            </div>
            <div className={classes.inputContainer}>
              <div className={classes.labelnputField}>Monto factura</div>
              <TextField
                value={modelo.montoBrutoTotal}
                onChange={(e) => setState('montoBrutoTotal', e.target.value)}
                className={[classes.textField].join(' ')}
                margin='normal'
                variant='outlined'
                placeholder='123456789765434567876543'
                inputProps={{className: classes.inputError, pattern:"^[0-9]*\\.?[0-9]*$"}}
              />
            </div>
          </div>
          {/*<div className={classes.inputContainer}>*/}
          {/*  <div className={classes.labelnputField}>Kilometraje</div>*/}
          {/*  <TextField*/}
          {/*    id='kilometraje'*/}
          {/*    className={[classes.textField, classes.midleInput].join(' ')}*/}
          {/*    margin='normal'*/}
          {/*    variant='outlined'*/}
          {/*    placeholder='100000'*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={classes.containerInputToRight}>*/}
          {/*  <div className={classes.labelnputField}>Notaria</div>*/}
          {/*  <Switch*/}
          {/*    checked={kilometraje}*/}
          {/*    onChange={() => setKilometraje(!kilometraje)}*/}
          {/*    name='checkedA'*/}
          {/*    inputProps={{ 'aria-label': 'secondary checkbox' }}*/}
          {/*  />*/}
          {/*</div>*/}
          {/*<div className={classes.inputContainer}>*/}
          {/*  <div className={classes.labelnputField}>Código Notaria</div>*/}
          {/*  <Select*/}
          {/*    id='codigo'*/}
          {/*    value={'Peso'}*/}
          {/*    className={[classes.selectField, classes.litleInput].join(' ')}*/}
          {/*    classes={{*/}
          {/*      outlined: classes.outlinesSelect*/}
          {/*    }}*/}
          {/*    displayEmpty={true}*/}
          {/*    variant='outlined'*/}
          {/*  >*/}
          {/*    {CODIGO_NOTARIA.map((v, i) => (*/}
          {/*      <MenuItem key={i} value={v}>{v}</MenuItem>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</div>*/}
        </div>
      </Collapse>
    </div>
  );
};

export default withStyles(contratosGeneralStyle)(DocumentoCompraVenta);

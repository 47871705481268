import React, { FC } from "react";
import { CircularProgress, WithStyles } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import scssTool from "../../../styles/scssTool";

const loaderCompStyle = (theme: Theme) => ({
  loaderContainer: {
    ...scssTool.flex("center", "row","center")
  }
});

interface ILoaderComp extends WithStyles<typeof loaderCompStyle> {}

const LoaderComp: FC<ILoaderComp> = (props) => {
  const { classes } = props;
  return (
    <div className={classes.loaderContainer}>
      <CircularProgress />
    </div>
  );
};

export default withStyles(loaderCompStyle)(LoaderComp);

import { Button, CardContent, Grid, Tab, Tabs, withStyles, WithStyles } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../config/rootReducer';
import FormularioSiniestro from './Components/FormularioSiniestro';
import PlazosContainer from './Components/PlazosContainer';
import { styleVendor } from './style';
import SolicitudContacto from './Components/SolicitudContacto';
import { getResumenCliente } from '../../services/vendorApi';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import actions from '../main/reducer';
import routes from '../../config/routes';
import { Link } from 'react-router-dom';
import EjecutivoContainer from '../factoring/components/EjecutivoContainer';
import BorderSeparator from '../sharedv2/BorderSeparator/BorderSeparator';
import LoaderComp from '../sharedv2/Loader/LoaderComp';

interface StateProps {
}

interface IProps extends StateProps, WithStyles<typeof styleVendor> {
  notificacion : (exito : boolean, mensaje : string) => void
}

const Vendor : FC<IProps> = (props) => {
  const { classes } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [siniestrosForm, setSiniestrosForm] = useState(false);
  const [openSolicitudContacto, setOpenSolicitudContacto] = useState(false);
  const [tipoSolicitud, setTipoSolicitud] = useState('');
  const [loading, setLoading] = useState(true);
  const [resumenCliente, setResumenCliente] = useState<Dto.Vendor.ResumenCliente | undefined>(undefined);

  useEffect(() => {
    getResumenCliente().then((resp : Dto.Vendor.ResumenCliente) => {
      setResumenCliente(resp);
      setLoading(false);
    });
  }, []);

  const onChangeTab = (event : React.ChangeEvent<any>, value : number) => {
    setActiveTab(value);
  };

  const onFormularioSiniestro = () => {
    setSiniestrosForm(() => !siniestrosForm);
  };

  const abrirModalSolicitud = (tipo : string) => {
    setTipoSolicitud(tipo);
    setOpenSolicitudContacto(true);
  };

  return (
    <React.Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12} md={12}>
          {loading ? <LoaderComp /> :
            <CardContent>
              {!siniestrosForm && resumenCliente && (
                <Grid container spacing={16}>
                  <Grid item xs={12} md={6}>
                    <div className={classes.contratosVigentesContainer}>
                      <Link
                        to={routes.vendor.contratos}
                        className={classes.contratosVigentesNumero}
                      >
                        {resumenCliente.cantidadContratosVigentes}
                      </Link>
                      <Link
                        to={routes.vendor.contratos}
                        className={classes.contratosVigentesTitle}
                      >
                        Contratos
                        <br />
                        Vigentes
                      </Link>
                      <Button
                        classes={{
                          root: classes.otroBtnRequerimientos,
                          label: classes.otroBtnRequerimientosLabel
                        }}
                        onClick={() => abrirModalSolicitud('Solicitud de Contacto')}
                      >
                        Solicita un nuevo contrato
                      </Button>
                    </div>
                    <div className={classes.otrosReqCon}>
                      <br />
                      <br />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className={classes.leftContainer}>
                      <BorderSeparator />
                      <EjecutivoContainer
                        titulo='Ejecutivo'
                        nombre={resumenCliente.nombreEjecutivo}
                        email={resumenCliente.correoEjecutivo}
                        telefono={resumenCliente.telefonoEjecutivo}
                        direccion={resumenCliente.direccionEjecutivo}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Tabs
                      value={activeTab}
                      onChange={onChangeTab}
                      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
                    >
                      <Tab
                        label='INFORMA TU SINIESTRO'
                        classes={{
                          root: classes.tabRoot,
                          selected: classes.tabsSelected
                        }}
                      />
                    </Tabs>
                    {activeTab === 0 && (
                      <div className={classes.tabContent}>
                        <div className={classes.tabContentTitle}>
                          ¿Cuáles son los pasos a seguir en caso de siniestro?
                        </div>
                        <Grid container>
                          <Grid item xs={12} md={7}>
                            <div className={classes.pasosSeguirContainer}>
                              <div className={classes.itemPasosASeguir}>
                                <div className={classes.pasosNumero}>1</div>
                                <div className={classes.textPasos}>
                                  Llama al <strong>600 411 1000</strong>
                                </div>
                              </div>
                              <div className={classes.itemPasosASeguir}>
                                <div className={classes.pasosNumero}>2</div>
                                <div className={classes.textPasos}>
                                  Completa el siguiente{' '}
                                  <span className={classes.underline} onClick={onFormularioSiniestro}>
                                  Formulario de denuncia siniestro
                                </span>
                                </div>
                              </div>
                              <div className={classes.itemPasosASeguir}>
                                <div className={classes.pasosNumero}>3</div>
                                <div className={classes.textPasos}>
                                  Adjunta los documentos solicitados: Cedula identidad.
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <PlazosContainer />
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
              {siniestrosForm && (
                <div className={classes.tabContent}>
                  <div className={classes.tabContentBigTitle}>Formulario de denuncia siniestro</div>
                  <FormularioSiniestro close={() => setSiniestrosForm(false)} notificacion={props.notificacion} />
                </div>
              )}
            </CardContent>}
        </Grid>
      </Grid>
      <SolicitudContacto
        open={openSolicitudContacto}
        onClose={() => setOpenSolicitudContacto(!openSolicitudContacto)}
        tipoSolicitud={tipoSolicitud}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state : AppState) : StateProps => ({});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, null, AnyAction>) => ({
  notificacion: (exito : boolean, mensaje : string) => dispatch(actions.openSnackBar(exito, mensaje))
});

export default withStyles(styleVendor)(connect(mapStateToProps, mapDispatchToProps)(Vendor));

export const esEjecutivoAsistente = (user: Comun.Dto.LoginUserInfo): boolean => {
  return user !== undefined && user.empresas.length === 1 && [2, 3].findIndex(d => d === user.empresas[0].executiveType) >= 0;
  /*
  1: Empresa
  2: Ejecutivo
  3: Asistente
  4: Marketing
   */
};

export const esEjecutivoRegistroCivil = (user: Comun.Dto.LoginUserInfo | undefined): boolean => {
  return user !== undefined && [2].findIndex(d => d === user.empresas[0].executiveType && user.empresas[0].registroCivil) >= 0;
  /*
  1: Externo (cliente)
  2: Interno
  3: Marketing
  4: Admin empresas
  5: Registro civil
   */
};

export const puedeEntrarSitioAdmin = (user: Comun.Dto.LoginUserInfo | undefined): boolean => {
  return user !== undefined && (user.perfiles.findIndex(d => d === 4) >= 0 || user.perfiles.findIndex(d => d === 5) >= 0);
  /*
  1: Externo (cliente)
  2: Interno
  3: Marketing
  4: Admin empresas
  5: Registro civil
   */
};


export const puedeEntrarSitioAdminDirecto = (user: Comun.Dto.LoginUserInfo | undefined): boolean => {
  return user !== undefined && user.empresas.length === 0 && (user.perfiles.findIndex(d => d === 4) >= 0 || user.perfiles.findIndex(d => d === 5) >= 0);
  /*
  1: Externo (cliente)
  2: Interno
  3: Marketing
  4: Admin empresas
  5: Admin registro civil
   */
};


const formatDateTime = new Intl.DateTimeFormat("es-ES", {
  hour12: false,
  minute: "2-digit",
  hour: "2-digit",
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
}).format;

const FormatDateTime = (text:string) => text ? formatDateTime(new Date(text)) : text

export default FormatDateTime

export const formatNumber = (amount : string | number, decimalCount : number = 0, decimal : string = ',', thousands : string = '.') => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const numberAmount = Number(amount);
    const negativeSign = numberAmount < 0 ? '-' : '';

    let i = parseInt(amount = Math.abs(numberAmount || 0)
                                  .toFixed(decimalCount))
      .toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ?
      i.substr(0, j) + thousands : '') + i.substr(j)
                                          .replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount ?
        decimal + Math.abs(numberAmount - Number(i))
                      .toFixed(decimalCount)
                      .slice(2) :
        '');
  } catch (e) {
  }
};

import React, { FC, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import modalMsgStyle from '../../../styles/jss/components/modalMsgStyle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { WithStyles } from '@material-ui/core';

interface IModalSeleccionRutsSincronizacionProps extends WithStyles<typeof modalMsgStyle> {
  openModal : boolean,
  closeModal : (continua : boolean, rutEmpresa : string) => void
}

const ModalSeleccionRutsSincronizacion : FC<IModalSeleccionRutsSincronizacionProps> = (props : IModalSeleccionRutsSincronizacionProps) => {
  const { classes, openModal, closeModal } = props;

  const [rutEmpresa, setRutEmpresa] = useState('');

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
    >
      <div className={classes.modalPaper}>
        <div className={classes.modalContent}>
          <Grid container>
            <Grid item xs={12}>
              <br />
              <Typography className={classes.modalContentTitle} variant="h6" id="modal-title"
                          style={{ lineHeight: 'normal' }}>
                Ingrese rut a sincronizar (vacío para sincronizar todos)
              </Typography>

            </Grid>
            <Grid item xs={12} style={{ paddingLeft: 60, paddingRight: 60, marginBottom: 15 }}>
              <TextField
                className={classes.filterFormtextField}
                name="rutEmpresa"
                value={rutEmpresa}
                label="Rut empresa sin guíon ni dígito"
                placeholder="Rut"
                onChange={(ev) => setRutEmpresa(ev.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.modalFooter} style={{ display: 'flex', marginTop: '2rem' }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => closeModal(false, '')}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={() => closeModal(true, rutEmpresa)}
          >
            Sincronizar
          </Button>

        </div>
      </div>
    </Modal>
  );
};

export default withStyles(modalMsgStyle)(ModalSeleccionRutsSincronizacion);

import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import '../custom_theme.scss';
import { TablePaginationActionsWrapped } from './TablePaginationActions';

const TableHeaderRowWrapper = ({ classes, config }: { classes: any, config: TC.Fila[] }) => {
  return <TableRow className={classes.TableRowFCT}>
    {config.map((colInfo: TC.Fila, index: number) => {
      const hidden = colInfo.hidden;
      const alignClass = colInfo.headAlign === 'left' ? classes.TDTableLeftAlign :
        colInfo.headAlign === 'right' ? classes.TDTableRightAlign :
          colInfo.headAlign === 'center' ? classes.TDTableCenterAlign : '';
      return hidden ? null :
        <TableCell key={index} className={`${classes.TableThFCT} ${alignClass}`}>{colInfo.column}</TableCell>;
    })}
  </TableRow>;
};

const TableRowBodyWrapped = ({ classes, data, config }: { classes: any, data: any, config: TC.Fila[] }) => {
  return <TableRow className={classes.TableRowBodyFCT}>
    {config.map((colInfo: TC.Fila, index) => {
      const render = colInfo.renderData ? colInfo.renderData : (item: any) => item[colInfo.relation];
      const hidden = colInfo.hidden || colInfo.hiddenFnc && colInfo.hiddenFnc(data);
      const colspan = colInfo.colspan && colInfo.colspan(data) || 1;
      const disabled = colInfo.disabled && colInfo.disabled(data) || false;
      const alignClass = colInfo.align === 'left' ? classes.TDTableLeftAlign :
        colInfo.align === 'right' ? classes.TDTableRightAlign :
          colInfo.align === 'center' ? classes.TDTableCenterAlign : '';
      return hidden ? null :
        <React.Fragment key={index}>
          <TableCell className={`${classes.TableCellFCT} ${disabled ? classes.TableRowBodyFCTDisabled : ''} ${alignClass}`}
                     colSpan={colspan}>
            <div className={`${disabled ? classes.TableCellFCTERRORCONTENT : ''}`}>{render(data)}</div>
          </TableCell>
        </React.Fragment>;
    })}
  </TableRow>;
};

interface ITableContainerProps {
  classes: any;
  data: any[];
  obtenerDatos?: (pagina: number, tamano: number) => void;
  pagination: State.Paginacion | undefined;
  tableInfo: TC.Fila[];
}

interface IState {
  loading: boolean;
}

class TableContainer extends React.Component<ITableContainerProps, IState> {
  constructor(props: ITableContainerProps) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, page: number) => {
    // console.log('handleChangePage', page, this.props.pagination.page);
    if (this.props.pagination && page != this.props.pagination.page && this.props.obtenerDatos) {
      debugger;
      this.props.obtenerDatos(page, this.props.pagination.pageSize);
    }
  };

  handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // console.log('handleChangeRowsPerPage', event.target.value, this.props.pagination.pageSize);
    if (this.props.pagination && event.target.value.toString() !== this.props.pagination.pageSize.toString() && this.props.obtenerDatos) {
      debugger;
      this.props.obtenerDatos(0, Number(event.target.value));
    }
  };

  render() {
    const { classes, data, tableInfo, pagination } = this.props;
    const pag = pagination;
    return (
      <div style={{ width: '100%' }}>
        {this.state.loading ? <div>Cargando</div> :
          <Table className={classes.TableFTC}>
            <TableHead className={classes.HeaderTableFCT}>
              <TableHeaderRowWrapper config={tableInfo} classes={classes} />
            </TableHead>
            <Fragment>
              <TableBody>
                {data.map((row, i) =>
                  (<TableRowBodyWrapped
                      key={i}
                      data={row}
                      config={tableInfo}
                      classes={classes}
                    />
                  ))}
              </TableBody>
              {pag &&
                <TableFooter className={classes.TableFooter}>
                  <TableRow>
                    <TablePagination
                      className='pagination-container'
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      colSpan={tableInfo.length}
                      count={pag.count}
                      page={pag.page}
                      rowsPerPage={pag.pageSize}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActionsWrapped}
                      labelRowsPerPage='Resultados por página'
                      labelDisplayedRows={(pag) => `${pag.from}-${pag.to} of ${pag.count}`}
                    />
                  </TableRow>
                </TableFooter>
              }
            </Fragment>
          </Table>
        }
      </div>
    );
  }
}

export default TableContainer;

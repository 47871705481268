import React from 'react';
import { WithStyles } from '@material-ui/core';
import DashboardStyle from '../../styles/dashboardStyle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Factoring from '../factoring/view';
import FactoringProducto from '../factoring/producto';

interface IProps extends WithStyles<typeof DashboardStyle>, RouteComponentProps {
  empresa : Dto.EnterpriseProfile;
}

interface IState {
}

class TableroFactoring extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { empresa } = this.props;
    return (empresa.factoring ? <Factoring /> : <FactoringProducto />);
  }
}

export default withStyles(DashboardStyle)(withRouter(TableroFactoring));

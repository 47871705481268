import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { SFooterButtons } from '../style';
import { FC } from 'react';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface IFooterButtonsProps extends WithStyles<typeof SFooterButtons> {
  classes : any,
  onClick : () => void,
  disabled : boolean,
  desplegarCeder : boolean,
  opcionFull : boolean
}

const FooterButtons : FC<IFooterButtonsProps> = (props : IFooterButtonsProps) => {

  const comeBack = () => {
    window.history.back();
  };

  return (
    <div className={props.classes.root}>
      <Button
        variant="outlined"
        color="primary"
        className={props.classes.botonVolver}
        onClick={() => comeBack()}>
        Volver
      </Button>
      {props.onClick && props.desplegarCeder &&
      <Button
        color="primary"
        onClick={() => props.onClick()}
        className={props.opcionFull ? props.classes.cederYAceptarFull : props.classes.cederYAceptar}
        disabled={props.disabled}>
        Aceptar y Ceder
      </Button>
      }

    </div>
  );
};

export default withStyles(SFooterButtons)(FooterButtons);

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { EliminarModalStyle } from './style';
import Dialog from '@material-ui/core/Dialog';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import DialogActions from '@material-ui/core/DialogActions';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof EliminarModalStyle> {
  onClose : () => void,
  open : boolean,
  titulo : string,
  mensaje : string,
  onDelete : () => void
}

interface IState {
  loading : boolean
}

class EliminarModal extends React.Component<IProps, IState> {

  constructor(props : IProps) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  loadingToogle = () => {
    this.setState((state) => ({
      loading: !state.loading
    }));
  };

  handlerContinuar = () => {
    this.handleClose();
  };

  render() {
    const { classes, onClose, open, titulo, mensaje, onDelete } = this.props;

    return (
      <Dialog onClose={this.handleClose} className={classes.rootDeleteModal} open={open}
              PaperProps={{ className: [classes.dialogPaper, classes.dialogPaperDelete].join(' ') }}>
        <div className={[classes.checkIcon, classes.chekIconDelete].join(' ')}><DeleteOutlinedIcon /></div>
        <h1>{titulo}</h1>
        <p className={classes.messageDelete}>{mensaje}</p>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 24px' }}>
          <Button variant="outlined" onClick={onDelete} className={classes.redButton}>
            Eliminar
          </Button>
          <Button variant="outlined" className={classes.greyButton} onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(EliminarModalStyle)(EliminarModal);

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'moment/moment';
import '../../../sharedv2/custom_theme.scss';
import 'react-datetime/css/react-datetime.css';
import Header from '../../../sharedv2/Header/Header';
import TabContainer from '../../../sharedv2/Table/TabContainer';
import TableContainer from '../../../sharedv2/Table/TableContainer';
import GridItem from '../../../shared/Grid/GridItem';
import GridContainer from '../../../shared/Grid/GridContainer';
import HistoricoStyle from '../../../../styles/jss/components/extendedFormsStyle';
import Loader from '../../../shared/Loader/Loader';
import RangeCalendar from '../../../shared/RangeCalendar/RangeCalendar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import SubirArchivosEjecutivo from './SubirArchivosEjecutivo';
import fxOpActions from './actions';
import { Link } from 'react-router-dom';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import SentimentDissatisfiedOutlinedIcon from '@material-ui/icons/SentimentDissatisfiedOutlined';
import CompareArrowsOutlinedIcon from '@material-ui/icons/CompareArrowsOutlined';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { WithStyles } from '@material-ui/core';
import { AppState } from '../../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { esEjecutivoAsistente } from '../../../../helpers/UserHelper';

const style = {
  optionContent: {
    border: '1px solid #82BC00',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '3px',
    height: '21px',
    padding: '5px',
    display: 'flex',
    alignItems: 'center',
    width: '112px',
    justifyContent: 'space-around',
    cursor: 'pointer'
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#82BC00',
    textDecoration: 'none'
  },
  rechazada: {
    display: 'flex',
    alignItems: 'center',
    color: '#790000',
    marginRight: '4px'
  },
  icon: {
    marginRight: '4px'
  }
};


interface IOperacionEnProcesoProps extends WithStyles<typeof HistoricoStyle> {
  operaciones: Dto.BackOffice.Operacion[],
  esEjecutivo: boolean,
  pagination: State.Paginacion,
  obtenerOperacionesEnProceso: (payload: Filters.OperacionesProceso) => Promise<void>
}

interface IState {
  numeroOperacion: string,
  loading: boolean,
  subirArchivosEjecutivoState: boolean,
  activeTab: number,
  calendarValue: {
    desde: string,
    hasta: string
  },
  numeroOperacionSelecionadaAdjuntar: number,
  estadoSolicitud: number,
  expanded: boolean,
  operaciones: Dto.BackOffice.Operacion[],
  openCalendar: boolean,
  anchorElCalendar: any
}

class OperacionEnProceso extends React.Component<IOperacionEnProcesoProps, IState> {

  constructor(props: IOperacionEnProcesoProps) {
    super(props);
    this.state = {
      operaciones: props.operaciones,
      expanded: true,
      openCalendar: false,
      anchorElCalendar: null,
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numeroOperacion: '',
      estadoSolicitud: 0,
      activeTab: 0,
      subirArchivosEjecutivoState: false,
      numeroOperacionSelecionadaAdjuntar: 0,
      loading: false
    };

    this.obtenerOperaciones = this.obtenerOperaciones.bind(this);
  }

  componentDidMount() {
    this.obtenerOperaciones(0, 10);
  }

  obtenerOperaciones(pagina: number, tamano: number) {
    this.setState({ loading: true });
    return this.props.obtenerOperacionesEnProceso(this.getFilters(pagina, tamano))
               .then((data) => {
                 this.setState({ loading: false });
               });
  }

  getFilters(pagina: number, tamano: number): Filters.OperacionesProceso {
    const numOp = Number(this.state.numeroOperacion);
    return {
      fechaDesde: (this.state.calendarValue.desde) ? Moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
      fechaHasta: (this.state.calendarValue.hasta) ? Moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : undefined,
      numeroOperacion: isNaN(numOp) ? undefined : numOp,
      pagina: pagina + 1,
      tamano: tamano
      // ascending: sorted && sorted[0] && sorted[0].desc ? true : sorted && sorted[0] ? false : null,
      // orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : ""
    };
  }

  handleSubirArchivosEjecutivoClose = (resultado: boolean) => {
    this.setState((state) => ({
      subirArchivosEjecutivoState: false
    }));
    if (resultado) {
    }
  };

  handlerClickAdjuntarDocumentos = (numeroOperacion: number) => {
    this.setState({
      numeroOperacionSelecionadaAdjuntar: numeroOperacion,
      subirArchivosEjecutivoState: true
    });
  };

  /* actualiza un state */
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeTab = (event: React.ChangeEvent<any>, value: number) => {
    this.setState({ activeTab: value });
  };

  cleanFilters = () => {
    this.setState({
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numeroOperacion: '',
      estadoSolicitud: 0
    }, () => {
      // this.setState({
      //   dataSolicitudes: this.state.operaciones
      // });
    });
  };

  handleExpandClick = () => this.setState((state) => ({ expanded: !state.expanded }));

  handleStatesCalendar = (params: any) => {
    this.setState(params);
  };

  searchSolicitudes = async () => {
    await this.obtenerOperaciones(0, 10);
  };


  tableInfo = [
    {
      column: 'Nº Operación',
      relation: 'numeroOperacion',
      renderData: (operacion: Dto.BackOffice.Operacion) => (
        <Link style={style.link}
              to={`/factoring/operaciones/proceso/${operacion.numeroOperacion}`}>{operacion.numeroOperacion}</Link>
      )
    },
    {
      column: 'Ingresado', relation: '', renderData: (operacion: Dto.BackOffice.Operacion) => (
        <Fragment><CheckCircleOutlineIcon style={style.icon} />Ingresado {operacion.fechaSolicitud}</Fragment>
      )
    },
    {
      column: 'Riesgo', relation: 'Riesgo', renderData: (operacion: Dto.BackOffice.Operacion) => (
        (operacion.idEstado === 2) ?
          <Fragment><QueryBuilderIcon style={style.icon} />En evaluación</Fragment>
          : (operacion.idEstado === 3 || operacion.idEstado === 4 || operacion.idEstado === 5) ?
            <Fragment><SentimentSatisfiedOutlinedIcon style={style.icon} />Aprobada</Fragment>
            : (operacion.idEstado === 0) ?
              <div style={style.rechazada}><SentimentDissatisfiedOutlinedIcon style={style.rechazada} />Rechazada</div>
              : ''
      )
    },
    {
      column: 'Cesión', relation: 'Cesion', renderData: (operacion: Dto.BackOffice.Operacion) => (
        operacion.idEstado === 3 ?
          (operacion.puedeCeder ?
              <div style={style.optionContent}>
                <Link style={style.link}
                      to={`/factoring/operaciones/proceso/${operacion.numeroOperacion}/${((operacion.puedeCeder && operacion.idEstadoCesion === 0) ? 1 : 0)}`}>
                  <CompareArrowsOutlinedIcon color='primary' style={{ marginRight: '4px' }} /> Ceder aquí
                </Link>
              </div> :
              (
                operacion.idEstadoCesion === 1 ?
                  <Fragment> <QueryBuilderIcon style={style.icon} />Cediendo</Fragment> :
                  operacion.idEstadoCesion === 2 ?
                    <Fragment><CheckCircleOutlineIcon style={style.icon} />Cedido</Fragment> :
                    operacion.idEstadoCesion === 3 ?
                      <Fragment>
                        <QueryBuilderIcon style={style.icon} />Cesión parcial&nbsp;&nbsp;
                        {operacion.puedeReintentarCeder && <div style={style.optionContent}>
                          <Link style={style.link}
                                to={`/factoring/operaciones/proceso/${operacion.numeroOperacion}/0`}>
                            <CompareArrowsOutlinedIcon color='primary' style={{ marginRight: '4px' }} /> Reintentar
                          </Link>
                        </div>}
                      </Fragment> :
                      operacion.idEstadoCesion === 4 ?
                        <Fragment>
                          <SentimentDissatisfiedOutlinedIcon style={style.icon} />Error&nbsp;&nbsp;
                          {operacion.puedeReintentarCeder && <div style={style.optionContent}>
                            <Link style={style.link}
                                  to={`/factoring/operaciones/proceso/${operacion.numeroOperacion}/0`}>
                              <CompareArrowsOutlinedIcon color='primary' style={{ marginRight: '4px' }} /> Reintentar
                            </Link>
                          </div>}
                        </Fragment> : ''
              )

          )//<Fragment><QueryBuilderIcon style={style.icon} />Esperando liberación</Fragment>
          : (operacion.idEstado === 4 || operacion.idEstado === 5) ?
            <Fragment><CheckCircleOutlineIcon style={style.icon} />Cedida</Fragment>
            : ''
      )
    },
    {
      column: 'Giro', relation: 'Giro', renderData: (operacion: Dto.BackOffice.Operacion) => (
        operacion.idEstado === 4 ?
          <Fragment><QueryBuilderIcon style={style.icon} />Proceso</Fragment>
          : operacion.idEstado === 5 ?
            <Fragment><CheckCircleOutlineIcon style={style.icon} />Girada</Fragment>
            : ''
      )
    },
    {
      column: 'Cargar Documentos', relation: 'adjuntar', renderData: (operacion: Dto.BackOffice.Operacion) => (
        (operacion.idEstado === 1 || operacion.idEstado === 2 || operacion.idEstado === 3) ?
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
               onClick={() => this.handlerClickAdjuntarDocumentos(Number(operacion.numeroOperacion))}>
            <AttachFileIcon style={style.icon} /> Adjuntar</div>
          : ''
        // <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'rgba(85, 87, 89, 0.2)'}}>
        //   <AttachFileIcon style={{color: 'rgba(85, 87, 89, 0.2)'}} /> Adjuntar</div>
      )
    }
  ];

  render() {
    // get classes && theme
    const { classes, operaciones, pagination, esEjecutivo } = this.props;
    // calendar values
    const {
      openCalendar,
      anchorElCalendar,
      calendarValue,
      activeTab
    } = this.state;

    return (
      <div className='container'>
        {this.state.loading ? <Loader /> :
          <div>
            <Header title='En Proceso' breadcrum='Usted se encuentra en: Factoring / Operaciones / En Curse' />
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    action={(
                      <div>
                        <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement='top'>
                          <IconButton onClick={this.handleExpandClick} aria-expanded={this.state.expanded}
                                      aria-label='Show more'>
                            <ExpandMoreIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                    title={(
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h5' className={classes.filterTitle}>Filtros de Búsqueda</Typography>
                        <Button color='primary' variant='outlined' onClick={this.cleanFilters} style={{ marginLeft: 14 }}>
                          Limpiar
                        </Button>
                      </div>
                    )}
                  />
                  <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
                    <CardContent className={classes.filterCardContent}>
                      <form className={classes.filterForm}>
                        <GridContainer>
                          <GridItem xs>
                            <FormControl fullWidth>
                              <RangeCalendar
                                id='fchSolicitud'
                                name='FchSolicitud'
                                label='Fecha Solicitud'
                                value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                                setStates={this.handleStatesCalendar}
                                openCalendar={openCalendar}
                                anchorElCalendar={anchorElCalendar}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem xs>
                            <FormControl fullWidth>
                              <TextField
                                className={classes.filterFormtextField}
                                id='numOpeId'
                                name='numeroOperacion'
                                value={this.state.numeroOperacion}
                                label='Numero Operación'
                                placeholder='Ej. 100'
                                onChange={this.onChange}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </GridItem>
                          {/*<GridItem xs>*/}
                          {/*  <FormControl fullWidth>*/}
                          {/*    <InputLabel htmlFor="estSolicitudId">Estado</InputLabel>*/}
                          {/*    <Select*/}
                          {/*      id="estSolicitudId"*/}
                          {/*      name="estadoSolicitud"*/}
                          {/*      displayEmpty*/}
                          {/*      className={classes.filterFormSelectField}*/}
                          {/*      value={this.state.estadoSolicitud}*/}
                          {/*      onChange={this.onChange}>*/}
                          {/*      <MenuItem value={0} selected>Todos</MenuItem>*/}
                          {/*      <MenuItem value={6}>En revision</MenuItem>*/}
                          {/*      <MenuItem value={1}>Disponible para Ceder</MenuItem>*/}
                          {/*      <MenuItem value={2}>Rechazada</MenuItem>*/}
                          {/*      <MenuItem value={3}>Enviada a Operaciones</MenuItem>*/}
                          {/*      <MenuItem value={4}>Cediendo</MenuItem>*/}
                          {/*      <MenuItem value={5}>Cesión con Error</MenuItem>*/}
                          {/*    </Select>*/}
                          {/*  </FormControl>*/}
                          {/*</GridItem>*/}
                          <GridItem>
                            <FormControl className={classes.filterFormButtonBody}>
                              <Button
                                variant='outlined'
                                color='primary'
                                onClick={this.searchSolicitudes}>
                                Buscar
                              </Button>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={2} />
                        </GridContainer>
                      </form>
                    </CardContent>
                  </Collapse>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <div className='tabs'>
                  <Tabs value={activeTab} onChange={this.onChangeTab} classes={{ indicator: 'indicator' }}>
                    <Tab label='OPERACIONES' classes={{ root: 'tabs-factotal' }} />
                  </Tabs>
                  {activeTab === 0 &&
                    <TabContainer>
                      <TableContainer classes={classes}
                                      data={operaciones}
                                      pagination={pagination}
                                      tableInfo={this.tableInfo}
                                      obtenerDatos={this.obtenerOperaciones}
                      />
                    </TabContainer>
                  }
                </div>
              </Grid>
            </Grid>
            <SubirArchivosEjecutivo
              open={this.state.subirArchivosEjecutivoState}
              esEjecutivo={esEjecutivo}
              onClose={this.handleSubirArchivosEjecutivoClose}
              numeroOperacion={this.state.numeroOperacionSelecionadaAdjuntar}
            />
          </div>
        }
      </div>
    );
  }
}

/* selecting the part of the data from the redux store */
const mapStateToProps = (state: AppState) => ({
  operaciones: state.operacionesEnProceso.rows,
  total: state.operacionesEnProceso.total,
  pagination: state.operacionesEnProceso.pagination,
  esEjecutivo: esEjecutivoAsistente(JSON.parse(sessionStorage.getItem('user') || ''))
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({
  obtenerOperacionesEnProceso: (payload: Filters.OperacionesProceso) => dispatch(fxOpActions.obtenerOperacionesEnProceso(payload))
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(OperacionEnProceso));

export const convertPaginacion = (paginacion: Dto.Paginacion, incrementoPagina: number = 0): State.Paginacion => ({
  to: paginacion.registroHasta,
  pageSize: paginacion.tamanoPagina,
  page: paginacion.numeroPagina + incrementoPagina,
  maxPages: paginacion.totalPagina,
  from: paginacion.registroDesde,
  count: paginacion.totalRegistro
});

export const iniPaginacion = (): State.Paginacion => ({
  page: 0,
  pageSize: 10,
  count: 0,
  maxPages: 0,
  from: 0,
  to: 0
});


import React, { FC } from "react";
import { Button, WithStyles } from "@material-ui/core";
import { Theme, withStyles } from "@material-ui/core/styles";
import scssTool from "../../../styles/scssTool";

const styleTabs = (theme: Theme) => ({
  tabsDashboardContainer: {
    ...scssTool.flex("center", "row", "flex-start"),
    top: "-26px",
    position: "absolute" as "absolute",
    left: "32px",
  },
});

interface ICustomTabsContainer extends WithStyles<typeof styleTabs> {}

const CustomTabsContainer: FC<ICustomTabsContainer> = (props) => {
  const { classes, children } = props;

  return <div className={classes.tabsDashboardContainer}>{children}</div>;
};

export default withStyles(styleTabs)(CustomTabsContainer);

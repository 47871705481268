import { eventTrackingToExcel, getCarteraFactoring, getDetalleCarteraFactoring, trackingSidebar } from '../../../services/factoringApi';
import { asyncActionType } from '../../../helpers/ActionsHelper';
import FileDownload from 'js-file-download';
import Moment from 'moment/moment';
import { Dispatch } from 'redux';

export const fetchAction = asyncActionType('@@DETALLE_COBRANZA', 'FETCH');

export const fetchDetalleCobranza = (tipoDocumento: number, loading: boolean) => ({
  type: fetchAction.PENDING,
  payload: {
    tipoDocumento: tipoDocumento,
    loading: loading
  }
});

export const detalleCobranzaFetched = (response: Response.Cartera, tipoDocumento: number) => ({
  type: fetchAction.SUCCESS,
  payload: {
    carteraRow: response.data,
    tipoDocumento: tipoDocumento,
    paginacion: {
      ...response.paginacion,
      numeroPagina: response.paginacion.numeroPagina - 1
    }
  }
});

export const obtenerCobranzaFactoring = (params: Filters.CobranzaFactoring) => (dispatch: Dispatch) => {
  dispatch(fetchDetalleCobranza(params.tipoDocumento, true));
  params.isCobranzaNoPdf = true;
  return getCarteraFactoring(params)
    .then((response) => {
      return dispatch(detalleCobranzaFetched(response as Response.Cartera, params.tipoDocumento));
    })
    .catch(error => error);
};

export const obtenerDetalleCobranzaFactoring = (params: Filters.DetalleCobranzaFactoring) => (dispatch: Dispatch) => {
  return getDetalleCarteraFactoring(params)
    .then((response) => response)
    .catch(error => error);
};

export const obtenerPdfCobranzaFactoring = (params: Filters.CobranzaFactoring) => (dispatch: Dispatch) => {
  params.isExportPdf = true;
  params.isCobranza = true;
  return getCarteraFactoring(params)
    .then(response => FileDownload(response as Blob, `Cobranza ${Moment().format('YYYY-MM-DD-hh-mm')}.pdf`))
    .catch(error => error);
};

export const obtenerExcelCobranzaFactoring = (params: Filters.CobranzaFactoring) => (dispatch: Dispatch) => {
  return getCarteraFactoring(params)
    .then(response => response)
    .catch(error => error);
};
export const setEventTrackingExcel = (params: string) => (dispatch: Dispatch) => {
  return eventTrackingToExcel(params)
    .then(response => response)
    .catch(error => error);
};
export const setTrackingSidebar = (params: string) => () => {
  return trackingSidebar(params)
    .then(response => response)
    .catch(error => error);
};
export default { fetchAction };

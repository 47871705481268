// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import 'react-datetime/css/react-datetime.css';
import ReactTable from 'react-table';
import checkboxHOC from 'react-table/lib/hoc/selectTable';
import Moment from 'moment/moment';
import moment from 'moment/moment';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Paper from '@material-ui/core/Paper';
import FormHelperText from '@material-ui/core/FormHelperText';
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';
import NavPills from '../../shared/NavPills/NavPills';
import Typography from '@material-ui/core/Typography';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../shared/RangeCalendar/RangeCalendar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PaginationBase from '../../shared/Pagination/PaginationBase';
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import { saveSiniestro } from '../../../services/leasingApi';
import { fileToBase64, getNameFile } from '../../../helpers/FileHelper';
import { formatRut, validaCaracterRut, validaRut } from '../../../helpers/RutHelper';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';

const CheckboxTableFacturas = checkboxHOC(ReactTable);

var detTipoBien = [
  'Camión',
  'Camioneta'
];
var detMarcas = [
  'Mitsubishi',
  'Nissan',
  'Volkswagen',
  'Ssangyong',
  'Ford'
];
var detPatentes = [
  'BBBB10',
  'GSBB20',
  'GTBC25',
  'BXBK86',
  'CJWP84'
];

interface IHistorico {
  _id : string,
  tipobien : string,
  marcas : string,
  patentes : string,
  estado : string,
  seguro : string,
  fechacontrato : moment.Moment,
  numerocontrato : number
}

interface IRecord<T> {
  _original : T
}

interface IRowRecord<T> {
  original : T
}

const newHistorico = (id : string) : IHistorico => ({
  _id: id, // tiene que tener un id unico para hacer el checkbox
  tipobien: detTipoBien[Math.floor(Math.random() * detTipoBien.length)],
  marcas: detMarcas[Math.floor(Math.random() * detMarcas.length)],
  patentes: detPatentes[Math.floor(Math.random() * detPatentes.length)],
  estado: 'Nuevo',
  seguro: 'FT Vendor',
  fechacontrato: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  numerocontrato: Math.floor(Math.random() * 100)
});

export function makeData(len : number) : IHistorico[] {
  let arr : IHistorico[] = [];
  for (let r = 0; r < len; r++) {
    arr.push(newHistorico(r.toString()));
  }
  return arr;
}

function getSteps() {
  return ['1', '2', '3'];
}

interface IProps extends WithStyles<typeof HistoricoStyle> {
  dispatch : any
}

interface IState extends State.ICalendar {
  activeStep : number,
  total : number,
  selection : string[],
  expanded : boolean,
  data : IHistorico[],
  denuncianteErrorMessage : string,
  isValidateDenunciante : boolean,
  rutErrorMessage : string,
  isValidateRut : boolean,
  nombreErrorMessage : string,
  isValidateNombre : boolean,
  emailErrorMessage : string,
  isValidateEmail : boolean,
  cedulafile : string,
  cedulaFileEncrypted : string,
  cedulaFileErrorMessage : string,
  isValidateCedulaFile : boolean,
  licenciafile : string,
  licenciaFileEncrypted : string,
  licenciaFileErrorMessage : string,
  isValidateLicenciaFile : boolean,
  padronfile : string,
  padronFileEncrypted : string,
  padronFileErrorMessage : string,
  isValidatePadronFile : boolean,
  rut : string,
  nombre : string,
  email : string,
  telefono : string,
  denunciante : string,
  patente : string,
  openCalendar : boolean,
  anchorElCalendar? : any,
  selectAll : boolean,
  numeroContrato : string,
}

class SiniestrosVendor extends React.Component<IProps, IState> {

  checkboxTable : any;

  constructor(props : IProps) {
    super(props);
    this.state = {
      activeStep: 0,
      total: 0,
      selection: [],
      expanded: true,
      data: makeData(12),
      selectAll: false,
      denunciante: '',
      denuncianteErrorMessage: '',
      isValidateDenunciante: true,
      rut: '',
      rutErrorMessage: '',
      isValidateRut: true,
      nombre: '',
      nombreErrorMessage: '',
      isValidateNombre: true,
      email: '',
      emailErrorMessage: '',
      isValidateEmail: true,
      telefono: '',
      cedulafile: '',
      cedulaFileEncrypted: '',
      cedulaFileErrorMessage: '',
      isValidateCedulaFile: true,
      licenciafile: '',
      licenciaFileEncrypted: '',
      licenciaFileErrorMessage: '',
      isValidateLicenciaFile: true,
      padronfile: '',
      padronFileEncrypted: '',
      padronFileErrorMessage: '',
      isValidatePadronFile: true,
      openCalendar: false,
      anchorElCalendar: null,
      patente: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numeroContrato: ''
      // dateRange: {
      //   selection: {
      //     startDate: new Date(),
      //     endDate: null,
      //     key: 'selection'
      //   }
      // }
    };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter : any, row : any, column : any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };


  toggleSelection = (key : string, shift : any, row : IHistorico) => {
    /*
      Implementation of how to manage the selection state is up to the developer.
      This implementation uses an array stored in the component state.
      Other implementations could use object keys, a Javascript Set, or Redux... etc.
    */
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(row._id);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selection.push(row._id);
    }
    // update the state
    this.setState({ selection, total: selection.length });
  };

  toggleAll = () => {
    /*
      'toggleAll' is a tricky concept with any filterable table
      do you just select ALL the records that are in your data?
      OR
      do you only select ALL the records that are in the current filtered data?

      The latter makes more sense because 'selection' is a visual thing for the user.
      This is especially true if you are going to implement a set of external functions
      that act on the selected information (you would not want to DELETE the wrong thing!).

      So, to that end, access to the internals of ReactTable are required to get what is
      currently visible in the table (either on the current page or any other page).

      The HOC provides a method call 'getWrappedInstance' to get a ref to the wrapped
      ReactTable and then get the internal state and the 'sortedData'.
      That can then be iterrated to get all the currently visible records and set
      the selection state.
    */
    const selectAll = !this.state.selectAll;
    const selection : string[] = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords : IRecord<IHistorico>[] = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }

    this.setState({ selectAll, selection, total: selection.length });
  };

  isSelected = (key : string) : boolean => {
    /*
      Instead of passing our external selection state we provide an 'isSelected'
      callback and detect the selection state ourselves. This allows any implementation
      for selection (either an array, object keys, or even a Javascript Set object).
    */
    return this.state.selection === undefined ? false : this.state.selection.includes(key);
  };

  handleNext = () => {

    //Antes de enviar al step nos asguramos de validar los campos
    let isValidate = true;
    const {
      activeStep,
      denunciante,
      rut, nombre, email, telefono, cedulafile, licenciafile, padronfile,
      cedulaFileEncrypted, licenciaFileEncrypted, padronFileEncrypted
    } = this.state;

    if (denunciante.trim() == '') {
      this.setState({ denuncianteErrorMessage: 'El campo denunciante es requerido', isValidateDenunciante: false });
      isValidate = false;
    } else if (denunciante.trim() != '') this.setState({ denuncianteErrorMessage: '', isValidateDenunciante: true });

    if (rut.trim() == '') {
      this.setState({ rutErrorMessage: 'El campo rut es requerido', isValidateRut: false });
      isValidate = false;
    } else if (rut.trim() != '') this.setState({ rutErrorMessage: '', isValidateRut: true });

    if (!validaRut(rut) && rut.trim() != '') {
      this.setState({ rutErrorMessage: 'El rut es inválido', isValidateRut: false });
      isValidate = false;
    }

    if (nombre.trim() == '') {
      this.setState({ nombreErrorMessage: 'El campo nombre es requerido', isValidateNombre: false });
      isValidate = false;
    } else if (nombre.trim() != '') this.setState({ nombreErrorMessage: '', isValidateNombre: true });

    if (email.trim() == '') {
      this.setState({ emailErrorMessage: 'El campo email es requerido', isValidateEmail: false });
      isValidate = false;
    } else if (email.trim() != '') this.setState({ emailErrorMessage: '', isValidateEmail: true });

    let re_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re_email.test(email) && email.trim() != '') {
      this.setState({ emailErrorMessage: 'El email ingresado es inválido', isValidateEmail: false });
      isValidate = false;
    }

    if (cedulafile.trim() == '') {
      this.setState({
        cedulaFileErrorMessage: 'El archivo cédula de identidad es requerido',
        isValidateCedulaFile: false
      });
      isValidate = false;
    } else if (cedulafile.trim() != '') this.setState({ cedulaFileErrorMessage: '', isValidateCedulaFile: true });

    if (licenciafile.trim() == '') {
      this.setState({
        licenciaFileErrorMessage: 'El archivo licencia de conducir es requerido',
        isValidateLicenciaFile: false
      });
      isValidate = false;
    } else if (licenciafile.trim() != '') this.setState({ licenciaFileErrorMessage: '', isValidateLicenciaFile: true });

    if (padronfile.trim() == '') {
      this.setState({ padronFileErrorMessage: 'El archivo padrón es requerido', isValidatePadronFile: false });
      isValidate = false;
    } else if (padronfile.trim() != '') this.setState({ padronFileErrorMessage: '', isValidatePadronFile: true });

    if (isValidate) {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));

      const steps = getSteps();

      if (activeStep === steps.length - 2) {

        const params = {
          denunciante,
          rut,
          nombre,
          email,
          telefono,
          cedulaFileEncrypted,
          licenciaFileEncrypted,
          padronFileEncrypted
        };
        saveSiniestro(params);
      }
    }
  };


  handleBack = () => {
    //const {denunciante, rut, nombre,email, telefono} = this.state;
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));

  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  onChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeRut = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    e.target.value = formatRut(e);
    this.onChange(e);
    // this.setState({ [e.target.name]: e.target.value });
  };

  onChangeOnlyNumeric = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const valor = e.target.value.replace(/[^0-9]+/gi, '')
                   .replace('(', '')
                   .replace(')', '')
                   .replace('|', '');
    e.target.value = valor;
    this.onChange(e);
    // this.setState({ [e.target.name]: e.target.value });
  };

  onChangeFile = (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {

    const inputFileName = evt.target.name;

    fileToBase64(evt as any)
      .then(result => {

        switch (inputFileName) {

          case 'cedulafile':
            this.setState({ cedulaFileEncrypted: result });
            break;
          case 'licenciafile':
            this.setState({ licenciaFileEncrypted: result });
            break;
          default: //padronFile
            this.setState({ padronFileEncrypted: result });
            break;
        }

      });

    this.onChange(evt);
    // this.setState({ [evt.target.name]: evt.target.value });

  };

  //onBlur = (e) => this.setState({ [e.target.name]: e.target.value });

  onChangeNumeroContacto = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (isNaN(Number(e.target.value))) e.target.value = '';
  };

  handleStatesCalendar = (calendar : State.ICalendar) => {
    this.setState(calendar);
  };


  cleanFilters() {
    this.setState({
      patente: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numeroContrato: ''
    }, () => {
      //this.handleFiltrar();
    });
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this;
    const { classes } = this.props;

    const {
      selectAll, activeStep, openCalendar, anchorElCalendar, calendarValue, denuncianteErrorMessage,
      isValidateDenunciante, rutErrorMessage, isValidateRut, nombreErrorMessage, isValidateNombre, emailErrorMessage,
      isValidateEmail, cedulaFileErrorMessage, isValidateCedulaFile, padronFileErrorMessage, isValidatePadronFile,
      licenciaFileErrorMessage, isValidateLicenciaFile, cedulafile, licenciafile, padronfile, nombre, rut, denunciante,
      email, telefono
    } = this.state;

    const steps = getSteps();
    const checkboxPropsFacturas = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox' as 'checkbox',
      getTrProps: (s : any, r : any) => {
        const r2 : IRowRecord<IHistorico> = r;
        if (r2 && r2.original && r2.original._id) {
          const selected = this.isSelected(r2.original._id);
          return {
            className: classes.TDTable + ' ' + classes.TDTableRightAlign + ' ' + classes.TDTableFilaCheck,
            style: {
              fontWeight: selected ? 'bold' : 'normal',
              backgroundColor: selected ? 'lightgreen' : 'inherit',
              color: selected ? 'white' : 'inherit'
            }
          };
        } else return {};
      }
    };
    const columns = [
      {
        Header: 'TIPO DE BIEN',
        accessor: 'tipobien',
        headerClassName: classes.HeaderTable,
        //width: "100%",
        className: classes.TDTable,
        id: 'tipobien'
      },
      {
        Header: 'MARCA',
        accessor: 'marcas',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign
      },
      {
        Header: 'PATENTE',
        accessor: 'patentes',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign
      },
      {
        Header: 'ESTADO DOCUMENTO',
        accessor: 'estado',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign
      },
      {
        Header: 'Nº CONTRATO',
        accessor: 'numerocontrato',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign
      },
      {
        Header: 'FECHA CONTRATO',
        accessor: 'fechacontrato',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: 80,
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign,
        Cell: (row : IRowRecord<IHistorico>) => <div>
          {row.original && row.original.fechacontrato ? Moment(row.original.fechacontrato)
            .format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        Header: 'SEGURO',
        accessor: 'seguro',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign
      }
    ];
    return (
      <div>
        <div>
          <Breadcrumb category="Usted se encuentra en: Leasing tecnológico / Siniestros" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Siniestros
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          // [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color="primary" variant="outlined" onClick={() => this.cleanFilters()}
                            style={{ marginLeft: 14 }}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="patente"
                          name="patente"
                          value={this.state.patente}
                          label="Patente"
                          onChange={this.onChange}
                          placeholder="000000"
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="numeroContrato"
                          label="Nº Contrato"
                          name="numeroContrato"
                          value={this.state.numeroContrato}
                          placeholder="000000"
                          onChange={this.onChangeOnlyNumeric}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id="fchcon"
                            name="fchcon"
                            label="Fecha Contrato"
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={6} lg={7} style={{ position: 'relative' }}>
            <GridItem className={classes.gridPDFExcelButtons}>
              <Tooltip title="Descargar PDF" placement="top">
                <IconButton>
                  <SVGIcons icon="pdf" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descargar Planilla Excel" placement="top">
                <IconButton>
                  <SVGIcons icon="excel" />
                </IconButton>
              </Tooltip>
            </GridItem>
            <NavPills
              limitWidthPill
              color="info"
              tabs={[
                {
                  tabButton: 'DENUNCIA',
                  hidden: !!this.state.data.length,
                  tabContent: (

                    <CheckboxTableFacturas
                      ref={r => (this.checkboxTable = r)}
                      data={this.state.data}
                      filterable={false}
                      columns={columns}
                      defaultPageSize={10}
                      showPaginationBottom
                      PaginationComponent={PaginationBase}
                      //className="-striped -highlight"
                      previousText="Anterior"
                      nextText="Siguiente"
                      loadingText="cargando..."
                      noDataText="Sin información"
                      pageText="Pagina"
                      ofText="de"
                      rowsText="filas"
                      defaultFilterMethod={this.defaultFilterMethod}
                      {...checkboxPropsFacturas}
                    />
                  )
                }
                ,
                {
                  tabButton: 'CONSULTAS',
                  hidden: true
                },
                {
                  tabButton: 'PASOS A SEGUIR',
                  hidden: true
                }

              ]}
            />
          </GridItem>
          <GridItem xs={6} lg={5}>
            <Card>
              <CardHeader title="Solicitud Denuncia de Siniestro" />
              <CardContent>
                <Stepper activeStep={activeStep} className={classes.fontSizeStep}>
                  <Step key={0}>
                    <StepLabel>Ingreso de información</StepLabel>f
                  </Step>
                  <Step key={1}>
                    <StepLabel>Confirmación de información</StepLabel>
                  </Step>
                  <Step key={2}>
                    <StepLabel>Comprobante Denuncio</StepLabel>
                  </Step>
                </Stepper>
                {activeStep === 0 ? (
                  <div>
                    <Paper /*className={classes.root}*/ elevation={1} style={{ padding: '1rem', backgroundColor: '#F9F9F9', borderColor: '#D6DEC8' }}>
                      <GridContainer>
                        <GridItem xs={6}>
                          <TextField
                            id="denunciante"
                            name="denunciante"
                            label="Denunciante"
                            required
                            // className={classes.textField}
                            margin="normal"
                            placeholder="¿Quién denuncia?"
                            error={!isValidateDenunciante}
                            defaultValue={denunciante}
                            onBlur={this.onChange}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{ shrink: true }}
                          />
                          <FormHelperText
                            id="component-error-text"
                            error>
                            {denuncianteErrorMessage}
                          </FormHelperText>

                        </GridItem>
                        <GridItem xs={6}>
                          <TextField
                            id="rut"
                            name="rut"
                            label="RUT"
                            required
                            // className={classes.textField}
                            margin="normal"
                            placeholder="Ingrese RUT"
                            error={!isValidateRut}
                            onChange={(e) => validaCaracterRut(e)}
                            onBlur={this.onChangeRut}
                            inputProps={{ maxLength: 10 }}
                            defaultValue={rut}
                            InputLabelProps={{ shrink: true }}
                          />
                          <FormHelperText
                            id="component-error-text"
                            error>
                            {rutErrorMessage}
                          </FormHelperText>
                        </GridItem>

                        <GridItem xs={12}>
                          <TextField
                            id="nombre"
                            name="nombre"
                            label="Nombre completo"
                            required
                            // className={classes.textField}
                            margin="normal"
                            placeholder="Ingrese su nombre"
                            error={!isValidateNombre}
                            //onChange={onchange}
                            onBlur={this.onChange}
                            defaultValue={nombre}
                            style={{ width: '100%' }}
                            inputProps={{ maxLength: 100 }}
                            InputLabelProps={{ shrink: true }}
                          />

                          <FormHelperText
                            id="component-error-text"
                            error>
                            {nombreErrorMessage}
                          </FormHelperText>
                        </GridItem>

                        <GridItem xs={6}>
                          <TextField
                            id="email"
                            name="email"
                            label="Email"
                            // type="email"
                            // className={classes.textField}
                            margin="normal"
                            placeholder="Ingrese su email"
                            required
                            error={!isValidateEmail}
                            // value={this.state.email}
                            //onChange={this.onChange}
                            defaultValue={email}
                            onBlur={this.onChange}
                            inputProps={{ maxLength: 50 }}
                            InputLabelProps={{ shrink: true }}
                          />

                          <FormHelperText
                            id="component-error-text"
                            error>
                            {emailErrorMessage}
                          </FormHelperText>
                        </GridItem>
                        <GridItem xs={6}>
                          <TextField
                            id="telefono"
                            name="telefono"
                            label="Teléfono de contacto"
                            // className={classes.textField}
                            margin="normal"
                            placeholder="Ingrese su teléfono"
                            onChange={this.onChangeNumeroContacto}
                            onBlur={this.onChange}
                            defaultValue={telefono}
                            inputProps={{ maxLength: 12 }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </GridItem>

                        <GridItem xs={12}>
                          <Typography variant="caption" gutterBottom>
                            Adjuntar documentos * (Tamaño máximo de 128 MB)
                          </Typography>
                        </GridItem>

                        <GridItem xs={4}>
                          <input
                            accept="image/*"
                            // className={classes.input}
                            style={{ display: 'none' }}
                            id="cedulafile"
                            name="cedulafile"
                            // error={!isValidateCedulaFile}
                            multiple
                            type="file"
                            //onChange={this.onChange}
                            onChange={this.onChangeFile}
                          />
                          <label htmlFor="cedulafile">
                            <Button variant="contained" component="span" color="primary" /*className={classes.button}*/>
                              CÉDULA DE IDENTIDAD
                            </Button>
                          </label>

                          <FormHelperText
                            id="component-error-text"
                            error>
                            {cedulaFileErrorMessage}
                          </FormHelperText>

                          <div>{getNameFile(cedulafile)}</div>

                        </GridItem>
                        <GridItem xs={4}>
                          <input
                            accept="image/*"
                            // className={classes.input}
                            style={{ display: 'none' }}
                            id="licenciafile"
                            name="licenciafile"
                            multiple
                            type="file"
                            //onChange={this.onChange}
                            onChange={this.onChangeFile}
                            // error={!isValidateLicenciaFile}
                          />
                          <label htmlFor="licenciafile">
                            <Button variant="contained" component="span" color="primary" /*className={classes.button}*/>
                              LICENCIA DE CONDUCIR
                            </Button>
                          </label>

                          <FormHelperText
                            id="component-error-text"
                            error>
                            {licenciaFileErrorMessage}
                          </FormHelperText>

                          <div>{getNameFile(licenciafile)}</div>

                        </GridItem>
                        <GridItem xs={4}>
                          <input
                            accept="image/*"
                            // className={classes.input}
                            style={{ display: 'none' }}
                            id="padronfile"
                            name="padronfile"
                            multiple
                            type="file"
                            // error={!isValidatePadronFile}
                            //onChange={this.onChange}
                            onChange={this.onChangeFile}
                          />
                          <label htmlFor="padronfile">
                            <Button variant="contained" component="span" color="primary" /*className={classes.button}*/>
                              PADRÓN VEHÍCULO
                            </Button>
                          </label>

                          <FormHelperText
                            id="component-error-text"
                            error>
                            {padronFileErrorMessage}
                          </FormHelperText>

                          <div>{getNameFile(padronfile)}</div>

                        </GridItem>
                      </GridContainer>
                    </Paper>

                  </div>
                ) : activeStep > 0 ?
                  (
                    <div>
                      <Paper /*className={classes.root}*/ elevation={1} style={{ padding: '1rem' }}>
                        <GridContainer>
                          <GridItem xs={6}>
                            <TextField
                              id="disabledenunciante"
                              disabled
                              value={this.state.denunciante}
                              label="Denunciante *"
                              // className={classes.textField}
                              margin="normal"
                              placeholder="¿Quién denuncia?"
                            />
                          </GridItem>
                          <GridItem xs={6}>
                            <TextField
                              id="disablerut"
                              value={this.state.rut}
                              label="RUT *"
                              // className={classes.textField}
                              margin="normal"
                              placeholder="Ingrese RUT"

                              disabled
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12}>
                            <TextField
                              id="disablenombre"
                              value={this.state.nombre}
                              label="Nombre completo *"
                              // className={classes.textField}
                              margin="normal"
                              placeholder="Ingrese su nombre"
                              disabled
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={6}>
                            <TextField
                              id="disableemail"
                              value={this.state.email}
                              label="Email *"
                              // className={classes.textField}
                              margin="normal"
                              placeholder="Ingrese su email"

                              disabled
                            />
                          </GridItem>
                          <GridItem xs={6}>
                            <TextField
                              id="disabletelefono"
                              value={this.state.telefono}
                              label="Teléfono de contacto"
                              // className={classes.textField}
                              margin="normal"
                              placeholder="Ingrese su teléfono"

                              disabled
                            />
                          </GridItem>
                        </GridContainer>
                        {/* <GridContainer>
                      <GridItem xs={12}>
                        <Typography variant="caption" gutterBottom >
                          Adjuntar documentos * (Tamaño máximo de 128 MB)
                        </Typography>
                      </GridItem>
                  </GridContainer> */}
                        <GridContainer>
                          <GridItem xs={4}>
                            <TextField
                              id="disablecedula"
                              value={this.state.cedulafile.replace(/^.*[\\\/]/, '')}
                              label="CÉDULA DE IDENTIDAD"
                              // className={classes.textField}
                              margin="normal"
                              disabled
                            />

                          </GridItem>
                          <GridItem xs={4}>
                            <TextField
                              id="disablecedula"
                              value={this.state.licenciafile.replace(/^.*[\\\/]/, '')}
                              label="LICENCIA DE CONDUCIR"
                              // className={classes.textField}
                              margin="normal"
                              disabled
                            />

                          </GridItem>
                          <GridItem xs={4}>
                            <TextField
                              id="disablecedula"
                              value={this.state.padronfile.replace(/^.*[\\\/]/, '')}
                              label="PADRÓN VEHÍCULO"
                              // className={classes.textField}
                              margin="normal"
                              disabled
                            />
                          </GridItem>
                        </GridContainer>
                      </Paper>

                    </div>
                  )
                  : ('')}
              </CardContent>
              <CardActions>
                {activeStep === steps.length - 1 ? (
                  <div>
                    <Button variant="contained" onClick={this.handleReset} color="primary" /*className={classes.button}*/>
                      Descargar
                    </Button>
                  </div>
                ) : (
                  <GridContainer>
                    <GridItem xs={3}>
                      <Button
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        //className={classes.button}
                        /*className={classes.button}*/
                        variant="outlined"
                        color="primary"
                      >
                        Anterior
                      </Button>
                    </GridItem>
                    <GridItem xs={3}>
                      <Button
                        //disabled={this.state.Total===0}
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        /*className={classes.button}*/
                        style={{ height: '30px' }}
                      >
                        {activeStep === steps.length - 2 ? 'ENVIAR' : 'CONTINUAR'}
                      </Button>
                    </GridItem>
                  </GridContainer>

                )}
              </CardActions>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state : AppState) => ({
  isLoading: state.main.isLoading
});

const mapDispatchToProps = (dispatch : ThunkDispatch<any, any, AnyAction>) => ({
  dispatch: (action : AnyAction) => {
    dispatch(action);
  }
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(SiniestrosVendor));

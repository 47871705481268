import React, { ChangeEvent } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Theme, withStyles, WithStyles } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Button from '../CustomButtons/Button';
import FactotalTheme from '../../../styles/FactotalTheme';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';

const styles = (theme : Theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap' as 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  },
  selectEmpty: {
    //marginTop: theme.spacing.unit * 2,
    '&:before, &:hover::before, &:after': {
      border: 'none !important'
    },
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)'
    }
  },
  selectMenu: {
    padding: '0 25px 0 0'
  },
  selectRoot: {
    margin: '0 10px',
    borderBottom: '1px solid rgba(46, 42, 37, 0.5)'
  },
  selectIcon: {
    color: '#000',
    height: '.7em',
    top: '-2px'
  },
  button: {
    margin: theme.spacing.unit
  },
  marginButtons: {
    margin: '0 5px',
    border: '1px solid #82BC00',
    color: '#82BC00'
  },
  borderButton: {},
  icons: {
    width: '17px',
    height: '17px'
  },
  pagination: {
    fontFamily: [
      'Larsseit',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontSize: '.8rem !important',
    color: `${FactotalTheme.palette.grey[500]} !important`,
    lineHeight: '1.5em !important',
    display: 'inline-block',
    padding: '10px',
    margin: '0',
    borderRadius: '4px',
    float: 'right' as 'right'
  },
  paginationItem: {
    display: 'inline'
  }
});

interface IProps extends WithStyles<typeof styles> {
  page : number,
  pages : number,
  data : any[],
  onPageChange : (page : number) => void,
  onPageSizeChange : (size : number) => void,
  showPageSizeOptions : boolean,
  rowsText : string,
  totalItems : number,
  canPrevious : boolean,
  canNext : boolean,
  pageSize : number,
  pageSizeOptions : string[]
}

interface IState {
  page : number
}

class PaginationBase extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);

    this.getSafePage = this.getSafePage.bind(this);
    this.changePage = this.changePage.bind(this);
    this.applyPage = this.applyPage.bind(this);
    this.state = {
      page: props.page
    };
  }

  componentDidUpdate(nextProps : IProps) {
    if (this.props.page !== nextProps.page) {
      this.setState({ page: nextProps.page });
    }
  }

  getSafePage(page : string | number) {
    if (Number.isNaN(page)) {
      page = this.props.page;
    } else {
      page = Number(page);
    }
    return Math.min(Math.max(page, 0), this.props.pages - 1);
  }

  changePage(page : number) {
    page = this.getSafePage(page);
    this.setState({ page });
    if (this.props.page !== page) {
      this.props.onPageChange(page);
    }
  }

  applyPage(e : ChangeEvent<HTMLInputElement>) {
    if (e) {
      e.preventDefault();
    }
    const page = this.state.page;
    this.changePage(page === 0 ? this.props.page : page);
  }

  render() {
    const {
      pages,
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      canPrevious,
      canNext,
      onPageSizeChange,
      classes,
      totalItems
    } = this.props;

    return (
      <React.Fragment>
        <ul className={classes.pagination}>
          <li className={classes.paginationItem}>
            {'Filas por página: '}
          </li>
          <li className={classes.paginationItem}>
            {showPageSizeOptions && (
              <Select
                value={pageSize}
                onChange={(e) => onPageSizeChange(Number(e.target.value))}
                name="age"
                className={classes.selectEmpty}
                classes={{
                  selectMenu: classes.selectMenu,
                  root: classes.selectRoot,
                  icon: classes.selectIcon
                }}
                IconComponent={(props) => {
                  return <SvgIcon {...props}>
                    <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
                    <path fill="none" d="M0 0h24v24H0V0z" />
                  </SvgIcon>;
                }}
              >
                {pageSizeOptions.map((option : string, i : number) => (
                  <MenuItem key={i} value={option}>{`${option} ${this.props.rowsText}`}</MenuItem>
                ))}
              </Select>
            )}
          </li>
          <li className={classes.paginationItem}>
            {(this.state.page * pageSize) + 1}
            {'-'}
            {((pageSize * (this.state.page === 0 ? 1 : this.state.page + 1) >= totalItems ? totalItems : (pageSize * (this.state.page === 0 ? 1 : this.state.page + 1)) || 1))}
            {' de '}
            {totalItems ? totalItems : this.props.data.length}
            {'  '}
          </li>
          <li className={classes.paginationItem}>
            <Button
              justIcon
              round
              color="white"
              className={classnames({
                [classes.marginButtons]: true,
                [classes.borderButton]: canPrevious
              })}
              onClick={() => {
                if (!canPrevious) return;
                this.changePage(page - 1);
              }}
              disabled={!canPrevious}
            >
              <KeyboardArrowLeft className={classes.icons} />
            </Button>
          </li>
          <li className={classes.paginationItem}>
            <Button
              justIcon
              round
              color="white"
              className={classnames({
                [classes.marginButtons]: true,
                [classes.borderButton]: canNext
              })}
              onClick={() => {
                if (!canNext) return;
                this.changePage(page + 1);
              }}
              //disabled={!canNext}
              disabled={(page + 1) === pages}
            >
              <KeyboardArrowRight className={classes.icons} />
            </Button>
          </li>
        </ul>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PaginationBase);

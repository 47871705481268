import { Theme } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC } from 'react';
import scssTool from '../../../styles/scssTool';

const styleComponent = (theme: Theme) => ({
  root: {
    background: '#F4F4F4',
    borderRadius: '10px',
    padding: '16px 24px',
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
    marginTop: '21px',
    position: 'relative' as 'relative'
  }
});

interface IRectangleContainer extends WithStyles<typeof styleComponent> {
  children: any;
}

const RectangleContainer: FC<IRectangleContainer> = (props) => {
  const { classes, children } = props;

  return (
    <div className={classes.root}>{children}</div>
  );
};

export default withStyles(styleComponent)(RectangleContainer);

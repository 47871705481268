import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../../sharedv2/custom_theme.scss';
import tableStyle from '../../../styles/jss/components/tableStyle';
import { formatStringDate } from '../../../helpers/FormatDate';
import moment from 'moment';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof tableStyle> {
  tutoriales : Dto.Portal.Tutorial[],
  openDetails : (tutorial : Dto.Portal.Tutorial) => void
}

interface IState {
}

class TableVideos extends React.Component<IProps, IState> {

  constructor(props : IProps) {
    super(props);
    this.state = {};
  }

  render() {

    const { tutoriales, classes, openDetails } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow className={classes.trow}>
            <TableCell className={classes.cell}>Contenido</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align="right">Descripción</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align="right">Agregado</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align="right">Visualizado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tutoriales.map((row : Dto.Portal.Tutorial) => (
            <TableRow key={row.id} className={classes.rowBody}>
              <TableCell className={classes.cellBody}>
                <div
                  style={{
                    backgroundImage: `url(https://img.youtube.com/vi/${row.urlRecurso}/0.jpg)`
                  }}
                  className={classes.ytPreviewBox}
                  onClick={() => openDetails(row)}>
                  <div className={classes.ytPreviewHover}>
                    <div></div>
                  </div>
                </div>
              </TableCell>
              <TableCell className={classes.cellBody}>
                <div className={classes.descColumn}>
                  <div className={classes.descColumnTitle}>{row.nombre}</div>
                  <p className={classes.descColumnLitle}>{row.descripcion}</p>
                </div>
              </TableCell>
              <TableCell align="right"
                         className={[classes.cellBody, classes.centerText, classes.descColumnLitle].join(' ')}>{formatStringDate(row.fechaCreacion)}</TableCell>
              <TableCell align="right" className={[classes.cellBody, classes.centerText].join(' ')}>
                <div className={classes.descColumn}>
                  <span
                    className={classes.descColumnLitle}>{row.fechaVisualizacion ? moment(row.fechaVisualizacion)
                    .format('DD-MM-YYYY HH:mm') : ''}</span>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
};

export default withStyles(tableStyle)(TableVideos);

import axios from 'axios';
import config from '../config/api';
import { instance, userEnterpriseRut } from './factoringApi';
import { buildQueryString } from '../helpers/UrlHelper';
import { authHeader } from '../helpers/AuthHeader';
import { getBase64Promise } from '../helpers/Base64ToBlob';
import { descargaArchivo } from '../helpers/FileHelper';

const apiUserUrl = config.apiUri;

export const saveSiniestro = (params : any) => {
  axios.post(`${apiUserUrl}/siniestroSve`, params);
};

export const getSiniestro = (id : string) => {
  let queryString = `ìd=${id}`;
  return instance.get(`${apiUserUrl}/siniestro?${queryString}`);
};

export const getResumenCliente = () : Promise<Dto.Leasing.ResumenCliente> => {
  return instance
    .get(`${apiUserUrl}leasing/${userEnterpriseRut()}/resumenCliente`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

const getBienes = (params : Request.Leasing.Bienes) : Promise<Response.Leasing.Bienes> => {
  params.numeroPagina = params.numeroPagina ? params.numeroPagina : 1;
  params.tamanoPagina = params.tamanoPagina ? params.tamanoPagina : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}leasing/${userEnterpriseRut()}/bienes?${queryString}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

const getContratos = (params : Request.Leasing.ContratosVigentes) : Promise<Response.Leasing.ContratosVigentes> => {
  params.numeroPagina = params.numeroPagina ? params.numeroPagina : 1;
  params.tamanoPagina = params.tamanoPagina ? params.tamanoPagina : 10;
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}leasing/${userEnterpriseRut()}/contratos?${queryString}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

const getCuotas = (params : { contrato : number }) : Promise<Response.Leasing.Cuotas> => {
  const queryString = buildQueryString(params, ['rut']);
  return instance
    .get(`${apiUserUrl}leasing/${userEnterpriseRut()}/cuotas?${queryString}`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);
};

export const solicitudCertificado = (params : Request.Leasing.SolicitudCertificadoEjecutivo) : Promise<boolean> =>
  instance
    .post(`${apiUserUrl}leasing/${userEnterpriseRut()}/solicitudCertificado`,
      params,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => response.data);

export const denunciarSiniestro = (payload : Request.Leasing.DenunciaSiniestro, cedula : File, padron : File, licencia : File, formulario : File) : Promise<boolean> => {
  const url = `${apiUserUrl}leasing/${userEnterpriseRut()}/denunciaSiniestro`;
  const proms = [getBase64Promise(cedula), getBase64Promise(padron), getBase64Promise(licencia), getBase64Promise(formulario)];
  return Promise.all(proms).then((resps : Payloads.ContenidoArchivo[]) => {
    payload.cedulaIdentidad = resps[0].content.split(',')[1];
    payload.padron = resps[1].content.split(',')[1];
    payload.licencia = resps[2].content.split(',')[1];
    payload.formulario = resps[3].content.split(',')[1];
    return instance.post(url, payload, { headers: authHeader() })
                   .catch(error => error)
                   .then(response => response.data);
  });
};

export const formularioSiniestro = (params : Request.Leasing.FormularioSiniestro) => {
  return instance
    .get(`${apiUserUrl}leasing/${userEnterpriseRut()}/formularioSiniestro`,
      {
        headers: authHeader()
      }
    )
    .catch(error => error)
    .then(response => descargaArchivo({
      bytes: response.data,
      nombre: 'FormularioSiniestro.docx'
    }));
};


export default { getBienes, getContratos, getCuotas };

import { defaultFont } from '../generalStyle';

import customDropdownStyle from './customDropdownStyle';
import FactotalTheme from '../../FactotalTheme';
import { Theme } from '@material-ui/core';
import scssTool from '../../scssTool';

const headerLinksStyle = (theme : Theme) => ({
  ...customDropdownStyle(theme),
  search: {
    margin: '0',
    paddingTop: '7px',
    paddingBottom: '7px'
    /*[theme.breakpoints.down("sm")]: {
      margin: "10px 15px",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "10px 15px",
      width: "auto"
    }*/
  },
  searchInput: {
    paddingTop: '2px'
  },
  linkText: {
    zIndex: 4,
    ...defaultFont,
    fontSize: '14px',
    margin: '0!important',
    textTransform: 'none' as 'none'
  },
  linkTextSalir: {
    zIndex: 4,
    fontSize: '.875em',
    margin: '0!important',
    textTransform: 'none' as 'none'
  },
  buttonLinkSalir: {

  },
  buttonLink: {

  },
  searchButton: {

  },
  top: {
    zIndex: 4
  },
  searchIcon: {
    width: '17px',
    zIndex: 4
  },
  links: {
    width: '20px',
    height: '20px',
    zIndex: 4
  },
  notifications: {
    zIndex: 4,
    position: 'absolute' as 'absolute',
    top: '-3px',
    border: '1px solid #FFF',
    right: '-5px',
    fontSize: '.75em',
    background: FactotalTheme.palette.primary.main,
    color: '#FFFFFF',
    minWidth: '16px',
    height: '16px',
    borderRadius: '10px',
    textAlign: 'center' as 'center',
    lineHeight: '1.8em',
    verticalAlign: 'middle',
    display: 'block'
  },
  managerClasses: {
    display: 'inline-block'
    /*[theme.breakpoints.up("md")]: {
      display: "inline-block"
    }*/
  },
  headerLinksSvg: {
    width: '20px !important',
    height: '20px !important'
  },
  btnAtencionCliente: {
    border: '1px solid #94C11F',
    padding: '0px 16px',
    height: '38px'
  },
  btnAtencionClienteSpan: {
    ...scssTool.font(12, 400, 25),
    color: '#94C11F',
    '& img': {
      marginRight: '12px'
    }
  }
});

export default headerLinksStyle;

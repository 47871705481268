import React from 'react';
import Typography from '@material-ui/core/Typography';

class TabContainer extends React.Component {

  render() {
    return (
      <Typography component="div">
        {this.props.children}
      </Typography>
    );
  }
}

export default TabContainer;

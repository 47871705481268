import React, { FC } from 'react';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import scssTool from '../../../styles/scssTool';

const borderSeparatorStyle = (theme: Theme) => ({
  borderSeparator: {
    height: '100%',
    ...scssTool.flex('center', 'row', 'center'),
    marginRight: '18px',
    '& span': {
      borderRight: '1px dashed #000000',
      width: '1px',
      height: '90%',
    },
  },
});

interface IBorderSeparator extends WithStyles<typeof borderSeparatorStyle> {}

const BorderSeparator: FC<IBorderSeparator> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.borderSeparator}>
      <span></span>
    </div>
  );
};

export default withStyles(borderSeparatorStyle)(BorderSeparator);

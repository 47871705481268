const SDropzone = (theme) => ({
  dropContainer: {
    maxWidth: 611,
    textAlign: 'center',
    borderRadius: 8,
    border: '2px dashed #DBDBDB',
    // marginTop: '30px',
    // [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
    //   height: 282,
    // },
  },
  inputStyle: {
    height: '42px',
    width: '50%',
    background: '#ffffff',
    border: '1px solid #95989A',
    borderRadius: '5px',
  },
  uploadButtonContainer: {
    position: 'absolute',
    top: '3px',
    right: '3px'
  },
  greenButton: {
    background: '#82BC00',
    borderRadius: '4px',
    color: '#fff',
    padding: '8px 24px',
    height: 36,
    fontSize: '12px',
    textTransform: 'capitalize',
    '&:hover': {
      color: '#82BC00',
    },
    '&:disabled': {
      color: '#2E2A25',
      background: 'rgba(19, 1, 1, 0.3)',
      borderColor: 'rgba(19, 1, 1, 0.3)',
    },
  },
  container: {
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    position: 'relative',
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      paddingLeft: 40,
      paddingRight: 40,
      paddingBottom: 30,
    },
  },
  icon: {
    color: '#EAEAEA',
    fontSize: 100,
    // [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
    //   marginTop: 44,
    // },
  },
  text: {
    fontStyle: 'normal',
    fontSize: 24,
    lineHeight: '29px',
    textAlign: 'center',
    marginBottom: '16px',
    color: '#555759'
  },
  subtitle: {
    fontStyle: 'normal',
    textAlign: 'center',
    display: 'block !important',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '17px',
    color: '#8C8C8C'
  }
});

export default SDropzone

import React from 'react';
import { formatNumber } from '../../../../../helpers/FormatNumber';
import '../../../../sharedv2/custom_theme.scss';
import EstadoDocumento from './EstadoDocumento';
import { connect } from 'react-redux';
import fxOpActions from '../actions';
import TableContainer from '../../../../sharedv2/Table/TableContainer';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../../../styles/jss/components/extendedFormsStyle';
import AccionesDocumento from './AccionesDocumento';
import DetalleOperacionModal from './DetalleDocumentoModal';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../../config/rootReducer';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core';

interface IDocumentTable extends WithStyles<typeof HistoricoStyle> {
  classes: any;
  documentos: Dto.BackOffice.Documento[];
  puedeCeder: boolean;
  inicioCesion: boolean;
  verificarPreFactibilidadDocumento: (id: string) => Promise<Factoring.Mensaje.Respuesta.Documento.Factibilidad>;
  descargarAdjunto: (idDocumento: string, nombre: string) => void;
}

const tableInfo = (puedeCeder: boolean, inicioCesion: boolean,
                   descargarAdjunto: (idDocumento: string, nombre: string) => void,
                   verDetalle: (doc: Dto.BackOffice.Documento) => void): TC.Fila[] => [
  { column: 'NROº', relation: 'numeroDocumento' },
  { column: 'Documento', relation: 'tipoDteDesc' },
  { column: 'Deudor', relation: 'razonSocialDeudor' },
  {
    column: 'Monto Total',
    align: 'right',
    relation: 'montoDocumento',
    renderData: (doc: Dto.BackOffice.Documento) => (<>${formatNumber(doc.montoDocumento)}</>)
  },
  {
    column: 'Estado',
    relation: 'idEstado',
    renderData: (doc: Dto.BackOffice.Documento) => (<EstadoDocumento documento={doc} puedeCeder={puedeCeder} />)
  },
  {
    column: 'Archivos',
    relation: 'archivos',
    hidden: !inicioCesion,
    renderData: (doc: Dto.BackOffice.Documento) => (
      <AccionesDocumento documento={doc} descargarAdjunto={descargarAdjunto}
                         verDetalle={verDetalle} />)
  }
];

interface IState {
  detalleOperacionModalState: boolean,
  documento: Dto.BackOffice.Documento | undefined
}

class DocumentTable extends React.Component<IDocumentTable, IState> {

  constructor(props: IDocumentTable) {
    super(props);
    this.state = {
      detalleOperacionModalState: false,
      documento: undefined
    };
    this.verificarPreFactibilidadDocumentos = this.verificarPreFactibilidadDocumentos.bind(this);
  }

  componentDidMount() {
    this.setState({});
    if (this.props.puedeCeder) {
      this.verificarPreFactibilidadDocumentos();
    }
  }

  verificarPreFactibilidadDocumentos = () => {
    this.props.documentos.filter(d => d.idEstado === 0)
        .forEach(d =>
          this.props.verificarPreFactibilidadDocumento(d.id)
        );
  };

  verDetalle = (doc: Dto.BackOffice.Documento) => {
    this.setState((state: IState) => ({
      documento: doc,
      detalleOperacionModalState: true
    }));
  };

  handleDetalleOperacionModalclose = () => {
    this.setState((state: IState) => ({
      detalleOperacionModalState: false
    }));
  };

  render() {
    const { classes, documentos, puedeCeder, inicioCesion } = this.props;
    return (
      <React.Fragment>
        <TableContainer
          classes={classes}
          data={documentos}
          pagination={undefined}
          tableInfo={tableInfo(puedeCeder, inicioCesion, this.props.descargarAdjunto, this.verDetalle)}
        >
        </TableContainer>
        <DetalleOperacionModal
          open={this.state.detalleOperacionModalState}
          onClose={this.handleDetalleOperacionModalclose}
          documento={this.state.documento}
        />
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({
  verificarPreFactibilidadDocumento: (id: string) => dispatch(fxOpActions.verificarPreFactibilidadDocumento(id))
});

export default withStyles(HistoricoStyle)(connect(null, mapDispatchToProps)(DocumentTable));

import { Action, combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { IMainState, reducer as main } from '../components/main/reducer';
import { ICarteraState, reducer as cartera } from '../components/factoring/cartera/reducer';
import { IEnProcesoOperacionesState, reducer as operacionesEnProceso } from '../components/factoring/operaciones/enProceso/reducer';
import { ISolicitudOperacionesState, reducer as operacionesSolicitud } from '../components/factoring/operaciones/solicitud/reducer';
import { reducer as operacionesHistoricas } from '../components/factoring/operaciones/historicas/reducer';
import { ILeasingState, reducer as leasing } from '../components/leasing/reducer';
import { IVendorState, reducer as vendor } from '../components/vendor/reducer';
import { reducer as login } from '../components/general/login/reducer';
import {reducer as subscriber } from '../components/general/Subscriber/reducer';
import { reducer as dashboard } from '../components/factoring/reducer';
import { reducer as excedentesDisponibles } from '../components/factoring/excedentes/disponibles/reducer';
import { reducer as excedentesHistorico } from '../components/factoring/excedentes/historico/reducer';
import { IRegistroCivilState, reducer as registroCivil } from '../components/registroVehiculos/reducer';
import { ICobranzaState, reducer as cobranza } from '../components/factoring/cobranza/reducer';
import { reducer as facturasEmitidas } from '../components/factoring/facturas/reducer';
import { IAdminDatosUsuarios, reducer as adminUser } from '../components/admin/usuario/reducer';
import { reducer as adminEnterprise } from '../components/admin/empresa/reducer';
import { IFactorXState, reducer as factorx } from '../components/general/fxEmpresa/reducer';
import { History } from 'history';

export interface ActionT<T> extends Action<string> {
  payload: T;
}

export type AppState = {
  main: IMainState,
  leasing: ILeasingState,
  vendor: IVendorState,
  registroCivil: IRegistroCivilState,
  cartera: ICarteraState,
  dashboard: State.IDashboard,
  factorx: IFactorXState,
  cobranza: ICobranzaState,
  operacionesSolicitud: ISolicitudOperacionesState,
  operacionesEnProceso: IEnProcesoOperacionesState,
  adminUser: IAdminDatosUsuarios
}

const RootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  main,
  leasing,
  vendor,
  registroCivil,
  cartera,
  login,
  subscriber,
  dashboard,
  excedentesDisponibles,
  operacionesEnProceso,
  operacionesSolicitud,
  operacionesHistoricas,
  cobranza,
  facturasEmitidas,
  excedentesHistorico,
  adminUser,
  adminEnterprise,
  factorx
});

export default RootReducer;

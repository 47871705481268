import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DropzoneContainer from '../../../sharedv2/Dropzone/Dropzone';
import FormErrorText from '../../../sharedv2/FormErrorText/FormErrorText';
import TextField from '@material-ui/core/TextField';
import UploadedCertificateListItem from './UploadedCertificateListItem';
import Grid from '@material-ui/core/Grid';
import Loader from '../../../shared/Loader/Loader';
import { connect } from 'react-redux';
import fxActions from '../actions';
import { SAcceptYieldModal } from '../../../factoring/operaciones/enProceso/style';
import { Checkbox } from '@material-ui/core';
import TermsAndConditions from '../../../sharedv2/TermsAndConditions/TermsAndConditions';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { WithStyles } from '@material-ui/core/styles/withStyles';


interface IAgregarCertificadoDialogProps extends WithStyles<typeof SAcceptYieldModal> {
  uploadCertificate: (payload: Payloads.ArchivoCertificado) => Promise<Mensaje.AgregarCertificadoRespuesta>,
  onSave: (certificado: File, clave: string) => void
}

interface IState {
  terminosCondiciones: boolean,
  open: boolean,
  password: string,
  file: File | undefined,
  estaCargando: boolean,
  isValid: boolean | undefined,
  termsModal: boolean,
  mensajeError: string
}

class AgregarCertificadoDialog extends React.Component<IAgregarCertificadoDialogProps, IState> {

  constructor(props: IAgregarCertificadoDialogProps) {
    super(props);

    this.state = {
      terminosCondiciones: false,
      open: false,
      password: '',
      file: undefined,// new File([''], ''),
      estaCargando: false,
      mensajeError: '',
      isValid: undefined,
      termsModal: false
    };

  }


  onDropFile = (acceptedFiles: File[], rejectedFiles: File[]) => {
    if (acceptedFiles.length) {
      this.setState({
        file: acceptedFiles[0],
        isValid: undefined
      });
    }
  };

  handleSubmit = async () => {
    const certificado = this.state.file;
    if (certificado) {
      this.setState({ estaCargando: true });
      const respuesta = await this.props.uploadCertificate({
        password: this.state.password,
        file: certificado
      });
      this.setState({
        isValid: respuesta.exito,
        mensajeError: respuesta.mensaje,
        file: respuesta.exito ? undefined : certificado,
        estaCargando: false
      });
      if (respuesta.exito) this.props.onSave(certificado, this.state.password);
    }
  };

  handleDeleteFile = () => {
    this.setState({ file: undefined });
  };

  changeTermsConditions = (checked: boolean) => {
    this.setState({
      terminosCondiciones: checked
    });
  };

  handlerTermsModal = () => {
    this.setState({
      termsModal: true
    });
  };

  handleCloseTerms = () => {
    this.setState({
      termsModal: false
    });
  };

  aceptarYCerrar = () => {
    this.changeTermsConditions(true);
    this.handleCloseTerms();
  };

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <DialogContent className={classes.dialogContent}>
          <DropzoneContainer
            title='Arrastre su certificado en formato .PFX para cargarlo a la plataforma'
            accept='.pfx'
            type='PFX'
            onDrop={this.onDropFile}
            name='files' />
          {this.state.file ? (
            <Grid item xs={12}>
              <UploadedCertificateListItem document={this.state.file} onDelete={this.handleDeleteFile} />
            </Grid>) : undefined
          }
          <div className={classes.passwordForm}>
            <div className={classes.titlePass}>Ingrese Contraseña</div>
            <TextField
              id='outlined-name'
              label='Contraseña'
              className={classes.textField}
              value={this.state.password}
              placeholder='**********'
              type={'password'}
              error={this.state.isValid === false}
              margin='normal'
              variant='outlined'
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => this.setState({ password: ev.target.value })}
            />
            {this.state.isValid === false &&
            <FormErrorText>{this.state.mensajeError}</FormErrorText>
            }
          </div>
          <Grid
            container
            className={classes.gridDialog}
            justify='flex-start'
            style={{ marginTop: 15 }}
          >
            <Grid item xs={12}>
              <div className={classes.dialogChekbox}>
                <Checkbox
                  color='primary'
                  checked={this.state.terminosCondiciones}
                  onChange={(event) => this.changeTermsConditions(event.target.checked)}
                  inputProps={{ 'aria-label': 'Selecionar' }}
                />
                <div className={classes.termsLink}>
                  Acepto los <a onClick={this.handlerTermsModal}>Términos y Condiciones</a>
                </div>
              </div>
            </Grid>
          </Grid>
          <TermsAndConditions open={this.state.termsModal} onClose={this.handleCloseTerms} onAccept={this.aceptarYCerrar} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', margin: '4px 0px' }}>
          <Button variant='outlined' onClick={this.handleSubmit} color='primary'
                  disabled={!this.state.file || !this.state.password || !this.state.terminosCondiciones || this.state.estaCargando}
                  className={classes.greenButton}>
            {this.state.isValid === true ? (
              <span style={{ display: 'flex' }}>Certificado aceptado</span>
            ) : (
              <span>Cargar</span>
            )}
          </Button>
          {this.state.estaCargando && <Loader />}
        </DialogActions>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  uploadCertificate: (payload: Payloads.ArchivoCertificado) => dispatch(fxActions.subirCertificado(payload))
});


export default withStyles(SAcceptYieldModal)(connect(null, mapDispatchToProps)(AgregarCertificadoDialog));

import { Theme } from '@material-ui/core';

const EliminarModalStyle = (theme : Theme) => ({
  rootDeleteModal: {
    '& h1': {
      color: '#000000',
      fontSize: '30px',
      textAlign: 'center' as 'center',
      fontWeight: 'normal' as 'normal'
    }
  },
  dialogPaper: {
    width: '750px',
    height: '300px',
    border: '3px solid #82BC00',
    boxSizing: 'border-box' as 'border-box',
    borderRadius: '16px',
    overflow: 'inherit',
    padding: '50px 40px 40px 40px',
    lineHeight: '21px',
    position: 'absolute' as 'absolute'
  },
  dialogPaperDelete: {
    border: '3px solid #CD2F2F'
  },
  checkIcon: {
    position: 'absolute' as 'absolute',
    width: '100px',
    height: '80px',
    left: '50%',
    top: '-40px',
    background: 'rgba(130, 188, 0, 0.8)',
    borderRadius: '13px',
    marginLeft: '-50px',
    textAlign: 'center' as 'center',
    '& svg': {
      color: '#fff',
      fontSize: '80px'
    }
  },
  chekIconDelete: {
    background: 'rgba(205, 47, 47, 0.9)'
  },
  messageDelete: {
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center' as 'center',
    margin: '32px 0px',
    color: '#8C8C8C',
    '& span': {
      color: '#000000'
    },
    '& .red': {
      color: '#CD2F2F'
    }
  },
  redButton: {
    background: '#fff',
    border: '1px solid #CD2F2F',
    borderRadius: '5px',
    color: '#CD2F2F',
    width: 131,
    height: 45,
    '&:hover': {
      background: '#CD2F2F',
      color: '#fff'
    }
  },
  greyButton: {
    background: '#EFF0F0',
    borderRadius: '5px',
    width: 131,
    height: 45
  }
});

export { EliminarModalStyle };

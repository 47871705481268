// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

// datepicker
import 'react-datetime/css/react-datetime.css';

// Table
import ReactTable from 'react-table';
import PaginationBase from '../../shared/Pagination/PaginationBase';
import Moment from 'moment/moment';
import moment from 'moment/moment';

// componentes material ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';

// componentes custom
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';
import Typography from '@material-ui/core/Typography';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../shared/RangeCalendar/RangeCalendar';

// @material-ui/icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Estilo para tabla
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';

// Helpers
import { formatNumber } from '../../../helpers/FormatNumber';
import { WithStyles } from '@material-ui/core';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

interface IEventos {
  date : string,
  comment : string
}

interface IHistorico {
  numoperacion : number,
  fechoperacion : moment.Moment,
  documentos : number,
  age : number,
  deudor : string,
  tipodocumento : string,
  estadodocumento : string,
  valdocumento : number,
  valfinanciado : number,
  retencion : number,
  totcosoperacion : number,
  CtaCliente : number,
  liqgirado : number,
  fechVencimiento : moment.Moment,
  fechUltPago : moment.Moment,
  monpagado : number,
  saldocumento : number,
  detexcedentes : string,
}

interface IRecord<T> {
  _original : T
}

interface IRowRecord<T> {
  value : any,
  original : T
}

const newHistorico = () : IHistorico => ({
  numoperacion: Math.floor(Math.random() * 100),
  fechoperacion: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  documentos: Math.floor(Math.random() * 30),
  age: Math.floor(Math.random() * 30),
  deudor: 'Deudor',
  tipodocumento: 'Factura',
  estadodocumento: 'Vigente',
  valdocumento: Math.floor(Math.random() * 100),
  valfinanciado: Math.floor(Math.random() * 100),
  retencion: Math.floor(Math.random() * 100),
  totcosoperacion: Math.floor(Math.random() * 100),
  CtaCliente: Math.floor(Math.random() * 100),
  liqgirado: Math.floor(Math.random() * 100),
  fechVencimiento: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  fechUltPago: Moment(new Date(+(new Date()) - Math.floor(Math.random() * 10000000000))),
  monpagado: Math.floor(Math.random() * 100),
  saldocumento: Math.floor(Math.random() * 100),
  detexcedentes: ''
});

export function makeData(len : number) {
  let arr : IHistorico[] = [];
  for (let r = 0; r < len; r++) {
    arr.push(newHistorico());
  }
  return arr;
}

interface IProps extends WithStyles<typeof HistoricoStyle> {
  dispatch : any
}

interface IState extends State.ICalendar {
  expanded : boolean,
  data : IHistorico[],
  openCalendar : boolean,
  anchorElCalendar? : any,
  estadoCuenta : string,
  numeroContrato : string,
  anchorElPopOver? : any
}

class CuotasLeasing extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);

    this.state = {
      expanded: true,
      data: makeData(15),
      openCalendar: false,
      anchorElCalendar: null,
      calendarValue: {
        desde: '',
        hasta: ''
      },
      estadoCuenta: '',
      numeroContrato: ''
    };
  }

  handleExpandClick = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter : any, row : any, column : any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };

  handleClickTable = (event : React.ChangeEvent<any>) => {
    const { currentTarget } = event;
    if (currentTarget && currentTarget.firstChild) currentTarget.firstChild.classList.add('active');
    this.setState({
      anchorElPopOver: currentTarget
    });
  };

  handleCloseTable = () => {
    if (this.state.anchorElPopOver && this.state.anchorElPopOver.firstChild) this.state.anchorElPopOver.firstChild.classList.remove('active');
    this.setState({
      anchorElPopOver: null
    });
  };

  handleStatesCalendar = (calendar : State.ICalendar) => {
    this.setState(calendar);
  };

  onChange = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeOnlyNumeric = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    var valor = e.target.value.replace(/[^0-9]+/gi, '')
                 .replace('(', '')
                 .replace(')', '')
                 .replace('|', '');
    e.target.value = valor;
    // this.setState({ [e.target.name]: e.target.value });
    this.onChange(e);
  };

  cleanFilters() {
    this.setState({
      estadoCuenta: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      numeroContrato: ''
    }, () => {
      //this.handleFiltrar();
    });
  }

  render() {
    const { classes } = this.props;
    // const { data } = this.state;
    const { anchorElPopOver, openCalendar, anchorElCalendar, calendarValue } = this.state;
    const open = Boolean(anchorElPopOver);
    const columnsTablePopUp = [
      {
        Header: 'Fecha',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        accessor: 'date',
        width: 100,
        id: 'date',
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign
      },
      {
        Header: 'Comentario',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        accessor: 'comment',
        width: 300,
        id: 'comment',
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign,
        Cell: ((row : IRowRecord<IEventos>) => {
          let text = row && row.original && row.original.comment ? row.original.comment : '';
          return <div style={{ whiteSpace: 'normal' }}>{text}</div>;
        })
      }
    ];
    const data_tablePopUp : IEventos[] = [
      {
        date: '25/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '05/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '13/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '08/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      },
      {
        date: '02/08/2010',
        comment: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptate natus enim incidunt sunt magnam recusandae exercitationem dolorem, modi aliquid adipisci earum libero omnis fugiat amet facere? Cumque vero facilis nostrum.'
      }
    ];
    const columns = [
      {
        Header: 'N° CUOTA',
        accessor: 'numoperacion',
        headerClassName: classes.HeaderTable,
        //width: "100%",
        id: 'numoperacion',
        // filterable: false,
        className: classes.TDTable + ' ' + classes.TDTableNoOperacion
      },
      {
        Header: 'VALOR CUOTA',
        accessor: 'monpagado',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: (row : IRowRecord<IHistorico>) => {
          let value = row.value;
          return formatNumber(value);
        }
      },
      {
        Header: 'INTERÉS',
        accessor: 'valfinanciado',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: (row : IRowRecord<IHistorico>) => {
          let value = row.value;
          return formatNumber(value);
        }
      },
      {
        Header: 'AMORTIZACIÓN',
        accessor: 'liqgirado',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: (row : IRowRecord<IHistorico>) => {
          let value = row.value;
          return formatNumber(value);
        }
      },
      {
        Header: 'SALDO CAPITAL',
        accessor: 'age',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableRightAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableRightAlign,
        Cell: (row : IRowRecord<IHistorico>) => {
          let value = row.value;
          return formatNumber(value);
        }
      },
      {
        Header: 'FECHA VENCIMIENTO',
        accessor: 'fechoperacion',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        //width: 80,
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row : IRowRecord<IHistorico>) => <div>
          {row.original && row.original.fechoperacion ? Moment(row.original.fechoperacion)
            .format('DD/MM/YYYY') : ''}
        </div>
      },

      {
        Header: 'ESTADO CUOTA',
        accessor: 'documentos',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign
      },
      {
        Header: 'Nº CONTRATO',
        accessor: 'CtaCliente',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign
      },
      {
        Header: 'FECHA CONTRATO',
        accessor: 'fechoperacion',
        headerClassName: classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
        //width: 80,
        className: classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
        Cell: (row : IRowRecord<IHistorico>) => <div>
          {row.original && row.original.fechoperacion ? Moment(row.original.fechoperacion)
            .format('DD/MM/YYYY') : ''}
        </div>
      },
      {
        Header: 'FACTURA EMITIDA',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        //width: "100%",
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign,
        Cell: ((row : IRowRecord<IHistorico>) => {
          return <div>
            <div
              aria-haspopup="true"
              onClick={this.handleClickTable}
            >
              <SearchIcon className={classes.TDTableCenterIcon} />
            </div>
          </div>;
        })
      }
    ];
    return (
      <div>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorElPopOver}
          onClose={this.handleCloseTable}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <div className={classes.TablePopUpContainer}>
            <ReactTable
              ref="popoverTable"
              data={data_tablePopUp}
              filterable={false}
              columns={columnsTablePopUp}
              defaultPageSize={5}
              showPaginationBottom
              PaginationComponent={PaginationBase}
              previousText="Anterior"
              nextText="Siguiente"
              loadingText="cargando..."
              noDataText="Sin información"
              pageText="Pagina"
              ofText="de"
              rowsText="filas"
              resizable={false}
              style={{
                height: '270px' // forzar el scroll vertical
              }}
              defaultFilterMethod={this.defaultFilterMethod}
            />
          </div>
        </Popover>
        <div>
          <Breadcrumb category="Usted se encuentra en: Leasing / Cuotas" textAlign="right" />
          <Typography variant="h5" gutterBottom className={classes.sectionTitle}>
            Cuotas
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                action={(
                  <div>
                    <Tooltip title={this.state.expanded ? 'Colapsar' : 'Ampliar'} placement="top">
                      <IconButton
                        className={classnames(classes.expand, {
                          // [classes.expandOpen]: this.state.expanded,
                          [classes.filterButtonCollapse]: true
                        })}
                        onClick={this.handleExpandClick}
                        aria-expanded={this.state.expanded}
                        aria-label="Show more"
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                title={(
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h5" className={classes.filterTitle}>
                      Filtros de Búsqueda
                    </Typography>
                    <Button color="primary" variant="outlined" onClick={() => this.cleanFilters()} style={{ marginLeft: 14 }}>
                      Limpiar
                    </Button>
                  </div>
                )}
                classes={{
                  title: classes.filterTitle,
                  action: classes.filterExpandButton,
                  root: classes.filterCardHeader
                }}
              />
              <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="estadoCuenta"
                          name="estadoCuenta"
                          value={this.state.estadoCuenta}
                          onChange={this.onChange}
                          label="Estado Cuenta"
                          placeholder="Seleccione"
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <TextField
                          className={classes.filterFormtextField}
                          InputLabelProps={{ shrink: true }}
                          id="numeroContrato"
                          name="numeroContrato"
                          value={this.state.numeroContrato}
                          label="N&deg; Contrato"
                          placeholder="000000"
                          onChange={this.onChangeOnlyNumeric}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id="FchVto"
                            name="FchVto"
                            label="Fecha Contrato"
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem className={classes.filterFormButtonContainer}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant="outlined"
                            color="primary"
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

            <GridItem align="right" className={classes.gridPDFExcelButtonsSinPestaña}>
              <Tooltip title="Descargar PDF" placement="top">
                <IconButton>
                  <SVGIcons icon="pdf" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Descargar Planilla Excel" placement="top">
                <IconButton>
                  <SVGIcons icon="excel" />
                </IconButton>
              </Tooltip>

            </GridItem>

            <ReactTable
              ref="facturasTable"
              data={this.state.data}
              filterable={false}
              columns={columns}
              defaultPageSize={10}
              showPaginationBottom
              PaginationComponent={PaginationBase}
              previousText="Anterior"
              nextText="Siguiente"
              loadingText="cargando..."
              noDataText="Sin información"
              pageText="Pagina"
              ofText="de"
              rowsText="filas"
              resizable={false}
              style={{
                //height: "400px", // forzar el scroll vertical
              }}
              defaultFilterMethod={this.defaultFilterMethod}
            />

          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = (state : AppState) => ({
  isLoading: state.main.isLoading
});

const mapDispatchToProps = (dispatch : ThunkDispatch<any, any, AnyAction>) => ({
  dispatch: (action : AnyAction) => {
    dispatch(action);
  }
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(CuotasLeasing));

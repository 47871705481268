import React, { FC } from 'react';
import cx from 'classnames';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { menuSidebarStyle } from './menuSidebarStyle';
import IcoGreenCollapseArrow from '../../../images/icons/ico-green-collapse-arrow.svg';
import { AccountCircle } from '@material-ui/icons';

interface IFatherMenuItem extends WithStyles<typeof menuSidebarStyle> {
  miniActive? : boolean;
  title : string;
  openWhen : string[];
  click : () => any;
  collapse? : string;
  fatherCollapse : string;
  version? : string;
}

const FatherMenuItem : FC<IFatherMenuItem> = (props) => {
  const { classes, title, openWhen, click, collapse, fatherCollapse, version } =
    props;

  // Checkear acá cual está abierto
  const checkCollapse = (collapses : string[]) => {
    let f = false;
    collapses.forEach((c) => {
      if (collapse === c) {
        f = true;
      }
    });
    return f;
  };

  return (
    <div
      className={cx(classes.listItemTitle, {
        open: checkCollapse(openWhen),
        whiteVersion: version === 'white'
      })}
      onClick={click}
    >
      {version === 'white' &&
      <AccountCircle className={classes.accountCircle} />
      }
      {title}
      <img
        src={IcoGreenCollapseArrow}
        className={cx(classes.arrowtoogleCollapse, {
          active: collapse === fatherCollapse || checkCollapse(openWhen)
        })}
      />
    </div>
  );
};

export default withStyles(menuSidebarStyle)(FatherMenuItem);

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import HistoricoStyle from '../../../../../styles/jss/components/extendedFormsStyle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import TableContainer from '../../../../sharedv2/Table/TableContainer';
import FormatDateTime from '../../../../../helpers/FormatDateTime';
import { WithStyles } from '@material-ui/core';

interface IDetalleDocumentoModalProps extends WithStyles<typeof HistoricoStyle> {
  onClose : () => void,
  open : boolean,
  documento : Dto.BackOffice.Documento | undefined
}

interface IState {
  loading : boolean,
  activeTab : 0
}

class DetalleDocumentoModal extends React.Component<IDetalleDocumentoModalProps, IState> {

  constructor(props : IDetalleDocumentoModalProps) {
    super(props);
    this.state = {
      loading: false,
      activeTab: 0
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  loadingToogle = () => {
    this.setState((state) => ({
      loading: !state.loading
    }));
  };

  render() {
    const { open } = this.props;
    const { activeTab } = this.state;
    const {
      SDetalleOperacionModal,
      SDetalleOperacionModalDialog,
      headerDetalleOperacionModal,
      TableFTC,
      HeaderTableFCT,
      TableRowFCT,
      TableThFCT,
      TableRowBodyFCT,
      TableCellFCT,
      operacionCedidaActionsTableButton
    } = this.props.classes;

    const documento : Dto.BackOffice.Documento | undefined = this.props.documento;

    let eventos = documento ? documento.eventos : [];

    return (
      <Dialog onClose={this.handleClose} className={SDetalleOperacionModal}
              PaperProps={{ className: SDetalleOperacionModalDialog }} open={open}>
        <div className={headerDetalleOperacionModal}>Historial de eventos de cesión</div>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="tabs">
              {documento && <TableContainer classes={{
                TableFTC,
                HeaderTableFCT,
                TableRowFCT,
                TableThFCT,
                TableRowBodyFCT,
                TableCellFCT,
                operacionCedidaActionsTableButton
              }}
                                            data={eventos}
                                            pagination={undefined}
                                            tableInfo={[
                                              {
                                                column: 'Fecha',
                                                relation: 'fecha',
                                                renderData: (evt : Factoring.Dto.Operacion.Evento) => (<>{FormatDateTime(evt.fecha)}</>)
                                              },
                                              { column: 'Evento', relation: 'estado' },
                                              { column: 'Detalle ', relation: 'observacion' }

                                            ]}
              />}
            </div>
          </Grid>
        </Grid>
        <div>
          <Button className="secondary" onClick={() => this.handleClose()}>Cerrar</Button>
        </div>
      </Dialog>
    );
  }
}


export default withStyles(HistoricoStyle)(DetalleDocumentoModal);

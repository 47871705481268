import React from "react";

const scssTool = {
  family: {
    lato: "'Lato', sans-serif;",
    poppins: "'Poppins', sans-serif",
    larsseit: 'Larsseit',
  },
  font: (
    size?: number,
    fontWeight?: 300 | 400 | 500 | 600 | 700 | 800 | 900 | "bold",
    lineHeight?: number | "137.4",
    letterSpacing?: 0.15 | 0.5 | 0.25
  ) => {
    const options = {
      size: size || 14,
      lineHeight:
        lineHeight !== undefined ? lineHeight === "137.4" ? "137.4%" : `${lineHeight}px` : `137.4%`,
      fontWeight: fontWeight || 300,
      letterSpacing: letterSpacing || 0.15,
    };
    return {
      fontWeight: options.fontWeight,
      fontSize: `${options.size}px`,
      lineHeight: `${options.lineHeight}`,
      letterSpacing: `${options.letterSpacing}px`,
    };
  },
  flex: (
    align: "center" | "flex-start" | "flex-end" | "space-between",
    direction?: "row" | "column",
    justify?: "center" | "space-between" | "flex-end" | "flex-start" | "space-around" | "space-evenly",
  ) => {
    const options = {
      flexDirection: direction || "row",
      justify: justify || "initial",
    };
    return {
      display: "flex" as "flex",
      alignItems: align,
      flexDirection: options.flexDirection,
      justifyContent: options.justify
    };
  }
};

export default scssTool;

// TODO Implementar function onGrabar, si es que requiere parametros
import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  FormHelperText,
  IconButton,
  MenuItem,
  OutlinedInput,
  TextField
} from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useState, Fragment } from 'react';
import CloudIcon from '../../../../images/icons/cloud_upload_24px.svg';
import ArrowIcon from '../../../../images/icons/icons-bienes/arrow_right_24px_outlined.svg';
import dialogStyle from '../../../../styles/jss/components/dialogStyle';
import clsx from 'clsx';
import CargarDocumentosTabla from './CargarDocumentosTabla';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import DropzoneContainer from '../../../sharedv2/Dropzone/Dropzone';
import TableContainer from '../../../sharedv2/Table/TableContainer';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { TModeloVehiculo } from '../../Contrato/primeraInscripcion';
import registroCivilApi from '../../../../services/registroCivilApi';
import utilitarios from '../../../../helpers/utilitarios';

interface ICargarDocumentoModal extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  onCargarArchivo: () => any;
  open: boolean;
  inscripcion?: Dto.RegistroCivil.Inscripcion;
  empresa?: Dto.RegistroCivil.Empresa;
  contrato?: Dto.RegistroCivil.Contrato;
  tipoSolicitud: number;
  transferencia?: Dto.RegistroCivil.Transferencia;
}

const CargarDocumentoModal: FC<ICargarDocumentoModal> = (props) => {
  const { classes, onClose, open, onCargarArchivo, inscripcion, empresa, contrato, tipoSolicitud, transferencia } = props;
  const [result, setResult] = useState(false);
  const [repertorio, setRepertorio] = useState('1');
  const [enviado, setEnviado] = useState(false);
  const [reportorios, setRepertorios] = useState([
    {
      id: '1',
      titulo: 'Jurídico'
    },
    {
      id: '2',
      titulo: 'Otro'
    }
  ]);

  const cerrarModal = () => {
    setResult(false);
    props.onClose();
  };

  let respuestaSubidaInicial: Response.RegistroCivil.CargaDocumentos = {
    listaErrores: [],
    message: '',
    success: false
  };
  const [respuestaSubidaDocumentos, setRespuestaSubidaDocumentos] = useState<Response.RegistroCivil.CargaDocumentos | undefined>(undefined);
  const [documentos, setDocumentos] = useState<Comun.Dto.ArchivoContenido[]>([]);
  const [doctosRC, setDoctosRC] = useState<Dto.RegistroCivil.DocumentoRegistroCivil[]>([]);
  const [documentosDesplegar, setDocumentosDesplegar] = useState<Comun.Dto.ArchivoContenido[]>([]);

  /*useEffect(()=>{

    if(inscripcion && empresa && contrato){
      debugger;
      obtenerDocumentosInscripcion();
    }
    if(transferencia && empresa && contrato){
      obtenerDocumentosTransferencia();
    }

  }, [inscripcion?.idInscripcion, transferencia?.idTransferencia])*/

  useEffect(() => {
    obtenerDocumentosInscripcion();
  }, [inscripcion?.idInscripcion]);

  useEffect(() => {
    obtenerDocumentosTransferencia();
  }, [transferencia?.idTransferencia]);

  const eliminarArchivo = (idDocumento: string) => {
    if (idDocumento) {
      onDelete(Number(idDocumento));
    }

  };

  const obtenerDocumentosInscripcion = () => {
    if (inscripcion && empresa && contrato) {
      setDocumentosDesplegar([]);
      let request: Request.RegistroCivil.ObtenerDocumentosInscripcion = {
        idInscripcion: inscripcion.idInscripcion
      };
      registroCivilApi.obtenerDocumentosInscripcion(empresa.idEmpresa.toString(), contrato.idContrato.toString(), request)
                      .then(resp => {
                        if (resp && resp.success) {
                          let docArray: Comun.Dto.ArchivoContenido[] = [];
                          resp.documentos.map(doc => {
                            let ac: Comun.Dto.ArchivoContenido = {
                              id: doc.documentoId ? doc.documentoId.toString() : '1',
                              nombre: doc.nombreArchivo,
                              puedeEliminar: doc.puedeEliminar,
                              file: new File([''], 'filename'),
                              error: '',
                              fecha: doc.fechaDeCarga,
                              progreso: 0,
                              puedeDescargar: true
                            };
                            docArray.push(ac);
                          });
                          setDocumentos(docArray);
                          setDocumentosDesplegar(docArray);
                        }

                      });
    }
  };
  const obtenerDocumentosTransferencia = () => {
    if (transferencia && empresa && contrato) {
      setDocumentosDesplegar([]);
      let request: Request.RegistroCivil.ObtenerDocumentosTransferencia = {
        idTransferencia: transferencia.idTransferencia
      };
      registroCivilApi.obtenerDocumentosTransferencia(empresa.idEmpresa.toString(), contrato.idContrato.toString(), request)
                      .then(resp => {
                        if (resp && resp.success) {
                          let docArray: Comun.Dto.ArchivoContenido[] = [];
                          resp.documentos.map(doc => {
                            let ac: Comun.Dto.ArchivoContenido = {
                              id: doc.documentoId ? doc.documentoId.toString() : '1',
                              nombre: doc.nombreArchivo,
                              puedeEliminar: doc.puedeEliminar,
                              file: new File([''], 'filename'),
                              error: '',
                              fecha: new Date(doc.fechaDeCarga),
                              progreso: 0,
                              puedeDescargar: true
                            };
                            docArray.push(ac);
                          });
                          setDocumentos(docArray);
                          setDocumentosDesplegar(docArray);
                        }

                      });
    }
  };

  const handleBottom = async () => {
    if ((inscripcion || transferencia) && empresa && contrato && !enviado) {
      let request: Request.RegistroCivil.CargaDocumentos;
      if (inscripcion) {
        request = {
          patente: inscripcion.ppu,
          clasificacion: 1,
          idTransferencia: 1,
          documentos: [],
          idInscripcion: inscripcion.idInscripcion,
          tipoSolicitud: tipoSolicitud,
          numeroSolicitud: 1 //se llena en api
        };
      } else if (transferencia) {
        request = {
          patente: transferencia.vehiculo.ppu,
          clasificacion: 1,
          idTransferencia: transferencia.idTransferencia,
          documentos: [],
          idInscripcion: 1,
          tipoSolicitud: tipoSolicitud,
          numeroSolicitud: 1 //se llena en api
        };
      }
      const promesa = documentos.filter(a => a.puedeEliminar).map(doc =>
        getBase64Promise(doc.file).then(b64 => {
          let docrc: Dto.RegistroCivil.DocumentoRegistroCivil = {
            archivoBase64: b64,//getBase64(doc.file),
            nombreArchivo: doc.nombre,
            fechaDeCarga: doc.fecha,
            puedeEliminar: doc.puedeEliminar
          };
          request.documentos.push(docrc);
        }));

      await Promise.all(promesa);
      // @ts-ignore
      setDoctosRC(request.documentos);
      // @ts-ignore
      registroCivilApi.cargaDocumento(empresa.idEmpresa.toString(), contrato.idContrato.toString(), request)
                      .then(resp => {
                        setEnviado(true);
                        if (resp && resp.success) {
                          setRespuestaSubidaDocumentos(resp);

                        }
                        console.log('handleBottom');
                        //setResult(true);
                        //debugger;
                        if (!result) {
                          setResult(!result);
                        } else {
                          //onCargarArchivo();
                        }
                      });


    }
  };

  const getBase64Promise = (file: Blob): Promise<string> => new Promise(async resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result;
      if (result && typeof result === 'string') {
        resolve(result.split(',')[1]);
      }
    };
  });

  const handleInputChange = (select: any) => {
    console.log('handleInputChange');
    console.log(select.target.value);
    setRepertorio(select.target.value);
  };

  const handlerAgregarArchivo = (select: any) => {
    console.log('handlerAgregarArchivo');
  };

  const onDropFile = (acceptedFiles: File[], rejectedFiles: File[]) => {
    const rrw = {};
    const t1 = () => 12;
    const ltr = 'letra';
    const t2 = () => 14;
    const rf = true;
    const mezcla = (rrw && t2()) || (rf && ltr && t1());

    if (acceptedFiles.length) {
      acceptedFiles.forEach((file) => {
        const archivo: Comun.Dto.ArchivoContenido = {
          id: file.lastModified.toString(),
          nombre: file.name,
          puedeEliminar: true,
          file: file,
          error: '',
          fecha: utilitarios.convertDateToStringDMY(new Date()),
          progreso: 0,
          puedeDescargar: false
        };
        updateListUploadingDocuments(archivo);
        setDocumentosDesplegar(documentosDesplegar => [...documentosDesplegar, archivo]);
      });
    }
    if (rejectedFiles.length) {
      console.log('fileError: true');
    }
  };


  const onDelete = (id: any) => {
    const currentDocumentos = [...documentosDesplegar];
    let idx = currentDocumentos.findIndex(a => a.id === id.toString());
    currentDocumentos.splice(idx, 1);
    setDocumentosDesplegar(currentDocumentos);

    const currentDocumentosSubir = [...documentos];
    let idxSubir = currentDocumentosSubir.findIndex(a => a.id === id.toString());
    currentDocumentosSubir.splice(idx, 1);
    setDocumentos(currentDocumentosSubir);
  };

  const updateListUploadingDocuments = (archivo: Comun.Dto.ArchivoContenido) => {
    setDocumentos(documentos => [...documentos, archivo]);
    let dc = documentos;
    //console.log('updateListUploadingDocuments');
  };
  const tableInfo = [
    {
      column: 'Nro',
      relation: 'idDocumento',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.id
    },
    /*{
      column: 'Nro Repertorio',
      relation: 'nroRepertorio',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: any) => documento.nroRepertorio,
    },*/
    /*{
      column: 'Tipo Doc',
      relation: 'tipoDoc',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.fecha,
    },*/
    /*{
      column: 'Clasificación',
      relation: 'clasificacion',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: any) => documento.clasificacion,
    },*/
    {
      column: 'Fecha ingreso',
      relation: 'fechaIngreso',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.fecha
    },
    {
      column: 'Nombre archivo',
      relation: 'nombreArchivo',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.nombre
    },
    {
      column: 'Acciones',
      relation: '',
      headAlign: 'right',
      renderData: (documento: Comun.Dto.ArchivoContenido) => (
        <div style={{ display: 'flex' as 'flex', justifyContent: 'flex-end' as 'flex-end' }}>
          {documento.puedeDescargar ?
            <IconButton onClick={() => onDescargar(documento.id)}>
              <GetAppIcon />
            </IconButton> :
            <IconButton onClick={() => eliminarArchivo(documento.id)}>
              <DeleteOutlineIcon />
            </IconButton>
          }

        </div>
      )
    }
  ];
  const onDescargar = (idDocumento: string) => {
    if (empresa && contrato) {
      let request: Request.RegistroCivil.DescargarDocumento = {
        idDocumento: Number(idDocumento)
      };
      if (inscripcion) {
        request.idInscripcion = inscripcion.idInscripcion;
      }
      if (transferencia) {
        request.idTransferencia = transferencia.idTransferencia;
      }
      registroCivilApi.descargarDocumento(empresa.idEmpresa.toString(), contrato.idContrato.toString(), request)
                      .then(resp => {
                        if (resp && resp.success) {
                          utilitarios.descargaArchivo(resp.documento.contenido, resp.documento.nombreArchivo);

                        }

                      });
    }

  };

  const obtenerOperaciones = (pagina: number, tamano: number) => {
    console.log('obtenerOperaciones');
  };
  /*const tableInfo = [
    {
      column: 'Nro',
      relation: 'idDocumento',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.id,
    },
    {
      column: 'Nro Repertorio',
      relation: 'nroRepertorio',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: any) => documento.nroRepertorio,
    },
    {
      column: 'Tipo Doc',
      relation: 'tipoDoc',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.fecha,
    },
    {
      column: 'Clasificación',
      relation: 'clasificacion',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: any) => documento.clasificacion,
    },
    {
      column: 'Fecha ingreso',
      relation: 'fechaIngreso',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.fecha,
    },
    {
      column: 'Nombre archivo',
      relation: 'nombreArchivo',
      align: 'center',
      headAlign: 'center',
      renderData: (documento: Comun.Dto.ArchivoContenido) => documento.nombre,
    },
    {
      column: 'Acciones',
      relation: '',
      headAlign: 'right',
      renderData: (documento: Comun.Dto.ArchivoContenido) => (
        <div style={{ display: 'flex' as 'flex', justifyContent: 'flex-end' as 'flex-end' }}>
          <IconButton onClick={() => onDescargar(documento.id)}>
            <GetAppIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(documento.id)}>
            <DeleteOutlineIcon />
          </IconButton>
        </div>
      ),
    },
  ];*/
  /*const onDescargar = (idDocumento: string) => {
    console.log('on Descargar');
  }*/

  return (
    <Dialog onClose={onClose} className={classes.root} open={open} PaperProps={{ className: clsx(classes.dialogPaperGreyVariant, 'responsive') }}>
      <div className={[classes.containerGreyVariant, classes.heightAuto].join(' ')}>
        <div className={classes.floatingHeaderIcon}>
          <img src={CloudIcon} alt='' />
        </div>
        <DialogContent
          classes={{
            root: classes.dialogContent
          }}>
          {!result && (
            <React.Fragment>
              <div className={classes.NewtitleModal}>Cargar documento</div>
              <div className={classes.subtitleModal}>Cargue los documentos que desea adjuntar a la solicitud.</div>
              <div className={classes.containerTable}>
                {/*<ButtonBase className={classes.borderButton} onClick={handlerAgregarArchivo}>
                  Agregar archivo
                </ButtonBase>*/}
                {/*<CargarDocumentosTabla documentos={documentosDesplegar} empresa={empresa} contrato={contrato} inscripcion={inscripcion} transferencia={transferencia} onEliminarArchivo={eliminarArchivo}/>*/}
                {/*<TableContainer classes={classes} data={documentos} tableInfo={tableInfo} pagination={undefined} />*/}
                <TableContainer classes={classes} data={documentosDesplegar} tableInfo={tableInfo} obtenerDatos={obtenerOperaciones} pagination={undefined} />
              </div>
              <div className={classes.containerSeccion}>
                {/*<div className={classes.inputContainer}>
                  <div className={classes.labelnputField}>Nro Repertorio</div>
                  <FormControl required variant='outlined' className={clsx(classes.textField, classes.textFieldMargin)}>
                    <Select name='juridica' displayEmpty value={repertorio} onChange={(value) => handleInputChange(value)} input={<OutlinedInput name='juridica' labelWidth={0} id='outlined-age-native-simple' />}>
                      {reportorios.map((reper) => {
                        return (
                          <MenuItem key={reper.id} value={reper.id}>
                            {reper.titulo}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {reportorios.length === 0 && <FormHelperText className={classes.errorLabel}>Debe seleccionar un juridico</FormHelperText>}
                  </FormControl>
                </div>*/}

                {/*<div className={classes.inputContainer}>*/}
                <div className={classes.labelnputField}>Archivo</div>
                <DropzoneContainer title='Arrastre los archivos' subtitle='que desea enviar' accept='' type='' onDrop={onDropFile} name='files' classes={{}}
                                   inputStyle={'true'} />
              </div>
              {/*</div>*/}
            </React.Fragment>
          )}
          {result && respuestaSubidaDocumentos?.success && (
            <React.Fragment>
              <div className={classes.bigTitle}>Ingreso Archivo</div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Fecha: {doctosRC[0].fechaDeCarga}</div>
              </div>
              {
                doctosRC.map(docto =>
                  <React.Fragment key={docto.documentoId}>
                    <div className={classes.containerInfo}>
                      <img src={ArrowIcon} alt='' />
                      <div className={classes.textinfo}>Solicitud: Ingreso Archivo {docto.nombreArchivo}</div>
                    </div>
                    <div className={classes.containerInfo}>
                      <img src={ArrowIcon} alt='' />
                      <div className={classes.textinfo}>Estado: Carga exitosa</div>
                    </div>
                  </React.Fragment>
                )
              }

            </React.Fragment>
          )}
          {result && !respuestaSubidaDocumentos?.success && (
            <React.Fragment>
              <div className={classes.bigTitle}>Ingreso Archivo</div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Fecha: {new Date()}</div>
              </div>
              {/*{
                doctosRC.map(docto=>
                  <>
                  </>
                )
              }*/}
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                <div className={classes.textinfo}>Solicitud: Ingreso Archivos</div>
              </div>
              <div className={classes.containerInfo}>
                <img src={ArrowIcon} alt='' />
                {respuestaSubidaDocumentos?.listaErrores.map((a, i) =>
                  <React.Fragment key={i}>
                    <div className={classes.textinfo}>Error: {a}</div>
                  </React.Fragment>
                )}

              </div>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button variant='outlined' color='primary' className={classes.greenButton} onClick={handleBottom}>
            {/*{!result?'Guardar':'Cerrar'}*/}
            Guardar
          </Button>
          <Button variant='outlined' className={classes.greyButton} onClick={cerrarModal}>
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(dialogStyle)(CargarDocumentoModal);

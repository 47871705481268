import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/es';
import { DateRange } from 'react-date-range';
import TextField from "@material-ui/core/TextField";
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import Popover from '@material-ui/core/Popover';

import rangeCalendarStyle from "../../../styles/jss/components/rangeCalendarStyle";

function formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return moment(date).format("DD/MM/YY");
}

function RangeCalendar({ ...props }) {
    const { classes, setStates, value, openCalendar, id, name, label, anchorElCalendar } = props;
    
    function handleToggle(event) {
        setStates({ anchorElCalendar: event.currentTarget });        
        setStates(state => ({ openCalendar: !state.openCalendar }));  
    };

    function handleRangeChange(which, payload){
        let items = {        
            selection: {
              startDate: new Date(),
              endDate: null,
              key: 'selection'
            },
        };
        items.selection.startDate=payload.startDate;
        items.selection.endDate=payload.endDate;
        setStates({
            dateRange: items,
            calendarValue:{
                desde: formatDateDisplay(payload.startDate,"Desde"),
                hasta: formatDateDisplay(payload.endDate,"Hasta")
            }
        });
    };

    function handleClearDate(){        
        setStates({
            dateRange: {
            selection: {
                startDate: new Date(),
                endDate: null,
                key: 'selection'
            },
            },
            calendarValue: {
                desde: '',
                hasta: ''
            },
        })
    }

    function handleCloseCalendar(){
        setStates({
            anchorElCalendar: null,
            openCalendar:false,
        });
    };
  return (
    <div>
      <TextField
        className={classes.filterFormtextField}
        id={id}
        name={name}
        label={label}
        placeholder="Desde - Hasta"
        value={value}
        onClick={handleToggle}
        InputProps={{
            readOnly: true,
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
            endAdornment: (
            <InputAdornment position="end" style={{margin: 0}}>
                <IconButton onClick={()=>handleClearDate()}>
                    <ClearIcon style={{width: 15, height: 15}}/>
                </IconButton>
            </InputAdornment>
            ),
        }}
        />                          
        <Popover 
        open={openCalendar} 
        anchorEl={anchorElCalendar} 
        onClose={handleCloseCalendar}
        className={classes.calendarClasses}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}>
        <div>
            <DateRange
            onChange={handleRangeChange.bind(this, 'dateRange')}
            direction="vertical"
            lang={"es"}
            calendars={1}                                
            className={classes.calendarClasses}
            />
        </div>
        </Popover>
    </div>
  );
}

RangeCalendar.defaultProps = {
    openCalendar: false,
    anchorElCalendar: null
};

RangeCalendar.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  setStates: PropTypes.func,
  openCalendar: PropTypes.bool,
  anchorElCalendar: PropTypes.object
};

export default withStyles(rangeCalendarStyle)(RangeCalendar);
// ##############################
// // // Heading component styles
// #############################
const rangeCalendarStyle = {
    filterFormtextField:{
        fontSize: "1rem", 
        width: "100%",
        "& input":{
            marginTop: ".4rem"
        }
    },
    calendarClasses:{
        "& .is-inRange:not(.is-selected)":{
          backgroundColor: "#E5EAD9 !important",
          color: "#555759 !important"
        },
        "& .is-selected":{
          backgroundColor: "#82BC00 !important"
        }
      }
};
  
  export default rangeCalendarStyle;
  
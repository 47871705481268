import React, { FC } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { sinCertificadoStyle } from './style';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Button from '@material-ui/core/Button';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof sinCertificadoStyle>, RouteComponentProps {

}

const SinCertificadoNotification : FC<IProps> = (props : IProps) => {
  const { classes, history } = props;

  const goToCertificados = () => {
    history.push('/user/certificados');
  };

  return (
    <div className={classes.noDocumentContainer}>
      <div className="info">
        <ReportProblemOutlinedIcon color="primary" />
        <p>No posees <strong>Certificados Digitales</strong> para firmar
          la cesión, para continuar con tu operación deberás <strong>configurar uno.</strong></p>
      </div>
      <Button variant="outlined" color="primary" onClick={goToCertificados}>Configurar uno aquí</Button>
    </div>
  );
};

export default withStyles(sinCertificadoStyle)(withRouter(SinCertificadoNotification));

import React, { FC } from 'react';
import { Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import scssTool from '../../styles/scssTool';
import IcoEmisionFactura from '../../images/icons/ico-emision-factura.svg';
import IcoCreditia from '../../images/icons/ico-crediticia.svg';
import IcoCesionFactura from '../../images/icons/ico-cesion-factura.svg';
import IcoDashedGreenArrow from '../../images/icons/ico-dashed-green-arrow.svg';
import IcoDesembolsoOperacion from '../../images/icons/ico-desombolso-operacion.svg';
import IcoProcesoCobranza from '../../images/icons/ico-proceso-cobranza.svg';
import IcoDevolucion from '../../images/icons/ico-devolucion.svg';
import EjecutivoContainer from './components/EjecutivoContainer';
import FactotalTheme from '../../styles/FactotalTheme';
import BorderSeparator from '../sharedv2/BorderSeparator/BorderSeparator';
import MesaComercialContainer from './components/MesaComercialContainer';
import NegociosForm from '../shared/NegociosForm/form';

const factoringStyle = (theme : Theme) => ({
  containerFac: {
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
    padding: '0px 10px'
  },
  facTitle: {
    ...scssTool.font(16, 'bold', 24),
    color: '#000000'
  },
  facParraf: {
    ...scssTool.font(14, 400, 18),
    color: '#414141',
    margin: '16px 0px',
  },
  formContactoColumn: {
    ...scssTool.flex('flex-start', 'column', 'flex-start'),
  },
  btnSolicitar: {
    background: FactotalTheme.palette.primary.main,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    width: '196px',
    height: '59px',
    borderRadius: '0px',
    '&:hover': {
      background: FactotalTheme.palette.primary.main,
    },
  },
  btnSolicitarLabel: {
    ...scssTool.font(16, 'bold', 24),
    color: 'white',
  },
  inputTextfield: {
    width: '100%',
    marginBottom: '12px',
  },
  leftContent: {
    ...scssTool.flex('flex-start', 'row', 'flex-start'),
    width: '100%',
    height: '100%',
  },
  contentIconDashed: {
    ...scssTool.flex('center', 'column', 'center'),
    height: '80%',
  },
  icoListInside: {
    height: '50px',
    ...scssTool.flex('center', 'column', 'center'),
  },
  listIconContainer: {
    ...scssTool.flex('flex-start', 'row', 'space-between'),
    height: '140px',
    width: '100%'
  },
  listColumnContainer: {
    ...scssTool.flex('center', 'column', 'center'),
    width: '80px',
  },
  greenCircle: {
    background: FactotalTheme.palette.primary.main,
    width: '18px',
    height: '18px',
    borderRadius: '18px',
    ...scssTool.font(12, 'bold', 17),
    ...scssTool.flex('center', 'row', 'center'),
    color: '#fff',
    marginBottom: '10px',
  },
  listTitle: {
    ...scssTool.font(12, 400, 17),
    color: '#414141',
    marginTop: '16px',
    width: '100%',
    textAlign: 'center' as 'center',
  },
});

interface IFactoringProducto extends WithStyles<typeof factoringStyle> {
}

const FactoringProducto : FC<IFactoringProducto> = (props) => {
  const { classes } = props;

  return (
    <Grid container spacing={16}>
      <Grid item xs={12} md={8}>
        <div className={classes.containerFac}>
          <div className={classes.facTitle}>Solicita tu Factoring</div>
          <div className={classes.facParraf}>
            El factoring es una alternativa de financiamiento que permite a personas naturales con
            giro o empresas obtener liquidez inmediata. Esto se realiza a partir de la cesión de sus
            cuentas por cobrar (facturas, cheques, letras, etc.), convirtiéndolas así en efectivo.
          </div>
          <div className={classes.listIconContainer}>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>1</div>
              <div className={classes.icoListInside}><img src={IcoEmisionFactura} alt='' /></div>
              <div className={classes.listTitle}>Emisión de factura</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>2</div>
              <div className={classes.icoListInside}><img src={IcoCreditia} alt='' /></div>
              <div className={classes.listTitle}>Evaluación comercial y crediticia</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>3</div>
              <div className={classes.icoListInside}><img src={IcoCesionFactura} alt='' /></div>
              <div className={classes.listTitle}>Cesión de facturas</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>4</div>
              <div className={classes.icoListInside}><img src={IcoDesembolsoOperacion} alt='' /></div>
              <div className={classes.listTitle}>Desombolso de la operación</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>5</div>
              <div className={classes.icoListInside}><img src={IcoProcesoCobranza} alt='' /></div>
              <div className={classes.listTitle}>Proceso de cobranza</div>
            </div>
            <div className={classes.contentIconDashed}>
              <img src={IcoDashedGreenArrow} alt='' />
            </div>
            <div className={classes.listColumnContainer}>
              <div className={classes.greenCircle}>6</div>
              <div className={classes.icoListInside}><img src={IcoDevolucion} alt='' /></div>
              <div className={classes.listTitle}>Devolución de excedentes*</div>
            </div>
          </div>
          <NegociosForm textoBoton={'Solicitar factoring'} tipoNegocioForm={'Factoring'} />
        </div>
      </Grid>
      <Grid item xs={12} md={4}>
        <div className={classes.leftContent}>
          <BorderSeparator />
          <MesaComercialContainer />
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(factoringStyle)(FactoringProducto);

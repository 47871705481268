import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import { WithStyles } from '@material-ui/core';

const styles = {
  wrapper: {
    paddingTop: 24,
    paddingBottom: 29,
    borderBottom: '1px solid #dbdbdb'
  },
  titleText: {
    fontSize: 16,
    marginBottom: 10
  },
  bodyText: {
    marginBottom: 15
  }
};

interface IProps extends WithStyles<typeof styles> {
  archivo : Comun.Dto.Archivo,
  onDelete? : (id : string) => void
}

const UploadingDocumentListItem : FC<IProps> = (props : IProps) => {
  const { classes, archivo, onDelete } = props;
  return (
    <div className={classes.wrapper}>
      <Typography variant="h2" className={classes.titleText}>{archivo.nombre}</Typography>
      {archivo.error ?
        (<React.Fragment>
          <Typography variant="body2" className={classes.bodyText} color='error'
                      onClick={onDelete ? () => onDelete(archivo.id) : undefined}>
            {archivo.error}</Typography>

        </React.Fragment>)
        : (
          <React.Fragment>
            <Typography variant="body2" className={classes.bodyText}>Subiendo archivo ...</Typography>
            <LinearProgress
              variant="determinate"
              color="secondary"
              value={archivo.progreso}
            />
          </React.Fragment>
        )}
    </div>
  );
};


export default withStyles(styles)(UploadingDocumentListItem);

import React from 'react';
import '../../sharedv2/custom_theme.scss';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import Header from '../../sharedv2/Header/Header';
import Loader from '../../shared/Loader/Loader';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TableVideos from './TableVideos';
import OpenVideoDetails from './OpenVideoDetails';
import factorxApi from '../../../services/factorxApi';
import { WithStyles } from '@material-ui/core';

interface IState {
  loading : boolean,
  activeTab : number,
  tutoriales : Dto.Portal.Tutorial[],
  abrirModalVideo : boolean,
  videoSelected : Dto.Portal.Tutorial | undefined
}

interface IProps extends WithStyles<typeof HistoricoStyle> {
}

class VideoTutoriales extends React.Component<IProps, IState> {

  constructor(props : IProps) {
    super(props);
    this.state = {
      loading: false,
      activeTab: 0,
      tutoriales: [],
      abrirModalVideo: false,
      videoSelected: undefined
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    factorxApi.tutorialesDisponiblesParaUsuario()
              .then((resp) => {
                if (resp.exito)
                  this.setState({ tutoriales: resp.tutoriales, loading: false });
              });
  }

  openDetailsModal = (detail : Dto.Portal.Tutorial) => {
    this.setState((state) => ({
      abrirModalVideo: !state.abrirModalVideo,
      videoSelected: detail
    }));
  };

  onCloseModal = () => {
    this.setState({
      abrirModalVideo: false,
      videoSelected: undefined
    });
  };

  render() {
    const { classes } = this.props;
    const { activeTab, tutoriales, abrirModalVideo, videoSelected } = this.state;

    return (
      <div className="container">
        {this.state.loading ? <Loader /> :
          <React.Fragment>
            <Header title="Tutoriales" breadcrum="Usted se encuentra en: Administración / Tutoriales" />
            <Grid container spacing={16}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`tabs ${classes.tabsBackground}`}>
                  <Tabs value={activeTab} classes={{ indicator: 'indicator' }}>
                    <Tab label="TUTORIALES FACTOTAL" classes={{ root: 'tabs-factotal' }} />
                  </Tabs>
                  <TableVideos tutoriales={tutoriales} openDetails={this.openDetailsModal} />
                </div>
              </Grid>
            </Grid>
            <OpenVideoDetails
              open={abrirModalVideo}
              onClose={this.onCloseModal}
              videoSelected={videoSelected}
            />
          </React.Fragment>
        }
      </div>
    );
  }
}

export default withStyles(HistoricoStyle)(VideoTutoriales);

import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TabUnselectedIcon from '@material-ui/icons/TabUnselected';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import Login from '../general/login/view';
import Subscriber from '../general/Subscriber/view'
import DashBoard from '../dashboard/view';
import MisDatos from '../general/misDatos/view';
import Marketing from '../marketing/view';
import AtencionClienteForm from '../marketing/form';
import Seguros from '../seguros/view';
import NegociosForm from '../shared/NegociosForm/form';
import CuentasBancarias from '../general/fxEmpresa/CuentasBancarias/view';
import Certificados from '../general/fxEmpresa/Certificados/view';
import Subscripciones from '../general/fxEmpresa/Subscripciones/view';

import AdminUsers from '../admin/usuario/view';
import SeguimientoOperacion from '../factoring/operaciones/seguimiento/view';
import Cobranza from '../factoring/cobranza/view';
import Cartera from '../factoring/cartera/view';
import FactoringProducto from '../factoring/producto';
import OperacionesHistoricas from '../factoring/operaciones/historicas/view';
import FacturasEmitidas from '../factoring/facturas/view';
import ExcedentesDisponibles from '../factoring/excedentes/disponibles/view';
import ExcedentesHistoricos from '../factoring/excedentes/historico/view';
import OperacionSolicitud from '../factoring/operaciones/solicitud/view';
import OperacionEnProceso from '../factoring/operaciones/enProceso/view';
import OperacionEnProcesoDetalle from '../factoring/operaciones/enProceso/detalle/view';
import Recesion from '../factoring/receder/view';

import Leasing from '../leasing/view';
import ContratosLeasing from '../leasing/Contratos/view';
import BienesLeasing from '../leasing/Bienes/view';
import CuotasLeasing from '../leasing/Cuotas/view';
import SiniestrosLeasing from '../leasing/Siniestros/view';
import CatalogoLeasing from '../leasing/Catalogo/view';
import LeasingProducto from '../leasing/producto';

import Vendor from '../vendor/view';
import ContratosVendor from '../vendor/Contratos/view';
import BienesVendor from '../vendor/Bienes/view';
import CuotasVendor from '../vendor/Cuotas/view';
import SiniestrosVendor from '../vendor/Siniestros/view';
import VideoTutoriales from '../general/videoTutoriales/view';
import VendorProducto from '../vendor/producto';

import Contratos from '../registroVehiculos/view';

import AdminEnterprise from '../admin/empresa/view';
import * as React from 'react';
import routes from '../../config/routes';
import ContratoDetalle from '../registroVehiculos/Contrato/view';
import PrimeraInscripcion from '../registroVehiculos/Contrato/primeraInscripcion';
import Transferencias from '../registroVehiculos/Contrato/transferencia';
import Informes from '../registroVehiculos/informes';

export interface IRuta {
  path: string;
  name: string;
  icon?: JSX.Element;
  component?: any;
  mini?: string;
  collapse?: boolean;
  redirect?: boolean;
  state?: string;
  pathTo?: string;
  rutas?: IRuta[];
  hide?: boolean;
}

const pages = (_userInfo: Comun.Dto.LoginUserInfo): IRuta[] => [
  {
    path: routes.miTablero,
    name: 'MI TABLERO',
    icon: <DashboardIcon />,
    component: DashBoard
  },
  {
    path: routes.user.misDatos,
    name: 'MIS DATOS',
    mini: 'MD',
    component: MisDatos
  },
  {
    path: routes.user.cuentasBancarias,
    name: 'CUENTAS BANCARIAS',
    mini: 'MD',
    component: CuentasBancarias
  },
  {
    path: routes.user.videoTutoriales,
    name: 'TUTORIALES',
    mini: 'MD',
    component: VideoTutoriales
  },
  {
    path: routes.user.subscripciones,
    name: 'SUBSCRIPCIONES',
    mini: 'MD',
    component: Subscripciones
  },
  {
    path: routes.user.certificados,
    name: 'CERTIFICADOS',
    mini: 'MD',
    component: Certificados
  },
  {
    path: routes.user.atencionCliente,
    name: 'Marketing',
    mini: 'MK',
    component: Marketing
  },
  {
    path: '/user/atencion-cliente-form',
    name: 'Ingrese su Solicitud',
    mini: 'MK',
    component: AtencionClienteForm
  },
  {
    path: '/negocio/seguros',
    name: 'Seguros',
    mini: 'SG',
    component: Seguros
  },
  {
    path: '/negocio-form/:form',
    name: 'Formulario Negocio',
    mini: 'SG',
    component: NegociosForm
  },
  {
    path: '/login',
    name: 'ACCESOS',
    mini: 'A',
    component: Login
  },
  {
    path: routes.subscriber,
    name: 'REGISTRO',
    mini: 'A',
    component: Subscriber
  },
  {
    path: '/factoring/cobranza',
    name: 'COBRANZA',
    mini: 'A',
    component: Cobranza
  },
  {
    path: '/factoring/facturasemitidas',
    name: 'FACTURAS EMITIDAS',
    mini: 'A',
    component: FacturasEmitidas
  },
  {
    path: '/factoring/operaciones/seguimiento',
    name: 'SEGUIMIENTO',
    mini: 'A',
    component: SeguimientoOperacion
  },
  {
    path: '/factoring/excedentes/disponibles',
    name: 'DISPONIBLES',
    mini: 'A',
    component: ExcedentesDisponibles
  },
  {
    path: '/factoring/excedentes/historico',
    name: 'HISTORICO',
    mini: 'A',
    component: ExcedentesHistoricos
  },
  {
    path: routes.factoring.operaciones.recesion,
    name: 'Recesión',
    mini: 'A',
    component: Recesion
  },
  {
    path: routes.factoring.operaciones.solicitud,
    name: 'Solicitud',
    mini: 'A',
    component: OperacionSolicitud
  },
  {
    path: routes.factoring.operaciones.proceso,
    name: 'En proceso',
    mini: 'A',
    component: OperacionEnProceso
  },
  {
    path: '/factoring/operaciones/proceso/:id/:paraCeder?',
    name: 'En proceso detalle',
    mini: 'PD',
    component: OperacionEnProcesoDetalle
  },
  // {
  //   path: '/factoring/operaciones/proceso/cediendo/:id',
  //   name: 'Operacion Cedida Estado',
  //   mini: 'PD',
  //   component: CediendoProcesoDetalle
  // },
  {
    path: routes.leasing.negocio,
    name: 'Leasing',
    mini: 'LS',
    component: Leasing
  },
  {
    path: routes.leasing.producto,
    name: 'LEASING',
    mini: 'A',
    component: LeasingProducto
  },
  {
    path: routes.leasing.bienes,
    name: 'BIENES',
    mini: 'A',
    component: BienesLeasing
  },
  {
    path: routes.leasing.bienesContrato(),
    name: 'BIENES',
    mini: 'A',
    component: BienesLeasing
  },
  {
    path: routes.leasing.contratos,
    name: 'CONTRATOS',
    mini: 'A',
    component: ContratosLeasing
  },
  {
    path: '/leasing/cuotas',
    name: 'CUOTAS',
    mini: 'A',
    component: CuotasLeasing
  },
  {
    path: '/leasing/siniestros',
    name: 'SINIESTROS',
    mini: 'A',
    component: SiniestrosLeasing
  },
  {
    path: '/leasing/catalogo',
    name: 'CATALOGO',
    mini: 'A',
    component: CatalogoLeasing
  },
  {
    path: routes.vendor.negocio,
    name: 'Vendor',
    mini: 'VD',
    component: Vendor
  },
  {
    path: routes.vendor.producto,
    name: 'VENDOR',
    mini: 'A',
    component: VendorProducto
  },
  {
    path: routes.vendor.bienes,
    name: 'BIENES',
    mini: 'A',
    component: BienesVendor
  },
  {
    path: routes.vendor.bienesContrato(),
    name: 'BIENES',
    mini: 'A',
    component: BienesVendor
  },
  {
    path: routes.vendor.contratos,
    name: 'CONTRATOS',
    mini: 'A',
    component: ContratosVendor
  },
  {
    path: routes.vendor.cuotas,
    name: 'CUOTAS',
    mini: 'A',
    component: CuotasVendor
  },
  {
    path: routes.vendor.siniestros,
    name: 'SINIESTROS',
    mini: 'A',
    component: SiniestrosVendor
  },
  {
    path: routes.factoring.producto,
    name: 'FACTORING',
    mini: 'P',
    component: FactoringProducto
  },
  {
    path: routes.factoring.cartera,
    name: 'CARTERA',
    mini: 'CA',
    icon: <AccountBalanceWalletIcon />,
    component: Cartera
  },
  {
    path: '/factoring/cesion',
    name: 'CESIÓN FACTURA',
    mini: 'CF',
    icon: <TabUnselectedIcon />,
    component: DashBoard
  },
  {
    path: routes.factoring.operaciones.historico,
    name: 'HISTORICO',
    mini: 'HI',
    icon: <TabUnselectedIcon />,
    component: OperacionesHistoricas
  },
  {
    path: routes.factoring.excedentes.disponibles,
    name: 'DISPONIBLES',
    mini: 'D',
    icon: <DoneAllIcon />,
    component: DashBoard
  },
  {
    path: routes.factoring.excedentes.historico,
    name: 'HISTÓRICOS',
    mini: 'H',
    icon: <ChromeReaderModeIcon />,
    component: DashBoard
  }
];

const pagesAdmin = (userInfo: Comun.Dto.LoginUserInfo): IRuta[] => [
  {
    path: routes.admin.empresas,
    name: 'Empresas',
    mini: 'E',
    component: AdminEnterprise,
    icon: <DashboardIcon />,
    hide: userInfo.perfiles.findIndex(d => d === 4) === -1
  },
  {
    path: routes.admin.users,
    name: 'Usuarios',
    mini: 'AU',
    component: AdminUsers,
    icon: <DashboardIcon />,
    hide: userInfo.perfiles.findIndex(d => d === 4) === -1
  },
  {
    path: routes.registroVehiculos.empresas,
    name: 'Registro e Inscripción',
    mini: 'A',
    component: Contratos,
    hide: userInfo.perfiles.findIndex(d => d === 5) === -1 && (userInfo.empresas && userInfo.empresas.length > 0 && !userInfo.empresas[0].registroCivil)
  },
  {
    path: routes.registroVehiculos.informes,
    name: 'Informes R.C.',
    mini: 'A',
    component: Informes,
    hide: userInfo.perfiles.findIndex(d => d === 5) === -1 && (userInfo.empresas && userInfo.empresas.length > 0 && !userInfo.empresas[0].registroCivil)
  },
  {
    path: routes.registroVehiculos.contratosEmpresa(),
    name: '',
    mini: '',
    component: ContratoDetalle,
    hide: true
  },
  {
    path: routes.registroVehiculos.primeraInscripcion(),
    name: '',
    component: PrimeraInscripcion,
    hide: true
  },
  {
    path: routes.registroVehiculos.transferencia(),
    name: '',
    component: Transferencias,
    hide: true
  }
];

const dashRoutes = (esAdmin: boolean, userInfo: Comun.Dto.LoginUserInfo): IRuta => {
  const rutas = (esAdmin ? pagesAdmin : pages)(userInfo);
  return {
    redirect: true,
    path: '/',
    pathTo: rutas.filter(d => d.hide === false || d.hide === undefined)[0].path,
    name: esAdmin ? 'ADMIN' : 'MI TABLERO',
    component: esAdmin ? AdminEnterprise : DashBoard,
    rutas: rutas
  };
};
export default dashRoutes;

import {
  eliminarXmlSubidoAction,
  eliminarXmlSubiendoAction,
  fechaVencimientoAction,
  filtrosDocumentosAction,
  generarSimulacionAction,
  limpiarSimulacionAction,
  obtenerDocumentosAction,
  quitarDocumentoAction,
  seleccionarDocumentoAction,
  subirXmlDocumentosAction,
  vaciarDocumentosAction
} from './actions';
import { uid } from 'uid';
import { AnyAction } from 'redux';
import { iniPaginacion } from '../../../../helpers/PaginationHelper';

const iniSimulacion: Factoring.Dto.Simulacion.Simulacion = {
  archivos: [],
  cantidadDocumentos: 0,
  documentos: [],
  fecha: '',
  montoDocumentos: 0,
  nombre: '',
  idPublico: '',
  montoGirar: 0,
  // locale: '',
  automatica: true,
  montos: {
    anticipado: 0,
    comision: 0,
    descuentos: 0,
    diferenciaPrecio: 0,
    documento: 0,
    excedentes: 0,
    gastos: 0,
    iva: 0,
    montoGirar: 0,
    ivaGastosNotariales: 0,
    gastosNotariales: 0,
    ivaGastosOperacion: 0,
    gastosOperacion: 0,
    ivaComision: 0,
    ivaGastos: 0,
    ivaDiferenciaPrecio: 0,
    precioCompra: 0,
    descuentosAplicados: 0
  },
  montoOperar: 0,
  montoRetencionDocumentos: 0,
  visualizacion: {
    historia: [],
    ultima: {} as Factoring.Dto.Visualizacion,
    primera: {} as Factoring.Dto.Visualizacion
  },
  publicada: false,
  colaborador: {} as Factoring.Dto.Persona,
  condicionesOficiales: {} as Factoring.Dto.CondicionesSimulacion,
  condicionesSimulacion: {} as Factoring.Dto.CondicionesSimulacion,
  identificadorCliente: '',
  razonSocialCliente: '',
  id: 0,
  moneda: { id: 0, decimales: 0, nombre: '' },
  paisDesc: '',
  paisId: 0,
  publicacion: {
    colaborador: {} as Factoring.Dto.Persona,
    fechaPublicacion: ''
  },
  usuario: {} as Factoring.Dto.Persona,
  existente: false,
  eventos: [],
  generadaPor: {} as Factoring.Dto.Persona,
  montoDescuentosDocumentos: 0,
  montoGirarMenosDescuentosInformados: 0,
  cantidadVisualizaciones: 0,
  locale: '',
  montoAnticipado: 0,
  montoDiferenciaPrecio: 0,
  ticks: 0,
  descuentosSimulacion: {} as Factoring.Dto.DescuentosSimulacion,
  margen: {} as Factoring.Dto.MargenSimulacion,
  identidadCesionDefinida: false,
  cesionExterna: false,
  detalleGastos: [],
  informacionPago: {} as Comun.Dto.InformacionPago,
  plazoPromedioPonderado: 0,
  terminosCondiciones: {} as Factoring.Dto.TerminosCondiciones,
  terminosCondicionesInstitucion: {} as Factoring.Dto.TerminosCondiciones,
  noCumpleRequisitos: false,
  documentacion: [],
  tasas: {
    tasaMora: 0,
    tasaNegocio: 0,
    tasaValorPresente: 0,
    porcentajeAnticipo: 0,
    costoFondo: 0
  },
  totalDescuentosInformados: 0,
  totalDescuentosAplicados: 0,
  comentarios: '',
  requisitosVisado: [],
  observacionesVisado: [],
  idProcesoMotorRiesgo: '',
  abonoEnLinea: {
    cumple: false,
    cumpleCondicionesActuales: false,
    cumpleCondicionesIniciales: false,
    cumpleCondicionMontoDiario: false,
    cumpleCondicionTipoCesion: false,
    observaciones: ''
  },
  motivosNoCumplimiento: [],
  paridad: {
    moneda: {} as Comun.Dto.Moneda,
    valor: 1,
    utiliza: false,
    fecha: ''
  },
  esDeIntermediario: false
};

const iniFiltros: Filters.Documentos = { folios: '', razonSocialDeudor: '', tipoDte: '', fechaEmision: undefined, pagina: 1, tamano: 25 };

// export const iniPaginacion : State.Paginacion = {
//   from: 0,
//   to: 0,
//   pageSize: 10,
//   count: 0,
//   maxPages: 0,
//   page: 0
// };

export interface ISolicitudOperacionesState {
  filtros: Filters.Documentos;
  rows: Comun.Dto.Invoice[];
  selected: Comun.Dto.Invoice[];
  simulacion: Factoring.Dto.Simulacion.Simulacion | null;
  pagination: State.Paginacion;
  isValidForm: boolean;
  uploadingAttachments: Comun.Dto.Archivo[];
  archivos: Comun.Dto.Archivo[];
  archivosXmlSubiendo: Comun.Dto.Archivo[];
  archivosXmlSubidos: Comun.Dto.Archivo[];
}

const solicitudOperacionesState: ISolicitudOperacionesState = ({
  rows: [],
  selected: [],
  filtros: iniFiltros,
  simulacion: iniSimulacion,
  pagination: iniPaginacion(),
  isValidForm: true,
  uploadingAttachments: [],
  archivos: [],
  archivosXmlSubiendo: [],
  archivosXmlSubidos: []
});

export const reducer = (state: ISolicitudOperacionesState = solicitudOperacionesState, action: AnyAction) => {
  switch (action.type) {
    case obtenerDocumentosAction.PENDING: {
      return Object.assign({}, state, {
        isLoading: true,
        error: null
      });
    }

    case obtenerDocumentosAction.SUCCESS: {
      const payload: Payloads.ObtenerDocumentosExitosoPayload = action.payload;
      const filtros: Filters.Documentos = payload.filtros;
      let pagination = state.pagination;
      let cantDocs = payload.documentos.length;
      const estimatedCount = (payload.hayMasRegistros) ?
        (filtros.pagina * filtros.tamano + 1) :
        ((filtros.pagina - 1) * filtros.tamano + cantDocs);
      pagination = Object.assign({}, state.pagination, {
        page: filtros.pagina - 1,
        pageSize: filtros.tamano,
        count: estimatedCount
      });
      const newState = {
        isLoading: false,
        rows: payload.documentos,
        pagination: pagination
      };
      return Object.assign({}, state, newState);
    }

    case generarSimulacionAction.SUCCESS: {
      const payload: Payloads.GenerarSimulacionExitoPayload = action.payload;

      const sim = payload.simulacion;

      sim.documentos.forEach(doc => {
        if (doc.archivos) {
          doc.archivos.forEach(a => {
            a.fecha = undefined;
            a.progreso = 0;
            a.id = uid();
            a.error = '';
          });
        }
      });
      return Object.assign({}, state,
        {
          simulacion: sim,
          uploadingAttachments: [],
          archivos: sim.archivos.map(a => {
            return {
              ...a,
              fecha: null,
              progreso: 0,
              id: uid(),
              error: ''
            };
          })
        });
    }

    case limpiarSimulacionAction.SUCCESS: {
      return Object.assign({}, state, {
        simulacion: iniSimulacion
      });
    }

    case filtrosDocumentosAction.SUCCESS: {
      return Object.assign({}, state, {
        filtros: iniFiltros
      });
    }

    case fechaVencimientoAction.SUCCESS: {
      const payload: Payloads.ActualizarFechaVencimientoPayload = action.payload;

      const rows = state.rows;
      payload.ids.forEach(id => {
        const docIndex = rows.findIndex(d => d.id === id);
        let documento = rows[docIndex];
        documento.fechaVencimiento = payload.date;
        documento.fechaInvalida = false;
        rows[docIndex] = { ...documento };
      });

      return Object.assign({}, state, { rows: [...rows] });
    }

    case subirXmlDocumentosAction.PENDING: {
      const payload: Comun.Dto.Archivo = action.payload;
      const archivosXmlSubiendo = state.archivosXmlSubiendo;
      archivosXmlSubiendo.push(payload);

      return Object.assign({}, state, {
        archivosXmlSubiendo: [...archivosXmlSubiendo]
      });
    }

    case subirXmlDocumentosAction.SUCCESS: {
      const payload: string = action.payload;

      const archivosXmlSubiendo = state.archivosXmlSubiendo;
      const documentIndex = archivosXmlSubiendo.findIndex(d => d.id === payload);
      const document = archivosXmlSubiendo[documentIndex];
      archivosXmlSubiendo.splice(documentIndex, 1);

      const archivosXmlSubidos = state.archivosXmlSubidos;
      archivosXmlSubidos.push({
        nombre: document.nombre,
        id: document.id,
        error: '',
        fecha: undefined,
        progreso: 100,
        puedeEliminar: true
      });

      return Object.assign({}, state, {
        archivosXmlSubidos: archivosXmlSubidos,
        archivosXmlSubiendo: archivosXmlSubiendo
      });
    }

    case subirXmlDocumentosAction.FAILURE: {
      const payload: Payloads.SubirArchivoXmlErrorPayload = action.payload;

      const archivosXmlSubiendo = state.archivosXmlSubiendo;
      const documentIndex = archivosXmlSubiendo.findIndex(d => d.id === payload.id);
      const document = archivosXmlSubiendo[documentIndex];
      document.error = payload.error;

      return Object.assign({}, state, {
        archivosXmlSubiendo: archivosXmlSubiendo
      });
    }

    case eliminarXmlSubidoAction.SUCCESS: {
      const payload: string = action.payload;
      const archivosXmlSubidos = state.archivosXmlSubidos;
      const documentIndex = archivosXmlSubidos.findIndex(d => d.id === payload);
      archivosXmlSubidos.splice(documentIndex, 1);

      return Object.assign({}, state, {
        archivosXmlSubidos: Object.assign([], archivosXmlSubidos, [])
      });
    }

    case eliminarXmlSubiendoAction.SUCCESS: {
      const payload: string = action.payload;
      const archivosXmlSubiendo = state.archivosXmlSubiendo;
      const documentIndex = archivosXmlSubiendo.findIndex(d => d.id === payload);
      archivosXmlSubiendo.splice(documentIndex, 1);

      return Object.assign({}, state, {
        archivosXmlSubiendo: Object.assign([], archivosXmlSubiendo, [])
      });
    }

    case seleccionarDocumentoAction.EXECUTE: {
      const payload: Comun.Dto.Invoice = action.payload;
      const selected = state.selected;

      selected.push(payload);

      return Object.assign({}, state, {
        selected: [...selected]
      });
    }

    case quitarDocumentoAction.EXECUTE: {
      const payload: Comun.Dto.Invoice = action.payload;
      const selected = state.selected;

      const documentIndex = selected.findIndex(d => d.id === payload.id);
      selected.splice(documentIndex, 1);

      return Object.assign({}, state, {
        selected: [...selected]
      });
    }


    case vaciarDocumentosAction.EXECUTE: {
      return Object.assign({}, state, {
        selected: []
      });
    }

    default:
      return state;
  }
};

export default reducer;

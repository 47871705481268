import { Theme } from '@material-ui/core';
import scssTool from '../../../styles/scssTool';

export const menuSidebarStyle = (theme: Theme) => ({
  listItemContainer: {
    ...scssTool.flex('flex-start', 'column'),
    padding: '0px'
  },
  listItemTitle: {
    ...scssTool.flex('center', 'row', 'space-between'),
    width: '100%',
    boxSizing: 'border-box' as 'border-box',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    ...scssTool.font(12, 400, 25),
    color: '#1D1D1D',
    padding: '13px 24px',
    textTransform: 'uppercase' as 'uppercase',
    cursor: 'pointer' as 'pointer',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    fontFamily: scssTool.family.larsseit,
    '&:hover': {
      background: '#C9CFD1'
    },
    '&.open': {
      fontWeight: 900
    },
    '&.whiteVersion': {
      background: '#FFFFFF',
      height: '50px',
      ...scssTool.font(11, 'bold', 14),
      color: '#000000',
      '&.open': {
        background: '#fff'
      }
    }
  },
  accountCircle: {
    marginRight: '8px'
  },
  collapseContainer: {
    width: '100%'
  },
  collapseEntered: {
    background: '#C9CFD1',
    '&.white': {
      background: '#ECEFF0'
    }
  },
  menuNavLink: {
    cursor: 'pointer' as 'pointer',
    width: '100%',
    ...scssTool.flex('center', 'row', 'space-between'),
    textDecoration: 'none' as 'none',
    '& span': {
      ...scssTool.font(11, 400, 25),
      color: '#363636',
      textDecoration: 'none' as 'none',
      textTransform: 'uppercase' as 'uppercase'
    },
    '&.open': {
      '& span': {
        fontWeight: 900
      }
    },
    '& img': {
      marginRight: '8px'
    }
  },
  menuNavLinkInside: {
    cursor: 'pointer' as 'pointer',
    width: '100%',
    ...scssTool.flex('center', 'row', 'flex-start'),
    textDecoration: 'none' as 'none',
    borderLeft: '3px solid rgba(0, 0, 0, 0.1)',
    paddingLeft: '​0px !important',
    paddingRight: '​0px !important',
    height: '34px',
    '& span': {
      ...scssTool.font(10, 400, 25),
      color: 'rgba(54, 54, 54, 0.7)',
      textDecoration: 'none' as 'none',
      textTransform: 'uppercase' as 'uppercase'
    },
    '& img': {
      marginRight: '8px'
    },
    '& .activeLink': {
      fontWeight: 'bold' as 'bold'
    }
  },
  listInside: {
    padding: '0px 0px 0px 18px'
  },
  linkTextContainer: {
    ...scssTool.flex('center', 'row', 'flex-start')
  },
  arrowtoogleCollapse: {
    transition: 'all 0.3s ease-in-out',
    '&.active': {
      transform: 'rotateZ(180deg)',
      transition: 'all 0.3s ease-in-out'
    }
  }
});

import { cardTitle, container } from '../generalStyle';
import customSelectStyle from './customSelectStyle';
import customCheckboxRadioSwitch from './customCheckboxRadioSwitch';
import FactotalTheme from '../../FactotalTheme';

const extendedFormsStyle = {
  ...container,
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0' as any,
    color: 'rgba(0, 0, 0, 0.26)',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: 400,
    display: 'inline-flex'
  },
  // right: {
  //   margin: '0',
  //   fontSize: '14px',
  //   float: 'right !important' as 'right',
  //   padding: '15px'
  // },
  RadioGroup: {
    group: {
      margin: '0px'
    },
    radio: {
      color: FactotalTheme.palette.primary.main as string,
      backgroundColor: '#333',
      '&$checked': {
        color: FactotalTheme.palette.primary.main as string,
        width: '200px'
      }
    },
    labelRadio: {
      fontSize: '12px'
    }
    // checked: {}
  },
  radioStanlone: {
    color: FactotalTheme.palette.primary.main as string,
    backgroundColor: '#333',
    '&:checked': {
      color: FactotalTheme.palette.primary.main as string,
      width: '200px'
    }
  },
  card: {
    border: '0',
    // marginBottom: '10px',
    // marginTop: '10px',
    // marginLeft: 15,
    // borderRadius: '6px',
    color: 'rgba(0, 0, 0, 0.87)',
    background: '#fff',
    width: '100%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    position: 'relative' as 'relative',
    display: 'flex',
    flexDirection: 'column' as 'column',
    minWidth: '0',
    wordWrap: 'break-word' as 'break-word',
    fontSize: '.875rem',
    height: '100%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row' as 'row',
    flexWrap: 'nowrap' as 'nowrap',
    alignItems: 'stretch',
    height: '30px',
    width: '100%',
    borderBottom: '1px solid #d3d3d3'
  },
  dashboardCardHeader: {
    backgroundColor: '#EFF0F0 !important'
  },
  HeaderTable: {
    fontFamily: [
      'Larsseit',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    fontSize: '.65rem !important',
    lineHeight: '.8rem !important',
    fontWeight: 200,
    textAlign: 'left' as 'left',
    color: '#FFFFFF',
    wordWrap: 'break-word' as 'break-word',
    whiteSpace: 'normal !important' as 'normal',
    backgroundColor: FactotalTheme.palette.grey[200] as string
  },
  /** */
  TableFTC: {
    marginTop: '2px',
    borderSpacing: '0 2px',
    borderCollapse: 'separate' as 'separate'
  },
  HeaderTableFCT: {
    backgroundColor: FactotalTheme.palette.grey[200] as string,
    textAlign: 'center' as 'center'
  },
  TableRowFCT: {
    paddingRight: '0px' as any,
    height: '30px'
  },
  TableRowBodyFCT: {
    height: '31px',
    color: FactotalTheme.palette.grey[500] as string,
    fontSize: '12px',
    borderBottom: 'none',
    '& td:last-child': {
      borderRight: '1px solid rgba(224, 224, 224, 1)',
      borderTop: '1px solid rgba(224, 224, 224, 1)'
    },
    '& td:first-child': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  TableRowBodyFCTDisabled: {
    opacity: '0.7',
    cursor: 'not-allowed',
    backgroundColor: '#DFDFDF',
    color: '#CD2F2F'
  },
  popover: {
    pointerEvents: 'none' as 'none'
  },
  paperPopover: {
    padding: 8
  },
  TableCellFCT: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    padding: '4px 16px 4px 4px'
  },
  TableCellFCTERROR: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    padding: '4px 16px 4px 4px',
    color: '#CD2F2F'
  },
  TableCellFCTERRORCONTENT: {
    display: 'flex',
    alignItems: 'center',
    opacity: '0.5',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      opacity: '1 !important',
      transition: 'all 0.3s ease-in-out'
    }

  },
  TableCellFCTERRORCONTENTINFO: {
    color: '#CD2F2F',
    marginRight: '8px' as any,
    fontSize: '19px'
  },
  TableNoRowFCT: {
    textAlign: 'center' as 'center'
  },
  TableThFCT: {
    padding: '0px' as any,
    color: '#FFFFFF',
    fontSize: '10px',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 'normal' as 'normal',
    letterSpacing: '1px'
  },
  linkTableFCT: {
    fontSize: '12px',
    color: FactotalTheme.palette.primary.main as string,
    textDecoration: 'none'
  },
  PaginatorIcon: {
    width: '30px',
    height: '30px',
    backgroundColor: '#fff',
    border: '1px solid #82BC00',
    marginRight: '4px' as any,
    marginLeft: 4
  },
  CenterHeaterTextTable: {
    textAlign: 'center' as 'center'
  },
  detailsTable: {
    width: '100%',
    height: 'auto',
    position: 'relative' as 'relative'
  },
  rowDetailsTable: {
    width: '100%',
    height: '30px',
    padding: '5px 13px',
    fontFamily: 'Arial',
    fontWeight: 'normal' as 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#3A3A3A',
    boxSizing: 'border-box' as 'border-box'
  },
  columnsLeft: {
    textAlign: 'left' as 'left',
    float: 'left' as 'left',
    width: '50%'
  },
  columnsRight: {
    textAlign: 'right' as 'right',
    fontWeight: 'bold' as 'bold',
    float: 'right' as 'right',
    width: '50%'
  },
  /** */
  TDTable: {
    color: `${FactotalTheme.palette.grey[500]} !important` as string,
    fontFamily: [
      'Larsseit',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&.TDTableNoOperacion': {
      color: '#608C00',
      textDecoration: 'underline',
      fontWeight: 'bold' as 'bold'
    },
    fontSize: '.8rem',
    '& button span': {
      fontSize: '.8rem'
    }
  },
  TDTableTotalFooter: {
    backgroundColor: '#686B6D',
    color: '#fff'
  },
  TDTableTotalOperacion: {
    color: '#608C00',
    textDecoration: 'underline',
    fontWeight: 'bold' as 'bold'
    //backgroundColor: "rgba(130, 188, 0, 0.2)",
    //borderTop: "5px solid transparent",
    //borderBottom: "5px solid transparent"
  },
  TDTableTotalOperacionHeader: {
    //backgroundColor: "rgba(130, 188, 0, 0.2)",
  },
  TDTableNoOperacion: {
    /*color: "#608C00 !important",
    textDecoration: "underline",
    fontWeight: "bold",
    cursor: "pointer"*/
  },

  TDTableFilaCheck: {
    height: '26px'
  },

  TDTableDisabled: {
    '& > div:first-child': {
      visibility: 'hidden' as 'hidden'
    }
  },

  TDTableHeaderDisabled: {
    '&> div:first-child > div:first-child > div:first-child > div:first-child > div:first-child': {
      visibility: 'hidden' as 'hidden'
    }
  },

  TDTableRightAlign: {
    textAlign: 'right !important' as 'right'
  },
  TDTableCenterAlign: {
    textAlign: 'center !important' as 'center'
  },
  TDTableLeftAlign: {
    textAlign: 'left !important' as 'left'
  },
  TDTableEstadoVigente: {
    color: '#82BC00 !important'
  },
  TDTableEstadoMoroso: {
    color: '#608C00 !important'
  },
  TDTableTextButton: {
    fontSize: '0.750em',
    color: '#608C00 !important',
    cursor: 'pointer',
    '&:hover, &:focus, span': {
      textDecoration: 'underline',
      background: 'none'
    }
  },
  TDTableCenterIcon: {
    display: 'block',
    margin: 'auto',
    cursor: 'pointer',
    width: '1.3rem',
    height: '1rem',
    fill: '#82BC00',
    '&:hover, &:focus, &.active': {
      backgroundColor: '#82BC00',
      fill: '#fff',
      borderRadius: 2
    }
  },
  divSteper: {
    textAlign: 'center' as 'center',
    padding: 0,
    '& text': {
      //content: " ",
    }
    //paddingTop: FactotalTheme.spacing.unit * 20,
  },
  fontSizeStep: {
    fontSize: '.75rem',
    padding: 0,
    marginBottom: '1.5rem',
    '& svg > text': {
      fontSize: '0.75rem'
    }
  },
  TablePopUpContainer: {
    padding: '10px',
    backgroundColor: '#E5EAD9',
    border: '2px solid #82BC00',
    '& .ReactTable  .pagination-bottom': {
      backgroundColor: '#DBDCDD',
      '& > ul': {
        margin: '0'
      }
    }


  },
  tablePopOver: {
    backgroundColor: 'black'
  },
  popoverOpen: {
    textDecoration: 'none',
    backgroundColor: 'rgba(46, 42, 37, 0.08)'
  },
  gridPDFExcelButtons: {
    position: 'absolute' as 'absolute',
    right: '5px',
    top: '29px',
    zIndex: 1,
    '& svg': {
      width: 16,
      height: 16
    }
  },

  gridPDFExcelButtonsSinPestaña: {
    //position: "absolute",
    right: '5px',
    top: '29px',
    zIndex: 1,
    '& svg': {
      width: 16,
      height: 16
    }
  },

  GreenCardHeaderTitle: {
    //color: "#608C00"
  },
  GreenCardHeaderSubTitle: {
    fontSize: '0.563em'
  },
  imgContainer: {
    '& img': {
      width: '100%'
    }
  },
  facEmitPDFContainer: {
    width: '100%',
    height: '100%',
    padding: '0px !important'
  },
  filterCardContent: {
    padding: '0 16px 24px !important'
  },
  filterTitle: {
    color: '#BDBFC0',
    fontWeight: 300,
    fontSize: '.9rem',
    lineHeight: '.9rem'
  },
  filterButtonCollapse: {
    padding: 0
  },
  filterForm: {
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center'
  },
  filterFormtextField: {
    fontSize: '1rem',
    width: '100%',
    '& input': {
      marginTop: '.4rem'
    }
  },
  filterFormSelectField: {
    fontSize: '1rem',
    width: '100%',
    marginTop: '1.5rem !important',
    '& input': {
      marginTop: '.4rem'
    }
  },
  filterFormButtonContainer: {
    display: 'flex'
  },
  filterFormButtonBody: {
    paddingLeft: 5,
    paddingRight: '5px' as any,
    margin: 'auto',
    top: '21px'
  },
  sectionTitle: {
    fontSize: '1.2rem',
    marginBottom: '4px',
    lineHeight: '1.2rem',
    float: 'left' as 'left',
    color: '#2E2A25'
  },
  expand: {},
  filterExpandButton: {
    marginRight: 0,
    marginTop: 0
  },
  filterCardHeader: {
    padding: '10px 16px'
  },
  filterFormcontrol: {
    width: '100%'
  },
  calendarClasses: {
    '& .is-inRange:not(.is-selected)': {
      backgroundColor: '#E5EAD9 !important',
      color: '#555759 !important'
    },
    '& .is-selected': {
      backgroundColor: '#82BC00 !important'
    }
  },
  commentRoot: {
    backgroundColor: '#EFF0F0',
    boxShadow: 'inset 0px 0px 0px 1px #BDBFC0',
    height: '100%'
  },
  comentHeaderTitle: {
    float: 'left' as 'left',
    marginRight: 14,
    color: '#608C00',
    fontWeight: 'bold' as 'bold'
  },
  commentSubHeaderTitle: {
    float: 'left' as 'left'
  },
  comentHeaderButton: {},
  comentHeaderRoot: {},
  comentHeaderContent: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  comentContent: {
    padding: '0 15px 13px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  cardMediaFacEmitidas: {
    height: '100%',
    width: '100%'
  },
  modalPaper: {
    backgroundColor: 'rgba(255,255,255, .85)',
    width: 870,
    height: 300,
    margin: 'auto',
    top: 0,
    position: 'absolute' as 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem',
    borderRadius: '1rem',
    boxShadow: '0px 0px 0px 3px #82BC00',
    '&:focus': {
      outline: 'none'
    }
  },
  modalHeader: {
    height: 80,
    width: 100,
    backgroundColor: 'rgba(130,188,0,.8)',
    borderRadius: '.8rem',
    position: 'relative' as 'relative',
    display: 'flex',
    margin: 'auto',
    top: -56,
    '& > div': {
      margin: 'auto'
    },
    '& svg': {
      height: 60,
      width: 60,
      margin: 'auto',
      '& path': {
        fill: '#fff',
        '&[fill="none"]': {
          fill: 'none'
        }
      }
    }
  },
  modalContent: {
    textAlign: 'center' as 'center',
    '& span': {
      fontSize: '1rem'
    }
  },
  modalContentTitle: {
    fontSize: '2rem',
    color: '#2E2A25',
    marginBottom: '2rem'
  },
  modalContentTitleStep1: {
    fontSize: '2rem',
    color: '#2E2A25',
    marginBottom: 35,
    marginTop: -35
  },
  modalContentSubTitle: {
    fontSize: '1rem',
    lineHeight: '2rem'
  },
  modalFooterStep1: {
    //marginTop: "4rem",
    display: 'flex',
    alignItems: 'center',
    marginLeft: 2,
    marginRight: '2rem' as any,
    '& button': {
      backgroundColor: '#82BC00',
      fontSize: '.85rem',
      margin: '0 10px',
      display: 'flex',
      '&:hover': {
        backgroundColor: '#82BC00'
      },
      '&:focus': {
        backgroundColor: '#82BC00'
      }
    }
  },
  modalFooter: {
    marginTop: '4rem',
    '& button': {
      backgroundColor: '#82BC00',
      fontSize: '.85rem',
      margin: 'auto',
      display: 'flex',
      '&:hover': {
        backgroundColor: '#82BC00'
      },
      '&:focus': {
        backgroundColor: '#82BC00'
      }
    }
  },
  deleteUser: {
    color: 'red',
    //border: "1px solid red",
    marginLeft: 2,
    padding: 0,
    minWidth: 'auto',
    '& path': {
      color: 'red'
    },
    '&:hover': {
      color: 'white',
      //border: "1px solid red",
      backgroundColor: 'red',
      '& path': {
        color: 'white'
      }
    }
  },
  enableUser: {
    color: 'rgb(130, 188, 0)',
    //border: "1px solid red",
    marginLeft: 2,
    padding: 0,
    minWidth: 'auto',
    '& path': {
      color: 'rgb(130, 188, 0)'
    },
    '&:hover': {
      color: 'white',
      //border: "1px solid red",
      backgroundColor: 'rgb(130, 188, 0)',
      '& path': {
        color: 'white'
      }
    }
  },
  disableUser: {
    color: 'rgb(225, 0, 80)',
    //border: "1px solid red",
    marginLeft: 2,
    padding: 0,
    minWidth: 'auto',
    '& path': {
      color: 'rgb(225, 0, 80)'
    },
    '&:hover': {
      color: 'white',
      //border: "1px solid red",
      backgroundColor: 'rgb(225, 0, 80)',
      '& path': {
        color: 'white'
      }
    }
  },
  customLoader: {
    position: 'absolute' as 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center' as 'center',
    zIndex: 999,
    background: 'rgba(255,255,255,0.8)'
  },
  customLoaderContent: {
    margin: 'auto'
  },
  SDetalleOperacionModal: {
    '& button.secondary': {
      background: '#82BC00',
      border: '1px solid #82BC00',
      borderRadius: '4px',
      width: '100px',
      height: '40px',
      textAlign: 'center' as 'center',
      fontSize: '12px',
      lineHeight: '17px',
      color: '#fff',
      marginRight: '20px' as any,
      '&:hover': {
        color: '#82BC00',
        background: '#FFF',
        fontSize: '13px'
      }
    }
  },
  SDetalleOperacionModalDialog: {
    maxWidth: '750px',
    height: '550px',
    padding: '24px',
    borderRadius: '7px'
  },
  headerDetalleOperacionModal: {
    fontSize: '20px',
    lineHeight: '32px',
    color: '#555759',
    borderBottom: '1px solid #E5E6E6',
    width: '100%',
    marginBottom: '32px'
  },
  operacionCedidaActionsTableButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& a': {
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center' as 'center',
      textDecorationLine: 'underline',
      color: '#555759',
      margin: '0px 5px',
      cursor: 'pointer'
    }
  },
  tabsBackground: {
    backgroundColor: '#fff',
    padding: '16px'
  },
  Snackbar: {
    background: '#82BC00',
    '& .MuiSnackbarContent-*': {
      background: '#82BC00'
    }
  },
  snackbarOld: {
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.136609)',
    borderRadius: '7px',
    '& .line-header': {
      borderRadius: '7px 7px 0px 0px',
      width: '100%',
      height: '10px',
      backgroundColor: '#82BC00'
    },
    '& .content': {
      padding: '16px',
      '& .title': {
        fontSize: '16px',
        lineHeight: '18px',
        color: '#95989A',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #82BC00',
        padding: '0px 0px 8px 0px'
      },
      '& .desc': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 8px',
        '& .circle': {
          minWidth: 9,
          minHeight: 9,
          borderRadius: 9,
          margin: '0px 16px 0px 0px',
          '&.green': {
            backgroundColor: '#82BC00'
          },
          '&.red': {
            backgroundColor: '#D75959'
          }
        },
        '& .t': {
          fontWeight: 'normal' as 'normal',
          fontSize: '16px',
          lineHeight: '18px',
          color: '#8C8C8C'

        }
      }
    }
  },
  noDocumentContainer: {
    padding: '8px 0px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    '& .info': {
      display: 'flex',
      alignItems: 'center',
      '& p': {
        marginLeft: 16,
        paddingRight: '57px' as any
      }
    }
  },
  btnReintentarCesion: {
    backgroundColor: '#790000',
    borderRadius: '4px',
    color: '#fff',
    float: 'right' as 'right',
    marginTop: '16px',
    '&:hover': {
      backgroundColor: '#900303'
    }
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '8px 16px'
  },
  cardBody: {
    padding: '0px 16px 32px'
  },
  cardBodyForm: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between'
  },
  cardBodyCurse: {
    padding: '16px 16px 0px'
  },
  cardHeaderTitle: {
    fontSize: '12px',
    lineHeight: '13px',
    color: '#BDBFC0',
    marginRight: '16px' as any
  },
  cardHeaderSubtitle: {
    fontSize: '7px',
    lineHeight: '12px',
    color: 'rgba(46, 42, 37, 0.54)'
  },
  cardHeaderTitleBold: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#555759'
  },
  indicator: {
    backgroundColor: '#82BC00'
  },
  tabFactotal: {
    color: '#000',
    backgroundColor: '#fff'
  },
  tabFactotalIcon: {
    color: '#82BC00'
  },
  tabFactotalIconWrapper: {
    flexDirection: 'inherit' as 'inherit'
  },
  tabFactotalIconLabel: {
    padding: '8px',
    width: 'auto'
  },
  datePickerVenc: {
    // background: 'rgba(121, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '0px 4px',
    '& input': {
      // color: '#790000'
    }
  },
  datePickerVencErr: {
    background: 'rgba(121, 0, 0, 0.1)',
    borderRadius: '4px',
    padding: '0px 4px',
    '& input': {
      color: '#790000'
    }
  },
  drawer: {
    background: '#F2F5EC',
    width: '720px',
    height: '100%',
    padding: '16px',
    display: 'flex',
    flexDirection: 'column' as 'column'
  },
  drawerTitle: {
    padding: '8px 0px',
    borderBottom: '2px solid #82BC00',
    fontWeight: 600,
    fontSize: '11px',
    lineHeight: '24px',
    textTransform: 'uppercase' as 'uppercase',
    color: '#000000'
  },
  drawerResume: {
    background: '#686B6D',
    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.15)',
    borderRadius: '0px',
    color: '#fff',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& span': {
      fontSize: '12px',
      lineHeight: '15px',
      textAlign: 'right' as 'right',
      color: '#FFFFFF'
    }
  },
  drawerCount: {
    marginRight: '62px' as any
  },
  actionsButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '32px'
  },
  deleteAll: {
    fontSize: '12px',
    lineHeight: '17px',
    textDecoration: 'underline',
    color: '#000000',
    cursor: 'pointer'
  },
  drawerCloseButton: {
    alignSelf: 'flex-end',
    marginBottom: '8px'
  },
  termsLink: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#8C8C8C',
    '& a': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  /* COLAPSE */
  collapseForm: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    height: '100%',
    width: '100%'
  },
  collapseFormButtons: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'flex-end' as 'flex-end',
    height: '100%',
    width: '100%',
    paddingRight: '26px',
    minHeight: '50px'
  },
  maxWidthInput: {
    width: '100%',
    minWidth: '217px',
    maxWidth: '267px',
    padding: '0px 16px 0px 0px'
  },
  /* DETAILS CARD */
  cardContentFixPadding: {
    padding: '16px 32px !important'
  },
  detailsCardContainer: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between',
    width: '100%'
  },
  containerContentDetails: {
    display: 'flex' as 'flex',
    flexDirection: 'column' as 'column',
    width: '50%',
    height: '100%'
  },
  containerContainerDetailsPadding: {
    paddingRight: '8px'
  },
  separatorContainerDetails: {
    width: '1px',
    backgroundColor: 'rgba(140, 140, 140, 0.2)',
    height: '50px',
    margin: '0px 32px'
  },
  containDetails: {
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'space-between' as 'space-between'
  },
  containDetailsValue: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#3A3A3A'
  },
  containDetailsAmount: {
    fontSize: '14px',
    lineHeight: '26px',
    color: '#3A3A3A',
    fontWeight: 600
  },
  seleccionaContratoAlert: {
    fontSize: '16px',
    lineHeight: '21px',
    textAlign: 'center' as 'center',
    color: '#555759',
    backgroundColor: '#F4F4F4',
    display: 'flex' as 'flex',
    alignItems: 'center' as 'center',
    justifyContent: 'center' as 'center',
    height: '100%',
    borderRadius: '4px',
    minHeight: '328px'
  }
};

export default extendedFormsStyle;

import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useState } from 'react';
import BallotIcon from '../../../images/icons/icons-bienes/ballot_24px_outlined.svg';
import CarIcon from '../../../images/icons/icons-bienes/caricons.svg';
import LocalShipIcon from '../../../images/icons/icons-bienes/local_shipping_24px_outlined.svg';
import DirectionRailsIcon from '../../../images/icons/icons-bienes/directions_railway_24px_outlined.svg';
import dialogStyle from '../../../styles/jss/components/dialogStyle';

export type TipoVehiculo = 'vehiculo' | 'vehiculoPesado' | 'maquinaria';
export const tvToString = (tv: TipoVehiculo) => {
  switch (tv) {
    case 'vehiculo':
      return 'Vehículo';
    case 'vehiculoPesado':
      return 'Vehículo pesado';
    case 'maquinaria':
      return 'Maquinaria';
  }
};
export type TipoInscripcion = 'inscripcion' | 'transferencia';
export const tiToString = (ti: TipoInscripcion) => {
  switch (ti) {
    case 'transferencia':
      return 'Transferencia';
    case 'inscripcion':
      return 'Inscripción';
  }
};

interface IProps extends WithStyles<typeof dialogStyle> {
  onClose: () => any;
  onSeleccionar: (tipoInscripcion: TipoInscripcion, tipoVehiculo: TipoVehiculo) => any;
  open: boolean;
}

const NuevoBien: FC<IProps> = (props) => {
  const { classes, onClose, onSeleccionar, open } = props;
  const [tipoVehiculo, setTipoVehiculo] = useState<TipoVehiculo | undefined>(undefined);
  const [tipoInscripcion, setTipoInscripcion] = useState<TipoInscripcion | undefined>(undefined);

  const seleccionarNuevoBienCrear = () => {
    if (tipoVehiculo !== undefined && tipoInscripcion !== undefined) {
      onSeleccionar(tipoInscripcion, tipoVehiculo);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      className={classes.root}
      open={open}
      PaperProps={{ className: classes.dialogPaperGreyVariant }}
    >
      <div className={classes.containerGreyVariant}>
        <div className={classes.floatingHeaderIcon}>
          <img src={BallotIcon} alt='' />
        </div>
        <DialogContent
          classes={{
            root: classes.dialogContent
          }}
        >
          <div className={classes.bigTitle}>¿Qué tipo de acción desea realizar?</div>
          <div className={classes.stepTitle}>Paso 1: Seleccione el tipo de vehículo</div>
          <div className={classes.containerOptions}>
            <div
              className={[
                classes.tipoBoxes,
                tipoVehiculo === 'vehiculo' ? classes.selectedTipoBoxes : ''
              ].join(' ')}
              onClick={() => setTipoVehiculo('vehiculo')}
            >
              <img src={CarIcon} alt='' />
              <div className={classes.boxesName}>Vehículo</div>
            </div>
            <div
              className={[
                classes.tipoBoxes,
                tipoVehiculo === 'vehiculoPesado' ? classes.selectedTipoBoxes : ''
              ].join(' ')}
              onClick={() => setTipoVehiculo('vehiculoPesado')}
            >
              <img src={LocalShipIcon} alt='' />
              <div className={classes.boxesName}>Vehículo pesado</div>
            </div>
            <div
              className={[
                classes.tipoBoxes,
                tipoVehiculo === 'maquinaria' ? classes.selectedTipoBoxes : ''
              ].join(' ')}
              onClick={() => setTipoVehiculo('maquinaria')}
            >
              <img src={DirectionRailsIcon} alt='' />
              <div className={classes.boxesName}>Maquinaria</div>
            </div>
          </div>
          {tipoVehiculo !== undefined && (
            <React.Fragment>
              <div className={classes.stepTitle}>Paso 2: Seleccione el tipo de incripción</div>
              <div className={classes.containerOptions}>
                <div
                  className={[
                    classes.tipoBoxes,
                    tipoInscripcion === 'inscripcion' ? classes.selectedTipoBoxes : ''
                  ].join(' ')}
                  onClick={() => setTipoInscripcion('inscripcion')}
                >
                  <div className={classes.boxesName}>
                    Primera
                    <br />
                    Inscripción
                  </div>
                </div>
                <div
                  className={[
                    classes.tipoBoxes,
                    tipoInscripcion === 'transferencia' ? classes.selectedTipoBoxes : ''
                  ].join(' ')}
                  onClick={() => setTipoInscripcion('transferencia')}
                >
                  <div className={classes.boxesName}>Transferencia</div>
                </div>
              </div>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          {(tipoVehiculo !== undefined && tipoInscripcion !== undefined) && (
            <Button
              variant='outlined'
              color='primary'
              className={classes.greenButton}
              onClick={seleccionarNuevoBienCrear}
            >
              Continuar
            </Button>
          )}
          <Button variant='outlined' className={classes.greyButton} onClick={onClose}>
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(dialogStyle)(NuevoBien);

import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../../../styles/jss/components/extendedFormsStyle';

interface IAdjuntosDocumento {
  classes : any,
  documento : Dto.BackOffice.Documento,
  descargarAdjunto : (idDocumento : string, nombre : string) => void,
  verDetalle : (doc : Dto.BackOffice.Documento) => void,
}

const AccionesDocumento = ({ classes, documento, descargarAdjunto, verDetalle } : IAdjuntosDocumento) => {
  return (
    <div className={classes.operacionCedidaActionsTableButton} key={documento.numeroDocumento}>
      {documento && documento.archivos ?
        <React.Fragment>
          {documento.archivos.map(a => <a key={documento.id + a.nombre}
                                          onClick={() => descargarAdjunto(documento.id, a.nombre)}>{a.nombre}</a>)}
        </React.Fragment> : null
      }
      <a onClick={() => verDetalle(documento)}>DETALLE</a>
    </div>

  );
};

export default withStyles(HistoricoStyle)(AccionesDocumento);

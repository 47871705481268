import React, { FC } from 'react';
import { Button, WithStyles } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import scssTool from '../../../styles/scssTool';
import classnames from 'classnames';

const styleTabs = (theme : Theme) => ({
  tabsDashboardContainer: {
    ...scssTool.flex('center', 'row', 'flex-start'),
    top: '-26px',
    position: 'absolute' as 'absolute',
    left: '32px'
  },
  rootTab: {
    background: '#82BC00',
    width: '132px',
    height: '52px',
    ...scssTool.flex('center', 'row', 'center'),
    marginRight: '4px',
    borderRadius: '0px',
    '&:hover': {
      background: '#82BC00'
    }
  },
  labelTab: {
    ...scssTool.font(18, 800, 25),
    color: '#fff'
  },
  inactive: {
    background: '#E6E6E6',
    '& $labelTab': {
      color: '#C4C4C4',
      fontWeight: 400
    },
    '&:hover': {
      background: '#82BC00',
      '& $labelTab': {
        color: '#fff'
      }
    }
  },
  leasingTecnologico: {
    width: '214px'
  }
});

interface ITabsDashboard extends WithStyles<typeof styleTabs> {
  setActive : (active : number) => any;
  activeTab : number;
}

const TabsDashboard : FC<ITabsDashboard> = (props) => {
  const { classes, setActive, activeTab } = props;

  return (
    <div className={classes.tabsDashboardContainer}>
      <Button
        classes={{ root: classes.rootTab, label: classes.labelTab }}
        className={classnames({
          [classes.inactive]: activeTab !== 1
        })}
        onClick={() => setActive(1)}
      >
        Factoring
      </Button>
      <Button
        classes={{ root: classes.rootTab, label: classes.labelTab }}
        className={classnames({
          [classes.inactive]: activeTab !== 2
        })}
        onClick={() => setActive(2)}
      >
        Leasing
      </Button>
      <Button
        classes={{ root: classes.rootTab, label: classes.labelTab }}
        className={classnames(classes.leasingTecnologico, {
          [classes.inactive]: activeTab !== 3
        })}
        onClick={() => setActive(3)}
      >
        Leasing tecnológico
      </Button>
    </div>
  );
};

export default withStyles(styleTabs)(TabsDashboard);

import * as React from 'react';
import { FC, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Theme, Typography, WithStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubirXmlDocumento from './SubirXmlDocumento';

const styles = (theme: Theme) => ({
  containerState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  typo: {
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center' as 'center',
    color: '#95989A',
    marginRight: '8px'
  },
  green: {
    color: '#82BC00'
  },
  red: {
    color: '#790000'
  },
  black: {
    color: '#555759'
  }
});

interface IEstadoDocumentoProps extends WithStyles<typeof styles> {
  documento: Dto.BackOffice.Documento,
  puedeCeder: boolean
}

const EstadoDocumento: FC<IEstadoDocumentoProps> = (props) => {
  const { classes, documento, puedeCeder } = props;
  return (
    <div className={classes.containerState}>
      {documento.idEstado === 0 && <Typography className={classes.typo}> </Typography>}
      {documento.idEstado === 1 && puedeCeder &&
        <Fragment>
          <Typography className={classes.typo}>Validando con el SII</Typography>
          <CircularProgress
            variant='indeterminate'
            disableShrink
            size={20}
            thickness={4}
          />
        </Fragment>
      }
      {documento.idEstado === 2 &&
        <Typography className={[classes.typo, classes.green].join(' ')}>Cedible</Typography>
      }
      {documento.idEstado === 3 && puedeCeder &&
        <Typography><SubirXmlDocumento className={[classes.typo, classes.red].join(' ')}
                                       documento={documento} /></Typography>
      }
      {documento.idEstado === 4 &&
        <Typography className={[classes.typo, classes.black].join(' ')}>Rechazado</Typography>
      }
      {documento.idEstado === 5 &&
        <Typography className={[classes.typo, classes.red].join(' ')}>Cedida a Factotal</Typography>
      }
      {documento.idEstado === 6 &&
        <Typography className={[classes.typo, classes.red].join(' ')} title={documento.mensajeVerificacion}>Verificación SII</Typography>
      }
      {documento.idEstado === 7 &&
        <Typography className={[classes.typo, classes.green].join(' ')}>Cedido</Typography>
      }
      {documento.idEstado === 8 &&
        <Typography className={[classes.typo, classes.red].join(' ')}>Error Cesión</Typography>
      }
      {documento.idEstado === 9 &&
        <Fragment>
          <Typography className={classes.typo}>Proceso Cesión</Typography>
          <CircularProgress
            variant='indeterminate'
            disableShrink
            size={20}
            thickness={4}
          />
        </Fragment>
      }
      {documento.idEstado === 10 &&
        <Typography className={[classes.typo, classes.black].join(' ')}>Proceso</Typography>
      }
    </div>
  );
};

export default withStyles(styles)(EstadoDocumento);

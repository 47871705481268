import React from 'react';
import { WithStyles } from '@material-ui/core';
import DashboardStyle from '../../styles/dashboardStyle';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Vendor from '../vendor/view';
import VendorProducto from '../vendor/producto';
import { AppState } from '../../config/rootReducer';


interface IProps extends WithStyles<typeof DashboardStyle>, RouteComponentProps {
  dispatch : any;
  empresa : Dto.EnterpriseProfile;
}

interface IState {
}

class TableroVendor extends React.Component<IProps, IState> {
  constructor(props : IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { empresa } = this.props;

    return <>{empresa.vendor ? <Vendor /> : <VendorProducto />}</>;
  }
}

const mapStateToProps = (state : AppState) => ({
  isLoading: state.main.isLoading,
  rows: state.dashboard.rows
});

export default withStyles(DashboardStyle)(connect(mapStateToProps)(withRouter(TableroVendor)));

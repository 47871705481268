import { ButtonBase } from '@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import React, { FC, useEffect, useMemo, useState } from 'react';
import contratosGeneralStyle from '../contratosGeneralStyle';
import { useHistory } from 'react-router';
import BigHeaderContent from '../components/BigHeaderContent/BigHeaderContent';
import ConfirmarSolicitud, { IResultadoEnvioTransferencia } from '../components/Modales/ConfirmarSolicitud';
import ImpuestoTransferenciaModal from '../components/Modales/ImpuestoTransferenciaModal';
import CargarDocumentoModal from '../components/Modales/CargarDocumentoModal';
import ActualizarRegistroModal from '../components/Modales/ActualizarRegistroModal';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HistorialTransferenciaModal from '../components/Modales/HistorialTransferenciaModal';
import routes from '../../../config/routes';
import { TipoVehiculo, tvToString } from './nuevoBien';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { connect } from 'react-redux';
import TransferenciaVehiculo from '../components/Vehiculo/TransferenciaVehiculo';
import { iniModeloCompradorVendedor, TModeloCompradorVendedor } from './view';
import DocumentoTransferencia from '../components/Documentos/DocumentoTransferencia';
import { invertirFecha } from '../../../helpers/FormatDate';
import { cargarTransferencia, enviarTransferencia, persistirTransferencia } from '../actions';
import Personas from '../components/Personas';
import CertificadoAnotacionesVigentesModal from '../components/Modales/CertificadoAnotacionesVigentesModal';

export type TModeloVehiculo = Dto.RegistroCivil.TransferenciaVehiculo;
export type TModeloIT = Dto.RegistroCivil.ImpuestoTransferencia;
export type TModeloTransferencia = Dto.RegistroCivil.DocumentoTransferencia;

const iniModeloTransferencia = (): TModeloTransferencia => ({
  fecha: '',
  folioFactura: '',
  idComunaLugar: '0' as any,
  monto: '',
  rutEmisor: '',
  tipoDocumento: '0' as any,
  tipoMoneda: '0' as any,
  codigoNotaria: 0,
  kilometraje: 0,
  naturaleza: '0' as any
});

const iniModeloVehiculo = (): TModeloVehiculo => ({
  anoFabricacion: new Date().getFullYear(),
  ppu: '',
  chasis: '',
  color: '',
  marca: '',
  modelo: '',
  nroMotor: '',
  nroSerie: '',
  nroVin: '',
  tipoVehiculo: '0' as any
});

const iniModeloIT = (): TModeloIT => ({
  cid: '',
  montoPagado: ''
});

interface RouteParams {
  id: string;
  tipoVehiculo: TipoVehiculo;
}

interface StateProps {
  empresa: Dto.RegistroCivil.Empresa | undefined;
  contrato: Dto.RegistroCivil.Contrato | undefined;
  transferencia: Dto.RegistroCivil.Transferencia | undefined;
}

interface DispatchProps {
  guardarTransferencia: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.Transferencia) => Promise<Response.RegistroCivil.Transferencia>;
  enviarTransferencia: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.EnviarTransferencia) => Promise<Response.RegistroCivil.EnviarTransferencia>;
  cargarTransferencia: (idTransferencia: number) => void;
}

interface IProps extends StateProps, DispatchProps, WithStyles<typeof contratosGeneralStyle>, RouteComponentProps<RouteParams> {
}

const mapStateToProps = (state: AppState): StateProps => ({
  empresa: state.registroCivil.empresa,
  contrato: state.registroCivil.contrato,
  transferencia: state.registroCivil.transferencia
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  guardarTransferencia: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.Transferencia) => dispatch(persistirTransferencia(idEmpresa, idContrato, payload)),
  enviarTransferencia: (idEmpresa: string, idContrato: string, payload: Request.RegistroCivil.EnviarTransferencia) => dispatch(enviarTransferencia(idEmpresa, idContrato, payload)),
  cargarTransferencia: (idTransferencia: number) => dispatch(cargarTransferencia(idTransferencia))
});

const Transferencias: FC<IProps> = (props) => {
  const { classes, transferencia, empresa, contrato } = props;

  const [openConfirmar, setOpenConfirmar] = useState(false);
  const [openTransferencia, setOpenTransferencia] = useState(false);
  const [openCargarDocumento, setOpenCargardocumento] = useState(false);
  const [openActualizarRegistro, setActualizarRegistro] = useState(false);
  const [openHistorial, setOpenHistorial] = useState(false);
  const [cargando, setCargando] = useState(false);

  const [modeloVehiculo, setModeloVehiculo] = useState<TModeloVehiculo>(iniModeloVehiculo());
  const [modeloIT, setModeloIT] = useState<TModeloIT>(iniModeloIT());
  const [modeloTransferencia, setModeloTransferencia] = useState<TModeloTransferencia>(iniModeloTransferencia());

  const [compradores, setCompradores] = useState<TModeloCompradorVendedor[]>([]);
  const [vendedores, setVendedores] = useState<TModeloCompradorVendedor[]>([]);

  const [showCargarArchivo, setShowCargarArchivo] = useState(false);
  const [showEnviar, setShowEnviar] = useState(true);
  const [showGuardar, setShowGuardar] = useState(true);
  const [showHistorial, setShowHistorial] = useState(false);
  const [openCertificadoAntecedentes, setOpenCertificadoAntecedentes] = useState(false);
  const [showObtenerCAV, setShowObtenerCAV] = useState(true);
  const [tipoSolicitud, setTipoSolicitud] = useState(2);
  const [isNotValidForm, setIsNotValidForm] = useState(false);

  const [color, setColor] = useState('');

  useEffect(() => {
    setModeloVehiculo(transferencia?.vehiculo || iniModeloVehiculo());
    setModeloIT(transferencia?.impuestoTransferencia || iniModeloIT());
    setModeloTransferencia(transferencia?.documentoTransferencia || iniModeloTransferencia());
    setCompradores([transferencia?.comprador || iniModeloCompradorVendedor()]);
    setVendedores([transferencia?.vendedor || iniModeloCompradorVendedor()]);
    setColor(transferencia?.vehiculo?.color?.toString() || '');
    if (transferencia?.numeroSolicitud) {
      setShowCargarArchivo(true);
      setShowEnviar(false);
      setShowGuardar(false);
    } else {
      setShowCargarArchivo(false);
      setShowEnviar(true);
      setShowGuardar(true);
    }
    if (transferencia?.idTransferencia) {
      setShowHistorial(true);
    } else {
      setShowEnviar(false);
    }
  }, [transferencia]);

  const setStateTransferencia = (field: keyof TModeloTransferencia, value: any) => {
    setModeloTransferencia({ ...modeloTransferencia, [field]: value });
  };

  const setStateVehiculo = (field: keyof TModeloVehiculo, value: any) => {
    setModeloVehiculo({ ...modeloVehiculo, [field]: value });
  };

  const setStateIT = (field: keyof TModeloIT, value: any) => {
    setModeloIT({ ...modeloIT, [field]: value });
  };

  const titulo = useMemo(() => {
    if (props.contrato === undefined) return '';
    return `Nueva transferencia en contrato # ${props.contrato.numero}`;
  }, [props.contrato]);

  useEffect(() => {
    if (props.match.params.id !== '') {
      props.cargarTransferencia(Number(props.match.params.id));
    }
  }, [props.match.params.id]);

  const history = useHistory();

  useEffect(() => {
    if (modeloVehiculo.marca.length < 2 || modeloVehiculo.modelo.length < 1 ||
      modeloVehiculo.anoFabricacion == 0 || modeloVehiculo.ppu.length < 8 ||
      color.length == 0 || modeloVehiculo.nroMotor.length == 0 ||
      modeloVehiculo.chasis.length == 0 || modeloVehiculo.nroSerie.length == 0 ||
      modeloVehiculo.nroVin.length == 0 || modeloIT.montoPagado.length == 0 || modeloIT.cid.length == 0 ||
      Number(modeloTransferencia.naturaleza) == 0 || Number(modeloTransferencia.tipoDocumento) == 0 ||
      modeloTransferencia.fecha.length == 0 || modeloTransferencia.rutEmisor.length == 0 ||
      modeloTransferencia.folioFactura.length == 0 || modeloTransferencia.kilometraje.toString().length == 0 ||
      Number(modeloTransferencia.idComunaLugar) == 0 || modeloTransferencia.monto.length == 0 ||
      Number(modeloTransferencia.tipoMoneda) == 0 || Number(modeloTransferencia.codigoNotaria) == 0 ||
      compradores.length == 0 || vendedores.length == 0) {
      setIsNotValidForm(true);
    } else {
      setIsNotValidForm(false);
    }
  }, [modeloVehiculo, vendedores, compradores, modeloTransferencia, modeloIT]);

  const setEnviarTransferencia = async (): Promise<IResultadoEnvioTransferencia> => {
    let exito: boolean = false;
    let mensaje: string = '';
    if (empresa && contrato && transferencia) {

      setCargando(true);
      const payload: Request.RegistroCivil.EnviarTransferencia = {
        idTransferencia: transferencia.idTransferencia
      };
      const respuesta = await props.enviarTransferencia(empresa.idEmpresa.toString(), contrato.idContrato.toString(), payload);
      exito = respuesta.success;
      mensaje = respuesta.message;
      setCargando(false);
    }
    return {
      fecha: 'PRUEBA',
      hora: 'PRUEBA',
      oficina: 'PRUEBA',
      observaciones: ['prueba', 'prueba'],
      ppu: '?????',
      solicitud: 'PRUEBA PRUEBA',
      success: exito,
      message: mensaje
    };
  };

  const onGrabar = async () => {
    if (empresa && contrato) {

      setCargando(true);
      const payload: Request.RegistroCivil.Transferencia = {
        idTransferencia: transferencia?.idTransferencia,
        documentoTransferencia: {
          ...modeloTransferencia,
          fecha: modeloTransferencia.fecha !== '' ? invertirFecha(modeloTransferencia.fecha) : ''
        },
        vehiculo: modeloVehiculo,
        impuestoTransferencia: modeloIT,
        comprador: compradores[0],
        vendedor: vendedores[0]
      };
      const respuesta = await props.guardarTransferencia(empresa.idEmpresa.toString(), contrato.idContrato.toString(), payload);
      setCargando(false);
    }
  };

  const onCargarArchivo = () => {
    setOpenCargardocumento(false);
  };

  const onAbrirHistorial = () => {
    setOpenHistorial(!openHistorial);
  };
  const onObtenerCAV = () => {
    setOpenCertificadoAntecedentes(!openCertificadoAntecedentes);
  };

  const onActualizarRegistro = () => {
    setActualizarRegistro(false);
  };

  const aceptarCompradores = (personas: TModeloCompradorVendedor[]) => {
    setCompradores([...personas]);
  };

  const aceptarVendedores = (personas: TModeloCompradorVendedor[]) => {
    setVendedores([...personas]);
  };

  const onBack = () => {
    history.push(routes.registroVehiculos.contratosEmpresa(props.match.params.id));
  };

  return (
    <React.Fragment>
      <div className={classes.afterHeader}>
        <ButtonBase aria-label='cerrar' onClick={onBack} className={classes.backButton}>
          <ArrowBackIcon /> Volver
        </ButtonBase>
      </div>
      <BigHeaderContent titulo={titulo} estado='' cargando={cargando} onGrabar={onGrabar} onEnviar={() => setOpenConfirmar(!openConfirmar)}
                        onVolver={onBack} showCargarArchivo={showCargarArchivo} showEnviar={showEnviar} showGuardar={showGuardar}
                        onAbrirHistorial={onAbrirHistorial} showHistorial={showHistorial}
                        onObtenerCAV={onObtenerCAV} showObtenerCAV={showObtenerCAV} notValid={isNotValidForm} />
      {/*<Banner text='Esta es la forma de mostrar algún warning' />*/}
      <div className={classes.titleSecciones}>Secciones</div>
      <TransferenciaVehiculo modelo={modeloVehiculo}
                             modeloIT={modeloIT}
                             setState={setStateVehiculo}
                             setStateIT={setStateIT}
                             color={color}
                             setColor={setColor} />
      <Personas personas={vendedores} aceptarPersonas={aceptarVendedores}
                titulo='Ingrese la información relativa al vendedor del vehículo' elemento='Vendedor' />
      <Personas personas={compradores} aceptarPersonas={aceptarCompradores}
                titulo='Ingrese la información relativa al comprador del vehículo' elemento='Comprador' />
      <DocumentoTransferencia modelo={modeloTransferencia} setState={setStateTransferencia} />
      <ConfirmarSolicitud open={openConfirmar} onClose={() => setOpenConfirmar(!openConfirmar)} onEnviarTransferencia={setEnviarTransferencia} />
      <ImpuestoTransferenciaModal open={openTransferencia} onClose={() => setOpenTransferencia(!openTransferencia)} />
      <CargarDocumentoModal open={openCargarDocumento} onClose={() => setOpenCargardocumento(!openCargarDocumento)}
                            onCargarArchivo={onCargarArchivo} tipoSolicitud={tipoSolicitud} />
      <CertificadoAnotacionesVigentesModal open={openCertificadoAntecedentes}
                                           empresa={empresa}
                                           contrato={contrato}
                                           transferencia={transferencia}
                                           onClose={() => setOpenCertificadoAntecedentes(!openCertificadoAntecedentes)}
      />
      <ActualizarRegistroModal open={openActualizarRegistro} onClose={() => setActualizarRegistro(!openActualizarRegistro)}
                               onActualizarRegistro={onActualizarRegistro} />
      <HistorialTransferenciaModal open={openHistorial} onClose={() => setOpenHistorial(!openHistorial)} contrato={contrato} empresa={empresa}
                                   transferencia={transferencia} />
    </React.Fragment>
  );
};

export default withStyles(contratosGeneralStyle)(connect(mapStateToProps, mapDispatchToProps)(Transferencias));

// import Main from "../components/Main/view";

export const routes = {
  root: '/',
  subscriber: '/Subscriber',
  login: '/login',
  operacionesHistoricas: '/ophistorico',
  cobranza: '/cobranza',
  admin: {
    empresas: '/admin/enterprise',
    users: '/admin/users'
  },
  user: {
    root: '',
    misDatos: '/user/misdatos',
    certificados: '/user/certificados',
    atencionCliente: '/user/atencion-cliente',
    videoTutoriales: '/user/videotutoriales',
    cuentasBancarias: '/user/cuentasBancarias',
    subscripciones: '/user/subscripciones'
  },
  registroVehiculos: {
    root: '/registroVehiculos',
    empresas: '/registroVehiculos/contratos',
    informes: '/registroVehiculos/informes',
    contratosEmpresa: (id = ':id') => '/registroVehiculos/contratos/' + id,
    primeraInscripcion: (id = ':id', tipoVehiculo = ':tipoVehiculo') => '/registroVehiculos/primeraInscripcion/' + id + '/' + tipoVehiculo,
    transferencia: (id = ':id', tipoVehiculo = ':tipoVehiculo') => '/registroVehiculos/transferencia/' + id + '/' + tipoVehiculo
  },
  miTablero: '/mi-tablero',
  factoring: {
    root: '/factoring',
    cartera: '/factoring/cartera',
    producto: '/factoring/producto',
    operaciones: {
      solicitud: '/factoring/operaciones/solicitud',
      proceso: '/factoring/operaciones/proceso',
      recesion: '/factoring/operaciones/recesion',
      historico: '/factoring/operaciones/historico'
    },
    excedentes: {
      disponibles: '/factoring/excedentes/disponibles',
      historico: '/factoring/excedentes/historico'
    }
  },
  facturasEmitidas: '/factemitidas',
  seguimientoOperacion: '/seOperacion',
  excedentesDisponibles: '/excedentes/disponibles',
  operacionesEnProceso: '/opEnProceso',
  leasing: {
    root: '/leasing',
    producto: '/leasing/producto',
    negocio: '/leasing/negocio',
    contratos: '/leasing/contratos',
    bienes: '/leasing/bienes',
    bienesContrato: (id = ':id') => '/leasing/bienes/' + id
  },
  vendor: {
    root: '/vendor',
    producto: '/vendor/producto',
    negocio: '/vendor/negocio',
    contratos: '/vendor/contratos',
    cuotas: '/vendor/cuotas',
    siniestros: '/vendor/siniestros',
    bienes: '/vendor/bienes',
    bienesContrato: (id = ':id') => '/vendor/bienes/' + id
  }
};
//
// const routes = [
//   { path: paths.root, component: DashBoard },
//   { path: paths.login, component: Login },
//   { path: paths.operacionesHistoricas, component: OperacionesHistoricas },
//   { path: paths.cobranza, component: Cobranza },
//   { path: paths.cartera, component: Cartera },
//   { path: paths.facturasEmitidas, component: FacturasEmitidas },
//   { path: paths.seguimientoOperacion, component: SeguimientoOperacion },
//   { path: paths.excedentesDisponibles, component: ExcedentesDisponibles },
//   { path: paths.operacionesEnProceso, component: OperacionesEnProceso }
// ];

export default routes;

import { generateMarketingAccountAPI, getMarketingUsersAPI, ISendMailToExecutiveAtencionClienteApiPayload, sendMailToExecutiveAtencionClienteApi } from '../../services/factoringApi';
import { asyncActionType } from '../../helpers/ActionsHelper';
import { Dispatch } from 'redux';

export const fetchAction = asyncActionType('@@MARKETING', 'FETCH');

export const fetchDatosUsuario = () => ({
  type: fetchAction.PENDING,
});

export const DatosUsuarioFetched = () => ({
  type: fetchAction.SUCCESS,
});

export const generateMarketingAccount = (params: { rutUsuario: string }) => (dispatch: Dispatch) => {
  return generateMarketingAccountAPI(params)
    .then(response => response)
    .catch(error => error);
};

export const SendMailToExecutive = (params: ISendMailToExecutiveAtencionClienteApiPayload) => (dispatch: Dispatch) => {
  return sendMailToExecutiveAtencionClienteApi(params)
    .then(response => response)
    .catch(error => error);
};


export const getMarketingUsers = () => (dispatch: Dispatch) => {
  return getMarketingUsersAPI()
    .then(response => response)
    .catch(error => error);
};

export default { generateMarketingAccount, getMarketingUsers, SendMailToExecutive };

// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import {
  obtenerCobranzaFactoring,
  obtenerDetalleCobranzaFactoring,
  obtenerExcelCobranzaFactoring,
  obtenerPdfCobranzaFactoring,
  setEventTrackingExcel,
  setTrackingSidebar
} from './actions';
import xlsx from 'xlsx';
import moment from 'moment';
import 'moment/locale/es';
import ReactTable from 'react-table';
import Moment from 'moment/moment';

// componentes material ui
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';

// componentes custom
import Breadcrumb from '../../shared/Breadcrumb/Breadcrumb';
import GridItem from '../../shared/Grid/GridItem';
import GridContainer from '../../shared/Grid/GridContainer';
import NavPills from '../../shared/NavPills/NavPills';
import SVGIcons from '../../shared/SVGIcons/SVGIcons';
import RangeCalendar from '../../shared/RangeCalendar/RangeCalendar';
import PaginationBase from '../../shared/Pagination/PaginationBase';
import Loader from '../../shared/Loader/Loader';
import { onChangeOnlyNumericHelper, onChangeRangNumericHelper, validarRangoNroDoc } from '../../../helpers/ValidateNumeric';
import { formatNumber } from '../../../helpers/FormatNumber';

// Estilo
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import { WithStyles } from '@material-ui/core';
import FiltersHeader from '../../sharedv2/FiltersHeader/FiltersHeader';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { convertPaginacion, iniPaginacion } from '../../../helpers/PaginationHelper';


interface IRowRecord<T> {
  value: any;
  original: T;
}

interface StateProps {
  rows: Dto.CarteraRow[][];
  paginacion: State.Paginacion[];
  total: Dto.CarteraTotal[];
  loading: boolean[];
}

interface DispatchProps {
  obtenerDetalleCobranzaFactoring: (filter: Filters.DetalleCobranzaFactoring) => Promise<Response.DetalleCobranza>;
  obtenerCobranzaFactoring: (filter: Filters.CobranzaFactoring) => Promise<void>;
  obtenerPdfCobranzaFactoring: (filter: Filters.CobranzaFactoring) => Promise<void>;
  obtenerExcelCobranzaFactoring: (filter: Filters.CobranzaFactoring) => Promise<Response.Cartera>;
  setEventTrackingExcel: (filter: string) => Promise<void>;
  setTrackingSidebar: (filter: string) => Promise<void>;
}

interface ITableProps {
  columns: any[];
  totalItemsPerPage: number;
  totalItems: number;
  nameTable: string;
  pages: number;
  loading: boolean;
  noTable: number;
  nomDataTable: string;
  totalTable: string;
  paginationTable: string;
  data: Dto.CarteraRow[];
  manual: boolean;
}

interface ITableModalProps {
  nameTable: string;
  columns: any[];
  loading: boolean;
  data: any;
  totalItems: number;
  manual: boolean;
}

interface IProps extends StateProps, DispatchProps, WithStyles <typeof HistoricoStyle> {
};

interface IState {
  activeTab: number;
  openCalendar: boolean;
  anchorEl: any;
  anchorElCalendar: any;
  expanded: boolean;
  numDocumentosFrom: string;
  numDocumentosTo: string;
  calendarValue: {
    desde: string
    hasta: string
  };
  estadoF: string;
  // numeOperacion : string
  nombreDeudor: string;
  // estadoDTE : string
  fechaVencimientoDesde: string;
  fechaVencimientoHasta: string;
  // tipoDocumento : string
  comentario: string;
  comentarioErrorMessage: string;
  isValidarComentario: boolean;
  tipoComentarioErrorMessage: string;
  isValidarTipoComentario: boolean;
  comentType: string;
  loadingPopup: boolean;
  paginationTablePopUp: State.Paginacion;
  dataTablePopUp: Dto.DetalleCobranzaRow[];
}

class Cobranza extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      expanded: true,
      openCalendar: false,
      anchorEl: null,
      anchorElCalendar: null,
      comentario: '',
      comentarioErrorMessage: '',
      isValidarComentario: true,
      tipoComentarioErrorMessage: '',
      isValidarTipoComentario: true,
      numDocumentosFrom: '',
      numDocumentosTo: '',
      fechaVencimientoDesde: moment(new Date()).format('DD/MM/YYYY'),
      fechaVencimientoHasta: moment(new Date()).format('YYYY-MM-DD'),
      estadoF: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      // dateRange: {
      //   selection: {
      //     startDate: new Date(),
      //     endDate: null,
      //     key: 'selection'
      //   }
      // },
      comentType: '',
      loadingPopup: false,
      activeTab: 1,
      dataTablePopUp: [],
      paginationTablePopUp: iniPaginacion(),
      nombreDeudor: ''

    };
    this.fetchData = this.fetchData.bind(this);
  }

  onChangeTab = (active: number) => this.setState({ activeTab: active });

  getFilters = (page: number, pageSize: number, tipoDocumento: any, sorted: any): Filters.CobranzaFactoring => {
    return {
      numeroDocumentoDesde: this.state.numDocumentosFrom,
      numeroDocumentoHasta: this.state.numDocumentosTo,
      fechaVencimientoDesde: (this.state.calendarValue.desde) ? Moment(this.state.calendarValue.desde, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      fechaVencimientoHasta: (this.state.calendarValue.hasta) ? Moment(this.state.calendarValue.hasta, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
      estadoDocumento: this.state.estadoF,
      deudor: this.state.nombreDeudor,
      numeroPagina: page,
      tamanoPagina: pageSize,
      tipoDocumento: tipoDocumento,
      ascending: sorted && sorted[0] && sorted[0].desc ? false : sorted && sorted[0] ? true : null,
      orderBy: sorted && sorted[0] && sorted[0].id ? this.getSortIdColumn(sorted[0].id) : 12,
      isExportPdf: null,
      isCobranza: null,
      isCobranzaNoPdf: null
    };
  };

  getSortIdColumn(column: string) {
    let _id = null;
    switch (column) {
      case 'CodFamilia':
        _id = 1;
        break;
      case 'CodTipoDocto':
        _id = 2;
        break;
      case 'TipoDocumento':
        _id = 3;
        break;
      case 'numeroDocumento':
        _id = 4;
        break;
      case 'montoDocumento':
        _id = 5;
        break;
      case 'Monto Financiado':
        _id = 6;
        break;
      case 'Retencion':
        _id = 7;
        break;
      case 'abonos':
        _id = 8;
        break;
      case 'saldos':
        _id = 9;
        break;
      case 'CodEstado':
        _id = 10;
        break;
      case 'estadoDocumento':
        _id = 11;
        break;
      case 'fechaVencimiento':
        _id = 12;
        break;
      case 'numeroOperacion':
        _id = 13;
        break;
      case 'CodEstadoDTE':
        _id = 14;
        break;
      case 'estadoDte':
        _id = 15;
        break;
      case 'CodGestionCob':
        _id = 16;
        break;
      case 'GestionCob':
        _id = 17;
        break;
      case 'FechaOperacion':
        _id = 18;
        break;
      case 'rutDeudor':
        _id = 19;
        break;
      case 'nombreDeudor':
        _id = 20;
        break;
    }
    return _id;
  }

  fetchData = (state: any, tipoDocumento: number, dataProp: string, dataTotal: string, dataPagination: string) => {
    if (this.props.loading[tipoDocumento]) return;
    this.props.obtenerCobranzaFactoring(this.getFilters(state.page, state.pageSize, tipoDocumento, state.sorted)).then(() => {
    });
  };

  buttonGetPDF = async () => {
    const { activeTab } = this.state;
    let tipoDocumento: number = activeTab;

    const paginationPage = this.props.paginacion[tipoDocumento].page;
    const paginationPageSize = this.props.paginacion[tipoDocumento].pageSize;

    await this.props.obtenerPdfCobranzaFactoring(this.getFilters(paginationPage, paginationPageSize, tipoDocumento, null));
  };

  buttonGetExcel = async () => {
    const { activeTab } = this.state;
    let tipoDocumento: number = activeTab;

    const paginationTotalItems = this.props.paginacion[activeTab].count;
    const data = await this.props.obtenerExcelCobranzaFactoring(this.getFilters(0, paginationTotalItems, tipoDocumento, null));
    this.exportExcel(data, tipoDocumento);
    await this.props.setEventTrackingExcel('cobranzaExcel');
  };

  exportExcel = (cartera: Response.Cartera, tipoDocumento: number) => {
    let workbook = xlsx.utils.book_new();
    let formattedData: any[] = [];
    if (cartera.data.length > 0) {
      formattedData = cartera.data.map(d => {
        let formattedOrder = {
          'N° DOCUMENTO': d.numeroDocumento,
          'DEUDOR': d.nombreDeudor,
          'MONTO DOCUMENTO': (d.montoDocumento),
          'FECHA VENCIMIENTO': d.fechaVencimiento ? Moment(d.fechaVencimiento).format('DD/MM/YYYY') : '',
          'ESTADO DOCUMENTO': d.estadoDocumento,
          'DÍAS MORA': d.diasMora
        };
        return formattedOrder;
      });
    }
    let orderSheet = xlsx.utils.json_to_sheet(formattedData);

    orderSheet['!cols'] = [
      { wpx: 100 }, //N° Documento
      { wpx: 250 }, //Deudor
      { wpx: 140 }, //Monto documento
      { wpx: 130 }, //Fecha vencimiento
      { wpx: 150 }, //Estado documento
      { wpx: 60 } //Días mora
    ];

    if (tipoDocumento === 1)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'FACTURAS');
    else if (tipoDocumento === 2)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'CHEQUES');
    else if (tipoDocumento === 3)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'LETRAS');
    else if (tipoDocumento === 4)
      xlsx.utils.book_append_sheet(workbook, orderSheet, 'PAGARES');

    xlsx.writeFile(workbook, `Cobranza - ${Moment().format('YYYY-MM-DD-hh-mm')}.xlsx`);
  };

  handleFiltrar = async () => {
    const facturaTable = this.refs.facturasTable as any;
    const chequeTable = this.refs.chequesTable as any;
    const letraTable = this.refs.letrasTable as any;
    const pagareTable = this.refs.pagaresTable as any;

    if (facturaTable) {
      facturaTable.state.page = 0;
      facturaTable.fireFetchData();
    }
    if (chequeTable) {
      chequeTable.state.page = 0;
      chequeTable.fireFetchData();
    }
    if (letraTable) {
      letraTable.state.page = 0;
      letraTable.fireFetchData();
    }
    if (pagareTable) {
      pagareTable.state.page = 0;
      pagareTable.fireFetchData();
    }

    if (!this.checkInputFilter()) {
      await this.props.setTrackingSidebar('cobranzaBuscar');
    }
  };

  toggleExpand = () => {
    this.setState((state) => ({ expanded: !state.expanded }));
  };

  defaultFilterMethod = (filter: any, row: any, column: any) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id]) === filter.value : true;
  };


  handleClickTable = async (event: any, n: number, c: number, l: string) => {
    const { currentTarget } = event;
    if (currentTarget && currentTarget.firstChild) currentTarget.firstChild.classList.add('active');
    this.setState({
      anchorEl: currentTarget,
      loadingPopup: true,
      dataTablePopUp: []
    });
    const response = await this.props.obtenerDetalleCobranzaFactoring({ numDocumento: n, tipoDocumento: c, rutDeudor: l, numeroPagina: 1, tamanoPagina: 50 });
    let data: Dto.DetalleCobranzaRow[] = response.data || [];
    data = data.filter(e => !e.fechaGestion.includes('1900'));
    let pagination = convertPaginacion(response.paginacion);
    this.setState({ ...this.state, dataTablePopUp: data, loadingPopup: false, paginationTablePopUp: pagination });
  };

  handleCloseTable = () => {
    if (this.state.anchorEl && this.state.anchorEl.firstChild) this.state.anchorEl.firstChild.classList.remove('active');
    this.setState({
      anchorEl: null
    });
  };

  handleStatesCalendar = (params: any) => {
    this.setState(params);
  };

  // onChangeNumOperacion = (e : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => (this.refs.facturasTable as any).filterColumn(this.state.columns[0], e.target.value);

  // @ts-ignore
  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => this.setState({ [e.target.name]: e.target.value });

  // @ts-ignore
  onChangeRangNumeric = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ [e.target.name]: onChangeRangNumericHelper(e) });

  // @ts-ignore
  onChangeOnlyNumeric = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState({ [e.target.name]: onChangeOnlyNumericHelper(e) });

  handleClickEnviar = () => {

    const { comentType, comentario } = this.state;

    if (comentType.trim() == '')
      this.setState({ tipoComentarioErrorMessage: 'El tipo comentario es requerido', isValidarTipoComentario: false });

    if (comentType.trim() != '')
      this.setState({ tipoComentarioErrorMessage: '', isValidarTipoComentario: true });

    if (comentario.trim() == '')
      this.setState({ comentarioErrorMessage: 'El comentario es requerido', isValidarComentario: false });
    else if (comentario.trim() != '')
      this.setState({ comentarioErrorMessage: '', isValidarComentario: true });
  };

  cleanFilters = () => {
    this.setState({
      numDocumentosFrom: '',
      numDocumentosTo: '',
      calendarValue: {
        desde: '',
        hasta: ''
      },
      estadoF: '',
      nombreDeudor: ''
    }, async () => {
      await this.handleFiltrar();
    });
  };

  checkInputFilter = () =>
    !(this.state.calendarValue.desde ||
      this.state.calendarValue.hasta ||
      this.state.numDocumentosFrom.length > 0 ||
      this.state.numDocumentosTo.length > 0 ||
      // this.state.estadoF.length > 0 ? false : true ||
      this.state.estadoF ||
      this.state.nombreDeudor.length > 4);

  componentDidMount() {
    this.cleanFilters();
  }

  tableProps = (params: ITableProps) => ({
    columns: params.columns,
    defaultPageSize: 10,
    minRows: params.totalItemsPerPage && params.totalItemsPerPage <= params.totalItems ? params.totalItemsPerPage : 10,
    showPaginationBottom: params.totalItemsPerPage === params.totalItems ? false : true,
    PaginationComponent: PaginationBase,
    previousText: 'Anterior',
    nextText: 'Siguiente',
    noDataText: 'Sin información',
    pageText: 'Pagina',
    ofText: 'de',
    rowsText: 'filas',
    resizable: false,
    defaultFilterMethod: this.defaultFilterMethod,
    filterable: true,
    showFilters: false,
    loadingText: <Loader loadTable />,
    manual: params.manual,
    data: params.data,
    ref: params.nameTable,
    onFetchData: (state: any) => this.fetchData(state, params.noTable, params.nomDataTable, params.totalTable, params.paginationTable),
    pages: params.pages,
    totalItems: params.totalItems,
    loading: params.loading
  });

  columnsTable = (total: Dto.CarteraTotal | undefined) => [
    {
      Header: 'N° DOCUMENTO',
      accessor: 'numeroDocumento',
      headerClassName: this.props.classes.HeaderTable,
      width: 80,
      id: 'numeroDocumento',
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableNoOperacion
    },
    {
      Header: 'DEUDOR',
      accessor: 'nombreDeudor',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      // width: 100,
      className: this.props.classes.TDTable,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => <div style={{ textTransform: 'capitalize' }}>{props.value.toLowerCase()}</div>
    },
    {
      Header: 'MONTO DOCUMENTO',
      accessor: 'montoDocumento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableRightAlign,
      width: 80,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableRightAlign,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => formatNumber(props.value)
    },
    {
      Header: 'ABONOS',
      accessor: 'abonos',
      id: 'abonos',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableRightAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableRightAlign,
      width: 100,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => formatNumber(props.value)
    },
    {
      Header: 'SALDO',
      accessor: 'saldos',
      id: 'saldos',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableRightAlign,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableRightAlign,
      width: 100,
      Cell: (props: IRowRecord<Dto.CarteraRow>) => formatNumber(props.value)
    },
    {
      Header: 'FECHA VENCIMIENTO',
      accessor: 'fechaVencimiento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 100,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
      Cell: (props: IRowRecord<Dto.CarteraRow>) =>
        <div>{props.original && props.original.fechaVencimiento ? Moment(props.original.fechaVencimiento).format('DD/MM/YYYY') : ''}</div>
    },
    {
      Header: 'ESTADO DOCUMENTO',
      accessor: 'estadoDocumento',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 80,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign
    },
    {
      Header: 'DÍAS MORA',
      accessor: 'diasMora',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 60,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign
    },
    {
      Header: 'ESTADO COBRANZA',
      headerClassName: this.props.classes.HeaderTable + ' ' + this.props.classes.TDTableCenterAlign,
      width: 70,
      className: this.props.classes.TDTable + ' ' + this.props.classes.TDTableCenterAlign,
      Cell: ((props: IRowRecord<Dto.CarteraRow>) => {
        return <div
          aria-haspopup='true'
          onClick={(e) => this.handleClickTable(e, props.original.numDocumento, Number(props.original.codigoTipoDocumento), props.original.rutDeudor)}
        >
          <SearchIcon className={this.props.classes.TDTableCenterIcon} />
        </div>;
      })
    }
  ];

  listTabs = (rows: Dto.CarteraRow[][]) => [
    {
      tabButton: 'FACTURAS',
      hidden: rows[1].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'facturasTable',
            columns: this.columnsTable(this.props.total[1]),
            loading: this.props.loading[1],
            data: rows[1],
            pages: this.props.paginacion[1].maxPages,
            totalItems: this.props.paginacion[1].count,
            totalItemsPerPage: this.props.paginacion[1].pageSize,
            noTable: 1,
            nomDataTable: 'dataFactura',
            totalTable: 'totalFactura',
            paginationTable: 'paginationFactura',
            manual: true
          })}
        />
      )
    },
    {
      tabButton: 'CHEQUES',
      hidden: rows[2].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'chequesTable',
            columns: this.columnsTable(this.props.total[2]),
            loading: this.props.loading[2],
            data: rows[2],
            manual: true,
            pages: this.props.paginacion[2].maxPages,
            totalItems: this.props.paginacion[2].count,
            totalItemsPerPage: this.props.paginacion[2].pageSize,
            noTable: 2,
            nomDataTable: 'dataCheque',
            totalTable: 'totalCheque',
            paginationTable: 'paginationCheque'
          })}
        />
      )
    },
    {
      tabButton: 'LETRAS',
      hidden: rows[3].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'letrasTable',
            columns: this.columnsTable(this.props.total[3]),
            loading: this.props.loading[3],
            data: rows[3],
            pages: this.props.paginacion[3].maxPages,
            totalItems: this.props.paginacion[3].count,
            totalItemsPerPage: this.props.paginacion[3].pageSize,
            noTable: 3,
            nomDataTable: 'dataLetra',
            manual: true,
            totalTable: 'totalLetra',
            paginationTable: 'paginationLetra'
          })}
        />
      )
    },
    {
      tabButton: 'PAGARÉS',
      hidden: rows[4].length !== 0,
      tabContent: (
        <ReactTable
          {...this.tableProps({
            nameTable: 'pagaresTable',
            columns: this.columnsTable(this.props.total[4]),
            loading: this.props.loading[4],
            data: rows[4],
            pages: this.props.paginacion[4].maxPages,
            totalItems: this.props.paginacion[4].count,
            totalItemsPerPage: this.props.paginacion[4].pageSize,
            noTable: 4,
            nomDataTable: 'dataPagare',
            manual: true,
            totalTable: 'totalPagare',
            paginationTable: 'paginationPagare'
          })}
        />
      )
    }
  ];

  render() {
    const { classes, rows } = this.props;
    const { anchorEl, openCalendar, anchorElCalendar, calendarValue } = this.state;

    const open = Boolean(anchorEl);

    const tablePopupProps = (params: ITableModalProps) => ({
      columns: params.columns,
      defaultPageSize: 5,
      showPaginationBottom: true,
      PaginationComponent: PaginationBase,
      previousText: 'Anterior',
      nextText: 'Siguiente',
      noDataText: 'Sin información',
      pageText: 'Pagina',
      ofText: 'de',
      rowsText: 'filas',
      resizable: false,
      defaultFilterMethod: this.defaultFilterMethod,
      filterable: true,
      showFilters: false,
      loadingText: <Loader loadTable />,
      manual: false,
      ref: params.nameTable,
      data: params.data,
      totalItems: params.totalItems,
      loading: params.loading
    });
    const columnsTablePopUp = [
      {
        Header: 'Fecha',
        headerClassName: classes.HeaderTable + ' ' + classes.TDTableCenterAlign,
        accessor: 'fechaGestion',
        width: 100,
        id: 'date',
        className: classes.TDTable + ' ' + classes.TDTableCenterAlign,
        Cell: (row: any) => <div>{row.original.fechaGestion ? moment(row.original.fechaGestion).format('DD/MM/YYYY') : ''}</div>
      },
      {
        Header: 'Comentario',
        headerClassName: classes.HeaderTable,
        accessor: 'comentario',
        width: 300,
        id: 'comment',
        className: classes.TDTable,
        Cell: ((row: any) => {
          let text = row && row.original && row.original.estadoCobranza ? row.original.estadoCobranza : '';
          return <div style={{ whiteSpace: 'normal' }}>{text}</div>;
        })
      }
    ];

    const tabs = this.listTabs(rows);

    let activa = tabs.findIndex(d => d.hidden);
    activa = activa === -1 ? 0 : activa;

    return (
      <div>
        <Popover
          id='simple-popper'
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleCloseTable}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <div className={classes.TablePopUpContainer}>
            <ReactTable
              {...tablePopupProps({
                nameTable: 'popoverTable',
                columns: columnsTablePopUp,
                loading: this.state.loadingPopup,
                data: this.state.dataTablePopUp,
                totalItems: this.state.paginationTablePopUp.count,
                manual: false
              })}
            />
          </div>
        </Popover>
        <div>
          <Breadcrumb category='Usted se encuentra en: Factoring / Cobranza' textAlign='right' />
          <Typography variant='h5' gutterBottom className={classes.sectionTitle}>
            Cobranza
          </Typography>
        </div>
        <GridContainer>
          <GridItem xs={12} style={{ height: '100%' }}>
            <Card
              style={{ height: '132px' }}>
              <FiltersHeader cleanFilters={this.cleanFilters} expanded={this.state.expanded} toggleExpand={this.toggleExpand} />

              <Collapse in={this.state.expanded} timeout='auto' unmountOnExit>
                <CardContent className={classes.filterCardContent}>
                  <form className={classes.filterForm}>
                    <GridContainer>
                      <GridItem xs={3}>
                        <FormControl fullWidth>
                          <FormLabel
                            style={{
                              fontSize: 11,
                              top: 0,
                              left: 0,
                              position: 'absolute'
                            }}
                          >N&deg; Documentos</FormLabel>
                          <GridContainer style={{ marginTop: 8 }}>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                InputLabelProps={{ shrink: true }}
                                id='numDocumentosFrom'
                                name='numDocumentosFrom'
                                value={this.state.numDocumentosFrom}
                                placeholder='Desde'
                                onChange={this.onChangeRangNumeric}
                                onBlur={validarRangoNroDoc}
                              />
                            </GridItem>
                            <GridItem xs={6}>
                              <TextField
                                className={classes.filterFormtextField}
                                id='numDocumentosTo'
                                name='numDocumentosTo'
                                value={this.state.numDocumentosTo}
                                placeholder='Hasta'
                                onChange={this.onChangeRangNumeric}
                                onBlur={validarRangoNroDoc}
                              />
                            </GridItem>
                          </GridContainer>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}>
                        <FormControl fullWidth>
                          <RangeCalendar
                            id='fechaVencimiento'
                            name='fechaVencimiento'
                            label='Fecha Vencimiento'
                            value={calendarValue.desde && calendarValue.hasta ? calendarValue.desde + ' - ' + calendarValue.hasta : ''}
                            setStates={this.handleStatesCalendar}
                            openCalendar={openCalendar}
                            anchorElCalendar={anchorElCalendar}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={2}>
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor='estadoF'>Estado documento</InputLabel>
                          <Select
                            className={classes.filterFormSelectField}
                            value={this.state.estadoF}
                            onChange={e => this.onChange(e)}
                            displayEmpty
                            input={
                              <Input name='estadoF' id='estadoF' />
                            }
                            name='estadoF'
                          >
                            <MenuItem value=''>
                              Todos
                            </MenuItem>
                            <MenuItem value={'Vigente'}>Vigente</MenuItem>
                            <MenuItem value={'Moroso'}>Moroso</MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>

                      <GridItem xs={2}
                      >
                        <FormControl fullWidth>
                          <TextField
                            className={classes.filterFormtextField}
                            InputLabelProps={{ shrink: true }}
                            id='nombreDeudor'
                            name='nombreDeudor'
                            value={this.state.nombreDeudor}
                            label='Deudor'
                            placeholder='Nombre'
                            onChange={this.onChange}
                          />
                        </FormControl>
                      </GridItem>

                      <GridItem className={classes.filterFormButtonContainer} xs={3}>
                        <FormControl className={classes.filterFormButtonBody}>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={this.handleFiltrar}
                            disabled={this.checkInputFilter()}
                          >
                            Buscar
                          </Button>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                  </form>
                </CardContent>
              </Collapse>
            </Card>
          </GridItem>
          <GridItem xs={6}>
            <Card
              raised={true}
              classes={{
                root: classes.comentHeaderRoot
              }}
              style={{
                backgroundColor: '#EFF0F0',
                boxShadow: 'inset 0px 0px 0px 1px #BDBFC0',
                display: 'none'
              }}
            >
              <CardHeader
                title='Comentarios'
                style={{ paddingLeft: '15px', color: '#608C00' }}
                subheader={
                  <div style={{ color: '#000000' }}>Envienos sus dudas y comentarios de cobranza desde este formulario.</div>
                }
                classes={{
                  title: classes.comentHeaderTitle,
                  subheader: classes.commentSubHeaderTitle,
                  content: classes.comentHeaderContent
                }}
              />
              <GridContainer className={classes.comentContent}>
                <GridItem xs={2}>
                  <FormControl fullWidth>
                    <TextField
                      className={classes.filterFormtextField}
                      id='comentType'
                      select
                      name='comentType'
                      label='Tipo Comentario'
                      error={!this.state.isValidarTipoComentario}
                      value={this.state.comentType}
                      required
                      onChange={this.onChange}
                      InputLabelProps={{ shrink: true }}
                    >
                      <MenuItem value='' />
                      <MenuItem value={'type 1'}>type 1</MenuItem>
                      <MenuItem value={'type 2'}>type 2</MenuItem>
                    </TextField>
                    <FormHelperText
                      id='component-error-text'
                      error>
                      {this.state.tipoComentarioErrorMessage}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={8}>
                  <FormControl fullWidth>
                    <TextField
                      className={classes.filterFormtextField}
                      id='Coment'
                      name='Coment'
                      label='Comentario'
                      error={!this.state.isValidarComentario}
                      placeholder='Comentario'
                      required
                      onBlur={(e) => {
                        this.setState({ comentario: e.target.value });
                      }}
                      multiline
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 500 }}
                    />
                    <FormHelperText
                      style={{
                        height: (!this.state.isValidarTipoComentario) ? '30px' : '100%'
                      }}
                      id='component-error-text'
                      error>
                      {this.state.comentarioErrorMessage}
                    </FormHelperText>
                  </FormControl>
                </GridItem>
                <GridItem xs={2}>
                  <FormControl style={{
                    paddingLeft: 5,
                    paddingRight: 5,
                    margin: 'auto'
                  }}>
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginTop: 3.5, marginBottom: 2.5 }}
                      onClick={this.handleClickEnviar}
                    >
                      Enviar
                    </Button>
                    <div
                      style={{ height: (!this.state.isValidarTipoComentario) ? '35px' : '100%' }}
                    >&nbsp;</div>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} style={{ position: 'relative' }}>
            {(rows[1].length + rows[2].length + rows[3].length + rows[4].length) > 0 &&
              <GridItem className={classes.gridPDFExcelButtons}>
                <Tooltip title='Descargar PDF' placement='top'>
                  <IconButton onClick={() => this.buttonGetPDF()}>
                    <SVGIcons id='CobrIconDescargarPdf' icon='pdf' />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Descargar Planilla Excel' placement='top'>
                  <IconButton onClick={() => this.buttonGetExcel()}>
                    <SVGIcons id='CobrIconDescargarExcel' icon='excel' />
                  </IconButton>
                </Tooltip>
              </GridItem>}
            <NavPills
              color='info'
              active={activa}
              tabs={tabs}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  obtenerDetalleCobranzaFactoring: (payload: Filters.DetalleCobranzaFactoring) => dispatch(obtenerDetalleCobranzaFactoring(payload)),
  obtenerCobranzaFactoring: (payload: Filters.CobranzaFactoring) => dispatch(obtenerCobranzaFactoring(payload)),
  obtenerPdfCobranzaFactoring: (payload: Filters.CobranzaFactoring) => dispatch(obtenerPdfCobranzaFactoring(payload)),
  obtenerExcelCobranzaFactoring: (payload: Filters.CobranzaFactoring) => dispatch(obtenerExcelCobranzaFactoring(payload)),
  setEventTrackingExcel: (payload: string) => dispatch(setEventTrackingExcel(payload)),
  setTrackingSidebar: (payload: string) => dispatch(setTrackingSidebar(payload))
});

const mapStateToProps = (state: AppState): StateProps => ({
  rows: state.cobranza.rows,
  paginacion: state.cobranza.paginacion,
  total: state.cobranza.total,
  loading: state.cobranza.loading
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(Cobranza));

import MenuItem from "@material-ui/core/MenuItem";
import React from "react";

export interface ISelector {
  id: string;
  texto: string;
}

export interface IVehiculo extends ISelector {
  impuestoVerde: boolean | 'condicion';
  citModelo: boolean;
  carga: boolean;
}
export interface IColor extends ISelector{
  key: string;
}

export interface ICalidad extends ISelector {
  personaNatural: boolean;
}

export const TIPO_CARGA_PESOS: ISelector[] = [{ id: '1', texto: 'Kilos' }, { id: '2', texto: 'Toneladas' }];

export const MONEDAS: ISelector[] = [{ id: '1', texto: 'Peso' }, { id: '2', texto: 'Dólar' }, { id: '3', texto: 'Unidad de fomento' }];

export const CALIDAD: ICalidad[] = [{ id: '1', texto: 'Natural', personaNatural: true },
  { id: '2', texto: 'Jurídico', personaNatural: false },
  { id: '3', texto: 'Extranjero', personaNatural: true },
  { id: '4', texto: 'Comunidad', personaNatural: false }];

export const COMBUSTIBLE: ISelector[] = [
  { texto: 'NO INFORMADO', id: '0' },
  { texto: 'GASOLINA', id: '1' },
  { texto: 'DIESEL', id: '2' },
  { texto: 'GNC (GAS NATURAL COMPRIMIDO)', id: '3' },
  { texto: 'GLP (GAS LICIADO DE PETROLEO)', id: '4' },
  { texto: 'ELECTRICO', id: '5' },
  { texto: 'DUAL (GNC/ELECTRICO)', id: '6' },
  { texto: 'DUAL (GLP/GASOLINA)', id: '7' },
  { texto: 'DUAL (GLC/GASOLINA)', id: '8' },
  { texto: 'DUAL (ELECTRICO/GASOLINA)', id: '9' }];

export const VEHICULOS_LM: IVehiculo[] = [
  { texto: 'AUTOMOVIL', id: '1', carga: false, citModelo: true, impuestoVerde: true },
  { texto: 'STATION WAGON', id: '2', carga: false, citModelo: true, impuestoVerde: true },
  { texto: 'FURGON', id: '3', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'CAMIONETA', id: '4', carga: true, citModelo: true, impuestoVerde: 'condicion' },
  { texto: 'MINIBUS', id: '5', carga: false, citModelo: true, impuestoVerde: 'condicion' },
  { texto: 'JEEP', id: '6', carga: false, citModelo: true, impuestoVerde: true },
  { texto: 'LIMUSINA', id: '7', carga: false, citModelo: true, impuestoVerde: true },
  { texto: 'BUS', id: '8', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'MICROBUS', id: '9', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'OMNIBUS', id: '10', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'MAQUINA AGRICULA', id: '11', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'TRACTOR', id: '12', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'CASA RODANTE', id: '13', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'MOTORIZADA', id: '14', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'MAQUINA INDUSTRIAL', id: '15', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'AMBULANCIA', id: '16', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'CARROBOMBA', id: '17', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'TROLEBUS', id: '18', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'CHASIS CABINADO', id: '19', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'COCHE MORTUORIO', id: '20', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'BUGGI', id: '21', carga: false, citModelo: false, impuestoVerde: false },
  { texto: 'TRACTOR ANFIBIO', id: '22', carga: false, citModelo: false, impuestoVerde: false }
];

export const VEHICULOS_TRANSFERENCIA: ISelector[] = [
  { texto: 'AUTOMÓVIL', id: '1' },
  { texto: 'STATION WAGON', id: '2' },
  { texto: 'FURGON', id: '3' },
  { texto: 'CAMIONETA', id: '4' },
  { texto: 'BUS', id: '5' },
  { texto: 'MINIBUS', id: '6' },
  { texto: 'MICROBUS', id: '7' },
  { texto: 'OMNIBUS', id: '8' },
  { texto: 'CAMIÓN', id: '9' },
  { texto: 'CARRO DE ARRASTRE', id: '10' },
  { texto: 'REMOLQUE', id: '11' },
  { texto: 'MÁQUINA AGRÍCOLA', id: '12' },
  { texto: 'MÁQUINA CONSTRUCCIÓN', id: '13' },
  { texto: 'TRACTOR', id: '14' },
  { texto: 'CASA RODANTE MOTORIZADA', id: '15' },
  { texto: 'MOTO', id: '16' },
  { texto: 'BICIMOTO', id: '17' },
  { texto: 'MOTONETA', id: '18' },
  { texto: 'JEEP', id: '19' },
  { texto: 'TRACTOCAMIÓN', id: '20' },
  { texto: 'CHASIS', id: '21' },
  { texto: 'MÁQUINA INDUSTRIAL', id: '22' },
  { texto: 'AMBULANCIA', id: '23' },
  { texto: 'CARROBOMBA', id: '24' },
  { texto: 'TAXIBUS', id: '25' },
  { texto: 'BUS PULLMAN', id: '26' },
  { texto: 'TROLEBUS.', id: '27' },
  { texto: 'SEMIREMOLQUE', id: '28' },
  { texto: 'CHASIS CABINADO', id: '29' },
  { texto: 'LIMUSINA', id: '30' },
  { texto: 'COCHE MORTUORIO', id: '31' },
  { texto: 'BUGGI', id: '32' },
  { texto: 'TRIMOTO', id: '33' },
  { texto: 'CUATRIMOTO', id: '34' },
  { texto: 'TRACTOR ANFIBIO', id: '35' },
  { texto: 'BICICLETA MOTOR', id: '36' },
  { texto: 'TRICICLO MOTOR', id: '37' }
];

export const CODIGO_NOTARIA: ISelector[] = [
  { texto: 'Julio Abasolo Aravena', id: '1' },
  { texto: 'Mario Patricio Aburto Contardo', id: '2' },
  { texto: 'Miguel Aburto Contardo', id: '3' },
  { texto: 'Mariana Abuter Game', id: '4' },
  { texto: 'Alejandro Abuter Game', id: '5' },
  { texto: 'Julio Abuyeres Jadue', id: '6' },
  { texto: 'Jorge Acuna Perez', id: '7' },
  { texto: 'Jessica Acuña Gomez', id: '8' },
  { texto: 'Jose Adasme Bravo', id: '9' },
  { texto: 'Guacolda Aedo Ormeno', id: '10' },
  { texto: 'Julio Aguilar Fuentes', id: '11' },
  { texto: 'Juan Francisco Alamos Ovejero', id: '12' },
  { texto: 'Carlos Alarcon Ramirez', id: '13' },
  { texto: 'Marta Alvarado Agurto', id: '14' },
  { texto: 'Luis Alvarez Diaz', id: '15' },
  { texto: 'Juan Carlos Alvarez Dominguez', id: '16' },
  { texto: 'Luis Alvarez Donoso', id: '17' },
  { texto: 'Francisca Alvarez Hurtado', id: '18' },
  { texto: 'Pedro Hernan Alvarez Lorca', id: '19' },
  { texto: 'Myriam Amigo Arancibia', id: '20' },
  { texto: 'Alejandra Angulo Sandoval', id: '21' },
  { texto: 'Isabel Arancibia Radich', id: '22' },
  { texto: 'Juan Araneda Medina', id: '23' },
  { texto: 'Nestor Araya Blazina', id: '24' },
  { texto: 'Sergio Arenas Benoni', id: '25' },
  { texto: 'Luis Alberto Arenas Moreno', id: '26' },
  { texto: 'Ismael Argandona Concha', id: '27' },
  { texto: 'Juan Arias Garrido', id: '28' },
  { texto: 'Rene Arriagada Basaur', id: '29' },
  { texto: 'Nicolas Arrieta Concha', id: '30' },
  { texto: 'Maria Ester Astorga Lagos', id: '31' },
  { texto: 'Mauricio Astudillo Pizarro', id: '32' },
  { texto: 'Patricio Andres Asun Fuentes', id: '33' },
  { texto: 'Nestor Alejandro Avila Urrutia', id: '34' },
  { texto: 'Jose Luis Ayala Manriquez', id: '35' },
  { texto: 'Pedro Aylwin Valenzuela', id: '36' },
  { texto: 'Eulogio Baeza Gutierrez', id: '37' },
  { texto: 'Gerardo Bambach Echazarreta', id: '38' },
  { texto: 'Jorge Baraqui Wasafe', id: '39' },
  { texto: 'Luisa Isabel Barraza Aguilera', id: '40' },
  { texto: 'Claudio Barrena Eyzaguirre', id: '41' },
  { texto: 'Lebby Barria Gutierrez', id: '42' },
  { texto: 'Cesar Eduardo Barrias Castillo', id: '43' },
  { texto: 'Heriberto Barrientos Bahamonde', id: '44' },
  { texto: 'Hector Basualto Bustamante', id: '45' },
  { texto: 'Miguel Bauza Fredes', id: '46' },
  { texto: 'Jack Behar Saravia', id: '47' },
  { texto: 'Susana Belmonte Aguirre', id: '48' },
  { texto: 'Rene Benavente Cash', id: '49' },
  { texto: 'Roberto A. Bennett Urzua', id: '50' },
  { texto: 'Jaime Bernales Larrain', id: '51' },
  { texto: 'Jaime Bernales Valenzuela', id: '52' },
  { texto: 'Mauricio Bertolino Rendic', id: '53' },
  { texto: 'Alvaro Bianchi Rosas', id: '54' },
  { texto: 'Ana Luisa Birkner Moreira', id: '55' },
  { texto: 'Hernan Blanche Sepulveda', id: '56' },
  { texto: 'Linda Bosch Jimenez', id: '57' },
  { texto: 'Hector Bown Ortega', id: '58' },
  { texto: 'Claudia Brahm Bahamonde', id: '59' },
  { texto: 'Andrea Bravo Barria', id: '60' },
  { texto: 'Maria Jose Bravo Cruz', id: '61' },
  { texto: 'Juan Bustos Bonniard', id: '62' },
  { texto: 'Claudio Cabello Olavarria', id: '63' },
  { texto: 'Rodrigo Cabrera Albarran', id: '64' },
  { texto: 'Irma Caceres Pena', id: '65' },
  { texto: 'Leonardo Calderara Emaldia', id: '66' },
  { texto: 'Zarella Elena Camerotto Moraga', id: '67' },
  { texto: 'Ximena Camus Hormazabal', id: '68' },
  { texto: 'Eugenio Camus Mesa', id: '69' },
  { texto: 'Sandra E. Carcamo Velasquez', id: '70' },
  { texto: 'Juan Ignacio Carmona Zuniga', id: '71' },
  { texto: 'Maria Carrillo Flores', id: '72' },
  { texto: 'Juan Carvajal Palacios', id: '73' },
  { texto: 'Gerardo Carvallo Castillo', id: '74' },
  { texto: 'Jorge Carvallo Velasco', id: '75' },
  { texto: 'Claudia Casanga Baeza', id: '76' },
  { texto: 'Patricio Cathalifaud Moroso', id: '77' },
  { texto: 'Heidi Patricia Cavieres Araya', id: '78' },
  { texto: 'Luis Fernando Celis Urrutia', id: '79' },
  { texto: 'Orlando Cerda Silva', id: '80' },
  { texto: 'Dario Chacon Vicentelo', id: '81' },
  { texto: 'Isabel Chadwick Vergara', id: '82' },
  { texto: 'Lidia Maria Chahuan Issa', id: '83' },
  { texto: 'Roberto Cifuentes Allel', id: '84' },
  { texto: 'Jose Cifuentes Guerra', id: '85' },
  { texto: 'Jaime Cifuentes Villagran', id: '86' },
  { texto: 'Roberto Joaquin Coello Solar', id: '87' },
  { texto: 'Fernando Coloma Amaro', id: '88' },
  { texto: 'Nicolas Constenla Novoa', id: '89' },
  { texto: 'Maria Contardo Silva', id: '90' },
  { texto: 'Pamela Contreras Flores', id: '91' },
  { texto: 'Juan Andres Contreras Fredes', id: '92' },
  { texto: 'Luis Alberto Contreras Fuentes', id: '93' },
  { texto: 'Jaime Contreras Miranda', id: '94' },
  { texto: 'Maria Isabel Contreras Munoz', id: '95' },
  { texto: 'Darwin Contreras Piderit', id: '96' },
  { texto: 'Roberto Cooper Aguilera', id: '97' },
  { texto: 'Carmen Correa Ossa', id: '98' },
  { texto: 'Gerardo Cortes Gasaui', id: '99' },
  { texto: 'Juan Miguel Cortes Michea', id: '100' },
  { texto: 'Gonzalo Cortes Yakcich', id: '101' },
  { texto: 'Luciano Cruz Munoz', id: '102' },
  { texto: 'Hernan Cuadra Gazmuri', id: '103' },
  { texto: 'Andres Gonzalez del Riego', id: '104' },
  { texto: 'Andrés Cuevas Ossandón', id: '105' },
  { texto: 'Edmundo Devia Osorio', id: '106' },
  { texto: 'Maria Lidia Diaz Diaz', id: '107' },
  { texto: 'Marcos Diaz Leon', id: '108' },
  { texto: 'Eduardo Diez Morello', id: '109' },
  { texto: 'Jose Dolmestch Urra', id: '110' },
  { texto: 'Rodrigo Dominguez Jara', id: '111' },
  { texto: 'Alfredo Rodrigo Domke Zepeda', id: '112' },
  { texto: 'María Patricia Donoso Gomien', id: '113' },
  { texto: 'Teodoro Duran Palma', id: '114' },
  { texto: 'Rodrigo Díaz Suárez', id: '115' },
  { texto: 'Sergio Elgueta Barrientos', id: '116' },
  { texto: 'Myriam Escobar Diaz', id: '117' },
  { texto: 'Juan Espinosa Bancalari', id: '118' },
  { texto: 'Bernardo Espinosa Bancalari', id: '119' },
  { texto: 'Alicia Espinosa Cid', id: '120' },
  { texto: 'Luis Espinoza Garrido', id: '121' },
  { texto: 'Raimundo Espinoza Wood', id: '122' },
  { texto: 'Juan Facuse Heresi', id: '123' },
  { texto: 'Rodrigo Farias Picon', id: '124' },
  { texto: 'Raul Farren Paredes', id: '125' },
  { texto: 'Abdallah Fernandez Atuez', id: '126' },
  { texto: 'Oscar Fernandez Mora', id: '127' },
  { texto: 'Waldo Fernandez Nome', id: '128' },
  { texto: 'Gabriel Ferrand Miranda', id: '129' },
  { texto: 'Rodrigo Ferrer Figueroa', id: '130' },
  { texto: 'Edmundo Figueroa Alvarez', id: '131' },
  { texto: 'Monica Figueroa Carvajal', id: '132' },
  { texto: 'Jorge Figueroa Herrera', id: '133' },
  { texto: 'Hugo Figueroa Vargas', id: '134' },
  { texto: 'Luis Fischer Yavar', id: '135' },
  { texto: 'Ricardo Fontecilla Gallardo', id: '136' },
  { texto: 'Luis Franzani Espinosa', id: '137' },
  { texto: 'Hugo Franzani Jara', id: '138' },
  { texto: 'Carolina Fuentealba Madriaga', id: '139' },
  { texto: 'Hernan Fuentes Acevedo', id: '140' },
  { texto: 'Carlos Fuentes Caceres', id: '141' },
  { texto: 'Francisco Fuenzalida Rodriguez', id: '142' },
  { texto: 'Eugenio Gaete Gonzalez', id: '143' },
  { texto: 'Alvaro Gajardo Casanas', id: '144' },
  { texto: 'M.Angelica Galan Bauerle', id: '145' },
  { texto: 'Cecilia Galvez Pino', id: '146' },
  { texto: 'Pablo Gandara Riveros', id: '147' },
  { texto: 'Gonzalo Garay Burnas', id: '148' },
  { texto: 'Gonzalo Garay Burnas', id: '149' },
  { texto: 'Hector Garcia Aguirre', id: '150' },
  { texto: 'Ramon Garcia Carrasco', id: '151' },
  { texto: 'Ximena Garrido Granada', id: '152' },
  { texto: 'Alejandro Gemmel Martinez', id: '153' },
  { texto: 'Eliana Gervasio Zamudio', id: '154' },
  { texto: 'Luis Gillet Bebin', id: '155' },
  { texto: 'Jorge Gillet Bebin', id: '156' },
  { texto: 'Gonzalo Godoy Moersch', id: '157' },
  { texto: 'Claudia Gomez Lucares', id: '158' },
  { texto: 'Carlos Gomez Oyarzun', id: '159' },
  { texto: 'Felipe Gomez-Lobo Somavia', id: '160' },
  { texto: 'Fernando Gomila Gatica', id: '161' },
  { texto: 'Fernando Gomila Larsen', id: '162' },
  { texto: 'Maria Gonzalez Barrientos', id: '163' },
  { texto: 'Pablo Gonzalez Caamano', id: '164' },
  { texto: 'Renata Gonzalez Carvallo', id: '165' },
  { texto: 'Enzo Gonzalez Gonzalez', id: '166' },
  { texto: 'Roberto Gonzalez Maldonado', id: '167' },
  { texto: 'Alvaro Gonzalez Salinas', id: '168' },
  { texto: 'Gabriel Guerrero Gonzalez', id: '169' },
  { texto: 'Gabriel Guerrero Gonzalez', id: '170' },
  { texto: 'Leonor Gutierrez Gatica', id: '171' },
  { texto: 'Octavio Gutierrez Lopez', id: '172' },
  { texto: 'Maria Pilar Gutierrez Rivera', id: '173' },
  { texto: 'Aldo Gutierrez Sepulveda', id: '174' },
  { texto: 'Carlos Guzman Baigorria', id: '175' },
  { texto: 'Hernan Guzman Iturra', id: '176' },
  { texto: 'Pablo Guzman Porras', id: '177' },
  { texto: 'Yusef Hales Hott', id: '178' },
  { texto: 'Gonzalo Harambillet Melero', id: '179' },
  { texto: 'Alejandro Haupt Ossa', id: '180' },
  { texto: 'Marianne Hauser Soto', id: '181' },
  { texto: 'Gaby Hernandez Soto', id: '182' },
  { texto: 'Claudio Herrera Moraga', id: '183' },
  { texto: 'Jacobo Hey Paoa', id: '184' },
  { texto: 'Francisco Hollmann Ovalle', id: '185' },
  { texto: 'Angelita Hormazabal Alegria', id: '186' },
  { texto: 'Sylvia Hueche Fuchslocher', id: '187' },
  { texto: 'Gonzalo Hurtado Morales', id: '188' },
  { texto: 'Daniel Hurtado Navia', id: '189' },
  { texto: 'Gonzalo Hurtado Peralta', id: '190' },
  { texto: 'Fernando Igualt Ansted', id: '191' },
  { texto: 'Bernardo Iturra Munoz', id: '192' },
  { texto: 'Lylian Jacques Parraguez', id: '193' },
  { texto: 'Felix Jara Cadot', id: '194' },
  { texto: 'Sergio Jara Catalan', id: '195' },
  { texto: 'Tomas Jarpa Concha', id: '196' },
  { texto: 'Elias Jarufe Rojas', id: '197' },
  { texto: 'Eugenio Jimenez Larrain', id: '198' },
  { texto: 'Camila Jorquiera Monardes', id: '199' },
  { texto: 'Juan Alberto Kadis Cifuentes', id: '200' },
  { texto: 'Jenson Kriman Nunez', id: '201' },
  { texto: 'Edward Langlois Danks', id: '202' },
  { texto: 'Hernan Larrere Castro', id: '203' },
  { texto: 'Henriette Llarrere Ravanal', id: '204' },
  { texto: 'Rodrigo Lascano Arriagada', id: '205' },
  { texto: 'Maria Soledad Lascar Merino', id: '206' },
  { texto: 'Fernando Laso Cordero', id: '207' },
  { texto: 'Rodrigo Lazcano Arriagada', id: '208' },
  { texto: 'Maria E. Le-Bert Acheritogaray', id: '209' },
  { texto: 'Francisco Leiva Carvajal', id: '210' },
  { texto: 'Raul Leiva Uribe-Echeverria', id: '211' },
  { texto: 'Sergio Lenero Gonzalez', id: '212' },
  { texto: 'Rene Leon Manieu', id: '213' },
  { texto: 'Elena Leyton Carvajal', id: '214' },
  { texto: 'Cristina E. Lolas Chaban', id: '215' },
  { texto: 'Marcos Lopez Julio', id: '216' },
  { texto: 'Oscar Lora Romero', id: '217' },
  { texto: 'Alejandra Loyola Ojeda', id: '218' },
  { texto: 'Juan A. Loyola Opazo', id: '219' },
  { texto: 'Maria Victoria Lucares Robledo', id: '220' },
  { texto: 'Luis Alberto Maldonado Concha', id: '221' },
  { texto: 'Luis Manquehual Mery', id: '222' },
  { texto: 'Patricia Manriquez Huerta', id: '223' },
  { texto: 'Hugo Alberto Marin Diaz', id: '224' },
  { texto: 'Sergio Martel Becerra', id: '225' },
  { texto: 'Fernando Martel Costa', id: '226' },
  { texto: 'Alfredo Martin Illanes', id: '227' },
  { texto: 'Evelyn Martinez Cardemil', id: '228' },
  { texto: 'Jose Antonio Martinez Demandes', id: '229' },
  { texto: 'Rene Martinez Loaiza', id: '230' },
  { texto: 'Pablo Martinez Loaiza', id: '231' },
  { texto: 'Monserratt Martinez Tapia', id: '232' },
  { texto: 'Maria Isabel Martinez Varela', id: '233' },
  { texto: 'Francisco Martinez Torres', id: '234' },
  { texto: 'Juan Carlos Maturana Lepeley', id: '235' },
  { texto: 'Cristian Matus Cuevas', id: '236' },
  { texto: 'Carlos Medina Fernandez', id: '237' },
  { texto: 'Marcela Medina Ricci', id: '238' },
  { texto: 'Luis Mendoza Camus', id: '239' },
  { texto: 'Maria Antonieta Mendoza Escala', id: '240' },
  { texto: 'Alvaro Mera Correa', id: '241' },
  { texto: 'Jose Merello Montofre', id: '242' },
  { texto: 'Rosemarie Mery Ricci', id: '243' },
  { texto: 'Claudio Mesina Schulz', id: '244' },
  { texto: 'Gonzalo Miguez Nunez', id: '245' },
  { texto: 'Humberto Mira Gazmuri', id: '246' },
  { texto: 'Ernesto Miranda Cordova', id: '247' },
  { texto: 'Carlos Miranda Jimenez', id: '248' },
  { texto: 'Julian Andres Miranda Osses', id: '249' },
  { texto: 'Enrique Monasterio Rebolledo', id: '250' },
  { texto: 'Daniel Mondaca Pedrero', id: '251' },
  { texto: 'Hugo Monroy Foix', id: '252' },
  { texto: 'Axel Montero Burgueno', id: '253' },
  { texto: 'Ernesto Montoya Peredo', id: '254' },
  { texto: 'Ulises Morales Rios', id: '255' },
  { texto: 'Alina Morales Tortora', id: '256' },
  { texto: 'Mercedes Moreno Guemes', id: '257' },
  { texto: 'Carolina Moreno Jashes', id: '258' },
  { texto: 'Ricardo Moscoso Bustamante', id: '259' },
  { texto: 'Julio Movillo Mattassi', id: '260' },
  { texto: 'Alberto Mozo Aguilar', id: '261' },
  { texto: 'Herbert Mundy Casanova', id: '262' },
  { texto: 'Daniela Munizaga Pendola', id: '263' },
  { texto: 'Francisco Javier Munoz Flores', id: '264' },
  { texto: 'Esmeralda Munoz Iglesias', id: '265' },
  { texto: 'Yamil Najle Alee', id: '266' },
  { texto: 'Oscar Navarrete Villalobos', id: '267' },
  { texto: 'Luis Navarrete Villegas', id: '268' },
  { texto: 'Gerardo Navarro Escala', id: '269' },
  { texto: 'Claudio Nehme Carpanetti', id: '270' },
  { texto: 'Ma. Antonieta Nino de Zepeda', id: '271' },
  { texto: 'Jaime Oda Ottone', id: '272' },
  { texto: 'Gabriel Ogalde Rodriguez', id: '273' },
  { texto: 'Victor Olguin Pena', id: '274' },
  { texto: 'Nilo Olivares Hidalgo', id: '275' },
  { texto: 'Ricardo Olivares Pizarro', id: '276' },
  { texto: 'Rafael Ormeno Rubilar', id: '277' },
  { texto: 'Julio Cesar Orrego Gonzalez', id: '278' },
  { texto: 'Carlos Ortega Alul', id: '279' },
  { texto: 'Alberto Ortega Jiron', id: '280' },
  { texto: 'Eduardo Ortiz Bravo', id: '281' },
  { texto: 'Gloria Ortiz Carmona', id: '282' },
  { texto: 'Claudio Alfonso Ortiz Cerda', id: '283' },
  { texto: 'Juan Segundo Ortiz Perez', id: '284' },
  { texto: 'Enrique Ortiz Schindler', id: '285' },
  { texto: 'Jorge Ossa Cuevas', id: '286' },
  { texto: 'Federico Oyarce Birchmeier', id: '287' },
  { texto: 'Lorgio Oñate Herrera', id: '288' },
  { texto: 'Alejandro Pardo Kuschel', id: '289' },
  { texto: 'Pedro Luis Parra Ahumada', id: '290' },
  { texto: 'Maria Soledad Parra Aspee', id: '291' },
  { texto: 'Alfredo Parra Ulloa', id: '292' },
  { texto: 'Alvaro Peralta Artigas', id: '293' },
  { texto: 'Osvaldo Pereira Gonzalez', id: '294' },
  { texto: 'Guillermo Perez Diaz', id: '295' },
  { texto: 'Soledad Perez Vega', id: '296' },
  { texto: 'Alex Perez de Tudela Vega', id: '297' },
  { texto: 'Maria Victoria Perugi Poblete', id: '298' },
  { texto: 'Maria Elena Pezoa Contreras', id: '299' },
  { texto: 'Juan Roberto Peña Urra', id: '300' },
  { texto: 'Julio Pizarro Maggio', id: '301' },
  { texto: 'Manuel Placencio Penaloza', id: '302' },
  { texto: 'Carmen Podlech Michaud', id: '303' },
  { texto: 'Anfion Podlech Romero', id: '304' },
  { texto: 'Jaime Polloni Contardo', id: '305' },
  { texto: 'Marcel Pommiez Ilufi', id: '306' },
  { texto: 'Luis Poza Maldonado', id: '307' },
  { texto: 'Abner Poza Matus', id: '308' },
  { texto: 'Miguel Canales Reveco', id: '309' },
  { texto: 'Juan Antonio Puga Lozano', id: '310' },
  { texto: 'Roberto Puga Pino', id: '311' },
  { texto: 'Humberto Quezada Moreno', id: '312' },
  { texto: 'Victor Hugo Quinones Sobarzo', id: '313' },
  { texto: 'Lorena Quintanilla Leon', id: '314' },
  { texto: 'Maria Loreto Quitral Veloso', id: '315' },
  { texto: 'Patricio Raby Benavente', id: '316' },
  { texto: 'Manuel Ramirez Escobar', id: '317' },
  { texto: 'Sonia Ravanal Toro', id: '318' },
  { texto: 'Marcelo Razon Sanchez', id: '319' },
  { texto: 'Sergio Recabarren Gonzalez', id: '320' },
  { texto: 'Enzo Redolfi Gonzalez', id: '321' },
  { texto: 'Jorge Rehbein Ohaco', id: '322' },
  { texto: 'Evaldo Rehbein Utreras', id: '323' },
  { texto: 'Ruben Reinoso Herrera', id: '324' },
  { texto: 'Omar Retamal Becerra', id: '325' },
  { texto: 'Juan Antonio Retamal Concha', id: '326' },
  { texto: 'Hernan Jose Retamal Grimberg', id: '327' },
  { texto: 'Pedro Reveco Hormazabal', id: '328' },
  { texto: 'Jorge Reyes Bessone', id: '329' },
  { texto: 'Ximena Ricci Diaz', id: '330' },
  { texto: 'Marcelo Riesco Vega', id: '331' },
  { texto: 'Andres Rieutord Alvarado', id: '332' },
  { texto: 'Ingrid Rios Fuentes', id: '333' },
  { texto: 'Ana Marisol Rios Lagos', id: '334' },
  { texto: 'Nazael Riquelme Espinoza', id: '335' },
  { texto: 'Marta Rivas Schultz', id: '336' },
  { texto: 'Maria Eugenia Rivera Gonzalez', id: '337' },
  { texto: 'Claudio Rivera Ramirez', id: '338' },
  { texto: 'Juan Riveros Donoso', id: '339' },
  { texto: 'Eduardo Roco Campos', id: '340' },
  { texto: 'Luis Eduardo Rodríguez Burr', id: '341' },
  { texto: 'Juan Bautista Rodriguez Ruiz', id: '342' },
  { texto: 'Sergio Rodriguez Uribe', id: '343' },
  { texto: 'Alberto Rojas Lopez', id: '344' },
  { texto: 'Heraclio Rojas Vergara', id: '345' },
  { texto: 'Carlos Roldan Mena', id: '346' },
  { texto: 'Jaime Romero Ordenes', id: '347' },
  { texto: 'Valeria Ronchera Flores', id: '348' },
  { texto: 'Francisco Rosas Villarroel', id: '349' },
  { texto: 'Carlos Ruiz Bahamondes', id: '350' },
  { texto: 'Santiago Ruiz de la Fuente', id: '351' },
  { texto: 'Andres Sabal Tuma', id: '352' },
  { texto: 'Pedro Sada Azar', id: '353' },
  { texto: 'Juan Luis Saiz del Campo', id: '354' },
  { texto: 'Francisca Salas Medina', id: '355' },
  { texto: 'Veronica Salazar Hernandez', id: '356' },
  { texto: 'Carla Salazar Meza', id: '357' },
  { texto: 'Jose Salazar Rivera', id: '358' },
  { texto: 'Juan Carlos San Martin Molina', id: '359' },
  { texto: 'Felipe San Martin Schroder', id: '360' },
  { texto: 'Ricardo San Martin Urrejola', id: '361' },
  { texto: 'Vicente Sanchez Cuesta', id: '362' },
  { texto: 'Jaime Sanchez Errazuriz', id: '363' },
  { texto: 'Maria Teresa Sanchez Puente', id: '364' },
  { texto: 'Evelyn Sanchez Tapia', id: '365' },
  { texto: 'Cristian Sanhueza Pimentel', id: '366' },
  { texto: 'Rene Antonio Santelices Gamboa', id: '367' },
  { texto: 'Gaston Santibanez Torres', id: '368' },
  { texto: 'Maria Soledad Santos Munoz', id: '369' },
  { texto: 'Luciano Sarmiento Jara', id: '370' },
  { texto: 'Olimpia Schneider MoenneLocooz', id: '371' },
  { texto: 'Wladimir Schramm Lopez', id: '372' },
  { texto: 'Jorge Schwenke Zuniga', id: '373' },
  { texto: 'Viviana Nicole Segura Donoso', id: '374' },
  { texto: 'Jose M. Sepulveda Garcia', id: '375' },
  { texto: 'Rodrigo Sepulveda Gomez', id: '376' },
  { texto: 'America Sepulveda Navarrete', id: '377' },
  { texto: 'Hector Sepulveda Quintana', id: '378' },
  { texto: 'Alejandro Sepulveda Valenzuela', id: '379' },
  { texto: 'Anibal Serrano del Solar', id: '380' },
  { texto: 'Zaida Silva Arriagada', id: '381' },
  { texto: 'Dora Silva Letelier', id: '382' },
  { texto: 'Dario Silva Marchant', id: '383' },
  { texto: 'Justino Silva Quiroga', id: '384' },
  { texto: 'Horacio Silva Reyes', id: '385' },
  { texto: 'Roberto Enrique Silva Ruiz', id: '386' },
  { texto: 'Jaime Silva Sciberras', id: '387' },
  { texto: 'Luis Eduardo Solar Bach', id: '388' },
  { texto: 'Eduardo Soto Diaz', id: '389' },
  { texto: 'Alejandro Soto Vera', id: '390' },
  { texto: 'Paulina Sotomayor Graepp', id: '391' },
  { texto: 'Maria Antonieta Suarez Castro', id: '392' },
  { texto: 'Carlos Swett Munoz', id: '393' },
  { texto: 'Jorge Tadres Hales', id: '394' },
  { texto: 'Ivan Tamargo Barros', id: '395' },
  { texto: 'Jorge Tampe Maldonado', id: '396' },
  { texto: 'Luis Tavolari Oliveros', id: '397' },
  { texto: 'Marcela Tavolari Oliveros', id: '398' },
  { texto: 'Joaquin Tejos Henriquez', id: '399' },
  { texto: 'Ivan Toledo Mora', id: '400' },
  { texto: 'Clovis Toro Campos', id: '401' },
  { texto: 'Ivan Torrealba Acevedo', id: '402' },
  { texto: 'Mariano Torrealba Ziliani', id: '403' },
  { texto: 'Elena Torres Seguel', id: '404' },
  { texto: 'Juan Treuer Moya', id: '405' },
  { texto: 'Edison Trincado Cordova', id: '406' },
  { texto: 'Igor Trincado Urra', id: '407' },
  { texto: 'Armando Ulloa Contreras', id: '408' },
  { texto: 'Carlos Ulloa Gonzalez', id: '409' },
  { texto: 'Raul Undurraga Laso', id: '410' },
  { texto: 'Carlos Urbina Reszczynski', id: '411' },
  { texto: 'Juan Urzua Ortiz de Rozas', id: '412' },
  { texto: 'Alvaro Valdebenito Salgado', id: '413' },
  { texto: 'Maricel Valderrama Vasquez', id: '414' },
  { texto: 'Absalon Valencia Gumucio', id: '415' },
  { texto: 'Pedro Jose Valenzuela Vargas', id: '416' },
  { texto: 'Francisco Varas Fernandez', id: '417' },
  { texto: 'Victor Varas Plaza', id: '418' },
  { texto: 'Martin Vasquez Cordero', id: '419' },
  { texto: 'Andres Vega Alvarado', id: '420' },
  { texto: 'Estela Velasquez Miranda', id: '421' },
  { texto: 'Maria Luisa Vera Jeraldo', id: '422' },
  { texto: 'Bernardo Verdejo Neira', id: '423' },
  { texto: 'Jose Vergara Villarroel', id: '424' },
  { texto: 'Luz Maria Vergara Williams', id: '425' },
  { texto: 'Alejandro Viada Ovalle', id: '426' },
  { texto: 'Ignacio Vidal Dominguez', id: '427' },
  { texto: 'Esmirna Vidal Moraga', id: '428' },
  { texto: 'Rodrigo Vila Cervera', id: '429' },
  { texto: 'Carlos Vila Molina', id: '430' },
  { texto: 'Gilberto Villablanca Ormazabal', id: '431' },
  { texto: 'Ruth Villalobos Gutierrez', id: '432' },
  { texto: 'Reinaldo Villalobos Pellegrini', id: '433' },
  { texto: 'Cristian Villalobos Pellegrini', id: '434' },
  { texto: 'Felipe Villarino Krumm', id: '435' },
  { texto: 'Mirson Wainraight Paredes', id: '436' },
  { texto: 'Alison Gail Wiegand Davies', id: '437' },
  { texto: 'Maria Wielandt Covarrubias', id: '438' },
  { texto: 'Harry Winter Aguilera', id: '439' },
  { texto: 'Martita Worner Tapia', id: '440' },
  { texto: 'Maria Isabel Zagal Cisternas', id: '441' },
  { texto: 'Maria Angelica Zagal Cisternas', id: '442' },
  { texto: 'Luis Zambrano Cadena', id: '443' },
  { texto: 'Pablo Zegarra Godoy', id: '444' },
  { texto: 'Conrad Zulch Parra', id: '445' },
  { texto: 'Eduardo de Rodt Espinosa', id: '446' },
  { texto: 'Nancy de la Fuente Hernandez', id: '447' },
  { texto: 'Charles de la Harpe Palma', id: '448' },
  { texto: 'Eduardo del Campo Vial', id: '449' },
  { texto: 'Cristian del Fierro Ruedliger', id: '450' },
  { texto: 'Eugenio del Real Armas', id: '451' },
  { texto: 'Andrea Aurora del Solar Rojas', id: '452' },
  { texto: 'Mabel Llano Díaz', id: '453' },
  { texto: 'Pablo Jose Hales Beseler', id: '454' },
  { texto: 'Ignacio Gonzalez Lopez', id: '455' },
  { texto: 'Tatiana Barrientos Albrecht', id: '456' },
  { texto: 'Claudio Salvador Cabezas', id: '457' },
  { texto: 'Gloria Jacome Sepúlveda', id: '458' },
  { texto: 'LUIS SEPULVEDA PONCE', id: '459' },
  { texto: 'RODRIGO CANTUARIAS COSTA', id: '460' },
  { texto: 'CRISTOBAL DIAZ ACEVEDO', id: '461' },
  { texto: 'CAROLINA ADAOS VALENZUELA', id: '462' },
  { texto: 'JESUS OSSES REVECO', id: '463' },
  { texto: 'PAULO CORTES OLGUIN', id: '464' },
  { texto: 'CESAR SUAREZ SANCHEZ', id: '465' },
  { texto: 'LUIS CONTRERAS PAVEZ', id: '466' },
  { texto: 'JANINA RODRIGUEZ CORTES', id: '467' },
  { texto: 'HECTOR CARRASCO SANHUEZA', id: '468' },
  { texto: 'MARCO URENDA BILICIC', id: '469' }
];

export const NATURALEZA: ISelector[] = [{ id: '1', texto: 'COMPRAVENTA' },
  { id: '2', texto: 'ADJUDICACIÓN' },
  { id: '3', texto: 'TRANSFERENCIA' },
  { id: '4', texto: 'REMATE' }
];

export const TIPODOCUMENTO: ISelector[] = [{ id: '1', texto: 'CONTRATO PRIVADO ELECTRONICO' },
  { id: '2', texto: 'ESCRITURA PUB ELEC' },
  { id: '3', texto: 'FACTURA ELECTRONICA' },
  { id: '4', texto: 'FACTURA ELECTRONICA EXENTA' },
  { id: '5', texto: 'FACTURA REMATE ELECTRONICA' },
  { id: '6', texto: 'FACTURA REMATE EXENTA ELEC' }
];

export const REGIONES: ISelector[]=[
  {id: '1', texto:'REGIÓN DE TARAPACÁ'},

  {id: '2', texto:'REGIÓN DE ANTOFAGASTA'},
  {id: '3', texto:'REGIÓN DE ATACAMA'},
  {id: '4', texto:'REGIÓN DE COQUIMBO'},
  {id: '5', texto:'REGIÓN DE VALPARAISO'},
  {id: '6', texto:"REGIÓN DEL LIBERTADOR GENERAL BERNARDO O'HIGGINS"},
  {id: '7', texto:'REGIÓN DEL MAULE'},
  {id: '8', texto:'REGIÓN DEL BIO BIO'},
  {id: '9', texto:'REGIÓN DE LA ARAUCANIA'},
  {id: '10', texto:'REGIÓN DE LOS LAGOS'},
  {id: '11', texto:'REGIÓN DE AYSEN DEL GENERAL CARLOS IBÁÑEZ DEL CAMPO'},
  {id: '12', texto:'REGIÓN DE MAGALLANES Y DE LA ANTARTICA CHILENA'},
  {id: '13', texto:'REGIÓN METROPOLITANA DE SANTIAGO'},
  {id: '14', texto:'REGIÓN DE LOS RÍOS'},
  {id: '15', texto:'REGIÓN DE ARICA Y PARINACOTA'},
  {id: '16', texto:'REGIÓN DE ÑUBLE'}
]

export const COMUNAS: ISelector[] = [{ id: '317', texto: 'AISÉN' },
  { id: '78', texto: 'ALGARROBO' },
  { id: '126', texto: 'ALHUÉ' },
  { id: '393', texto: 'ALTO BIOBÍO' },
  { id: '28', texto: 'ALTO DEL CARMEN' },
  { id: '394', texto: 'ALTO HOSPICIO' },
  { id: '302', texto: 'ANCUD' },
  { id: '32', texto: 'ANDACOLLO' },
  { id: '243', texto: 'ANGOL' },
  { id: '335', texto: 'ANTÁRTICA' },
  { id: '16', texto: 'ANTOFAGASTA' },
  { id: '217', texto: 'ANTUCO' },
  { id: '237', texto: 'ARAUCO' },
  { id: '366', texto: 'ARGENTINA' },
  { id: '1', texto: 'ARICA' },
  { id: '349', texto: 'BELÉN' },
  { id: '367', texto: 'BRASIL' },
  { id: '120', texto: 'BUIN' },
  { id: '204', texto: 'BULNES' },
  { id: '46', texto: 'CABILDO' },
  { id: '389', texto: 'CABO DE HORNOS' },
  { id: '215', texto: 'CABRERO' },
  { id: '351', texto: 'CAIMANES' },
  { id: '13', texto: 'CALAMA' },
  { id: '295', texto: 'CALBUCO' },
  { id: '23', texto: 'CALDERA' },
  { id: '61', texto: 'CALERA' },
  { id: '122', texto: 'CALERA DE TANGO' },
  { id: '51', texto: 'CALLE LARGA' },
  { id: '2', texto: 'CAMARONES' },
  { id: '7', texto: 'CAMIÑA' },
  { id: '43', texto: 'CANELA' },
  { id: '240', texto: 'CAÑETE' },
  { id: '270', texto: 'CARAHUE' },
  { id: '374', texto: 'CARAMPANGUE' },
  { id: '346', texto: 'CARÉN' },
  { id: '75', texto: 'CARTAGENA' },
  { id: '72', texto: 'CASABLANCA' },
  { id: '301', texto: 'CASTRO' },
  { id: '345', texto: 'CATALINA' },
  { id: '370', texto: 'CATAPILCO' },
  { id: '58', texto: 'CATEMU' },
  { id: '191', texto: 'CAUQUENES' },
  { id: '105', texto: 'CERRILLOS' },
  { id: '110', texto: 'CERRO NAVIA' },
  { id: '357', texto: 'CERRO NEGRO' },
  { id: '311', texto: 'CHAITÉN' },
  { id: '193', texto: 'CHANCO' },
  { id: '20', texto: 'CHAÑARAL' },
  { id: '153', texto: 'CHÉPICA' },
  { id: '372', texto: 'CHIGUAYANTE' },
  { id: '320', texto: 'CHILE CHICO' },
  { id: '194', texto: 'CHILLÁN' },
  { id: '379', texto: 'CHILLÁN VIEJO' },
  { id: '150', texto: 'CHIMBARONGO' },
  { id: '385', texto: 'CHINCOLCO' },
  { id: '391', texto: 'CHOLCHOL' },
  { id: '308', texto: 'CHONCHI' },
  { id: '318', texto: 'CISNES' },
  { id: '210', texto: 'COBQUECURA' },
  { id: '294', texto: 'COCHAMÓ' },
  { id: '322', texto: 'COCHRANE' },
  { id: '135', texto: 'CODEGUA' },
  { id: '208', texto: 'COELEMU' },
  { id: '198', texto: 'COIHUECO' },
  { id: '146', texto: 'COINCO' },
  { id: '185', texto: 'COLBÚN' },
  { id: '8', texto: 'COLCHANE' },
  { id: '113', texto: 'COLINA' },
  { id: '245', texto: 'COLLIPULLI' },
  { id: '145', texto: 'COLTAUCO' },
  { id: '38', texto: 'COMBARBALÁ' },
  { id: '227', texto: 'CONCEPCIÓN' },
  { id: '83', texto: 'CONCHALÍ' },
  { id: '377', texto: 'CONCÓN' },
  { id: '181', texto: 'CONSTITUCIÓN' },
  { id: '241', texto: 'CONTULMO' },
  { id: '22', texto: 'COPIAPÓ' },
  { id: '354', texto: 'COPIULEMU' },
  { id: '31', texto: 'COQUIMBO' },
  { id: '235', texto: 'CORONEL' },
  { id: '278', texto: 'CORRAL' },
  { id: '315', texto: 'COYHAIQUE' },
  { id: '361', texto: 'CUMPEO' },
  { id: '258', texto: 'CUNCO' },
  { id: '247', texto: 'CURACAUTÍN' },
  { id: '125', texto: 'CURACAVÍ' },
  { id: '305', texto: 'CURACO DE VÉLEZ' },
  { id: '238', texto: 'CURANILAHUE' },
  { id: '260', texto: 'CURARREHUE' },
  { id: '182', texto: 'CUREPTO' },
  { id: '165', texto: 'CURICÓ' },
  { id: '371', texto: 'CURIMÓN' },
  { id: '304', texto: 'DALCAHUE' },
  { id: '21', texto: 'DIEGO DE ALMAGRO' },
  { id: '147', texto: 'DOÑIHUE' },
  { id: '101', texto: 'EL BOSQUE' },
  { id: '201', texto: 'EL CARMEN' },
  { id: '360', texto: 'EL MANZANO' },
  { id: '131', texto: 'EL MONTE' },
  { id: '77', texto: 'EL QUISCO' },
  { id: '350', texto: 'EL SALVADOR' },
  { id: '76', texto: 'EL TABO' },
  { id: '356', texto: 'EL TRÁNSITO' },
  { id: '179', texto: 'EMPEDRADO' },
  { id: '287', texto: 'ENTRE LAGOS' },
  { id: '248', texto: 'ERCILLA' },
  { id: '104', texto: 'ESTACIÓN CENTRAL' },
  { id: '231', texto: 'FLORIDA' },
  { id: '263', texto: 'FREIRE' },
  { id: '26', texto: 'FREIRINA' },
  { id: '298', texto: 'FRESIA' },
  { id: '300', texto: 'FRUTILLAR' },
  { id: '313', texto: 'FUTALEUFÚ' },
  { id: '277', texto: 'FUTRONO' },
  { id: '272', texto: 'GALVARINO' },
  { id: '4', texto: 'GENERAL LAGOS' },
  { id: '265', texto: 'GORBEA' },
  { id: '133', texto: 'GRANEROS' },
  { id: '319', texto: 'GUAITECAS' },
  { id: '63', texto: 'HIJUELAS' },
  { id: '312', texto: 'HUALAIHUÉ' },
  { id: '170', texto: 'HUALAÑÉ' },
  { id: '392', texto: 'HUALPÉN' },
  { id: '232', texto: 'HUALQUI' },
  { id: '6', texto: 'HUARA' },
  { id: '27', texto: 'HUASCO' },
  { id: '84', texto: 'HUECHURABA' },
  { id: '40', texto: 'ILLAPEL' },
  { id: '82', texto: 'INDEPENDENCIA' },
  { id: '5', texto: 'IQUIQUE' },
  { id: '388', texto: 'IQUIQUE SO ALTO HOSPICIO' },
  { id: '130', texto: 'ISLA DE MAIPO' },
  { id: '80', texto: 'ISLA DE PASCUA' },
  { id: '343', texto: 'ITATA' },
  { id: '73', texto: 'JUAN FERNÁNDEZ' },
  { id: '100', texto: 'LA CISTERNA' },
  { id: '60', texto: 'LA CRUZ' },
  { id: '162', texto: 'LA ESTRELLA' },
  { id: '94', texto: 'LA FLORIDA' },
  { id: '96', texto: 'LA GRANJA' },
  { id: '30', texto: 'LA HIGUERA' },
  { id: '44', texto: 'LA LIGUA' },
  { id: '97', texto: 'LA PINTANA' },
  { id: '91', texto: 'LA REINA' },
  { id: '29', texto: 'LA SERENA' },
  { id: '281', texto: 'LA UNIÓN' },
  { id: '284', texto: 'LAGO RANCO' },
  { id: '316', texto: 'LAGO VERDE' },
  { id: '329', texto: 'LAGUNA BLANCA' },
  { id: '224', texto: 'LAJA' },
  { id: '114', texto: 'LAMPA' },
  { id: '275', texto: 'LANCO' },
  { id: '148', texto: 'LAS CABRAS' },
  { id: '89', texto: 'LAS CONDES' },
  { id: '352', texto: 'LASTARRIA' },
  { id: '255', texto: 'LAUTARO' },
  { id: '236', texto: 'LEBU' },
  { id: '171', texto: 'LICANTÉN' },
  { id: '64', texto: 'LIMACHE' },
  { id: '183', texto: 'LINARES' },
  { id: '161', texto: 'LITUECHE' },
  { id: '57', texto: 'LLAILLAY' },
  { id: '299', texto: 'LLANQUIHUE' },
  { id: '382', texto: 'LLAY LLAY' },
  { id: '363', texto: 'LLICO DE ARAUCO' },
  { id: '88', texto: 'LO BARNECHEA' },
  { id: '103', texto: 'LO ESPEJO' },
  { id: '108', texto: 'LO PRADO' },
  { id: '155', texto: 'LOLOL' },
  { id: '266', texto: 'LONCOCHE' },
  { id: '186', texto: 'LONGAVÍ' },
  { id: '246', texto: 'LONQUIMAY' },
  { id: '365', texto: 'LONTUE' },
  { id: '239', texto: 'LOS ALAMOS' },
  { id: '49', texto: 'LOS ANDES' },
  { id: '214', texto: 'LOS ANGELES' },
  { id: '276', texto: 'LOS LAGOS' },
  { id: '297', texto: 'LOS MUERMOS' },
  { id: '253', texto: 'LOS SAUCES' },
  { id: '42', texto: 'LOS VILOS' },
  { id: '234', texto: 'LOTA' },
  { id: '251', texto: 'LUMACO' },
  { id: '136', texto: 'MACHALÍ' },
  { id: '92', texto: 'MACUL' },
  { id: '279', texto: 'MÁFIL' },
  { id: '106', texto: 'MAIPÚ' },
  { id: '140', texto: 'MALLOA' },
  { id: '163', texto: 'MARCHIHUE' },
  { id: '12', texto: 'MARÍA ELENA' },
  { id: '124', texto: 'MARÍA PINTO' },
  { id: '274', texto: 'MARIQUINA' },
  { id: '178', texto: 'MAULE' },
  { id: '296', texto: 'MAULLÍN' },
  { id: '17', texto: 'MEJILLONES' },
  { id: '259', texto: 'MELIPEUCO' },
  { id: '123', texto: 'MELIPILLA' },
  { id: '168', texto: 'MOLINA' },
  { id: '37', texto: 'MONTE PATRIA' },
  { id: '134', texto: 'MOSTAZAL' },
  { id: '221', texto: 'MULCHÉN' },
  { id: '223', texto: 'NACIMIENTO' },
  { id: '152', texto: 'NANCAGUA' },
  { id: '325', texto: 'NATALES' },
  { id: '334', texto: 'NAVARINO' },
  { id: '160', texto: 'NAVIDAD' },
  { id: '222', texto: 'NEGRETE' },
  { id: '212', texto: 'NINHUE' },
  { id: '62', texto: 'NOGALES' },
  { id: '271', texto: 'NUEVA IMPERIAL' },
  { id: '196', texto: 'ÑIQUÉN' },
  { id: '90', texto: 'ÑUÑOA' },
  { id: '323', texto: `O'HIGGINS` },
  { id: '137', texto: 'OLIVAR' },
  { id: '14', texto: 'OLLAGÜE' },
  { id: '65', texto: 'OLMUÉ' },
  { id: '285', texto: 'OSORNO' },
  { id: '35', texto: 'OVALLE' },
  { id: '381', texto: 'PADRE HURTADO' },
  { id: '376', texto: 'PADRE LAS CASAS' },
  { id: '34', texto: 'PAIHUANO' },
  { id: '282', texto: 'PAILLACO' },
  { id: '121', texto: 'PAINE' },
  { id: '314', texto: 'PALENA' },
  { id: '157', texto: 'PALMILLA' },
  { id: '280', texto: 'PANGUIPULLI' },
  { id: '56', texto: 'PANQUEHUE' },
  { id: '48', texto: 'PAPUDO' },
  { id: '368', texto: 'PARAGUAY' },
  { id: '164', texto: 'PAREDONES' },
  { id: '187', texto: 'PARRAL' },
  { id: '102', texto: 'PEDRO AGUIRRE CERDA' },
  { id: '337', texto: 'PEDRO DE VALDIVIA' },
  { id: '175', texto: 'PELARCO' },
  { id: '192', texto: 'PELLUHUE' },
  { id: '203', texto: 'PEMUCO' },
  { id: '180', texto: 'PENCAHUE' },
  { id: '229', texto: 'PENCO' },
  { id: '129', texto: 'PEÑAFLOR' },
  { id: '93', texto: 'PEÑALOLÉN' },
  { id: '158', texto: 'PERALILLO' },
  { id: '256', texto: 'PERQUENCO' },
  { id: '45', texto: 'PETORCA' },
  { id: '144', texto: 'PEUMO' },
  { id: '9', texto: 'PICA' },
  { id: '143', texto: 'PICHIDEGUA' },
  { id: '159', texto: 'PICHILEMU' },
  { id: '199', texto: 'PINTO' },
  { id: '118', texto: 'PIRQUE' },
  { id: '390', texto: 'PISAGUA' },
  { id: '264', texto: 'PITRUFQUÉN' },
  { id: '151', texto: 'PLACILLA' },
  { id: '207', texto: 'PORTEZUELO' },
  { id: '331', texto: 'PORVENIR' },
  { id: '383', texto: 'POTRERILLOS' },
  { id: '10', texto: 'POZO ALMONTE' },
  { id: '332', texto: 'PRIMAVERA' },
  { id: '86', texto: 'PROVIDENCIA' },
  { id: '69', texto: 'PUCHUNCAVÍ' },
  { id: '261', texto: 'PUCÓN' },
  { id: '109', texto: 'PUDAHUEL' },
  { id: '116', texto: 'PUENTE ALTO' },
  { id: '292', texto: 'PUERTO MONTT' },
  { id: '288', texto: 'PUERTO OCTAY' },
  { id: '293', texto: 'PUERTO VARAS' },
  { id: '156', texto: 'PUMANQUE' },
  { id: '39', texto: 'PUNITAQUI' },
  { id: '327', texto: 'PUNTA ARENAS' },
  { id: '307', texto: 'PUQUELDÓN' },
  { id: '252', texto: 'PURÉN' },
  { id: '289', texto: 'PURRANQUE' },
  { id: '54', texto: 'PUTAENDO' },
  { id: '3', texto: 'PUTRE' },
  { id: '355', texto: 'PUTÚ' },
  { id: '373', texto: 'PUYEHUE' },
  { id: '348', texto: 'QUEHUE' },
  { id: '339', texto: 'QUEHUI' },
  { id: '309', texto: 'QUEILÉN' },
  { id: '310', texto: 'QUELLÓN' },
  { id: '303', texto: 'QUEMCHI' },
  { id: '220', texto: 'QUILACO' },
  { id: '112', texto: 'QUILICURA' },
  { id: '218', texto: 'QUILLECO' },
  { id: '205', texto: 'QUILLÓN' },
  { id: '59', texto: 'QUILLOTA' },
  { id: '70', texto: 'QUILPUÉ' },
  { id: '306', texto: 'QUINCHAO' },
  { id: '141', texto: 'QUINTA DE TILCOCO' },
  { id: '107', texto: 'QUINTA NORMAL' },
  { id: '68', texto: 'QUINTERO' },
  { id: '211', texto: 'QUIRIHUE' },
  { id: '132', texto: 'RANCAGUA' },
  { id: '206', texto: 'RÁNQUIL' },
  { id: '173', texto: 'RAUCO' },
  { id: '85', texto: 'RECOLETA' },
  { id: '244', texto: 'RENAICO' },
  { id: '111', texto: 'RENCA' },
  { id: '139', texto: 'RENGO' },
  { id: '138', texto: 'REQUÍNOA' },
  { id: '188', texto: 'RETIRO' },
  { id: '353', texto: 'REUMÉN' },
  { id: '52', texto: 'RINCONADA' },
  { id: '283', texto: 'RÍO BUENO' },
  { id: '176', texto: 'RÍO CLARO' },
  { id: '36', texto: 'RÍO HURTADO' },
  { id: '321', texto: 'RÍO IBÁÑEZ' },
  { id: '290', texto: 'RÍO NEGRO' },
  { id: '328', texto: 'RÍO VERDE' },
  { id: '167', texto: 'ROMERAL' },
  { id: '269', texto: 'SAAVEDRA' },
  { id: '169', texto: 'SAGRADA FAMILIA' },
  { id: '41', texto: 'SALAMANCA' },
  { id: '364', texto: 'SAMO ALTO' },
  { id: '74', texto: 'SAN ANTONIO' },
  { id: '119', texto: 'SAN BERNARDO' },
  { id: '195', texto: 'SAN CARLOS' },
  { id: '177', texto: 'SAN CLEMENTE' },
  { id: '50', texto: 'SAN ESTEBAN' },
  { id: '197', texto: 'SAN FABIÁN' },
  { id: '53', texto: 'SAN FELIPE' },
  { id: '149', texto: 'SAN FERNANDO' },
  { id: '330', texto: 'SAN GREGORIO' },
  { id: '200', texto: 'SAN IGNACIO' },
  { id: '347', texto: 'SAN IGNACIO LOS' },
  { id: '190', texto: 'SAN JAVIER' },
  { id: '95', texto: 'SAN JOAQUÍN' },
  { id: '387', texto: 'SAN JOSÉ' },
  { id: '117', texto: 'SAN JOSÉ DE MAIPO' },
  { id: '291', texto: 'SAN JUAN DE LA COSTA' },
  { id: '99', texto: 'SAN MIGUEL' },
  { id: '213', texto: 'SAN NICOLÁS' },
  { id: '286', texto: 'SAN PABLO' },
  { id: '127', texto: 'SAN PEDRO' },
  { id: '15', texto: 'SAN PEDRO DE ATACAMA' },
  { id: '380', texto: 'SAN PEDRO DE LA PAZ' },
  { id: '378', texto: 'SAN RAFAEL' },
  { id: '98', texto: 'SAN RAMÓN' },
  { id: '225', texto: 'SAN ROSENDO' },
  { id: '142', texto: 'SAN VICENTE' },
  { id: '384', texto: 'SAN VTE TAGUA TAGUA' },
  { id: '219', texto: 'SANTA BÁRBARA' },
  { id: '154', texto: 'SANTA CRUZ' },
  { id: '233', texto: 'SANTA JUANA' },
  { id: '55', texto: 'SANTA MARÍA' },
  { id: '81', texto: 'SANTIAGO' },
  { id: '79', texto: 'SANTO DOMINGO' },
  { id: '338', texto: 'SAUZAL' },
  { id: '362', texto: 'SEWELL' },
  { id: '358', texto: 'SHOSHUENCO' },
  { id: '18', texto: 'SIERRA GORDA' },
  { id: '386', texto: 'SN JOSÉ DE MARIQUINA' },
  { id: '342', texto: 'SOTAQUÍ' },
  { id: '128', texto: 'TALAGANTE' },
  { id: '174', texto: 'TALCA' },
  { id: '228', texto: 'TALCAHUANO' },
  { id: '19', texto: 'TALTAL' },
  { id: '254', texto: 'TEMUCO' },
  { id: '166', texto: 'TENO' },
  { id: '268', texto: 'TEODORO SCHMIDT' },
  { id: '24', texto: 'TIERRA AMARILLA' },
  { id: '115', texto: 'TILTIL' },
  { id: '333', texto: 'TIMAUKEL' },
  { id: '242', texto: 'TIRÚA' },
  { id: '341', texto: 'TIRÚA DE NEHUENTUE' },
  { id: '11', texto: 'TOCOPILLA' },
  { id: '267', texto: 'TOLTÉN' },
  { id: '230', texto: 'TOMÉ' },
  { id: '336', texto: 'TOMECO' },
  { id: '359', texto: 'TONGOY' },
  { id: '326', texto: 'TORRES DEL PAINE' },
  { id: '324', texto: 'TORTEL' },
  { id: '250', texto: 'TRAIGUÉN' },
  { id: '209', texto: 'TREGUACO' },
  { id: '340', texto: 'TREHUACO' },
  { id: '216', texto: 'TUCAPEL' },
  { id: '369', texto: 'URUGUAY' },
  { id: '273', texto: 'VALDIVIA' },
  { id: '25', texto: 'VALLENAR' },
  { id: '66', texto: 'VALPARAÍSO' },
  { id: '172', texto: 'VICHUQUÉN' },
  { id: '249', texto: 'VICTORIA' },
  { id: '33', texto: 'VICUÑA' },
  { id: '257', texto: 'VILCÚN' },
  { id: '189', texto: 'VILLA ALEGRE' },
  { id: '71', texto: 'VILLA ALEMANA' },
  { id: '262', texto: 'VILLARRICA' },
  { id: '67', texto: 'VIÑA DEL MAR' },
  { id: '87', texto: 'VITACURA' },
  { id: '184', texto: 'YERBAS BUENAS' },
  { id: '226', texto: 'YUMBEL' },
  { id: '202', texto: 'YUNGAY' },
  { id: '47', texto: 'ZAPALLAR' },
  { id: '375', texto: 'ZUÑIGA' }

];

export const COLORES: IColor[]=[
  {key:'1', id:'CAFE', texto:'ABANO'},
  {key:'2', id:'BEIGE', texto:'ABORIO'},
  {key:'3', id:'GRIS', texto:'ACERADO'},
  {key:'4', id:'GRIS', texto:'ACERO'},
  {key:'5', id:'VERDE', texto:'AGUAMARINA'},
  {key:'6', id:'CELESTE', texto:'ALBICELESTE'},
  {key:'7', id:'VERDE', texto:'ALGA'},
  {key:'8', id:'CAFE', texto:'ALPACA'},
  {key:'9', id:'PLATEADO', texto:'ALUMINIO'},
  {key:'10', id:'BEIGE', texto:'AMARANTO'},
  {key:'11', id:'AMARILLO', texto:'AMBAR'},
  {key:'12', id:'NARANJO', texto:'ANARANJADO'},
  {key:'13', id:'NARANJO', texto:'ARANCIO'},
  {key:'14', id:'BEIGE', texto:'ARENA'},
  {key:'15', id:'PLATEADO', texto:'ARGENTA'},
  {key:'16', id:'BLANCO', texto:'ARMIÑO'},
  {key:'17', id:'AMARILLO', texto:'AROMO'},
  {key:'18', id:'GRIS', texto:'ASTURIA'},
  {key:'19', id:'GRIS', texto:'AXINITE'},
  {key:'20', id:'AZUL', texto:'AZULINO'},
  {key:'21', id:'BEIGE', texto:'BAMBU'},
  {key:'22', id:'BEIGE', texto:'BARQUILLO'},
  {key:'23', id:'VIOLETA', texto:'BELLADONA'},
  {key:'24', id:'ROJO', texto:'BERMELLON'},
  {key:'25', id:'BLANCO', texto:'BLANC'},
  {key:'26', id:'AZUL', texto:'BLUE'},
  {key:'27', id:'DORADO', texto:'BRONCE'},
  {key:'28', id:'CAFE', texto:'BROWN'},
  {key:'29', id:'ROJO', texto:'BURDEO'},
  {key:'30', id:'CAFE', texto:'BURGUNDY'},
  {key:'31', id:'CELESTE', texto:'CALIPSO'},
  {key:'32', id:'CAFE', texto:'CAMEL'},
  {key:'33', id:'CAFE', texto:'CAMELLO'},
  {key:'34', id:'AMARILLO', texto:'CANARIO'},
  {key:'35', id:'CAFE', texto:'CANELA'},
  {key:'36', id:'CAFE', texto:'CARAMELO'},
  {key:'37', id:'BEIGE', texto:'CARDO'},
  {key:'38', id:'ROJO', texto:'CARMIN'},
  {key:'39', id:'ROJO', texto:'CARMINE'},
  {key:'40', id:'BEIGE', texto:'CASCARA'},
  {key:'41', id:'CAFE', texto:'CASTAÑO'},
  {key:'42', id:'GRIS', texto:'CENDRE'},
  {key:'43', id:'GRIS', texto:'CENIZA'},
  {key:'44', id:'ROJO', texto:'CEREZA'},
  {key:'45', id:'AMARILLO', texto:'CHAMPAGNE'},
  {key:'46', id:'NEGRO', texto:'CHARCOAL'},
  {key:'47', id:'ROJO', texto:'CHERRY'},
  {key:'48', id:'CAFE', texto:'CHOCOLATE'},
  {key:'49', id:'AZUL', texto:'CINZA'},
  {key:'50', id:'VERDE', texto:'CIPRES'},
  {key:'51', id:'ROJO', texto:'COBRE'},
  {key:'52', id:'ROJO', texto:'COBRIZO'},
  {key:'53', id:'CAFE', texto:'COGNAC'},
  {key:'54', id:'ROJO', texto:'COLORADO'},
  {key:'55', id:'ROJO', texto:'CONCHOVINO'},
  {key:'56', id:'ROJO', texto:'COPPER'},
  {key:'57', id:'ROJO', texto:'CORAL'},
  {key:'58', id:'BEIGE', texto:'CREMA'},
  {key:'59', id:'AMARILLO', texto:'CURRY'},
  {key:'60', id:'VERDE', texto:'CYPRES'},
  {key:'61', id:'NARANJO', texto:'DAMASCO'},
  {key:'62', id:'GRIS', texto:'DELFIN'},
  {key:'63', id:'AMARILLO', texto:'DURAZNO'},
  {key:'64', id:'VERDE', texto:'ESMERALDA'},
  {key:'65', id:'ROSADO', texto:'FUCSIA'},
  {key:'66', id:'ROJO', texto:'GERANIO'},
  {key:'67', id:'AMARILLO', texto:'GIALLO'},
  {key:'68', id:'AZUL', texto:'GLACIER'},
  {key:'69', id:'DORADO', texto:'GOLD'},
  {key:'70', id:'DORADO', texto:'GOLDEN'},
  {key:'71', id:'GRIS', texto:'GRAFITO'},
  {key:'72', id:'ROJO', texto:'GRANATE'},
  {key:'73', id:'GRIS', texto:'GRANITO'},
  {key:'74', id:'ROJO', texto:'GREDA'},
  {key:'75', id:'AMARILLO', texto:'GUIALLIO'},
  {key:'76', id:'ROJO', texto:'GUINDA'},
  {key:'77', id:'GRIS', texto:'HUMO'},
  {key:'78', id:'AZUL', texto:'JACINTO'},
  {key:'79', id:'VERDE', texto:'JADE'},
  {key:'80', id:'ROJO', texto:'LACRE'},
  {key:'81', id:'ROJO', texto:'LADRILLO'},
  {key:'82', id:'CELESTE', texto:'LIGHTBLUE'},
  {key:'83', id:'VIOLETA', texto:'LILA'},
  {key:'84', id:'AMARILLO', texto:'LIMON'},
  {key:'85', id:'CAFE', texto:'LOUVRE'},
  {key:'86', id:'AMARILLO', texto:'LUCUMA'},
  {key:'87', id:'CAFE', texto:'MADERA'},
  {key:'88', id:'ROJO', texto:'MAGENTA'},
  {key:'89', id:'AMARILLO', texto:'MAIZ'},
  {key:'90', id:'CELESTE', texto:'MALADRIT'},
  {key:'91', id:'VIOLETA', texto:'MALVA'},
  {key:'92', id:'NARANJO', texto:'MANDARINA'},
  {key:'93', id:'BEIGE', texto:'MANTEQUILLA'},
  {key:'94', id:'GRIS', texto:'MARENGO'},
  {key:'95', id:'BLANCO', texto:'MARFIL'},
  {key:'96', id:'CAFE', texto:'MARRON'},
  {key:'97', id:'BEIGE', texto:'MASILLA'},
  {key:'98', id:'PLATEADO', texto:'METAL'},
  {key:'99', id:'PLATEADO', texto:'METALIZADO'},
  {key:'100', id:'CAFE', texto:'MIEL'},
  {key:'101', id:'AMARILLO', texto:'MIMBRE'},
  {key:'102', id:'VIOLETA', texto:'MORA'},
  {key:'103', id:'VIOLETA', texto:'MORADO'},
  {key:'104', id:'AZUL', texto:'MORIZ'},
  {key:'105', id:'CAFE', texto:'MORO'},
  {key:'106', id:'AMARILLO', texto:'MOSTAZA'},
  {key:'107', id:'NEGRO', texto:'NOIR'},
  {key:'108', id:'AMARILLO', texto:'OCRE'},
  {key:'109', id:'VERDE', texto:'OLIVA'},
  {key:'110', id:'VERDE', texto:'OLIVO'},
  {key:'111', id:'DORADO', texto:'OPALESCENTE'},
  {key:'112', id:'NARANJO', texto:'ORANGE'},
  {key:'113', id:'AZUL', texto:'ORION'},
  {key:'114', id:'DORADO', texto:'ORO'},
  {key:'115', id:'ROJO', texto:'OXIDO'},
  {key:'116', id:'VERDE', texto:'PALTA'},
  {key:'117', id:'CAFE', texto:'PARDO'},
  {key:'118', id:'GRIS', texto:'PERLA'},
  {key:'119', id:'AZUL', texto:'PETROLEO'},
  {key:'120', id:'GRIS', texto:'PIEDRA'},
  {key:'121', id:'GRIS', texto:'PIOMBO'},
  {key:'122', id:'PLATEADO', texto:'PLATA'},
  {key:'123', id:'PLATEADO', texto:'PLATINADO'},
  {key:'124', id:'PLATEADO', texto:'PLATINO'},
  {key:'125', id:'GRIS', texto:'PLOMO'},
  {key:'126', id:'AZUL', texto:'PURPURA'},
  {key:'127', id:'ROJO', texto:'RED'},
  {key:'128', id:'ROSADO', texto:'ROSA'},
  {key:'129', id:'ROJO', texto:'RUBU'},
  {key:'130', id:'BEIGE', texto:'SAARA'},
  {key:'131', id:'AZUL', texto:'SAFIRO'},
  {key:'132', id:'NARANJO', texto:'SALMON'},
  {key:'133', id:'CAFE', texto:'SARDE'},
  {key:'134', id:'CAFE', texto:'SEPIA'},
  {key:'135', id:'CAFE', texto:'SIENA'},
  {key:'136', id:'PLATEADO', texto:'SILVER'},
  {key:'137', id:'GRIS', texto:'SINSA'},
  {key:'138', id:'ROJO', texto:'SOROMA'},
  {key:'139', id:'CAFE', texto:'TABACO'},
  {key:'140', id:'BEIGE', texto:'TAUPE'},
  {key:'141', id:'ROJO', texto:'TERRACOTA'},
  {key:'142', id:'GRIS', texto:'TIBESTI'},
  {key:'143', id:'BEIGE', texto:'TIBETANO (BEIGE)'},
  {key:'144', id:'CAFE', texto:'TIBETANO (CAFE)'},
  {key:'145', id:'GRIS', texto:'TITANIUM'},
  {key:'146', id:'CELESTE', texto:'TOPACIO'},
  {key:'147', id:'BEIGE', texto:'TRIGO'},
  {key:'148', id:'AZUL', texto:'TUNGTENO'},
  {key:'149', id:'VERDE', texto:'TURQUESA'},
  {key:'150', id:'CAFE', texto:'VICUÑA'},
  {key:'151', id:'VERDE', texto:'VIRIDIANA'},
  {key:'152', id:'BEIGE', texto:'VISON'},
  {key:'153', id:'BLANCO', texto:'WHITE'},
  {key:'154', id:'AMARILLO', texto:'YELLOW'},
  {key:'155', id:'NARANJO', texto:'ZAPALLO'},
  {key:'156', id:'AZUL', texto:'ZAFIRO'}
]

// External
import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { generateMarketingAccount, getMarketingUsers } from './actions';

// componentes material ui
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import Select from '@material-ui/core/Select';

// componentes custom
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import ModalComponent from '../shared/ModalMsg/ModalMsg';
import Loader from '../shared/Loader/Loader';
import { validaRut } from '../../helpers/RutHelper';
import Card from '@material-ui/core/Card';
import CustomTab from '../sharedv2/Tabs/CustomTab';
import CustomTabsContainer from '../sharedv2/Tabs/CustomTabsContainer';

//Estilos
import scssTool from '../../styles/scssTool';
import IcoLineaTelefonica from '../../images/icons/ico-linea-telefonica.svg';
import IcoformularioWeb from '../../images/icons/ico-formulario-web.svg';
import IcoChat from '../../images/icons/ico-chat.svg';
import IcoAtencionPortal from '../../images/icons/ico-atencion-portal.svg';
import { Theme, WithStyles } from '@material-ui/core';
import { AppState } from '../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme: Theme) => ({
  root: {
    flexGrow: 1,
    // height: 250
  },
  // input: {
  //   display: 'flex',
  //   padding: 0,
  //   width: 500
  // },
  // valueContainer: {
  //   display: 'flex',
  //   flexWrap: 'wrap' as 'wrap',
  //   flex: 1,
  //   alignItems: 'center' as 'center',
  //   overflow: 'hidden' as 'hidden'
  // },
  // chip: {
  //   margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`
  // },
  // chipFocused: {
  //   backgroundColor: emphasize(
  //     theme.palette.type === 'light'
  //       ? theme.palette.grey[300]
  //       : theme.palette.grey[700],
  //     0.08
  //   )
  // },
  filterFormButtonBody: {
    paddingLeft: 5,
    paddingRight: '5px' as any,
    margin: 'auto',
    top: '21px'
  },
  filterFormtextField: {
    fontSize: '1rem',
    width: '100%',
    '& input': {
      marginTop: '.4rem'
    }
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16
  },
  placeholder: {
    position: 'absolute' as 'absolute',
    left: 2,
    fontSize: 16
  },
  paper: {
    position: 'absolute' as 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0
  },
  divider: {
    height: theme.spacing.unit * 2
  },
  newElevationCard: {
    background: '#FFFFFF',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.08)',
    marginTop: '39px',
    padding: '42px 21px 32px',
    position: 'relative' as 'relative'
  },
  atencionContainer: {
    ...scssTool.flex('flex-start', 'column', 'center')
  },
  textTitleatencion: {
    ...scssTool.font(16, 'bold', 24),
    color: '#000000'
  },
  subTitleAtencion: {
    ...scssTool.font(14, 400, 16),
    color: '#141414',
    marginBottom: '32px'
  },
  boxLinea: {
    background: '#FFFFFF',
    border: '1px solid #EBEBEB',
    boxSizing: 'border-box' as 'border-box',
    boxShadow: '0px 2px 9px rgba(0, 0, 0, 0.12)',
    borderRadius: '2px',
    ...scssTool.flex('center', 'column', 'center'),
    padding: '24px',
    height: '145px',
    width: '145px',
    cursor: 'pointer' as 'pointer'
  },
  boxLineaTitle: {
    ...scssTool.font(14, 'bold', 18),
    color: '#000000',
    marginBottom: '6px',
    marginTop: '16px',
    textAlign: 'center' as 'center'
  },
  boxLineaSubtitle: {
    ...scssTool.font(10, 500, 12),
    color: '#141414',
    textAlign: 'center' as 'center'
  }
});

interface DispatchProps {
  getMarketingUsers: () => Promise<Dto.UserProfile[]>;
  generateMarketingAccount: (params: { rutUsuario: string }) => Promise<Dto.UserProfile>;
}

interface StateProps {
  isLoading: boolean;
}

interface IProps extends DispatchProps, StateProps,
  WithStyles<typeof styles> {
}

interface IState {
  user: Comun.Dto.LoginUserInfo;
  openModal: boolean;
  rutUser: string;
  successGenerated: boolean;
  errorGenerated: boolean;
  loading: boolean;
  nameUser: string;
  temporalPassword: string;
  marketingUSers: Dto.UserProfile[];
  showAtencionEjecutivo: string;
  rutErrorMessage: string;
}

const mapStateToProps = (state: AppState): StateProps => ({
  isLoading: state.main.isLoading

});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  getMarketingUsers: () => dispatch(getMarketingUsers()),
  generateMarketingAccount: (params) => dispatch(generateMarketingAccount(params))
});


class Marketing extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    let user: Comun.Dto.LoginUserInfo = JSON.parse(sessionStorage.getItem('user') || '');
    if (user.empresas[0] && user.empresas[0].executiveType !== 1)
      window.location.href = '/';
    this.state = {
      user: user,
      openModal: false,
      rutUser: '',
      successGenerated: false,
      errorGenerated: false,
      loading: false,
      nameUser: '',
      temporalPassword: '',
      marketingUSers: [],
      showAtencionEjecutivo: 'none',
      rutErrorMessage: '',
    };
  }

  componentWillMount = () => {
    return this.props.getMarketingUsers().then((data) => {
      if (data && data[0])
        this.setState((st) => ({
          ...st, marketingUSers: data
        }));
    });
  };

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => this.setState((st) => ({ ...st, [e.target.name]: e.target.value }));

  generateAccount = () => {
    let _ValidRut = validaRut(this.state.rutUser);
    if (this.state.rutUser.trim() === '') {
      this.setState((st) => ({ ...st, rutErrorMessage: 'El RUT es requerido' }));
      return false;
    } else if (!_ValidRut) {
      this.setState((st) => ({ ...st, rutErrorMessage: 'El Rut no es válido' }));
      return false;
    }
    this.setState({
      successGenerated: false,
      errorGenerated: false,
      loading: true,
      rutErrorMessage: ''
    });
    return this.props.generateMarketingAccount({ rutUsuario: this.state.rutUser }).then((user) => {
      if (user) {
        this.setState({
          successGenerated: true,
          loading: false,
          nameUser: user.firstName + ' ' + user.lastName + ' / ' + user.rut + '-' + user.dv,
          temporalPassword: user.temporalPassword
        });
      } else {
        this.setState({ errorGenerated: true, loading: false });
      }
    });
  };

  handleChange = (value: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState((st) => ({ ...st, rutUser: value.target.value }));
  };
  openChatZendesk = (e: any) => {
    const script = document.createElement('script');
    script.src = 'https://v2.zopim.com/?4C2O1sVy5adIROMqNm5iGKqnJL0E9l61';
    document.body.appendChild(script);
    const chat: any = document.getElementsByClassName('zopim');
    if (chat.length) chat[0].style.display = '';
  };

  componentWillUnmount() {
    const chat: any = document.getElementsByClassName('zopim');
    if (chat && chat.length) {
      chat[0].style.display = 'none';
      chat[1].style.display = 'none';
    }
  }

  showAtencionEjecutivo = () => {
    this.setState({ showAtencionEjecutivo: '' });
  };

  render() {
    const { classes } = this.props;
    const { openModal, showAtencionEjecutivo } = this.state;

    return (
      <div>
        {this.state.loading ? <Loader /> : ''}
        <ModalComponent
          openModal={openModal}
          title='¡Datos guardados con éxito!'
          text=''
          closeModalMsg={() => {
            this.setState({ openModal: false });
          }}
        />

        <Breadcrumb
          category='Usted se encuentra en: Administración / Atención al Cliente'
          textAlign='right'
        />
        <Grid container id={'atencion-cliente'}>
          <Grid item xs={12}>
            <Card
              classes={{
                root: classes.newElevationCard
              }}
            >
              <CustomTabsContainer>
                <CustomTab
                  activeTab={1}
                  click={() => false}
                  number={1}
                  title='Atención al cliente'
                ></CustomTab>
              </CustomTabsContainer>
              <div className={classes.atencionContainer}>
                <div className={classes.textTitleatencion}>
                  ¿Tienes alguna duda o consulta?
                </div>
                <div className={classes.subTitleAtencion}>
                  En Factotal estamos comprometidos con entregarte una respuesta
                  concreta, útil y rápida. Por favor, selecciona el medio por el
                  cuál te deseas contactar:
                </div>
              </div>
              <Grid container justify={'center'} spacing={16}>
                <Grid item xs={12} md={3}>
                  <a className={classes.boxLinea} href='tel: 226855900'>
                    <img src={IcoLineaTelefonica} />
                    <div className={classes.boxLineaTitle}>
                      Línea telefónica
                    </div>
                    <div className={classes.boxLineaSubtitle}>
                      +56 2 2685 5900
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} md={3}>
                  <a className={classes.boxLinea} href='/user/atencion-cliente-form'>
                    <img src={IcoformularioWeb} />
                    <div className={classes.boxLineaTitle}>Formulario web</div>
                    <div className={classes.boxLineaSubtitle}>
                      Ingresa tu solicitud
                    </div>
                  </a>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={classes.boxLinea} onClick={this.openChatZendesk}>
                    <img src={IcoChat} />
                    <div className={classes.boxLineaTitle}>Chat en línea</div>
                    <div className={classes.boxLineaSubtitle}>
                      Resuelve tus dudas en línea con nuestros ejecutivos
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={3}>
                  <div className={classes.boxLinea} onClick={this.showAtencionEjecutivo}>
                    <img src={IcoAtencionPortal} />
                    <div className={classes.boxLineaTitle}>Atención portal</div>
                    <div className={classes.boxLineaSubtitle}>
                      Atención inmediata
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        <Grid
          container
          id='atencion-ejecutivo'
          style={{ display: showAtencionEjecutivo }}
        >
          <Grid item xs={12}>
            <Paper
              style={{
                marginTop: 14,
                padding: 14
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant='h2' color='primary'>
                    <b>
                      Otorgar permiso de acceso a usuario interno de Factotal:
                    </b>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 14
                  }}
                >
                  <FormControl className={classes.filterFormButtonBody}>
                    <Typography variant='caption' style={{ marginRight: 14 }}>
                      <b>
                        Seleccione el nombre del asistente que lo está
                        ateniendo:
                      </b>
                    </Typography>
                    <FormHelperText
                      id='component-error-text'
                      error
                    ></FormHelperText>
                  </FormControl>
                  <FormControl className={classes.filterFormButtonBody}>
                    {/*<TextField
                  id="rutUser"
                  name="rutUser"
                  placeholder="12345678-9"
                  value={this.state.rutUser}
                  onChange={this.onChangeRut}
                  InputLabelProps={{ shrink: true }}
                  style={{marginRight: 14}}
                />*/}

                    <Select
                      style={{ width: '100%' }}
                      // components={AtencionFormComponents}
                      value={this.state.rutUser}
                      onChange={this.handleChange}
                      placeholder=''
                    >
                      {this.state.marketingUSers.map((e, i) => (<MenuItem key={i} value={e.rut + '-' + e.dv}>{e.completeName}</MenuItem>))}
                    </Select>
                    <FormHelperText id='component-error-text' error>
                      {this.state.rutErrorMessage}
                    </FormHelperText>
                  </FormControl>
                  <FormControl className={classes.filterFormButtonBody}>
                    <Button
                      variant='outlined'
                      color='primary'
                      disabled={this.state.rutUser === ''}
                      onClick={this.generateAccount}
                      style={{ marginLeft: 15 }}
                    >
                      Generar
                    </Button>
                    <FormHelperText
                      id='component-error-text'
                      error
                    ></FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}></Grid>
          {this.state.successGenerated ? (
            <Grid item xs={12}>
              <Paper
                style={{
                  marginTop: 14,
                  padding: 14
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 14
                    }}
                  >
                    <Typography variant='caption' style={{ marginRight: 14 }}>
                      <b>Rut usuario</b>
                    </Typography>
                    <TextField
                      id='rutUser'
                      value={this.state.rutUser}
                      onChange={this.onChange}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 14
                    }}
                  >
                    <Typography variant='caption' style={{ marginRight: 14 }}>
                      <b>
                        Para acceder al sistema, debe compartir el siguiente
                        token/clave de acceso, con el usuario interno Factotal:
                      </b>
                    </Typography>
                    <TextField
                      id='emailUSer'
                      className={classes.filterFormtextField}
                      value={this.state.temporalPassword}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : (
            ''
          )}
          {this.state.errorGenerated ? (
            <Grid item xs={12}>
              <Paper
                style={{
                  marginTop: 14,
                  padding: 14
                }}
              >
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 14
                    }}
                  >
                    <Typography variant='caption' style={{ marginRight: 14 }}>
                      <b>Error al intentar generar acceso a usuario de rut:</b>
                    </Typography>
                    <TextField
                      id='rutUser'
                      placeholder='12345678-9'
                      value={this.state.rutUser}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(Marketing));

import { fetchAction } from './actions';
import { convertPaginacion, iniPaginacion } from '../../../helpers/PaginationHelper';
import { ActionT } from '../../../config/rootReducer';
import { IFetchCarteraSuccess, IFetchPending } from '../cartera/actions';
import { iniCarteraCobranza, iniTotalCarteraCobranza } from '../cartera/reducer';

export interface ICobranzaState {
  rows: Dto.CarteraRow[][];
  paginacion: State.Paginacion[];
  total: Dto.CarteraTotal[];
  loading: boolean[];
  filterParameters: any;
  isValidForm: true;
  validationMessages: {
    numeroDocumentoDesde: ''
    numeroDocumentoHasta: ''
  };
}

const detalleCobranzaState: ICobranzaState = ({
  filterParameters: {},
  rows: iniCarteraCobranza(),
  paginacion: [iniPaginacion(), iniPaginacion(), iniPaginacion(), iniPaginacion(), iniPaginacion()],
  total: [iniTotalCarteraCobranza(), iniTotalCarteraCobranza(), iniTotalCarteraCobranza(), iniTotalCarteraCobranza(), iniTotalCarteraCobranza()],
  loading: [false, false, false, false, false],
  isValidForm: true,
  validationMessages: {
    numeroDocumentoDesde: '',
    numeroDocumentoHasta: ''
  }
});
export const reducer = (state = detalleCobranzaState, action: ActionT<any>) => {
  switch (action.type) {
    case fetchAction.PENDING: {
      const payload = action.payload as IFetchPending;

      let { rows, paginacion, loading } = state;
      return {
        ...state,
        loading: [...loading]
      };
    }

    case fetchAction.SUCCESS: {
      const payload = action.payload as IFetchCarteraSuccess;

      let { rows, paginacion, total, loading } = state;
      rows[payload.tipoDocumento] = payload.carteraRow;
      paginacion[payload.tipoDocumento] = convertPaginacion(payload.paginacion);
      total[payload.tipoDocumento] = payload.carteraTotal;
      loading[payload.tipoDocumento] = false;

      return {
        ...state,
        rows: [...rows],
        total: [...total],
        paginacion: [...paginacion],
        loading: [...loading]
      };
    }

    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { formatRut } from './RutHelper';

export const handleChangeState = <T, >(state : T, setNewState : (ns : T) => any) => (event : React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>) => {
  // @ts-ignore
  let { name, value, checked, type, dataset, validity } = event.target;
  // console.log(name, value, checked, type, dataset, validity);
  if (validity === undefined || validity.valid) {
    if (type === 'checkbox') {
      // @ts-ignore
      state[name] = checked;
    } else {
      if (dataset !== undefined) {
        if (dataset.tipo === 'rut') {
          value = formatRut(event as React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>);
        }
      }
      // @ts-ignore
      state[name] = value;
    }
    setNewState({ ...state });
  }
};

import Grid from '@material-ui/core/Grid';
import React from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormatDate from '../../../../../helpers/FormatDate';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import Button from '@material-ui/core/Button';
import AgregarCertificadoModal from '../../../../general/fxEmpresa/Certificados/AgregarCertificado';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import HistoricoStyle from '../../../../../styles/jss/components/extendedFormsStyle';
import { connect } from 'react-redux';
import { AppState } from '../../../../../config/rootReducer';
import { WithStyles } from '@material-ui/core/styles/withStyles';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

interface ISeleccionCertificadoCesionPartialProps extends WithStyles<typeof HistoricoStyle> {
  certificados: Core.Dto.Certificado[];
  idCertificadoCesion?: number;
  passwordCertificadoCesion: string;
  handleSelectCertificadoCesion: (event: React.ChangeEvent<{}>, checked: boolean) => void;
  onChangeClaveCertificado: any;
  enModal: boolean;
}

interface IState {
  agregarCertificadoModal: boolean,
  openSnack: boolean
}

class SeleccionCertificadoCesionPartial extends React.Component<ISeleccionCertificadoCesionPartialProps, IState> {
  constructor(props: ISeleccionCertificadoCesionPartialProps) {
    super(props);
    this.state = {
      agregarCertificadoModal: false,
      openSnack: false
    };
  }

  openModalAgregarCertificado = () => {
    this.setState((state) => ({
      agregarCertificadoModal: !state.agregarCertificadoModal
    }));
  };

  closeCertificado = () => {
    this.setState((state) => ({
      agregarCertificadoModal: !state.agregarCertificadoModal,
      openSnack: false
    }));
  };

  saveCertificado = () => {
    this.setState((state) => ({
      agregarCertificadoModal: false,
      openSnack: !state.openSnack,
      snackColor: 'green',
      snackMessage: 'El certificado digital fue agregado exitosamente.'
    }));
  };

  render() {

    const { classes, certificados, enModal }: ISeleccionCertificadoCesionPartialProps = this.props;

    return (
      <React.Fragment>
        <Grid item xs={12} md={enModal ? 12 : 6}>
          <div className='cuadro-dos'>
            <h5>1. SELECCIONE CERTIFICADO DIGITAL</h5>
            {certificados.length ? (
              <React.Fragment>
                <p>Debes seleccionar tu certificado digital para tu cesión online</p>
                <div className='radioButtons'>
                  <RadioGroup
                    aria-label='certificado'
                    name='idCertificadoCesion'
                    defaultValue={this.props.idCertificadoCesion}>
                    {certificados.map(c => {
                      {
                        const label = (
                          <span>{c.nombre}, rut <strong>{c.identificador}</strong> válido desde {FormatDate(c.fechaEmision)} hasta <strong>{FormatDate(c.fechaVencimiento)}</strong></span>
                        );
                        return (
                          <FormControlLabel
                            key={c.id.toString()}
                            value={c.id.toString()}
                            onChange={this.props.handleSelectCertificadoCesion}
                            control={<Radio />}
                            label={label}
                          />
                        );
                      }
                    })}
                  </RadioGroup>
                </div>
              </React.Fragment>
            ) : (
              <div className={classes.noDocumentContainer}>
                <div className='info'>
                  <ReportProblemOutlinedIcon color='primary' />
                  <p>No posees Certificados Digitales para firmar la cesión, para continuar con tu operación deberás
                    configurar uno.</p>
                </div>
                <Button variant='outlined' color='primary' onClick={this.openModalAgregarCertificado}>Configurar
                  uno aquí</Button>
                <AgregarCertificadoModal
                  open={this.state.agregarCertificadoModal}
                  onClose={this.closeCertificado}
                  onSave={this.saveCertificado}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={enModal ? 12 : 6}>
          <div className='cuadro-dos'>
            <h5>2. INGRESE CONTRASEÑA</h5>
            {certificados.length ? (
              <React.Fragment>
                <p>Para autorizar debe ingresar la clave del certificado digital seleccionado.</p>
                <TextField
                  name='passwordCertificadoCesion'
                  value={this.props.passwordCertificadoCesion}
                  onChange={this.props.onChangeClaveCertificado}
                  margin='normal'
                  type='password'
                  variant='outlined'
                  className='inputPass'
                  placeholder='Ingrese contraseña'
                />
              </React.Fragment>) : null}
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, null, AnyAction>) => ({});

const mapStateToProps = (state: AppState) => ({
  certificados: state.factorx.empresa.certificados.filter(d => d.paraCeder)
});

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(SeleccionCertificadoCesionPartial));

// ##############################
// // // Heading component styles
// #############################

const breadcrumStyle = {
  heading: {
    //marginBottom: "30px",
  },
  rightTextAlign: {
    textAlign: "right",
  },
  leftTextAlign: {
    textAlign: "left",
  },
  centerTextAlign: {
    textAlign: "center",
  },
  title: {
    marginTop: "10px",
    color: "#3C4858",
    textDecoration: "none",
  },
  category: {
    fontFamily: ["Larsseit", "Helvetica", "Arial", "sans-serif"].join(","),
    margin: 0,
    fontSize: ".7rem",
    lineHeight: ".7rem",
		float: "right",
		color: "#A9ACAD",
  },
};

export default breadcrumStyle;

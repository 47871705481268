import React, { FC, useState } from 'react';
import { Button, MobileStepper, WithStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Theme, withStyles } from '@material-ui/core/styles';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { routes } from '../../../config/routes';
import BannerSolicitar from './BannerSolicitar';
import ImgLeasing from '../../../images/LeasingBanner.png';
import ImgVendor from '../../../images/VendorBanner.jpg';
import scssTool from '../../../styles/scssTool';

const style = (theme: Theme) => ({
  mobileStepper: {
    background: 'transparent' as 'transparent',
    width: '100%'
  },
  slideContainer: {
    width: '100%',
    height: '154px',
    minHeight: '154px',
    position: 'relative' as 'relative'
  },
  slider: {
    width: '100%',
    height: '154px',
    minHeight: '154px',
    overflow: 'hidden' as 'hidden'
  },
  slid: {
    opacity: 0,
    mouseEvent: 'none' as 'none',
    position: 'absolute' as 'absolute',
    transition: 'all 0.3s ease',
    width: '100%',
    height: '154px',
    minHeight: '154px',
    '&.active': {
      opacity: 1,
      mouseEvent: 'inherit' as 'inherit',
      transition: 'all 0.3s ease'
    }
  },
  buttoncontainer: {
    width: '188px',
    ...scssTool.flex('center', 'row', 'space-between'),
    height: '48px',
    position: 'absolute' as 'absolute',
    right: '0px',
    bottom: '0px'
  }
});

interface IProps extends WithStyles<typeof style>, RouteComponentProps {
}

interface IState {
}

const SlideLeasing: FC<IProps> = (props) => {
  const { classes } = props;
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = 2;

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (active: number) => {
    setActiveStep(active);
  };

  return (
    <div className={classes.slideContainer}>
      <div className={classes.slider}>
        <div
          className={[classes.slid, activeStep === 0 ? 'active' : ''].join(' ')}
        >
          <BannerSolicitar
            img={ImgLeasing}
            titulo='¿Buscas leasing?'
            subtitulo='Encuéntralo también en nuestro sitio Factotal.'
            botonTexto='Solicitar leasing'
            action={() => props.history.push(routes.leasing.producto)}
          />
        </div>
        <div
          className={[classes.slid, activeStep === 1 ? 'active' : ''].join(' ')}
        >
          <BannerSolicitar
            img={ImgVendor}
            titulo='¿Buscas leasing tecnológico?'
            subtitulo='Encuéntralo también en nuestro sitio Factotal.'
            botonTexto='Solicitar leasing tecnológico'
            action={() => props.history.push(routes.vendor.producto)}
          />
        </div>
      </div>
      <div className={classes.buttoncontainer}>
        <MobileStepper
          steps={maxSteps}
          position='static'
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </div>
    </div>
  );
};

export default withStyles(style, { withTheme: true })(withRouter(SlideLeasing));

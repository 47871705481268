import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../../../sharedv2/custom_theme.scss';
import Radio from '@material-ui/core/Radio';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import tableStyle from '../../../../styles/jss/components/tableStyle';
import { WithStyles } from '@material-ui/core';

interface IProps extends WithStyles<typeof tableStyle> {
  data: Comun.Dto.FormaPago[];
  onDelete: (cuenta: Comun.Dto.FormaPago) => void;
  handleChange: (cuenta: Comun.Dto.FormaPago, predeterminada: boolean, habilitada: boolean) => void;
}

interface IState {
}

class TableCuentas extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {

    const { data, classes, onDelete, handleChange } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow className={classes.trow}>
            <TableCell className={classes.cell}>Banco</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Tipo</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Numero</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Predeterminada</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Estado</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Eliminar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id} className={classes.rowBody}>
              <TableCell className={classes.cellBody}>{row.banco.nombre}</TableCell>
              <TableCell align='right'
                         className={[classes.cellBody, classes.centerText].join(' ')}>{row.tipoCuenta}</TableCell>
              <TableCell align='right'
                         className={[classes.cellBody, classes.centerText].join(' ')}>{row.numero}</TableCell>
              <TableCell className={[classes.cellBody, classes.centerText].join(' ')}>
                <Radio
                  checked={row.predeterminada}
                  onChange={(event, checked) => handleChange(row, checked, row.habilitada)}
                  name='banco-predeterminado'
                  aria-label={`${row.banco.nombre}`}
                  // className={classes.radio}
                />
              </TableCell>
              <TableCell className={[classes.cellBody, classes.centerText].join(' ')}>
                <Switch
                  onChange={(event, checked) => handleChange(row, row.predeterminada, checked)}
                  checked={row.habilitada}
                />
              </TableCell>
              <TableCell className={[classes.cellBody, classes.centerText].join(' ')}>
                <IconButton aria-label='Delete' /*className={classes.margin}*/ onClick={() => onDelete(row)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
};

export default withStyles(tableStyle)(TableCuentas);

// External
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { History } from 'history';
import rootReducer from './rootReducer';

export default function configureStore(history: History) {
  const enhancers = [];
  const reduxRouterMiddleware = routerMiddleware(history);
  let middlewares = [reduxRouterMiddleware];
  middlewares.push(thunkMiddleware);

  const devToolsExtension = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
  if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
      collapsed: true
    });
    middlewares.push(logger);
    if (typeof devToolsExtension === 'function') {
      enhancers.push(devToolsExtension());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers);
  const store = createStore(rootReducer(history), composedEnhancers);
  return { store, history };
}

import {
  eliminarAnexoDocumentoSimulacionAction,
  fetchOperacionAction,
  fetchOperacionesAction,
  subirDocumentoAction,
  verificarPrefactibilidadAction
} from './actions';
import { AnyAction } from 'redux';
import { iniPaginacion } from '../../../../helpers/PaginationHelper';


export interface IEnProcesoOperacionesState {
  filterParameters : any,
  rows : Dto.BackOffice.Operacion[],
  simulacion : Dto.BackOffice.Operacion | undefined,
  pagination : State.Paginacion,
  isValidForm : boolean,
  total : any,
  validationMessages : any
}

const enProcesoOperacionesState : IEnProcesoOperacionesState = ({
  filterParameters: {},
  rows: [],
  simulacion: undefined,
  pagination: iniPaginacion(),
  total: {},
  isValidForm: true,
  validationMessages: {
    numeroDocumentoDesde: '',
    numeroDocumentoHasta: ''
  }
});

export const reducer = (state = enProcesoOperacionesState, action : AnyAction) => {
  switch (action.type) {
    case fetchOperacionesAction.PENDING: {
      return Object.assign({}, state, action);
    }

    case fetchOperacionesAction.SUCCESS: {
      return Object.assign({}, state, action);
    }

    case fetchOperacionAction.PENDING: {
      return Object.assign({}, state, action);
    }

    case fetchOperacionAction.SUCCESS: {
      return Object.assign({}, state, action);
    }

    case verificarPrefactibilidadAction.PENDING: {
      const simulacion = state.simulacion;
      if (simulacion) {
        const id : string = action.payload;
        let documentos = simulacion.documentos;
        const docIndex = documentos.findIndex(d => d.id === id);
        let documento = documentos[docIndex];
        documento.idEstado = 1;
        //Esto es para que los controles que dependen de documentos (TableContainer) se rendereen de nuevo.
        //Si se hace sin esta línea, se renderea hasta la simulación pero no la tabla de documentos
        documentos[docIndex] = { ...documento };
        simulacion.documentos = [...documentos];
      }
      return Object.assign({}, state, { simulacion: { ...simulacion } });
    }

    case verificarPrefactibilidadAction.SUCCESS: {
      const retorno : Payloads.ResultadoVerificarPrefactibilidadPayload = action.payload;
      const simulacion = state.simulacion;
      if (simulacion) {
        let documentos = simulacion.documentos;
        const docIndex = documentos.findIndex(d => d.id === retorno.id);
        let documento = documentos[docIndex];
        documento.idEstado = (retorno.resultado ? 2 : (retorno.existeXml ? 6 : 3));
        documento.aproboFactibilidad = retorno.resultado;
        documento.mensajeVerificacion = retorno.mensaje;
        documento.existeXml = retorno.existeXml;
        //Esto es para que los controles que dependen de documentos (TableContainer) se rendereen de nuevo.
        //Si se hace sin esta línea, se renderea hasta la simulación pero no la tabla de documentos
        documentos[docIndex] = { ...documento };
        simulacion.documentos = [...documentos];
      }
      return Object.assign({}, state, { simulacion: { ...simulacion } });
    }

    case subirDocumentoAction.PENDING: {
      return Object.assign({}, state, {});
    }

    case subirDocumentoAction.SUCCESS: {
      const payload : Payloads.ArchivoSubidoPayload = action.payload;
      const simulacion = state.simulacion;
      if (simulacion) {
        const documentIndex = simulacion.documentos.findIndex(d => d.id === payload.invoiceId);
        const documento = simulacion.documentos[documentIndex];
        documento.archivos.push({
          nombre: payload.file.name,
          fecha: '',
          progreso: 0,
          id: payload.attachmentId,
          error: '',
          puedeEliminar: true
        });
      }
      return Object.assign({}, state, { simulacion: simulacion });
    }

    case subirDocumentoAction.FAILURE: {
      return Object.assign({}, state, {});
    }

    case eliminarAnexoDocumentoSimulacionAction.SUCCESS: {
      const payload = action.payload;
      const simulacion = state.simulacion;
      if (simulacion) {
        let documentos = simulacion.documentos;
        const documentIndex = documentos.findIndex(d => d.id === payload.invoiceId);
        const documento = Object.assign({}, documentos[documentIndex], {});
        let archivos : any[] = Object.assign([], documento.archivos, []);
        const attachmentIndex = archivos.findIndex(d => d.id === payload.attachmentId);
        archivos.splice(attachmentIndex, 1);
        //esto es para que los controles que dependen de documentos (TableContainer) se rendereen de nuevo.
        //si se hace sin esta linea, se renderea hasta la simulación pero no la tabla de documentos
        documento.archivos = Object.assign([], archivos);
        documentos[documentIndex] = Object.assign({}, documento, {});
        simulacion.documentos = Object.assign([], simulacion.documentos, documentos);
      }
      return Object.assign({}, state, { simulacion: simulacion });
    }

    default:
      return state;
  }
};

export default reducer;

import { default as React } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Tab, Tabs } from '@material-ui/core/';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import TermsAndConditionStyle from './style';
import DialogTitle from '../DialogTitle/DialogTitle';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

interface ITermsAndConditionsProps extends WithStyles<typeof TermsAndConditionStyle> {
  open : boolean,
  onClose : () => void,
  onAccept : () => void
}

interface IState {
  active : number
}

class TermsAndConditions extends React.Component<ITermsAndConditionsProps, IState> {
  constructor(props : ITermsAndConditionsProps) {
    super(props);
    this.state = {
      active: 0
    };
  }

  handleChange = (event : React.ChangeEvent<any>, value : number) => {
    this.setState({ active: value });
  };

  render() {
    const { classes, open, onClose, onAccept } = this.props;
    const { active } = this.state;
    const paperClass = [classes.dialogPaper, classes.rootXml, classes.dialogPapersOr].join(' ');

    return (
      <Dialog
        onClose={onClose}
        PaperProps={{ className: paperClass }}
        className={classes.root}
        open={open}
      >
        <DialogTitle onClose={onClose} classes={{ root: classes.dialogTitleTerms }}>
          Condiciones del servicio y privacidad
        </DialogTitle>
        <div>
          <Tabs
            value={active}
            onChange={this.handleChange}
            classes={{ root: classes.dialogTermsTabRoot, indicator: classes.dialogTermIndicator }}
          >
            <Tab
              label='TÉRMINOS Y CONDICIONES'
              classes={{ root: classes.termDialogTab, selected: classes.dialogTermsTabsSelected }}
            />
            {/*<Tab*/}
            {/*  label='POLÍTICA DE PRIVACIDAD'*/}
            {/*  classes={{*/}
            {/*    root: classes.termDialogTab,*/}
            {/*    selected: classes.dialogTermsTabsSelected,*/}
            {/*    labelContainer: classes.dialogTermLabelContainer,*/}
            {/*  }}*/}
            {/*/>*/}
          </Tabs>
        </div>
        {active === 0 && (
          <DialogContent className={classes.dialogScrollContent}>
            <h2 className={classes.termsConditionTitleBig}>
              Términos de Servicio y Política de Privacidad de DATAMART
            </h2>
            <h2>1. Mandato de tratamiento de datos</h2>
            <p>
              <span>FACTOTAL S.A.</span> o en adelante “Destinatario” de los datos utiliza a
              Datamart como solución para la obtención de parte de la información requerida en sus
              procesos de negocio, evaluación comercial, financiera, riesgo y/o legal. En el momento
              en que tú o la sociedad que representas aceptan estos Términos de Servicio y Política
              de Privacidad, autorizas que Datamart realice actividades de tratamiento de datos
              relativas a extracción, procesamiento, almacenamiento y comunicación de la información
              requerida por el Destinatario, quién la utilizará para los siguientes usos o fines en
              el contexto de su <span>Sucursal Virtual Factoring</span>, en adelante la prestación.
            </p>
            <h2>2. Datamart</h2>
            <p>
              Datamart SpA, con domicilio en Alonso de Monroy 2869, oficina 201, Vitacura, Santiago
              de Chile, es una empresa dedicada a la obtención, extracción y procesamiento de datos
              desde distintas fuentes de información, a través de medios informáticos (“Datamart” o
              “nosotros”). Datamart permite solicitar, procesar y almacenar de forma segura
              documentos y antecedentes que pueden contener tu información personal, la cual podrás
              autorizar a compartir con empresas con quienes quieras relacionarte y hacer negocios,
              o con las que ya tengas una relación comercial. El tratamiento de datos personales se
              realizará en plena observancia de las normativa aplicable y normas complementarias.
            </p>
            <h2>3. Debes tener poderes de representación</h2>
            <p>
              Al aceptar estos términos y condiciones de uso, declaras actuar a tu nombre o, en su
              caso, a nombre y representación de una sociedad u organización debidamente
              constituida, y que tienes facultades legales y contractuales suficientes para
              obligarte u obligar a esa entidad a cumplir con este acuerdo.
            </p>
            <h2>4. Los datos son tuyos o de tu sociedad</h2>
            <p>
              Para nosotros tu confianza y la seguridad de tus datos son una prioridad. Nos
              comprometemos a ser transparentes sobre los datos que obtenemos bajo la ley o con tu
              consentimiento, y la forma en que los utilizamos.
            </p>
            <p>
              Por favor, revisa atentamente esta política de privacidad para que comprendas qué
              datos obtendremos, con qué fin serán utilizados y con quienes podrían ser compartidos.
              Los datos solicitados han sido considerados como necesarias por el Destinatario. Si no
              aceptas estas condiciones de tratamiento, las finalidades indicadas en este documento
              no podrán efectuarse.
            </p>
            <h2>5. La información que obtenemos</h2>
            <p>
              Dependiendo de la finalidad para la cual sean requeridos tus datos, podríamos obtener
              el siguiente tipo de información:
            </p>
            <ul>
              <li>Tu nombre, documento de identidad (RUN o DNI) y domicilio.</li>
              <li>
                Identificación de la sociedad u organización a la que representas, en caso aplique,
                pudiendo incluir su razón social, domicilio y documento de identidad (RUT o RUC).
              </li>
              <li>
                Tus datos personales y/o datos de la sociedad u organización que representas,
                incluyendo direcciones de correo electrónico y números de teléfono.
              </li>
              <li>
                Los datos personales de identificación, contacto y socioeconómicos, que podamos
                recoger de fuentes de información públicas.
              </li>
              <li>
                Datos de solvencia económica y financiera, morosidad, riesgo, actividad y
                comportamiento comercial o financiero, tuyos o los de la sociedad u organización que
                representas.
              </li>
              <li>
                Debes tener en cuenta que los datos personales que podremos recopilar podrían ser
                sensibles de conformidad con la legislación aplicable, tales como ingresos
                económicos.
              </li>
              <li>
                Si representas a una sociedad u organización, también recopilaremos los datos
                personales de sus representantes y personas vinculadas. Conforme a la legislación
                aplicable, deberás asegurarte de informarles a los titulares de dichos datos
                oportunamente de esta recopilación.
              </li>
            </ul>
            <h2>6. Cómo obtenemos la información</h2>
            <ul>
              <li>Obtenemos datos desde fuentes de información públicas o privadas.</li>
              <li>
                También podremos obtener datos de fuentes de libre acceso, como bases de datos y
                registros públicos, diarios y boletines oficiales, listas para la prevención del
                fraude, redes sociales e internet.
              </li>
            </ul>
            <h2>7. Información recopilamos y para qué la utilizamos</h2>
            <p>
              Dependiendo de la finalidad para la cual sean requeridos tus datos, podríamos obtener
              el siguiente tipo de información para facilitar la automatización de procesos de
              contratación, de evaluación financiera, oportunidades de compra, venta e
              intermediación de productos y servicios, de prevención de delitos y fraudes, y para
              otros fines que nos hayas autorizado expresamente o que sean permitidos por la ley.{' '}
            </p>
            <p>
              En caso de que dicha información corresponda a información Personal, ésta será
              almacenada por DATMART en su banco de datos de “Personas”, base de datos que en
              función de la regulación podrá está registrada según lo establezca la normativa
              aplicable por el tiempo que el contrato se mantenga vigente, más 10 años, siempre y
              cuando tu no decidas revocar tu autorización y/o nos solicites eliminarlos.
            </p>
            <p>
              Sin perjuicio de lo anterior, en detallada a continuación con las siguientes
              finalidades u otras que, de tiempo en tiempo, nos autorices expresamente:
            </p>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Identidad</div>
                <div className={classes.cardReqSubtitleLitle}>Origen: SII</div>
                <div className={classes.cardReqSubtitle}>Características del contribuyente</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>
                      Identificar y verificar tu identidad y/o la identidad de la sociedad u
                      organización que representas.
                    </li>
                    <li>
                      Verificar que se cumplan las condiciones establecidas en créditos y productos
                      financieros.
                    </li>
                    <li>
                      Otras finalidades permitidas por ley, reglamentos e instrucciones de
                      autoridades competentes.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Identidad</div>
                <div className={classes.cardReqSubtitleLitle}>Origen: SII</div>
                <div className={classes.cardReqSubtitle}>
                  Certificado Digital del Representante Legal y/o Autorizado
                </div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>
                      Identificar y verificar tu identidad y/o la identidad de la sociedad u
                      organización que representas.
                    </li>
                    <li>Identificarte ante el SII</li>
                    <li>
                      Con tu consentimiento Firmar Archivos Electrónicos de Cesión de cara a
                      facilitar las operaciones de factoring.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Información tributaria</div>
                <div className={classes.cardReqSubtitleLitle}>Origen: SII</div>
                <div className={classes.cardReqSubtitle}>Carpeta Tributaria Electrónica</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>Facilitar y automatizar procesos de evaluación financiera.</li>
                    <li>Estimación de flujo de caja.</li>
                    <li>Aproximación de estados financieros.</li>
                    <li>
                      Verificar que se cumplan las condiciones establecidas en créditos y productos
                      financieros.
                    </li>
                    <li>
                      Otras finalidades permitidas por ley, reglamentos e instrucciones de
                      autoridades competentes
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Información tributaria</div>
                <div className={classes.cardReqSubtitleLitle}>Origen: SII</div>
                <div className={classes.cardReqSubtitle}>Documentos Tributarios Electrónicos</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>Facilitar y automatizar procesos de evaluación financiera.</li>
                    <li>Evaluar y proponer ofertas proactivas de financiamiento</li>
                    <li>Estimación de flujo de caja.</li>
                    <li>
                      Verificar que se cumplan las condiciones establecidas en créditos y productos
                      financieros.
                    </li>
                    <li>
                      Otras finalidades permitidas por ley, reglamentos e instrucciones de
                      autoridades competentes
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>
                  Endeudamiento, Morosidad y Comportamiento de pago
                </div>
                <div className={classes.cardReqSubtitleLitle}>Origen: SII</div>
                <div className={classes.cardReqSubtitle}>Carpeta Tributaria</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>Estimar tu comportamiento de pago.</li>
                    <li>
                      Verificar la existencia de morosidades con instituciones de los impuestos
                      mensuales.
                    </li>
                    <li>
                      Verificar que se cumplan las condiciones establecidas en créditos y productos
                      financieros.
                    </li>
                    <li>
                      Otras finalidades permitidas por ley, reglamentos e instrucciones de
                      autoridades competentes
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>
                  Endeudamiento, Morosidad y Comportamiento de pago
                </div>
                <div className={classes.cardReqSubtitleLitle}>Origen: www.tgr.cl</div>
                <div className={classes.cardReqSubtitle}>
                  Certificado Deuda Fiscal, Cartola Fiscal y Convenios{' '}
                </div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>Estimar tu comportamiento de pago.</li>
                    <li>Verificar la existencia de morosidades con instituciones del estado.</li>
                    <li>
                      Verificar que se cumplan las condiciones establecidas en créditos y productos
                      financieros.
                    </li>
                    <li>
                      Otras finalidades permitidas por ley, reglamentos e instrucciones de
                      autoridades competentes.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqIcon}>
                <PriorityHighIcon />
              </div>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Registro de Transferencias de Crédito</div>
                <div className={classes.cardReqSubtitleLitle}>Origen: SII</div>
                <div className={classes.cardReqSubtitle}>RPETC</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li>Analizar registros de facturas negociables de créditos a terceros.</li>
                    <li>
                      Generar estadísticas de facturas negociables que permitan realizar análisis
                      comparativos y/o estimar condiciones de acceso a financiamiento.
                    </li>
                    <li>
                      Otras finalidades permitidas por ley, reglamentos e instrucciones de
                      autoridades competentes
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <p>Debes tener en cuenta que:</p>
            <ul>
              <li>
                Ciertos datos personales que nos autorices a procesar podrían ser sensibles, de
                conformidad con la legislación aplicable, tales como ingresos económicos y datos
                biométricos.{' '}
              </li>
              <li>
                Si representas a una sociedad u organización, también recopilaremos los datos
                personales de sus representantes y personas vinculadas. Conforme a la legislación
                peruana, deberás asegurarte de informarles oportunamente de esta recopilación.
              </li>
            </ul>

            <h2>8. Comunicación de tus datos a terceras empresas</h2>
            <p>
              Al aceptar nos autorizas a comunicar tu información con aquellos terceros que nos
              indiques (por ejemplo, con una institución financiera), y con aquellos que tengan un
              interés legítimo sobre esos datos o puedan tratarlos de acuerdo con la ley,
              reglamentos e instrucciones de autoridades competentes.
            </p>
            <p>
              Desde el momento que te identifiques y/o a tu sociedad, y en el contexto de esta
              prestación, la información provista será comunicada al Destinatario. Para que pueda
              utilizarla de acuerdo con los fines establecidos.
            </p>

            <div className={classes.cardReq}>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Destinatarios</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li><span className={classes.pbold}>Razón social: </span>FACTOTAL S.A.</li>
                    <li><span className={classes.pbold}>RUC: </span>96660790-4.</li>
                    <li><span className={classes.pbold}>Domicilio: </span>AV. ANDRÉS BELLO 2233 PISO 7, PROVIDENCIA, SANTIAGO.</li>
                    <li><span className={classes.pbold}>PAIS: </span>CHILE.</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className={classes.cardReq}>
              <div className={classes.cardReqContent}>
                <div className={classes.cardReqTitle}>Información de contacto:</div>
                <div className={classes.cardReqFunc}>
                  <ul>
                    <li><span className={classes.pbold}>Email: </span>contacto@ft.cl.</li>
                    <li><span className={classes.pbold}>Teléfono: </span>+562 - 26855900.</li>
                  </ul>
                </div>
              </div>
            </div>

            <h2>9. Encargados de tratamiento</h2>
            <p>
              En la realización de las finalidades descritas, podremos compartir tu información bajo
              encargo, en el territorio peruano o fuera de él, y siempre sujeto a medidas de
              seguridad, con:
            </p>
            <p>
              A) AMAZON WEB SERVICES, INC., ubicado en 410 Terry Avenue North, Seattle, WA, Estados
              Unidos, que resguarda tu información de manera encriptada.
            </p>
            <p>
              B) MICROSOFT CORPORATION, INC , ubicado en One Microsoft Way, Redmond, WA 98052-6399
              USA, y que resguarda tu información de manera encriptada.
            </p>
            <p>C) Con otras empresas o personas que tú nos autorices explícitamente.</p>
            <p className={classes.pbold}>
              Otras empresas o personas autorizadas para realizar tratamiento:
            </p>
            <div className={classes.boxLitle}>
              <p>
                Esta términos de uso y política de privacidad no define otras empresas o personas
                autorizadas para realizar tratamiento
              </p>
            </div>
            <p>
              En caso hubiera nuevos terceros encargados de tratamiento, actualizaremos esta
              relación y te informaremos oportunamente mediante correo electrónico.
            </p>
            <h2>10. Cómo protegemos tu información</h2>
            <ul>
              <li>
                Tu información está sujeta a medidas de seguridad de carácter legal, organizacional
                y técnico exigidas por la normativa local.
              </li>
              <li>
                Nuestros procesos siguen la norma ISO 27001 sobre confidencialidad, integridad y
                disponibilidad de la información.
              </li>
              <li>
                Tu información siempre viaja y es almacenada de manera encriptada, con los más altos
                estándares de seguridad disponibles.
              </li>
              <li>
                Tu información es almacenada de forma aislada en bóvedas digitales provistas por
                Amazon Web Services, Inc., que no son compartidas con ninguna otra empresa o entidad
                que no esté legitimada para acceder a esos datos.
              </li>
              <li>
                No comunicaremos información que pueda identificarte, o a la sociedad u organización
                que representas, sin tu autorización explícita.
              </li>
              <li>
                Todas nuestras plataformas y servicios operan en una infraestructura cloud de clase
                mundial, que cumple con la normativa peruana sobre seguridad de la información.
              </li>
              <li>
                La infraestructura física sobre la que operamos es escalable, tiene un diseño de
                alta disponibilidad, uptime promedio mensual sobre el 99,9% y funcionalidad de
                tolerancia de errores integrada. Además, la infraestructura no tiene períodos de
                mantenimiento o inactividad programados.
              </li>
              <li>
                Auditamos y revisamos nuestros procedimientos de seguridad de forma permanente.
              </li>
              <li>
                Nos reservamos el derecho de exigir sistemas de autenticación más robustos para que
                puedas acceder a tu información, como doble factor, tokens, biometría u otros, con
                el objetivo de reducir al máximo el riesgo de suplantación y de acceso ilegal.
              </li>
            </ul>
            <h2>11. Cuáles son tus derechos</h2>
            <ul>
              <li>
                Siempre podrás conocer qué información tuya o de la sociedad u organización que
                representas hemos recopilado y procesado, para qué la hemos procesado, con quien la
                hemos compartido (con tu autorización) y cuándo o hicimos.
              </li>
              <li>
                Siempre tendrás derecho a una copia del documento electrónico que acredita tu
                autorización explícita para acceder y procesar tu información.
              </li>
              <li>
                Siempre podrás solicitar que dejemos de compartir tu información con determinado
                tercero indicado por ti.
              </li>
              <li>
                Siempre podrás solicitar revocar tu autorización ara tratamiento de datos, que
                eliminemos tu información y/o que la disociemos, de manera tal que no se pueda
                vincular a ti, o a la sociedad u organización que representas.
              </li>
              <li>
                Puedes ejercer tus derechos dirigiendo una solicitud a:
                <ul>
                  <li>Chile: tusderechos@datamart.cl</li>
                  <li>Perú: tusderechos@datamart.pe</li>
                </ul>
              </li>
            </ul>
            <h2>12. Responsabilidad sobre la calidad de la información</h2>
            <p>No nos hacemos responsables sobre la veracidad, exactitud o aptitud para los fines que se requieran, de la información que nos proporcionas. En
              todo momento podrás revisarla y solicitar que la actualicemos o rectifiquemos.</p>
            <h2>13. Portabilidad</h2>
            <p>Te proporcionaremos una copia de la información tuya o de la sociedad u organización que representas que hayamos procesado, en un formato
              electrónico portable.</p>
            <h2>14. Servicio gratuito</h2>
            <p>Datamart es un servicio gratuito.</p>
            <h2>15. Preguntas</h2>
            <p>Si tienes alguna duda, te invitamos a contactarnos directamente a:</p>
            <ul>
              <li>Chile: preguntas@datamart.cl</li>
              <li>Perú: preguntas@datamart.pe</li>
            </ul>
            <h2>16. Versión</h2>
            <p>La versión que estás aceptando corresponde a Versión 1.1 del 21/07/2020</p>
            <p className={classes.pcenter}>Fin del Documento</p>
          </DialogContent>
        )}
        {active === 1 && <DialogContent className={classes.dialogScrollContent} />}
        <DialogActions className={classes.dialogActions}>
          <Button variant='contained' onClick={onAccept} color='primary'>
            Aceptar
          </Button>
          <Button
            onClick={onClose}
            variant='outlined'
            color='primary'
            className={classes.cancelButtom}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(TermsAndConditionStyle)(TermsAndConditions);

import React, { FC, useCallback, useEffect, useState } from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import Header from '../../../components/sharedv2/Header/Header';
import HistoricoStyle from '../../../styles/jss/components/extendedFormsStyle';
import {
  Button,
  Card,
  Grid,
  IconButton,
  Radio,
  Tab,
  Tabs
} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import TableContainer from '../../../components/sharedv2/Table/TableContainer';
import BienesContrato from './bienes';
import NuevoContrato from './nuevo';
import NuevoBien, { TipoInscripcion, TipoVehiculo } from './nuevoBien';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { RouteComponentProps } from 'react-router-dom';
import { AppState } from '../../../config/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  limpiarInscripcion,
  limpiarTransferencia,
  nuevoContratoEmpresa,
  obtenerEmpresaContratos,
  seleccionarContrato
} from '../actions';
import { connect } from 'react-redux';
import routes from '../../../config/routes';
import { useHistory } from 'react-router';
import { formatNumber } from '../../../helpers/FormatNumber';

interface RouteParams {
  id: string;
}

interface StateProps {
  empresa: Dto.RegistroCivil.Empresa | undefined;
  contrato: Dto.RegistroCivil.Contrato | undefined;
  paginacion: State.Paginacion;
}

interface DispatchProps {
  obtenerEmpresa: (idEmpresa: string, payload: Request.RegistroCivil.EmpresaContratos) => Promise<void>;
  nuevoContrato: (idEmpresa: string, payload: Request.RegistroCivil.NuevoContrato) => Promise<void>;
  seleccionarContrato: (idContrato: number) => void;
  limpiarInscripcion: () => void;
  limpiarTransferencia: () => void;
}

const mapStateToProps = (state: AppState): StateProps => ({
  empresa: state.registroCivil.empresa,
  contrato: state.registroCivil.contrato,
  paginacion: state.registroCivil.paginacionContratos
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => ({
  obtenerEmpresa: (idEmpresa: string, payload: Request.RegistroCivil.EmpresaContratos) => dispatch(obtenerEmpresaContratos(idEmpresa, payload)),
  seleccionarContrato: (idContrato: number) => dispatch(seleccionarContrato(idContrato)),
  nuevoContrato: (idEmpresa: string, payload: Request.RegistroCivil.NuevoContrato) => dispatch(nuevoContratoEmpresa(idEmpresa, payload)),
  limpiarInscripcion: () => dispatch(limpiarInscripcion()),
  limpiarTransferencia: () => dispatch(limpiarTransferencia())
});

interface IProps extends WithStyles<typeof HistoricoStyle>, StateProps, DispatchProps, RouteComponentProps<RouteParams> {

}

export type TModeloCompradorVendedor = Dto.RegistroCivil.Persona;

export const iniModeloCompradorVendedor = (): TModeloCompradorVendedor => ({
  apellidoMaterno: '',
  apellidoPaterno: '',
  calle: '',
  calidad: '1' as any,
  codigoPostal: '',
  correoElectronico: '',
  idComuna: '0' as any,
  letraDomicilio: '',
  nombresRazon: '',
  numeroDomicilio: '',
  runRut: '',
  restoDomicilio: '',
  telefono: ''
});

const ContratoDetalle: FC<IProps> = (props) => {
  const { classes, empresa, paginacion, contrato: contratoSeleccionado, seleccionarContrato } = props;
  const [activeTab, setActiveTab] = useState(0);
  const [creandoContrato, setCreandoContrato] = useState(false);
  const [nuevoContrato, setNuevoContrato] = useState(false);
  const [nuevoBien, setNuevoBien] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const history = useHistory();

  useEffect(() => {
    props.obtenerEmpresa(props.match.params.id, { tamanoPagina: 500, numeroPagina: 1 });
    return () => {
    };
  }, [props.match.params.id]);

  const onChangeContratoBienes = (contrato: Dto.RegistroCivil.Contrato) => {
    seleccionarContrato(contrato.idContrato);
  };

  const tableInfo = [
    {
      column: '',
      relation: '',
      renderData: (contrato: Dto.RegistroCivil.Contrato) => (
        <>
          <Radio
            checked={contratoSeleccionado?.idContrato === contrato.idContrato}
            onChange={() => onChangeContratoBienes(contrato)}
            name='contratoSelected'
          />
        </>
      )
    },
    {
      column: 'Número',
      relation: 'numero',
      align: 'center',
      renderData: (contrato: Dto.RegistroCivil.Contrato) => contrato.numero
    },
    {
      column: 'Firmado',
      relation: 'firmado',
      align: 'center',
      renderData: (contrato: Dto.RegistroCivil.Contrato) => contrato.fechaFirma
    },
    {
      column: 'Bienes',
      relation: 'bienes',
      align: 'center',
      renderData: (contrato: Dto.RegistroCivil.Contrato) => contrato.cantidadBienes
    },
    {
      column: 'Monto',
      relation: 'monto',
      align: 'center',
      renderData: (contrato: Dto.RegistroCivil.Contrato) => formatNumber(contrato.monto, 0)
    },
    {
      column: 'Estado',
      relation: 'estado',
      align: 'center',
      renderData: (contrato: Dto.RegistroCivil.Contrato) => contrato.estado
    }
  ];

  const obtenerOperaciones = (pagina: number, tamano: number) => {
    console.log('obtenerOperaciones');
  };

  const onCloseContratosDialog = (param: any) => {
    console.log('onclos dialog nuevo ontrato');
    setNuevoContrato(!nuevoContrato);
  };

  const onSaveContrato = async (request: Request.RegistroCivil.NuevoContrato) => {
    setCreandoContrato(true);
    await props.nuevoContrato(props.match.params.id, request);
    setCreandoContrato(false);
    setNuevoContrato(!nuevoContrato);
    // setOpenSnackbar(true);
  };

  const onCloseBienDialog = (param: any) => {
    console.log('onclos dialog nuevo bien');
    setNuevoBien(!nuevoBien);
  };

  const onSeleccionarNuevoBien = useCallback((tipoInscripcion: TipoInscripcion, tipoVehiculo: TipoVehiculo) => {
    if (tipoInscripcion === 'inscripcion') {
      //Se debe borrar la inscripción
      props.limpiarInscripcion();
      history.push(routes.registroVehiculos.primeraInscripcion(props.match.params.id, tipoVehiculo));

    } else if (tipoInscripcion === 'transferencia') {
      props.limpiarTransferencia();
      history.push(routes.registroVehiculos.transferencia(props.match.params.id, tipoVehiculo));
    }

    console.log('onSaveContrato dialog nuevo bien');
    setOpenSnackbar(true);
    setNuevoBien(!nuevoBien);
  }, [props.match.params.id]);

  return (
    <>
      <Header
        title='Contratos'
        breadcrum='Usted se encuentra en: Transferencias / Contratos / Detalle'
      >
        <Button variant='contained' className='btn-internal-header' color='primary' disabled={creandoContrato} onClick={() => setNuevoContrato(!nuevoContrato)}>
          Nuevo Contrato
        </Button>
      </Header>
      {empresa &&
        <>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Card>
                <CardContent className={classes.cardContentFixPadding}>
                  <div className={classes.detailsCardContainer}>
                    <div className={classes.containerContentDetails}>
                      <div className={classes.containDetails}>
                        <span className={classes.containDetailsValue}>Nombre Cliente</span>
                        <span className={classes.containDetailsAmount}>{empresa.razonSocial}</span>
                      </div>
                      <div className={classes.containDetails}>
                        <span className={classes.containDetailsValue}>RUT Cliente</span>
                        <span className={classes.containDetailsAmount}>{empresa.rut}</span>
                      </div>
                    </div>
                    <div className={classes.separatorContainerDetails}></div>
                    <div className={classes.containerContentDetails}>
                      <div className={classes.containDetails}>
                        <span className={classes.containDetailsValue}>Cantidad contratos</span>
                        <span className={classes.containDetailsAmount}>{empresa.contratos.length}</span>
                      </div>
                      <div className={classes.containDetails}>
                        <span className={classes.containDetailsValue}>Contacto</span>
                        <span className={classes.containDetailsAmount}>{empresa.encargado}</span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={12} md={12}>
              <div className='tabs'>
                <Tabs value={activeTab} classes={{ indicator: 'indicator' }}>
                  <Tab label='CONTRATOS' classes={{ root: 'tabs-factotal' }} />
                </Tabs>
              </div>
              <Grid container>
                {activeTab === 0 && (
                  <>
                    <div className={classes.detailsCardContainer}>
                      <div className={[classes.containerContentDetails, classes.containerContainerDetailsPadding].join(' ')}>
                        <TableContainer
                          classes={classes}
                          data={empresa.contratos}
                          pagination={paginacion}
                          tableInfo={tableInfo}
                          obtenerDatos={obtenerOperaciones}
                        />
                      </div>
                      <div className={classes.containerContentDetails}>
                        {!contratoSeleccionado && (
                          <div className={classes.seleccionaContratoAlert}>
                            Selecciona el contrato para acceder a los bienes que tiene registrado
                          </div>
                        )}
                        {contratoSeleccionado &&
                          <BienesContrato
                            contrato={contratoSeleccionado}
                            onAgregarBien={() => setNuevoBien(true)}
                          />
                        }
                      </div>
                    </div>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <NuevoContrato
            open={nuevoContrato}
            creandoContrato={creandoContrato}
            onClose={() => onCloseContratosDialog(true)}
            onSave={onSaveContrato}
          />
          <NuevoBien
            open={nuevoBien}
            onClose={() => onCloseBienDialog(true)}
            onSeleccionar={onSeleccionarNuevoBien}
          />
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={openSnackbar}
            autoHideDuration={6000}
            message={'Operación exitosa'}
            color='primary'
            action={
              <>
                <IconButton aria-label='close' color='inherit' onClick={() => setOpenSnackbar(false)}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </>
            }
          />
        </>}
    </>
  );
};

export default withStyles(HistoricoStyle)(connect(mapStateToProps, mapDispatchToProps)(ContratoDetalle));

export const convertBase64ToBlob = (b64Data : string, contentType : string = '', sliceSize = 512) : Blob => {
  const byteCharacters = atob(b64Data);

  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize),
      byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};



export const getBase64 = (file : any, cb : any) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
};

export const getBase64Promise = (file : File) => new Promise<Payloads.ContenidoArchivo>(async resolve => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    resolve({ file: file, content: reader.result as string });
  };
});

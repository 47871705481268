import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../../../sharedv2/custom_theme.scss';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import tableStyle from '../../../../styles/jss/components/tableStyle';
import FormatDate from '../../../../helpers/FormatDate';
import { WithStyles } from '@material-ui/core/styles/withStyles';

interface ITableCertificadosProps extends WithStyles<typeof tableStyle> {
  onDelete: (certificado: Core.Dto.Certificado) => void;
  esEjecutivo: boolean;
  data: Core.Dto.Certificado[];
  handleChange: (certificado: Core.Dto.Certificado, ceder: boolean, contrato: boolean) => void;
}

class TableCertificados extends React.Component<ITableCertificadosProps> {
  constructor(props: ITableCertificadosProps) {
    super(props);
  }

  render() {

    const { data, classes, onDelete, handleChange, esEjecutivo } = this.props;

    return (
      <Table className={classes.table}>
        <TableHead className={classes.thead}>
          <TableRow className={classes.trow}>
            <TableCell className={classes.cell}>Emitido Para</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Valido Desde</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Valido Hasta</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Firma Cesión</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Firma Contrato</TableCell>
            <TableCell className={[classes.cell, classes.centerText].join(' ')} align='right'>Eliminar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id} className={classes.rowBody}>
              <TableCell className={classes.cellBody}>{row.nombre}</TableCell>
              <TableCell align='right'
                         className={[classes.cellBody, classes.centerText].join(' ')}>{FormatDate(row.fechaEmision)}</TableCell>
              <TableCell align='right'
                         className={[classes.cellBody, classes.centerText].join(' ')}>{FormatDate(row.fechaVencimiento)}</TableCell>
              <TableCell className={[classes.cellBody, classes.centerText].join(' ')}>
                <Switch
                  disabled={esEjecutivo}
                  onChange={(event, checked) => handleChange(row, checked, row.paraContrato)}
                  checked={row.paraCeder}
                />
              </TableCell>
              <TableCell className={[classes.cellBody, classes.centerText].join(' ')}>
                <Switch
                  disabled={esEjecutivo}
                  onChange={(event, checked) => handleChange(row, row.paraCeder, checked)}
                  checked={row.paraContrato}
                />
              </TableCell>
              <TableCell className={[classes.cellBody, classes.centerText].join(' ')}>
                <IconButton aria-label='Delete' disabled={esEjecutivo} onClick={() => onDelete(row)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(tableStyle)(TableCertificados);

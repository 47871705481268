import FactotalTheme from './FactotalTheme';
import { Theme } from '@material-ui/core';

const styles = (theme : Theme) => ({
  // ...FactotalTheme,
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: '24px 24px 42px',
    boxShadow: 'none',
    textAlign: 'center' as 'center'
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    fontSize: 16
  },
  fullPage: {
    padding: '60px 0',
    position: 'relative' as 'relative',
    minHeight: '100vh',
    maxHeight: '1000px',
    display: 'flex!important',
    margin: '0',
    border: '0',
    color: '#fff',
    alignItems: 'center' as 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center' as 'center',
    height: '100%',
    '& footer': {
      position: 'absolute' as 'absolute',
      bottom: 15,
      width: '100%',
      border: 'none !important',
      color: '#555759'

    },
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.65)'
    },
    '&:before,&:after': {
      display: 'block',
      content: '""',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      zIndex: 5
    }
  },
  container: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&:before,&:after': {
      display: 'table',
      content: '" "'
    },
    '&:after': {
      clear: 'both' as 'both'
    }
  },
  gridLoginImage: {
    backgroundColor: '#82BC00',
    borderRadius: '2.25rem 0 0'
  },
  media: {
    width: '40%',
    margin: '1rem auto 1rem auto',
    alignItems: 'center',
    display: 'block'
  },
  gridLoginContent: {
    padding: '0 2.5rem'
  },
  icon: {
    color: '#495057',
    fontSize: 36
  },
  iconLine: {
    color: '#555759',
    fontSize: 24
  },
  button: {
    margin: '8px 8px 25px',
    backgroundColor: '#82BC00',
    color: '#FFFFFF',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 25,
    display: 'flex',
    fontSize: '.85rem',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 600,
    lineHeight: '.85rem',
    padding: '14px 16px',

    '&:hover': {
      backgroundColor: '#82BC00'
    },
    '&:focus': {
      backgroundColor: '#82BC00'
    }
  },
  buttonOutLined: {
    margin: '8px 8px 25px',
    color: '#82BC00',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 25,
    display: 'flex',
    fontSize: '.85rem',
    textTransform: 'uppercase' as 'uppercase',
    fontWeight: 600,
    lineHeight: '.85rem',
    padding: '14px 16px',
    boxShadow: 'inset 0px 0px 0px 2px #82BC00',
    border: 'none',
    '&:hover': {
      boxShadow: 'inset 0px 0px 0px 2px #82BC00',
      border: 'none'
    },
    '&:focus': {
      boxShadow: 'inset 0px 0px 0px 2px #82BC00',
      border: 'none'
    }
  },
  margin: {
    margin: theme.spacing.unit
  },
  card: {
    backgroundColor: '#ffffff',
    borderRadius: '2.25rem .25em .25em',
    opacity: '0.9',
    filter: 'alpha(opacity=80)',
    width: '100%',
    wordWrap: 'break-word' as 'break-word'
  },
  cardContent: {
    padding: '0 !important'
  },

  paperCrearCuenta: {
    boxShadow: 'none',
    alignItems: 'left',
    textAlign: 'left' as 'left'
  },
  footerLogin: {
    backgroundColor: FactotalTheme.palette.background.paper,
    marginTop: FactotalTheme.spacing.unit * 8,
    padding: `${FactotalTheme.spacing.unit * 6}px 0`
  },
  textoFooterLogin: {
    color: '#555759',
    fontSize: '1rem',
    marginBottom: 10,
    padding: '15px 15px 0px 15px !important',
    '& a': {
      color: '#2E2A25',
      textDecoration: 'none' as 'none',
      '&:hover, &:focus': {
        textDecoration: 'underline' as 'underline'
      }
    }
  },
  loginFormAdornedStart: {
    color: '#4B4D4F',
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
    '&:before, &:after': {
      left: '32px',
      borderColor: '#BDBFC0'
    },
    '&:hover:before, &:hover:after, &:focus:before, &:focus:after': {
      borderColor: '#82BC00 !important',
      borderBottomWidth: '1px'
    }
  },
  loginFormInputFocused: {
    color: '#4B4D4F',
    '&:before, &:after, &:active': {
      borderColor: '#82BC00 !important'
    }
  },
  buttonForgotPwdRoot: {
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    float: 'right' as 'right',
    fontSize: '.85rem',
    marginBottom: 20,
    color: '#4B4D4F',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline' as 'underline'
    }
  },
  buttonSubscriberRoot: {
    minWidth: 0,
    minHeight: 0,
    padding: 0,
    float: 'left' as 'left',
    fontSize: '.85rem',
    marginBottom: 20,
    color: '#4B4D4F',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline' as 'underline'
    }
  },
  msgCrearClavePaper: {
    backgroundColor: 'rgba(255,255,255, .85)',
    width: 750,
    height: 300,
    margin: 'auto',
    top: 0,
    position: 'absolute' as 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem',
    borderRadius: '1rem',
    boxShadow: '0px 0px 0px 3px #82BC00',
    '&:focus': {
      outline: 'none'
    }
  },
  msgCrearClaveHeader: {
    height: 80,
    width: 100,
    backgroundColor: 'rgba(130,188,0,.8)',
    borderRadius: '.8rem',
    position: 'relative' as 'relative',
    display: 'flex',
    margin: 'auto',
    top: -56,
    '& > div': {
      margin: 'auto'
    },
    '& svg': {
      height: 60,
      width: 60,
      margin: 'auto',
      '& path': {
        fill: '#fff',
        '&[fill="none"]': {
          fill: 'none'
        }
      }
    }
  },
  msgCrearClaveContent: {
    textAlign: 'center' as 'center'
  },
  msgCrearClaveContentTitle: {
    fontSize: '2rem',
    color: '#2E2A25',
    marginBottom: '2rem'
  },
  msgCrearClaveContentSubTitle: {
    fontSize: '1rem',
    lineHeight: '2rem'
  },
  msgCrearClaveFooter: {
    marginTop: '4rem',
    '& button': {
      backgroundColor: '#82BC00',
      fontSize: '.85rem',
      margin: 'auto',
      display: 'flex',
      '&:hover': {
        backgroundColor: '#82BC00'
      },
      '&:focus': {
        backgroundColor: '#82BC00'
      }
    }
  },
  containerCardsCompany: {
    opacity: '1 !important',
    boxShadow: 'inset 0px 0px 0px 1px #82BC00',
    maxHeight: 500,
    overflowY: 'auto' as 'auto',
    overflowX: 'hidden' as 'hidden'
  },
  cardSelectCompany: {
    border: '1px solid #82BC00',
    cursor: 'pointer',
    color: '#82BC00',
    height: '100px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '.85rem',
    '&:hover': {
      backgroundColor: 'rgb(239, 240, 240)'
    }
  },
  cardContentRoot: {
    '&:last-child': {
      paddingBottom: 16
    }
  }
});

export default styles;

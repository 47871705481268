import React from 'react';

export const fileToBase64 = (evt : React.ChangeEvent<HTMLInputElement>) : Promise<string> => {
  return new Promise(resolve => {
    if (evt.target.files) {
      let f = evt.target.files[0]; // FileList object
      let reader = new FileReader();
      // Closure to capture the file information.
      reader.onload = (function (theFile) {
        return function (e : any) {
          const binaryData = e.target.result;
          //Converting Binary Data to base 64
          const base64String = window.btoa(binaryData);
          resolve(base64String);
        };
      })(f);
      // Read in the image file as a data URL.
      reader.readAsBinaryString(f);
    } else {
      resolve('');
    }
  });
};

export const getNameFile = (pathFile : string) => {
  let fileName = pathFile.split('\\');
  return fileName[fileName.length - 1];
};

function base64ToArrayBuffer(base64 : any) {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export const descargaArchivo = (payload : Payloads.DescargaArchivoPayload) => {
  const archivo = base64ToArrayBuffer(payload.bytes);
  const blob = new Blob([archivo]);
  const fileName = payload.nombre;
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
